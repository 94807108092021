import { createReducer, on } from '@ngrx/store'
import {
  getOrganizationById,
  getOrganizationByIdInvalidate,
  getOrganizationByIdResponse,
  updateAutoEnableManagementSessionResponse,
  updateOrganizationGeneralSettingsResponse,
  updateOrganizationManagementActionsResponse,
  updateOrganizationVirtualTenantsResponse,
  updateSecureByDefaultResponse,
  updateServiceNowEnabledResponse,
} from './organizations.actions'
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'
import { OrganizationsState } from '@app/store/organizations/organizations.types'
import { Organization } from '@core/models/Organization'
import { UpdateStr } from '@ngrx/entity/src/models'

/**
 * Creates a handy adapter for the the "organizations" state.
 * The adapter makes sure to take the `guid` property to index the "organizations"
 * state rather than the default `id` property which is not available on Organization data type.
 */
export const organizationsAdapter: EntityAdapter<Organization> = createEntityAdapter<Organization>({
  selectId: ({ guid }) => guid,
})

export const initialState: OrganizationsState = organizationsAdapter.getInitialState({
  didInvalidate: false,
  isFetching: false,
  error: null,
  myOrganization: undefined,
  loaded: false,
})

const updateProperties = (state: OrganizationsState, { data }: any) => {
  const { guid, ...changes } = data
  const update: UpdateStr<Organization> = { id: data.guid as string, changes }

  return organizationsAdapter.updateOne(update, state)
}

/**
 * Derives the next "organizations" state from the previous one and the current action.
 * Uses the adapter to make sure the next state is a valid entity state.
 * The returned state is a brand a new object.
 */
export const organizationsReducer = createReducer(
  initialState,
  on(getOrganizationById, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(getOrganizationByIdResponse, (state, { data, selected }) => {
    const nextState = organizationsAdapter.setOne(data, state)
    if (selected) {
      nextState.selectedOrganization = data.guid
    }
    return {
      ...nextState,
      isFetching: false,
      loaded: selected || (state.selectedOrganization && !!state.entities[state.selectedOrganization]) || false,
    }
  }),
  on(getOrganizationByIdInvalidate, (state, action) => ({ ...state, error: action.error, isFetching: false, didInvalidate: true })),
  on(updateOrganizationGeneralSettingsResponse, updateProperties),
  on(updateOrganizationVirtualTenantsResponse, updateProperties),
  on(updateSecureByDefaultResponse, updateProperties),
  on(updateServiceNowEnabledResponse, updateProperties),
  on(updateAutoEnableManagementSessionResponse, updateProperties),
  on(updateOrganizationManagementActionsResponse, updateProperties)
)

export default organizationsReducer

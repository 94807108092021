<div class="cv-dialog-div-container">
  <div><cv-button 
  [mat-dialog-close]="'cancel'" 
  [text]="'common_Back' | translate" 
  [buttonType]="'tertiary'"
  [leftIcon]="'arrow_back'"
  style="margin-left: -15px">
</cv-button>
<h2>{{ title | translate }}</h2>
</div>
<cv-alert-bar
  variant="info"
  [title]="'playbook_timeSavingPerWorkflowInfo' | translate"
  style="margin-bottom: 10px"
  [hideCloseButton]="true"
>
</cv-alert-bar>
<div class="dialog-container">  
  <app-client-datagrid 
    [rowMultiSelectWithClick]="false"
    [hideColumnsSelector]="true" 
    [columnsParameters]="columnParams"
    [selectionActions]="selectionActions" 
    [getItems]="getItems" 
    style="flex: 1">
  </app-client-datagrid>
</div>
</div>
import { TranslateHelper } from '@coreview/coreview-library'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

export type filterModel = { filterType: string, type: string, filter: string | null, modelAsString?: string | null }

export type monthYearSelections = ['common_ThisMonth', 'common_LastMonth', 'common_Last3Months', 'common_Last6Months', 'common_Last12Months']

export class SingleSelectMonthYear {
    public static Init() {
        dayjs.extend(utc)
    }

    public static getMonthYearOptions(translateHelper: TranslateHelper) {
        return [
            { value: 'common_ThisMonth', display: translateHelper.instant('common_ThisMonth') },
            { value: 'common_LastMonth', display: translateHelper.instant('common_LastMonth') },
            { value: 'common_Last3Months', display: translateHelper.instant('common_Last3Months') },
            { value: 'common_Last6Months', display: translateHelper.instant('common_Last6Months') },
            { value: 'common_Last12Months', display: translateHelper.instant('common_Last12Months') }
        ]
    }

    public static getValue(value: string | null) {
        const values = new Map([
            ['common_ThisMonth', () => ({ type: 'greaterThanOrEqual', filter: dayjs().utc(true).startOf("month").format("YYYY-MM-DD") })],
            ['common_LastMonth', () => ({ type: 'inRange', filter: dayjs().utc(true).subtract(1, 'month').startOf("month").format("YYYY-MM-DD") + " & " + dayjs().utc(true).subtract(1, 'month').endOf("month").format("YYYY-MM-DD") })],
            ['common_Last3Months', () => ({ type: 'greaterThanOrEqual', filter: dayjs().utc(true).subtract(2, 'month').startOf("month").format("YYYY-MM-DD") })],
            ['common_Last6Months', () => ({ type: 'greaterThanOrEqual', filter: dayjs().utc(true).subtract(5, 'month').startOf("month").format("YYYY-MM-DD") })],
            ['common_Last12Months', () => ({ type: 'greaterThanOrEqual', filter: dayjs().utc(true).subtract(11, 'month').startOf("month").format("YYYY-MM-DD") })]])
        const val = values.get(value || 'common_ThisMonth')
        return val ? val() : { type: 'equals', filter: '1970-01-01' }
    }

    public static getFilterModel = (value: string | null): filterModel => {
        return { filterType: 'monthYear', ...SingleSelectMonthYear.getValue(value), modelAsString: value }
    }
}
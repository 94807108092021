import { Constants } from '@app/shared/utilities/constants'

/* Imported from 4ward365.Web on 03/22/2021 */
  /* List all the roles you wish to use in the app
  * You have a max of 31 before the bit shift pushes the accompanying integer out of
  * the memory footprint for an integer
  */
  //TODO: download the public roles to join to the fixed ones
  export const availableRoles = [
    Constants.roles.Guest,
    Constants.roles.Public,
    Constants.roles.User,
    Constants.roles.TenantAdmin,
    Constants.roles.GlobalStats,
    Constants.roles.Audit,
    Constants.roles.Admin,
    Constants.roles.Beta,
    Constants.roles.PortalAdmin,
    Constants.roles.Management,
    Constants.roles.Sales,
    Constants.roles.GlobalLicenses,
    Constants.roles.CoreLearningAdmin,
    Constants.roles.SupportChat,
    Constants.roles.Feedback,
    Constants.roles.Invoice,
    Constants.roles.WorkflowEditor,
    Constants.roles.WorkflowPublisher
  ]


  export const accessLevels: Record<string, string[]> = {
    noServerCheck: [Constants.noServiceCheck],
    public: availableRoles,
    anon: [Constants.roles.Public, Constants.roles.Guest, Constants.roles.User, Constants.roles.TenantAdmin],
    setup: [Constants.roles.Guest, Constants.roles.TenantAdmin],
    user: [Constants.roles.User, Constants.roles.TenantAdmin, Constants.roles.Beta],
    tenantAdmin: [Constants.roles.TenantAdmin, Constants.roles.Beta],
    management: [Constants.roles.Management, Constants.roles.TenantAdmin, Constants.roles.Beta],
    globalStats: [Constants.roles.GlobalStats, Constants.roles.TenantAdmin, Constants.roles.Beta],
    portalAdmin: [Constants.roles.PortalAdmin, Constants.roles.Beta],
    beta: [Constants.roles.Beta],
    audit: [Constants.roles.TenantAdmin, Constants.roles.Audit],
    sales: [Constants.roles.Sales],
    globalLicenses: [Constants.roles.TenantAdmin, Constants.roles.GlobalLicenses],
    coreLearning: [Constants.roles.CoreLearningAdmin, Constants.roles.TenantAdmin],
    workflow: [Constants.roles.TenantAdmin, Constants.roles.Management, Constants.roles.WorkflowEditor, Constants.roles.WorkflowPublisher],
    workflowAdmin: [Constants.roles.TenantAdmin, Constants.roles.WorkflowEditor],
    playbookAdmin: [Constants.roles.TenantAdmin, Constants.roles.PlaybookAdmin]
  }

import { ReportsService, } from '@app/core/services/reports.service'
import { of } from 'rxjs'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { getOnlineuserColumns, getOnlineuserColumnsInvalidate, getOnlineuserColumnsResponse } from './onlineuser-columns.actions'

@Injectable()
export class OnlineUserColumnsEffects {

  getOnlineUserColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOnlineuserColumns),
      mergeMap(() => this.reportsService.getOnlineUsersColumns()
        .pipe(
          map(actions => getOnlineuserColumnsResponse({ actions })),
          catchError((error) => of(getOnlineuserColumnsInvalidate({ error: { code: error.status, message: error.message }})
            ))
        ))
    )
  );

  constructor(
    private actions$: Actions,
    private reportsService: ReportsService
  ) {
  }
}

<div>
  <cv-button-menu
    size="tiny"
    class="columns-button"
    leftIcon="visibility"
    [text]="'common_Columns' | translate"
    buttonType="tertiary"
    (click)="refreshPanelColumns()"
    (onMenuClose)="clear()"
  >
    <div class="columns-header">
      <div class="columns-message">
        <p *ngIf="currentSelection.length === 0" translate>common_SelectAtLeastOneItem</p>
        <p *ngIf="currentSelection.length > 0">{{ currentSelection.length }} {{ 'common_SelectedItems' | translate }}</p>
      </div>
      <cv-button
        size="tiny"
        (click)="resetColumns(); $event.stopPropagation()"
        class="reset-button"
        buttonType="tertiary"
        color="alert"
        [text]="'common_ResetColumns' | translate"
        leftIcon="autorenew"
      ></cv-button>
    </div>
    <div class="filter-container">
      <cv-input
        [label]="'common_Search' | translate"
        rightIcon="search"
        class="filter"
        [ngModel]="filter"
        (ngModelChange)="changedFilter($event)"
        (click)="$event.stopPropagation()"
        #input
        cv-data-test="column-search-field"
      ></cv-input>
      <mat-icon (click)="clear(); $event.stopPropagation()" *ngIf="input.value" class="clear-icon">close</mat-icon>
    </div>
    <div class="columns-container" cdkDropList (cdkDropListDropped)="drop($event)">
      <p *ngIf="visibleColumns.length === 0" translate>common_NoRowsToShow</p>
      <mat-checkbox
        *ngIf="visibleColumns.length > 0"
        (click)="checkAll($event)"
        [indeterminate]="selectAllStatus === 'indeterminate'"
        [checked]="selectAllStatus === 'checked'"
        class="selectall-checkbox"
      >
        <span translate *ngIf="!filter">common_SelectAll</span>
        <span translate *ngIf="filter">common_SelectAllSearchResult</span>
      </mat-checkbox>

      <div
        *ngFor="let col of visibleColumns"
        cdkDrag
        [cdkDragDisabled]="col.hide"
        class="draggable-checkbox"
        (click)="toggleCheckbox(col, $event)"
      >
        <mat-checkbox
          class="column-checkbox"
          [checked]="!col.hide || !!isLockedColumn(col)"
          (change)="check(col, $event)"
          [disabled]="isLockedColumn(col)"
          (click)="$event.stopPropagation()"
        >
          <div class="column-checkbox-content" (mouseenter)="showHandCursor($event)" (mouseleave)="hideHandCursor($event)">
            <div translate>{{ col.headerName }}</div>
            <ng-container *ngIf="col.field && defaultFilters">
              <app-default-filter-indicator
                [filterKey]="col.field"
                [filter]="defaultFilters[col.field]"
                *ngIf="defaultFiltersKeys.includes(col.field)"
              ></app-default-filter-indicator>
            </ng-container>
          </div>
        </mat-checkbox>
        <div class="drag-handle" cdkDragHandle [class.hidden]="col.hide">
          <mat-icon>drag_indicator</mat-icon>
        </div>
      </div>
    </div>
    <div class="footer-buttons">
      <cv-button (click)="clear()" buttonType="tertiary" [text]="'common_Cancel' | translate"></cv-button>
      <cv-button
        [disabled]="currentSelection.length === 0"
        (click)="changeColumnsVisibility()"
        buttonType="primary"
        [text]="'common_Apply' | translate"
      ></cv-button>
    </div>
  </cv-button-menu>
</div>

import { Component, Input, OnInit } from '@angular/core'
import { AdvancedFilterConfiguration, LicensePoolInfoFilter } from '@app/core/models/LicensePool'
import { RightPanelRef } from '@app/core/services/right-panel.service'

@Component({
  selector: 'app-advanced-filters',
  templateUrl: './advanced-filters.component.html',
  styleUrls: ['./advanced-filters.component.sass'],
})
export class AdvancedFiltersComponent implements OnInit {
  @Input() configuration!: AdvancedFilterConfiguration
  @Input() showOnlyPoolsWithAllocation = true

  licenses: LicensePoolInfoFilter[] = []
  selectedLicenses: string[] = []
  hasShowOnlyPoolsWithAllocation = true
  currentShowOnlyPoolsWithAllocation = true
  isValid = true
  selectAll = false

  constructor(private rightPanelRef: RightPanelRef) {}

  ngOnInit(): void {
    this.hasShowOnlyPoolsWithAllocation = this.configuration.hasShowOnlyPoolsWithAllocation
    this.currentShowOnlyPoolsWithAllocation = this.showOnlyPoolsWithAllocation

    this.licenses = this.configuration.licenses

    if (this.configuration.selectedLicenses) {
      this.selectedLicenses = this.configuration.selectedLicenses
    } else {
      this.licenses.forEach((s: LicensePoolInfoFilter) => this.selectedLicenses.push(s.skuId))
    }

    this.selectAll = this.selectedLicenses.length === this.licenses.length;
  }

  isCheckedLicense(license: LicensePoolInfoFilter) {
    return this.selectedLicenses.indexOf(license.skuId) >= 0
  }

  showHideManageLicenses() {
    this.selectedLicenses = []
    this.licenses = this.configuration.licenses
    this.licenses.forEach((s: LicensePoolInfoFilter) => this.selectedLicenses.push(s.skuId))
  }

  handleChange(license: LicensePoolInfoFilter) {
    const idx = this.selectedLicenses.indexOf(license.skuId)
    if (idx > -1) {
      this.selectedLicenses.splice(idx, 1)
    } else {
      this.selectedLicenses.push(license.skuId)
    }
    this.isValid = this.selectedLicenses.length > 0
    this.selectAll = this.selectedLicenses.length === this.licenses.length;
  }

  close() {
    this.rightPanelRef.close()
  }

  submit() {
    this.rightPanelRef.close({
      config: this.configuration,
      selectedLicenses: this.selectedLicenses,
      showOnlyPoolsWithAllocation: this.currentShowOnlyPoolsWithAllocation
    })
  }

  changeSKUwithAllocation(isShow: boolean) {
    this.currentShowOnlyPoolsWithAllocation = isShow
  }

  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.selectedLicenses = this.licenses.map(license => license.skuId);
    } else {
      this.selectedLicenses = [];
    }
    this.isValid = this.selectedLicenses.length > 0;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseItem } from '@app/core/models/universal-search';
import { UniversalSearchService } from '@app/core/services/universal-search.service';

@Component({
  selector: 'app-show-more-item',
  templateUrl: './show-more-item.component.html',
  styleUrls: ['../universal-search.component.sass']
})
export class ShowMoreItemComponent {
  @Input() item!: BaseItem
  @Input() index!: number 
  @Output() manageItem: EventEmitter<BaseItem> = new EventEmitter<BaseItem>()
  @Output() onManageableElementClick: EventEmitter<BaseItem> = new EventEmitter<BaseItem>()

  showOnHover: { [key: number]: boolean } = {}

  constructor(private universalSearchService: UniversalSearchService) {}

  handleManageItem(item: BaseItem): void {
    this.manageItem.emit(item)
  }

  handleOnManageableElementClick(item: BaseItem): void {
    this.onManageableElementClick.emit(item)
  }

  isElmentManageable = (item: BaseItem) => this.universalSearchService.isElmentManageable(item)
}

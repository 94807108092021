<mat-toolbar>
  <div *ngIf="isMenuOpen" style="width: 226px" class="toolbar-logo" [ngClass]="{ 'menu-closed': !isMenuOpen }">
    <span style="flex: 1"
      ><img
        *ngIf="logoLoaded"
        [src]="logoUrl ? logoUrl : '../../../../assets/img/logos/CoreView logo.svg'"
        routerLink="/"
        class="logo"
        alt="company logo"
      />
    </span>
  </div>
  <div *ngIf="!isMenuOpen" style="width: 226px" class="toolbar-logo" [ngClass]="{ 'menu-closed': !isMenuOpen }">
    <span style="display: inline-block; width: 40px; overflow: hidden">
      <img
        *ngIf="logoLoaded"
        [src]="smallLogoUrl ? smallLogoUrl : '../../../../assets/img//logos/cv-logo-small.svg'"
        class="logo"
        routerLink="/"
        alt="compoany logo"
      />
    </span>
  </div>

  <div class="toolbar-search-wrapper">
    <app-universal-search *ngIf="showGlobalSearch" (universalSearchToggled)="showUniversalSearch = $event"></app-universal-search>
  </div>

  <div class="toolbar-actions-wrapper pendo-space">
    <div class="tenants" [class.left-border]="showManagement" [class.active]="showTenants">
      <div class="tenant-selector" (click)="showTenants = !showTenants">
        {{ (defaultTenant$ | async)?.managedTenantName }}
      </div>
      <div class="right-panel" *ngIf="showTenants">
        <div class="toolbar-panel-title">
          <div style="flex: 1; font-size: 14px; font-weight: 600" translate>common_Tenants</div>
          <cv-close-button (click)="showTenants = false"  cv-data-test="close-tenants-panel"></cv-close-button>
        </div>
        <cv-input
          [clearButton]="true"
          [ngModel]="filter"
          (inputChange)="changedFilter($event)"
          label="Search"
          value=""
          leftIcon="search"
          style="padding: 18px; width: 100%; box-sizing: border-box"
        ></cv-input>
        <div style="max-height: 100%; overflow-x: auto">
          <mat-option
            (click)="tenant.onClick()"
            class="tenant-option"
            style="font-size: 14px; padding: 18px; display: flex"
            *ngFor="let tenant of getFilteredTenants()"
          >
            <div style="display: flex; flex: 1">
              <span style="flex: 1">{{ tenant.managedTenantName }}</span>
              <mat-icon style="margin: auto 0px" *ngIf="tenant.managedTenantId === (defaultTenant$ | async)?.managedTenantId"
                >checked</mat-icon
              >

              <cv-badge
                *ngIf="tenant.isGranted"
                [text]="'administration_SeeGrantedDetails' | translate"
                fontSize="12px"
                [hideBorder]="true"
                color="var(--primary-color)"
                backgroundColor="var(--info-surface-color)"
                (click)="$event.stopPropagation(); showGrantAccessTenantDetails(tenant)"
              ></cv-badge>

            </div>
          </mat-option>
        </div>
      </div>
    </div>

    <div class="management" [class.left-border]="showNotifications" [class.active]="showManagement">
      <div class="management-status" (click)="showManagement = !showManagement" cv-data-test="management-status">
        <div *ngIf="managementStatus" class="management-chip">
          <span [innerHTML]="getManagementStatus()?.text || '' | translate"></span>
          <mat-icon
            [style.opacity]="getManagementStatus()?.iconOpacity"
            class="management-icon"
            >{{ getManagementStatus()?.icon }}</mat-icon
          >
        </div>
      </div>
      <div class="right-panel" *ngIf="showManagement">
        <app-management-panel [managementStatus]="managementStatus" [organization]="organization" (close)="showManagement = false"></app-management-panel>
      </div>
    </div>

    <div
      class="notifications toolbar-action-container-back"
      [class.active]="showNotifications"
      [class.right-border]="showManagement"
      [class.left-border]="showFilters"
    >
      <div
        style="position: relative"
        class="toolbar-action-container"
        [class.active]="showNotifications"
        cv-data-test="show-notifications"
        (click)="onShowTasksPanel()"
      >
        <mat-icon class="toolbar-action material-icons">notifications</mat-icon>
        <div
          class="notifications"
          *ngIf="notificationsNumber && !showNotifications"
          style="
            padding: 3px;
            pointer-events: none;
            min-width: 20px;
            height: 20px;
            border-radius: 22px;
            background: #c04c50;
            position: absolute;
            top: 25px;
            left: 30px;
            text-align: center;
            vertical-align: middle;
            line-height: 22px;
            font-size: 12px;
            color: white;
          "
        >
          {{ notificationsNumber }}
        </div>
      </div>
      <div class="right-panel" *ngIf="showNotifications">
        <ng-container *ngIf="!shownTask && !shownAggregatedTask">
          <div class="toolbar-panel-title">
            <div style="flex: 1; font-size: 14px; font-weight: 600" translate>common_TaskNotifications</div>
            <cv-close-button (click)="closeNotificationsPanel()" cv-data-test="close-tasks-notification-panel"></cv-close-button>
          </div>
          <div class="tasks-filters">
            <cv-input
              [(ngModel)]="taskInput"
              (inputChange)="tasksInputChanged$.emit($event)"
              class="fl-1 m-r-10 search-field"
              leftIcon="search"
              [label]="'common_Search' | translate"
            ></cv-input>
            <cv-select
              (selectionChanged)="selectTaskChanged()"
              [(ngModel)]="taskSelect"
              icon="filter_alt"
              iconStyle="outlined"
              class="fl-1 m-r-10 search-field"
              [label]="'common_FilterByStatus' | translate"
              [suggestions]="taskFilterStatuses"
            ></cv-select>
            <div class="default-text default-margin-left" translate>common_Last7days</div>
          </div>
          <div
            class="fl-1"
            style="display: grid; grid-template-columns: max-content 1fr max-content max-content; grid-auto-rows: 90px; overflow: auto"
          >
            <div *ngIf="loadingTasks">
              <i style="font-size: 15px; margin: 0 15px" class="fas fa-spinner fa-pulse"></i><span translate>common_RowLoading</span>
            </div>
            <div *ngIf="!tasks.length && !loadingTasks" class="m-l-10 m-t-10" [innerHtml]="'common_NoNotificationsText' | translate"></div>
            <ng-container *ngFor="let task of tasks">
              <div
                (click)="showTaskDetails(task); $event.stopPropagation()"
                class="task-status-container"
                [ngClass]="{ 'task-to-read': task.toRead }"
              >
                <cv-badge
                  [variant]="getStatusDetail(task.state?.toString() || '')?.variant || 'grey'"
                  [leftIcon]="getStatusDetail(task.state?.toString() || '')?.icon || ''"
                  [text]="getStatusDetail(task.state?.toString() || '')?.text || '' | translate"
                  fontSize="12px"
                  [hideBorder]="true"
                ></cv-badge>
              </div>
              <div
                (click)="showTaskDetails(task); $event.stopPropagation()"
                class="task-title-container"
                [ngClass]="{ 'task-to-read': task.toRead }"
              >
                <div style="font-size: 10px; line-height: 14px; flex: 1" class="text-grey">
                  <div class="m-t-5" style="text-transform: uppercase">{{ formatDate(task.startDate) }}</div>
                </div>
                <div style="font-size: 14px; line-height: 20px; font-weight: 600; text-overflow: ellipsis; overflow: hidden">
                  {{ getTaskTitleTranslated(task.title) }}
                </div>
                <div style="font-size: 12px; line-height: 20px; flex: 1; text-overflow: ellipsis; overflow: hidden" class="text-grey">
                  {{ task.shortDescription }}
                </div>
              </div>
              <div
                class="task-workflow-info-container"
              >
                <cv-badge
                  *ngIf="isWorkflowTask(task)"
                  color="var(--primary-color)"
                  backgroundColor="var(--info-surface-color)"
                  (click)="onWorkflowExecutionClick(task); $event.stopPropagation()"
                  [text]="'common_SeeWorkflowExecution' | translate"
                >
                </cv-badge>
              </div>
              <div
                (click)="showTaskDetails(task); $event.stopPropagation()"
                class="task-status-container"
                [ngClass]="{ 'task-to-read': task.toRead} "
              >
                <mat-icon [tooltip]="'common_TaskWarningAttached' | translate" class="task-icon" *ngIf="task.warningMessage"
                  >warning</mat-icon
                >
              </div>
            </ng-container>
          </div>
          <div *ngIf="false" style="display: flex; height: 67px; align-items: center; padding: 0 10px">
            <cv-switch style="overflow: hidden" class="fl-1">{{ 'common_EnableNotificationForFailedTasks' | translate }}</cv-switch>
            <cv-button
              buttonType="tertiary"
              style="overflow: hidden"
              [text]="'common_Settings' | translate"
              leftIcon="settings"
            ></cv-button>
          </div>
        </ng-container>

        <ng-container *ngIf="shownTask">
          <div class="toolbar-panel-title">
            <div
              appCheckEllipsis
              [tooltip]="shownTask.title"
              [display]="taskEllipsis.isEllipsed"
              tooltipClass="cv-tooltip"
              #taskEllipsis="appCheckEllipsis"
              style="flex: 1; font-size: 14px; font-weight: 600; overflow: hidden; text-overflow: ellipsis"
            >
              {{ getTaskTitleTranslated(shownTask.title) }}
            </div>
            <cv-close-button (click)="closeNotificationsPanel()" cv-data-test="close-task-details-panel"></cv-close-button>
          </div>
          <div class="fl-1" style="display: flex; flex-direction: column; overflow: hidden">
            <div>
              <cv-button
                class="m-l-10"
                style="float: right; margin-top: 10px; margin-right: 10px"
                buttonType="tertiary"
                [text]="'common_Reload' | translate"
                leftIcon="refresh"
                (click)="reloadTaskDetail()"
              ></cv-button>
            </div>
            <div
              style="
                flex: 1;
                display: grid;
                grid-template-columns: max-content 1fr;
                width: 100%;
                margin-top: 20px;
                overflow: auto;
                grid-auto-rows: min-content;
              "
            >
              <div style="padding: 10px; overflow: hidden; font-weight: 600" translate>common_User</div>
              <div style="padding: 10px; overflow: hidden">{{ shownTask.user }}</div>
              <div style="padding: 10px; overflow: hidden; font-weight: 600" translate>common_State</div>

              <cv-badge
                class="p-10"
                (click)="showTaskDetails(shownTask); $event.stopPropagation()"
                [variant]="getStatusDetail(shownTask.state?.toString() || '')?.variant || 'grey'"
                [leftIcon]="getStatusDetail(shownTask.state?.toString() || '')?.icon || ''"
                [text]="getStatusDetail(shownTask.state?.toString() || '')?.text || '' | translate"
                fontSize="12px"
                [hideBorder]="true"
              ></cv-badge>

              <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis; font-weight: 600" translate>common_Percentage</div>
              <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis">{{ shownTask.percentage + '%' }}</div>
              <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis; font-weight: 600" translate>common_StartDate</div>
              <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis">{{ formatDate(shownTask.startDate) }}</div>
              <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis; font-weight: 600" translate>common_endDate</div>
              <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis">{{ formatDate(shownTask.endDate) }}</div>
              <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis; font-weight: 600" translate>common_Parameters</div>
              <div>
                <div style="display: grid; grid-template-columns: 1fr 2fr; width: 100%">
                  <ng-container *ngFor="let e of shownTask.parameters | keyvalue">
                    <div
                      appCheckEllipsis
                      [tooltip]="e.key"
                      [display]="keyEllipsis.isEllipsed"
                      tooltipClass="cv-tooltip"
                      style="padding: 10px; overflow: hidden; text-overflow: ellipsis; font-weight: 600"
                      #keyEllipsis="appCheckEllipsis"
                    >
                      {{ e.key }}
                    </div>
                    <div
                      appCheckEllipsis
                      [tooltip]="e.value"
                      [display]="valueEllipsis.isEllipsed"
                      placement="left"
                      tooltipClass="long-tooltip"
                      style="padding: 10px; overflow: hidden; text-overflow: ellipsis"
                      #valueEllipsis="appCheckEllipsis"
                    >
                      {{ ': ' + e.value }}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis; font-weight: 600" translate>common_Notes</div>
              <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis">{{ shownTask.note }}</div>
              <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis; font-weight: 600" translate>
                common_ShortDescription
              </div>
              <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis; white-space: normal">
                {{ shownTask.shortDescription }}
              </div>
              <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis; font-weight: 600" translate>common_Description</div>
              <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis; white-space: normal">{{ shownTask.description }}</div>
              <ng-container *ngIf="shownTask.warningMessage">
                <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis; font-weight: 600" translate>common_Warning</div>
                <div style="padding: 10px; overflow: hidden; text-overflow: ellipsis; white-space: normal">
                  {{ shownTask.warningMessage }}
                </div>
              </ng-container>
            </div>
          </div>
          <div class="tasks-back-button-container">
            <cv-button
              class="m-l-10"
              (click)="shownTask = null; $event.stopPropagation()"
              buttonType="secondary"
              [text]="'common_back' | translate"
              leftIcon="arrow_backward"
            ></cv-button>
          </div>
        </ng-container>

        <ng-container *ngIf="shownAggregatedTask && !shownTask">
          <div class="toolbar-panel-title">
            <div
              appCheckEllipsis
              [tooltip]="shownAggregatedTask.title"
              [display]="aggTaskEllipsis.isEllipsed"
              tooltipClass="cv-tooltip"
              #aggTaskEllipsis="appCheckEllipsis"
              style="flex: 1; font-size: 14px; font-weight: 600; overflow: hidden; text-overflow: ellipsis"
            >
              {{ shownAggregatedTask.title }}
            </div>
            <cv-close-button (click)="closeNotificationsPanel()"  cv-data-test="close-agg-task-details-panel"></cv-close-button>
          </div>
          <div class="fl-1" style="display: flex; flex-direction: column; overflow: hidden">
            <div>
              <cv-button
                class="m-l-10"
                style="float: right; margin-top: 10px; margin-right: 10px"
                buttonType="tertiary"
                [text]="'common_Reload' | translate"
                leftIcon="refresh"
                (click)="grid.refresh()"
              ></cv-button>
            </div>
            <app-datagrid
              class="fl-1"
              [getItems]="getItems()"
              [gridDefinition]="reportGridDefinition"
              [hideColumnsSelector]="true"
              #grid
            ></app-datagrid>
          </div>
          <div class="tasks-back-button-container">
            <cv-button
              class="m-l-10"
              (click)="shownAggregatedTask = null; $event.stopPropagation()"
              buttonType="secondary"
              [text]="'common_back' | translate"
              leftIcon="arrow_backward"
            ></cv-button>
          </div>
        </ng-container>

        <ng-container *ngIf="!shownTask">
          <div class="line-top"></div>
          <div class="container-justified-with-margin">
            <div class="default-text" translate>common_ShowingMaxTheLast10Results</div>
            <cv-button
              buttonType="primary"
              style="overflow: hidden"
              [text]="'common_SeeAllResults' | translate"
              (click)="closeNotificationsPanel(); navigate('/management/tasksProgress')"
              leftIcon="visibility"
            />
          </div>
        </ng-container>
      </div>
    </div>

    <div
      class="filters toolbar-action-container-back"
      [class.active]="showFilters"
      [class.right-border]="showNotifications"
      *ngIf="showFilterTab"
    >
      <div class="toolbar-action-container" [class.active]="showFilters" (click)="onShowFilters()" cv-data-test="show-filters">
        <mat-icon
          [style.color]="
            !!selectedGlobalFilter || !!selectedLicense || !!selectedUserGroups?.length || !!selectedCustomMenus?.length || !!selectedDemoModeSkus?.length
              ? '#FDB82A'
              : 'rgb(255, 255, 255 / 70%)'
          "
          class="toolbar-action material-icons"
          >filter_alt</mat-icon
        >
      </div>
      <div class="right-panel" *ngIf="showFilters">
        <div class="toolbar-panel-title">
          <div style="flex: 1; font-size: 14px; font-weight: 600" translate>common_Filter</div>
          <cv-close-button (click)="showFilters = false" cv-data-test="close-filters-panel"></cv-close-button>
        </div>
        <div class="p-20" style="flex: 1; display: flex; flex-direction: column; overflow: auto">
          <app-demo-experience-filter
            *ngIf="showDemoMode"
            [activatedDemoMode]="activatedDemoMode"
            [portalSkus]="selectedDemoModeSkus || []"
          ></app-demo-experience-filter>
          <div style="margin-top: 20px">
            <div style="display: inline">
              <span translate style="font-size: 14px; font-weight: 600">common_GlobalFilter</span>
              <mat-icon
              [tooltip]="'common_GlobalFilterInfo' | translate"
              class="m-l-5 info material-icons-outlined info-button"
              >info</mat-icon>
            </div>
            <cv-autocomplete-input
              (selectionChanged)="globalFilterChanged($event)"
              (inputChange)="globalFilterInputChanged($event)"
              [ngModel]="selectedGlobalFilter"
              [forceSelection]="true"
              [suggestions]="globalFilters"
              [emptyLabel]="originalGlobalFilters?.length ? '' : ('common_NoFilterAvailable' | translate)"
              [emptySuggestion]="originalGlobalFilters?.length ? '' : ('common_NoFiltersWarning' | translate)"
              style="display: block; width: 100%; margin: 10px 0px"
              [label]="'common_Select' | translate"
            ></cv-autocomplete-input>
            <div style="display: flex; justify-content: flex-end">
              <cv-button
                class="m-r-10"
                (click)="selectedGlobalFilter = null; globalFilterChanged(null)"
                buttonType="tertiary"
                color="alert"
                leftIcon="close"
                [text]="'common_Clear' | translate"
              ></cv-button>
            </div>
          </div>
          <div *ngIf="(!!selectedLicense || isGlobalLicenses()) && showLPVT" style="margin-top: 20px">
            <div style="display: inline">
              <span translate style="font-size: 14px; font-weight: 600">common_FilterByLicensePool</span>
            </div>
            <cv-autocomplete-input
              (selectionChanged)="licensePoolChanged($event)"
              (inputChange)="licensePoolInputChanged($event)"
              [forceSelection]="true"
              [ngModel]="selectedLicense"
              [suggestions]="licensePools"
              [emptyLabel]="originalLicensePools?.length ? '' : ('common_NoFilterAvailable' | translate)"
              [emptySuggestion]="originalLicensePools?.length ? '' : ('common_NoFiltersWarning' | translate)"
              style="display: block; width: 100%; margin: 10px 0px"
              [label]="'common_Select' | translate"
            ></cv-autocomplete-input>
            <div style="display: flex; justify-content: flex-end">
              <cv-button
                class="m-r-10"
                (click)="selectedLicense = null; licensePoolChanged(null)"
                buttonType="tertiary"
                color="alert"
                leftIcon="close"
                [text]="'common_Clear' | translate"
              ></cv-button>
              <cv-button
                (click)="showFilters = false; navigate('administrations/manage-license-pools')"
                leftIcon="settings"
                [text]="'common_Manage' | translate"
              ></cv-button>
            </div>
          </div>

          <div *ngIf="((selectedUserGroups || []).length > 0 || isAdmin()) && showLPVT" style="margin-top: 20px">
            <div style="display: flex; align-items: center;">
              <span translate style="font-size: 14px; font-weight: 600">common_FilterByVirtualTenants</span>
              <mat-icon *ngIf="showVirtualTenantsSubfilter" [tooltip]="'common_FilterByVirtualTenantsSubfilterInfo' | translate" class="m-l-5 info material-icons-outlined info-button">info</mat-icon>
            </div>
            <cv-multi-input
              [disabled]="!isAdmin()"
              (ngModelChange)="userGroupsChanged($event)"
              (inputChange)="userGroupsInputChanged($event)"
              [ngModel]="selectedUserGroups"
              [suggestions]="userGroups"
              [forceSelection]="true"
              [emptyLabel]="originalUserGrops?.length ? '' : ('common_NoFilterAvailable' | translate)"
              [emptySuggestion]="originalUserGrops?.length ? '' : ('common_NoFiltersWarning' | translate)"
              style="display: block; width: 100%; margin: 10px 0px"
              [label]="'common_Select' | translate"
            ></cv-multi-input>
            <cv-autocomplete-input
              *ngIf="showVirtualTenantsSubfilter"
              (selectionChanged)="virtualTenantsSubfilterChanged($event)"
              (inputChange)="virtualTenantsSubfilterInputChanged($event)"
              [ngModel]="selectedVirtualTenantsSubfilter"
              [forceSelection]="true"
              [suggestions]="virtualTenantsSubfilters"
              [emptyLabel]="originalVirtualTenantsSubfilters?.length ? '' : ('common_NoFilterAvailable' | translate)"
              [emptySuggestion]="originalVirtualTenantsSubfilters?.length ? '' : ('common_NoFiltersWarning' | translate)"
              style="display: block; width: 100%; margin: 10px 0px"
              [label]="'common_FilterByVirtualTenants' | translate"
            ></cv-autocomplete-input>
            <div *ngIf="isAdmin()" style="display: flex; justify-content: flex-end">
              <cv-button
                class="m-r-10"
                (click)="selectedUserGroups = null; userGroupsChanged(null)"
                buttonType="tertiary"
                color="alert"
                leftIcon="close"
                [text]="'common_Clear' | translate"
              ></cv-button>
              <cv-button
                (click)="showFilters = false; navigate('administrations/v-tenants')"
                leftIcon="settings"
                [text]="'common_Manage' | translate"
              ></cv-button>
            </div>
          </div>

          <div *ngIf="((selectedCustomMenus || []).length > 0 || isAdmin()) && showPermissions" style="margin-top: 20px">
            <div style="display: inline">
              <span translate style="font-size: 14px; font-weight: 600">common_FilterByPermission</span>
            </div>
            <cv-multi-input
              [disabled]="!isAdmin()"
              (ngModelChange)="customMenusChanged($event)"
              (inputChange)="customMenusInputChanged($event)"
              [ngModel]="selectedCustomMenus"
              [suggestions]="customMenus"
              [forceSelection]="true"
              [emptyLabel]="originalCustomMenus?.length ? '' : ('common_NoFilterAvailable' | translate)"
              [emptySuggestion]="originalCustomMenus?.length ? '' : ('common_NoFiltersWarning' | translate)"
              style="display: block; width: 100%; margin: 10px 0px"
              [label]="'common_Select' | translate"
            ></cv-multi-input>
            <div *ngIf="isAdmin()" style="display: flex; justify-content: flex-end">
              <cv-button
                class="m-r-10"
                (click)="selectedCustomMenus = null; customMenusChanged(null)"
                buttonType="tertiary"
                color="alert"
                leftIcon="close"
                [text]="'common_Clear' | translate"
              ></cv-button>
              <cv-button
                (click)="showFilters = false; navigate('administrations/permissions')"
                leftIcon="settings"
                [text]="'common_Manage' | translate"
              ></cv-button>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: flex-end; padding: 12px 20px; box-shadow: 0px -1px 4px rgba(116, 112, 112, 0.2)">
          <cv-button
            [disabled]="!isAdmin()"
            (click)="removeAllFilters()"
            buttonType="secondary"
            color="alert"
            leftIcon="close"
            [text]="'common_ClearAllFilters' | translate"
          ></cv-button>
        </div>
      </div>
    </div>

    <div style="width: 10px; height: 50px" [class.right-border]="showFilters" *ngIf="showFilterTab">
      <div class="toolbar-action-container"></div>
    </div>

    <span class="toolbar-userdata">
      <div style="width: 10px; height: 50px" [class.left-border]="showUserMenu">
        <div class="toolbar-action-container"></div>
      </div>
      <div class="userMenu" [class.active]="showUserMenu">
        <div
          class="toolbar-action-container"
          [class.active]="showUserMenu"
          (click)="showUserMenu = !showUserMenu"
          style="display: flex; flex-direction: row; align-items: center"
          cv-data-test="show-user-menu"
        >
          <mat-icon class="toolbar-action material-icons">account_circle</mat-icon>
          <span style="display: block; max-width: 100px; min-width: 100px; text-overflow: ellipsis; overflow: hidden; font-size: 14px">
            <small class="text-color">{{ 'common_Greetings' | translate }}</small>
            <small class="text-color" cv-data-test="toolbar-firstname">{{ (loggedUser$ | async)?.firstName }}</small>
          </span>
        </div>
        <div class="right-panel" *ngIf="showUserMenu">
          <div class="toolbar-panel-title">
            <div class="right-menu-info-container">
              <mat-icon style="color: #306ac8" class="material-icons material-icons-large">account_circle</mat-icon>
              <div class="text-container foreground-color">
                <div *ngIf="loggedUser$ | async" class="first-line" cv-data-test="toolbar-fullname">
                  {{ (loggedUser$ | async)?.firstName + ' ' + (loggedUser$ | async)?.lastName }}
                </div>
                <div style="padding-right: 10px" class="second-line foreground-color" cv-data-test="toolbar-email">
                  {{ (loggedUser$ | async)?.userName }}
                </div>
              </div>
            </div>
            <cv-close-button (click)="showUserMenu = false" cv-data-test="close-profile-panel"></cv-close-button>
          </div>
          <div style="margin: 10px 0">
            <mat-option (click)="showUserMenu = false" [routerLink]="['/administrations/me', { id: userId }]" class="my-profile"
              ><mat-icon>person</mat-icon>{{ 'common_MyProfile' | translate }}</mat-option
            >
          </div>
          <span class="right-menu-divider"></span>
          <div style="margin: 10px 0 10px 15px; display: flex; align-items: center;">
            <cv-switch [value]="darkMode" (checkedChange)="toggleDarkMode()">{{ 'common_DarkTheme' | translate }}</cv-switch>
          </div>
          <span class="right-menu-divider"></span>
          <div style="margin: 10px 0">
            <mat-option (click)="logout()" class="logout" cv-data-test="toolbar-logout">
              <mat-icon>logout</mat-icon>
              Logout
            </mat-option>
            <mat-option (click)="logoutAzure()" class="logout" cv-data-test="toolbar-logout">
              <mat-icon>logout</mat-icon>
              Logout Azure
            </mat-option>
          </div>
        </div>
      </div>
    </span>
    <div style="width: 10px; height: 50px" [class.right-border]="showUserMenu">
      <div class="toolbar-action-container"></div>
    </div>
  </div>
</mat-toolbar>
<div *ngIf="showFilters || showUserMenu || showTenants || showNotifications || showManagement || showUniversalSearch" class="overlay-panel"></div>

/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input } from '@angular/core'
import { Constants, WorkflowExecutionStatus } from '@app/shared/utilities/constants'
import { TranslateHelper } from '@coreview/coreview-library'


@Component({
  selector: 'app-execution-status-badge',
  template: ` <cv-badge
    [variant]="getVariant(status)"
    [text]="getText(status)"
    [leftIcon]="getIcon(status)"
    [hideBorder]="true"
  ></cv-badge>`,
})
export class ExecutionStatusBadgeComponent {
  @Input() status = 'NotStarted'

  constructor(private translateHelper: TranslateHelper) {}

  getVariant = (status: string) => Constants.Workflows.statusVariantMap[status as WorkflowExecutionStatus] ?? Constants.Workflows.statusVariantMap.NotStarted

  getIcon = (status: string|null) => Constants.Workflows.statusIconMap[status as WorkflowExecutionStatus] ?? null

  getText = (status: string) => {
    const text = this.translateHelper.instant(Constants.Workflows.statusTextMap[status as WorkflowExecutionStatus] ?? status)
    return text ?? this.translateHelper.instant(Constants.Workflows.statusTextMap.NotStarted as string)
  }
}

import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { getReportDefinitionById, getReportDefinitionByIdInvalidate,
    getReportDefinitionByIdResponse, getReportsDefinition, getReportsDefinitionResponse } from './reports-definition.actions'
import { ReportsDefinitionState } from './reports-definition.types'

export const reportsDefinitionAdapter: EntityAdapter<ReportDefinition> = createEntityAdapter<ReportDefinition>({
    selectId: ({ entity }) => entity || '-'
})

export const initialState: ReportsDefinitionState = reportsDefinitionAdapter.getInitialState({
    didInvalidate: false,
    isFetching: false,
    error: null,
})

export const reportsDefinitionReducer = createReducer(
    initialState,
    on(getReportsDefinition,(state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
    on(getReportsDefinitionResponse, (state, { reports }) => {
        const managed = reports.map(({ entity }) => entity)
        const nextState = { ...state, isFetching: false, managed }
        return reportsDefinitionAdapter.setAll(reports, nextState)
      }),


    on(getReportDefinitionById, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
    on(getReportDefinitionByIdResponse, (state, reportsDefinition) => reportsDefinitionAdapter.setOne(reportsDefinition, state)),
    on(getReportDefinitionByIdInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true })),
  )

export default reportsDefinitionReducer

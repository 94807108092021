import { RightPanelRef } from '@app/core/services/right-panel.service'
import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { ReportsService } from '@app/core/services/reports.service'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Component, OnInit } from '@angular/core'
import { Constants } from '@app/shared/utilities/constants'
import { SavedReport } from '@app/core/models/saved-report'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { TranslateHelper } from '@coreview/coreview-library'
import { loadFavorites } from '@app/store/menu/menu.actions'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { ToastService } from '@coreview/coreview-components'
import { ApplicationInsightService } from '@app/core/services/application-insight.service'
import { SharedHelperService } from '@app/shared/shared.helper.service'

@Component({
  selector: 'app-save-view',
  templateUrl: './save-view.component.html',
  styleUrls: ['./save-view.component.sass'],
})
export class SaveViewComponent implements OnInit {
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl(''),
    isFavourite: new UntypedFormControl(false),
    isShared: new UntypedFormControl(false),
    columnsLocked: new UntypedFormControl(false),
    overwriteExistingReport: new UntypedFormControl(false),
    predefinedReport: new UntypedFormControl(false),
    isComplianceReport: new UntypedFormControl(false),
  })

  lastRequest!: any | null
  reportDefinition!: ReportDefinition | null
  route!: string | null
  savedReport?: SavedReport
  isSharedDisabled = false
  isSaving = false
  pivotSettings: any | null
  isPivot = false

  constructor(
    private rightPanelRef: RightPanelRef,
    private reportsService: ReportsService,
    private localstorageService: LocalstorageService,
    private translateHelper: TranslateHelper,
    private store: Store<RootState>,
    private toastService: ToastService,
    private appInsights: ApplicationInsightService,
    private sharedHelperService: SharedHelperService
  ) {}

  ngOnInit(): void {
    if (
      !!this.savedReport &&
      this.savedReport.userName === this.localstorageService.getLoggedUser().userName &&
      !this.savedReport.isComplianceReport
    ) {
      this.form.get('overwriteExistingReport')?.setValue(true)
    } else {
      this.form.get('overwriteExistingReport')?.disable()
    }

    if (this.savedReport) {
      this.form.get('name')?.setValue(this.savedReport.reportName)
      this.form.get('description')?.setValue(this.savedReport.description)
      this.form.get('columnsLocked')?.setValue(this.savedReport.columnsLocked)
      this.form.get('isShared')?.setValue(this.savedReport.isShared)
      this.form
        .get('isFavourite')
        ?.setValue(!!this.savedReport.usersFavourite.find((x) => x === this.localstorageService.getLoggedUser().userName))
      const isPredefined = !!this.savedReport.tags && this.savedReport?.tags.indexOf('PredefinedSavedReport') >= 0
      this.form.get('predefinedReport')?.setValue(isPredefined)
      this.form.get('isComplianceReport')?.setValue(this.savedReport.isComplianceReport)
      if (
        !!this.form.get('overwriteExistingReport')?.value &&
        this.savedReport.schedulations?.length > 0 &&
        this.savedReport.schedulations.some((x) => !!x.isShared)
      ) {
        this.form.get('isShared')?.disable()
      }
    } else {
      this.form.get('columnsLocked')?.disable()
      if (!!this.reportDefinition && !!this.reportDefinition.title) {
        this.form.get('name')?.setValue(this.translateHelper.instant(this.reportDefinition.title))
      }
    }

    const sharedController = this.form.get('isShared')
    sharedController?.valueChanges.subscribe((x) => {
      if (sharedController.value) {
        this.form.get('columnsLocked')?.enable()
      } else {
        this.form.get('columnsLocked')?.disable()
      }

      if (!this.savedReport || !!this.isAdmin()) {
        this.form.get('columnsLocked')?.setValue(false)
      }
    })
    const overwriteExistingReportController = this.form.get('overwriteExistingReport')
    overwriteExistingReportController?.valueChanges.subscribe((x) =>
      !overwriteExistingReportController.value
        ? this.form.get('isShared')?.enable()
        : this.savedReport && this.savedReport.schedulations?.length > 0 && this.savedReport.schedulations.some((s) => !!s.isShared)
        ? this.form.get('isShared')?.disable()
        : this.form.get('isShared')?.enable()
    )
  }

  close() {
    this.rightPanelRef.close()
  }

  save(): void {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      return
    }
    this.isSaving = true
    const formValue = this.form.value
    const overwriteSavedReport = !!this.savedReport?.guid && !!formValue.overwriteExistingReport
    const report = {
      request: {
        ...(this.lastRequest || {}),
        savedReportParams: {
          reportName: (formValue.name as string).trim(),
          description: formValue.description,
          columnsLocked: formValue.columnsLocked,
          tags: [],
          reportTreeFilters: [],
          groupMembershipFilter: null,
          reportUrl: this.route,
          isShared: formValue.isShared,
          isComplianceReport: false,
          schedulations: [],
          guid: overwriteSavedReport ? this.savedReport?.guid : null,
          visibility: this.savedReport?.visibility,
        },
      },
      isFavourite: formValue.isFavourite,
      columnsLocked: formValue.columnsLocked,
      tags: [],
      category: Constants.savedReportsCategories.PortalV2,
      pivotSettings: this.pivotSettings,
      isPivot: this.isPivot,
      resultItem: this.reportDefinition?.responseItemProp,
      targetEntity: this.reportDefinition?.targetEntity,
      isMapOpen: false,
      schedulations: overwriteSavedReport ? this.savedReport?.schedulations : [],
    }
    this.reportsService.saveReport(report, overwriteSavedReport).subscribe(
      () => {
        this.isSaving = false
        if (formValue.isFavourite) {
          this.store.dispatch(loadFavorites())
        }
        this.rightPanelRef.close(true)
        this.toastService.open({
          id: 'succ',
          variant: 'success',
          title: this.translateHelper.instant('common_Success'),
          message: this.translateHelper.combineTranslations(
            !overwriteSavedReport ? 'generic_ObjectSuccessfullyCreated' : 'generic_ObjectSuccessfullyModified',
            'reports_CustomReport',
            formValue.name
          ),
        })
      },
      (err) => {
        this.isSaving = false
        this.toastService.open({
          id: 'err',
          variant: 'error',
          title: this.translateHelper.instant('common_Error'),
          message: this.translateHelper.combineTranslations(
            !overwriteSavedReport ? 'generic_ObjectCouldNotBeCreated' : 'generic_ObjectCouldNotBeModified',
            'reports_CustomReport',
            formValue.name
          ),
        })
        this.appInsights.trackError(err)
      }
    )
  }

  isPortalAdmin = (): boolean => this.sharedHelperService.isPortalAdmin()

  isAdmin = (): boolean => this.sharedHelperService.isAdmin()
}

/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core'
import { Actions, ofType, createEffect } from '@ngrx/effects'
import { debounceTime, filter, map, withLatestFrom } from 'rxjs/operators'
import * as LPCActions from './license-pool-center.actions'
import { RootState } from '../RootState.type'
import { Store } from '@ngrx/store'
import { updateUserSettingsExtra, updateUserSettingsExtraLive } from '../userSettings/userSettings.actions'
import { selectLicensePoolCenterState } from './license-pool-center.selectors'

@Injectable()
export class LicensePoolCenterEffects {

  updateUserSettingsExtraLive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LPCActions.setLeftPanelIsOpen,
        LPCActions.setLeftPanelReport,
        LPCActions.setGroupedBySkuAreAllRowsExpanded,
        LPCActions.setGroupedByPoolsAreAllRowsExpanded,
        LPCActions.setOverAllocatedAreAllRowsExpanded,
        LPCActions.setOutOfCapacityAreAllRowsExpanded,
        LPCActions.setShowOnlyPoolsWithAllocation,
        LPCActions.setSelectedLicenses,
      ),
      withLatestFrom(this.store.select(selectLicensePoolCenterState)),
      filter(([_, extraState]) => !extraState.isSavedReport),
      map(([_, extraState]) =>  updateUserSettingsExtraLive({ key: 'licensePoolCenter', value: extraState }))
    )
  )

  updateUserSettingsExtra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserSettingsExtraLive),
      debounceTime(1000),
        withLatestFrom(this.store.select(selectLicensePoolCenterState)),
        filter(([_, extraState]) => !extraState.isSavedReport),
        map(([_, extraState]) =>  {
          const modifiedExtraState = { ...extraState, isOpenInFull: false };
          return updateUserSettingsExtra({ key: 'licensePoolCenter', value: modifiedExtraState });
        })
    )
  )

  constructor(
    private actions$: Actions,
    private store: Store<RootState>
  ) {}

}

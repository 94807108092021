import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { pluck } from 'rxjs/operators'
import { ItemsContainer } from '../models/ItemsContainer'
import { ManagedTenant } from '../models/ManagedTenant'
import { ManagementResponse, UpdateManagenentRes } from '../models/management-response'
import { ManagementTypeResponse } from '../models/ManagementTypeResponse'
import { NamingConvetionRule } from '../models/NamingConventionRule'
import {
  ExtensionAttributesMapping,
  MultiforestServerSettings,
  OnPremisesImportSettings,
  OnPremisesSettings,
  Organization,
  ServiceAccountRes,
} from '../models/Organization'
import { ResponseStatus } from '../models/ServerResponse'
import { Sku } from '../models/Sku'
import { ServiceNowConfiguration } from './../models/ServiceNowConfiguration'
import { ServiceNowIntegration } from './../models/ServiceNowIntegration'
import { ApiclientService } from './apiclient.service'

interface ManagedTenantsResponse {
  readonly defaultTenant: ManagedTenant;
  readonly managedTenants: ManagedTenant[];
}

const toApiUpdatingData = ({ guid, ...rest }: Partial<Organization>) => ({ companyId: guid, ...rest } as any)

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private apiClient: ApiclientService, private httpClient: HttpClient) {}

  /**
   * Retrieve organization data by id
   */
  getOrganization(orgId: string): Observable<Organization> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${orgId}`

    return this.httpClient.get<{ organization: Organization }>(url, { withCredentials: true }).pipe(pluck('organization'))
  }

  getManagementType(orgId: string): Observable<ManagementTypeResponse> {
    const url = `${this.apiClient.basePortalApiUrl}/management/${orgId}/managementType`
    return this.httpClient.get<ManagementTypeResponse>(url, { withCredentials: true })
  }

  updateNewUxSettings(data: Partial<Organization>): Observable<boolean> {
    const url = `${this.apiClient.basePortalApiUrl}/organizationsku`
    const nextData = toApiUpdatingData(data)

    return this.httpClient.put<boolean>(url, nextData, { withCredentials: true })
  }

  /**
   * Receives a partial of type Organization and performs a PUT request to the /settings url
   * to update the general settings
   */
  updateGeneralSettings(data: Partial<Organization>): Observable<boolean> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/settings`
    const nextData = toApiUpdatingData(data)

    return this.httpClient.put<boolean>(url, nextData, { withCredentials: true })
  }

  /**
   * Receives a partial of type Organization and performs a PUT request to the /vtenantsettings url
   * to update the virtual tenants settings
   */
  updateVirtualTenants(data: Partial<Organization>): Observable<boolean> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/vtenantsettings`
    const nextData = toApiUpdatingData(data)
    nextData.enableVTenantExclusionForTeamsGroups = nextData.isVTenantExclusionForTeamsGroupsEnabled

    return this.httpClient.put<boolean>(url, nextData, { withCredentials: true })
  }

  /**
   * Receives a partial of type Organization and performs a PUT request to the /email/settings url
   * to update the email settings
   */
  updateMailSettings(data: Partial<Organization>): Observable<boolean> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/email/settings`
    const nextData: any = toApiUpdatingData(data)

    // TODO: double check the following condition, it is implemented in the "old portal" but I'm not totally sure about it
    if (nextData.mailProvider === 'SendGrid') {
      nextData.customerOf = '4Ward.it'
    }

    return this.httpClient.put<boolean>(url, nextData, { withCredentials: true })
  }

  updateManagementActions(data: Partial<Organization>): Observable<boolean> {
    const url = `${this.apiClient.basePortalApiUrl}/management/${data.guid}/actions/notesfield/mandatory`
    const nextData: any = toApiUpdatingData(data)
    nextData.mandatory = data.managementActionNotesFieldMandatory
    return this.httpClient.put<boolean>(url, nextData, { withCredentials: true })
  }

  getOrganizationCheck() {
    return this.httpClient.get<any>(`${this.apiClient.basePortalApiUrl}/organization/check`, { withCredentials: true })
  }

  /**
   * Retrieves the managed tenants
   */
  getManagedTenants(): Observable<ManagedTenantsResponse> {
    const url = `${this.apiClient.basePortalApiUrl}/managedtenants/?metadata=true&useSelectedOrganization=false`

    return this.httpClient.get<ManagedTenantsResponse>(url, { withCredentials: true })
  }

  getOrganizationSettings(orgId: string) {
    const url = `${this.apiClient.basePortalApiUrl}/organization/settings/?companyId=${orgId}`

    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  getKnowledgeBase(fileName: string, reportNameContainer: string): Observable<{ fileBody: string; fileFormat: string }> {
    const url = `${this.apiClient.basePortalApiUrl}/knowledgeBase`

    return this.httpClient.get<{ fileBody: string; fileFormat: string }>(url, {
      withCredentials: true,
      params: { fileName, reportNameContainer },
    })
  }

  /**
   *
   * @param tenantId
   */
  getSelectedTenantApiUrl(tenantId: string): Observable<{ dataCenter: string; apiUrl: string }> {
    const url = `${this.apiClient.basePortalApiUrl}/selectedtenant/apiurl/${tenantId}`

    return this.httpClient.get<{ dataCenter: string; apiUrl: string }>(url, { withCredentials: true })
  }

  putTenantSession(companyId: string): Observable<null> {
    const url = `${this.apiClient.basePortalApiUrl}/selectedtenant/`

    return this.httpClient.put<null>(url, { companyId }, { withCredentials: true })
  }

  /**
   * Returns SKUs of an organization
   *
   * @param orgId - The current organization id
   *
   * @return Array of SKUs
   */
  getOrganizationSkus(orgId: string): Observable<Array<string>> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/skus/${orgId}`
    return this.httpClient.get<Array<string>>(url, { withCredentials: true })
  }

  getPortalSkus(subscriptionLevel: string, skusId: string[]): Observable<Sku[]> {
    const url = `${this.apiClient.basePortalApiUrl}/portalskus`
    return this.httpClient.get<Sku[]>(url, { withCredentials: true, params: { subscriptionLevel, skusId }})
  }

  updateManagementType(params: any): Observable<UpdateManagenentRes> {
    const url = `${this.apiClient.basePortalApiUrl}/management/updatemanagementtype/`
    return this.httpClient.post<UpdateManagenentRes>(url, params, { withCredentials: true })
  }

  setAutomaticSessionEnabled(params: { companyId: string; autoEnableManagementSession: boolean }): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/management/automaticsession/`
    return this.httpClient.put<void>(url, params, { withCredentials: true })
  }

  getServiceNowConfiguration(orgId: string): Observable<ServiceNowIntegration> {
    const url = `${this.apiClient.serviceNowUrl}/serviceNow/companies/integration/`
    return this.httpClient.get<ServiceNowIntegration>(url, {
      withCredentials: true,
      headers: { 'x-scompany': orgId },
    })
  }

  deleteServiceNowConfiguration(orgId: string): Observable<void> {
    const url = `${this.apiClient.serviceNowUrl}/serviceNow/companies/integration/`
    return this.httpClient.delete<void>(url, {
      withCredentials: true,
      headers: { 'x-scompany': orgId },
    })
  }

  registerServiceNow(orgId: string, params: ServiceNowConfiguration): Observable<{ uri: string }> {
    const url = `${this.apiClient.serviceNowUrl}/serviceNow/register`
    return this.httpClient.post<{ uri: string }>(url, params, {
      withCredentials: true,
      headers: { 'x-scompany': orgId },
    })
  }

  getNamingConventionRules(companyId: string): Observable<NamingConvetionRule[]> {
    const url = `${this.apiClient.basePortalApiUrl}/namingConventionRules?companyId=${companyId}`
    return this.httpClient
      .get<ItemsContainer<NamingConvetionRule>>(url, {
        withCredentials: true,
      })
      .pipe(pluck('items'))
  }

  getNamingConventionRulesContexts(): Observable<string[]> {
    const url = `${this.apiClient.basePortalApiUrl}/namingConventionRules/contexts`
    return this.httpClient
      .get<ItemsContainer<string>>(url, {
        withCredentials: true,
      })
      .pipe(pluck('items'))
  }

  getNamingConventionRulesFieldNames(context: string, companyId: string): Observable<string[]> {
    const url = `${this.apiClient.basePortalApiUrl}/namingConventionRules/context/${context}/fieldnames?companyId=${companyId}`
    return this.httpClient
      .get<ItemsContainer<string>>(url, {
        withCredentials: true,
      })
      .pipe(pluck('items'))
  }

  saveNamingConventionRule(rule: NamingConvetionRule): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/namingConventionRules`
    return rule.id
      ? this.httpClient.put<void>(url, rule, {
          withCredentials: true,
        })
      : this.httpClient.post<void>(url, rule, {
          withCredentials: true,
        })
  }

  deleteNamingConventionRule(ruleId: string): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/namingConventionRules/${ruleId}`
    return this.httpClient.delete<void>(url, {
      withCredentials: true,
    })
  }

  updateSecureByDefault(data: Partial<Organization>): Observable<boolean> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/secureByDefault`
    const nextData: any = toApiUpdatingData(data)

    return this.httpClient.put<boolean>(url, nextData, {
      withCredentials: true,
    })
  }

  updateServiceAccountPswRotation(data: {
    companyId: string;
    enableAutomaticRotation?: boolean;
    force?: boolean;
    days?: number;
  }): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/adminreadonlypasswordrotation`
    return this.httpClient.post<void>(url, data, {
      withCredentials: true,
    })
  }

  getManagementSession(): Observable<ManagementResponse> {
    return this.httpClient.get<ManagementResponse>(`${this.apiClient.basePortalApiUrl}/management/session/`, { withCredentials: true })
  }

  startManagementSession(
    companyId: string,
    params: Partial<{
      adminPasswordOffice365: string;
      adminUsernameOffice365: string;
      companyId: string;
      setAutomaticSessionEnabled: boolean;
      useDifferentAccount: boolean;
    }>
  ): Observable<void> {
    return this.httpClient.post<void>(`${this.apiClient.basePortalApiUrl}/management/${companyId}/session/`, params, {
      withCredentials: true,
    })
  }

  closeManagementSession(): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiClient.basePortalApiUrl}/management/session/`, {
      withCredentials: true,
    })
  }

  updateNotifyByEmailOnOperatorAdded(companyId: string, enable: boolean): Observable<boolean> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/notifybyemailonoperatoradded/`
    return this.httpClient.put<boolean>(url, { companyId, enable }, { withCredentials: true })
  }

  //#region EXTENSION ATTRIBUTES
  getExtensionAttributes(companyId: string): Observable<ExtensionAttributesMapping[]> {
    const url = `${this.apiClient.basePortalApiUrl}/PortalAttributesMapping`
    return this.httpClient.get<any>(url, { withCredentials: true, params: { companyId }}).pipe(pluck('portalAttributesMapping'))
  }

  getExtensionAttributesTypeLimitMapping() {
    const url = `${this.apiClient.basePortalApiUrl}/PortalAttributesMappingTypeLimit`
    return this.httpClient.get<any>(url, { withCredentials: true }).pipe(pluck('typeLimits'))
  }

  updateExtensionsAttributesMapping(data: any): Observable<boolean> {
    const url = `${this.apiClient.basePortalApiUrl}/portalAttributesMapping`
    return this.httpClient.post<any>(url, data, { withCredentials: true })
  }
  //#endregion

  //#region ON PREMISES

  getOnPremisesSettings(companyId: string): Observable<OnPremisesSettings> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/configuration`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  getOnPremisesConfigurationType(companyId: string): Observable<{ isMultiforest: boolean }> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/typeconfiguration`
    return this.httpClient.get<{ isMultiforest: boolean }>(url, { withCredentials: true })
  }

  switchOnpremisesConfigurationType(companyId: string): Observable<ResponseStatus> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/switch`
    return this.httpClient.post<ResponseStatus>(url, { companyId }, { withCredentials: true })
  }

  saveOnPremisesSettings(
    companyId: string,
    isNew: boolean,
    settings: Pick<OnPremisesSettings, 'activeDirectorySettings' | 'exchangeSettings'>
  ): Observable<ResponseStatus> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/configure`
    if (isNew) {
      return this.httpClient.post<ResponseStatus>(url, settings, { withCredentials: true })
    } else {
      return this.httpClient.put<ResponseStatus>(url, settings, { withCredentials: true })
    }
  }

  deleteOnPremisesSettings(companyId: string): Observable<ResponseStatus> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/configuration`
    return this.httpClient.delete<ResponseStatus>(url, { withCredentials: true })
  }

  getOnPremisesImportSettings(companyId: string): Observable<OnPremisesImportSettings> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/import/settings`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  saveOnPremisesImportSettings(companyId: string, importSettings: any): Observable<OnPremisesImportSettings> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/import/settings`
    return this.httpClient.put<any>(url, importSettings, { withCredentials: true })
  }

  getMultiForestSettings(companyId: string): Observable<OnPremisesSettings> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/multiforest/`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  getMultiforestMapping(companyId: string): Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/multiforest/mappingou`
    return this.httpClient.get<any>(url, { withCredentials: true }).pipe(pluck('mappingOrganizationalUnits'))
  }

  saveMultiforestMapping(companyId: string, mapping: any): Observable<ResponseStatus> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/multiforest/managemappingou`
    return this.httpClient.post<ResponseStatus>(url, mapping, { withCredentials: true })
  }

  saveMultiforestServer(
    companyId: string,
    serverToAdd: { adChannelTypeSettingsDto?: MultiforestServerSettings[]; exChannelTypeSettingsDto?: MultiforestServerSettings[] }
  ) {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/multiforest/addchannel`
    return this.httpClient.post<ResponseStatus>(url, serverToAdd, { withCredentials: true })
  }

  removeMultiforestServer(
    companyId: string,
    serverToRemove: { adChannelTypeSettingsDto?: MultiforestServerSettings[]; exChannelTypeSettingsDto?: MultiforestServerSettings[] }
  ) {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/multiforest/removeservers`
    return this.httpClient.post<ResponseStatus>(url, serverToRemove, { withCredentials: true })
  }

  tryToOpenSessionOnPremises(companyId: string, isMultiforest: boolean) {
    const url = isMultiforest
      ? `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/multiforest/trytoopen`
      : `${this.apiClient.basePortalApiUrl}/organization/${companyId}/tryopenpowershellconnection`
    return this.httpClient.post<ResponseStatus>(url, { companyId }, { withCredentials: true })
  }

  generateMultiforestApiKey(companyId: string): Observable<ResponseStatus> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/multiforest/generate/apikey`
    return this.httpClient.post<ResponseStatus>(url, { companyId }, { withCredentials: true })
  }

  deleteMultiForestSettings(companyId: string): Observable<ResponseStatus> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/multiforest/delete`
    return this.httpClient.post<ResponseStatus>(url, { companyId }, { withCredentials: true })
  }

  saveOnPremSourceAnchor(companyId: string, sourceAnchor?: string): Observable<ResponseStatus> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/sourceanchor`
    return this.httpClient.post<ResponseStatus>(url, { companyId, sourceAnchor }, { withCredentials: true })
  }

  //#endregion

  getServiceAccount(): Observable<ServiceAccountRes> {
    const url = `${this.apiClient.basePortalApiUrl}/management/serviceaccount`
    return this.httpClient.get<ServiceAccountRes>(url, { withCredentials: true })
  }

  updateNumberPool(enableNumberPool: boolean): Observable<null> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/numberpool`
    return this.httpClient.patch<null>(url, { enableNumberPool }, { withCredentials: true })
  }

  setManagedSupportAccess(allowManagedSupportAccess: boolean): Observable<null> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/setmanagedsupportaccess`
    return this.httpClient.patch<null>(url, { allowManagedSupportAccess }, { withCredentials: true })
  }
}

<div style="margin-top: 20px">
  <div style="display: inline">
    <span translate style="font-size: 14px; font-weight: 600">common_DemoExperience</span>
  </div>
  <cv-autocomplete-input
    (selectionChanged)="handleMainSkusFilterChanged($event)"
    (inputChange)="handleMainSkusFilterInputChanged($event)"
    [forceSelection]="true"
    [ngModel]="selectedMainSkusFilter"
    [suggestions]="mainSkusFilter"
    [emptyLabel]="originalMainSkus?.length ? '' : ('common_NoFilterAvailable' | translate)"
    [emptySuggestion]="originalMainSkus?.length ? '' : ('common_NoFiltersWarning' | translate)"
    style="display: block; width: 100%; margin: 10px 0px"
    [label]="'common_SelectCommercialSku' | translate"
    [disabled]="activatedDemoMode"
  ></cv-autocomplete-input>

  <cv-multi-input
    *ngIf="selectedMainSkusFilter !== null"
    (ngModelChange)="handleAddOnSkusFilterChanged($event)"
    (inputChange)="handleAddOnSkusFilterInputChanged($event)"
    [ngModel]="selectedAddOnSkusFilter"
    [suggestions]="addOnSkusFilter"
    [forceSelection]="true"
    [emptyLabel]="originalAddOnSkus?.length ? '' : ('common_NoFilterAvailable' | translate)"
    [emptySuggestion]="originalAddOnSkus?.length ? '' : ('common_NoFiltersWarning' | translate)"
    style="display: block; width: 100%; margin: 10px 0px"
    [label]="'common_SelectAddOn' | translate"
    [disabled]="activatedDemoMode"
  ></cv-multi-input>

  <div style="display: flex; justify-content: flex-end">
    <cv-button
      *ngIf="!activatedDemoMode"
      [disabled]="selectedMainSkusFilter === null"
      (click)="handleActivateDemoMode()"
      leftIcon="check"
      [text]="'common_ActivateDemoExperience' | translate"
    ></cv-button>
    <cv-button
      *ngIf="activatedDemoMode"
      [color]="'info'"
      (click)="handleExitDemoMode()"
      leftIcon="close"
      [text]="'common_ExitDemoExperience' | translate"
    ></cv-button>
  </div>
</div>

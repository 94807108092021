import { Component, Input, OnInit } from '@angular/core'
import { ApiErrorResponse, ManagementAction } from '@app/core/models/management-action'
import { BuildColParameter } from '@app/shared/utilities/build-col-ag-grid'
import { TranslateHelper } from '@coreview/coreview-library'
import { uniq } from 'lodash-es'
import { of } from 'rxjs'

@Component({
  selector: 'app-submit-error-message-detail',
  templateUrl: './submit-error-message-detail.component.html',
  styleUrls: ['./submit-error-message-detail.component.sass'],
})
export class SubmitErrorMessageDetailComponent implements OnInit {
  @Input() action!: ManagementAction
  @Input() errors!: ApiErrorResponse[]

  errorMessagesColumnDefs: BuildColParameter = {
    selectedCols: ['target', 'errorMessage'],
    allcols: [
      {
        originalName: 'target',
        name: 'target',
        translate: 'management_Targets',
        type: 'string',
        filter: { type: 'string', name: 'target' },
      },
      {
        originalName: 'errorMessage',
        name: 'errorMessage',
        translate: 'management_ErrorMessage',
        type: 'string',
        filter: { type: 'string', name: 'errorMessage' },
        agColDef: {
          cellRenderer: 'tooltipRenderer',
        },
      },
    ],
  }

  processedErrorMessages?: { target: string; errorMessage: string }[]
  errorDescription = ''

  constructor(private translateHelper: TranslateHelper) {}

  ngOnInit(): void {
    const errors = uniq(uniq(this.errors.map((x) => x.errorCode)).map((x) =>
      x === 'ManagementActionNotAllowed' ? 'management_VirtualTenantError' : 'management_ServerError'
    ))
    this.errorDescription = this.translateHelper.genericCombineTranslations('management_ActionFailedErrorDescription', undefined, {
      actionName: this.action.translate,
      errorDescription: errors.map((errKey) => this.translateHelper.instant(errKey)).join(', '),
    })
    this.processedErrorMessages = this.errors.map((err) => {
      const splittedMessage = err.message?.split('--') || []
      return {
        target: splittedMessage[0]?.trim(),
        errorMessage: splittedMessage[1]?.replace('<br/>', '')?.trim(),
      }
    })
  }

  getErrorMessages = (): any => of(this.processedErrorMessages)
}

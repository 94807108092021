import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { Role } from '@app/core/models/authenticated-user'
import { DataCenter } from '@app/core/models/data-center'
import { NavItem } from '@app/core/models/nav-item'
import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { ReportDefinition as LibReportDefinition, FilterReportColumn, SelectionAction } from '@coreview/coreview-library/models/ReportDefinition'
import { SavedReport } from '@app/core/models/saved-report'
import { ApiclientService } from '@app/core/services/apiclient.service'
import { BreadcrumbService, TranslateHelper } from '@coreview/coreview-library'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { ReportExportService } from '@app/core/services/report-export.service'
import { ReportsService } from '@app/core/services/reports.service'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { ButtonComponent, HorizontalTabGroupComponent, SmartPanelComponent, TabComponent, ToastService } from '@coreview/coreview-components'
import { of, Subject, combineLatest, Observable, timer } from 'rxjs'
import { filter, mergeMap, startWith, takeUntil, tap } from 'rxjs/operators'
import { Constants } from '@app/shared/utilities/constants'
import { NewSchedulationComponent } from '../new-schedulation/new-schedulation.component'
import { SaveViewComponent } from '../../components/save-view/save-view.component'
import { BuildColAgGrid } from '@app/shared/utilities/build-col-ag-grid'
import { cloneDeep, first, keys } from 'lodash-es'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import { selectOnlineuserColumns } from '@app/store/onlineuser-columns/onlineuser-columns.selectors'
import { ServerResponse } from '@app/core/models/ServerResponse'
import { MapConfiguration } from '../../map-configuration.interface'
import { Helpers } from '@app/shared/utilities/helpers'
import { ClientDatagridComponent } from '@app/shared/components/client-datagrid/client-datagrid.component'
import * as Highcharts from 'highcharts'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { IReportChartDefinition } from '../../report-chart-definitions'
import { AuthHelperService } from '@app/core/services/auth.helper.service'
import { GridOptions } from '@ag-grid-community/core'
import { GenericCenterService } from '../../services/generic-center.service'
import { LicensePoolService } from '@app/modules/licenses/services/license-pool.service'
import { ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper'
import { WorkflowItemsSelectionColumnsDefinition } from '@app/shared/models/workflow-target-selection-definition'
import { Policy } from '@app/core/models/playbook'
import { NewCustomPolicyComponent } from '@app/modules/playbook/components/new-custom-policy/new-custom-policy.component'
import { convertFromMembersTypeUser } from '@app/core/enums/group-type'
import { childFilter } from '@app/core/models/QueryFilter'
import { selectFavorites, selectFlatMenusAll } from '@app/store/menu/menu.selectors'
import { selectedOrganizationSkus } from '@app/store/organizations/organizations.selectors'

@Component({
  selector: 'app-generic-center',
  templateUrl: './generic-center.component.html',
  styleUrls: ['./generic-center.component.sass'],
})
export class GenericCenterComponent implements OnInit, OnDestroy {
  @ViewChildren(SmartPanelComponent) panels!: QueryList<SmartPanelComponent>

  @ViewChild(ClientDatagridComponent)
  clientGrid!: ClientDatagridComponent | undefined

  @ViewChild(DatagridComponent)
  grid!: DatagridComponent | undefined

  @ViewChildren('tab') set content(content: QueryList<TabComponent>) {
    if (content) {
      // initially setter gets called with undefined
      this.tabElement = content
    }
  }

  @ViewChild(HorizontalTabGroupComponent)
  public reportResponse$!: Observable<ServerResponse<any>> | null

  public entityName = ''
  public tabs!: any[]
  public refreshData!:
    | ((params: ApiDataParameters) => {
        items: Observable<ServerResponse<any>>
        cols: Observable<CoreViewColumn[]> | null
      })
    | undefined

  public isPivotModeActive = false
  public hideCustomReportsPanel = false
  canSeeCustomReportsBySku = true //#bug 40906

  reportDefinition!: ReportDefinition
  highcharts = Highcharts

  chartsContainer: any = {}
  dataChart: any
  data: any
  chart!: IReportChartDefinition | null
  params!: ApiDataParameters
  enablePivotMode!: boolean
  rowSelection?: 'single' | 'multiple'

  mapsData: MapConfiguration[] = []
  public pivotResponseItemProp = ''
  customParameters: any = {}
  rangeFilters!: { since?: string; to?: string; days?: number }
  customFilters: FilterReportColumn = {}
  routeParamsFilters!: Record<string, string>
  savedReportFilterSortModel?: { filterModel: any; sortModel: { sortField: string; sortOrder: 'asc' | 'desc' } }
  selectedRows: any[] = []

  menu?: NavItem
  loadedFirstTime = false
  hasCharts = false
  chartPreference = true
  allCols!: CoreViewColumn[] | undefined
  savedReportIdFilter?: string // do not use this property, it gets cleaned up after first api call
  savedReportId?: string
  savedReports: SavedReport[] = []
  metadata!: any
  savedReport!: SavedReport | null
  reportTitle?: string
  reportDescription?: string

  selectionActions: SelectionAction[] = []
  groupedActions: SelectionAction[] = []
  filterActions: SelectionAction[] = []

  loggedUser!: { userName: string; roles: Role[] }
  hideColumnsSelector = false

  gridOptionsClientDataGrid!: GridOptions
  detailCellRendererClientDataGrid!: string
  detailCellRenderParamsClientDataGrid!: any

  route!: string
  selectedDataCenter?: DataCenter
  tabSelectedIndex = 0
  selectedTab!: any

  genericCenterDefinition!: any

  pivotSelectionActions: SelectionAction[] = [
    {
      ...this.reportsComponentHelper.exportPivotAction,
      onClick: (data: any[], key: string | null) => {
        this.reportsComponentHelper.exportPivotReport(key || '', this.clientGrid, this.selectedTab.title)
      },
    },
  ]

  managementColumnDefinition!: WorkflowItemsSelectionColumnsDefinition
  isOpenPanelCustomReport = true
  policy!: Policy | undefined

  private pageDataChanged$ = new Subject<{ currentPage: number; currentPageSize: number; data: any[]; dataChart: any }>()
  private destroyed$: Subject<boolean> = new Subject()
  private tabChanged$ = new Subject()
  private tabElement!: QueryList<TabComponent>

  constructor(
    private store: Store<RootState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public storage: LocalstorageService,
    private breadcrumbService: BreadcrumbService,
    private translateHelper: TranslateHelper,
    private reportsService: ReportsService,
    private reportExportService: ReportExportService,
    private apiClient: ApiclientService,
    private rightPanelService: RightPanelService,
    private sharedHelperService: SharedHelperService,
    private authHelperService: AuthHelperService,
    private genericCenterService: GenericCenterService,
    private buildColumnsHelper: BuildColAgGrid,
    private licenseService: LicensePoolService,
    private toastService: ToastService,
    public reportsComponentHelper: ReportsComponentHelper
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.activatedRoute = activatedRoute
    this.savedReportIdFilter = this.activatedRoute.snapshot.queryParams.SavedReportId
    this.savedReportId = this.activatedRoute.snapshot.queryParams.SavedReportId
    this.loggedUser = this.storage.getLoggedUser()
    this.chartPreference = this.storage.chartDisplayPreference
  }

  isRowMaster = () => this.selectedTab.gridOptions?.masterDetail

  getClientGridData = () => {
    const params: any = { pageSize: 10000, pageNumber: 1, sortField: this.selectedTab.sortField, sortOrder: this.selectedTab.sortOrder }

    return this.getItems$(this.selectedTab.apiUrl, this.selectedTab.verb || 'get', this.getReportParams(params))
  }

  refreshTable = () => this.selectedTab.isClientDataGrid ? this.clientGrid?.refresh() : this.grid?.refresh()

  getCurrentPage = (): number => this.grid!.gridApi.paginationGetCurrentPage() + 1

  getPageSize = (): number => this.grid!.gridApi.paginationGetPageSize()

  getItems$ = (reportUrl: string, verb: Verb, params: any, dataCenterUrl?: string) => {
    this.params = params
    return (this.reportsService.getData(reportUrl, verb, Object.assign({}, params), dataCenterUrl) || of({} as ServerResponse<any>)).pipe(
      tap((x) => (this.loadedFirstTime = true))
    )
  }

  getExportedItems = (params: ApiDataParameters & { asyncExport: boolean; reportNameContainer: string }, format: string) => {
    this.params = params
    return (
      this.reportsService.getExportedData(
        this.reportDefinition?.url || '',
        this.reportDefinition?.verb || 'post',
        params,
        format,
        this.selectedDataCenter?.apiUrl
      ) || of({} as any)
    )
  }

  onlineUsersColumns = () => this.store.select(selectOnlineuserColumns)

  ngOnInit(): void {
    const event = this.router.events.pipe(
      filter((eventRes: any) => eventRes instanceof NavigationEnd),
      startWith(this.router)
    )

    this.licenseService
      .arePermissionsLoaded()
      .pipe(
        filter((x) => !!x),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        combineLatest([event, this.activatedRoute.data, this.store.select(selectedOrganizationSkus)])
          .pipe(takeUntil(this.destroyed$))
          .subscribe(([eventRes, data, orgSkus]) => {
            this.route = eventRes.url
            this.entityName = data.entity
            this.savedReport = data.savedReport

            this.genericCenterDefinition = this.genericCenterService.getGenericCenterDefinition(this.entityName)

            if (!this.genericCenterDefinition) {
              return
            }

            this.tabs = this.genericCenterDefinition.tabs.filter(
              (t: any) => this.licenseService.canSeeTab(t.menuId) && (!t.featureFlag || orgSkus?.includes(t.featureFlag))
            )
            if (this.tabs.length === 0) {
              this.toastService.open({
                id: 'warning',
                variant: 'warning',
                title: this.translateHelper.instant('generic_NoPermissions'),
              })
            }

            if (this.savedReport) {
              const index = this.tabs.findIndex((t) => this.isSameReport(t, this.savedReport!))
              this.tabSelectedIndex = index >= 0 ? index : 0
            }

            this.selectedTab = this.tabs[this.tabSelectedIndex]

            this.tabChanged({ index: this.tabSelectedIndex }, true)
          })
      })

    this.breadcrumbService.addComponent({
      type: ButtonComponent,
      data: {
        buttonType: 'tertiary',
        text: this.translateHelper.instant('common_Reload'),
        leftIcon: 'autorenew',
        size: 'tiny',
      },
      events: {
        clicked: (e: any) => this.refreshTable(),
      },
    })
  }

  onClickChartEvent = (params: { filters?: any; paramsApi?: any }) => {
    if (!this.reportDefinition || (!params.filters && !params.paramsApi)) {
      return
    }

    if (!this.reportDefinition.filters) {
      this.reportDefinition = { ...this.reportDefinition }
    }

    if (!this.customParameters) {
      this.customParameters = {}
    }

    if (params.paramsApi) {
      Object.keys(params.paramsApi).forEach((f: any) => {
        this.customParameters[f] = params.paramsApi[f]
      })
    }

    if (params.filters) {
      this.customFilters = { ...params.filters }
    }

    this.refreshTable()
  }

  ngOnDestroy(): void {
    this.breadcrumbService.clear()
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  pageDataChanged(event: { currentPage: number; currentPageSize: number; data: any[]; dataChart: any }) {
    this.pageDataChanged$.next(event)
  }

  onRangeFilterChange(event: { since?: string; to?: string; days?: number }) {
    timer(10).subscribe(() => {
      this.rangeFilters = event
      this.refreshTable()
    })
  }

  selectDataCenter = (dc: DataCenter) => {
    this.selectedDataCenter = dc
    this.grid!.refresh(dc.apiUrl)
  }

  onGridReady() {
    if (this.savedReport?.request) {
      this.setGridFromSavedReport()
    }
  }

  setGridFromSavedReport() {
    const filterModel: any = {}
    Object.keys(this.savedReport?.request.filters).forEach((f: any) => {
      filterModel[f] = JSON.parse(this.savedReport?.request.filters[f])
    })
    this.clientGrid?.gridApi.setFilterModel(filterModel)

    const sortModel = [{ colId: this.savedReport?.request.sort, sort: this.savedReport?.request.sortOrder }]
    this.clientGrid?.columnApi.applyColumnState({ state: sortModel })
  }

  schedule() {
    if (this.grid) {
      this.metadata.queryRequest.fields = this.reportsComponentHelper.getVisibleFields(this.grid?.columnApi)
    }
    const panelRef = this.rightPanelService.open({
      type: NewSchedulationComponent,
      data: {
        request: this.metadata?.queryRequest,
        route: this.menu?.route,
        initialName: this.translateHelper.instant(this.selectedTab.title),
        savedReport: this.savedReport,
        fromReport: true,
      },
    })
    panelRef
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(() => {
        this.loadSavedReports()
        if (this.panels) {
          this.panels.first.open = true
        }
      })
  }

  saveView() {
    if (!this.selectedTab.isClientDataGrid) {
      if (this.grid) {
        this.metadata.queryRequest.fields = this.reportsComponentHelper.getVisibleFields(this.grid.columnApi)
      }
    } else {
      if (this.clientGrid) {
        this.metadata.queryRequest.fields = this.reportsComponentHelper.getVisibleFields(this.clientGrid.columnApi)
      }

      const filters: any = {}
      const filterModel = this.clientGrid?.gridApi.getFilterModel()
      if (filterModel) {
        Object.keys(filterModel).forEach((f) => (filters[f] = JSON.stringify(filterModel[f])))
      }

      this.metadata.queryRequest.filters = filters
      const sortModel = this.clientGrid?.columnApi.getColumnState().find((s) => s.sort !== null)
      this.metadata.queryRequest.sort = sortModel?.colId
      this.metadata.queryRequest.sortOrder = sortModel?.sort
    }

    const panelRef = this.rightPanelService.open({
      type: SaveViewComponent,
      data: {
        lastRequest: this.metadata?.queryRequest,
        reportDefinition: this.tabs[this.tabSelectedIndex],
        route: this.menu?.route,
        savedReport: this.savedReport,
      },
    })
    panelRef
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(() => {
        this.loadSavedReports()
        if (this.panels) {
          this.panels.first.open = true
        }
      })
  }

  getChart(value: any) {
    return value.chart
  }

  getChartData(value: any) {
    return value.data
  }

  getChartDataChart(value: any) {
    return value.dataChart
  }

  changeChart($this: any) {
    if (!this.reportDefinition?.chart) {
      return
    }

    this.chart = this.reportDefinition.chart[$this.index]
    this.chartsContainer = {}
    this.chartsContainer[this.chart.name || 'chart'] = { chart: this.chart, data: this.data, dataChart: this.dataChart }
  }

  allChartsLoaded() {
    return this.reportDefinition?.chart && this.reportDefinition?.chart[0].areTabbable
      ? Object.keys(this.chartsContainer).length === 1
      : Object.keys(this.chartsContainer).length === this.reportDefinition?.chart?.length
  }

  calculateChartsContainerWidth() {
    return 'calc(100% * (1/' + Object.keys(this.chartsContainer).length + '))'
  }

  getDefaultTabChartIndex(): number {
    if (this.reportDefinition?.chart) {
      return this.reportDefinition.chart.findIndex((c: IReportChartDefinition) => c.isDefault)
    }
    return 0
  }

  exportReport(key: string) {
    if (this.grid) {
      this.metadata.queryRequest.fields = this.reportsComponentHelper.getVisibleFields(this.grid?.columnApi)
    }

    this.reportExportService
      .exportGeneric(
        this.getExportedItems,
        this.metadata,
        this.reportDefinition.verb,
        key,
        this.reportTitle || '',
        `${this.apiClient.basePortalApiUrl + this.reportDefinition?.url}`
      )
      .subscribe()
  }

  rowSelected() {
    this.selectedRows = this.grid!.getSelectedRows()
  }

  init() {
    this.rowSelection = this.selectedTab.gridOptions?.rowSelection
    this.enablePivotMode = this.selectedTab.enablePivotMode
    this.hideColumnsSelector = (!this.isAdmin() && !!this.savedReport?.columnsLocked) || this.selectedTab.hideColumnsSelector

    this.groupedActions = [
      {
        ...this.reportsComponentHelper.exportAction,
        isMenuButton: true,
        isVisible: () => !this.selectedTab.selectionActions || this.selectedTab.selectionActions.indexOf('Export') >= 0,
        options: this.selectedTab.isClientDataGrid ? Constants.exportOptionsNoPdf : Constants.exportOptions,
        onClick: (data: any[], key: string | null) =>
          this.selectedTab.isClientDataGrid
            ? this.reportsComponentHelper.exportPivotReport(key || '', this.clientGrid, this.selectedTab.title)
            : this.exportReport(key ?? ''),
      },
      {
        ...this.reportsComponentHelper.saveCustomReportAction,
        isVisible: () => (this.reportsComponentHelper.saveCustomReportAction.isVisible?.([]) && (!this.selectedTab.selectionActions || this.selectedTab.selectionActions.indexOf('SaveCustomReport') >= 0)) ?? false,
        onClick: () => {
          this.saveView()
        }
      },
      {
        ...this.reportsComponentHelper.scheduleAction,
        isVisible: () => (this.reportsComponentHelper.scheduleAction.isVisible?.([]) && (!this.selectedTab.selectionActions || this.selectedTab.selectionActions.indexOf('Schedule') >= 0)) ?? false,
        onClick: (data: any[]) => {
          this.schedule()
        },
      },
    ]
    this.selectionActions = []
    this.selectedTab.customSelectionActions?.forEach((a: any) => {
      const action = a.build(this.refreshTable, this.rightPanelService)
      this.selectionActions.unshift(action)
    })

    if (this.selectedTab.isClientDataGrid) {
      this.gridOptionsClientDataGrid = this.selectedTab.gridOptions
      this.detailCellRendererClientDataGrid = this.selectedTab.detailCellRenderer
      this.detailCellRenderParamsClientDataGrid = this.selectedTab.detailCellRenderParams

      this.reportTitle = !!this.savedReport ? this.savedReport.reportName : this.translateHelper.instant(this.entityName || '')
      this.reportDescription = !!this.savedReport
        ? this.savedReport.description
        : this.translateHelper.instant(`descriptions_${this.entityName}`)

      combineLatest([this.store.select(selectFlatMenusAll), this.store.select(selectFavorites)])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(([{ flatMenuAll }, favorites]) => {
          this.setMenu(flatMenuAll, favorites)
        })
    } else {
      this.loadData()
    }
  }

  tabChanged(params: { index: number }, isFirst = false) {
    this.tabSelectedIndex = params.index
    this.isPivotModeActive = false

    if (!isFirst) {
      this.savedReport = null
      this.savedReportId = undefined
      this.savedReportFilterSortModel = undefined
    }

    this.hasCharts = false
    this.chart = null
    this.chartsContainer = {}
    this.data = undefined

    this.tabChanged$.next()

    this.selectedTab = this.tabs[this.tabSelectedIndex]
    this.selectedRows = []

    this.reportsService.deleteSessionFiltersReports().subscribe((_) => {
      this.reportsService.reportFilter = undefined
      this.grid?.refresh()
    })

    this.init()
  }

  canManage(): boolean {
    return this.authHelperService.hasRole(Constants.roles.Management)
  }

  loadSavedReports() {
    this.reportsService
      .getViews({ pageSize: 50, pageNumber: 1, route: this.menu?.route, sortOrder: 'asc', sortField: 'reportName' })
      .subscribe((res) => {
        this.savedReports = res.filter((r: SavedReport) => this.isSameReport(this.selectedTab, r))
        this.isOpenPanelCustomReport = !!this.savedReports?.length && this.storage.isOpenPanelCustomReport
      })
  }

  buildChart(chart: IReportChartDefinition) {
    this.hasCharts = true

    this.pageDataChanged$.pipe(takeUntil(this.destroyed$), takeUntil(this.tabChanged$)).subscribe(({ data, dataChart }) => {
      if (chart.hasSameApi) {
        this.dataChart = dataChart
        this.data = data
        this.chart = chart.areTabbable ? this.chart || chart : chart

        this.chartsContainer[this.chart.name || 'chart'] = { dataChart, data, chart: this.chart }
      } else if (chart.apiUrl && (!this.data || chart.reloadOnChange)) {
        this.reportsService.getChartData(chart.apiUrl, chart.apiVerb, Object.assign({}, this.params)).subscribe((d) => {
          this.data = d
          this.chart = chart.areTabbable && this.chart ? this.chart : chart

          this.chartsContainer[this.chart.name || 'chart'] = { data: d, chart: this.chart }
        })
      }
    })
  }

  checkCharts() {
    if (
      !this.reportDefinition?.chart ||
      this.reportDefinition.chart.length === 0 ||
      (this.reportDefinition.chartAccessLevel &&
        !this.authHelperService.authorize(this.loggedUser as any, this.reportDefinition.chartAccessLevel))
    ) {
      return
    }

    const charts =
      this.reportDefinition?.chart && this.reportDefinition.chart.length > 1 && this.reportDefinition.chart[0].areTabbable
        ? [this.reportDefinition.chart[this.getDefaultTabChartIndex()]]
        : this.reportDefinition.chart

    if (!charts || charts.length === 0) {
      return
    }

    charts.forEach((chart: IReportChartDefinition) => {
      this.buildChart(chart)
    })
  }

  loadData() {
    this.selectedTab.reportDefinition$
      .pipe(
        tap((definition: any) => {
          this.allCols = undefined
          if (definition) {
            this.reportDefinition = cloneDeep(definition)
            this.setGroupedActions()
            this.reportDefinition.groupedActions = this.groupedActions
            this.reportDefinition.selectionActions = this.selectionActions
            this.setReportsFiltersActions()
            this.reportDefinition.filterActions = this.filterActions

            if (this.reportDefinition.calendarType && this.reportDefinition.defaultDaysFilter && !this.rangeFilters?.days) {
              this.rangeFilters = {
                days: this.reportDefinition.defaultDaysFilter,
              }
            }

            this.setSavedReportsParams()
            // this.checkMaps(definition)
            this.checkCharts()
            this.refreshData = (params: ApiDataParameters, dataCenterUrl?: string) => ({
              items: this.getItems$(
                this.reportDefinition?.url || '',
                this.reportDefinition?.verb || 'post',
                this.getReportParams(params),
                dataCenterUrl
              ).pipe(
                tap((x) => {
                  this.managementColumnDefinition = {
                    configs: x?.configs,
                    fields: x?.metadata.fields,
                    isOnlineUsersType: definition.isOnlineUsersType,
                  }
                })
              ),
              cols: definition.isOnlineUsersType ? this.onlineUsersColumns() : null,
            })
            this.reportTitle = !!this.savedReport ? this.savedReport.reportName : this.translateHelper.instant(this.entityName || '')
            this.reportDescription = !!this.savedReport
              ? this.savedReport.description
              : this.translateHelper.instant(`descriptions_${this.entityName}`)
          }
          this.pivotResponseItemProp = this.reportDefinition?.responseItemProp ? this.reportDefinition?.responseItemProp : ''
          this.routeParamsFilters = {}
          keys(this.activatedRoute.snapshot.params || {}).forEach(
            (x) => (this.routeParamsFilters[Helpers.capitalize(x)] = this.activatedRoute.snapshot.params[x])
          )
          keys(this.activatedRoute.snapshot.queryParams || {})
            .filter((x) => x.toLowerCase() !== 'savedReportId'.toLowerCase())
            .forEach((x) => (this.routeParamsFilters[Helpers.capitalize(x)] = this.activatedRoute.snapshot.queryParams[x]))
          this.refreshTable()
        }),
        mergeMap(() => combineLatest([this.store.select(selectFlatMenusAll), this.store.select(selectFavorites)])),
        takeUntil(this.destroyed$)
      )
      .subscribe(([{ flatMenuAll }, favorites]: any) => {
        this.setMenu(flatMenuAll, favorites)
      })
  }

  setMenu(flatMenuAll: NavItem[], favorites: any) {
    this.menu = flatMenuAll.find((x: NavItem) => {
      const urlToFind =
        this.route.indexOf(Constants.savedReportString) >= 0
          ? this.route.substring(0, this.route.indexOf(Constants.savedReportString))
          : this.route
      return '/' + x.route === urlToFind
    })
    if (this.menu) {
      this.setIsFavorite(favorites)
      this.loadSavedReports()
      this.breadcrumbService.updatePath([...this.getPath(), this.reportTitle || '-'])
    } else {
      this.breadcrumbService.updatePath([this.translateHelper.instant('common_Reports'), this.reportTitle || '-'])
    }
  }

  setGroupedActions() {
    if (!this.reportDefinition) {
      return
    }
    let createPolicyEnabled = !this.policy
    if (this.reportDefinition.actionsTags?.includes('playbook_DisablePolicyCreation')) {
      createPolicyEnabled = false
    }
    this.setCreateCustomPolicyAction(createPolicyEnabled)
  }

  setCreateCustomPolicyAction(createPolicyEnabled: boolean) {
    if (this.isAdmin() && createPolicyEnabled) {
      this.groupedActions = [
        {
          text: 'reports_CreateCustomPolicy',
          buttonType: 'tertiary',
          icon: 'add',
          visibility: 'noRow',
          isVisible: () => true,
          onClick: (data: any[]) => {
            const filters = this.grid?.getFilters()
            const reportDefinition = {
              ...this.reportDefinition,
              fields: this.grid?.columnApi
                ?.getAllDisplayedColumns()
                ?.map((x) => (x.getColDef().field ? Helpers.capitalize(x.getColDef().field || '') : ''))
                ?.filter((x) => !!x && x !== 'Selection'),
              title: this.reportDefinition?.title ? this.translateHelper.instant(this.reportDefinition?.title) : '',
              initialUrlFilters: this.reportDefinition?.verb === 'get' ? JSON.parse(filters || '{}') : filters,
              defaultDaysFilter: this.rangeFilters?.days,
              since: this.rangeFilters?.since,
              to: this.rangeFilters?.to,
              treeFilters: this.grid?.getTreeFilters(),
              selectionActions: []
            }
            this.rightPanelService
              .open({
                type: NewCustomPolicyComponent,
                data: {
                  width: '100%',
                  customReportId:
                    this.savedReport && !this.reportDefinition?.url?.includes('auditactivities') ? this.savedReport?.guid : undefined,
                  reportDefinition: createPolicyEnabled ? reportDefinition : undefined,
                  defaultTarget: this.reportDefinition?.url?.includes('auditactivities')
                    ? 'Audit'
                    : this.savedReport
                    ? 'CustomReports'
                    : 'Reports',
                  customColumns: this.selectedTab?.columnDefs ?? this.selectedTab.columnDefs
                },
              })
              .afterClosed()
              .pipe(filter((x) => !!x))
              .subscribe(() =>
                this.toastService.open({
                  id: 'success',
                  variant: 'success',
                  title: this.translateHelper.instant('playbook_PolicyCreatedTitle'),
                  message: this.translateHelper.instant('playbook_PolicyCreatedMessage'),
                })
              )
          },
        },
        ...this.groupedActions,
      ]
    }
  }

  setReportsFiltersActions() {
    this.filterActions = [
      {
        text: 'reports_ReportsFilters',
        buttonType: 'tertiary',
        icon: 'filter_alt',
        classIcon: 'mat-icon-no-color',
        leftClassStyle: 'outlined',
        cvDataTest: 'reports-filters',
        isVisible: () => this.reportDefinition?.entity === 'microsoft365AppsUsage',
        onClick: () => {
          this.showReportsFiltersPanel()
        },
        visibility: 'custom',
      },
      {
        text: 'reports_ClearReportsFilters',
        buttonType: 'tertiary',
        icon: 'filter_alt_off',
        classIcon: 'mat-icon-no-color',
        leftClassStyle: 'outlined',
        cvDataTest: 'clear-reports-filters',
        color: 'alert',
        isVisible: () =>
          this.reportDefinition?.entity === 'microsoft365AppsUsage' &&
          !!this.reportsService.reportFilter &&
          Object.keys(this.reportsService.reportFilter).length > 0,
        onClick: () => {
          this.reportsService.deleteSessionFiltersReports().subscribe((_) => {
            this.reportsService.reportFilter = undefined
            this.grid?.refresh()
          })
        },
        visibility: 'custom',
      },
    ]
    //this.targetEntity = this.reportDefinition!.targetEntity
  }

  showReportsFiltersPanel() {
    this.savedReportId = undefined
    const mappedTargetEntity =
      convertFromMembersTypeUser(this.reportDefinition?.targetEntity ?? '') ?? this.reportDefinition?.targetEntity ?? ''

    this.reportsService.showReportsFiltersPanel(
      mappedTargetEntity,
      this.grid,
      mappedTargetEntity === 'Audit',
      first(this.grid?.gridApi?.getFilterModel()?.workload?.filter?.children?.map((x: childFilter) => x.queryFilter?.value)),
      this.grid?.gridApi?.getFilterModel()?.operation?.filter?.children?.map((x: childFilter) => x.queryFilter?.value)
    )
  }

  setSavedReportsParams() {
    if (!!this.savedReport) {
      this.savedReportFilterSortModel = {
        filterModel: this.savedReport.request.filters,
        sortModel: { sortField: this.savedReport.request.sort, sortOrder: this.savedReport.request.sortOrder },
      }
    }
  }

  isAdmin = (): boolean => this.sharedHelperService.isAdmin()

  onPivotModeChanged = (val: boolean) => {
    if (this.isPivotModeActive && !val) {
      const filterModel = this.buildColumnsHelper.dictionaryFilterToFilterDefinition(this.params.filters as any, [])
      if (this.reportDefinition) {
        this.reportDefinition.initialFilters = filterModel
      }
    }
    this.isPivotModeActive = val
  }

  setIsFavorite(favorites: any) {
    if (!!this.menu) {
      if (!!this.savedReportId) {
        this.menu.isFavorite = !!favorites.favoriteSavedReports.find(
          (x: any) =>
            this.menu?.route === x.route.replace(`${Constants.savedReportString}${this.savedReportId}`, '') &&
            this.savedReportId === x.savedReportId
        )
      } else {
        this.menu.isFavorite = !!favorites.favoriteMenus.find((x: any) => this.menu?.id === x)
      }
    }
  }

  onAppDataGridColumnDefined = (colConfig: any) => {
    this.reportsComponentHelper.pivotParamsForBuildColDef = {
      selectedCols: this.params.fields,
      allcols: colConfig.serverCols,
      isPivot: true,
    }
  }

  getPivotData = () => {
    const pivotParams = { ...this.params }
    return this.reportsComponentHelper.getPivotData(this.reportDefinition as LibReportDefinition, pivotParams).pipe(tap((x) => (this.loadedFirstTime = true)))
  }

  openChangePanelCustomReport(open: boolean) {
    this.storage.isOpenPanelCustomReport = open
  }

  updateChartVisibilityPreference(chartPreference: boolean) {
    this.chartPreference = chartPreference
    if (!this.isPivotModeActive) {
      this.storage.chartDisplayPreference = chartPreference
    }
  }

  canSeeLeftPanel() {
    return !this.isPivotModeActive && !this.selectedTab?.hideCustomReportsPanel && this.canSeeCustomReportsBySku
  }

  private isSameReport(tabInfo: any, savedReportInfo: SavedReport) {
    return tabInfo.apiUrl === savedReportInfo.apiUrl
  }

  private getPath(): string[] {
    const path = []
    let menu = this.menu?.parent
    while (menu) {
      path.push(this.translateHelper.instant(menu.title))
      menu = menu.parent || undefined
    }
    return path.reverse()
  }

  private getReportParams(params: ApiDataParameters): any {
    const reportParams = { ...params }
    if (!!this.reportDefinition?.urlParameters) {
      Object.assign(reportParams, this.reportDefinition.urlParameters)
    }

    if (!!this.reportDefinition?.urlRouteParameters && Object.keys(this.reportDefinition.urlRouteParameters).length > 0) {
      const urlRoute: any = {}

      Object.keys(this.reportDefinition?.urlRouteParameters).forEach((p: string) => {
        if (this.reportDefinition?.urlRouteParameters != null) {
          urlRoute[p] = this.activatedRoute.snapshot.params[this.reportDefinition.urlRouteParameters[p]]
        }
      })

      Object.assign(reportParams, urlRoute)
    }

    if (!!this.savedReportIdFilter) {
      Object.assign(reportParams, { savedReportId: this.savedReportIdFilter })
      this.savedReportIdFilter = undefined
    }

    return reportParams
  }
}

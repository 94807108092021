import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { LicensesFormatterService } from '@app/modules/workflow/services/licenses-formatter.service'
import { Helpers } from '@app/shared/utilities/helpers'
import { TranslateHelper } from '@coreview/coreview-library'

@Component({
  selector: 'app-execution-detail-input-item',
  template: `
    <div *ngIf="key !== 'selectedLicenses'" class="executionDetailInputItem-container">
      <span class="key-field">{{ key }}</span
      >: <span class="value">{{ value }}</span>
    </div>
    <div *ngIf="key === 'selectedLicenses'" class="executionDetailInputItem-container">
      <strong>{{ key }}</strong>
      <ul>
        <li *ngFor="let license of licenses">
          {{ license }}
        </li>
      </ul>
    </div>
  `,
  styleUrls: ['./execution-detail-input-item.component.sass'],
})
export class ExecutionDetailInputItemComponent implements OnChanges {
  @Input() key = ''
  @Input() value = ''

  public licenses: string[] = []

  constructor(private translateHelper: TranslateHelper, private licensesFormatterService: LicensesFormatterService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.convertValue()
  }

  convertValue() {
    if (this.key === 'waitDuration') {
      this.value = Helpers.formatDuration(+this.value || 0)
    }
    if (this.key === 'selectedLicenses') {
      this.licenses = this.hasValOutput(this.licensesFormatterService.licenseFormatter(this.value))
    }
  }

  hasValOutput = (val: string[]) =>
    val.length !== 0 ? val : [`${this.translateHelper.instant('NoAppliedLicenses')} ${String.fromCharCode(0x2715)}`]
}

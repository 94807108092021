import { Suggestion } from "@coreview/coreview-components"
import { TranslateHelper } from "@coreview/coreview-library"
import { JobRecurrence, JobRecurrenceFrequency, JobRecurrenceSchedule } from "@app/core/models/Schedulation"
import { Injectable } from "@angular/core"

@Injectable({
  providedIn: 'root',
})

export class SchedulationService {
  sendWhenOptions: Suggestion[] = [
    { value: 'IsEmpty', displayValue: this.translate('reports_IsEmpty') },
    { value: 'IsNotEmpty', displayValue: this.translate('reports_IsNotEmpty') },
    { value: 'Always', displayValue: this.translate('reports_Always') },
  ]

  recurrenceOptions: Suggestion[] = [
    { value: 'oneTime', displayValue: this.translate('common_OneTime') },
    { value: 'recurring', displayValue: this.translate('common_Recurring') },
  ]

  unitOptions: Suggestion[] = [
    { value: '1', displayValue: this.translate('common_Hours') },
    { value: '2', displayValue: this.translate('common_Days') },
    { value: '3', displayValue: this.translate('common_Weeks') },
    { value: '4', displayValue: this.translate('common_Months') },
  ]

  startingOptions: Suggestion[] = [
    { value: 'now', displayValue: this.translate('common_Now') },
    { value: 'specificTime', displayValue: this.translate('reports_AtSpecificTime') },
  ]

  formatOptions: Suggestion[] = [
    { value: 'CSV', displayValue: 'CSV' },
    { value: 'PDF', displayValue: 'PDF' },
    { value: 'XLSX', displayValue: 'XLSX' },
  ]

  daysOptions: Suggestion[]

  dayMonthOptions: Suggestion[] = Array(31)
      .fill(0)
      .map((_, index: number) => ({
        value: index + 1,
        displayValue: (index + 1).toString(),
      }))

  private onDay = this.translate('common_OnDay').toLowerCase()
  private onThe = this.translate('common_OnThe').toLowerCase()
  private lastDayOfMonth = this.translate('common_LastDayOfMonth').toLowerCase()
  private every = this.translate('common_Every').toLowerCase()
  private on = this.translate('common_on')

  private cardinalNumberStrings: Record<number, string> = {
    1: this.translate('common_First'),
    2: this.translate('common_Second'),
    3: this.translate('common_Third'),
    4: this.translate('common_Fourth'),
    5: this.translate('common_Last'),
  }

  constructor(private translateHelper: TranslateHelper) {
    const translatableWeekdays = [
      'common_Sunday', 
      'common_Monday', 
      'common_Tuesday', 
      'common_Wednesday', 
      'common_Thursday', 
      'common_Friday', 
      'common_Saturday'
    ];

    this.daysOptions = translatableWeekdays
      .map((day: string, i: number) => ({ 
        value: ((i + 6) % 7).toString(), 
        displayValue: this.translate(day)
      }))
   }
  
  public getRecurringDataString(recurrence?: JobRecurrence): string {
    if (!recurrence) return ''
    const { schedule } = recurrence

    let frequencyDisplayValue = this.getFrequencyDisplayValue(recurrence)
    let recurringString = `${this.every} ${recurrence.interval + ' ' || ''}${this.translate(frequencyDisplayValue).toLowerCase()}`
  
    if (recurrence.frequency === JobRecurrenceFrequency.Week) {
      recurringString += this.getWeeklyRecurrenceString(schedule);
    } else if (recurrence.frequency === JobRecurrenceFrequency.Month) {
      recurringString += this.getMonthlyRecurrenceString(schedule);
    }

    return recurringString
  }

  private getFrequencyDisplayValue(recurrence: JobRecurrence) : string {
    let frequencyDisplayValue = this.getDisplayValue(this.unitOptions, recurrence.frequency)
    return SchedulationService.getSingleOrPlural(recurrence, frequencyDisplayValue)
  }
  private static getSingleOrPlural(recurrence: JobRecurrence, frequencyDisplayValue: string) : string {
    return recurrence.interval !== 1 
      ? frequencyDisplayValue
      : frequencyDisplayValue.slice(0, -1)
  }

  private static isMonthlyOcurrenceOnWeekDay(scheduleData: JobRecurrenceSchedule) {
    const monthlyOccurrences = scheduleData.monthlyOccurrences

    return monthlyOccurrences !== undefined 
      && monthlyOccurrences.length > 0 
      && monthlyOccurrences[0].day !== undefined
      && monthlyOccurrences[0].occurrence
  }

  private static isMonthlyOccurenceSpecificDay(scheduleData: JobRecurrenceSchedule) {
    return scheduleData.monthDays !== undefined 
      && scheduleData.monthDays.length > 0
  }

  private static isMonthlyOccurenceLastDay(scheduleData: JobRecurrenceSchedule) {
    return scheduleData.lastDay ?? false
  }

  private static isWeeklyOccurenceSpecificDay(scheduleData: JobRecurrenceSchedule) {
    return scheduleData.days !== undefined
      && scheduleData.days.length > 0
  }

  private getWeeklyRecurrenceString(schedule?: JobRecurrenceSchedule): string {
    if (!schedule) return ''
    if (!SchedulationService.isWeeklyOccurenceSpecificDay(schedule)) return ''

    const weekdayDisplayValue = this.getDisplayValue(this.daysOptions, schedule.days![0])
    return ` ${this.on} ${weekdayDisplayValue}`
  }

  private getMonthlyRecurrenceString(schedule?: JobRecurrenceSchedule): string {
    if (!schedule || SchedulationService.isMonthlyOccurenceLastDay(schedule)) {
      return ` ${this.onThe} ${this.lastDayOfMonth}`
    }

    if (SchedulationService.isMonthlyOccurenceSpecificDay(schedule)) {
      return ` ${this.onDay} ${schedule.monthDays![0]}`
    } 
    
    if (SchedulationService.isMonthlyOcurrenceOnWeekDay(schedule)) {
      const weekdayMonthDisplayValue = this.getDisplayValue(this.daysOptions, schedule.monthlyOccurrences![0].day)
      const cardinalString = this.cardinalNumberStrings[schedule.monthlyOccurrences![0].occurrence!]
      return ` ${this.onThe} ${cardinalString} ${weekdayMonthDisplayValue}`
    }

    return ''
  }

  private getDisplayValue<T>(options: Suggestion[], value: T): string {
    let enumValue = Number(value);

    if (Number.isNaN(enumValue)) {
      return 'common_' + value
    }
      
    return options.find(option => option.value === enumValue.toString())?.displayValue || ''
  }

  private translate(text: string) {
    return this.translateHelper.instant(text)
  }
}
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiclientService } from './apiclient.service'
import { ApplicationInsightService } from './application-insight.service'

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(private apiclient: ApiclientService, private httpClient: HttpClient, private appInsight: ApplicationInsightService) {}

  logError(message: string) {
    this.appInsight.trackError(message)
    let msg = 'fail encode message'
    try {
      msg = btoa(encodeURIComponent(message))
    } catch {}
    return this.httpClient.post(this.getLogErrorUrl(), { message: msg }, { withCredentials: true })
  }

  getLogErrorUrl() {
    return `${this.apiclient.basePortalApiUrl}/v2/logs/error`
  }
}

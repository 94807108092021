export const licensesMultiselectFilterDefinition = {
  filterOptions: [
    { key: '', value: 'common_Select' },
    { key: 'contains', value: 'common_Contains' },
    { key: 'doesNotContain', value: 'common_DoesNotContain' },
    { key: 'isEmpty', value: 'common_IsEmpty' },
    { key: 'isNotEmpty', value: 'common_IsNotEmpty' },
  ],
  defaultFilterType: '',
  suppressAndOrCondition: true,
}

export const licensesLegacyMultiselectFilterDefinition = {
  filterOptions: [
    { key: '', value: 'common_Select' },
    { key: 'equals', value: 'common_Equals' },
    { key: 'contains', value: 'common_Contains' },
    { key: 'notEqual', value: 'common_NotEqual' },
    { key: 'isEmpty', value: 'common_IsEmpty' },
    { key: 'isNotEmpty', value: 'common_IsNotEmpty' },
  ],
  defaultFilterType: '',
  suppressAndOrCondition: true,
}

export const scopeMultiselectFilterDefinition = {
  filterOptions: [
    { key: '', value: 'common_Select' },
    { key: 'contains', value: 'common_Contains' },
    { key: 'doesNotContain', value: 'common_DoesNotContain' },
    { key: 'isEmpty', value: 'common_IsEmpty' },
    { key: 'isNotEmpty', value: 'common_IsNotEmpty' },
  ],
  defaultFilterType: '',
  suppressAndOrCondition: true,
}

export const appRoleValueMultiselectFilterDefinition = {
  filterOptions: [
    { key: '', value: 'common_Select' },
    { key: 'equals', value: 'common_Equals' },
    { key: 'contains', value: 'common_Contains' },
    { key: 'doesNotContain', value: 'common_DoesNotContain' },
    { key: 'isEmpty', value: 'common_IsEmpty' },
    { key: 'isNotEmpty', value: 'common_IsNotEmpty' },
  ],
  defaultFilterType: '',
  suppressAndOrCondition: true,
}

export const valueMultiselectFilterDefinition = {
  filterOptions: [
    { key: '', value: 'common_Select' },
    { key: 'equals', value: 'common_Equals' },
    { key: 'contains', value: 'common_Contains' },
    { key: 'doesNotContain', value: 'common_DoesNotContain' },
    { key: 'isEmpty', value: 'common_IsEmpty' },
    { key: 'isNotEmpty', value: 'common_IsNotEmpty' },
  ],
  defaultFilterType: '',
  suppressAndOrCondition: true,
}

<mat-tree class="fl-1" [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePaddingIndent="18px" matTreeNodePadding class="not-expandable">
    <ng-container *ngIf="node.icon"> <em class="{{ node.icon }} node-icon"></em>&nbsp; </ng-container>
    <ng-container
      ><span class="f-s-12">{{ node.name }}</span></ng-container
    >
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="18px">
    <button matTreeNodeToggle class="btn">
      <mat-icon class="mat-icon-rtl-mirror expand-icon">
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>
    <ng-container *ngIf="node.icon"> <em class="{{ node.icon }} node-icon"></em>&nbsp;</ng-container>
    <ng-container
      ><span class="f-s-12">{{ node.name }}</span></ng-container
    >
  </mat-tree-node>
</mat-tree>

import { EnhancedJsonFormData } from '@app/shared/models/enhanced-json-form-data'
import { Helpers } from '@app/shared/utilities/helpers'
import { SchemaPropertyDecorator } from './json-schema-rosetta-v1.class'

/**
 * Add an order property if it's not exist.
 *
 * This property is use by method `calculateSortedProperties` in FormBuilderComponent.
 */
export class TitleDecorator extends SchemaPropertyDecorator {
  getJsonSchema(): EnhancedJsonFormData {
    const schema = super.getJsonSchema()

    if (!!schema?.properties) {
      for (const propName in schema.properties) {
        if (!schema.properties[propName].title) {
          schema.properties[propName].title = Helpers.capitalize(propName)
        }
      }
    }

    return schema
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { ScheduledReport, Schedulation } from './../models/Schedulation'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiclientService } from './apiclient.service'
import { OperationColumn, ReportDefinition } from '../models/ReportDefinition'
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs'
import { Verb } from '../models/PageDataCommonClasses'
import { ServerResponse } from '../models/ServerResponse'
import { ApiDataParameters } from '../models/ApiDataParameters'
import { CoreViewColumn } from '../models/CoreViewColumn'
import { filter, map, mergeMap, pluck, tap } from 'rxjs/operators'
import { SavedReport } from '../models/saved-report'
import { flatten } from 'lodash-es'
import { Constants } from '@app/shared/utilities/constants'
import dayjs from 'dayjs'
import { RightPanelService } from './right-panel.service'
import { IntuneDeviceActionsStatusComponent } from '@app/modules/reports/pages/intune-device-actions-status/intune-device-actions-status.component'
import { OperationalReportsInfoComponent } from '@app/modules/reports/components/operational-reports/operational-reports-info/operational-reports-info.component'
import { ReportsFiltersComponent } from '@app/modules/reports/components/reports-filters/reports-filters.component'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'
import { ReportsFilters } from '../models/reports-filters'
import { Office365Group } from '../models/Office365Group'
import { ToastService } from '@coreview/coreview-components'
import { TranslateHelper } from '@coreview/coreview-library'
import { LocalstorageService } from './localstorage.service'
import { Store } from '@ngrx/store'
import { RootState } from '@app/store/RootState.type'
import { addReportFilter } from '@app/store/report-filters/report-filters.actions'
import { ViewModeActionPanelComponent } from '@app/modules/reports/components/view-mode-action-panel/view-mode-action-panel.component'
import { UserAnalyzerData } from '../models/usersAnalyzer'
import {
  TargetEntityAuditType,
  TargetEntityBaseType,
  TargetEntityCustomActionType,
  TargetEntityPolicyType,
  TargetEntityTeamsType,
  TargetEntityType,
  TargetEntityWorkflowType,
  convertFromGroupType2,
} from '../enums/group-type'
import { ManagementAction } from '../models/management-action'
import { ManagementHelperService } from '@app/shared/utilities/management.helper.service'
import { selectManagementActionsByIds } from '@app/store/management-actions/management-actions.selectors'
import { ColumnApi, Events, GridApi } from '@ag-grid-community/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { ClientDataGridDialogComponent } from '@app/shared/components/client-data-grid-dialog/client-data-grid-dialog.component'

export type reportFilterActions = { cancel: boolean; sessionFilter: boolean; saveFilter: boolean; update: boolean }

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  apis!: Record<string, ReportDefinition>

  private $reportFilter: BehaviorSubject<ReportsFilters | undefined> = new BehaviorSubject<ReportsFilters | undefined>(undefined)

  constructor(
    private apiClient: ApiclientService,
    private httpClient: HttpClient,
    private rightPanelService: RightPanelService,
    private dialog: MatDialog,
    private toastService: ToastService,
    private translateHelper: TranslateHelper,
    private storage: LocalstorageService,
    private store: Store<RootState>,
    private managementHelperService: ManagementHelperService
  ) {}

  getReportDefinitions() {
    const url = `${this.apiClient.basePortalApiUrl}/reportsdefinition`
    return this.httpClient.get<ReportDefinition[]>(url, { withCredentials: true })
  }

  getDataSimplified(reportUrl: string | undefined, verb: Verb | undefined, params?: any) {
    const url = `${this.apiClient.basePortalApiUrl + reportUrl}?metadata=true&jsconfig=inv:false`

    if (verb === 'post') {
      return this.httpClient.post<any>(url, params, { withCredentials: true })
    }

    return this.httpClient.get<ServerResponse<any>>(url, { params, withCredentials: true })
  }

  getData(reportUrl: string, verb: Verb, params?: ApiDataParameters, dataCenterUrl?: string): Observable<ServerResponse<any>> {
    const apiUrl = dataCenterUrl || this.apiClient.basePortalApiUrl
    const url = `${apiUrl + reportUrl}`

    if (verb === 'post') {
      return this.httpClient.post<any>(url, params, { params: { metadata: true, jsconfig: 'inv:false' }, withCredentials: true })
    }
    if (verb === 'put') {
      return this.httpClient.put<any>(url, params, { params: { metadata: true, jsconfig: 'inv:false' }, withCredentials: true })
    }
    if (verb === 'patch') {
      return this.httpClient.patch<any>(url, params, { params: { metadata: true, jsconfig: 'inv:false' }, withCredentials: true })
    }
    return this.httpClient.get<ServerResponse<any>>(url, {
      params: { ...params, metadata: true, jsconfig: 'inv:false' } as any,
      withCredentials: true,
    })
  }

  getDataString(reportUrl: string, params?: string, dataCenterUrl?: string): Observable<ServerResponse<any>> {
    const apiUrl = dataCenterUrl || this.apiClient.basePortalApiUrl
    const url = `${apiUrl + reportUrl}`

    return this.httpClient.get<ServerResponse<any>>(url + params, { withCredentials: true })
  }

  getExportedData(
    reportUrl: string,
    verb: Verb,
    params: ApiDataParameters & { asyncExport: boolean; reportNameContainer: string },
    format: string,
    dataCenterUrl?: string
  ): Observable<any> {
    const apiUrl = dataCenterUrl || this.apiClient.basePortalApiUrl
    const url = `${apiUrl + reportUrl}?metadata=true`

    if (verb === 'post') {
      return this.httpClient.post(`${url}&format=${format}&reportNameContainer=${params.reportNameContainer}`, params, {
        responseType: 'blob',
        withCredentials: true,
        observe: 'response',
      })
    }

    return this.httpClient.get(url, { params: params as any, responseType: 'blob', withCredentials: true, observe: 'response' })
  }

  getOnlineUsersColumns(): Observable<CoreViewColumn[]> {
    const url = `${this.apiClient.basePortalApiUrl}/onlineusers/columns`
    return this.httpClient.get<CoreViewColumn[]>(url, { withCredentials: true })
  }

  getGeoPoints() {
    const url = `${this.apiClient.basePortalApiUrl}/multigeo`
    return this.httpClient.get<CoreViewColumn[]>(url, { withCredentials: true }).pipe(pluck('items'))
  }

  getAzureReportingSignInCoordinatesEvent(companyId: string, params?: any) {
    const url = `${this.apiClient.basePortalApiUrl}/azurereporting/${companyId}/signIns/coordinates/?metadata=true&reportNameContainer=SignInsEvent`
    return this.httpClient.post<any>(url, params, { withCredentials: true }).pipe(pluck('items'))
  }

  addToFavorite(menuId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.apiClient.basePortalApiUrl}/FavoritePage`, { menuId }, { withCredentials: true })
  }

  removeFromFavorite(menuId: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiClient.basePortalApiUrl}/FavoritePage`, {
      withCredentials: true,
      params: { menuId: menuId.toString() },
    })
  }

  getViews(params: any): Observable<SavedReport[]> {
    const url = `${this.apiClient.basePortalApiUrl}/views`
    return this.httpClient.get<any>(url, { withCredentials: true, params: { ...params, isPortalV2: true } }).pipe(pluck('savedReports'))
  }

  saveReport(report: { request: SavedReport; targetEntity?: string }, usePut: boolean = false): Observable<void> {
    report.request.savedReportParams = {
      ...report.request.savedReportParams,
      groupMembershipFilter: this.reportFilter?.membershipReportFilters,
    }
    if (usePut) {
      return this.httpClient.put<void>(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        `${this.apiClient.basePortalApiUrl}/savedreports`,
        report,
        { withCredentials: true, headers: { AngularRoute: location.href } }
      )
    }
    return this.httpClient.post<void>(
      // eslint-disable-next-line @typescript-eslint/naming-convention
      `${this.apiClient.basePortalApiUrl}/savedreports`,
      report,
      { withCredentials: true, headers: { AngularRoute: location.href } }
    )
  }

  getExportedReports(params: ApiDataParameters): Observable<ServerResponse<any>> {
    return this.httpClient.get<ServerResponse<any>>(`${this.apiClient.basePortalApiUrl}/savedreports/asyncexport?metadata=true`, {
      params: params as any,
      withCredentials: true,
    })
  }

  canViewReport(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<{ canViewReport: boolean; reason: 'Permission' | 'Skus' | 'SecureByDefault' }> {
    const guardUrl = route.routeConfig?.path ? state.url.split(route.routeConfig?.path)[0] + route?.routeConfig?.path : state.url
    const params: { route: string; savedReportId?: string } = { route: guardUrl }
    if (route.queryParams.SavedReportId) {
      params['savedReportId'] = route.queryParams.SavedReportId
    }
    return this.httpClient.get<{ canViewReport: boolean; reason: 'Permission' | 'Skus' | 'SecureByDefault' }>(
      `${this.apiClient.basePortalApiUrl}/V2/canviewreport`,
      { withCredentials: true, params }
    )
  }

  getFilterValues(url: string, itemsProperty: string | ((item: any) => any[]), params: any = null) {
    return this.httpClient
      .get<any[]>(`${this.apiClient.basePortalApiUrl}/${url}`, {
        params,
        withCredentials: true,
      })
      .pipe(typeof itemsProperty === 'string' ? pluck(itemsProperty) : map(itemsProperty))
  }

  getSavedReports(params: ApiDataParameters, isPublic: boolean, isPredefined: boolean, category: string) {
    delete params.reportTreeFilters
    const url = `${this.apiClient.basePortalApiUrl}/savedReports?metadata=true`
    return this.httpClient.get<any>(url, {
      params: { ...params, isShared: isPublic, isPredefined, category } as any,
      withCredentials: true,
    })
  }

  getSavedReportById(id: string) {
    const url = `${this.apiClient.basePortalApiUrl}/savedreport/${id}`
    return this.httpClient.get<any>(url, { params: { id } as any, withCredentials: true }).pipe(pluck('savedReport'))
  }

  getScheduledReports(params: ApiDataParameters, isPublic: boolean, isPredefined: boolean) {
    if (params?.filters) {
      params.filters = JSON.stringify(params.filters)
    }
    const url = `${this.apiClient.basePortalApiUrl}/scheduledReports?metadata=true`
    return this.httpClient
      .get<any>(url, {
        params: { ...params, category: Constants.savedReportsCategories.PortalV2, isShared: isPublic, isPredefined } as any,
        withCredentials: true,
      })
      .pipe(
        pluck('savedReports'),
        map((reports: SavedReport[]) =>
          flatten(
            reports.map((report) => report.schedulations.map((schedulationInfo) => ({ report, schedulationInfo } as ScheduledReport)))
          )
        )
      )
  }

  getSubscribedScheduledReports(params: ApiDataParameters) {
    if (params?.filters) {
      params.filters = JSON.stringify(params.filters)
    }
    const url = `${this.apiClient.basePortalApiUrl}/scheduledReports/subscribed?metadata=true`
    return this.httpClient
      .get<any>(url, {
        params: { ...params, category: Constants.savedReportsCategories.PortalV2 } as any,
        withCredentials: true,
      })
      .pipe(
        pluck('savedReports'),
        map((reports: SavedReport[]) =>
          flatten(
            reports.map((report) => report.schedulations.map((schedulationInfo) => ({ report, schedulationInfo } as ScheduledReport)))
          )
        )
      )
  }

  getChartData(reportUrl: string, verb: Verb = 'get', params?: ApiDataParameters): Observable<ServerResponse<any>> {
    const url = `${this.apiClient.basePortalApiUrl + reportUrl}?metadata=true`

    if (verb === 'post') {
      return this.httpClient.post<ServerResponse<any>>(url, params, { withCredentials: true })
    }

    return this.httpClient.get<ServerResponse<any>>(url, { params: params as any, withCredentials: true })
  }

  setIsPublicSavedReport(params: { savedReportId: string; isShared: boolean }) {
    return this.httpClient.put<void>(`${this.apiClient.basePortalApiUrl}/savedreports/shared`, params, { withCredentials: true })
  }

  addSavedReportToFavorite(params: { savedReportId: string }) {
    return this.httpClient.put<void>(`${this.apiClient.basePortalApiUrl}/savedreports/favourites`, params, { withCredentials: true })
  }

  removeSavedReportFromFavorite(params: { savedReportId: string }) {
    return this.httpClient.delete<void>(`${this.apiClient.basePortalApiUrl}/savedreports/favourites`, { withCredentials: true, params })
  }

  deleteSavedReport(params: { savedReportId: string }) {
    return this.httpClient.delete<void>(`${this.apiClient.basePortalApiUrl}/savedreports/`, { withCredentials: true, params })
  }

  downloadSavedReports(managedTenantId: string, ids: string[]): Observable<{ category: string; resources: any }> {
    return this.httpClient.put<any>(
      `${this.apiClient.basePortalApiUrl}/model/export/${managedTenantId}/SavedReport/`,
      { ids },
      {
        withCredentials: true,
      }
    )
  }

  uploadSavedReports(managedTenantId: string, resources: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiClient.basePortalApiUrl}/model/import/${managedTenantId}/SavedReport/?metadata=true`,
      { resources },
      {
        withCredentials: true,
      }
    )
  }

  getUserSuggestions(params: { searchedString: string; excludeItems?: string[] }): Observable<{ userPrincipalName: string }[]> {
    return this.httpClient
      .get<string[]>(`${this.apiClient.basePortalApiUrl}/onlineusers/suggestion/`, { params: params as any, withCredentials: true })
      .pipe(pluck('items'))
  }

  getGenericSuggestions(
    entity: string,
    reponseItems: string,
    params: { searchedString: string; sort: string; sortOrder: string; excludeItems?: string[] }
  ): Observable<any[]> {
    return this.httpClient
      .post<string[]>(`${this.apiClient.basePortalApiUrl}/${entity}/suggestion/`, params, { withCredentials: true })
      .pipe(pluck(reponseItems))
  }

  getTeamsGroupSuggestions(params: { search: string; excludeItems?: string[]; sort: string; sortOrder: string }) {
    return this.httpClient
      .post<Office365Group[]>(`${this.apiClient.basePortalApiUrl}/suggest/teams/groups`, params, { withCredentials: true })
      .pipe(pluck('items'))
  }

  saveSchedulation(schedulation: Schedulation) {
    return this.httpClient.put<void>(`${this.apiClient.basePortalApiUrl}/savedreports/schedulations/`, schedulation, {
      withCredentials: true,
    })
  }

  deleteSchedulation(params: { schedulationInfoId: string; savedReportId: string }) {
    return this.httpClient.delete<void>(`${this.apiClient.basePortalApiUrl}/savedreports/schedulations/`, {
      params: params as any,
      withCredentials: true,
    })
  }

  subscribeSchedulation(subscription: { id: string; isSubscribe: boolean; schedulationId: string }) {
    return this.httpClient.put<void>(`${this.apiClient.basePortalApiUrl}/scheduledReports/subscribe/`, subscription, {
      withCredentials: true,
    })
  }

  shareSchedulation(subscription: { id: string; isShared: boolean; schedulationId: string }) {
    return this.httpClient.put<void>(`${this.apiClient.basePortalApiUrl}/scheduledReports/share/`, subscription, {
      withCredentials: true,
    })
  }

  subscriptionsHistoryCellClassRulesFunc() {
    return {
      'bg-cell-expired': (params: any) => params.value && dayjs(params.value).diff(dayjs()) < 0,
      'bg-cell-critical': (params: any) =>
        params.value && dayjs(params.value).diff(dayjs()) >= 0 && dayjs(params.value).diff(dayjs()) < 1296000000, // 15days
      'bg-cell-warning': (params: any) =>
        params.value && dayjs(params.value).diff(dayjs()) >= 1296000000 && dayjs(params.value).diff(dayjs()) < 2592000000, // 30 days
    }
  }

  getDeviceActions(params: any): Observable<SavedReport[]> {
    const url = `${this.apiClient.basePortalApiUrl}/manageddevice/deviceActionResults`
    return this.httpClient.get<any>(url, { withCredentials: true, params })
  }

  locateDevice(params: any): Observable<SavedReport[]> {
    const url = `${this.apiClient.basePortalApiUrl}/manageddevice/locatedevice`
    return this.httpClient.post<any>(url, params, { withCredentials: true })
  }

  getServiceHealthIssueById(params: any): Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/dashboards/issue`
    return this.httpClient.get<any>(url, { withCredentials: true, params })
  }

  getMicrosoft365MessageById(params: any): Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/dashboards/message`
    return this.httpClient.get<any>(url, { withCredentials: true, params })
  }

  getMicrosoft365ServiceHealthCurrentStatus(params: any): Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/office365/currentstatus`
    return this.httpClient.get<any>(url, { withCredentials: true, params })
  }

  getAnalyzeSearchData(): Observable<UserAnalyzerData> {
    const url = `${this.apiClient.basePortalApiUrl}/analyzersearchtype`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  getUserAnalyserAggregations(queryItem: any): Observable<{ aggregations: any }> {
    const params = {
      pageSize: 0,
      additionalFilters: [],
      destinationType: 'Forward365.Services.ServiceModel.Types.OnlineUser',
      managedUserField: 'UserPrincipalName',
      pageNumber: 1,
      sort: 'DisplayName',
      sortOrder: 'asc',
      ...queryItem,
    }

    const url = `${this.apiClient.basePortalApiUrl}/analyzerfulltextsearch`
    return this.httpClient.post<any>(url, params, { withCredentials: true }).pipe(pluck('elasticSearchResult'))
  }

  saveOrDeleteReportsFiltersHandler = (action: reportFilterActions, skipSelectReportFilter: boolean, grid?: DatagridComponent) => {
    if (action.cancel) {
      return
    }
    this.store.dispatch(addReportFilter({ isGlobalNull: false, filter: this.reportFilter }))
    this.storage.setSelectedGlobalFilter(null)
    this.deleteSessionFiltersReports().subscribe()
    this.manageSaveFilter(action, skipSelectReportFilter, grid)
  }

  saveSessionFiltersReports = (filters: ReportsFilters | undefined) => {
    if (filters?.targetEntity === 'Audit') {
      return of(void 0)
    }
    const url = `${this.apiClient.basePortalApiUrl}/securitymanager/reportfilters/select`
    return this.httpClient.post<any>(url, { ...filters, ReportFilters: [] }, { withCredentials: true })
  }

  deleteSessionFiltersReports = () => {
    const url = `${this.apiClient.basePortalApiUrl}/securitymanager/reportfilters/remove`
    return this.httpClient.delete<any>(url, { withCredentials: true })
  }

  saveFiltersReports = (filters: ReportsFilters | undefined) => {
    const url = `${this.apiClient.basePortalApiUrl}/reportfilters`
    return this.httpClient.post<any>(url, { ...filters }, { withCredentials: true })
  }

  updateFiltersReports = (filters: ReportsFilters | undefined, id: string) => {
    const url = `${this.apiClient.basePortalApiUrl}/reportfilters/${id}`
    return this.httpClient.put<any>(url, { ...filters }, { withCredentials: true })
  }

  getReportsFilters = (params: ApiDataParameters): Observable<ServerResponse<any>> => {
    const url = `${this.apiClient.basePortalApiUrl}/reportfilters`
    return this.httpClient.get<any>(url, { withCredentials: true, params: { ...params, metadata: true, jsconfig: 'inv:false' } as any })
  }

  deleteFiltersReports = (id?: string) => {
    const url = `${this.apiClient.basePortalApiUrl}/reportfilters/${id}`
    return this.httpClient.delete<any>(url, { withCredentials: true })
  }

  refreshDataReport(url: string, webCorrelationId: string) {
    return this.httpClient.put<any>(`${this.apiClient.basePortalApiUrl}/${url}`, { webCorrelationId }, { withCredentials: true })
  }

  public set reportFilter(filters: ReportsFilters | undefined) {
    this.$reportFilter.next(filters)
  }

  public get reportFilter() {
    return this.$reportFilter.value || (this.storage.getSelectedGlobalFilter() as ReportsFilters)
  }

  public getPivotUrl(reportDefinition: { url?: string; pivotUrl?: string; isOnlineUsersType?: boolean }): string {
    if (reportDefinition.pivotUrl) {
      return reportDefinition.pivotUrl
    } else {
      return reportDefinition.isOnlineUsersType ? '/onlineusers/pivot' : reportDefinition.url || ''
    }
  }

  getReportFilterObservable(): Observable<ReportsFilters | undefined> {
    return this.$reportFilter.asObservable()
  }

  showReportsFiltersPanel = (
    targetEntity: string | undefined,
    grid?: DatagridComponent,
    readonly: boolean = false,
    workload?: string,
    operations?: string[],
    skipSelectReportFilter = false
  ) => {
    const panelRef = this.rightPanelService.open({
      type: ReportsFiltersComponent,
      data: {
        reportFilter: this.reportFilter,
        targetEntity,
        readonly,
        workload,
        operations,
      },
    })
    panelRef
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe((action: reportFilterActions) => {
        if (grid) {
          this.deselectAllGridItems(grid)
        }
        this.saveOrDeleteReportsFiltersHandler(action, skipSelectReportFilter, grid)
      })
  }

  public deselectAllGridItems(grid: { gridApi: GridApi; columnApi: ColumnApi }) {
    grid.gridApi.deselectAll()
    const rowSelected = {
      type: Events.EVENT_ROW_SELECTED,
      api: grid.gridApi,
      columnApi: grid.columnApi,
      data: {},
      node: {
        isSelected: () => false,
      },
    }
    grid.gridApi.dispatchEvent(rowSelected)
  }

  public generateFiltersForTeamsPolicies = (): Map<string, any> => {
    const teamPolicies: string[] = [
      'TeamsAppPermissionPolicy',
      'TeamsAppSetupPolicy',
      'TeamsEventsPolicy',
      'TeamsAudioConferencingPolicy',
      'TeamsCallHoldPolicy',
      'TeamsCallParkPolicy',
      'CallingLineIdentity',
      'TeamsCallingPolicy',
      'TenantDialPlan',
      'TeamsEmergencyCallingPolicy',
      'TeamsEmergencyCallRoutingPolicy',
      'TeamsEnhancedEncryptionPolicy',
      'TeamsMeetingBroadcastPolicy',
      'TeamsMeetingPolicy',
      'TeamsMessagingPolicy',
      'TeamsMobilityPolicy',
      'TeamsChannelsPolicy',
      'TeamsUpdateManagementPolicy',
      'OnlineVoiceRoutingPolicy',
      'OnlineVoicemailPolicy',
    ]
    const map = new Map<string, any>()

    teamPolicies.forEach((key) => {
      map.set(key, {
        filterParams: {
          suppressAndOrCondition: true,
          options: this.getFilterValues(`teams/policies/${key}`, 'values'),
        },
        filter: 'singleSelectFilter',
      })
    })

    return map
  }

  canEditRow(rowData: any, actions: ManagementAction[]): boolean {
    return !!actions.find((act) => this.managementHelperService.isCompliantWithActionRules(act, [rowData]))
  }

  openEditActionPanel(rowData: any[], possibleActions: ManagementAction[]) {
    const action = possibleActions.find((act) => this.managementHelperService.isCompliantWithActionRules(act, rowData))
    this.managementHelperService.openManagementPanel(rowData, action)
  }

  getEditOperation(
    targetEntity:
      | TargetEntityBaseType
      | TargetEntityType
      | TargetEntityPolicyType
      | TargetEntityCustomActionType
      | TargetEntityAuditType
      | TargetEntityWorkflowType
      | TargetEntityTeamsType
  ): Observable<OperationColumn | null> {
    return this.getEditActionsByTargetEntity(targetEntity).pipe(
      mergeMap((actions) => {
        if (actions.length) {
          return of({
            type: 'iconButton',
            originalName: 'common_Edit',
            name: 'common_Edit',
            position: -2,
            notSelectable: true,
            sortName: 'no_sort',
            agColDef: {
              ...Constants.defaultOperationColumnsDefinition,
              colId: 'edit',
              pinned: 'left',
              lockPinned: true,
              hide: false,
              cellRenderer: 'iconButtonRenderer',
              cellRendererParams: {
                icon: 'edit',
                iconClass: 'material-icons',
                tooltipKey: 'common_Edit',
                hideIcon: (rowData: any) => !this.canEditRow(rowData, actions),
                onClick: (params: { event: any; rowData: any }) => {
                  this.openEditActionPanel([params.rowData], actions)
                },
              },
            },
          } as OperationColumn)
        }
        return of(null)
      })
    )
  }

  private getEditActionsByTargetEntity(
    targetEntity:
      | TargetEntityBaseType
      | TargetEntityType
      | TargetEntityPolicyType
      | TargetEntityCustomActionType
      | TargetEntityAuditType
      | TargetEntityWorkflowType
      | TargetEntityTeamsType
  ): Observable<ManagementAction[]> {
    switch (targetEntity) {
      case TargetEntityBaseType.User: {
        const prioritizedActionIds = [
          Constants.managementActionsIds.editUserProperties,
          Constants.managementActionsIds.editSynchronizedUser,
          Constants.managementActionsIds.editOnPremisesUser,
        ]
        return this.store.select(selectManagementActionsByIds(prioritizedActionIds)).pipe(
          mergeMap((actions: ManagementAction[]) => {
            actions.sort((a, b) => prioritizedActionIds.indexOf(a.actionItemId) - prioritizedActionIds.indexOf(b.actionItemId))
            return of(actions)
          })
        )
      }
      case TargetEntityType.Office365Group:
      case TargetEntityCustomActionType.O365Group:
        return this.store.select(selectManagementActionsByIds([Constants.managementActionsIds.editO365Group]))

      case TargetEntityBaseType.Teams:
      case TargetEntityTeamsType.TeamsGroupChannel:
        return this.store.select(selectManagementActionsByIds([Constants.managementActionsIds.editTeamsGroup]))

      case TargetEntityBaseType.SecurityGroup:
        return this.store.select(
          selectManagementActionsByIds([
            Constants.managementActionsIds.editSecurityGroup,
            Constants.managementActionsIds.editOnPremisesSecurityGroup,
          ])
        )

      case TargetEntityBaseType.DistributionGroup:
        return this.store.select(
          selectManagementActionsByIds([
            Constants.managementActionsIds.editDistributionGroup,
            Constants.managementActionsIds.editOnPremisesDistributionGroup,
          ])
        )

      default:
        return of([])
    }
  }

  private manageSaveFilter(action: reportFilterActions, skipSelectReportFilter: boolean, grid?: DatagridComponent) {
    if (!action.saveFilter && !action.sessionFilter) {
      grid?.refresh()
      return
    }
    const saves = []
    if (action.saveFilter) {
      const te = convertFromGroupType2(this.reportFilter!.targetEntity!)
      if (action.update) {
        saves.push(this.updateFiltersReports({ ...this.reportFilter, targetEntity: te }, this.reportFilter?.id!))
      } else {
        saves.push(
          this.saveFiltersReports({ ...this.reportFilter, targetEntity: te }).pipe(
            tap((res) => (this.reportFilter = res.insertedReportFilter))
          )
        )
      }
    }

    if (skipSelectReportFilter) {
      saves.push(of(void 0))
    } else if (action.sessionFilter) {
      saves.push(this.saveSessionFiltersReports(this.reportFilter))
    }
    combineLatest(saves).subscribe(
      (_) => {
        grid?.refresh()
      },
      (_) => {
        this.toastService.open({
          id: 'err',
          variant: 'error',
          title: this.translateHelper.instant('common_Error'),
          message: this.translateHelper.instant('error_GenericSaveError'),
        })
      }
    )
  }

  private openIntuneHistoryFunction = (event: any) => {
    const panelRef = this.rightPanelService.open({
      type: IntuneDeviceActionsStatusComponent,
      data: {
        rowData: event.rowData,
        width: '30%',
      },
    })
    panelRef.afterClosed().subscribe(() => {
      alert('')
    })
  }

  private importDeviceData = (event: any) => {
    const url = `${this.apiClient.basePortalApiUrl}/manageddevice/import`
    this.httpClient
      .post<any>(
        url,
        {
          azureAdDeviceId: event.rowData.azureADDeviceId,
          managedDeviceId: event.rowData.managedDeviceId,
          objectId: event.rowData.objectId,
          deviceName: event.rowData.displayName,
        },
        { withCredentials: true }
      )
      .subscribe(() =>
        this.toastService.open({
          id: 'success',
          variant: 'success',
          title: this.translateHelper.instant('common_ImportDeviceDataTitle'),
          message: this.translateHelper.instant('common_ImportDeviceDataDescription'),
        })
      )
  }

  private serviceHealthIssuesFunction = (event: any) => {
    this.rightPanelService.open({
      type: OperationalReportsInfoComponent,
      data: {
        rowData: event.rowData,
        type: 'serviceHealthIssues',
        width: Constants.modalDialogSizes.m,
      },
    })
  }

  private microsoft365MessagesFunction = (event: any) => {
    this.rightPanelService.open({
      type: OperationalReportsInfoComponent,
      data: {
        rowData: event.rowData,
        type: 'microsoft365Messages',
        width: Constants.modalDialogSizes.m,
      },
    })
  }

  private autoAttendantDetailsFunction = (event: any) => {
    this.rightPanelService.open({
      type: ViewModeActionPanelComponent,
      data: {
        actionId: Constants.managementActionsIds.editTeamsAutoAttendant,
        actionTitle: 'management_ViewTeamsAutoAttendant',
        width: '100%',
        selectedTargets: [event.rowData],
      },
    })
  }

  private callQueueDetailsFunction = (event: any) => {
    this.rightPanelService.open({
      type: ViewModeActionPanelComponent,
      data: {
        actionId: Constants.managementActionsIds.editTeamsCallQueue,
        actionTitle: 'management_ViewTeamsCallQueue',
        width: '100%',
        selectedTargets: [event.rowData],
        waitFormBuilderEventFired: true,
      },
    })
  }

  private intuneNotCompliantDeviceFunction = (event: any) => {
    const title = event?.rowData?.policyName ?? ''
    const uniqueId = event?.rowData?.uniqueId ?? ''
    const url = '/devices/intune/notcompliantpolicies/settings/{uniqueId}'
    let filters = undefined
    if (event?.rowData?.policySettingsNames) {
      filters = {
        filteredColumn: 'setting',
        selectedElement: event?.rowData?.policySettingsNames[event?.index ?? 0],
      }
    }
    this.openDialog(title, uniqueId, url, filters)
  }

  private intuneNotCompliantConfigurationFunction = (event: any) => {
    const title = event?.rowData?.policyName ?? ''
    const uniqueId = event?.rowData?.uniqueId ?? ''
    const url = '/devices/intune/notCompliantConfigurations/settings/{uniqueId}'
    let filters = undefined
    if (event?.rowData?.settingsNames) {
      filters = {
        filteredColumn: 'setting',
        selectedElement: event?.rowData?.settingsNames[event?.index ?? 0],
      }
    }
    this.openDialog(title, uniqueId, url, filters)
  }

  private openDialog(title: string, uniqueId: string, url: string, filters: any) {
    this.dialog.open(ClientDataGridDialogComponent, {
      data: {
        title: title,
        data: {
          uniqueId: uniqueId,
          filters: filters,
        },
        params: {
          type: 'datagrid-dialog',
          getItems: {
            url: url,
            verb: 'get',
            mapResultFunction: 'return data',
          },
          columnDefinition: {
            selectedCols: ['Setting', 'SettingStatus', 'ErrorCode'],
            allcols: [
              {
                originalName: 'Setting',
                name: 'setting',
                translate: 'reports_IntuneSetting',
                type: 'string',
                filter: { type: 'string', name: 'setting' },
              },
              {
                originalName: 'SettingStatus',
                name: 'settingStatus',
                translate: 'reports_IntuneAttribute',
                type: 'string',
                filter: { type: 'string', name: 'settingStatus' },
              },
              {
                originalName: 'ErrorCode',
                name: 'errorCode',
                translate: 'reports_IntuneError',
                type: 'string',
                filter: { type: 'string', name: 'errorCode' },
              },
            ],
          },
        },
      },
    })
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  operationsColumnsFunctionsMap: { [K: string]: (event: any) => any } = {
    intuneDeviceSyncFunction: this.importDeviceData,
    intuneDeviceFunction: this.openIntuneHistoryFunction,
    intuneNotCompliantDeviceFunction: this.intuneNotCompliantDeviceFunction,
    intuneNotCompliantConfigurationFunction: this.intuneNotCompliantConfigurationFunction,
    serviceHealthIssuesFunction: this.serviceHealthIssuesFunction,
    microsoft365MessagesFunction: this.microsoft365MessagesFunction,
    autoAttendantDetailsFunction: this.autoAttendantDetailsFunction,
    callQueueDetailsFunction: this.callQueueDetailsFunction,
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store'
import { values } from 'lodash-es'
import { dashboardsAdapter } from './dashboards.reducer'
import { DashboardState } from './dashboards.types'

const defaultSelectors = dashboardsAdapter.getSelectors()

export const selectDashboardState = createFeatureSelector<DashboardState>('dashboards')

export const selectDashboards = createSelector(
    selectDashboardState,
    (state: DashboardState) => values(state.entities)
)

export const selectDashboardByRoute = (route: string ) => createSelector(
    selectDashboardState,
    (state: DashboardState) => state.entities[route]
)

export const selectDashboardLoaded = createSelector(
  selectDashboardState,
  (state: DashboardState) => state.loaded
)

export const selectDashboardsLoading = createSelector(
  selectDashboardState,
  state => state.isFetching
)

export const selectDashboardsError = createSelector(
    selectDashboardState,
    state => state.error
  )

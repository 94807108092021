import { of } from 'rxjs'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  getManagementType,
  getManagementTypeInvalidate,
  getManagementTypeResponse,
} from '@app/store/management-type/management-type.actions'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { OrganizationService } from '@app/core/services/organization.service'

@Injectable()
export class ManagementTypeEffects {
  getManagementType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getManagementType),
      mergeMap(({ orgId }) =>
        this.organizationService.getManagementType(orgId).pipe(
          map((data) => getManagementTypeResponse({ data })),
          catchError((error) => of(getManagementTypeInvalidate({ error: error.message })))
        )
      )
    )
  )

  constructor(private actions$: Actions, private organizationService: OrganizationService) {}
}

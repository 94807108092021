import { Injectable } from '@angular/core'
import { HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { LocalstorageService } from '../services/localstorage.service'
import { ApplicationInsightService } from '../services/application-insight.service'
import { environment } from '@environments/environment'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private storage: LocalstorageService, private appInsights: ApplicationInsightService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let headers = this.getHeaders()

    if (!this.isWorkflowV1Url(request.url)) {
      headers = this.addAppInsights(request, headers)
    }

    headers = this.addCredential(request, headers)
    headers = this.addSelectedCompany(headers)

    request = request.clone({
      headers,
    })
    return next.handle(request)
  }

  getHeaders = () =>
    /* eslint-disable @typescript-eslint/naming-convention */
    new HttpHeaders({
      Accept: 'application/json, text/plain, */*',
      // 'Content-Type': 'application/json;charset=utf-8',
      // "Access-Control-Allow-Origin": '*',
      // "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, HEAD, OPTIONS"
    })
  /* eslint-enable @typescript-eslint/naming-convention */

  addAppInsights(request: HttpRequest<any>, header: HttpHeaders): HttpHeaders {
    if (request.withCredentials) {
      header = header.set('X-ss-id', this.appInsights.contextId())
    }
    return header
  }

  addCredential(request: HttpRequest<any>, header: HttpHeaders): HttpHeaders {
    // for wf v1 requests, withCredentials must be false but it still needs the Authorization header
    if (request.withCredentials || !!this.isWorkflowV1Url(request.url)) {
      header = header.set('Authorization', `Bearer ${this.storage.authToken}`)
    }
    return header
  }

  // TODO remove this when we'll have us and cae correcly set on environment or localstorage
  isWorkflowV1Url(apiUrl: string) {
    const envName = environment.name.toLowerCase()
    return (
      apiUrl.startsWith('https://wfusapi') ||
      apiUrl.startsWith('https://wfus2api') ||
      apiUrl.startsWith('https://wfeuapi') ||
      apiUrl.startsWith('https://wfcaapi') ||
      apiUrl.startsWith('https://cfacusapi') ||
      apiUrl.startsWith('https://cfaccaapi') ||
      apiUrl.startsWith('https://cfaceuapi') ||
      apiUrl.startsWith('https://cfacus2api') ||
      apiUrl.startsWith('https://3p5w6fbp83') ||
      apiUrl.startsWith('https://mokthyv6lk') ||
      apiUrl.startsWith('https://u8zuaflgyf') ||
      apiUrl.startsWith(environment.workflowUrl[envName]) ||
      apiUrl.startsWith(environment.workflowUrlCatalog[envName])
    )
  }

  addSelectedCompany(header: HttpHeaders): HttpHeaders {
    if (!!this.storage.selectedOrganization) {
      header = header.set(
        'x-scompany',
        this.storage.selectedOrganization.id + '|' + btoa(unescape(encodeURIComponent(this.storage.selectedOrganization.text))) + '--'
      )
    }
    return header
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { CellClickedEvent } from '@ag-grid-community/core'
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { CustomMenu } from '@app/core/models/CustomMenu'
import { ManagedTenant } from '@app/core/models/ManagedTenant'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { SelectedUserGroups, UserGroup } from '@app/core/models/UserGroup'
import { AuthenticatedUser } from '@app/core/models/authenticated-user'
import { ToolbarType } from '@app/core/models/toolbar/toolbar-type'
import { AdministrationsService } from '@app/core/services/administrations.service'
import { AuthenticationService } from '@app/core/services/authentication.service'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { TasksService } from '@app/core/services/tasks.service'
import { ToolbarService } from '@app/core/services/toolbar.service'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { Constants } from '@app/shared/utilities/constants'
import { RootState } from '@app/store/RootState.type'
import { selectLoggedOperator } from '@app/store/operators/operators.selectors'
import { selectOrganizationById, selectedOrganizationSkus } from '@app/store/organizations/organizations.selectors'
import { addReportFilter } from '@app/store/report-filters/report-filters.actions'
import { selectReportFilter } from '@app/store/report-filters/report-filters.selectors'
import { requestManagedTenantChange } from '@app/store/tenants/tenants.actions'
import { Store } from '@ngrx/store'
import { Suggestion, ToastService, Variants } from '@coreview/coreview-components'
import dayjs from 'dayjs'
import { Observable, combineLatest, of } from 'rxjs'
import { debounceTime, delay, distinct, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { selectManagedTenants, selectSelectedTenant } from '../../../store/tenants/tenants.selectors'
import { RightPanelService, TranslateHelper } from '@coreview/coreview-library'
import { selectManagementStatus } from './../../../store/management-status/management-status.selectors'
import { selectLastMessageOfType } from './../../../store/messages/messages.selectors'
import { selectUserGroups, selectUserGroupsLoaded } from './../../../store/userGroups/userGroups.selectors'
import { AggregatedAsyncTask, AsyncTask } from '@coreview/coreview-library/models/aggregated-async-task'
import { GlobalFilter } from './../../models/GlobalFilter'
import { LicensePoolGroup } from './../../models/LicensePoolGroup'
import { ManagementResponse } from '../../models/management-response'
import { Organization } from './../../models/Organization'
import { ReportsService } from './../../services/reports.service'
import { CommercialPortalSkusService } from '@app/core/services/commercial-portalskus.service'
import { Helpers } from '@app/shared/utilities/helpers'
import { setDemoMode } from '@app/store/demo-mode/demo-mode.actions'
import { GrantAccessDetailsComponent } from '@app/modules/administrations/components/grant-access-details/grant-access-details.component'

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass'],
})
export class ToolbarComponent implements OnInit {
  @Output() themeChanged = new EventEmitter<'light' | 'dark'>()
  @Input() isMenuOpen = true
  @Input() darkMode = false

  loggedUser$: Observable<AuthenticatedUser | undefined>
  userId: string

  defaultTenant$: Observable<ManagedTenant | undefined>
  managedTenants: (ManagedTenant & {
    id?: string,
    isGranted?: boolean,
    onClick: () => void
  })[] = []

  showFilters = false
  showUserMenu = false
  showTenants = false
  showNotifications = false
  showManagement = false
  showUniversalSearch = false

  originalGlobalFilters!: GlobalFilter[]
  globalFilters!: Suggestion[]
  selectedGlobalFilter!: Suggestion | null

  originalLicensePools!: LicensePoolGroup[]
  licensePools!: Suggestion[]
  selectedLicense!: Suggestion | null

  originalUserGrops: UserGroup[] = []
  userGroups: Suggestion[] = []
  selectedUserGroups!: Suggestion[] | null

  originalCustomMenus!: CustomMenu[]
  customMenus!: Suggestion[]
  selectedCustomMenus!: Suggestion[] | null

  logoUrl!: string | undefined
  smallLogoUrl!: string | undefined
  logoLoaded = false

  originalVirtualTenantsSubfilters: SelectedUserGroups[] = []
  virtualTenantsSubfilters: Suggestion[] = []
  selectedVirtualTenantsSubfilter!: Suggestion | null

  taskFilterStatuses: Suggestion[] = []

  tasks: (AggregatedAsyncTask & { toRead?: boolean })[] = []

  filterChanged$ = new EventEmitter<string>()
  filter = ''

  defaultTasksParams: any = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    filters: JSON.stringify({ IsBackground: false }),
    pageSize: 10,
    pageNumber: 1,
    sort: 'StartDate',
    sortOrder: 'desc',
    days: 7,
  }

  tasksInputChanged$ = new EventEmitter<string>()

  taskInput!: string
  taskSelect = ''
  notificationsNumber = 0
  shownTask!: AggregatedAsyncTask | null
  shownAggregatedTask!: AggregatedAsyncTask | null

  reportGridDefinition: ReportDefinition = {
    entity: 'tasksProgressToolbar',
    title: 'notused',
    fields: ['Title', 'State'],
    defaultHiddenFields: [],
    sortField: 'StartDate',
    sortOrder: 'desc',
    verb: 'get' as Verb,
    isOnlineUsersType: true,
    responseItemProp: 'aggregatedAsyncTaskList',
    gridOptions: {
      onCellClicked: (event: CellClickedEvent) => {
        if (event.colDef.field === 'title') {
          this.shownTask = event.data
        }
      },
    },
    rowSelection: 'none',
    entityIdField: 'correlationId',
  }

  organization!: Organization | undefined
  managementStatus!: ManagementResponse | null

  showFilterTab = false
  showGlobalSearch = false
  loadingTasks = false
  showLPVT = false
  showPermissions = false

  showVirtualTenantsSubfilter = false

  showDemoMode = false
  activatedDemoMode = false
  selectedDemoModeSkus!: string[] | null

  constructor(
    private store: Store<RootState>,
    private authenticationService: AuthenticationService,
    private commercialPortalSkusService: CommercialPortalSkusService,
    private storage: LocalstorageService,
    private administrationsService: AdministrationsService,
    private sharedHelperService: SharedHelperService,
    private router: Router,
    private translateHelper: TranslateHelper,
    private location: Location,
    private tasksService: TasksService,
    private reportsService: ReportsService,
    private toolbarService: ToolbarService,
    private window: Window,
    private rightPanelService: RightPanelService,
    private toastService: ToastService
  ) {
    this.defaultTenant$ = this.store.select(selectSelectedTenant)


    this.loggedUser$ = this.store.select(selectLoggedOperator)
    this.userId = this.storage.getLoggedUser().userId

    this.initData()
  }

  @HostListener('document:click', ['$event'])
  onclick = (event: MouseEvent) => {
    this.closeSearchAndPanelsWhenClickingElsewhere(event)
  }

  initData() {
    this.initManagedTenants()
    this.initLicenses()
    this.initGlobalFilters()
    this.initUserGroups()
    this.initTasks()
    this.loadSelectedCustomMenus()
  }

  isAllowedSupportAccount(upn: string): boolean {
    const user = upn.split('@')[0];
    const convertedUser = user.split('_')[1];
    const allowedDomains = ['coreviewsupport.onmicrosoft.com', 'coreviewsupport.com'];
    return allowedDomains.includes(convertedUser);
  }

  initManagedTenants() {
    combineLatest([
      this.isAllowedSupportAccount(this.storage.getLoggedUser().userName) ? of([]) : this.store.select(selectManagedTenants).pipe(filter((x) => !!x && x.length > 0)),
      this.store.select(selectedOrganizationSkus)
        .pipe(filter((x) => !!x && x.length > 0), 
          switchMap((skus) =>  Helpers.hasAnySkus(skus ?? [], ['FT:INTERNALSUPPORT']) ? this.administrationsService.getGrantedTenants() : of([])))
    ])
    .subscribe({
      next: ([managedTenants, grantedTenants]) => { 
        this.managedTenants = [
          ...managedTenants.map(mt => ({...mt, onClick: () => this.changeTenant(mt) })), 
          ...grantedTenants.map(gt => ({
          id: gt.id,
          managedTenantId: gt.companyId,
          displayValue: gt.companyName,
          managedTenantName: gt.companyName,
          partnerId: '',
          partnerName: '',
          isGranted: true,
          onClick: () => this.supportLogin(gt)
        }))];
      },
      error: () => { 
        this.toastService.open({
          id: 'err',
          variant: 'error',
          title: this.translateHelper.instant('common_Error'),
          message: this.translateHelper.instant('common_Error'),
        })
      }
    })
  }

  initLicenses() {
    this.administrationsService
      .getCompanyLicensePools()
      .pipe(
        tap((res) => (this.originalLicensePools = res)),
        map((result) => result.map((x) => ({ value: x.id, displayValue: x.name }))),
        tap((res) => {
          this.selectedLicense = this.storage.getSelectedGroupFilter()
            ? res.find((x) => x.value === this.storage.getSelectedGroupFilter()) ?? null
            : null
        })
      )
      .subscribe((res) => (this.licensePools = res))
  }

  initGlobalFilters() {
    this.authenticationService
      .getGlobalFilters()
      .pipe(
        tap((res) => (this.originalGlobalFilters = res)),
        map((result) => result.map((x) => ({ value: x.id, displayValue: x.name }))),
        tap((res) => {
          this.selectedGlobalFilter = !this.storage.getSelectedGlobalFilter()
            ? null
            : { value: this.storage.getSelectedGlobalFilter()?.id ?? '', displayValue: this.storage.getSelectedGlobalFilter()?.name ?? '' }
        })
      )
      .subscribe((res) => (this.globalFilters = res))
  }

  initUserGroups() {
    if (this.isAdmin()) {
      this.store
        .select(selectUserGroupsLoaded)
        .pipe(
          filter((x) => !!x),
          switchMap(() => combineLatest([this.store.select(selectUserGroups), this.administrationsService.getUserGroupsLoggedUser()]))
        )
        .subscribe(([groups, selected]) => {
          this.originalUserGrops = (groups || []) as UserGroup[]
          this.userGroups = this.originalUserGrops.map((x) => ({ value: x.id, displayValue: x.name }))
          this.selectedUserGroups = selected.map((x) => ({ value: x.userGroupId, displayValue: x.userGroupName }))
          this.initVirtualTenantsSubfilter(selected)

          if (this.selectedUserGroups.some((s) => !this.userGroups.some((x) => x.value === s.value))) {
            this.location.reload()
          }
        })

      this.store.select(selectReportFilter).subscribe((result) => {
        if (!result.isGlobalNull) {
          this.selectedGlobalFilter = null
        }
      })
    } else {
      this.administrationsService.getUserGroupsLoggedUser().subscribe((selected) => {
        this.selectedUserGroups = selected.map((x) => ({ value: x.userGroupId, displayValue: x.userGroupName }))
        this.initVirtualTenantsSubfilter(selected)
      })
    }
  }

  initDemoMode(portalSkus: readonly string[]) {
    this.showDemoMode = Helpers.hasAnySkus(portalSkus, ['FT:SKUDEMO'])
    if (this.showDemoMode) {
      this.administrationsService.getSelectedDemoModeSkus().subscribe((skus: string[]) => {
        this.activatedDemoMode = skus.length > 0
        this.store.dispatch(setDemoMode({ demoModeActivated: this.activatedDemoMode }))
        this.selectedDemoModeSkus = skus
        if (this.activatedDemoMode) {
          this.checkSkuAvailability(skus)
        }
      })
    }
  }

  initTasks() {
    this.store
      .select(selectLastMessageOfType('NotifyTaskStatus'))
      .pipe(
        filter((x) => !!x?.body?.aggregationId),
        mergeMap((x) => {
          const asyncTask = x?.body as AsyncTask
          return this.tasksService.getNotificationTasks({}, asyncTask.aggregationId)
        })
      )
      .subscribe((x) => {
        const task = x[0] as AggregatedAsyncTask & { toRead?: boolean }
        if (
          !task?.title?.toLowerCase().includes(this.taskInput?.toLowerCase() || '') ||
          (this.taskSelect && task.state !== this.taskSelect)
        ) {
          return
        }
        if (
          !task.isAggregated ||
          task.total === (task.totalFailed || 0) + (task.totalFinished || 0) ||
          !this.tasks.some((t) => (t.aggregationId ? t.aggregationId === task.aggregationId : t.asyncTaskId === task.asyncTaskId))
        ) {
          this.tasks = this.tasks.filter((t) =>
            t.aggregationId ? t.aggregationId !== task.aggregationId : t.asyncTaskId !== task.asyncTaskId
          )
          task.toRead = true
          this.tasks = [task, ...this.tasks]
        }
        this.notificationsNumber = this.tasks.filter((t) => t.toRead).length
      })
  }

  loadSelectedCustomMenus() {
    this.administrationsService.getCustomMenuMembership().subscribe((selected) => {
      this.selectedCustomMenus = selected.map((x) => ({ value: x.id, displayValue: x.name }))
    })
  }
  
  setLogoUrls(x: Organization) {
    this.logoUrl = (x.logoUrl && x.logoFileName) ? x.logoUrl + '?' + new Date().getTime() : undefined
    this.smallLogoUrl = (x.smallLogoUrl && x.smallLogoFileName) ? x.smallLogoUrl + '?' + new Date().getTime() : undefined

    const faviconUrl = x.faviconUrl ? x.faviconUrl + '?' + new Date().getTime() : undefined
    document.querySelector('#favicon')?.setAttribute('href', faviconUrl ?? 'data:,')
  }

  isAdmin = (): boolean => this.sharedHelperService.isAdmin()

  isManagement = (): boolean => this.sharedHelperService.isManagement()

  isGlobalLicenses = (): boolean => this.sharedHelperService.isGlobalLicenses()

  subscribeToGrantAccessNotification() {
    this.store.select(selectLastMessageOfType('GrantOrganizationAccessAndPermissionsResult'))
      .pipe(filter((x) => !!x))
      .subscribe((result) => {
        const toastOptions = result?.body.resultStatus === 'Succeeded' ? {
          id: 'grantResult',
          variant: 'success' as Variants,
          title: this.translateHelper.instant('common_Success'),
          message: this.translateHelper.genericCombineTranslations(
            result?.body.actionType === 'Grant' ? 'administration_GrantAccessSucceed' : 'administration_RevokeAccessSucceded',
             { operator: result?.body.operator }
          )
        } : {
          id: 'grantResult',
          variant: 'error' as Variants,
          title: this.translateHelper.instant('common_Failed'),
          message: this.translateHelper.genericCombineTranslations(
            result?.body.actionType === 'Grant' ? 'administration_GrantAccessFailed' : 'administration_RevokeAccessFailed',
            { operator: result?.body.operator }
          )
        }
        this.toastService.open(toastOptions)
      })
  }

  ngOnInit(): void {
    this.translateHelper.get('common_All').subscribe((text: string) => {
      this.taskFilterStatuses = [
        { value: '', displayValue: this.translateHelper.instant('common_All') },
        { value: 'Progress', displayValue: this.translateHelper.instant('common_OnProgress') },
        { value: 'Finished', displayValue: this.translateHelper.instant('common_Finished') },
        { value: 'Failed', displayValue: this.translateHelper.instant('common_Failed') },
      ]
    })

    this.subscribeToGrantAccessNotification()

    this.filterChanged$.pipe(debounceTime(200)).subscribe((x) => (this.filter = x))
    this.tasksInputChanged$.pipe(debounceTime(200)).subscribe((x) => this.loadTasks(x, this.taskSelect))
    this.store
      .select(selectManagementStatus)
      .pipe(filter((x) => !!x?.status))
      .subscribe((x) => {
        this.managementStatus = x
      })

    this.store
      .select(selectSelectedTenant)
      .pipe(
        filter((x) => !!x),
        switchMap((x) => this.store.select(selectOrganizationById(x?.managedTenantId ?? '')).pipe(filter((o) => !!o))),
        tap((org) => {
          this.organization = org
          this.logoLoaded = true
          this.setLogoUrls(org!)
        }),
        distinct((org) => !!org?.portalSkus?.length)
      )
      .subscribe((org) => {
        this.initDemoMode(org!.portalSkus)
        this.checkSkuAvailability(org!.portalSkus)

        if (this.showVirtualTenantsSubfilter) {
          this.administrationsService.getVtenantSubfilter().subscribe((res) => {
            if (res.userGroup) {
              const { name , id } = res.userGroup
              this.selectedVirtualTenantsSubfilter = { value: id, displayValue: name }
            }
          })
        }
      })

    this.toolbarService.toggleToolbarPanel$.pipe(delay(1)).subscribe((panelInfo: { open: boolean; toolbarType: ToolbarType }) => {
      switch (panelInfo.toolbarType) {
        case 'organization-selector':
          this.showTenants = panelInfo.open
          break
        case 'management-session':
          this.showManagement = panelInfo.open
          break
        case 'tasks':
          if (panelInfo.open) {
            this.showNotifications = panelInfo.open
          } else {
            this.closeNotificationsPanel()
          }
          break
        case 'filters':
          this.showFilters = panelInfo.open
          break
        case 'profile':
          this.showUserMenu = panelInfo.open
          break
      }
    })
  }

  onShowTasksPanel = () => {
    if (this.showNotifications) {
      this.closeNotificationsPanel()
    } else {
      this.showNotifications = true
      this.loadingTasks = true
      this.loadTasks()
    }
  }

  onShowFilters(): void {
    if (!this.showFilters) {
      this.administrationsService.getCustomMenus().subscribe((customMenus) => {
        this.originalCustomMenus = customMenus
        this.customMenus = customMenus.map((x) => ({ value: x.id, displayValue: x.name }))
      })
    }
    this.showFilters = !this.showFilters
  }

  logout(): void {
    this.authenticationService.logout()
  }

  logoutAzure(): void {
    this.authenticationService.logoutAzure()
  }

  globalFilterChanged(selGlobalFilter: Suggestion | null) {
    this.selectedGlobalFilter = selGlobalFilter
    this.showFilters = false
    const globalFilter = this.originalGlobalFilters.find((x) => x.id === selGlobalFilter?.value)
    this.store.dispatch(addReportFilter({ isGlobalNull: true, filter: globalFilter }))
    this.reportsService.saveSessionFiltersReports(globalFilter).subscribe(() => {
      this.storage.setSelectedGlobalFilter(
        selGlobalFilter ? this.originalGlobalFilters.find((x) => x.id === selGlobalFilter.value) ?? null : null
      )
      this.location.reload()
    })
  }

  globalFilterInputChanged(input: string) {
    this.globalFilters = this.originalGlobalFilters
      .filter((x) => x.name.toLowerCase().includes(input?.toLowerCase() || ''))
      .map((x) => ({ value: x.id, displayValue: x.name }))
  }

  licensePoolChanged(license: Suggestion | null) {
    this.selectedLicense = license
    this.showFilters = false
    this.administrationsService.setCompanyLicensePoolsFilter(license ? { licensePoolGroupId: license.value } : null).subscribe(() => {
      this.storage.setSelectedGroupFilter(license?.value || null)
      this.location.reload()
    })
  }

  licensePoolInputChanged(input: string) {
    this.licensePools = this.originalLicensePools
      .filter((x) => x.name.toLowerCase().includes(input?.toLocaleLowerCase() || ''))
      .map((x) => ({ value: x.id, displayValue: x.name }))
  }

  getStateName(name: string) {
    if (name === 'Progress') {
      return this.translateHelper.instant('common_OnProgress')
    }
    return name
  }

  userGroupsChanged(userGroups: Suggestion[] | null) {
    this.showFilters = false
    this.administrationsService.setUserGroups(userGroups?.map((x) => x.value) ?? null).subscribe(() => {
      this.location.reload()
    })
  }

  userGroupsInputChanged(input: string) {
    this.userGroups = this.originalUserGrops
      .filter((x) => x.name.toLowerCase().includes(input?.toLocaleLowerCase() ?? ''))
      .map((x) => ({ value: x.id, displayValue: x.name }))
  }

  customMenusChanged(custoMenus: Suggestion[] | null) {
    this.showFilters = false
    this.administrationsService.setCustomMenusMembership(custoMenus?.map((x) => x.value) ?? null).subscribe(() => {
      this.location.reload()
    })
  }

  customMenusInputChanged(input: string) {
    this.customMenus = this.originalCustomMenus
      ?.filter((x) => x.name.toLowerCase().includes(input?.toLocaleLowerCase() || ''))
      .map((x) => ({ value: x.id, displayValue: x.name }))
  }

  virtualTenantsSubfilterChanged(custoMenus: Suggestion | null) {
    this.showFilters = false
    this.administrationsService.setVTenantSubfilter(custoMenus?.value || '').subscribe(() => {
      this.location.reload()
    })
  }

  virtualTenantsSubfilterInputChanged(input: string) {
    this.virtualTenantsSubfilters = this.originalVirtualTenantsSubfilters
      .filter((x) => x.userGroupName.toLowerCase().includes(input?.toLocaleLowerCase() ?? ''))
      .map((x) => ({ value: x.userGroupId, displayValue: x.userGroupName }))
  }

  removeAllFilters() {
    this.showFilters = false
    this.storage.setSelectedGroupFilter(null)
    this.storage.setSelectedGlobalFilter(null)
    combineLatest([
      this.administrationsService.removeUserFromAllFilters(),
      this.activatedDemoMode ? this.commercialPortalSkusService.exitDemoMode() : of(null),
    ]).subscribe(() => {
      this.location.reload()
    })
  }

  changeTenant(tenant: ManagedTenant) {
    this.storage.selectedOrganization = { id: tenant.managedTenantId, text: tenant.managedTenantName }
    this.store.dispatch(requestManagedTenantChange({ tenant }))
  }

  supportLogin(tenant: ManagedTenant & {grantedOperator: string}) {
    this.authenticationService.logout()
    this.location.href = `?state=${tenant.grantedOperator}`
  }

  changedFilter(event: any): void {
    this.filterChanged$.emit(event)
  }

  getFilteredTenants() {
    return this.managedTenants?.filter(
      (x) => x.managedTenantName && x.managedTenantName.toUpperCase().indexOf(this.filter.toUpperCase()) >= 0)
  }

  manageGlobalFilter() {
    this.reportsService.showReportsFiltersPanel('User')
  }

  navigate(route: string | undefined) {
    if (route) {
      this.router.navigate([route]).catch((_: any) => _)
    }
  }

  goToLink(url: string) {
    this.window.open(url)
  }

  formatDate(dateString: string | undefined) {
    if (!dateString) {
      return ''
    }
    const date = dayjs(dateString).utc(false)
    if (date.isToday()) {
      return this.translateHelper.instant('common_Today') + ' ' + date.format('LT')
    }
    if (date.isYesterday()) {
      return this.translateHelper.instant('common_Yesterday') + ' ' + date.format('LT')
    }
    return date.format('LLL')
  }

  getStatusDetail(state: string) {
    return Constants.taskStatusDetailsMap[state]
  }

  selectTaskChanged() {
    this.loadTasks(this.taskInput, this.taskSelect)
  }

  closeNotificationsPanel() {
    this.tasks = []
    this.showNotifications = false
    this.taskInput = ''
    this.taskSelect = ''
    this.notificationsNumber = 0
    this.shownTask = null
    this.shownAggregatedTask = null
  }

  clearTasks() {
    this.tasks = []
    this.notificationsNumber = 0
  }

  loadTasks(title?: string, state?: string) {
    this.defaultTasksParams.filters = JSON.stringify({
      IsBackground: false,
      Title: title ?? '',
      State: state && state !== '' ? state : undefined,
    })
    this.tasksService
      .getNotificationTasks(this.defaultTasksParams)
      .pipe(delay(500))
      .subscribe((tasks) => {
        this.tasks = tasks
        this.loadingTasks = false
      })
  }

  getTaskTitleTranslated(title: string): string {
    return this.translateHelper.tryTranslateWithPreffix('management', title)
  }

  showTaskDetails(task: AggregatedAsyncTask) {
    if (task.isAggregated) {
      this.shownAggregatedTask = task
    } else {
      this.shownTask = task
    }
  }

  reloadTaskDetail() {
    if (this.shownTask) {
      this.tasksService.getNotificationTasks({ pageSize: 100 }, this.shownTask.aggregationId).subscribe((tasks) => {
        if (tasks.length) {
          this.shownTask = tasks.find((x) => x.asyncTaskId === this.shownTask?.asyncTaskId) ?? this.shownTask
        }
      })
    }
  }

  getItems = () => (params: ApiDataParameters) => ({
    items: this.reportsService.getData(
      `/aggregatedasynctasks/${this.shownAggregatedTask?.aggregationId ?? ''}`,
      this.reportGridDefinition.verb,
      {
        ...params,
      } as any
    ),
    cols: of([
      {
        originalName: 'Title',
        name: 'title',
        translate: 'Title',
        type: 'string',
        filter: { type: 'string', name: 'title' },
        agColDef: {
          cellStyle: () => ({ cursor: 'pointer' }),
          cellRenderer: ({ value }: any): any => `<span style="color: #306AC8; cursor: pointer">${value}</span>`,
        },
      },
      {
        originalName: 'State',
        name: 'state',
        translate: 'State',
        type: 'string',
        filter: { type: 'string', name: 'AsyncTaskState' },
        agColDef: {
          cellRenderer: 'badgeRenderer',
          cellRendererParams: {
            getVariant: (value: string) => Constants.taskStatusDetailsMap[value || '']?.variant,
            getIcon: (value: string) => Constants.taskStatusDetailsMap[value || '']?.icon,
          },
        },
      },
    ]),
  })

  getManagementStatus(): { text: string; icon: string; iconOpacity: string } | null {
    switch (this.managementStatus?.status) {
      case 'Enabled':
        return { text: 'common_ManagementON', icon: 'power_settings_new', iconOpacity: '1' }
      case 'Disabled':
      case 'Failed':
        return { text: 'common_ManagementOFF', icon: 'power_settings_new', iconOpacity: '0.6' }
      case 'Enabling':
        return { text: 'common_ManagementEnabling', icon: 'timelapse', iconOpacity: '0.6' }
      case 'Disabling':
        return { text: 'common_ManagementDisabling', icon: 'timelapse',iconOpacity: '0.6' }
    }
    return null
  }

  isWorkflowTask = (task: AggregatedAsyncTask): boolean => !!task?.workflowExecutionId

  onWorkflowExecutionClick = (task: AggregatedAsyncTask) => {
    this.router.navigate(['/workflow/list', task.workflowId], {
      queryParams: { workflowExecutionId: task?.workflowExecutionId, k: Date.now() },
    })
    this.closeNotificationsPanel()
  }

  toggleDarkMode() {
    this.darkMode = !this.darkMode
    this.themeChanged.emit(this.darkMode ? 'dark' : 'light')
  }

  showGrantAccessTenantDetails(item: ManagedTenant) {
    this.administrationsService.getGrantSupportAccessById(item.id)
    .subscribe((item) => {
      const panelRef = this.rightPanelService.open({
        type: GrantAccessDetailsComponent,
        data: { width: '50%', item },
      })
  
      panelRef.afterClosed().subscribe()
    })
    
  }

  private checkSkuAvailability(portalSkus: ReadonlyArray<string>) {
    this.showFilterTab = Helpers.hasAnySkus(portalSkus, ['FT:SHOWFILTERTAB', 'FT:SKUDEMO']) || this.showDemoMode
    this.showGlobalSearch = Helpers.hasAnySkus(portalSkus, ['FT:SHOWGSEARCH'])
    this.showLPVT = Helpers.hasAnySkus(portalSkus, ['FT:SHOWLPVT'])
    this.showPermissions = Helpers.hasAnySkus(portalSkus, ['FT:SHOWPERMISSIONS'])
    this.showVirtualTenantsSubfilter = Helpers.hasAnySkus(portalSkus, ['FT:VTENANTSUBFILTER'])
  }

  private closeSearchAndPanelsWhenClickingElsewhere(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement
    if (!clickedElement || clickedElement.closest('.cdk-overlay-container')) {
      return
    }

    if (this.showFilters && !clickedElement.closest('.filters')) {
      this.showFilters = false
      return
    }

    if (this.showManagement && !clickedElement.closest('.management')) {
      this.showManagement = false
      return
    }

    if (this.showNotifications && !clickedElement.closest('.notifications') && !clickedElement.closest('.ag-popup')) {
      this.closeNotificationsPanel()
      return
    }

    if (this.showUserMenu && !clickedElement.closest('.userMenu')) {
      this.showUserMenu = false
      return
    }

    if (this.showTenants && !clickedElement.closest('.tenants')) {
      this.showTenants = false
    }
  }

  private initVirtualTenantsSubfilter(selected: SelectedUserGroups[]) {
    this.originalVirtualTenantsSubfilters = selected
    this.virtualTenantsSubfilters = selected.map((x) => ({ value: x.userGroupId, displayValue: x.userGroupName }))
  }
}

<cv-select
  *ngIf="(targetsCount === 1 || licensePoolMode === 'Assign') && !isWorkflow"
  [(ngModel)]="selectedLicensePoolId"
  (selectionChanged)="licensePoolSelected()"
  [label]="'common_LicensePool' | translate"
  [suggestions]="licensePoolSuggestions"
  style="width: 100%"
  cv-data-test="mgmt-select-license-pool"
></cv-select>
<cv-select
  style="width: 100%"
  *ngIf="hasTemplatesToShow"
  [(ngModel)]="selectedTemplateId"
  (selectionChanged)="templateSelected($event)"
  [label]="'common_LicenseTemplate' | translate"
  [suggestions]="templateSuggestions"
  cv-data-test="mgmt-select-template"
></cv-select>
<cv-input
  [label]="'common_Search' | translate"
  rightIcon="search"
  [placeholder]="'common_searchLicense' | translate"
  (inputChange)="filterChanged($event)"
  style="width: 100%"
  class="p-b-10"
  [clearButton]="true"
></cv-input>
<ng-container *ngIf="!loading && !valueLoading">
  <div *ngIf="isShowNoResult()">
    <span translate>generic_NoResults</span>
  </div>
  <div *ngIf="isShowNoLicense()">
    <span translate>licenses_NoLicenseFound</span>
  </div>
  <div class="license" [class.expanded]="l.expanded" [class.disabled]="!!l.disabled" [class.hidden]="l.hidden" *ngFor="let l of licenses">
    <div class="d-fl">
      <mat-icon (click)="l.expanded = l.disabled ? false : !l.expanded" class="chevron">{{
        !l.expanded ? 'chevron_right' : 'expand_more'
      }}</mat-icon>
      <div class="fl-1" style="margin: auto">{{ l.sku }}</div>
      <cv-tertiary-dropdown
        class="m-r-20"
        [options]="getOptions(l.remainingUnits, l.oldStatus)"
        [value]="l.status === 'removed' ? 'remove' : l.status === 'assigned' ? 'assign' : 'ignore'"
        (selectionChanged)="licenseStatusChanged(l, $any($event))"
        [disabled]="!!l.disabled"
        cv-data-test="mgmt-select-license"
        #comp
      ></cv-tertiary-dropdown>
    </div>
    <div style="font-size: 10px; line-height: 10px" class="m-l-20">
      <span translate>common_AvailableLicenes</span>: {{ l.remainingUnits }}
    </div>
    <div style="font-size: 10px; line-height: 10px" class="m-l-20 m-t-5">
      <span translate>common_EnabledServices</span>: {{ l.selectedPlansCount }}/{{ l.plans?.length }}
    </div>
    <div class="plan-container" *ngIf="l.expanded">
      <div class="d-fl" [class.disabled]="p.disabled" [class.hidden]="p.hidden" *ngFor="let p of l.plans">
        <div class="fl-1" style="margin: auto">{{ p.title }}</div>
        <cv-tertiary-dropdown
          [options]="getPlansOptions(l.remainingUnits, l.oldStatus, p.oldStatus)"
          [value]="p.status === 'enabled' ? 'enable' : p.status === 'disabled' ? 'disable' : 'ignore'"
          (selectionChanged)="planStatusChanged(l, p, $any($event))"
          [disabled]="!!p.disabled"
          cv-data-test="mgmt-select-plan"
          #comp
        ></cv-tertiary-dropdown>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="loading || valueLoading">
  <div
    class="loading-skeleton"
    style="display: flex; flex-direction: column; gap: 10px; border-radius: 4px; background-color: var(--opaque-light-contrast-color)"
    *ngFor="let _ of [].constructor(5)"
  >
    <div style="height: 83px; width: 100%"></div>
  </div>
</ng-container>

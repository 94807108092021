<app-panel-steps
  *ngIf="steps.length > 0"
  [steps]="steps"
  [panelTitle]="'licenses_ManagePricePerUnit' | translate"
  [isSaving]="isSaving"
  (save)="save()"
  cv-data-test="manage-price-unit-panel"
>
  <div step [class.hidden]="panelSteps?.activeStep?.stepKey !== 'licenses_SelectTarget'" class="flex-full-height">
    <div class="flex-full-height">
      <app-target-selection
        *ngIf="((configuration?.targetSelectionDefinition?.getTargetsToSelect | async) || []).length > 0"
        [selectedTargets]="selectedTargetsObject.licenses"
        [options]="configuration.targetSelectionConfiguration"
        [targetsToSelectDefinition]="configuration.targetSelectionDefinition"
        (selectedTargetsChanged)="onSelectedTargetsChanged($event)"
      >
      </app-target-selection>
    </div>
  </div>

  <div step [class.hidden]="panelSteps?.activeStep?.stepKey !== 'licenses_SetPrice'" class="flex-full-height">
    <div style="overflow: auto; flex: 1; padding: 0 12px; margin: 0 -12px">
      <cv-alert-bar *ngIf="selectedTargetsObject.licenses.length === 0" [hideCloseButton]="true" variant="error">
        {{ 'licenses_LicensesRequired' | translate }}</cv-alert-bar
      >

      <app-license-cost-allocator *ngIf="selectedTargetsObject.licenses.length !== 0" [data]="allocatorData"></app-license-cost-allocator>
    </div>
  </div>

  <div step [class.hidden]="panelSteps?.activeStep?.stepKey !== 'complete'" class="flex-full-height summary">
    <div class="panel-container">
      <div class="cards-container">
        <div class="panel">
          <div class="panel-title">
            <span style="flex: 1" translate>Targets</span>
            <cv-button
              (click)="clickTab(0)"
              class="edit"
              buttonType="tertiary"
              leftIcon="edit"
              [text]="'common_Edit' | translate"
            ></cv-button>
          </div>
          <div class="panel-subtitle" translate>licenses_TargetsSelected</div>
          <div class="panel-content">
            <div *ngFor="let item of selectedTargetsObject.licenses" class="panel-content-item">
              {{ item.sku }}
            </div>
          </div>
        </div>

        <div class="panel">
          <div class="panel-title">
            <span style="flex: 1" translate>licenses_PriceChanges</span>
            <cv-button
              (click)="clickTab(1)"
              class="edit"
              buttonType="tertiary"
              leftIcon="edit"
              [text]="'common_Edit' | translate"
            ></cv-button>
          </div>
          <div class="panel-subtitle" translate>licenses_PriceChangesSubtitle</div>
          <div class="panel-content">
            <div class="panel-content-item">
              <div *ngFor="let i of licensesToSave; last as isLast">
                <div style="font-weight: 600">{{ i.sku }}</div>

                <ng-container *ngFor="let prop of allocatorProps">
                  <div *ngIf="i[prop.keyNew] !== i[prop.keyCurrent]">
                    <span translate>{{ prop.titleNew }}</span
                    >:
                    <span>{{ i[prop.keyNew] }}</span>
                  </div>
                </ng-container>

                <span class="divider" *ngIf="!isLast">&nbsp;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-panel-steps>

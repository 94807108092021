<div class="primary-color text" style="flex: 1">
  <span
    [innerHTML]="
      'generic_ShowingItems' | translate : { fromRow: fromRow(), toRow: toRow(), rowCount: gridApi?.paginationGetRowCount() || 0 }
    "
  ></span>
  &nbsp;
  <span *ngIf="getSelectedRowsCount() > 0"
    ><span translate>common_Selected</span>: <b>{{ getSelectedRowsCount() }}</b></span
  >
</div>
<div class="pages-container">
  <mat-icon
    [class.page-arrow-active]="getCurrentPage() !== 1"
    class="primary-color page-arrow"
    (click)="firstPage()"
    style="margin-right: 6px"
    >first_page</mat-icon
  >
  <mat-icon
    [class.page-arrow-active]="getCurrentPage() !== 1"
    class="primary-color page-arrow"
    (click)="prevPage()"
    style="margin-right: 12px"
    >chevron_left</mat-icon
  >
  <ng-container *ngFor="let page of pages">
    <span *ngIf="page !== -1" [class.page-active]="page === getCurrentPage()" class="primary-color page" (click)="setPage(page)">
      {{ page }}</span
    >
    <span class="dots" *ngIf="page === -1">...</span>
  </ng-container>
  <mat-icon
    [class.page-arrow-active]="getCurrentPage() < getPagesTotal()"
    class="primary-color page-arrow"
    (click)="nextPage()"
    style="margin-right: 6px"
    >chevron_right</mat-icon
  >
</div>
<div class="select-container">
  <span translate class="primary-color text" id="common_RowsPerPage">common_RowsPerPage</span>
  <mat-select class="primary-color select" (selectionChange)="pageSizeSelected($event)" hideSingleSelectionIndicator [value]="pageSize">
    <mat-option value="10">10</mat-option>
    <mat-option value="25">25</mat-option>
    <mat-option value="50">50</mat-option>
    <mat-option value="100">100</mat-option>
  </mat-select>
</div>

import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { createAction, props } from '@ngrx/store'

export const getReportsDefinition = createAction('[ReportsDefinition] GET request')
export const getReportsDefinitionResponse = createAction('[ReportsDefinition] GET resp', props<{ reports: ReportDefinition[] }>())
export const getReportsDefinitionInvalidate = createAction(
  '[ReportsDefinition] GET invalidates',
  props<{ error: { code: number; message: string } }>()
)

export const getReportDefinitionById = createAction('[ReportsDefinition] GET By Id request', props<{ entity: string }>())
export const getReportDefinitionByIdResponse = createAction('[ReportsDefinition] GET By Id resp', props<ReportDefinition>())
export const getReportDefinitionByIdInvalidate = createAction(
  '[ReportsDefinition] GET By Id invalidates',
  props<{ error: { code: number; message: string } }>()
)

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { CommunicationService } from '@core/services/communication.service'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core'
import { CoreModule } from '@core/core.module'
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { RootStoreModule } from '@app/store/store.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CoreViewMissingTranslationHandlerFactory } from '@app/app.translation-loader'
import { ApplicationInsightService } from './core/services/application-insight.service'
import { ErrorHandlerService } from '@app/error-handler.service'
import { RoutesService } from '@core/services/routes.service'
import { EncodeHttpParamsInterceptor } from './core/interceptors/encode-httpParams.interceptor'
import { RouterModule } from '@angular/router'
import { LocalstorageService } from './core/services/localstorage.service'
import { TranslateHelper } from '@coreview/coreview-library'
import { AuthenticationService } from './core/services/authentication.service'
import { ApiclientService } from './core/services/apiclient.service'
import { FroalaConfigService } from '@coreview/coreview-dynamoforms'
import { Store } from '@ngrx/store'
import { RootState } from './store/RootState.type'
import { UserSettingsService } from './core/services/user-settings.service'
import { appInitializer } from './core/initializers/app.initializer';

/**
 * The root module, bootstraps the application
 */
@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RootStoreModule,
    CoreModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: CoreViewMissingTranslationHandlerFactory,
        deps: [ApplicationInsightService],
      },
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [
        CommunicationService,
        TranslateHelper,
        HttpClient,
        LocalstorageService,
        AuthenticationService,
        ApiclientService,
        RoutesService,
        FroalaConfigService,
        Store<RootState>,
        UserSettingsService,
      ],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
  ],
})
export class AppModule {}

<div *ngIf="value" style="font-size: 12px; margin-left: 75px">
  {{ value }}
</div>
<div *ngIf="html" [innerHtml]="html" style="font-size: 12px"></div>
<div *ngIf="tableDataField" style="font-size: 12px">
  <ag-grid-angular
      domLayout="normal"
      class="ag-theme-alpine ag-theme-coreview"
      style="width: 100%; height: 300px; flex: 1"
      [rowData]="tableDataField"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [modules]="modules"
      (gridReady)="onGridReady($event)"
      (rowDataChanged)="rowDataChanged()"
      (gridSizeChanged)="gridSizeChanged()"
      [components]="frameworkComponents"
      [rowSelection]="rowSelection"
      (rowSelected)="onChildRowSelected($event)"
      [enableRangeSelection]="true"
      [getContextMenuItems]="getContextMenuItems"
      [processCellForClipboard]="processCellForClipboard"
    >
    </ag-grid-angular>
</div>

import { Component } from '@angular/core'
import { ICellRendererAngularComp } from '@ag-grid-community/angular'
import { Column, ColumnApi, GetContextMenuItemsParams, GridApi, MenuItemDef, ProcessCellForExportParams } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { BuildColAgGrid } from '@app/shared/utilities/build-col-ag-grid'

@Component({
  selector: 'app-dictionary-cell-renderer',
  templateUrl: './dictionary-cell-renderer.component.html',
  styleUrls: ['./dictionary-cell-renderer.component.sass'],
})
export class DictionaryCellRendererComponent implements ICellRendererAngularComp {
  dictionaryFieldsKeys?: string[]
  defaultColDef = {
    flex: 1,
    filter: true,
    sortable: true,
  }
  rowData: any = {}
  columnDefs: any = {}
  gridApi!: GridApi
  columnApi!: ColumnApi
  modules = [ClientSideRowModelModule]

  constructor(private buildColumnsHelper: BuildColAgGrid) {}

  agInit(params: any): void {
    this.dictionaryFieldsKeys = params.columnApi
      .getAllDisplayedColumns()
      .filter((c: Column) => c.getUserProvidedColDef()?.cellRendererParams === 'dictionaryRenderer')
      .map((c: Column) => c.getColId())
    this.dictionaryFieldsKeys?.forEach((key) => {
      this.rowData[key] = Object.keys(params.data[key]).map((k) => ({ [key]: k, value: params.data[key][k] }))
      this.columnDefs[key] = [{ field: key, suppressSizeToFit: true }, { field: 'value' }]
    })
  }

  onGridReady(params: any) {
    this.gridApi = params.api
    this.columnApi = params.columnApi
  }

  refresh(params: any): boolean {
    return false
  }

  rowDataChanged = () => {
    this.columnApi?.autoSizeAllColumns()
    this.gridApi?.sizeColumnsToFit()
  }

  gridSizeChanged = () => {
    this.columnApi?.autoSizeAllColumns()
    this.gridApi?.sizeColumnsToFit()
  }

  getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] =>
    this.buildColumnsHelper.getContextMenuItems(this.gridApi)

  processCellForClipboard = (params: ProcessCellForExportParams) => this.buildColumnsHelper.processCellForClipboard(params)
}

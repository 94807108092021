import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { forkJoin, of } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'

import {
  getWorkflowActionCategoryLookups,
  getWorkflowActionCategoryLookupsInvalidate,
  getWorkflowActionCategoryLookupsResponse,
} from './workflow-action-category-lookups.actions'
import { WorkflowBuilderHttpService } from '@app/modules/workflow/http/workflow-builder.http'

@Injectable()
export class WorkflowActionCategoryLookupsEffects {
  getWorkflowActionCategoryLookups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWorkflowActionCategoryLookups),
      mergeMap(() =>
        this.workflowBuilderHttp.fetchCatalogProviders().pipe(
          mergeMap((resp) => {
            const items = resp.data.items || []
            const request = items.reduce(
              (acc, item) => ({ ...acc, [item.providerId]: this.workflowBuilderHttp.fetchProviderCategories(item.providerId) }),
              {}
            )

            items.forEach((item) => this.providerMap.set(item.providerId, item))

            return forkJoin(request)
          }),
          map((categories) => Object.values(categories).reduce((acc: any[], curr: any) => [...acc, ...curr.data.items], [])),
          map((categories) => categories.map((category) => ({ ...category, parentProvider: this.providerMap.get(category.providerId) }))),
          map((categories) => getWorkflowActionCategoryLookupsResponse({ lookups: categories })),
          catchError((error) => of(getWorkflowActionCategoryLookupsInvalidate({ error: { code: error.status, message: error.message } })))
        )
      )
    )
  )

  private providerMap = new Map()

  constructor(private actions$: Actions, private workflowBuilderHttp: WorkflowBuilderHttpService) {}
}

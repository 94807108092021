/* eslint-disable @typescript-eslint/member-ordering, @angular-eslint/no-output-native */

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ReplaySubject } from 'rxjs';
import { TreeNode } from './models/Tree'

@Component({
  selector: 'app-tree-view',
  styleUrls: ['./tree-view.component.sass'],
  template: `
    <app-tree-view-single
      class="fl-1"
      *ngIf="type === 'single'"
      [readonly]="readonly"
      [data]="data"
      [selected]="selected"
      [searchable]="searchable"
      [searchLabel]="searchLabel"
      [preventTranslation]="preventTranslation"
      (selectionChanged)="handleChange($event)"
    ></app-tree-view-single>
    <app-tree-view-multi
      class="fl-1"
      *ngIf="type === 'multi'"
      [readonly]="readonly"
      [data]="data"
      [selected]="multiSelected"      
      [disabled]="disabled"
      [hideSelectAll]="hideSelectAll"
      [expandItems]="expandItems"
      [searchable]="searchable"
      [searchLabel]="searchLabel"
      [preventTranslation]="preventTranslation"
      [selectAll$]="selectAll$"
      (selectionChanged)="handleMultiChange($event)"
    ></app-tree-view-multi>
  `,
})
export class TreeViewComponent<T extends { text: string | number; icon?: string; children: T[] }> {
  @Input()
  type: 'multi' | 'single' = 'single'

  @Input()
  selected?: number = undefined

  @Input()
  disabled: (number | string)[] = []

  @Input()
  multiSelected: (number | string)[] = []

  @Input()
  data!: T[]

  @Input()
  readonly = false

  @Input()
  hideSelectAll = false

  @Input()
  expandItems = true

  @Input()
  searchable = false

  @Input()
  searchLabel = 'common_Search'

  @Input()
  preventTranslation = false

  @Input() selectAll$: ReplaySubject<boolean> = new ReplaySubject()

  @Output()
  selectionChanged: EventEmitter<TreeNode | null | TreeNode[]> = new EventEmitter()

  public handleChange($event: TreeNode | null) {
    this.selectionChanged.emit($event)
  }

  public handleMultiChange($event: TreeNode[]) {
    this.selectionChanged.emit($event)
  }
}

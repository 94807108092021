import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ManagementStatusState } from './management-status.types'

export const selectManagementStatusState = createFeatureSelector<ManagementStatusState>('managementStatus')

export const selectManagementStatus = createSelector(
    selectManagementStatusState,
    (status) => !!status.entities?.length ? status.entities[0] : null
)

// export const selectManagementActionsLoading = createSelector(
//   selectManagementActionState,
//   state => state.isFetching
// )

// export const selectManagementActionsError = createSelector(
//     selectManagementActionState,
//     state => state.error
//   )

import { Component, Input } from '@angular/core'
import { SummaryCard } from '@app/shared/models/summary-card'
import { TranslateHelper } from '@coreview/coreview-library'
import { isArray } from 'lodash-es'

@Component({
  selector: 'app-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.sass'],
})
export class SummaryCardComponent {
  @Input() cardInfo!: SummaryCard
  @Input() isHtml = true
  @Input() truncateValues?: number

  constructor(private translateHelper: TranslateHelper) { }

  order = () => 0

  getKey(key: string): string {
    return this.translateHelper.instant(key)
  }

  isTable(value: any): boolean {
    return !!value?.headers
  }

  isTree(value: any): boolean {
    return !!value?.treeData
  }

  isArray(value: any): boolean {
    return isArray(value)
  }

  isObject(value: any): boolean {
    return !!value && typeof value === 'object'
  }
}

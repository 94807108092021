import isNil from 'lodash-es/isNil'
import isObject from 'lodash-es/isObject'
import isArray from 'lodash-es/isArray'
import isEmpty from 'lodash-es/isEmpty'
import isEqual from 'lodash-es/isEqual'

const isTrue = (value: any): boolean => {
  if (!isBoolean(value)) {
    return false
  }

  return value === true
}

const isNumber = (value: any): boolean => typeof value === 'number'

const isString = (value: any): boolean => typeof value === 'string'

const isEmptyString = (value: any): boolean => isNil(value) || value === ''

const isEmptyObject = (obj: any) => Object.keys(obj).length === 0

const isIE = (): boolean =>
  window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1

const isBoolean = (value: any): boolean => value === true || value === false

const isSafari = (): boolean =>
  navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1

const hasInputDateSupport = (): boolean =>
  isIE() || (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1)

export const assert = {
  hasInputDateSupport,
  isBoolean,
  isEmptyString,
  isEmptyObject,
  isIE,
  isNil,
  isEmpty,
  isEqual,
  isArray,
  isNumber,
  isObject,
  isSafari,
  isString,
  isTrue,
}

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnDestroy, OnInit, OnChanges, Optional, Self, SimpleChanges, Output, EventEmitter } from '@angular/core'
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, NgControl } from '@angular/forms'
import { Store } from '@ngrx/store'
import { BaseControlComponent, Suggestion } from '@coreview/coreview-components'
import { forkJoin, Observable, Subject } from 'rxjs'
import { debounceTime, delay, distinctUntilChanged, filter, pluck, takeUntil } from 'rxjs/operators'
import { selectedOrganization } from '@app/store/organizations/organizations.selectors'
import { OrganizationService } from '@app/core/services/organization.service'
import { ManagementActionsService } from '@app/core/services/management-actions.service'
import { Constants } from '@shared/utilities/constants'
import { ExtensionAttributesMapping } from '@app/core/models/Organization'
import { AccountType } from '@app/core/enums/account-type'
import { TranslateHelper } from '@coreview/coreview-library'
import { groupBy, isArray, remove, uniq } from 'lodash-es'
import { UniversalSearchService } from '@app/core/services/universal-search.service'
import { selectUserSettings } from '@app/store/userSettings/userSettings.selectors'
import { AdministrationsService } from '@app/core/services/administrations.service'
import { Helpers } from '@app/shared/utilities/helpers'
import { CustomComponentReady } from '@coreview/coreview-dynamoforms'

@Component({
  selector: 'app-dynamic-fields',
  templateUrl: './dynamic-fields.component.html',
  styleUrls: ['./dynamic-fields.component.sass'],
})
export class DynamicFieldsComponent extends BaseControlComponent implements OnInit, OnDestroy, OnChanges, CustomComponentReady {
  @Input() managementActionType!: DynamicFieldsActions
  @Input() targetsAccountTypes?: AccountType[]

  @Input()
  get value(): { key: string; value: any; title: string; isExtensionAttribute: boolean }[] {
    return this.form.getRawValue()
  }

  set value(model: any) {
    /* we don't need a set value as the initial state is always empty, but don't remove this */
  }

  @Output() customComponentReady = new EventEmitter<void>()

  fields: Record<string, FieldDefinition> = {}
  fieldPlaceholder = 'management_SelectFieldToEdit'
  addedFields: string[] = []
  availableFields: Suggestion[] = []

  form = new UntypedFormArray([])
  formReady = false
  canAddNewControl = true

  disclaimer?: {
    title?: string;
    message?: string;
    secondaryMessageTranslated?: string;
  }

  suggestions: Record<string, Suggestion[]> = {}
  suggestersSubjects: Record<string, Subject<string>> = {}
  booleanSuggestions: Suggestion[] = [
    {
      value: true,
      displayValue: 'True',
    },
    {
      value: false,
      displayValue: 'False',
    },
  ]

  canViewPortalAttributes?: boolean
  allExtensionAttributes: ExtensionAttributesMapping[] = []
  extensionAttributesActions: DynamicFieldsActions[] = ['EditUserPropertiesInBulk', 'SetPortalAttributes']
  extensionAttributesAccountTypes = [AccountType.OnCloud, AccountType.Synchronized]
  localeIso!: string

  private destroyed$: Subject<boolean> = new Subject()

  constructor(
    private store: Store,
    private organizationService: OrganizationService,
    private managementActionsService: ManagementActionsService,
    private translateHelper: TranslateHelper,
    private universalSearchService: UniversalSearchService,
    private administrationService: AdministrationsService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(ngControl)
  }

  ngOnInit(): void {
    const initialCalls: { [key: string]: Observable<any> | null } = {}

    this.store
      .select(selectedOrganization)
      .pipe(
        filter((x) => !!x),
        takeUntil(this.destroyed$)
      )
      .subscribe((org) => {
        if (this.extensionAttributesActions.includes(this.managementActionType)) {
          initialCalls.extensionAttributes = this.organizationService.getExtensionAttributes(org?.guid || '')
          if (this.managementActionType === 'EditUserPropertiesInBulk') {
            initialCalls.canViewPortalAttributes = this.managementActionsService.canViewAction(
              Constants.managementActionsIds.setPortalAttributes
            )
          }
        } else if (this.managementActionType === 'ManageTeamsPolicies') {
          this.fieldPlaceholder = 'management_SelectPolicyToEdit'
          initialCalls.policies = this.managementActionsService.getTeamsPolicies()
          initialCalls.readonlyPolicies = this.managementActionsService.getReadonlyPolicies()
        }

        forkJoin(initialCalls).subscribe((results: any) => {
          if (this.extensionAttributesActions.includes(this.managementActionType)) {
            this.allExtensionAttributes = results.extensionAttributes

            if (this.managementActionType === 'EditUserPropertiesInBulk') {
              this.canViewPortalAttributes = results.canViewPortalAttributes
              this.setEditUserPropertiesFields()
            }

            this.disclaimer = {
              message: 'management_EditUserBulkInfoMessage',
            }

            if (!!this.canViewPortalAttributes || this.managementActionType === 'SetPortalAttributes') {
              this.setPortalAttributesFields()
              // this is so we can wait until account types arrive before calculating possibilities for extension attributes
              if (this.targetsAccountTypes === undefined || (isArray(this.targetsAccountTypes) && !!this.targetsAccountTypes.length)) {
                this.setExtensionAttributesFields()
              }
              this.disclaimer.secondaryMessageTranslated = this.translateHelper.instant('management_ExtensionAttributesMappingDisclaimer')
            }
          } else if (this.managementActionType === 'ManageTeamsPolicies') {
            this.disclaimer = {
              message: 'management_ManageTeamUserPolicyInfoMessage',
            }

            if (results.readonlyPolicies?.teamsReadonlyPolicies?.length) {
              // eslint-disable-next-line max-len
              this.disclaimer.secondaryMessageTranslated = `${this.translateHelper.instant(
                'management_TeamUserReadonlyPoliciesInfoMessage'
              )} ${results.readonlyPolicies.teamsReadonlyPolicies.join(', ')}.`
            }

            const groupedPolicies = groupBy(results.policies.optionValues, 'name')
            this.setTeamsPoliciesFields(groupedPolicies)
          }
          this.availableFields = this.getAllFields()
          this.formReady = true
          this.customComponentReady.emit()
        })
      })

    this.store
      .select(selectUserSettings)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userSettings) => {
        if (userSettings) {
          const regionalSetting = Constants.regionalSettings.find((us) => us.fullLabel === userSettings.regionalSettings)
          this.localeIso = Helpers.isoRegionalSetting(regionalSetting)
        }
      })

    this.form.valueChanges.pipe(delay(1), takeUntil(this.destroyed$)).subscribe(() => {
      this.onChange(this.value)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.targetsAccountTypes) {
      const targetsAccountTypes = uniq(this.targetsAccountTypes)
      const allowedAccountTypes = [AccountType.Synchronized]
      if (targetsAccountTypes.includes(AccountType.OnCloud) || targetsAccountTypes.includes(AccountType.Synchronized)) {
        allowedAccountTypes.push(AccountType.OnCloud)
      }
      if (targetsAccountTypes.includes(AccountType.OnPremises) || targetsAccountTypes.includes(AccountType.Synchronized)) {
        allowedAccountTypes.push(AccountType.OnPremises)
      }
      this.extensionAttributesAccountTypes = allowedAccountTypes
      this.setExtensionAttributesFields()
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  getAvailableFields(currentValue?: string) {
    return this.availableFields.filter((x) => currentValue === x.value || !this.addedFields.includes(x.value))
  }

  getValueOptions(fieldKey: string): Suggestion[] {
    if (!!this.fields[fieldKey] && !!this.fields[fieldKey].valueOptions) {
      return this.fields[fieldKey].valueOptions || []
    }
    return []
  }

  deleteField(fieldKey: string) {
    const indexToRemove = this.form.getRawValue().findIndex((c) => c.key === fieldKey)
    if (indexToRemove > -1) {
      this.form.removeAt(indexToRemove)
      this.form.updateValueAndValidity()
    }
    remove(this.addedFields, (f) => f === fieldKey)
  }

  addControl() {
    this.form.push(
      new UntypedFormGroup({
        key: new UntypedFormControl(''),
        value: new UntypedFormControl(''),
        title: new UntypedFormControl(''),
        isExtensionAttribute: new UntypedFormControl(false),
      })
    )
    this.canAddNewControl = false
  }

  onChangeField(value: string) {
    const formValues = this.form.getRawValue()
    const currentIndex = formValues.findIndex((c) => c.key === value)
    this.form.at(currentIndex).patchValue({
      ...formValues[currentIndex],
      title: this.fields[value].label,
      isExtensionAttribute: this.fields[value].isExtensionAttribute || false,
    })
    this.canAddNewControl = true
    this.addedFields = this.form.getRawValue().map((x) => x.key)
  }

  getInputType(fieldKey: string): 'number' | 'text' | 'password' {
    return ['number', 'text', 'password'].includes(this.fields[fieldKey].type)
      ? (this.fields[fieldKey].type as 'number' | 'text' | 'password')
      : 'text'
  }

  private getAllFields() {
    return Object.values(this.fields).map((x) => ({ value: x.propKey, displayValue: x.label }))
  }

  private onSuggesterStringInputChange(fieldKey: string, query: string) {
    this.administrationService.getFieldsValues('onlineusers', fieldKey, query).pipe(
      pluck('values')
    ).subscribe((values) => {
      this.fields[fieldKey].valueOptions = values.filter((x: string) => !!x).map((y: string) => ({ value: y, displayValue: y }))
    })
  }

  // DO NOT change this arrow function to a normal function it'll lose the reference to universalSearchService
  private onManagerSuggesterInputChange = (fieldKey: string, query: string) => {
    this.universalSearchService.getUsers({ recipientTypeDetailsToExclude: 'GuestMailUser', search: query }).subscribe((res) => {
      this.fields[fieldKey].valueOptions = res.options?.map((y: any) => ({ value: y.uniqueId, displayValue: y.uniqueId }))
    })
  }

  private setEditUserPropertiesFields() {
    const userProperties: Partial<FieldDefinition>[] = [
      { propKey: 'StreetAddress', type: 'suggester-string' },
      { propKey: 'PostalCode', type: 'suggester-string' },
      { propKey: 'City', type: 'suggester-string' },
      { propKey: 'State', type: 'suggester-string' },
      { propKey: 'Country', type: 'suggester-string' },
      { propKey: 'PhoneNumber', type: 'text' },
      { propKey: 'MobilePhoneNumber', type: 'text' },
      { propKey: 'Fax', type: 'text' },
      { propKey: 'Office', type: 'suggester-string' },
      { propKey: 'Department', type: 'suggester-string' },
      { propKey: 'Manager', type: 'suggester-string', customSuggesterFunction: this.onManagerSuggesterInputChange },
      { propKey: 'Company', type: 'suggester-string' },
      { propKey: 'Title', type: 'suggester-string' },
    ]
    userProperties.forEach((x) => {
      x.label = this.translateHelper.instant(x.propKey as string)
      this.suggestersSubjects[x.propKey as string] = new Subject<string>()
      this.suggestersSubjects[x.propKey as string].pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
        if (x.customSuggesterFunction) {
          x.customSuggesterFunction(x.propKey as string, value)
        } else {
          this.onSuggesterStringInputChange(x.propKey as string, value)
        }
      })
      this.fields[x.propKey as string] = x as FieldDefinition
    })
  }

  private setPortalAttributesFields() {
    for (let index = 1; index <= 3; index++) {
      const propKey = `PortalAttribute${index}`
      this.fields[propKey] = {
        label: this.translateHelper.instant(propKey),
        propKey,
        type: 'suggester-string',
      }
      this.suggestersSubjects[propKey] = new Subject<string>()
      this.suggestersSubjects[propKey].pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
        this.onSuggesterStringInputChange(propKey, value)
      })
    }
  }

  private filterExtensionAttributes() {
    return this.allExtensionAttributes.filter((x: ExtensionAttributesMapping) =>
      this.extensionAttributesAccountTypes?.length ? this.extensionAttributesAccountTypes.includes(x.accountType as AccountType) : true
    )
  }

  private setExtensionAttributesFields() {
    this.filterExtensionAttributes().forEach((x) => {
      this.fields[x.name] = {
        label: x.displayName,
        propKey: x.name,
        type: x.type === 'Int' ? 'number' : x.type === 'Bool' ? 'bool' : x.type === 'DateTime' ? 'date' : 'text',
        isExtensionAttribute: true,
      }
    })
    this.availableFields = this.getAllFields()
  }

  private setTeamsPoliciesFields(policies: any) {
    let tempFields: any = {};

    Object.keys(policies).forEach((f: any) => {
      tempFields[f] = {
        label:
          f.lastIndexOf('Policy') > -1
            ? this.translateHelper.instant(f.substring(0, f.lastIndexOf('Policy')))
            : this.translateHelper.instant(f),
        propKey: f,
        type: 'select',
        valueOptions: policies[f].map((x: any) => ({ displayValue: x.value, value: x.value }))
          .sort((a: any, b: any) => a.value.localeCompare(b.value))
      };
    });

    const sortedKeys = Object.keys(tempFields).sort((a, b) => tempFields[a].label.localeCompare(tempFields[b].label));

    this.fields = {};

    sortedKeys.forEach(key => {
      this.fields[key] = tempFields[key];
    });
  }
}

type DynamicFieldsActions = 'EditUserPropertiesInBulk' | 'ManageTeamsPolicies' | 'SetPortalAttributes'
type FieldDefinition = {
  label: string;
  propKey: string;
  type: 'text' | 'select' | 'suggester-string' | 'date' | 'bool' | 'number';
  isExtensionAttribute?: boolean;
  customSuggesterFunction?: (field: string, query: string) => void;
  valueOptions?: Suggestion[];
}

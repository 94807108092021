<div class="main-container" attr.cv-data-test="{{ colId }}">
  <div class="loading-skeleton" *ngIf="!isLoaded">
    <div class="loading-row"></div>
    <div class="loading-reset-button loading-row"></div>
    <div class="loading-row"></div>
    <div class="loading-row"></div>
    <div class="loading-row"></div>
    <div class="loading-row"></div>
    <div class="loading-row"></div>
    <div class="loading-row"></div>
    <div class="loading-row"></div>
  </div>

  <ng-container *ngIf="isLoaded">
    <div style="padding: 5px 10px; width: 235px" *ngIf="filterOptions.length > 0">
      <select
        [disabled]="filterOptions.length === 1"
        *ngIf="filterOptions && filterOptions.length > 0"
        [(ngModel)]="type"
        (ngModelChange)="value = null; values = []; modelChanged()"
        style="
          font-size: 13px;
          font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
          width: 100%;
          margin: auto 0px;
          height: 27.6px;
          border-radius: 5px;
          padding-left: 6px;
          border: 1px solid var(--opaque-light-contrast-color);
          background: var(--default-surface-color);
          color: var(--default-text-color);
        "
        cv-data-test="select-filter-optionns"
        #select
      >
        <option *ngFor="let o of filterOptions" [ngValue]="o.key" translate>{{ o.value }}</option>
      </select>
    </div>
    <ng-container *ngIf="isShow()">
      <div class="filter-container" [class.m-t-0]="filterOptions.length > 0">
        <input
          [placeholder]="'common_Search' | translate"
          class="filter"
          [(ngModel)]="searchFilter"
          (ngModelChange)="searchChanged$.emit()"
          (click)="$event.stopPropagation()"
          #input
        />
        <mat-icon (click)="clearSearch(); $event.stopPropagation()" *ngIf="input.value" class="clear-icon">close </mat-icon>
      </div>
      <div style="display: flex; flex-direction: row">
        <cv-button
          size="tiny"
          (click)="resetColumns(); $event.stopPropagation()"
          class="reset-button"
          buttonType="tertiary"
          [text]="'common_ResetFilters' | translate"
          leftIcon="autorenew"
          cv-data-test="reset-button"
        ></cv-button>
      </div>
      <div class="columns-container">
        <mat-checkbox
          style="flex: 1"
          (click)="$event.stopPropagation()"
          [(ngModel)]="selectAll"
          (change)="checkAll($event)"
          class="select-all"
          attr.cv-data-test="{{ colId }}-sel-all"
          ><span translate>common_SelectAll</span></mat-checkbox
        >
        <mat-label *ngFor="let g of groupedOptions; last as isLast">
          <div *ngIf="g[0] !== 'undefined'">
            <b>{{ g[0] }}</b>
          </div>
          <mat-checkbox
            class="column-checkbox"
            (click)="$event.stopPropagation()"
            *ngFor="let col of getSortedItems(g[1]); trackBy: trackByGroupedFn"
            [checked]="col.locked"
            [(ngModel)]="col.checked"
            (change)="check(col, $event)"
            [disabled]="col.locked"
            style="display: flex"
          >
            <span
              [tooltip]="col.value"
              matTooltipPosition="left"
              tooltipClass="cv-tooltip"
              style="text-overflow: ellipsis; white-space: nowrap; max-width: 260px; overflow: hidden; display: block"
              >{{ col.value }}</span
            >
          </mat-checkbox>
          <span class="divider" *ngIf="!isLast">&nbsp;</span>
        </mat-label>
      </div>

      <div class="footer-buttons">
        <cv-button
          (click)="cancelFilter()"
          buttonType="tertiary"
          [text]="'common_Cancel' | translate"
          cv-data-test="cancel-button"
        ></cv-button>
        <cv-button (click)="applyFilter()" buttonType="primary" [text]="'common_Apply' | translate" cv-data-test="apply-button">
        </cv-button>
      </div>
    </ng-container>
  </ng-container>
</div>

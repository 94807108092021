import { CustomManagementAction, ManagementAction } from '../../core/models/management-action'
import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'
import { ManagementActionState } from './management-actions.types'
import {
  getManagementActions,
  getManagementActionsResponse,
  getManagementActionsInvalidate,
} from './management-actions.actions'


export const managementActionsAdapter: EntityAdapter<ManagementAction> = createEntityAdapter<ManagementAction>({
  selectId: ({ actionItemId }) => actionItemId,
})

export const initialState: ManagementActionState = managementActionsAdapter.getInitialState({
  didInvalidate: false,
  isFetching: false,
  error: null,
  customActions: [],
})

export const managementActionsReducer = createReducer(
  initialState,
  on(getManagementActions, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(getManagementActionsResponse, (state, managementActions) => {
    const customAction = managementActions.actions.find(action => action.title === 'CustomAction')
    const customActions = customAction?.actionItems as CustomManagementAction[] || []
    const newState: ManagementActionState = {
      ...state,
      customActions,
      isFetching: false,
    }
    return managementActionsAdapter.setAll(managementActions.actions, newState)}
    ),
  on(getManagementActionsInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true, isFetching: false })),
)

export default managementActionsReducer

import { ICellRendererAngularComp } from '@ag-grid-community/angular'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import {
  ColDef,
  ColumnApi,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  MenuItemDef,
  ProcessCellForExportParams,
  RowSelectedEvent,
} from '@ag-grid-community/core'
import { Component, EventEmitter, OnDestroy } from '@angular/core'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { ReportsService } from '@app/core/services/reports.service'
import { ServerResponse } from '@app/core/models/ServerResponse'
import { of, timer } from 'rxjs'
import { Store } from '@ngrx/store'
import { RootState } from '@app/store/RootState.type'
import { selectLastMessage } from '@app/store/messages/messages.selectors'
import { filter, takeUntil } from 'rxjs/operators'
import { BuildColAgGrid } from '@app/shared/utilities/build-col-ag-grid'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'

@Component({
  selector: 'app-custom-detail',
  templateUrl: './custom-detail.component.html',
  styleUrls: ['./custom-detail.component.sass'],
})
export class CustomDetailComponent implements ICellRendererAngularComp, OnDestroy {
  value!: string
  html!: string

  params!: any

  tableDataField!: any[]
  defaultColDef = {
    flex: 1,
    filter: true,
    sortable: true,
  }
  rowData: any = {}
  columnDefs!: (ColDef & CoreViewColumn)[]

  frameworkComponents!: any
  rowSelection?: 'single' | 'multiple'

  gridApi!: GridApi
  columnApi!: ColumnApi
  modules = [ClientSideRowModelModule, RangeSelectionModule, MenuModule, ClipboardModule]
  correlationObj!: any

  rowId!: string
  masterGridApi?: GridApi

  constructor(private store: Store<RootState>, private buildColumnsHelper: BuildColAgGrid, private reportsService: ReportsService) {
    this.frameworkComponents = buildColumnsHelper.frameworkComponents
  }

  agInit(params: any): void {
    this.params = params

    if (params.field) {
      this.value = params.data[params.field]
    } else if (params.innerHtml) {
      this.html = params.innerHtml(params.data)
    } else if ((!!params.tableDataField || !!params.getTableData) && !!params.columnDefs) {
      this.populateTableParams(params)
    } else if (params.waitMessage) {
      this.waitMessageAndPopulateTable(params)
    } else if (params.apiUrl) {
      this.columnDefs = params.columnDefs
      let p: any = {}
      if (!!params.getParams && typeof params.getParams === 'function') {
        p = params.getParams(params.data)
      } else {
        params.fieldsToPass.forEach((f: string) => {
          p[f] = params.data[f]
        })
      }

      this.getItems$(params.apiUrl, params.verb, p).subscribe((res) => {
        this.tableDataField = params.responseItemProp ? res[params.responseItemProp] : (res as unknown as any[])
      })
    }
  }

  onChildRowSelected(event: RowSelectedEvent) {
    this.params.onChildRowSelected(event, this.masterGridApi, this.rowId)
  }

  refresh(params: any): boolean {
    return false
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api
    this.columnApi = params.columnApi

    const gridInfo = {
      id: this.rowId,
      api: params.api,
      columnApi: params.columnApi,
    }

    this.masterGridApi?.addDetailGridInfo(this.rowId, gridInfo)
  }

  rowDataChanged = () => {
    this.columnApi?.autoSizeAllColumns()
    this.gridApi?.sizeColumnsToFit()
  }

  ngOnDestroy(): void {
    this.masterGridApi?.removeDetailGridInfo(this.rowId)
  }

  gridSizeChanged = () => {
    this.columnApi?.autoSizeAllColumns()
    this.gridApi?.sizeColumnsToFit()
  }

  getItems$ = (reportUrl: string, verb: Verb, params: any, dataCenterUrl?: string) =>
    this.reportsService.getData(reportUrl, verb, { ...(params || {}) }, dataCenterUrl) || of({} as ServerResponse<any>)

  getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] =>
    this.buildColumnsHelper.getContextMenuItems(this.gridApi)

  processCellForClipboard = (params: ProcessCellForExportParams) => this.buildColumnsHelper.processCellForClipboard(params)

  private populateTableParams(params: any) {
    if (params.getTableData) {
      this.tableDataField = params.getTableData(params.data)
    } else {
      this.tableDataField = params.data[params.tableDataField]
    }

    this.tableDataField.forEach((f) => (f.parentNode = params.data))
    this.columnDefs = params.columnDefs
    this.rowSelection = params.rowSelection

    this.rowId = params.node.id
    this.masterGridApi = params.api

    if (params.masterDetailSelection) {
      params.masterDetailSelection(this.masterGridApi)
    }
  }

  private waitMessageAndPopulateTable(params: any) {
    const p: any = {}
    this.columnDefs = params.columnDefs

    params.fieldsToPass.forEach((f: string) => {
      p[f] = params.data[f]
    })

    this.getItems$(params.apiUrlPushAction, params.verbPushAction, p).subscribe((res: any) => {
      this.correlationObj = params.correlationObj ? res[params.correlationObj] : res
    })

    const subscription$ = new EventEmitter<boolean>()

    timer(500).subscribe(() =>
      this.store
        .select(selectLastMessage)
        .pipe(filter((x) => !!x))
        .pipe(takeUntil(subscription$))
        .subscribe((x: any) => {
          if (params.checkNotification(x)) {
            this.getItems$(params.apiUrl, params.verb, params.getParams(this.correlationObj)).subscribe((res) => {
              this.tableDataField = res[params.responseItemProp]

              subscription$.next()
              subscription$.complete()
            })
          }
        })
    )
  }
}

import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'

import { UserSettingsState } from './userSettings.types'
import {
  getUserSettings,
  getUserSettingsResponse,
  getUserSettingsInvalidate,
  setShowSensitiveData,
  saveUserSettingsInvalidate,
  saveUserSettings,
  saveUserSettingsResponse,
  updateUserSettingsExtra,
  resetUserSettingsExtra,
  updateUserSettingsExtraLive,
} from './userSettings.actions'
import { UserSettings } from '@coreview/coreview-library/models/user-settings'

export const userSettingsAdapter: EntityAdapter<UserSettings> = createEntityAdapter<UserSettings>({
  selectId: ({ userAuthId }) => userAuthId,
})

export const initialState: UserSettingsState = userSettingsAdapter.getInitialState({
  didInvalidate: false,
  isFetching: false,
  error: null,
  showSensitiveData: false,
})

export const userSettingsReducer = createReducer(
  initialState,
  on(getUserSettings, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(getUserSettingsResponse, (state, userSettings) => userSettingsAdapter.setOne(userSettings.data, state)),
  on(getUserSettingsInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true })),
  on(setShowSensitiveData, (state, action) => ({ ...state, showSensitiveData: action.showSensitiveData })),
  on(saveUserSettings, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(saveUserSettingsResponse, (state, userSettings) => userSettingsAdapter.setOne(userSettings.data, state)),
  on(saveUserSettingsInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true })),
  on(resetUserSettingsExtra, (state) => {
    const extraObject = {}
    const updatedExtra = {
      ...extraObject,
    };
    const extra = JSON.stringify(updatedExtra)
    return userSettingsAdapter.updateOne({
      id: +state.ids[0],
      changes: {
        extra
      }
    }, state)}),
  on(updateUserSettingsExtra, (state, action) => {
    const extraObject = safeParseJSON(state.entities[state.ids[0]]?.extra);
    const updatedExtra = {
      ...extraObject,
      [action.key]: action.value
    };
    const extra = JSON.stringify(updatedExtra)
    return userSettingsAdapter.updateOne({
      id: +state.ids[0],
      changes: {
        extra
      }
    }, state)
  }),
  on(updateUserSettingsExtraLive, (state, action) => {
    const extraObject = safeParseJSON(state.entities[state.ids[0]]?.extra);
    const updatedExtra = {
      ...extraObject,
      [action.key]: action.value
    };
    const extra = JSON.stringify(updatedExtra)
    return userSettingsAdapter.updateOne({
      id: +state.ids[0],
      changes: {
        extra
      }
    }, state)
  }),
)

const safeParseJSON = (json?: string): Record<string, any> => {
  if (!json) {
    return {}
  }
  try {
    return JSON.parse(json)
  }
  catch {
    return {}
  }
}

export default userSettingsReducer

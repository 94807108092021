import { createFeatureSelector, createSelector } from '@ngrx/store'
import { OrganizationsState } from '@app/store/organizations/organizations.types'
import { organizationsAdapter } from './organizations.reducer'

const defaultSelectors = organizationsAdapter.getSelectors()

/**
 * Selects the whole organization state
 */
export const selectOrganizationState = createFeatureSelector<OrganizationsState>('organizations')

/**
 * Select all the available entities
 */
export const selectOrganizations = createSelector(selectOrganizationState, defaultSelectors.selectEntities)

/**
 * Selects a specific organization by its id
 */
export const selectOrganizationById = (organizationId: string) =>
  createSelector(selectOrganizationState, (state: OrganizationsState) => state.entities[organizationId])

/**
 * Selects the error of the OrganizationsState
 */
export const selectOrganizationError = createSelector(selectOrganizationState, (state: OrganizationsState) => state.error)

/**
 * Selects the fetching flag of the OrganizationsState
 */
export const selectOrganizationLoading = createSelector(selectOrganizationState, (state: OrganizationsState) => state.isFetching)

export const selectOrganizationLoaded = createSelector(selectOrganizationState, (state: OrganizationsState) => state.loaded)

/**
 * Selects the managing organization
 */
export const selectedOrganization = createSelector(selectOrganizationState, (organizationState: OrganizationsState) =>
  organizationState.selectedOrganization ? organizationState.entities[organizationState.selectedOrganization] : undefined
)

export const selectedOrganizationSkus = createSelector(selectOrganizationState, (organizationState: OrganizationsState) =>
  organizationState.selectedOrganization ? organizationState.entities[organizationState.selectedOrganization]?.portalSkus : []
)

<header class="header">
  <cv-button
    text="{{ 'management_ResetToDefault' | translate }}"
    (click)="resetToDefault()"
    leftIcon="undo"
    buttonType="tertiary"
  ></cv-button>
  <cv-select
    style="width: 210px"
    [label]="'management_SetBusinessHours' | translate"
    [suggestions]="types"
    [(ngModel)]="type"
    (selectionChanged)="handleTypeChange()"
  ></cv-select>
  <cv-switch [(ngModel)]="full" *ngIf="type === 'same'" (checkedChange)="handleChange($event)">
    {{ 'management_SetBusinessHoursFull' | translate }}
  </cv-switch>
</header>
<form [formGroup]="form" *ngIf="type === 'different'">
  <app-auto-attendant-business-hours-group
    class="m-b-10"
    *ngFor="
      let dayOfWeek of ['sundayHours', 'mondayHours', 'tuesdayHours', 'wednesdayHours', 'thursdayHours', 'fridayHours', 'saturdayHours']
    "
    [dayOfWeek]="getDayLabel(dayOfWeek)"
    [formControlName]="dayOfWeek"
  ></app-auto-attendant-business-hours-group>
</form>
<form [formGroup]="sameForm" *ngIf="type === 'same'">
  <app-auto-attendant-business-hours-group
    class="m-b-10"
    *ngFor="
      let dayOfWeek of ['mondayToFriday', 'saturdayToSunday']
    "
    [dayOfWeek]="getGroupedDayLabel(dayOfWeek)"
    [fieldsDisabled]="full"
    [formControlName]="dayOfWeek"
  ></app-auto-attendant-business-hours-group>
</form>

/* eslint-disable max-len */
import { Injectable, Type } from '@angular/core'
import { ApiclientService } from '@app/core/services/apiclient.service'
import { ApplicationInsightService } from '@app/core/services/application-insight.service'
import { ManagementActionsService } from '@app/core/services/management-actions.service'
import { ReportsService } from '@app/core/services/reports.service'
import { forkJoin, Observable, of } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { Constants } from '@app/shared/utilities/constants'
import { LicenseAllocatorComponent } from '@app/modules/licenses/components/license-allocator/license-allocator.component'
import { AutoAttendantBusinessHoursComponent } from '@app/modules/management/components/custom-components/auto-attendant-business-hours/auto-attendant-business-hours.component'
import { AutoAttendantHolidaySettingComponent } from '@app/modules/management/components/custom-components/auto-attendant-holiday-setting/auto-attendant-holiday-setting.component'
import { AutoAttendantRedirectionComponent } from '@app/modules/management/components/custom-components/auto-attendant-redirection/auto-attendant-redirection.component'
import { DynamicFieldsComponent } from '@app/modules/management/components/custom-components/dynamic-fields/dynamic-fields.component'
import { ExtensionAttributesBuilderComponent } from '@app/modules/management/components/custom-components/extension-attributes-builder/extension-attributes-builder.component'
import { ServicePlansPickerComponent } from '@app/modules/management/components/custom-components/service-plans-picker/service-plans-picker.component'
import { DatetimeUtcPickerComponent } from '@app/shared/components/datetime-utc-picker/datetime-utc-picker.component'
import { LicensesPickerComponent } from '@app/shared/components/licenses-picker/licenses-picker.component'
import { PanelStep } from '@app/shared/models/panel-step'
import { Helpers } from '@app/shared/utilities/helpers'
import { TranslateHelper } from '@coreview/coreview-library'
import { ToastService } from '@coreview/coreview-components'
import { flattenDeep, get, isArray, maxBy, set, union, uniq } from 'lodash-es'
import { DatetimeUtcRangePickerComponent } from '../components/datetime-utc-range-picker/datetime-utc-range-picker.component'
import { OrganizationalUnitPickerComponent } from '../components/organizational-unit-picker/organizational-unit-picker.component'
import { TargetSelectionComponent } from '../components/target-selection/target-selection.component'
import {
  EnhancedJsonFormData,
  EnhancedProperties,
  EnhancedPropertyFormDescriptor,
  EnhancedPropertyFormDescriptorOptions,
} from '../models/enhanced-json-form-data'
import { DynamicGroupRuleBuilderComponent } from '@app/modules/management/components/custom-components/dynamic-group-rule-builder/dynamic-group-rule-builder'

@Injectable({ providedIn: 'root' })
export class DynamoFormHelperService {
  customComponentsMapping: Record<string, Type<any>> = {
    organizationalUnitPicker: OrganizationalUnitPickerComponent,
    dateTimeUtcPiker: DatetimeUtcPickerComponent,
    dateTimeUtcRangePiker: DatetimeUtcRangePickerComponent,
    extensionAttributesBuilder: ExtensionAttributesBuilderComponent,
    licenseAllocatorComponent: LicenseAllocatorComponent,
    licensePicker: LicensesPickerComponent,
    dynamicFields: DynamicFieldsComponent,
    autoAttendantRedirection: AutoAttendantRedirectionComponent,
    autoAttendantBusinessHours: AutoAttendantBusinessHoursComponent,
    autoAttendantHolidaySettings: AutoAttendantHolidaySettingComponent,
    targetSelection: TargetSelectionComponent,
    servicePlans: ServicePlansPickerComponent,
    ruleBuilder: DynamicGroupRuleBuilderComponent
  }

  constructor(
    private appInsights: ApplicationInsightService,
    private toastService: ToastService,
    private translateHelper: TranslateHelper,
    private reportsService: ReportsService,
    private managementService: ManagementActionsService,
    private apiClientService: ApiclientService
  ) {}

  static getPropertyPath(properties: EnhancedProperties, propertyKey: string): string {
    let propPath = propertyKey
    Object.keys(properties)
      .filter((key) => properties[key].format === 'step' || properties[key].format === 'partial')
      .every((key) => {
        if (properties[propertyKey]) {
          return false
        } else {
          if (properties[key]?.properties) {
            const nextPath = this.getPropertyPath(properties[key].properties as EnhancedProperties, propertyKey)
            if (nextPath) {
              propPath = `${key}.properties.${nextPath}`
              if (get(properties, propPath)) {
                return false
              }
              return true
            }
          }
          return true
        }
      })
    return propPath
  }

  getFormattedJsonFormData(jsonSchema: string): Observable<EnhancedJsonFormData | null> {
    try {
      const jsonFormData = JSON.parse(jsonSchema) as EnhancedJsonFormData
      this.enrichSchemaProperties(jsonFormData.properties)
      if (jsonFormData.options?.templates) {
        this.enrichSchemaProperties(jsonFormData.options.templates)
      }
      const observables = {
        ...this.getEnumObservables(jsonFormData.properties),
        permissions: this.getActionPermissionObservable(jsonFormData.properties),
      }
      return Object.keys(observables).length > 0
        ? forkJoin(observables).pipe(
            map((results: any) => {
              Object.keys(results).forEach((key) => {
                if (key === 'permissions' && !!results[key]) {
                  this.hideProperties(
                    jsonFormData.properties,
                    Object.entries(results[key])
                      .filter((x) => !x[1])
                      .map((x) => x[0].toString())
                  )
                } else {
                  const enumPropertyPath = DynamoFormHelperService.getPropertyPath(jsonFormData.properties, key)
                  if (enumPropertyPath) {
                    this.populateJsonDataEnums(jsonFormData, enumPropertyPath, results[key])
                  }
                }
              })
              return jsonFormData
            })
          )
        : of(jsonFormData)
    } catch (error: any) {
      this.toastService.open({
        id: 'err',
        variant: 'error',
        title: this.translateHelper.instant('common_Error'),
        message: this.translateHelper.instant('common_JsonSchemaNotValid'),
      })
      this.appInsights.trackError(error)
      return of(null)
    }
  }

  getEnumObservables(properties: EnhancedProperties): { [key: string]: Observable<any> | null } {
    const enumsToFetch: { [key: string]: Observable<any> | null } = {}

    Object.keys(properties)
      .filter(
        (key) =>
          (!!properties[key].options?.enumApi && !this.isSuggester(properties[key].format)) ||
          properties[key].format === 'step' ||
          properties[key].format === 'partial'
      )
      .forEach((key) => {
        if (properties[key].options?.enumApi) {
          let params: any = {}
          let baseUrl: string | undefined
          if (properties[key].options?.enumGetApiParams) {
            const func = Function('query', properties[key].options?.enumGetApiParams as string)
            params = func()
            params = Object.entries(params).reduce((acc, [k, value]) => {
              acc[k] = value ?? ''
              return acc
            }, {} as Record<string, any>)
          }
          if (properties[key].options?.enumDatacenterUrl === 'workflow') {
            const baseApi = this.apiClientService.getWorkflowBaseApi(properties[key].options?.enumDatacenterUrl !== 'workflow')
            params.withCredentials = baseApi.withCred || false
            baseUrl = baseApi.url
          } else {
            params.withCredentials = true
          }
          const verb = properties[key].options?.enumApiVerb ?? 'get'
          enumsToFetch[key] = this.apiClientService.getData(properties[key].options?.enumApi as string, verb, params, baseUrl).pipe(
            catchError((e) => {
              const message = e.error.error.message || 'common_Error'
              this.toastService.open(
                {
                  id: 'err',
                  variant: 'error',
                  title: this.translateHelper.instant('common_Error'),
                  message: this.translateHelper.instant(message),
                  dismiss: (toast: { id: any }) => this.toastService.close(toast.id),
                },
                {
                  autoclose: false,
                  timeout: 50000,
                }
              )
              return of([])
            })
          )
        } else if (properties[key].properties) {
          Object.assign(enumsToFetch, this.getEnumObservables(properties[key].properties as EnhancedProperties))
        }
      })

    return enumsToFetch
  }

  isSuggester(format?: string): boolean {
    return (
      format === 'suggester' ||
      format === 'suggester-string' ||
      format === 'suggester-button' ||
      format === 'multi-input' ||
      format === 'table'
    )
  }

  getPropertiesToWatch(properties: EnhancedProperties): { [key: string]: EnhancedPropertyFormDescriptor } {
    const suggestersToWatch: { [key: string]: EnhancedPropertyFormDescriptor } = {}
    Object.keys(properties)
      .filter(
        (key) =>
          (!!properties[key].options?.enumApi && this.isSuggester(properties[key].format)) ||
          !!properties[key].options?.useValueAs ||
          ['step', 'partial', 'extended', 'extended-smart-input', 'smart-input'].includes(properties[key].format || '')
      )
      .forEach((key) => {
        if (!!properties[key].options?.enumApi || !!properties[key].options?.useValueAs) {
          suggestersToWatch[key] = properties[key]
        } else if (properties[key].properties) {
          Object.assign(suggestersToWatch, this.getPropertiesToWatch(properties[key].properties as EnhancedProperties))
        }
      })
    return suggestersToWatch
  }

  enrichSchemaProperties(properties: EnhancedProperties) {
    Object.keys(properties).forEach((key) => {
      if (
        ((properties[key].format === 'step' || properties[key].format === 'partial') && !!properties[key].properties) ||
        (properties[key].format === 'table' && !!properties[key].items?.properties)
      ) {
        this.enrichSchemaProperties(
          properties[key].format !== 'table'
            ? (properties[key].properties as EnhancedProperties)
            : ((properties[key].items || {}).properties as EnhancedProperties)
        )
      } else {
        if (properties[key].pattern === 'email') {
          properties[key].pattern = Constants.emailRegex
          if (properties[key].options) {
            properties[key].options!.patternErrorMessageKey = 'common_NotValidEmail'
          } else {
            properties[key].options = {
              patternErrorMessageKey: 'common_NotValidEmail',
            }
          }
        }
        if (properties[key].format === 'custom' && !!(properties[key].options || {}).customType) {
          const componentKey = (properties[key].options || {}).customType as any as string
          ;(properties[key].options || {}).customType = this.customComponentsMapping[componentKey] as Type<any>
        }
      }
    })
  }

  getSubSteps(
    jsonFormData: EnhancedJsonFormData,
    parentStepKey: string,
    lastSort: number,
    validityFunction?: (key: string) => boolean,
    canEnterStep?: () => boolean,
    expandedStepsKeys: string[] = []
  ): PanelStep[] {
    const steps: PanelStep[] = []
    Object.keys(jsonFormData.properties)
      .filter((key) => jsonFormData.properties[key].format === 'step' && !jsonFormData.properties[key].options?.hidden)
      .forEach((key) => {
        const newSort = ++lastSort
        const subSteps = jsonFormData.properties[key].properties
          ? this.getSubSteps(jsonFormData.properties[key] as any, key, newSort, validityFunction, canEnterStep)
          : undefined

        steps.push({
          sort: newSort,
          stepKey: key,
          parentStepKey,
          title: jsonFormData.properties[key].title || Helpers.capitalize(key),
          summaryCardTitlePreffix: jsonFormData.properties[key].options?.summaryTitlePreffix || '',
          summaryCardSubtitle: jsonFormData.properties[key].subtitle || '',
          isRequired: !!jsonFormData.properties[key].required && (jsonFormData.properties[key].required?.length || 0) > 0,
          status: 'Active',
          isValid: validityFunction ? () => validityFunction(key) : undefined,
          canEnterStep,
          subSteps,
          showSchema: true,
          expanded: expandedStepsKeys.includes(key),
        })
        if (subSteps?.length) {
          lastSort =
            maxBy(flattenDeep(subSteps.map((x) => [x, x.subSteps?.map((y) => [y, y.subSteps || []]) || []])), 'sort')?.sort || lastSort
        }
      })
    return steps
  }

  bindInitialData(jsonFormData: EnhancedJsonFormData, initialData: any) {
    Object.keys(jsonFormData.properties).forEach((key) => {
      if (jsonFormData.properties[key].format === 'step' || jsonFormData.properties[key].format === 'partial') {
        this.bindInitialData(jsonFormData.properties[key] as EnhancedJsonFormData, initialData)
      } else if (!jsonFormData.properties[key].options?.ignoreBinding && Object.keys(initialData || {}).includes(key)) {
        jsonFormData.properties[key].default = initialData[key]
      }
    })
  }

  getUpdatedSuggesterProp(
    enumProperty: EnhancedPropertyFormDescriptor,
    formData: any,
    query: any,
    selectedTargets: any[],
    isLoadingMore = false
  ): Observable<Partial<EnhancedPropertyFormDescriptor>> {
    if (enumProperty.options?.enumApi) {
      let params = {}
      if (enumProperty.options?.enumGetApiParams) {
        const func = Function('query', 'data', 'targets', enumProperty.options.enumGetApiParams)
        params = func(query, formData, selectedTargets)
      }
      const verb = enumProperty.options?.enumApiVerb ? enumProperty.options?.enumApiVerb : 'get'
      if (verb === 'get') {
        const encParam = '?metadata=true&' + this.objectToUrlString(params)
        return this.reportsService
          .getDataString(enumProperty.options?.enumApi, encParam)
          ?.pipe(map((response) => this.getUpdatedEnumProp(enumProperty, response, formData, isLoadingMore)))
      } else {
        return this.reportsService
          .getData(enumProperty.options?.enumApi, verb, params)
          ?.pipe(map((response) => this.getUpdatedEnumProp(enumProperty, response, formData, isLoadingMore)))
      }
    }
    return of(enumProperty)
  }

  getUpdatedSuggesterPropWorkFlow(
    enumProperty: EnhancedPropertyFormDescriptor,
    formData: any,
    query: string,
    selectedTargets: any[]
  ): Observable<Partial<EnhancedPropertyFormDescriptor>> {
    if (enumProperty.options?.enumApi) {
      let params = {}
      if (enumProperty.options?.enumGetApiParams) {
        const func = Function('query', 'data', 'targets', enumProperty.options.enumGetApiParams)
        params = func(query, formData, selectedTargets)
      }
      const verb = enumProperty.options?.enumApiVerb ? enumProperty.options?.enumApiVerb : 'get'
      if (verb === 'get') {
        const encParam = '?metadata=true&' + this.objectToUrlString(params)
        const baseUrl =
          enumProperty.options?.enumDatacenterUrl === 'workflow'
            ? this.apiClientService.getWorkflowBaseApi(enumProperty.options?.enumDatacenterUrl !== 'workflow')
            : { url: undefined, withCred: true }
        return this.apiClientService
          .getDataString(enumProperty.options?.enumApi, encParam, baseUrl.url, { withCredentials: baseUrl.withCred })
          ?.pipe(map((response) => this.getUpdatedEnumProp(enumProperty, response, formData)))
      } else {
        return this.reportsService
          .getData(enumProperty.options?.enumApi, verb, params)
          ?.pipe(map((response) => this.getUpdatedEnumProp(enumProperty, response, formData)))
      }
    }
    return of(enumProperty)
  }

  getPropertyData(jsonFormData: EnhancedJsonFormData, propertyKey: string): EnhancedPropertyFormDescriptor | undefined {
    return (
      jsonFormData.properties[propertyKey] ||
      Object.values(jsonFormData.properties)
        .filter((x) => x.properties)
        .map((x) => this.getPropertyData(x as EnhancedJsonFormData, propertyKey))
        .find((x) => !!x)
    )
  }

  getUpdatedEnumProp(
    enumProp: EnhancedPropertyFormDescriptor,
    newData: any,
    formData: any,
    isLoadingMore = false
  ): Partial<EnhancedPropertyFormDescriptor> {
    if (newData) {
      let enumResults = this.getEnumResults(newData, enumProp.options?.enumResponseProp)

      if (enumProp.options?.enumMapResultsFunction) {
        const func = Function('results', 'formData', enumProp.options.enumMapResultsFunction)
        enumResults = func(enumResults, formData)
      }

      if (enumProp.options?.enableInfiniteScrolling) {
        enumProp.options.totalCountMetadata = newData?.metadata?.totalCount
      }

      if (enumProp.format === 'table') {
        return this.getTablePropWithNewData(enumResults, enumProp, isLoadingMore)
      } else {
        return this.getSuggesterPropWithNewData(enumResults, enumProp.options)
      }
    }
    return {}
  }

  private getTablePropWithNewData(
    enumResults: any,
    enumProp: EnhancedPropertyFormDescriptor,
    isLoadingMore: boolean
  ): Partial<EnhancedPropertyFormDescriptor> {
    const finalResults = isLoadingMore ? (enumProp.default || []).concat(enumResults) : enumResults
    return { ...enumProp, default: finalResults }
  }

  private getSuggesterPropWithNewData(
    enumResults: any,
    propOptions?: EnhancedPropertyFormDescriptorOptions
  ): Partial<EnhancedPropertyFormDescriptor> {
    if (propOptions?.enumResponseDisplayProp) {
      return this.getSuggesterPropWithDisplayValues(enumResults, propOptions)
    } else {
      return {
        enum: propOptions?.enumResponseKeyProp ? (enumResults as []).map((x) => get(x, propOptions.enumResponseKeyProp!)) : enumResults,
        options: {
          ...(propOptions ?? {}),
          preventEnumTranslations: true,
          enumDisplayValues: [],
          enumOtherDisplayValues: [],
          enumSubDisplayValues: [],
        },
      }
    }
  }

  private getEnumResults(newData: any, enumResponseProp?: string | string[]) {
    if (enumResponseProp) {
      return get(newData, enumResponseProp)?.filter((x: any) => !!x)
    }
    return newData instanceof Array ? newData.filter((x: any) => !!x) : newData
  }

  private getSuggesterPropWithDisplayValues(
    enumResults: any,
    propOptions: EnhancedPropertyFormDescriptorOptions
  ): Partial<EnhancedPropertyFormDescriptor> {
    const newSuggesterProp: Partial<EnhancedPropertyFormDescriptor> = {
      enum: [],
      options: {
        ...propOptions,
        enumDisplayValues: [],
        enumOtherDisplayValues: [],
        enumSubDisplayValues: [],
        enumData: [],
        preventEnumTranslations: true,
      },
    }
    const enumResponseOtherDisplayProp = propOptions.enumResponseOtherDisplayProp
    const enumResponseSubDisplayProp = propOptions.enumResponseSubDisplayProp

    this.populateEmptyOption(newSuggesterProp, propOptions)
    ;(enumResults as [])?.forEach((enumResult: any) => {
      newSuggesterProp.enum!.push(propOptions.enumResponseKeyProp ? get(enumResult, propOptions.enumResponseKeyProp) : enumResult)
      newSuggesterProp.options!.enumDisplayValues!.push(this.sanitizeValue(get(enumResult, propOptions.enumResponseDisplayProp!)))
      newSuggesterProp.options!.enumData!.push(enumResult)
      if (enumResponseOtherDisplayProp) {
        newSuggesterProp.options!.enumOtherDisplayValues!.push(this.sanitizeValue(get(enumResult, enumResponseOtherDisplayProp)))
      }
      if (enumResponseSubDisplayProp) {
        newSuggesterProp.options!.enumSubDisplayValues!.push(this.sanitizeValue(get(enumResult, enumResponseSubDisplayProp)))
      }
    })
    return newSuggesterProp
  }

  private populateEmptyOption(
    newSuggesterProp: Partial<EnhancedPropertyFormDescriptor>,
    propOptions: EnhancedPropertyFormDescriptorOptions
  ) {
    if (propOptions.emptyOption) {
      newSuggesterProp.enum!.push(propOptions.emptyOption.key)
      newSuggesterProp.options!.enumDisplayValues!.push(this.translateHelper.instant(propOptions.emptyOption.displayLabel))

      if (propOptions.enumResponseOtherDisplayProp) {
        newSuggesterProp.options!.enumOtherDisplayValues!.push('')
      }

      if (propOptions.enumResponseSubDisplayProp) {
        newSuggesterProp.options!.enumSubDisplayValues!.push('')
      }

      newSuggesterProp.options!.enumData!.push({
        [propOptions.enumResponseKeyProp as string]: propOptions.emptyOption.key,
        [propOptions.enumResponseDisplayProp as string]: propOptions.emptyOption.displayLabel,
      })
    }
  }

  private sanitizeValue(value: string): string {
    return !!value?.indexOf && value?.indexOf('<br/>') > -1 ? value.split('<br/>')[0] : value
  }

  private getActionPermissionObservable(properties: EnhancedProperties): Observable<any> | null {
    const actionIdsToCheck = this.getActionIdsToCheck(properties)
    if (actionIdsToCheck.length) {
      return this.managementService.canViewActions(actionIdsToCheck)
    }
    return of(null)
  }

  private objectToUrlString(params: any) {
    const queryString = Object.keys(params)
      .map((key) => key + '=' + this.getParamValue(params[key]))
      .join('&')
    return queryString
  }

  private getParamValue(param: any) {
    if (typeof param === 'string' && decodeURIComponent(param) !== param) {
      return param
    }
    return encodeURIComponent(param)
  }

  private populateJsonDataEnums(jsonFormData: EnhancedJsonFormData, enumPropertyPath: string, results: any) {
    const enumProp = get(jsonFormData.properties, enumPropertyPath)
    if (enumProp) {
      const newEnumProp = this.getUpdatedEnumProp(enumProp, results, {})
      set(jsonFormData.properties, `${enumPropertyPath}`, Object.assign(get(jsonFormData.properties, `${enumPropertyPath}`), newEnumProp))
    }
  }

  private getActionIdsToCheck(properties: EnhancedProperties): number[] {
    let actionIds: number[] = []
    Object.entries(properties).forEach(([k, d]) => {
      if (d.options?.permissionActionIds) {
        if (isArray(d.options?.permissionActionIds)) {
          actionIds = union(actionIds, d.options?.permissionActionIds)
        } else {
          actionIds.push(d.options?.permissionActionIds)
        }
      }
      if (d.properties) {
        actionIds = union(actionIds, this.getActionIdsToCheck(d.properties))
      }
    })
    return uniq(actionIds)
  }

  private hideProperties(properties: EnhancedProperties, actionIdsToHide: string[]) {
    Object.keys(properties)
      .filter(
        (key) => !!properties[key].options?.permissionActionIds || properties[key].format === 'step' || properties[key].format === 'partial'
      )
      .forEach((key) => {
        if (
          !!properties[key].options?.permissionActionIds &&
          this.shouldHideProperty(properties[key].options?.permissionActionIds as number | number[], actionIdsToHide)
        ) {
          ;(properties[key].options || {}).hidden = true
        }
        if (properties[key].properties) {
          this.hideProperties(properties[key].properties as EnhancedProperties, actionIdsToHide)
        }
      })
  }

  private shouldHideProperty(permissionActionIds: number | number[], actionIdsToHide: string[]): boolean {
    return isArray(permissionActionIds)
      ? permissionActionIds.every((x) => actionIdsToHide.includes(x.toString()))
      : actionIdsToHide.includes(permissionActionIds.toString())
  }
}

import { UntypedFormControl, UntypedFormGroup, NgControl } from '@angular/forms'
import { Component, Input, OnInit, Optional, Self, OnDestroy } from '@angular/core'
import { BaseControlComponent } from '@coreview/coreview-components'
import dayjs from 'dayjs'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-datetime-utc-range-picker',
  templateUrl: './datetime-utc-range-picker.component.html',
  styleUrls: ['./datetime-utc-range-picker.component.sass'],
})
export class DatetimeUtcRangePickerComponent extends BaseControlComponent implements OnInit, OnDestroy {
  @Input()
  checkPastDates = true

  @Input()
  required = false

  @Input()
  get value(): { startDatetime: dayjs.Dayjs; endDatetime: dayjs.Dayjs } | null {
    const formValue = this.form.getRawValue()
    return !formValue.startDatetime || !formValue.endDatetime ? null : formValue
  }

  @Input() disableUtc: boolean = false
  @Input() displayInline: boolean = false

  set value(value: { startDatetime: dayjs.Dayjs; endDatetime: dayjs.Dayjs } | null) {
    this.form.setValue(
      !value
        ? {
            startDatetime: null,
            endDatetime: null,
          }
        : value
    )
  }

  endDatePast = false
  incompatibleDates = false
  startDatePast = false
  missingDates = false

  form = new UntypedFormGroup({
    startDatetime: new UntypedFormControl(),
    endDatetime: new UntypedFormControl(),
  })

  private destroyed$: Subject<boolean> = new Subject()

  constructor(@Optional() @Self() public ngControl: NgControl) {
    super(ngControl)
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.checkDates()
      this.onChange(this.value)
    })
  }

  private checkDates() {
    const formValue = this.form.getRawValue()
    const startDate = formValue.startDatetime
    const endDate = formValue.endDatetime

    if (this.checkPastDates) {
      this.startDatePast = !!startDate && startDate < dayjs()
      this.endDatePast = !!endDate && endDate < dayjs()
    }
    this.incompatibleDates = !!startDate && !!endDate && startDate >= endDate

    this.missingDates = this.isMissingDates(this.required, startDate, endDate)

    const control = this.ngControl?.control

    if (control) {
      if (this.incompatibleDates || this.startDatePast || this.endDatePast || this.missingDates) {
        control.setValidators(errorValidator)
      } else {
        control.removeValidators(errorValidator)
      }
      control.updateValueAndValidity()
    }
  }

  private isMissingDates(required: boolean, start: any, end: any): boolean {
    if (!required) {
      return false
    }

    const startDate: boolean = !!start && dayjs(start).isValid()
    const endDate: boolean = !!end && dayjs(start).isValid()

    return !startDate || !endDate

  }
}

const errorValidator = () => ({ datesError: 'error' })

<mat-expansion-panel [expanded]="true" *ngIf="sequence">
  <mat-expansion-panel-header collapsedHeight="44px" expandedHeight="44px">
    <mat-panel-title>
      <mat-icon>list</mat-icon>
      {{ sequence.name }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="actionSequence-container">
    <app-action-list
      [actions]="actions"
      [parentUid]="sequence.uid"
      [previewType]="previewType"
      [showDetails]="showDetails"
      [isCatch]="isCatch"
    ></app-action-list>
  </div>
</mat-expansion-panel>

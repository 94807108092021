import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FilterDefinition } from '@app/core/models/ReportDefinition';
import { Helpers } from '@app/shared/utilities/helpers';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-default-filter-indicator',
  templateUrl: './default-filter-indicator.component.html',
  styleUrls: ['./default-filter-indicator.component.sass']
})
export class DefaultFilterIndicatorComponent implements OnInit, OnDestroy {

  @Input() public set filter(value: FilterDefinition) {
    if (value) {
      const condition = this.translateService.instant('common_' + Helpers.capitalize(value.type))
      const conditionValue = value.filter ? ` ${value.filter}` : ''
      this.value = `${condition}${conditionValue}`
    }
  }
  @Input() public filterKey?: string

  public value?: string
  public showCard = false

  public debounceTime = 300

  private destroyed$: Subject<boolean> = new Subject()
  private open$: Subject<boolean> = new Subject()
  private close$: Subject<boolean> = new Subject()
  private stop$: Subject<boolean> = new Subject()

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.open$
      .pipe(
        filter(() => !this.showCard),
        takeUntil(this.destroyed$),
        debounceTime(this.debounceTime),
        withLatestFrom(this.stop$),
        map(([_, second]) => (second ? false : true))
      )
      .subscribe((data) => {
        this.showCard = data
      })

    this.close$
      .pipe(
        filter(() => this.showCard),
        takeUntil(this.destroyed$),
        debounceTime(this.debounceTime),
        withLatestFrom(this.stop$),
        map(([_, second]) => (second ? false : true))
      )
      .subscribe((data) => {
        this.showCard = data
      })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  openCard() {
    this.open$.next(true)
    this.stop$.next(false)
  }

  closeCard() {
    this.close$.next(true)
    this.stop$.next(true)
  }

}

import { Component, Input, OnInit, Optional, Self } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, NgControl, Validators } from '@angular/forms'
import { TranslateHelper } from '@coreview/coreview-library'
import { BaseControlComponent, Suggestion } from '@coreview/coreview-components'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { forEach, isNumber, isString } from 'lodash-es'
import { timer } from 'rxjs'

@Component({
  selector: 'app-datetime-utc-picker',
  templateUrl: './datetime-utc-picker.component.html',
  styleUrls: ['./datetime-utc-picker.component.sass'],
})
export class DatetimeUtcPickerComponent extends BaseControlComponent implements OnInit {
  @Input()
  dateLabel!: string

  @Input()
  isError = false

  @Input()
  disableUtc = false

  @Input()
  displayInline = false

  @Input()
  minDate = '0000-01-01'

  @Input()
  maxDate = '9999-12-31'

  @Input()
  required = false

  dayJsValue!: dayjs.Dayjs | null

  @Input()
  get value(): dayjs.Dayjs | null {
    return this.dayJsValue
  }

  set value(value: dayjs.Dayjs | null) {
    if (isNumber(value)) {
      value = dayjs().add(value, 'day')
    }
    if (isString(value)) {
      value = dayjs(value)
    }
    if (value) {
      this.formControls.date?.setValue(value.utc(), { emitEvent: false })
      this.formControls.time?.setValue(this.getTimeValue(value.utc()), { emitEvent: false })
      this.formControls.utc?.setValue('+00:00', { emitEvent: false })
      this.dayJsValue = this.getDatetime()
      timer(50).subscribe(() => this.onChange(this.dayJsValue))
    } else {
      this.dayJsValue = null
    }
  }

  formControls = {
    date: new UntypedFormControl(null, [Validators.required]),
    time: new UntypedFormControl(null, [Validators.required]),
    utc: new UntypedFormControl(null, [Validators.required]),
  }

  form: UntypedFormGroup = new UntypedFormGroup(this.formControls)

  timesOptions!: Suggestion[]
  offsetOptions!: Suggestion[]

  constructor(private translateHelper: TranslateHelper, @Optional() @Self() public ngControl: NgControl) {
    super(ngControl)
  }

  ngOnInit(): void {
    dayjs.extend(customParseFormat)

    if (this.disableUtc) {
      this.formControls.utc.setValue('+00:00')
      this.formControls.utc.disable()
    }

    this.timesOptions = this.getTimesValues()
    this.offsetOptions = this.getOffsetValues()

    this.form.valueChanges.subscribe((x) => {
      this.dayJsValue = this.getDatetime()
      timer(50).subscribe(() => this.onChange(this.dayJsValue))
    })
  }

  getDatetime() {
    const value = this.form.getRawValue()
    if (value.date && value.time && value.utc) {
      return dayjs(value.date.format('YYYY-MM-DD') + 'T' + value.time + value.utc, 'YYYY-MM-DDTHH:mmZ')
    } else {
      return null
    }
  }

  getStringDatetime() {
    return this.dayJsValue?.format('LLL')
  }

  private getTimesValues(): Suggestion[] {
    const timesArr: Suggestion[] = []
    for (let index = 0; index < 24; index++) {
      const hourWhole = (index < 10 ? '0' + index : index) + ':00'
      const labelWhole = dayjs(hourWhole, 'HH:mm').format('hh:mm A')
      timesArr.push({ value: hourWhole, displayValue: labelWhole })

      const hourHalf = (index < 10 ? '0' + index : index) + ':30'
      const labelHalf = dayjs(hourHalf, 'HH:mm').format('hh:mm A')
      timesArr.push({ value: hourHalf, displayValue: labelHalf })
    }

    return timesArr
  }

  private getOffsetValues(): Suggestion[] {
    const offsetArr: Partial<Suggestion>[] = [
      { value: '-12:00' },
      { value: '-11:00' },
      { value: '-10:00' },
      { value: '-09:30' },
      { value: '-09:00' },
      { value: '-08:00' },
      { value: '-07:00' },
      { value: '-06:00' },
      { value: '-05:00' },
      { value: '-04:30' },
      { value: '-04:00' },
      { value: '-03:30' },
      { value: '-03:00' },
      { value: '-02:00' },
      { value: '-01:00' },
      { value: '+00:00' },
      { value: '+01:00' },
      { value: '+02:00' },
      { value: '+03:00' },
      { value: '+03:30' },
      { value: '+04:00' },
      { value: '+04:30' },
      { value: '+05:00' },
      { value: '+05:30' },
      { value: '+05:45' },
      { value: '+06:00' },
      { value: '+06:30' },
      { value: '+07:00' },
      { value: '+08:00' },
      { value: '+08:45' },
      { value: '+09:00' },
      { value: '+09:30' },
      { value: '+10:00' },
      { value: '+10:30' },
      { value: '+11:00' },
      { value: '+11:30' },
      { value: '+12:00' },
      { value: '+12:45' },
      { value: '+13:00' },
      { value: '+14:00' },
    ]

    // TODO: use the city names like here https://www.plus2net.com/javascript_tutorial/clock-time-zones-list.php
    forEach(offsetArr, (o) => {
      // let translateKey = (o.value.charAt(0) === '+' ?  o.value.replace('+', 'Plus') : o.value.replace('-', 'Minus')) as string
      // translateKey = translateKey.replace(':', '')
      // o.displayValue = `(UTC ${o.value}) ${this.translateHelper.instant('common_UTC' + translateKey)}`
      o.displayValue = `(UTC ${o.value})`
    })

    return offsetArr as Suggestion[]
  }

  private getTimeValue(date: dayjs.Dayjs) {
    return `${date.hour() < 10 ? '0' + date.hour() : date.hour()}:${date.minute() >= 30 ? '30' : '00'}`
  }
}

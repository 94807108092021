import { Injectable } from '@angular/core'
import { ReportsService } from '@app/core/services/reports.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { getReportDefinitionByIdInvalidate, getReportsDefinition, getReportsDefinitionResponse } from './reports-definition.actions'

@Injectable()
export class ReportsDefinitionEffects {
  getReportDefinition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getReportsDefinition),
      mergeMap(() =>
        this.reportsService.getReportDefinitions().pipe(
          map((reps) => getReportsDefinitionResponse({ reports: reps })),
          catchError((error) => of(getReportDefinitionByIdInvalidate({ error: { code: error.status, message: error.message }})))
        )
      )
    )
  )
  
  constructor(private actions$: Actions, private reportsService: ReportsService) {}
}

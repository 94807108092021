<app-panel-steps
  *ngIf="steps.length > 0"
  [steps]="steps"
  [panelTitle]="'licenses_ManageCosts' | translate"
  [isSaving]="isSaving"
  (save)="save()"
  cv-data-test="manage-costs-panel"
>
  <div step [class.hidden]="panelSteps?.activeStep?.stepKey !== 'licenses_SelectTarget'" class="flex-full-height">
    <div class="flex-full-height">
      <app-target-selection
        [selectedTargets]="selectedTargetsObject.licensePools"
        [targetsToSelectDefinition]="configuration.licensePools.targetSelectionDefinition"
        [options]="configuration.licensePools.targetSelectionConfiguration"
        (selectedTargetsChanged)="onSelectedTargetsChanged($event)"
      >
      </app-target-selection>
    </div>
  </div>

  <div step [class.hidden]="panelSteps?.activeStep?.stepKey !== 'licenses_ManageCosts'" class="flex-full-height">
    <div class="flex-full-height">
      <div style="overflow: auto; flex: 1; padding: 0 12px; margin: 0 -12px">
        <cv-alert-bar *ngIf="selectedTargetsObject.licensePools.length === 0" [hideCloseButton]="true" variant="error">
          {{ 'licenses_LicensePoolsRequired' | translate }}</cv-alert-bar
        >

        <app-license-cost-allocator
          *ngIf="selectedTargetsObject.licensePools.length !== 0"
          [data]="allocatorData"
        ></app-license-cost-allocator>
      </div>
    </div>
  </div>

  <div step [class.hidden]="panelSteps?.activeStep?.stepKey !== 'complete'" class="flex-full-height summary">
    <div class="panel-container">
      <div class="cards-container">
        <div class="panel">
          <div class="panel-title">
            <span style="flex: 1" class="uppercase" translate>licenses_CostChanges</span>
            <cv-button
              (click)="clickTab(1)"
              class="edit"
              buttonType="tertiary"
              leftIcon="edit"
              [text]="'common_Edit' | translate"
            ></cv-button>
          </div>
          <div class="panel-subtitle" translate>licenses_CostChangesSubtitle</div>
          <div class="panel-content">
            <div class="panel-content-item">
              <div *ngFor="let lp of lpsToSave; last as isLast">
                <div style="font-weight: 600">{{ lp.name }}</div>

                <ng-container *ngFor="let prop of allocatorProps">
                  <div *ngIf="lp[prop.keyNew] !== lp[prop.keyCurrent]">
                    <span translate>{{ prop.titleNew }}</span
                    >:
                    <span>{{ lp[prop.keyNew] }}</span>
                  </div>
                </ng-container>

                <span class="divider" *ngIf="!isLast">&nbsp;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-panel-steps>

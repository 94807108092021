import { isEqual } from 'lodash-es'
import { Component, EventEmitter, Input, OnDestroy, Optional, Output, Self, OnInit } from '@angular/core'
import { FormControl, FormGroup, NgControl, Validators } from '@angular/forms'
import { BaseControlComponent, Suggestion } from '@coreview/coreview-components'
import { TranslateHelper } from '@coreview/coreview-library'
import { distinctUntilChanged, takeUntil } from 'rxjs/operators'
import { combineLatest, of, Subject } from 'rxjs'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import { SchedulationService } from '@app/modules/reports/services/schedulation.service'

export interface Recurrence {
  recurrence: 'oneTime' | 'recurring'
  starting: 'now' | 'specificTime'
  particularScheduleWeeks: boolean
  frequency: '1' | '2' | '3' | '4'
  interval?: number | null
  weekdaySelected?: '0' | '1' | '2' | '3' | '4' | '5' | '6' | null
  startDateTime: dayjs.Dayjs
  endDateTime: dayjs.Dayjs | null
  monthControl?: 'dayMonth' | 'daysOfWeek' | 'lastDay' | null
  dayMonth?: number | null
  ordinal?: 'First' | 'Second' | 'Third' | 'Fourth' | 'Last' | null
  weekdayMonthSelected?: '0' | '1' | '2' | '3' | '4' | '5' | '6' | null
}

@Component({
  selector: 'app-recurrence',
  templateUrl: './recurrence.component.html',
  styleUrls: ['./recurrence.component.sass'],
})
export class RecurrenceComponent extends BaseControlComponent implements OnInit, OnDestroy {
  @Input() initialDatesValid = false
  @Input() checkStartDate = true
  @Input() hideParticularScheduleWeeks = false
  @Input() onlyRecurring = false
  @Input() noEndDatecheck = true
  @Input() customEndDateErrorMessage?: string
  @Output() intervalChanged = new EventEmitter()

  firstValue!: Recurrence

  @Input() get value(): Recurrence {
    return this.form.getRawValue()
  }

  set value(value: Recurrence) {
    if (!this.firstValue) {
      this.firstValue = value
    }
    this.form.patchValue(value)
  }

  formControls = {
    recurrence: new FormControl(),
    starting: new FormControl(),
    particularScheduleWeeks: new FormControl(),
    frequency: new FormControl(),
    interval: new FormControl(),
    weekdaySelected: new FormControl(),
    monthControl: new FormControl(),
    ordinal: new FormControl(),
    dayMonth: new FormControl(),
    weekdayMonthSelected: new FormControl(),
    startDateTime: new FormControl(),
    endDateTime: new FormControl(),
  }

  form: FormGroup = new FormGroup(this.formControls)

  recurrenceOptions = this.schedulationService.recurrenceOptions
  unitOptions = this.schedulationService.unitOptions
  daysOptions = this.schedulationService.daysOptions
  dayMonthOptions = this.schedulationService.dayMonthOptions

  ordinalOptions: Suggestion[] = [
    { value: 'First', displayValue: this.translate('common_First') },
    { value: 'Second', displayValue: this.translate('common_Second') },
    { value: 'Third', displayValue: this.translate('common_Third') },
    { value: 'Fourth', displayValue: this.translate('common_Fourth') },
    { value: 'Last', displayValue: this.translate('common_Last') },
  ]

  startingOptions = this.schedulationService.startingOptions

  startingOptionsMonth: Suggestion[] = [{ value: 'specificTime', displayValue: this.translate('reports_AtSpecificTime') }]

  sendWhenOptions = this.schedulationService.sendWhenOptions

  noEndDate = true
  startDatePast = false
  endDatePast = false
  incompatibleDates = false

  private destroyed$ = new Subject<boolean>()

  constructor(
    private translateHelper: TranslateHelper, 
    private schedulationService: SchedulationService,
    @Optional() @Self() public ngControl: NgControl) {
    super(ngControl)
    dayjs.extend(localeData)

    this.formControls.frequency.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((frequency) => {
      if (frequency === '3' || this.formControls.recurrence.value === 'oneTime') {
        if (this.formControls.recurrence.value !== 'oneTime') {
          this.formControls.interval.disable()
          this.formControls.interval.setValue(1)
        }
        this.formControls.interval.clearValidators()
      } else {
        this.formControls.interval.enable()
        this.formControls.interval.markAsTouched()
        this.formControls.interval.setValidators([Validators.required, Validators.min(1)])
      }

      if (frequency === '4' && this.formControls.recurrence.value !== 'oneTime') {
        if (!this.value.monthControl) {
          this.formControls.monthControl.setValue('lastDay')
        }
        this.setMontControl(this.value.monthControl || 'lastDay')

        this.formControls.starting.setValue('specificTime')
        this.formControls.monthControl.addValidators(Validators.required)
      } else {
        this.formControls.monthControl.clearValidators()
        this.formControls.dayMonth.clearValidators()
        this.formControls.ordinal.clearValidators()
        this.formControls.weekdayMonthSelected.clearValidators()

        this.formControls.dayMonth.updateValueAndValidity()
        this.formControls.ordinal.updateValueAndValidity()
        this.formControls.weekdayMonthSelected.updateValueAndValidity()
      }
      this.formControls.monthControl.updateValueAndValidity({ emitEvent: false })

      if (this.hideParticularScheduleWeeks) {
        if (frequency === '3' && this.formControls.recurrence.value !== 'oneTime') {
          this.formControls.weekdaySelected.addValidators(Validators.required)
        } else {
          this.formControls.weekdaySelected.setValue(null)
          this.formControls.weekdaySelected.clearValidators()
        }
        this.formControls.weekdaySelected.markAsTouched()
        this.formControls.weekdaySelected.updateValueAndValidity()
      }

      this.formControls.interval.updateValueAndValidity()
    })

    this.formControls.recurrence.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x === 'recurring') {
        this.formControls.interval.setValidators([Validators.required, Validators.min(1)])
        if (!this.noEndDate) {
          this.formControls.endDateTime.setValidators([Validators.required])
          this.formControls.endDateTime.setValue(dayjs().add(1, 'day').utc().set('hour', 0).set('minute', 0))
        }
      } else {
        this.formControls.interval.clearValidators()
        this.formControls.endDateTime.clearValidators()
      }
      this.formControls.interval.markAsTouched()
      this.formControls.interval.updateValueAndValidity()
      this.formControls.endDateTime.updateValueAndValidity()
    })

    this.formControls.starting.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x === 'specificTime') {
        this.formControls.startDateTime.setValidators([Validators.required])
      } else {
        this.formControls.startDateTime.clearValidators()
        this.formControls.startDateTime.setValue(dayjs().add(dayjs().utcOffset(), 'minute'))
      }
      this.formControls.startDateTime.updateValueAndValidity()
    })

    this.formControls.monthControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      this.setMontControl(x)
    })

    if (!this.hideParticularScheduleWeeks) {
      this.formControls.particularScheduleWeeks.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
        if (x) {
          this.formControls.weekdaySelected.enable()
          this.formControls.weekdaySelected.addValidators(Validators.required)
        } else {
          this.formControls.weekdaySelected.setValue(null)
          this.formControls.weekdaySelected.disable()
          this.formControls.weekdaySelected.clearValidators()
        }
        this.formControls.weekdaySelected.markAsTouched()
        this.formControls.weekdaySelected.updateValueAndValidity()
      })
    }

    combineLatest([
      this.formControls.startDateTime.valueChanges.pipe(distinctUntilChanged()),
      this.formControls.endDateTime.valueChanges.pipe(distinctUntilChanged()),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.checkDates()
      })

    this.form.valueChanges.pipe(distinctUntilChanged(isEqual), takeUntil(this.destroyed$)).subscribe(() => {
      this.onChange(this.value)
    })
  }

  ngOnInit(): void {
    if (this.firstValue?.endDateTime !== null || this.noEndDatecheck === false) {
      this.noEndDate = false
    }
    this.form.statusChanges.pipe(distinctUntilChanged(isEqual), takeUntil(this.destroyed$)).subscribe(() => {
      const control = this.ngControl?.control
      if (control) {
        this.form.updateValueAndValidity({ emitEvent: false })
        if (!this.form.valid) {
          control.setValidators(this.errorValidator)
        } else {
          control.removeValidators(this.errorValidator)
        }
        control.updateValueAndValidity()
      }
    })
    ;(this.ngControl?.control?.statusChanges || of()).pipe(distinctUntilChanged(isEqual), takeUntil(this.destroyed$)).subscribe(() => {
      if (this.ngControl?.control?.disabled && !this.form.disabled) {
        this.form.disable()
      } else if (this.form.disabled) {
        this.form.enable()
        this.form.patchValue(this.value)
      }
      this.form.updateValueAndValidity()
    })
    this.checkDates()
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  get recurrenceValue(): string {
    return this.formControls.recurrence.value
  }

  checkDates() {
    const formValue = this.form.getRawValue()
    const startDate = formValue.starting === 'now' ? null : formValue.startDateTime
    const endDate = formValue.recurrence !== 'recurring' ? null : formValue.endDateTime

    if (
      this.initialDatesValid &&
      dayjs(this.firstValue.startDateTime).isSame(dayjs(startDate)) &&
      dayjs(this.firstValue.endDateTime).isSame(dayjs(endDate))
    ) {
      this.startDatePast = false
      this.endDatePast = false
      this.incompatibleDates = false
      this.form.removeValidators(this.errorValidator)
      this.form.updateValueAndValidity()
      return
    }

    this.startDatePast = this.checkStartDate && !!startDate && startDate < dayjs()
    this.endDatePast = !!endDate && endDate < dayjs()
    this.incompatibleDates = !!startDate && !!endDate && startDate >= endDate

    if (this.startDatePast && this.noEndDate) {
      //date in past is ok and no end date so dates are fine
      this.form.removeValidators(this.errorValidator)
      this.form.updateValueAndValidity()
      return
    }

    if (this.incompatibleDates || this.startDatePast || this.endDatePast) {
      this.form.setValidators(this.errorValidator)
    } else {
      this.form.removeValidators(this.errorValidator)
    }
    this.form.updateValueAndValidity()
  }

  geIntervalErrorMessage(): string {
    if (this.formControls.interval.errors) {
      if (this.formControls.interval.errors.min) {
        return 'common_Minimum1'
      }
      if (this.formControls.interval.errors.required) {
        return 'common_Required'
      }
    }
    return ''
  }

  resetEndDateFormValue() {
    if (this.noEndDate) {
      this.formControls.endDateTime.setValue(null)
      this.formControls.endDateTime.clearValidators()
    } else {
      this.formControls.endDateTime.setValidators([Validators.required])
    }
    this.formControls.endDateTime.updateValueAndValidity()
  }

  private setMontControl(x: string) {
    if (x === 'dayMonth') {
      this.formControls.ordinal.setValue(null)
      this.formControls.weekdayMonthSelected.setValue(null)
      this.formControls.dayMonth.enable()
      this.formControls.dayMonth.addValidators(Validators.required)
      this.formControls.ordinal.disable()
      this.formControls.ordinal.clearValidators()
      this.formControls.weekdayMonthSelected.disable()
      this.formControls.weekdayMonthSelected.clearValidators()
    } else if (x === 'daysOfWeek') {
      this.formControls.dayMonth.setValue(null)
      this.formControls.dayMonth.disable()
      this.formControls.dayMonth.clearValidators()
      this.formControls.ordinal.enable()
      this.formControls.ordinal.addValidators(Validators.required)
      this.formControls.weekdayMonthSelected.enable()
      this.formControls.weekdayMonthSelected.addValidators(Validators.required)
    } else {
      this.formControls.dayMonth.setValue(null)
      this.formControls.ordinal.setValue(null)
      this.formControls.weekdayMonthSelected.setValue(null)
      this.formControls.dayMonth.disable()
      this.formControls.dayMonth.clearValidators()
      this.formControls.ordinal.disable()
      this.formControls.ordinal.clearValidators()
      this.formControls.weekdayMonthSelected.disable()
      this.formControls.weekdayMonthSelected.clearValidators()
    }
    this.formControls.dayMonth.markAsTouched()
    this.formControls.ordinal.markAsTouched()
    this.formControls.weekdayMonthSelected.markAsTouched()

    this.formControls.dayMonth.updateValueAndValidity()
    this.formControls.ordinal.updateValueAndValidity()
    this.formControls.weekdayMonthSelected.updateValueAndValidity()
  }

  private translate(text: string) {
    return this.translateHelper.instant(text)
  }

  private errorValidator = () => ({ datesError: 'error' })
}

<select
  [(ngModel)]="type"
  (ngModelChange)="value = null; modelChanged()"
  style="
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
    width: 100%;
    margin: auto 0px;
    height: 27.6px;
    border-radius: 5px;
    padding-left: 6px;
    border: 1px solid var(--opaque-light-contrast-color);
    background: var(--default-surface-color);
    color: var(--default-text-color);
  "
>
  <option value="" translate>common_Select</option>
  <option *ngFor="let option of options" [value]="option.value" translate>{{ option.display }}</option>
</select>
<span class="ag-icon ag-icon-small-down" style="position: absolute; right: 14px; top: 55px; pointer-events: none"></span>
<cv-daterangepicker
  style="width: 100%"
  (ngModelChange)="value = $event; modelChanged()"
  [(ngModel)]="value"
  class="m-t-10"
  *ngIf="type === 'inRange'"
  customClass="ag-custom-component-popup"
  [preventChangeOnSetValue]="true"
></cv-daterangepicker>
<cv-datepicker
  style="width: 100%"
  (ngModelChange)="value = $event; modelChanged()"
  [(ngModel)]="value"
  class="m-t-10"
  *ngIf="type && ['equals', 'greaterThan', 'greaterThanOrEqual', 'lessThan', 'lessThanOrEqual'].includes(type)"
  customClass="ag-custom-component-popup"
  [preventChangeOnSetValue]="true"
></cv-datepicker>
<input
  (input)="modelChanged()"
  [(ngModel)]="numberValue"
  *ngIf="type && ['lastNDays', 'notInLastNDays', 'nextNDays'].includes(type)"
  type="number"
  style="margin-top: 10px; height: 20px; border-radius: 3px; padding-left: 6px; border: 1px solid var(--opaque-light-contrast-color); background: var(--default-surface-color); color: var(--default-text-color);"
/>

/* eslint-disable max-len */
import { Observable, of } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiclientService } from './apiclient.service'
import { UserCardV2CustomWidget, UserSettings } from '@coreview/coreview-library/models/user-settings'
import { filter, mergeMap, pluck, take, tap } from 'rxjs/operators'
import { ServerResponse } from '../models/ServerResponse'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { selectUserSettings } from '@app/store/userSettings/userSettings.selectors'
import { getUserSettings } from '@app/store/userSettings/userSettings.actions'

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  constructor(private apiClient: ApiclientService, private httpClient: HttpClient, private store: Store<RootState>) {}

  updateExtraUserSettings(key: string, value: any) {
    return this.store.select(selectUserSettings).pipe(
      take(1),
      filter((x) => !!x),
      mergeMap((us: UserSettings | undefined) => {
        if (us) {
          const oldextra = this.safeParseJSON(us.extra)
          const newUs = { ...us, extra: JSON.stringify({ ...oldextra, [key]: value }) }
          return this.saveUserSettings(newUs)
        }
        return of(undefined)
      })
    )
  }

  parseExtraUserSettings(us?: UserSettings): Record<string, any> {
    if (us && us.extra) {
      try {
        return JSON.parse(us.extra)
      } catch {
        return {}
      }
    }
    return {}
  }

  getExtraValue(key: string, us?: UserSettings): any {
    const extra = this.parseExtraUserSettings(us)
    return extra[key]
  }

  getRowsPerPagePreference(us?: UserSettings, isPivotActive?: boolean): number {
    return isPivotActive ? 100 : (this.getExtraValue('rowsPerPagePreference', us) || 25)
  }

  updateUserSettings(columns: any[], entityName: string) {
    return this.store.select(selectUserSettings).pipe(
      take(1),
      filter((x) => !!x),
      mergeMap((us: UserSettings | undefined) => {
        if (!!us && entityName) {
          const newColumns = { ...us.columnsDef }
          newColumns[entityName] = columns
            .filter((x) => !!x.originalName)
            .map((c) => {
              const pinned = c.pinned
              const sort = c.sort
              const sortIndex = c.sortIndex === undefined || c.sortIndex === null ? null : c.sortIndex
              return {
                originalName: c.originalName,
                position: c.position,
                ...(pinned && { pinned }),
                ...(sort && { sort }),
                ...(sortIndex !== null && { sortIndex }),
              }
            })
          const newUs = { ...us }
          newUs.columnsDef = newColumns
          return this.saveUserSettings(newUs)
        }
        return of(undefined)
      })
    )
  }

  getUserSettings(params: any = { userName: '', userAuthId: '' }): Observable<UserSettings> {
    let queryParams = '?metadata=true'
    queryParams += params.userName ? '&userName=' + params.userName : ''
    queryParams += params.userAuthId ? '&userAuthId=' + params.userAuthId : ''

    const url = `${this.apiClient.basePortalApiUrl}/user/settings/${queryParams}`
    return this.httpClient.get<any>(url, { withCredentials: true }).pipe(pluck('userSettings'))
  }

  saveUserSettings(userSettings: UserSettings): Observable<ServerResponse<UserSettings>> {
    const url = `${this.apiClient.basePortalApiUrl}/user/settings/`
    return this.httpClient
      .put<ServerResponse<UserSettings>>(url, { userSettings }, { withCredentials: true })
      .pipe(tap((x) => this.store.dispatch(getUserSettings())))
  }

  saveLanguage(params: any = { language: '', label: '' }): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/languages/`
    return this.httpClient.put<any>(url, params, { withCredentials: true }).pipe(tap((x) => this.store.dispatch(getUserSettings())))
  }

  saveCustomWidget(customWidget: UserCardV2CustomWidget): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/v2/userCardV2/customWidget`
    return this.httpClient
      .put<any>(url, { customWidget }, { withCredentials: true })
      .pipe(tap((x) => this.store.dispatch(getUserSettings())))
  }

  private safeParseJSON(json?: string): Record<string, any> {
    if (!json) {
      return {}
    }
    try {
      return JSON.parse(json)
    } catch {
      return {}
    }
  }
}

import { Injectable } from '@angular/core'
import { Constants } from '@app/shared/utilities/constants'
import { Helpers } from '@app/shared/utilities/helpers'
import { TranslateHelper } from '@coreview/coreview-library'
import { selectReportDefinitionById } from '@app/store/reports/reports-definition.selectors'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { getSubscriptionCenterDefinition } from './generic-center.definitions'

@Injectable({
  providedIn: 'root',
})
export class GenericCenterService {
  constructor(
    private translateHelper: TranslateHelper,
    private store: Store<RootState>,
  ) {}

  getGenericCenterDefinition = (entity: string) => {
    const definition: any = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      M365ProPlusCenter: {
        tabs: [
          {
            menuId: Constants.menuIds.licenseOfficeInstallations,
            title: 'OfficeInstallations',
            entity: 'officeInstallations',
            tooltip: 'OfficeInstallations_info',
            apiUrl: '/officeInstallations',
            enablePivotMode: true,
            reportDefinition$: this.store.select(selectReportDefinitionById('officeInstallations')),
          },
          {
            menuId: Constants.menuIds.lLicenseSoftwareLicenses,
            title: 'SoftwareLicenses',
            entity: 'softwareLicenses',
            tooltip: 'SoftwareLicenses_info',
            apiUrl: '/office365/activations',
            enablePivotMode: true,
            reportDefinition$: this.store.select(selectReportDefinitionById('softwareLicenses')),
          },
          {
            menuId: Constants.menuIds.licenseM365AppsUsage,
            title: 'Microsoft365AppsUsage',
            entity: 'microsoft365AppsUsage',
            tooltip: 'Microsoft365AppsUsage_info',
            apiUrl: '/m365/appplatformusage/details',
            enablePivotMode: true,
            reportDefinition$: this.store.select(selectReportDefinitionById('microsoft365AppsUsage')),
          },
          {
            menuId: Constants.menuIds.licenseOffice365ActiveUsers,
            title: 'Office365ActiveUsers',
            entity: 'office365ActiveUsers',
            tooltip: 'Office365ActiveUsers_info',
            apiUrl: '/office365/activeusers',
            enablePivotMode: true,
            reportDefinition$: this.store.select(selectReportDefinitionById('office365ActiveUsers')),
          },
        ],
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      SubscriptionCenter: getSubscriptionCenterDefinition(this.dateFormatter)
    }

    return definition[entity]
  }

  private dateFormatter = (params: any): string => Helpers.formatDate(params.value) || this.translateHelper.instant('common_NeverUsed')
}

<div class="cv-dialog-div-container">
    <div *ngIf="isDeleting" class="div-container-icon">
        <span class="icon-div alert">
            <mat-icon class="icon">info</mat-icon>
        </span>
    </div>
    <div class="div-container-data">
        <div class="modal-header">
            <div class="modal-sub-header">
                <h2>{{ mainTitle }}</h2>
                <span>{{ subTitle }}</span>
            </div>
            <cv-close-button class="close-btn" (click)="onCloseDialog()"></cv-close-button>
        </div>
        <div class="modal-body">
            <span *ngIf="!!upperTitle">{{ upperTitle }}</span>

            <cv-autocomplete-string-input
                [required]="true"
                [label]="comboBoxTitle"
                [suggestions]="comboBoxValues"
                [maxSuggestions]="10"
                [exceededMaxSuggestion]="'common_SearchSuggestion' | translate"
                rightIcon="arrow_drop_down"
                class="m-b-15"
                style="width: 100%"
                (selectionChanged)="comboBoxValue = $event ?? ''"
                (inputChange)="onInputChanged($event)"
            />

            <span *ngIf="!!underTitle">{{ underTitle }}</span>
        </div>
        <div class="modal-footer">
            <cv-button 
                class="cancel-btn" 
                buttonType="tertiary" text="{{ 'common_Cancel' | translate }}" 
                [color]='buttonColor'
                (click)="onCloseDialog()"
            />

            <cv-button 
                class="save-btn" 
                text="{{ (isDeleting ? 'common_Delete' : 'common_Save') | translate }}"
                [color]='buttonColor'
                (click)="onActionButtonClick()"
                [disabled]="!comboBoxValue || comboBoxValue.trim().length == 0"
            />
        </div>
    </div>
</div>
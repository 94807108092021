import { LocalstorageService } from './../../core/services/localstorage.service'
import { of } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { OrganizationService } from '@core/services/organization.service'
import { ApiclientService } from '@core/services/apiclient.service'
import {
  changeManagedTenantInvalidate,
  changeManagedTenantSuccess,
  getManagedTenants,
  getManagedTenantsInvalidate,
  getManagedTenantsResponse,
  requestManagedTenantChange,
  requestTenantSessionSuccess,
} from '@app/store/tenants/tenants.actions'
import { getOrganizationById } from '../organizations/organizations.actions'
import { RootState } from '../RootState.type'
import { Store } from '@ngrx/store'

/**
 * The following injectable class defined and contains all the "tenants" state related side effects.
 */
@Injectable()
export class TenantsEffects {
  /**
   * When the getManagedTenants action is dispatched, it takes care of performing
   * the related actions to fetch the data and bring them to the store
   */
  getManagedTenants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getManagedTenants),
      mergeMap(() =>
        this.organizationService.getManagedTenants().pipe(
          map((response) =>
            getManagedTenantsResponse({
              ...response,
              selected: this.storage.selectedOrganization ? this.storage.selectedOrganization.id : response.defaultTenant.managedTenantId,
            })
          ),
          tap((resp) => {
            this.store.dispatch(getOrganizationById({ id: resp.selected || resp.defaultTenant.managedTenantId, selected: true }))
          }),
          catchError((error) => of(getManagedTenantsInvalidate({ error: error.message })))
        )
      )
    )
  )

  /**
   * When the requestManagedTenantChange action is dispatched, retrieves the new apiUrl
   * and changes its value into the apiClientService.
   * Once the operation is completed, dispatches the putManagedTenantSessionRequest as the
   * two side effects are chained.
   */
  changeManageTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestManagedTenantChange),
      map(({ tenant }) => tenant),
      mergeMap((tenant) =>
        this.organizationService.getSelectedTenantApiUrl(tenant.managedTenantId).pipe(
          tap((dc) => {
            this.apiClientService.orgEnvName = dc.dataCenter
            this.apiClientService.basePortalApiUrl = dc.apiUrl
          }),
          map(() => changeManagedTenantSuccess({ tenant })),
          catchError((error) => of(changeManagedTenantInvalidate({ error: error.message })))
        )
      )
    )
  )

  /**
   * When the changeManagedTenantSuccess is dispatched, takes care of performing
   * the related actions to fetch the data and bring them to the store
   */
  changeTenantSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeManagedTenantSuccess),
      mergeMap(({ tenant }) =>
        this.organizationService.putTenantSession(tenant.managedTenantId).pipe(
          map(() => requestTenantSessionSuccess({ tenant })),
          catchError((error) => of(changeManagedTenantInvalidate({ error: error.message })))
        )
      )
    )
  )

  refreshPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(requestTenantSessionSuccess),
        tap(({ tenant }) => {
          window.location.href = window.location.origin
        })
      ),
    { dispatch: false }
  )

  constructor(
    private actions$: Actions,
    private apiClientService: ApiclientService,
    private organizationService: OrganizationService,
    private storage: LocalstorageService,
    private store: Store<RootState>
  ) {}
}

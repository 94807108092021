import { createReducer, on, } from '@ngrx/store'
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'
import { OnlineuserColumnState } from './onlineuser-columns.types'
import { getOnlineuserColumns, getOnlineuserColumnsInvalidate, getOnlineuserColumnsResponse } from './onlineuser-columns.actions'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'

export const onlineuserColumnsAdapter: EntityAdapter<CoreViewColumn> = createEntityAdapter<CoreViewColumn>({
  selectId: ({ name }) => name,
})

export const initialState: OnlineuserColumnState = onlineuserColumnsAdapter.getInitialState({
  didInvalidate: false,
  isFetching: false,
  error: null,
})

export const onlineuserColumnsReducer = createReducer(
  initialState,
  on(getOnlineuserColumns, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(getOnlineuserColumnsResponse, (state, onlineuserColumns) => onlineuserColumnsAdapter.setAll(onlineuserColumns.actions, state)),
  on(getOnlineuserColumnsInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true })),
)

export default onlineuserColumnsReducer

<mat-chip-listbox class="default-filter-indicator__chip" (mouseover)="openCard()" (mouseleave)="closeCard()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <mat-chip>{{'common_DefaultFiltersApplied' | translate}}</mat-chip>
</mat-chip-listbox>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="showCard">
  <div class="default-filter-indicator__hover" (mouseover)="openCard()" (mouseleave)="closeCard()" >
    <header class="default-filter-indicator__header">
      <mat-icon class="default-filter-indicator__logo">filter_alt</mat-icon>
      <h2 class="default-filter-indicator__title" translate>
        common_DefaultFilters
      </h2>
    </header>
    <p translate>reports_AllConditionTrue</p>
    <p class="default-filter-indicator__row">
      <mat-chip-listbox *ngIf="filterKey">
        <mat-chip>{{filterKey}}</mat-chip>
      </mat-chip-listbox>
      <span *ngIf="value">{{value}}</span>
    </p>
  </div>
</ng-template>

import { AgFilterComponent } from '@ag-grid-community/angular'
import { IFilterParams, IDoesFilterPassParams, AgPromise, IAfterGuiAttachedParams } from '@ag-grid-community/core'
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core'
import { TranslateHelper } from '@coreview/coreview-library'
import { Observable } from 'rxjs'
import { filterModel, SingleSelectMonthYear } from './single-select-monthYear.behavior'
import { debounceTime, takeUntil } from 'rxjs/operators'

export interface IFilterParamsSingleSelect extends IFilterParams {
  options: Observable<string[] | { value: string; display: string }[]>
  filterOptions: { key: string; value: string }[]
  defaultFilterType: '' | 'equals' | 'notEqual'
  columnFilterType: string
}

@Component({
  selector: 'app-single-select-filter',
  templateUrl: './single-select-filter.component.html',
  styleUrls: ['./single-select-filter.component.sass'],
})
export class SingleSelectFilterComponent implements OnInit, OnDestroy, AgFilterComponent {
  params!: IFilterParamsSingleSelect
  value!: string | null
  type: '' | 'equals' | 'notEqual' | 'contains' | 'isEmpty' | 'isNotEmpty' = ''
  options!: { value: string; display: string }[]

  filterOptions = [
    { key: '', value: 'common_Select' },
    { key: 'equals', value: 'common_Equals' },
    { key: 'notEqual', value: 'common_NotEqual' },
  ]

  colId!: string

  destroyed$ = new EventEmitter<void>()
  inputChanged$ = new EventEmitter<string | null>()

  private hideFilter!: (() => void) | undefined

  constructor(private translateHelper: TranslateHelper) {}

  ngOnInit(): void {
    this.inputChanged$.pipe(debounceTime(500), takeUntil(this.destroyed$)).subscribe((currentValue) => {
      this.modelChanged()
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.emit()
    this.destroyed$.complete()
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {
    this.hideFilter = params?.hidePopup
  }

  isFilterActive(): boolean {
    if (!this.type) {
      return false
    }

    if (['isEmpty', 'isNotEmpty'].includes(this.type)) {
      return true
    }

    return !!this.value
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const value = this.params.valueGetter(params.data)

    return this.type === 'equals' ? value === this.value : value !== this.value
  }

  getFilterModel = (value: string | null): filterModel => ({ filterType: 'string', type: this.type as string, filter: value })

  getModel() {
    return this.type ? this.getFilterModel(this.value) : null
  }

  modelChanged() {
    if (!this.type || this.isFilterActive()) {
      this.params.filterChangedCallback({ source: 'columnFilter' })
      if (this.hideFilter && this.type !== 'contains') {
        this.hideFilter()
      }
    }
  }

  setModel(model: any): void | AgPromise<void> {
    if (model) {
      this.type = model.type || ''
      this.value = model.filter
    }
  }

  agInit(params: IFilterParamsSingleSelect): void {
    this.colId = params.column.getColId()
    this.params = params
    if (this.params.columnFilterType === 'monthYear') {
      SingleSelectMonthYear.Init()
      this.getFilterModel = SingleSelectMonthYear.getFilterModel
      this.options = SingleSelectMonthYear.getMonthYearOptions(this.translateHelper)
    } else {
      this.params.options.subscribe((res: string[] | { value: string; display: string }[]) => {
        this.options = res.map((item) => {
          if (typeof item === 'string') {
            return { value: item, display: item };
          } else {
            return { value: item.value, display: item.display };
          }
        })
      })
    }

    if (this.params.filterOptions) {
      this.filterOptions = this.params.filterOptions
      this.type = this.params.defaultFilterType
    }
  }

  onFloatingFilterChanged(type: string, value: string) {
    this.type = (type || '') as any
    this.value = value
    this.params.filterChangedCallback({ source: 'columnFilter' })
  }

  onInputBoxChanged() {
    this.inputChanged$.emit(this.value)
  }
}

import { LicensePickerValue, LicensesPickerComponent, License } from '@app/shared/components/licenses-picker/licenses-picker.component'
import { Injectable } from '@angular/core'
import { cloneDeep, concat, difference, isArray, pick, unset } from 'lodash-es'
import { Helpers } from '@app/shared/utilities/helpers'
import { TranslateHelper } from '@coreview/coreview-library'
import { EnhancedJsonFormData, EnhancedProperties, EnhancedPropertyFormDescriptor } from '../models/enhanced-json-form-data'
import { SummaryCard, SummaryTableDefinition } from '../models/summary-card'
import { PropertyFormDescriptor } from '@coreview/coreview-dynamoforms/interfaces/json-form-data.interface'
import dayjs, { isDayjs } from 'dayjs'
import { DatetimeUtcRangePickerComponent } from '../components/datetime-utc-range-picker/datetime-utc-range-picker.component'
import { ExtensionAttributesBuilderComponent } from '@app/modules/management/components/custom-components/extension-attributes-builder/extension-attributes-builder.component'
import { LicenseAllocatorComponent } from '@app/modules/licenses/components/license-allocator/license-allocator.component'
import { DynamicFieldsComponent } from '@app/modules/management/components/custom-components/dynamic-fields/dynamic-fields.component'
import {
  AutoAttendantRedirectionComponent,
  AutoAttendantRedirectionTypes,
} from '@app/modules/management/components/custom-components/auto-attendant-redirection/auto-attendant-redirection.component'
import { AutoAttendantBusinessHoursComponent } from '@app/modules/management/components/custom-components/auto-attendant-business-hours/auto-attendant-business-hours.component'
import { AutoAttendantHolidaySettingComponent } from '@app/modules/management/components/custom-components/auto-attendant-holiday-setting/auto-attendant-holiday-setting.component'
import { DynamoFormHelperService } from './dynamo-form.helper.service'

@Injectable({ providedIn: 'root' })
export class DynamoFormSummaryHelperService {
  constructor(private translateHelper: TranslateHelper) {}

  getSummaryProperties(jsonFormData: EnhancedJsonFormData, formValues: any): { [key: string]: any }[] {
    const summaryProperties: { [key: string]: any }[] = []

    if (!!jsonFormData && !!formValues) {
      let jsonProps: EnhancedProperties = { ...jsonFormData.properties }
      jsonProps = this.getPartialProperties(jsonProps)
      const sortedProperties = Object.entries(jsonProps)
        .filter(
          (x) =>
            !x[1].options?.hidden &&
            x[1].format !== 'partial' &&
            x[1].format !== 'button' &&
            !x[1].format?.includes('label') &&
            !(x[1].format === 'suggester-string' && !!x[1].options?.cleanValueAfterSelection) &&
            !(
              x[1].format === 'suggester' &&
              (!!x[1].options?.cleanValueAfterSelection || x[1].options?.cleanValueAfterSelection === undefined)
            )
        )
        .sort((a, b) => (!a[1].propertyOrder ? 1 : !b[1].propertyOrder ? -1 : a[1].propertyOrder - b[1].propertyOrder))

      sortedProperties.forEach((property) => {
        const propertyKey = property[0]
        const propertySchema = property[1] as PropertyFormDescriptor
        summaryProperties.push({
          [propertySchema.title || Helpers.capitalize(propertyKey)]: this.getSummaryPropertyData(propertyKey, propertySchema, formValues),
        })
      })
    }

    return summaryProperties
  }

  private getPartialProperties(jsonProps: EnhancedProperties): EnhancedProperties {
    Object.values(jsonProps)
      .filter((x) => x.format === 'partial' && !x.options?.hidden)
      .forEach((partialProp) => {
        Object.assign(jsonProps, partialProp.properties)
        if (partialProp.properties) {
          Object.assign(jsonProps, this.getPartialProperties(partialProp.properties))
        }
      })
    return jsonProps
  }

  private getSummaryPropertyData(propertyKey: string, propertySchema: PropertyFormDescriptor, formValues: any): any {
    const format = this.getFormatForSummary(propertySchema)

    if (this.formatSummaryMapping[format]) {
      return this.formatSummaryMapping[format](formValues[propertyKey], propertySchema, formValues)
    }
    return formValues[propertyKey]
  }

  private getTableValues(propertyValue: any, formValues: any, propertySchema: PropertyFormDescriptor): any[] {
    if (isArray(propertyValue) && propertyValue?.length > 0 && !!propertySchema.items?.properties) {
      if (propertySchema.options?.selectedProperty) {
        return this.formatTableValues(propertySchema.items?.properties, formValues[propertySchema.options.selectedProperty])
      }
      return this.formatTableValues(propertySchema.items?.properties, propertyValue)
    }
    return []
  }

  private formatTableValues(
    properties: {
      [s: string]: PropertyFormDescriptor & {
        filter?: PropertyFormDescriptor
        isRequired?: boolean
      }
    },
    tableValues: any[]
  ) {
    const formattedTableValues: any[] = []
    tableValues.forEach((value) => {
      const formattedValue: any = {}
      Object.entries(value).forEach(([k, v]) => {
        if (properties[k]) {
          formattedValue[k] = this.getSummaryPropertyData(k, properties[k], value)
        }
      })
      formattedTableValues.push(formattedValue)
    })
    return formattedTableValues
  }

  private findPropertyDescriptor(key: string, jsonFormData: EnhancedJsonFormData): PropertyFormDescriptor {
    return (
      jsonFormData.properties[key] ||
      Object.values(jsonFormData.properties)
        .filter((x) => x.properties)
        .map((x) => this.findPropertyDescriptor(key, x as EnhancedJsonFormData))
        .find((x) => !!x)
    )
  }

  private getFormatForSummary(propertySchema: EnhancedPropertyFormDescriptor): SummaryFormat {
    if (this.isEnumProperty(propertySchema)) {
      return 'enum'
    } else if (this.isMultiInputTagsProperty(propertySchema)) {
      return 'multi-input-tags'
    } else if (propertySchema.type === 'boolean') {
      return 'bool'
    } else if (propertySchema.format === 'custom') {
      if (propertySchema.options?.customType === DatetimeUtcRangePickerComponent) {
        return 'date-time-utc-range'
      } else if (propertySchema.options?.customType === ExtensionAttributesBuilderComponent) {
        return 'extension-attributes'
      } else if (propertySchema.options?.customType === LicenseAllocatorComponent) {
        return 'license-allocator'
      } else if (propertySchema.options?.customType === DynamicFieldsComponent) {
        return 'dynamic-fields'
      } else if (propertySchema.options?.customType === LicensesPickerComponent) {
        return 'license-picker'
      } else if (propertySchema.options?.customType === AutoAttendantRedirectionComponent) {
        return propertySchema.options?.summaryDataType === 'table' ? 'auto-attendant-redirection-table' : 'auto-attendant-redirection'
      } else if (propertySchema.options?.customType === AutoAttendantBusinessHoursComponent) {
        return 'auto-attendant-business-hours'
      } else if (propertySchema.options?.customType === AutoAttendantHolidaySettingComponent) {
        return 'auto-attendat-holiday'
      }
    }
    return propertySchema.format as SummaryFormat
  }

  private isEnumProperty(propertySchema: PropertyFormDescriptor): boolean {
    return (
      propertySchema.format !== 'multi-input' &&
      propertySchema.format !== 'suggester' &&
      !!propertySchema.enum?.length
    )
  }

  private isMultiInputTagsProperty(propertySchema: PropertyFormDescriptor): boolean {
    return propertySchema.type === 'tags' && propertySchema.format === 'multi-input'
  }

  private getPasswordSummaryValue = (propValue: string | undefined) =>
    propValue
      ? propValue
          .split('')
          .map(() => '*')
          .join('')
      : ''

  private getSuggesterSummaryValue = (propValue: { value: string; displayValue: string } | undefined) =>
    propValue ? propValue.displayValue : ''

  private getEnumSummaryValue = (propValue: any, propertySchema: PropertyFormDescriptor) => {
    const selectedIndex = propertySchema.enum?.indexOf(propValue)
    if (
      typeof selectedIndex === 'number' &&
      selectedIndex > -1 &&
      propertySchema.options?.enumDisplayValues?.length &&
      selectedIndex <= propertySchema.options?.enumDisplayValues?.length
    ) {
      return propertySchema.options.preventEnumTranslations
        ? propertySchema.options.enumDisplayValues[selectedIndex]
        : this.translateHelper.instant(propertySchema.options.enumDisplayValues[selectedIndex])
    } else if (propValue) {
      return this.translateHelper.instant(propValue)
    }
  }

  private getMultiInputTagsSummaryValue = (propValue: { value: string; displayValue: string }[] | undefined) =>
    propValue?.map((x: any) => x.displayValue)

    private getBooleanSummaryValue = (propValue: boolean | string | undefined) => {
      let propBoolValue = false;
      if (propValue === 'true' || propValue === true) {
        propBoolValue = true;
      }

      return propBoolValue ? this.translateHelper.instant('common_Yes') : this.translateHelper.instant('common_No');
    }

  private getTableSummaryValue = (propValue: any | undefined, propertySchema: PropertyFormDescriptor, formValues: any) => {
    const tableValues = this.getTableValues(propValue, formValues, propertySchema)
    let table: SummaryTableDefinition
    if (tableValues?.length > 0) {
      const propertyKeys = Object.entries(propertySchema.items?.properties || {})
        .filter((x: any[]) => !x[1].options?.hidden)
        .map((x) => x[0])
      table = {
        headers: propertyKeys.map((x) => ({
          name: x,
          displayValue: propertySchema.items?.properties[x].title || Helpers.capitalize(x),
        })),
        values: tableValues.map((x: any) => pick(x, propertyKeys)),
      }
      return table
    }
    return undefined
  }

  private getFileSummaryValue = (propValue: File | undefined) => propValue?.name

  private getDateSummaryValue = (propValue?: any) => {
    if (!propValue) {
      return ''
    }

    if (String(propValue).startsWith('{')) {
      return propValue
    }

    if (isDayjs(propValue) && propValue.isValid()) {
      return propValue.format('LL')
    }

    const parsedDate = dayjs(propValue)

    return parsedDate.isValid()
      ? parsedDate.format('LL')
      : parsedDate
  }

  private getDatetimeSummaryValue = (propValue?: any) => {
    if (propValue) {
      return isDayjs(propValue) ? propValue.utc(false).format('L LT') : dayjs(propValue).utc(false).format('L LT')
    }
    return ''
  }

  private getDateTimeUtcRangeSummaryValue = (propValue?: any) =>
    !propValue?.startDatetime || !propValue?.endDatetime
      ? ''
      : this.translateHelper.instant('startDate') +
        ': ' +
        propValue.startDatetime.format('LLL') +
        '<br>' +
        this.translateHelper.instant('endDate') +
        ': ' +
        propValue.endDatetime.format('LLL')

  private getExtensionAttributesSummaryValue = (propValue?: any) =>
    !propValue
      ? ''
      : Object.entries(propValue)
          .map(([k, v]) => k + ': ' + (!v ? '' : this.getExtensionAttributeValue(v)))
          .join('<br>')

  private getExtensionAttributeValue(value: any) {
    return isDayjs(value) ? value.format('LLL') : value.toString()
  }

  private getLicenseAllocatorSummaryValue = (_propValue: any, _propertySchema: PropertyFormDescriptor, formValues: any) =>
    formValues.licenses
      .map((v: any) => {
        const htmlNewAssigned =
          v.newAssigned === undefined || v.newAssigned === v.assigned
            ? ''
            : '<span class=" ' +
              (v.newAssigned > v.assigned ? 'success' : v.newAssigned < v.assigned ? 'error' : '') +
              '">' +
              ((+v.newAssigned || 0) >= v.assigned ? '+ ' : ' ') +
              ((+v.newAssigned || 0) - v.assigned) +
              '</span>' +
              '<span> = </span>' +
              '<span>' +
              (+v.newAssigned || 0) +
              '</span>'

        const html = '<div>' + v.sku + '</div>' + '<div>' + v.assigned + ' ' + htmlNewAssigned + '</div>'

        return html
      })
      .join('<br>')

  private getDynamicFieldsSummaryValue = (propValue?: { key: string; value: any; title: string }[]) => {
    if (propValue?.length) {
      const initialValue = {}
      return propValue.reduce(
        (obj, item) => ({
          ...obj,
          [item.title]: isDayjs(item.value) ? item.value.format('LLL') : item.value,
        }),
        initialValue
      )
    }
    return undefined
  }

  private getLicensePickerSummaryValue = (propValue?: LicensePickerValue) => {
    if (!propValue) {
      return ''
    }

    const licensesEnabled = propValue.licenses
      ?.filter((l) => l.plans.some((x) => x.status === 'enabled'))
      .map(
        (l) =>
          l.sku.trim() +
          ': ' +
          l.plans
            ?.filter((x) => x.status === 'enabled')
            .map((x) => x.title)
            .join(', ')
      )
      .join('<br>')
    const licensesDisabled = propValue.licenses
      ?.filter((l) => l.plans.some((x) => x.status === 'disabled'))
      .map(
        (l) =>
          l.sku.trim() +
          ': ' +
          l.plans
            ?.filter((x) => x.status === 'disabled')
            .map((x) => x.title)
            .join(', ')
      )
      .join('<br>')
    return (
      (licensesEnabled
        ? // eslint-disable-next-line @typescript-eslint/quotes
          "<span class='cv-title-underline m-t-5'>" +
          this.translateHelper.instant('management_LicensesEnabled') +
          '</span>' +
          licensesEnabled
        : '') +
      (licensesDisabled
        ? // eslint-disable-next-line @typescript-eslint/quotes
          "<span class='cv-title-underline m-t-5'>" +
          this.translateHelper.instant('management_LicensesDisabled') +
          '</span>' +
          licensesDisabled
        : '')
    )
  }

  private getAutoAttendantRedirectionSummaryTableValue = (propValue?: any) => {
    const { destinationValue, type } = this.getAutoAttendantRedirectionSummaryBase(propValue)
    let formattedValue = destinationValue

    if (type === 'SharedVoicemail') {
      formattedValue += " | " + this.getSummaryPropertyData('enableTranscription', { type: 'boolean' }, propValue)
      formattedValue += " | " + this.getSummaryPropertyData('enableSharedVoicemailSystemPromptSuppression', { type: 'boolean' }, propValue)
    }

    return formattedValue
  }

  private getAutoAttendantRedirectionSummaryValue = (propValue?: any) => {
    const { destinationValue, type } = this.getAutoAttendantRedirectionSummaryBase(propValue)
    const formattedValue = new Map()
    formattedValue.set(this.translateHelper.instant(Helpers.capitalize('callFlowRedirectSettings')), destinationValue)
    if (type === 'SharedVoicemail') {
      formattedValue.set(this.translateHelper.instant('management_Transcription'), this.getSummaryPropertyData('enableTranscription', { type: 'boolean' }, propValue))
      formattedValue.set(this.translateHelper.instant('management_SuppressVoicemailSystemMessage'), this.getSummaryPropertyData('enableSharedVoicemailSystemPromptSuppression', { type: 'boolean' }, propValue))
    }
    return formattedValue
  }

  private getAutoAttendantRedirectionSummaryBase = (propValue?: any) => {
    let destinationValue = ''
    const type = propValue.type as AutoAttendantRedirectionTypes
    if (type) {
      destinationValue = this.translateHelper.instant(AutoAttendantRedirectionComponent.displayValueMapping[type])
      if (propValue.destination) {
        if (type === 'AnnouncementAudio') {
          destinationValue += ' - ' + this.getSummaryPropertyData('destination', { type: 'object', format: 'file' }, propValue)
        } else if (type === 'AnnouncementText' || type === 'ExternalPstn') {
          destinationValue += ' - ' + propValue.destination
        } else {
          destinationValue += ' - ' + this.getSummaryPropertyData('destination', { type: 'string', format: 'suggester' }, propValue)
        }
      }
    }

    return { destinationValue, type };
  }

  private getAutoAttendantBusinessHoursSummaryValue = (propValue?: any) => {
    const formattedValue = new Map()
    ;['sundayHours', 'mondayHours', 'tuesdayHours', 'wednesdayHours', 'thursdayHours', 'fridayHours', 'saturdayHours'].forEach(
      (dayOfWeek) => {
        formattedValue.set(
          this.translateHelper.instant(AutoAttendantBusinessHoursComponent.dayOfWeekLabelMapping[dayOfWeek]),
          (propValue[dayOfWeek] || []).map((setting: { startTime: string; endTime: string }) => {
            const hs = Number(setting.startTime.split(':')[0])
            const ms = Number(setting.startTime.split(':')[1])
            const he = Number(setting.endTime.split(':')[0])
            const me = Number(setting.endTime.split(':')[1])
            return `${dayjs().set('hour', hs).set('minute', ms).format('hh:mm A')} - ${dayjs()
              .set('hour', he)
              .set('minute', me)
              .format('hh:mm A')}`
          })
        )
      }
    )
    return formattedValue
  }

  private getEnabledLicenses(licenses: License[] | undefined, isCloneAction: boolean) {
    return licenses
      ?.filter(
        (l) =>
          l.status === 'assigned' &&
          (l.plans.some((x) => x.status !== x.oldStatus && x.status === 'enabled') || (isCloneAction && l.remainingUnits >= 1))
      )
      .map((l) => ({
        [l.sku.trim()]: l.plans
          ?.filter((x) => x.status === 'enabled' && (x.status !== x.oldStatus || isCloneAction))
          .map((x) => x.title)
          .join('<br/>'),
      }))
      .reduce(
        (obj, item) => ({
          ...obj,
          ...item,
        }),
        {}
      )
  }

  private hasSkuToRemove(skusToRemove: { id: string }[], l: License): boolean {
    return skusToRemove.some((obj) => obj.id === l.id)
  }
  private hasPlanToRemove(l: License, isSingleTargetOrCreate: boolean) {
    return l.plans.some(
      (x) =>
        (x.status !== x.oldStatus && x.status === 'disabled') ||
        (isSingleTargetOrCreate && l.status === 'removed' && x.oldStatus !== 'disabled')
    )
  }
  private addPlanRemoved(l: License, isSingleTargetOrCreate: boolean) {
    return l.plans
      ?.filter((x) => x.status === 'disabled' || (isSingleTargetOrCreate && l.status === 'removed' && x.oldStatus !== 'disabled'))
      .map((x) => x.title)
      .join('<br/>')
  }

  private addPlanRemovedBySku(l: License) {
    return l.plans
      ?.filter((x) => x.status === 'enabled')
      .map((x) => x.title)
      .join('<br/>')
  }

  private getDisabledLicenses(licenses: License[] | undefined, isSingleTargetOrCreate: boolean, skusToRemove: { id: string }[]) {
    return licenses
      ?.filter((l) => this.hasSkuToRemove(skusToRemove, l) || this.hasPlanToRemove(l, isSingleTargetOrCreate))
      .map((l) => ({
        [l.sku.trim()]: this.hasSkuToRemove(skusToRemove, l)
          ? this.addPlanRemovedBySku(l)
          : this.addPlanRemoved(l, isSingleTargetOrCreate),
      }))
      .reduce(
        (obj, item) => ({
          ...obj,
          ...item,
        }),
        {}
      )
  }

  private getManageLicensesTargetsSummaryCards(
    formValues: any,
    selectedTargets: any[],
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] {
    const summaryCards: SummaryCard[] = []

    const ignoredTargetsOnPremises = formValues.onPremisesTargets || []
    const selectedTargetsValidAccType = formValues.selectedTargets.filter((x: any) => !ignoredTargetsOnPremises.includes(x.userPrincipalName))

    const ignoredTargetsSummaryProperties = []
    let ignoredTargetsWithoutUsageLocation = []

    if (!formValues.assignLocation && !formValues.usageLocation) {
      ignoredTargetsWithoutUsageLocation = selectedTargetsValidAccType.filter((x: any) => !x.usageLocation).map((x: any) => x.userPrincipalName)
      if (ignoredTargetsWithoutUsageLocation.length) {
        ignoredTargetsSummaryProperties.push({
          ['management_TargetsWithoutUsageLocation']: ignoredTargetsWithoutUsageLocation,
        })
      }
    }

    if (ignoredTargetsOnPremises.length) {
      ignoredTargetsSummaryProperties.push({
        ['management_TargetsOnPremises']: ignoredTargetsOnPremises,
      })
    }

    if (ignoredTargetsSummaryProperties.length) {
      summaryCards.push({
        title: 'management_IgnoredTargets',
        properties: [...ignoredTargetsSummaryProperties],
        editFunction: goToEditPropertiesFunction,
      })
    }

    summaryCards.push(...this.getTargetsUsageLocationSummaryCards(formValues, selectedTargetsValidAccType, goToEditPropertiesFunction))

    return summaryCards
  }

  private getTargetsUsageLocationSummaryCards(
    formValues: any,
    selectedTargets: any[],
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] {
    const summaryCards: SummaryCard[] = []

    if (!!formValues.assignLocation && !!formValues.usageLocation) {
      const newULTargets = selectedTargets.filter((x) => x.usageLocation !== formValues.usageLocation)
      if (newULTargets.length) {
        // all targets that will receive new OU
        summaryCards.push({
          title: 'management_TargetsNewUsageLocation',
          properties: [
            { ['management_NewUsageLocation']: formValues.usageLocation },
            {
              ['Users']: newULTargets.map((x: any) => x.userPrincipalName),
            },
          ],
          editFunction: goToEditPropertiesFunction,
        })
      }

      const sameULTargets = selectedTargets.filter((x) => x.usageLocation === formValues.usageLocation)
      if (sameULTargets.length) {
        // all targets that will will keep current UL
        summaryCards.push({
          title: 'management_TargetsSameUsageLocation',
          properties: [
            {
              ['Users']: sameULTargets.map((x: any) => x.userPrincipalName),
            },
          ],
          editFunction: goToEditPropertiesFunction,
        })
      }
    } else {
      const validTargets = selectedTargets.filter((x: any) => !!x.usageLocation)
      if (validTargets.length) {
        // valid targets: will keep current UL
        summaryCards.push({
          title: 'management_TargetsSameUsageLocation',
          properties: [
            {
              ['Users']: validTargets.map((x: any) => x.userPrincipalName),
            },
          ],
          editFunction: goToEditPropertiesFunction,
        })
      }
      const noUlTargets = selectedTargets.filter((x: any) => !x.usageLocation)
      if (!!formValues.usageLocation && !!noUlTargets.length) {
        // targets that will receive a new usage location value because it was empty
        summaryCards.push({
          title: 'management_TargetsNewUsageLocation',
          properties: [
            { ['management_NewUsageLocation']: formValues.usageLocation },
            {
              ['Users']: noUlTargets.map((x: any) => x.userPrincipalName),
            },
          ],
          editFunction: goToEditPropertiesFunction,
        })
      }
    }

    return summaryCards
  }

  // This function should remain as arrow function
  private getManageLicensesSummary = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const summaryCards: SummaryCard[] = []

    const isSingleTargetOrCreate = selectedTargets.length === 0 || (selectedTargets.length === 1 && !formValues.selectedTargets)
    const value = formValues.licenses as LicensePickerValue

    const licensesInError = formValues.isCloneAction
      ? value?.licenses?.filter((sku) => sku.remainingUnits < 1 && sku.status === 'assigned')
      : []

    const skusToRemove = value.skusToRemove
    const licensesEnabled = this.getEnabledLicenses(value?.licenses, !!formValues.isCloneAction)
    const licensesDisabled = this.getDisabledLicenses(value?.licenses, isSingleTargetOrCreate, skusToRemove)

    if (isSingleTargetOrCreate) {
      const locationSummaryCard = {
        title: 'management_LocationLicensePool',
        properties: [],
        editFunction: goToEditPropertiesFunction,
      } as SummaryCard
      const propertySchema = this.findPropertyDescriptor('usageLocation', jsonFormData)
      locationSummaryCard.properties.push({
        [propertySchema.title || Helpers.capitalize('usageLocation')]: this.getSummaryPropertyData(
          'usageLocation',
          propertySchema,
          formValues
        ),
      })
      if (!!value.licensePoolGroupId && value.licensePoolGroupName) {
        locationSummaryCard.properties.push({
          ['common_LicensePool']: value.licensePoolGroupName,
        })
      }
      summaryCards.push(locationSummaryCard)
    } else if (formValues.selectedTargets?.length) {
      summaryCards.push(...this.getManageLicensesTargetsSummaryCards(formValues, selectedTargets, goToEditPropertiesFunction))
    }

    summaryCards.push(
      {
        title: 'management_LicensesEnabled',
        disclaimer: licensesInError?.length
          ? {
              variant: 'warning',
              message: `${this.translateHelper.instant('management_NoLicensesAvailableCloneUser')} ${licensesInError
                .map((x) => x.sku)
                .join(', ')}`,
            }
          : undefined,
        properties: [
          !!licensesEnabled && !!Object.keys(licensesEnabled).length
            ? licensesEnabled
            : { ['LicensesEnabled']: this.translateHelper.instant('management_NoLicensesEnabled') },
        ],
        editFunction: goToEditPropertiesFunction,
      },
      {
        title: 'management_LicensesDisabled',
        properties: [
          !!licensesDisabled && !!Object.keys(licensesDisabled).length
            ? licensesDisabled
            : { ['LicensesDisabled']: this.translateHelper.instant('management_NoLicensesDisabled') },
        ],
        editFunction: goToEditPropertiesFunction,
      }
    )

    return summaryCards
  }

  // This function should remain as arrow function
  private getManageServicePlansSummary = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const summaryCards: SummaryCard[] = []
    const value = formValues.servicePlans as {
      servicePlansToBeAddedSummary: string[]
      servicePlansToBeRemovedSummary: string[]
      skusToBeAddedSummary: { sku: string; services: string[] }[]
      skusToBeRemovedSummary: { sku: string; services: string[] }[]
    }

    const licensesEnabled = value?.skusToBeAddedSummary?.length
      ? value.skusToBeAddedSummary
          .map((l) => ({
            [l.sku.trim()]: l.services.join('<br/>'),
          }))
          .reduce(
            (obj, item) => ({
              ...obj,
              ...item,
            }),
            {}
          )
      : {
          ['management_ServicePlansEnabled']: value?.servicePlansToBeAddedSummary?.length
            ? value.servicePlansToBeAddedSummary
            : this.translateHelper.instant('management_NoServicePlansEnabled'),
        }

    const licensesDisabled = value?.skusToBeRemovedSummary?.length
      ? value.skusToBeRemovedSummary
          .map((l) => ({
            [l.sku.trim()]: l.services.join('<br/>'),
          }))
          .reduce(
            (obj, item) => ({
              ...obj,
              ...item,
            }),
            {}
          )
      : {
          ['management_ServicePlansDisabled']: value?.servicePlansToBeRemovedSummary?.length
            ? value.servicePlansToBeRemovedSummary
            : this.translateHelper.instant('management_NoServicePlansDisabled'),
        }

    summaryCards.push(
      {
        title: 'management_ServicePlansEnabled',
        properties: [licensesEnabled],
        editFunction: goToEditPropertiesFunction,
      },
      {
        title: 'management_ServicePlansDisabled',
        properties: [licensesDisabled],
        editFunction: goToEditPropertiesFunction,
      }
    )

    return summaryCards
  }

  private addMembersToGroups = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const summaryCards: SummaryCard[] = []
    const membersProp = this.findPropertyDescriptor('selectedMembers', jsonFormData)
    const groupsProp = this.findPropertyDescriptor('groups', jsonFormData)

    summaryCards.push({
      title: 'management_NewMembers',
      properties: [
        { [membersProp.title || 'management_SelectedMembers']: this.getSummaryPropertyData('selectedMembers', membersProp, formValues) },
      ],
      editFunction: goToSelectTargetsFunction || goToEditPropertiesFunction,
    })

    summaryCards.push({
      title: 'management_Groups',
      properties: [{ [groupsProp.title || 'Groups']: this.getSummaryPropertyData('groups', groupsProp, formValues) }],
      editFunction: goToEditPropertiesFunction,
    })

    return summaryCards
  }

  private addGroupsToMembers = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const summaryCards: SummaryCard[] = []
    const membersProp = this.findPropertyDescriptor('selectedMembers', jsonFormData)
    const groupsProp = this.findPropertyDescriptor('groups', jsonFormData)

    summaryCards.push({
      title: 'management_Groups',
      properties: [{ [groupsProp.title || 'Groups']: this.getSummaryPropertyData('groups', groupsProp, formValues) }],
      editFunction: goToSelectTargetsFunction || goToEditPropertiesFunction,
    })

    summaryCards.push({
      title: 'management_NewMembers',
      properties: [
        { [membersProp.title || 'management_SelectedMembers']: this.getSummaryPropertyData('selectedMembers', membersProp, formValues) },
      ],
      editFunction: goToEditPropertiesFunction,
    })

    return summaryCards
  }

  private emailAddresses = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const summaryCards: SummaryCard[] = []
    const emailsProp = this.findPropertyDescriptor('emailAddresses', jsonFormData)
    summaryCards.push({
      title: 'EmailAddresses',
      properties: [{ [emailsProp.title || 'EmailAddresses']: this.getSummaryPropertyData('emailAddresses', emailsProp, formValues) }],
      editFunction: goToEditPropertiesFunction,
    })
    return summaryCards
  }

  private sharepointExternalSharing = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const summaryCards: SummaryCard[] = []
    const form = cloneDeep(jsonFormData)
    const values = { ...formValues }

    const expirationGuestSameAsOrgPath = DynamoFormHelperService.getPropertyPath(form.properties, 'expirationGuestSameAsOrg')
    unset(form, `properties.${expirationGuestSameAsOrgPath}`)
    if (values.expirationGuestSameAsOrg) {
      values.autoExpire = this.translateHelper.instant('management_SameAsOrganizationSettings')
      const expirationDaysPath = DynamoFormHelperService.getPropertyPath(form.properties, 'expirationDays')
      unset(form, `properties.${expirationDaysPath}`)
    }

    const defaultSharingSameAsOrgPath = DynamoFormHelperService.getPropertyPath(form.properties, 'defaultSharingSameAsOrg')
    unset(form, `properties.${defaultSharingSameAsOrgPath}`)
    if (values.defaultSharingSameAsOrg) {
      values.sharingLinkType = this.translateHelper.instant('management_SameAsOrganizationSettings')
    }

    const expirationAnyoneLinkSameAsOrgPath = DynamoFormHelperService.getPropertyPath(form.properties, 'expirationAnyoneLinkSameAsOrg')
    unset(form, `properties.${expirationAnyoneLinkSameAsOrgPath}`)
    if (values.expirationAnyoneLinkSameAsOrg) {
      values.autoExpireAnyoneLink = this.translateHelper.instant('management_SameAsOrganizationSettings')
    }

    const linkPermissionSameAsOrgPath = DynamoFormHelperService.getPropertyPath(form.properties, 'linkPermissionSameAsOrg')
    unset(form, `properties.${linkPermissionSameAsOrgPath}`)
    if (values.linkPermissionSameAsOrg) {
      values.sharingPermissionType = this.translateHelper.instant('management_SameAsOrganizationSettings')
      const anyoneLinkExpirationDaysPath = DynamoFormHelperService.getPropertyPath(form.properties, 'anyoneLinkExpirationDays')
      unset(form, `properties.${anyoneLinkExpirationDaysPath}`)
    }

    const summaryProps = this.getSummaryProperties(form, values)
    summaryCards.push({
      title: 'management_Properties',
      properties: summaryProps,
      editFunction: goToEditPropertiesFunction,
    })
    return summaryCards
  }

  private manageM365GroupMembers = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const summaryCards: SummaryCard[] = []
    const newMembersProp = this.findPropertyDescriptor('newMembers', jsonFormData)
    const modifiedMembersProp = this.findPropertyDescriptor('modifiedMembers', jsonFormData)

    summaryCards.push({
      title: 'management_NewMembers',
      properties: [
        { [newMembersProp.title || 'management_NewMembers']: this.getSummaryPropertyData('newMembers', newMembersProp, formValues) },
      ],
      editFunction: goToEditPropertiesFunction,
    })

    formValues.modifiedMembers = formValues.modifiedMembers?.filter((x: any) => !formValues.removedMembers?.find((r: any) => r.userPrincipalName === x.userPrincipalName))
    summaryCards.push({
      title: 'management_ModifiedMembers',
      properties: [
        {
          [modifiedMembersProp.title || 'management_ModifiedMembers']: this.getSummaryPropertyData(
            'modifiedMembers',
            modifiedMembersProp,
            formValues
          ),
        },
      ],
      editFunction: goToEditPropertiesFunction,
    })

    summaryCards.push({
      title: 'management_RemovedMembers',
      properties: [
        {
          ['management_RemovedMembers']: this.getSummaryPropertyData(
            'removedMembers',
            modifiedMembersProp,
            formValues
          ),
        },
      ],
      editFunction: goToEditPropertiesFunction,
    })

    return summaryCards
  }

  private accessesToMailboxes = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const propertySchema = this.findPropertyDescriptor('accessesToMailboxes', jsonFormData)
    const permissions = formValues.accessesToMailboxes?.reduce((prev: any, curr: any) => {
      const accessesKeys = Object.keys(curr).filter((x) => x !== 'mailbox' && !!curr[x])
      const accessesTranslated = accessesKeys.map((x) =>
        this.translateHelper.instant(propertySchema.items?.properties[x]?.title || Helpers.capitalize(x))
      )
      prev.push({ [curr.mailbox]: accessesTranslated })
      return prev
    }, [])
    const summaryCards: SummaryCard[] = [
      {
        title: 'management_AccessToOtherMailboxes',
        properties: permissions.length
          ? permissions
          : [{ ['Mailboxes']: this.translateHelper.instant('management_NoAccessesToMailboxes') }],
        editFunction: goToEditPropertiesFunction,
      },
    ]
    return summaryCards
  }

  // select mailboxes first, then delegates
  private addDelegatesToMailboxes = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const permissionsEnabledTranslated = this.getPermissionsEnabledTranslated(formValues, jsonFormData)
    const permissionsDisabledTranslated = this.getPermissionsDisabledTranslated(formValues, jsonFormData)
    const summaryCards: SummaryCard[] = [
      {
        title: 'Mailboxes',
        properties: [
          {
            ['Mailbox']: selectedTargets.map((x) => x.userPrincipalName),
          },
        ],
        editFunction: goToEditPropertiesFunction,
      },
      {
        title: 'management_Delegates',
        properties: [
          {
            ['UserPrincipalName']: formValues.usersListArray?.length
              ? formValues.usersListArray?.map((x: any) => x.value)
              : this.translateHelper.instant('management_NoDelegatesAdded'),
            ['management_PermissionType']: concat(permissionsEnabledTranslated, permissionsDisabledTranslated).sort((a, b) => a.localeCompare(b))
          },
        ],
        editFunction: goToSelectTargetsFunction,
      },
    ]
    return summaryCards
  }

  // select users first, then mailboxes
  private addUsersAsDelegates = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const permissionsEnabledTranslated = this.getPermissionsEnabledTranslated(formValues, jsonFormData)

    const summaryCards: SummaryCard[] = [
      {
        title: 'management_Delegates',
        properties: [
          {
            ['UserPrincipalName']: selectedTargets.map((x) => x.loginName || x.userPrincipalName),
          },
        ],
        editFunction: goToSelectTargetsFunction,
      },
      {
        title: 'Mailboxes',
        properties: [
          {
            ['Mailbox']: formValues.mbxListArray?.length
              ? formValues.mbxListArray?.map((x: any) => x.value)
              : this.translateHelper.instant('management_NoMailboxesAdded'),
            ['management_PermissionType']: permissionsEnabledTranslated,
          },
        ],
        editFunction: goToEditPropertiesFunction,
      },
    ]
    return summaryCards
  }

  private addCalendarPermission = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const accessRightsEnabledTranslated = this.getAccessRightsEnabledTranslated(formValues, jsonFormData)

    const summaryCards: SummaryCard[] = [
      {
        title: 'common_Permissions',
        properties: [
          {
            ['management_GrantAccessToMailboxes']: formValues.mailboxes?.length
              ? formValues.mailboxes?.map((x: any) => x.mailbox)
              : this.translateHelper.instant('management_NoMailboxesAdded'),
          },
          {
            ['management_Roles']: formValues.roles,
          },
          {
            ['management_AccessRights']: accessRightsEnabledTranslated,
          },
        ],
        editFunction: goToEditPropertiesFunction,
      },
    ]
    return summaryCards
  }

  private editCalendarPermission = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const accessRightsEnabledTranslated = this.getAccessRightsEnabledTranslated(formValues, jsonFormData)

    const summaryCards: SummaryCard[] = [
      {
        title: 'common_Permissions',
        properties: [
          {
            ['common_User']: formValues.user,
          },
          {
            ['common_Folder']: formValues.folderName,
          },
          {
            ['management_Roles']: formValues.roles,
          },
          {
            ['management_AccessRights']: accessRightsEnabledTranslated,
          },
        ],
        editFunction: goToEditPropertiesFunction,
      },
    ]
    return summaryCards
  }

  private getPermissionsEnabledTranslated(formValues: any, jsonFormData: EnhancedJsonFormData) {
    const permissionsEnabled = Object.entries(formValues)
      .filter(
        (x) =>
          [
            'automapping',
            'grantSendOnBehalfTo',
            'sendAs',
            'FullAccess',
            'ExternalAccount',
            'DeleteItem',
            'ReadPermission',
            'ChangePermission',
            'ChangeOwner',
          ].includes(x[0]) && !!x[1]
      )
      .map((x) => x[0])
    const permissionsEnabledTranslated = permissionsEnabled.map((x) => {
      const permissionTitle = this.findPropertyDescriptor(x, jsonFormData)?.title
      return permissionTitle ? this.translateHelper.instant(permissionTitle) : x
    })
    return permissionsEnabledTranslated
  }

  private getPermissionsDisabledTranslated(formValues: any, jsonFormData: EnhancedJsonFormData) {
    const permissionsDisabled = Object.entries(formValues)
      .filter((x) => ['grantSendOnBehalfTo', 'sendAs'].includes(x[0]) && x[1] === false)
      .map((x) => x[0])
    const permissionsDisabledTranslated = permissionsDisabled.map((x) => {
      const permissionTitle = this.findPropertyDescriptor(x, jsonFormData)?.title
      return (
        (permissionTitle ? this.translateHelper.instant(permissionTitle) : x) +
        ' (' +
        Helpers.downcase(this.translateHelper.instant('management_Remove') || 'Remove') +
        ')'
      )
    })
    return permissionsDisabledTranslated
  }

  private getAccessRightsEnabledTranslated(formValues: any, jsonFormData: EnhancedJsonFormData) {
    const permissionsEnabled = Object.entries(formValues)
      .filter(
        (x) =>
          [
            'readItems',
            'createItems',
            'editOwnedItems',
            'deleteOwnedItems',
            'editAllItems',
            'deleteAllItems',
            'createSubfolders',
            'folderOwner',
            'folderContact',
            'folderVisible',
          ].includes(x[0]) && !!x[1]
      )
      .map((x) => x[0])

    const permissionsEnabledTranslated = permissionsEnabled.map((x) => {
      const permissionTitle = this.findPropertyDescriptor(x, jsonFormData)?.title
      return permissionTitle ? this.translateHelper.instant(permissionTitle) : x
    })
    return permissionsEnabledTranslated
  }

  private manageQuarantineMessages = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const summaryCards: SummaryCard[] = [
      {
        title: '',
        properties: [
          {
            ['management_Choose']: this.translateHelper.instant(formValues.releaseType),
          },
        ],
        editFunction: goToEditPropertiesFunction,
      },
    ]

    if (formValues.releaseType === 'management_ReleaseToSpecificRecipient') {
      summaryCards[0].properties.push({
        ['management_RecipientsToRelease']: formValues.recipientsList.filter((x: any) => x.selected).map((x: any) => x.address),
      })
    }

    return summaryCards
  }

  private removeTags = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const assignedTagsProp = this.findPropertyDescriptor('tags', jsonFormData)

    const summaryCards: SummaryCard[] = [
      {
        title: 'management_Properties',
        properties: [
          {
            ['management_AssignedTags']: this.getSummaryPropertyData('tags', assignedTagsProp, formValues),
            ['management_RemovedTags']: difference(formValues.initialTags, formValues.tags),
          },
        ],
        editFunction: goToEditPropertiesFunction,
      },
    ]

    return summaryCards
  }

  private removeHoliday = (
    formValues: any,
    jsonFormData: EnhancedJsonFormData,
    selectedTargets: any[],
    goToSelectTargetsFunction?: () => void,
    goToEditPropertiesFunction?: () => void
  ): SummaryCard[] => {
    const properties = ['name', 'startEndDatesAsString']

    const summaryCards: SummaryCard[] = [
      {
        title: 'management_Targets',
        properties: [{
          ['Targets']: {
            headers: properties.map((x) => ({
              name: x,
              displayValue: Helpers.capitalize(x || ''),
            })),
            values: selectedTargets.map(target => ({name: target.name, startEndDatesAsString: target.startEndDatesAsString.join('<br/>')})),
          },
        }],
        editFunction: goToSelectTargetsFunction,
      },
    ]

    return summaryCards
  }

  /* eslint-disable @typescript-eslint/member-ordering */
  formatSummaryMapping: Record<SummaryFormat, (propertyValue: any, propertySchema: PropertyFormDescriptor, formValues: any) => any> = {
    password: this.getPasswordSummaryValue,
    suggester: this.getSuggesterSummaryValue,
    enum: this.getEnumSummaryValue,
    'multi-input-tags': this.getMultiInputTagsSummaryValue,
    bool: this.getBooleanSummaryValue,
    table: this.getTableSummaryValue,
    file: this.getFileSummaryValue,
    date: this.getDateSummaryValue,
    datetime: this.getDatetimeSummaryValue,
    'date-time-utc-range': this.getDateTimeUtcRangeSummaryValue,
    'extension-attributes': this.getExtensionAttributesSummaryValue,
    'license-allocator': this.getLicenseAllocatorSummaryValue,
    'dynamic-fields': this.getDynamicFieldsSummaryValue,
    'license-picker': this.getLicensePickerSummaryValue,
    'auto-attendant-redirection-table': this.getAutoAttendantRedirectionSummaryTableValue,
    'auto-attendant-redirection': this.getAutoAttendantRedirectionSummaryValue,
    'auto-attendant-business-hours': this.getAutoAttendantBusinessHoursSummaryValue,
    'auto-attendat-holiday': this.getSuggesterSummaryValue,
  }

  customSummaryMapping: Record<
    string,
    (
      formValues: any,
      jsonFormData: EnhancedJsonFormData,
      selectedTargets: any[],
      goToSelectTargetsFunction?: () => void,
      goToEditPropertiesFunction?: () => void
    ) => SummaryCard[]
  > = {
    manageLicenses: this.getManageLicensesSummary,
    manageServicePlans: this.getManageServicePlansSummary,
    addMembersToGroups: this.addMembersToGroups,
    addGroupsToMembers: this.addGroupsToMembers,
    emailAddresses: this.emailAddresses,
    sharepointExternalSharing: this.sharepointExternalSharing,
    manageM365GroupMembers: this.manageM365GroupMembers,
    accessesToMailboxes: this.accessesToMailboxes,
    addDelegatesToMailboxes: this.addDelegatesToMailboxes,
    addUsersAsDelegates: this.addUsersAsDelegates,
    addCalendarPermission: this.addCalendarPermission,
    editCalendarPermission: this.editCalendarPermission,
    manageQuarantineMessages: this.manageQuarantineMessages,
    removeTags: this.removeTags,
    removeHoliday: this.removeHoliday,
    none: () => [],
  }
  /* eslint-enable @typescript-eslint/member-ordering */
}

type SummaryFormat =
  | 'password'
  | 'suggester'
  | 'enum'
  | 'multi-input-tags'
  | 'bool'
  | 'table'
  | 'file'
  | 'date'
  | 'datetime'
  | 'date-time-utc-range'
  | 'extension-attributes'
  | 'license-allocator'
  | 'dynamic-fields'
  | 'license-picker'
  | 'auto-attendant-redirection-table'
  | 'auto-attendant-redirection'
  | 'auto-attendant-business-hours'
  | 'auto-attendat-holiday'

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { Workflow } from '@app/modules/workflow/models/workflow.model'
import { Subject } from 'rxjs'
import { debounceTime, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators'

@Component({
  selector: 'app-details-icon',
  templateUrl: './details-icon.component.html',
  styleUrls: ['./details-icon.component.sass'],
})
export class DetailsIconComponent implements OnInit, OnChanges, OnDestroy {
  @Input() action!: Workflow.Dto.V1.Common.Action
  @Input() isCatch = false

  objectKeys = Object.keys
  showCard = false

  public actionSettings: any = {}
  public iconName = ''

  private destroyed$: Subject<boolean> = new Subject()
  private open$: Subject<boolean> = new Subject()
  private close$: Subject<boolean> = new Subject()
  private stop$: Subject<boolean> = new Subject()

  ngOnInit(): void {
    this.open$
      .pipe(
        filter(() => !this.showCard),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.showCard = true
      })

    this.close$
      .pipe(
        filter(() => this.showCard),
        takeUntil(this.destroyed$),
        debounceTime(500),
        withLatestFrom(this.stop$),
        map(([_, second]) => (second ? false : true))
      )
      .subscribe((data) => {
        this.showCard = data
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.actionSettings = Object.entries(JSON.parse(changes.action.currentValue.settings) || {}).reduce((acc, [key, value]) => {
      if (key !== 'selectedLicenses') {
        acc[key] = value
      }
      return acc
    }, {} as Record<string, any>)
    this.iconName = 'Settings'
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  isArray(value: any) {
    return Array.isArray(value)
  }

  openCard() {
    this.open$.next(true)
    this.stop$.next(false)
  }

  closeCard() {
    this.close$.next(true)
    this.stop$.next(true)
  }
}

import { ColDef, ColumnApi, GridApi } from '@ag-grid-community/core'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import { ExternalFilter, FilterReportColumn, SelectionAction } from '@app/core/models/ReportDefinition'
import { TranslateHelper } from '@coreview/coreview-library'
import { RootState } from '@app/store/RootState.type'
import { setShowSensitiveData } from '@app/store/userSettings/userSettings.actions'
import { selectShowSensitiveData } from '@app/store/userSettings/userSettings.selectors'
import { Store } from '@ngrx/store'
import { Subject, of } from 'rxjs'
import { buffer, debounceTime } from 'rxjs/operators'

@Component({
  selector: 'app-datagrid-actions',
  templateUrl: './datagrid-actions.component.html',
  styleUrls: ['./datagrid-actions.component.sass'],
})
export class DatagridActionsComponent implements OnInit, OnChanges {
  @Input() hideColumnsSelector = false
  @Input() lockedColumns?: string[]
  @Input() filterActions: SelectionAction[] = []
  @Input() actions: SelectionAction[] = []
  @Input() columnDefs!: (ColDef & CoreViewColumn)[]
  @Input() defaultCols!: (ColDef & CoreViewColumn)[]
  @Input() columnApi!: ColumnApi
  @Input() gridApi!: GridApi
  @Input() maxSelectableColumns!: number
  @Input() entityName?: string
  @Input() pivotModeEnabled = false
  @Input() pivotMode = false
  @Input() externalFilters: ExternalFilter[] = []
  @Input() groupedActions: Omit<SelectionAction, 'color' | 'style' | 'rightIcon' | 'classIcon' | 'leftClassStyle'>[] = []
  @Input() enableAnonymousData = false
  @Input() filters?: FilterReportColumn
  @Input() getSelectedRows!: () => any[]

  @Input() rowSelected$: Subject<{ rowData: any; isSelected: boolean }> = new Subject<{ rowData: any; isSelected: boolean }>()
  @Input() selectedColumnsChanged$: Subject<{ newColumns: (ColDef & CoreViewColumn)[]; visibilityUpdated: boolean }> = new Subject<{
    newColumns: (ColDef & CoreViewColumn)[];
    visibilityUpdated: boolean;
  }>()

  @Output() pivotModeChanged: EventEmitter<boolean> = new EventEmitter()
  @Output() anonymousDataModeChanged: EventEmitter<boolean> = new EventEmitter()
  @Output() visibleColumnChanged: EventEmitter<(ColDef<any, any> & CoreViewColumn)[]> = new EventEmitter()
  @Input() externalFunctionMapper: Record<string, (filter: ExternalFilter, key: string | null) => void> = {}

  showAnonymousData = true
  selectedRows: any[] = []

  constructor(private store: Store<RootState>, private translateService: TranslateHelper) {}

  @Input() refresh: (dataCenterUrl?: string) => any = () => of(null)

  ngOnInit() {
    if (this.enableAnonymousData) {
      this.store.select(selectShowSensitiveData).subscribe((showSensitiveData) => {
        this.showAnonymousData = !showSensitiveData
        this.setSelectableColumnsForAnonymousData()
      })
    }
    
    this.rowSelected$.pipe(buffer(this.rowSelected$.pipe(debounceTime(10)))).subscribe((rows) => {
      this.updateSelectedRows()
    })

    this.externalFilters?.forEach((x) => (x.enum = x.enum.map((e) => ({ ...e, text: this.translateService.instant(e.text || '') }))))
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.columnDefs && this.enableAnonymousData) {
      this.setSelectableColumnsForAnonymousData()
    }
  }

  onFilterActionsClick = (filter: SelectionAction, selectedRows: any[], key: string | null) => {
    if (filter.onClick) {
      filter.onClick(selectedRows, key)
    }
  }

  //#region ExternalFilters
  onExternalFilterClick = (filter: ExternalFilter, key: string | null) => {
    if (this.externalFunctionMapper[filter.onClick]) {
      this.externalFunctionMapper[filter.onClick](filter, key)
    }
  }
  //#endregion

  onAnonymousDataModeChange = (anonData: boolean) => {
    if (!anonData) {
      this.columnApi.setColumnsVisible(
        this.columnApi
          .getColumns()
          ?.filter((c) => c.isVisible() || ['UserPrincipalName', 'UserDisplayName'].includes(c.getColDef().headerName || '')) || [],
        true
      )
    } else {
      this.columnApi.setColumnsVisible(
        this.columnApi.getColumns()?.filter((c) => c.isVisible() || ['UserHash'].includes(c.getColDef().headerName ?? '')) ?? [],
        true
      )
    }
    this.setSelectableColumnsForAnonymousData()
    this.anonymousDataModeChanged.emit(anonData)
    this.store.dispatch(setShowSensitiveData({ showSensitiveData: !anonData }))
  }

  onVisibleColumnChanged(columns:(ColDef<any, any> & CoreViewColumn)[]) {
    this.visibleColumnChanged.emit(columns)
  }

  //#region PivotMode
  onPivotModeChange = (val: boolean) => {
    this.pivotModeChanged.emit(val)
    this.pivotMode = val
  }

  someGroupedActionVisible() {
    return this.groupedActions?.some((action) => !this.isActionHidden(action))
  }

  isActionHidden = (action: SelectionAction) =>
    (action.visibility === 'noRow' && this.selectedRows.length) ||
    (action.visibility === 'singleRow' && this.selectedRows.length !== 1) ||
    (action.visibility === 'multiRow' && this.selectedRows.length === 0) ||
    (action.visibility === 'custom' && action.isVisible && !action.isVisible(this.selectedRows, this.gridApi?.getDisplayedRowCount())) ||
    (action.filterRows && !action.filterRows(this.selectedRows)) ||
    (this.pivotMode && !action.isPivotAction && this.pivotModeEnabled)
  //#endregion

  private updateSelectedRows() {
    this.selectedRows = this.getSelectedRows ? this.getSelectedRows() : this.gridApi?.getSelectedRows()
  }

  private setSelectableColumnsForAnonymousData() {
    this.columnDefs?.forEach((x) => {
      if (['UserPrincipalName', 'UserDisplayName'].includes(x.originalName)) {
        x.notSelectable = !!this.showAnonymousData
      } else if (x.originalName === 'UserHash') {
        x.notSelectable = !this.showAnonymousData
      }
    })
  }
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { WorkflowHttpService } from '@app/modules/workflow/http/workflow.http'
import { Subject, merge, timer } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Workflow } from '@app/modules/workflow/models/workflow.model'

@Component({
  selector: 'app-execution-details-panel',
  templateUrl: './execution-details-panel.component.html',
  styleUrls: ['./execution-details-panel.component.sass'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ExecutionDetailsPanelComponent implements OnInit, OnDestroy {
  @Input() workflowId!: string
  @Input() executionId!: string
  @Input() isForPlaybook = false

  @Input() executionIndex = 0
  @Input() executionsCurrentPage = 0
  @Input() executionsPage: Workflow.Dto.V1.WorkflowExecutions.Item[] = []

  public loadedFirstTime = false
  public executionDetails!: Workflow.Dto.V1.WorkflowExecutionDetail.Data

  public currentIndex = 0

  private destroyed$: Subject<boolean> = new Subject()
  private stopPolling$: Subject<boolean> = new Subject()

  constructor(private workflowHttpService: WorkflowHttpService) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  ngOnInit(): void {
    timer(0, 15000)
      .pipe(takeUntil(merge(this.destroyed$, this.stopPolling$)))
      .subscribe(() => {
        this.refreshData()
      })
  }

  public refreshData(): void {
    this.workflowHttpService
      .fetchWorkflowExecutionDetails(this.workflowId, this.executionId, this.isForPlaybook)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((resp) => {
        this.executionDetails = resp
        if (!!this.executionDetails.status && !['NotStarted', 'Running'].includes(this.executionDetails.status)) {
          this.stopPolling$.next(true)
          this.stopPolling$.complete()
        }
        this.loadedFirstTime = true
      })
  }

  public loadParentExecutionData(): void {
    this.executionId = this.executionDetails?.originalExecutionId
    this.refreshData()
  }

  getEvents(
    inputs: Workflow.Dto.V1.WorkflowExecutionDetail.ExecutionHistoryEvent[]
  ): Workflow.Dto.V1.WorkflowExecutionDetail.ExecutionHistoryEvent[] {
    return inputs.filter((item) => !item.action.parentUid)
  }

  getExecutionHistoryActions(): Workflow.Dto.V1.WorkflowExecutionDetail.Action[] {
    let allActions: Workflow.Dto.V1.WorkflowExecutionDetail.Action[] = []
    if (this.executionDetails?.executionHistoryEvents) {
      allActions = this.executionDetails.executionHistoryEvents.map((event) => event.action)
    }
    if (this.executionDetails?.catchExecutionHistoryEvents) {
      allActions = allActions.concat(this.executionDetails.catchExecutionHistoryEvents.map((e) => e.action))
    }
    return allActions
  }

  goToPreviousExecution(): void {
    if (this.executionIndex > 0) {
      this.executionIndex--
      this.executionId = this.executionsPage[this.executionIndex].id
      this.refreshData()
    }
  }

  goToNextExecution(): void {
    if (this.executionIndex < this.executionsPage.length - 1) {
      this.executionIndex++
      this.executionId = this.executionsPage[this.executionIndex].id
      this.refreshData()
    }
  }
}

import { Component, Inject, Input, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-workflow-preview-dialog',
  templateUrl: './workflow-preview-dialog.component.html',
  styleUrls: ['./workflow-preview-dialog.component.sass'],
})
export class WorkflowPreviewDialogComponent implements OnInit {
  @Input()
  workflowId!: string
  isForPlaybook: boolean

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.workflowId = data.workflowId
    this.isForPlaybook = !!data?.isForPlaybook
  }

  ngOnInit(): void {}
}

import { Component } from '@angular/core'
import { ICellRendererAngularComp } from '@ag-grid-community/angular'

@Component({
  selector: 'app-flat-dictionary-cell-renderer',
  templateUrl: './flat-dictionary-cell-renderer.component.html',
  styleUrls: ['./flat-dictionary-cell-renderer.component.sass'],
})
export class FlatDictionaryCellRendererComponent implements ICellRendererAngularComp {

  value!: any

  constructor() {}

  agInit(params: any): void {
    this.value = params.value
  }

  refresh(): boolean {
    return true
  }
}

import { Component, Input, OnInit } from '@angular/core'
import { AuthenticationService } from '@app/core/services/authentication.service'
import { RootState } from '@app/store/RootState.type'
import { selectedOrganization } from '@app/store/organizations/organizations.selectors'
import { Store } from '@ngrx/store'
import { filter } from 'rxjs/operators'
import {Location} from '@angular/common';

@Component({
  selector: 'app-unauthorized-access',
  templateUrl: './unauthorized-access.component.html',
  styleUrls: ['./unauthorized-access.component.sass'],
})
export class UnauthorizedAccessComponent implements OnInit {
  @Input() errorCode = 500
  @Input() errorTitleKey = 'generic_SomethingWentWrongLogin'
  @Input() errorMessageKey = 'generic_SomethingWentWrongLoginDescription'
  @Input() showLogoutButton = false
  imageName: string = '403.svg'
  buttonText = 'generic_SignInWithDifferentAccount'
  constructor(private authenticationService: AuthenticationService, private store: Store<RootState>, private location: Location) {}

  errorsLookup = new Map<number, [string, string, string, string, boolean]>([
    [401, ['unauthorized-access.svg', 'generic_UnauthorizedAccess', 'generic_UnauthorizedAccessDescription', 'generic_SignInWithDifferentAccount', true]],
    [403, ['unauthorized-access.svg', 'generic_UnauthorizedAccess', 'generic_UnauthorizedAccessDescription', 'generic_SignInWithDifferentAccount', true]],
    [473, ['check-import.svg', 'generic_CheckImportTitle', 'generic_CheckImportDescription', 'generic_LogOut', true]],
    [479, ['expired.svg', 'generic_ExpiredOrganization_', 'generic_ExpiredOrganization_', '', false]],
    [476, ['expired.svg', 'generic_OnboardingIncompleteTitle', 'generic_OnboardingIncompleteDescription', 'generic_LogOut', true]],
  ])

  ngOnInit(): void {
    const errorValues = this.errorsLookup.get(this.errorCode)
    if (errorValues) {
      ;[this.imageName, this.errorTitleKey, this.errorMessageKey, this.buttonText, this.showLogoutButton] = errorValues
    }
    this.store
      .select(selectedOrganization)
      .pipe(filter((x) => !!x))
      .subscribe((x) => {
        let suffix = 'PRODUCTION'
        let suffixType = ''
        if (['POV', 'TRIAL'].includes(x!.subscriptionLevel)) {
          suffix = 'TRIAL'
          suffixType = x!.organizationType?.toLocaleLowerCase() === 'customer' ? 'Customer' : ''
        }
        this.errorTitleKey = `${this.errorTitleKey}${suffix}_${suffixType}_Title`
        this.errorMessageKey = `${this.errorMessageKey}${suffix}_${suffixType}_Description`
      })
      this.location.replaceState('/');
  }

  public handleLogout() {
    this.authenticationService.logoutAzure()
  }
}

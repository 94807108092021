import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, Self, SimpleChanges } from '@angular/core'
import { NgControl, UntypedFormGroup } from '@angular/forms'
import { ExtensionAttributesMapping } from '@app/core/models/Organization'
import { OrganizationService } from '@app/core/services/organization.service'
import { Helpers } from '@app/shared/utilities/helpers'
import { selectedOrganization } from '@app/store/organizations/organizations.selectors'
import { BaseControlComponent } from '@coreview/coreview-components'
import { CustomComponentReady } from '@coreview/coreview-dynamoforms'
import { JsonFormData, JsonFormDataOptions } from '@coreview/coreview-dynamoforms/interfaces/json-form-data.interface'
import { Store } from '@ngrx/store'
import dayjs, { isDayjs } from 'dayjs'
import { isEqual } from 'lodash-es'
import { BehaviorSubject, Subject } from 'rxjs'
import { delay, filter, first, switchMap, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-extension-attributes-builder',
  templateUrl: './extension-attributes-builder.component.html',
  styleUrls: ['./extension-attributes-builder.component.sass'],
})
export class ExtensionAttributesBuilderComponent extends BaseControlComponent implements OnInit, OnDestroy, OnChanges, CustomComponentReady {
  @Input()
  accountTypes!: string[]

  @Input()
  options!: JsonFormDataOptions

  @Input()
  isCloneAction?: boolean

  @Input()
  get value(): any {
    const val = this.form.getRawValue()
    return Object.fromEntries(
      Object.entries(val || {}).filter(([k, v]) => {
        if (!!v && isDayjs(v)) {
          return !v.isSame(dayjs((this.initialValue || {})[k])) || !!this.isCloneAction
        }
        return !isEqual(v || null, (this.initialValue || {})[k] || null) || !!this.isCloneAction
      })
    )
  }

  set value(model: any) {
    this.handleSetValue(model)
  }

  @Output() customComponentReady = new EventEmitter<void>()

  extensionAttributes: ExtensionAttributesMapping[] = []
  allExtensionAttributes: ExtensionAttributesMapping[] = []

  form = new UntypedFormGroup({})
  json: JsonFormData = {
    $schema: '',
    type: 'object',
    properties: {},
  }
  jsonReady = false
  formReady = new BehaviorSubject<boolean>(false)

  private destroyed$: Subject<boolean> = new Subject()
  private initialValue: any = {}
  private initialFullFormValues = {}

  constructor(@Optional() @Self() public ngControl: NgControl, private organizationService: OrganizationService, private store: Store) {
    super(ngControl)
  }

  ngOnInit(): void {
    this.json.options = this.options

    this.store
      .select(selectedOrganization)
      .pipe(
        filter((x) => !!x),
        switchMap((o) => this.organizationService.getExtensionAttributes(o?.guid || ''))
      )
      .subscribe((extensionAttributes) => {
        this.customComponentReady.emit()
        this.allExtensionAttributes = extensionAttributes
        this.createJsonSchemaProperties()
      })

    this.form.valueChanges.pipe(delay(1), takeUntil(this.destroyed$)).subscribe(() => {
      this.onChange(this.value)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.accountTypes) {
      this.jsonReady = false
      this.formReady.next(false)
      setTimeout(() => {
        this.createJsonSchemaProperties()
      })
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  private createJsonSchemaProperties() {
    this.json.properties = {}
    this.extensionAttributes = this.allExtensionAttributes.filter((x) =>
      this.accountTypes?.length ? !x.accountType || this.accountTypes.includes(x.accountType || '') : true
    )
    this.extensionAttributes.forEach((x) => {
      this.json.properties[x.name] = {
        title: x.displayName,
        type: x.type === 'Int' ? 'number' : x.type !== 'Bool' ? 'string' : 'boolean',
        format: x.type === 'DateTime' ? 'date' : x.type === 'Bool' ? 'radio' : '',
        enum: (x.type === 'Bool' ? [true, false] : undefined) as any,
        options: {
          showTitle: x.type === 'Bool',
          enumDisplayValues: x.type === 'Bool' ? ['common_True', 'common_False'] : undefined,
        },
      }
    })
    this.jsonReady = true
    this.handleSetValue(this.initialFullFormValues)
  }

  private handleSetValue(model: any) {
    this.initialFullFormValues = model
    if (this.formReady.value) {
      this.setValue(this.initialFullFormValues)
    } else {
      this.onChange(null)
      this.formReady
        .pipe(
          filter((x) => !!x),
          first(),
          delay(1)
        )
        .subscribe((x) => this.setValue(this.initialFullFormValues))
    }
  }

  private setValue(model: any) {
    const newModel: any = {}

    if (model) {
      this.extensionAttributes?.forEach((x) => {
        const target = Helpers.downcase(x.portalAttributeName)
        if (model[target]) {
          newModel[x.name] = model[target]
        }
      })
      model = newModel
    }
    this.initialValue = newModel
    this.form.patchValue(newModel)
    this.onChange(this.value)
  }
}

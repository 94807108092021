import { Component, EventEmitter, Input, Output } from '@angular/core'

import { Workflow } from '@app/modules/workflow/models/workflow.model'
import { WorkflowFlowDefinitionTypes } from '@app/modules/workflow/shared/constants/workflow'

@Component({
  selector: 'app-preview-action',
  templateUrl: './preview-action.component.html',
  styleUrls: ['./preview-action.component.sass'],
})
export class PreviewActionComponent {
  @Input() action?: Workflow.Dto.V1.Common.Action
  @Input() isLast = false
  @Input() previewType: WorkflowFlowDefinitionTypes = 'workflows'
  @Input() isCatch = false
  @Input() inSequence = false
  @Input() editable = false
  @Input() stopClick = false

  @Output() clickedAction = new EventEmitter<Workflow.Dto.V1.Common.Action>()

  public handleClick() {
    if (!this.stopClick) {
      this.clickedAction.emit(this.action)
    }
  }
}

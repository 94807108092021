import { AuthenticationService } from '@core/services/authentication.service'
import { environment } from '@environments/environment'
import { CoreViewTranslationsLoader, loadTranslator } from '@app/app.translation-loader'
import { ApiclientService } from '../services/apiclient.service'
import { CommunicationService } from '../services/communication.service'
import { HttpClient } from '@angular/common/http'
import { LocalstorageService } from '../services/localstorage.service'
import { RoutesService } from '../services/routes.service'
import { TranslateHelper } from '@coreview/coreview-library'
import { Helpers } from '@app/shared/utilities/helpers'
import { Constants } from '@app/shared/utilities/constants'
import { filter, first, map, mergeMap, switchMap } from 'rxjs/operators'
import { forkJoin, of } from 'rxjs'
import { FroalaConfigService } from '@coreview/coreview-dynamoforms'
import { froalaToolbarButtons } from '@app/modules/workflow/features/workflow-builder/models/worflow-froala-buttons'
import { Store } from '@ngrx/store'
import { RootState } from '@app/store/RootState.type'
import { UserSettingsService } from '../services/user-settings.service'
import { getUserSettings } from '@app/store/userSettings/userSettings.actions'
import { selectUserSettings } from '@app/store/userSettings/userSettings.selectors'
import { UserSettings } from '@coreview/coreview-library/models/user-settings'

export function appInitializer(
  commService: CommunicationService,
  translateHelper: TranslateHelper,
  http: HttpClient,
  storage: LocalstorageService,
  authService: AuthenticationService,
  apiclient: ApiclientService,
  routesService: RoutesService,
  froalaConfigService: FroalaConfigService,
  store: Store<RootState>,
  userSettingsService: UserSettingsService
) {
  return () => {
    translateHelper.currentLoader = new CoreViewTranslationsLoader(http, location.pathname === '/consent')
    if (
      (!!storage.authToken && !authService.tokenIsExpired(storage.authToken) && authService.checkHasAnyRole()) ||
      location.pathname === '/consent'
    ) {
      setStorageValues(apiclient, storage)
      initFroalaSettings(apiclient, storage, froalaConfigService)

      if (!authService.isGuestUser()) {
        if (!storage.selectedOrganization) {
          storage.selectedOrganization = { id: storage.getLoggedUser().organizationId, text: storage.getLoggedUser().organizationName }
        }
        if (location.pathname !== '/consent') {
          commService.init()
        }
        routesService.dispatchMenuAndDashboards()
      } else {
        authService.setLoginStatus(473, 'User is Guest')
        reloadTranslations(getIsoLang())
        return of({})
      }
    } else {
      loginRequired(authService)
    }

    return authService.loginCompleted$.pipe(
      mergeMap(() => {
        if (authService.loginStatus > 0) {
          storage.removeAuthToken()
          reloadTranslations(getIsoLang())
          return of(authService.loginStatus)
        }

        const { roles } = authService.parseToken()
        if (!roles?.includes('Guest')) {
          store.dispatch(getUserSettings())
          if (authService.loginStatus === 0) {
            return forkJoin([
              initTranslations(store, storage, translateHelper),
              initTheme(store, userSettingsService),
              routesService.loadReportRoutes(),
              routesService.loadDashboardRoutes(),
            ])
          } else {
            return initTranslations(store, storage, translateHelper)
          }
        }
        reloadTranslations(getIsoLang())
        return of({})
      }),
      first()
    )
  }

  function setStorageValues(apiclient: ApiclientService, storage: LocalstorageService) {
    apiclient.basePortalApiUrl = storage.basePortalApiUrl ? storage.basePortalApiUrl : environment.baseCentralUrl + 'api/'

    const selectedTenantId = Helpers.getSelectedTenantFromUrl()
    if (selectedTenantId) {
      storage.selectedOrganization = { id: selectedTenantId, text: selectedTenantId }
    }
  }

  function loginRequired(authService: AuthenticationService) {
    authService.loginCompleted$.pipe(map((_) => _)).subscribe()
    return authService
      .login()
      .then(() => {})
      .catch((_) => {
        return of(null)
      })
  }

  function initFroalaSettings(apiClient: ApiclientService, storage: LocalstorageService, froalaConfigService: FroalaConfigService) {
    const selectedOrganization = storage.selectedOrganization
    let xScompany = {}
    if (selectedOrganization?.id) {
      const encodedText = btoa(encodeURIComponent(selectedOrganization.text || ''))
      xScompany = {
        'x-scompany': `${selectedOrganization.id}|${encodedText}--`,
      }
    }
    froalaConfigService.froalaOptions = {
      key: 'qc1H2pF1B2A1B5D6D5F4hBi1a2d2Za1IXBh1f1THTBPLIIWBORpF1F1E1I4F1C11A8C2B5C3==',
      zIndex: 999999,
      toolbarButtons: froalaToolbarButtons,
      fontFamily: {
        'Arial,Helvetica,sans-serif': 'Arial',
        'Georgia,serif': 'Georgia',
        'Impact,Charcoal,sans-serif': 'Impact',
        'Tahoma,Geneva,sans-serif': 'Tahoma',
        "'Times New Roman',Times,serif": 'Times New Roman',
        'Verdana,Geneva,sans-serif': 'Verdana',
        "'Open Sans',sans-serif": 'Open Sans',
      },
      fontSizeUnit: 'pt',
      charCounterCount: false,
      imageUploadURL: apiClient.baseCentralUrl + 'api/images?format=json',
      imageUploadMethod: 'POST',
      imageManagerLoadURL: apiClient.baseCentralUrl + 'api/images?format=json',
      imageManagerLoadMethod: 'GET',
      imageManagerDeleteURL: apiClient.baseCentralUrl + 'api/images?format=json',
      imageManagerDeleteMethod: 'DELETE',
      imageMaxSize: 10 * 1024 * 1024,
      imageOutputSize: true,
      requestWithCORS: false,
      requestHeaders: {
        Authorization: 'Bearer ' + storage.authToken,
        ...xScompany,
      },
    }
  }

  function initTheme(store: Store<RootState>, userSettingsService: UserSettingsService) {
    return store.select(selectUserSettings).pipe(
      filter((x) => !!x),
      mergeMap((us: UserSettings | undefined) => {
        const darkMode = userSettingsService.getExtraValue('preferredTheme', us) === 'dark'
        document.querySelector('body')?.setAttribute('cv-data-theme', darkMode ? 'dark' : 'light')
        return of({})
      }),
      first()
    )
  }

  function initTranslations(store: Store<RootState>, storage: LocalstorageService, translateHelper: TranslateHelper) {
    return store.select(selectUserSettings).pipe(
      filter((x) => !!x),
      switchMap((us) => {
        const shouldreloadLanguage = storage.preferredLanguage !== Constants.languages.find((l) => l.fullLabel === us?.language)?.iso
        if (shouldreloadLanguage) {
          storage.preferredLanguage = Constants.languages.find((l) => l.fullLabel === us?.language)?.iso ?? getIsoLang()
          return loadTranslator()
        }
        return of({})
      }),
      switchMap(() => {
        reloadTranslations(storage.preferredLanguage)
        return of({})
      }),
      first()
    )
  }

  function getIsoLang() {
    const browserLanguage = navigator.language || 'en-US'
    const isoLang = Constants.languages.find((l) => l.locale === browserLanguage)?.iso || Constants.languages[0].iso
    return isoLang
  }

  function reloadTranslations(preferredLanguage: string) {
    translateHelper.reloadLang(preferredLanguage)
    translateHelper.use(preferredLanguage)
  }
}

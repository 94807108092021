import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { WorkflowHttpService } from '@app/modules/workflow/http/workflow.http'
import { Workflow } from '@app/modules/workflow/models/workflow.model'
import { HelperExecutionService } from '@app/modules/workflow/services/helper-execution.service'
import { SecurityService } from '@app/modules/workflow/services/security.service'
import { TranslateHelper } from '@coreview/coreview-library'
import { DialogComponent, ToastService } from '@coreview/coreview-components'
import { ExecutionDetailsPanelComponent } from '../../workflow-execution-details/components/execution-details-panel/execution-details-panel.component'
import { filter, switchMap } from 'rxjs/operators'
import { ClientDatagridComponent } from '@app/shared/components/client-datagrid/client-datagrid.component'
import { isValidRestartWorkflowExecution, isValidStopWorkflowExecution } from '@app/modules/workflow/models/workflow.definition'
import { Constants } from '@app/shared/utilities/constants'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'

type ExecutionStateCustomFilter = { key: string; text: string; icon?: string }

@Injectable({
  providedIn: 'root',
})
export class WorkflowExecutionListService {
  constructor(
    private dialog: MatDialog,
    private toastService: ToastService,
    private translateHelper: TranslateHelper,
    private workflowHttp: WorkflowHttpService,
    private helperExecutionService: HelperExecutionService,
    private rightPanelService: RightPanelService,
    private securityService: SecurityService
  ) {}

  public stateOptions: ExecutionStateCustomFilter[] = [
    {
      key: 'All',
      text: this.translateHelper.instant('workflow_ExecutionFilterAll'),
    },
    {
      key: 'InQueue',
      text: this.translateHelper.instant(Constants.Workflows.statusTextMap.InQueue),
    },
    {
      key: 'Running',
      text: this.translateHelper.instant(Constants.Workflows.statusTextMap.Running),
    },
    {
      key: 'PendingForApproval',
      text: this.translateHelper.instant(Constants.Workflows.statusTextMap.PendingForApproval),
    },
    {
      key: 'Succeeded',
      text: this.translateHelper.instant(Constants.Workflows.statusTextMap.Succeeded),
    },
    {
      key: 'Failed',
      text: this.translateHelper.instant(Constants.Workflows.statusTextMap.Failed),
    },
    {
      key: 'ApprovalRejected',
      text: this.translateHelper.instant(Constants.Workflows.statusTextMap.ApprovalRejected),
    },
    {
      key: 'Aborted',
      text: this.translateHelper.instant(Constants.Workflows.statusTextMap.Aborted),
    },
    {
      key: 'TimedOut',
      text: this.translateHelper.instant(Constants.Workflows.statusTextMap.TimedOut),
    },
  ]

  public getActionMenu(data: any): any[] {
    const menu = []
    if (this.securityService.canView()) {
      menu.push({ key: 'executionDetails', text: this.translateHelper.instant('workflow_ExecutionDetails'), icon: 'article' })
    }

    if (data.status != 'InQueue' && this.securityService.canExportWorkflow()) {
      menu.push({ key: 'export', text: this.translateHelper.instant('common_Export'), icon: 'file_download' })
    }
    if (data?.status) {
      const status = data?.status.toLowerCase()
      if (this.securityService.canExecuteRetryStopWorkflow()) {
        if (isValidStopWorkflowExecution(status)) {
          menu.push({ key: 'stop', text: this.translateHelper.instant('workflow_StopExecutions'), icon: 'close' })
        }
        if (isValidRestartWorkflowExecution(status)) {
          menu.push({ key: 'restart', text: this.translateHelper.instant('workflow_RestartExecutions'), icon: 'restart_alt' })
        }
      }
    }
    return menu
  }

  public notifyWorkflowExecutionAction(resp: Workflow.Dto.V1.ExecutionRestartCommand.Response): void {
    const { data: bulkData } = resp
    const hasError = bulkData.filter((execution: any) => execution.status === 'NotStarted').length > 0

    if (hasError === true) {
      this.toastService.open({
        id: 'executionNotStartedError',
        variant: 'error',
        title: this.translateHelper.instant('common_Error'),
        message: this.translateHelper.instant('workflow_FailedExecutionsRestartWarning'),
      })
    }

    if (hasError === false) {
      this.toastService.open({
        id: 'success',
        title: this.translateHelper.instant('common_Success'),
        variant: 'success',
        message: this.translateHelper.instant('workflow_OperationSuccess'),
      })
    }
  }

  public handleExportJson(workflowId: string, execution: Workflow.Dto.V1.WorkflowExecutions.Item) {
    this.workflowHttp.fetchWorkflowExecution(workflowId, execution.id).subscribe((resp) => {
      this.helperExecutionService.saveJsonContent(execution.id, resp)
    })
  }

  public handleExecutionDetails(
    execution: Workflow.Dto.V1.WorkflowExecutions.Item,
    executionsCurrentPage: number,
    executionsPage: Workflow.Dto.V1.WorkflowExecutions.Item[]
  ) {
    const executionIndex = executionsPage.findIndex((item) => item.id === execution.id)

    return this.rightPanelService.open({
      type: ExecutionDetailsPanelComponent,
      data: {
        workflowId: execution.workflowId,
        executionId: execution.id,
        executionIndex,
        executionsCurrentPage,
        executionsPage,
        width: '700px',
      },
      closeOnOutsideClick: true,
    })
  }

  public handleBulkStopWorkflowExecutionCommand(
    bulkSelectedItems: Workflow.Dto.V1.WorkflowExecutions.Item[],
    grid: DatagridComponent | ClientDatagridComponent
  ) {
    const validItems = this.helperExecutionService.filterValidItemsStopWorkflowExecutionBulk(bulkSelectedItems)

    if (validItems.length > 0) {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '550px',
        data: {
          title: this.translateHelper.instant('workflow_StopWorkflowExecution'),
          text: this.translateHelper.instant(`workflow_ProceedConfirmation`),
          primaryText: this.translateHelper.instant('common_Continue'),
          secondaryText: this.translateHelper.instant('common_Cancel'),
          type: 'info',
          centered: false,
        },
      })
      dialogRef
        .afterClosed()
        .pipe(
          filter((result) => result === true),
          switchMap(() => this.workflowHttp.bulkStopWorkflowExecution(validItems))
        )
        .subscribe(() => {
          grid.gridApi.deselectAll()
          grid.refresh()
        })
    }
  }

  public handleAllStopWorkflowInQueueExecutionCommand(workflowId: string, grid: DatagridComponent | ClientDatagridComponent) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '550px',
      data: {
        title: this.translateHelper.instant('workflow_StopAllInQueueExecutionsTitle'),
        text: this.translateHelper.instant(`workflow_StopAllInQueueExecutionsBody`),
        primaryText: this.translateHelper.instant('common_Continue'),
        secondaryText: this.translateHelper.instant('common_Cancel'),
        type: 'info',
        centered: false,
      },
    })
    dialogRef
      .afterClosed()
      .pipe(
        filter((result) => result === true),
        switchMap(() => this.workflowHttp.abortAllInQueueExecutions(workflowId))
      )
      .subscribe(() => {
        grid.gridApi.deselectAll()
        grid.refresh()
      })
  }

  public handleBulkRestartWorkflowExecutionCommand(
    bulkSelectedItems: Workflow.Dto.V1.WorkflowExecutions.Item[],
    grid: DatagridComponent | ClientDatagridComponent
  ) {
    const validItems = this.helperExecutionService.filterValidItemsRestartWorkflowExecutionBulk(bulkSelectedItems)

    if (validItems.length > 0) {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '550px',
        data: {
          title: this.translateHelper.instant('workflow_RestartWorkflowExecution'),
          text: this.translateHelper.instant(`workflow_ProceedConfirmation`),
          primaryText: this.translateHelper.instant('common_Continue'),
          secondaryText: this.translateHelper.instant('common_Cancel'),
          type: 'info',
          centered: false,
        },
      })

      dialogRef
        .afterClosed()
        .pipe(
          filter((result: boolean) => result === true),
          switchMap(() => this.workflowHttp.bulkRestartWorkflowExecution(validItems))
        )
        .subscribe((resp: Workflow.Dto.V1.ExecutionRestartCommand.Response) => {
          this.notifyWorkflowExecutionAction(resp)
          grid.gridApi.deselectAll()
          grid.refresh()
        })
    }
  }
}

import { Organization } from './../../../../core/models/Organization'
import { combineLatest } from 'rxjs'
import { DashboardService } from '@app/core/services/dashboard.service'
import { Component, Input, OnInit } from '@angular/core'
import { TreeNode } from '@app/shared/components/tree-view/models/Tree'
import { groupBy, map } from 'lodash-es'
import dayjs from 'dayjs'
import { RightPanelRef } from '@app/core/services/right-panel.service'

interface Node {
  id: string;
  text: string;
  children: Node[];
}

@Component({
  selector: 'app-services-usage-filter',
  templateUrl: './services-usage-filter.component.html',
  styleUrls: ['./services-usage-filter.component.sass'],
})
export class ServicesUsageFilterComponent implements OnInit {
  @Input() organization!: Organization
  @Input() filter: { filterActivities?: Record<string, string[]>; dates?: string[] } = {}

  products!: Node[]
  dates!: Node[]

  selectedProducts: string[] = []
  selectedDates: string[] = []

  constructor(private dashboardService: DashboardService, private rightPanelRef: RightPanelRef) {}

  ngOnInit(): void {
    if (this.filter?.filterActivities) {
      this.selectedProducts = ([] as string[]).concat(
        ...Object.keys(this.filter.filterActivities).map((k) => (this.filter.filterActivities as any)[k].map((v: string) => k + ':' + v))
      )
    }

    this.selectedDates = this.filter?.dates || []

    combineLatest([
      this.dashboardService.getServicesUsageProductFilters(this.organization?.guid || ''),
      this.dashboardService.getServicesUsageDates(this.organization?.guid || ''),
    ]).subscribe(([products, dates]) => {
      this.products = Object.keys(products).map((f) => ({
        id: f,
        text: f,
        children: Object.keys(products[f]).map((s) => ({
          id: f + ':' + s,
          text: s,
          children: (products[f][s] || []).map((t: string) => ({
            id: f + ':' + s + '/' + t,
            text: t,
          })),
        })),
      }))

      dates.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1))
      this.dates = map(
        groupBy(dates, (d) => d.split('-')[0]),
        (v, k) => ({
          id: k,
          text: k,
          children: v.map((d) => ({
            id: d,
            text: dayjs.utc(d).format('MMMM'),
            children: [],
          })),
        })
      )
    })
  }

  handleProductsChange(nodes: TreeNode | TreeNode[] | null) {
    const f: any = {}
    ;(nodes as TreeNode[])
      .filter((x) => !x.expandable)
      .forEach((x) => {
        f[x.ref.id.split(':')[0]] = [...(f[x.ref.id.split(':')[0]] || []), x.ref.id.split(':')[1]]
      })
    this.filter.filterActivities = f
  }

  handleDatesChange(nodes: TreeNode | TreeNode[] | null) {
    this.filter.dates = (nodes as TreeNode[]).filter((x) => !x.expandable).map((x) => x.ref.id)
  }

  clear() {
    this.selectedDates = []
    this.selectedProducts = []
    this.filter = {}
  }

  apply() {
    this.rightPanelRef.close(this.filter)
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core'
import { Policy, PolicyScheduleReport, ScheduleType, ThresholdSeverity } from '@app/core/models/playbook'
import { BadgeVariant, Suggestion, SwitchComponent } from '@coreview/coreview-components'
import { generateCronExpressionFromRecurrenceObject } from '@app/core/utils/manage-payload'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { TranslateHelper } from '@coreview/coreview-library'
import { CronExpressionHelper } from '@app/shared/utilities/cron-expression-helper'
import { ExtendedPolicy } from '../components/policy/policy.component'
import { filter } from 'rxjs/operators'
import { timer } from 'rxjs'
import { PlaybookService } from './playbook.service'
import { EditPolicyComponent } from '../components/edit-policy/edit-policy.component'
import { NewCustomPolicyComponent } from '../components/new-custom-policy/new-custom-policy.component'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { NavItem } from '@app/core/models/nav-item'

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  constructor(
    private translateHelper: TranslateHelper,
    private cronHelper: CronExpressionHelper,
    private playbookService: PlaybookService,
    private rightPanelService: RightPanelService
  ) {
    dayjs.extend(utc)
  }

  ordinalMap: Record<string, string> = {
    1: 'First',
    2: 'Second',
    3: 'Third',
    4: 'Fourth',
    5: 'Last',
  }

  ordinalMapReversed: Record<string, number> = {
    First: 1,
    Second: 2,
    Third: 3,
    Fourth: 4,
    Last: 5,
  }

  dayOfWeeksMap: Record<string, string> = {
    MON: '0',
    TUE: '1',
    WED: '2',
    THU: '3',
    FRI: '4',
    SAT: '5',
    SUN: '6',
  }

  sendReportSuggestions: Record<string, string> = {
    IsEmpty: this.translateHelper.instant('reports_IsEmpty'),
    IsNotEmpty: this.translateHelper.instant('reports_IsNotEmpty'),
    Always: this.translateHelper.instant('reports_Always'),
  }

  createCronExpressionFromScheduleReport(policy: Policy) {
    const recurrence = this.createRecurrenceFromScheduleReport(policy)
    return generateCronExpressionFromRecurrenceObject(recurrence)
  }

  createRecurrenceFromCronExpression(cronExpression: string, startDatetime: Date, endDatetime: Date | null) {
    const segments = cronExpression.split(' ') || []
    if (segments.length === 6 || segments.length === 7) {
      const [_seconds, _minutes, hours, dayOfMonth, month, dayOfWeek, _year] = segments

      const recurrence = {
        recurrence: 'recurring',
        particularScheduleWeeks: true,
        frequency: '1',
        interval: '',
        weekdaySelected: '',
        ordinal: '',
        dayMonth: 0,
        weekdayMonthSelected: '',
        startDateTime: startDatetime,
        endDateTime: endDatetime ?? null,
        monthControl: '',
        starting: 'specificTime',
      }
      this.setRecurrenceValues(recurrence, hours, dayOfMonth, month, dayOfWeek)

      return recurrence
    }
    return undefined
  }

  createRecurrenceFromScheduleReport(policy: Policy) {
    return {
      recurrence: 'recurring',
      particularScheduleWeeks: true,
      frequency: policy?.scheduleReport?.frequency?.toString(),
      interval: policy?.scheduleReport?.interval,
      weekdaySelected: policy?.scheduleReport?.days?.length ? policy?.scheduleReport?.days[0]?.toString() : null,
      dayMonth: policy?.scheduleReport?.monthDays?.length ? policy?.scheduleReport.monthDays[0] : null,
      ordinal: policy?.scheduleReport?.monthlyOccurrences?.length
        ? this.ordinalMap[policy?.scheduleReport?.monthlyOccurrences[0].occurrence ?? '1']
        : null,
      weekdayMonthSelected: policy?.scheduleReport?.monthlyOccurrences?.length
        ? policy?.scheduleReport?.monthlyOccurrences[0].day?.toString()
        : null,
      startDateTime: policy?.scheduleReport?.startTime,
      endDateTime: policy?.scheduleReport?.endTime ?? null,
      monthControl: this.getMonthControl(policy),
      starting: 'specificTime',
    }
  }

  createScheduledReport(remediationAction: any, lastRequest: any): PolicyScheduleReport | undefined {
    const recurrence = remediationAction.recurrenceData

    return remediationAction.remediationType === 'ScheduleReport'
      ? {
          startTime: recurrence.startDateTime ?? dayjs().utc().toDate(),
          endTime: recurrence.endDateTime,
          format: remediationAction.format,
          sendWhen: remediationAction.sendReport,
          text: remediationAction.emailBody,
          frequency: +recurrence.frequency || 1,
          interval: recurrence.interval || 1,
          recipients: remediationAction.recipients?.map((x: Suggestion) => x.value),
          days: recurrence.weekdaySelected ? [+recurrence.weekdaySelected] : undefined,
          lastDay: recurrence.monthControl === 'lastDay',
          monthDays: recurrence.dayMonth ? [recurrence.dayMonth] : undefined,
          reportRequest: lastRequest,
          type: lastRequest?.__type,
          monthlyOccurrences:
            recurrence.monthControl === 'daysOfWeek'
              ? [{ day: recurrence.weekdayMonthSelected, occurrence: this.ordinalMapReversed[recurrence.ordinal] }]
              : undefined,
        }
      : undefined
  }

  checkThresholdForVariant(policy: Policy): BadgeVariant {
    if (!policy.isThresholdEnabled) {
      return 'blue'
    } else if (policy.isThresholdExceeded) {
      if (policy.thresholdSeverity === ThresholdSeverity.Warning) return 'yellow'

      return policy.thresholdSeverity === ThresholdSeverity.Informational ? 'blue' : 'red'
    }
    return 'green'
  }

  getNotificationRemediationMessage(policy: Policy): string {
    return this.translateHelper.instant('playbook_SendNotificationRemediation') + ' ' + policy.notificationRecipients?.join(' ')
  }

  patchPolicy(policy: Policy) {
    const { isPolicyEnabled, isWorkflowEnabled } = policy
    return this.playbookService.patchPolicy(policy.id || '', { isPolicyEnabled, isWorkflowEnabled })
  }

  openEditPolicyPanel(policy: Policy, patch: any = {}, comp: SwitchComponent | null = null, goToRemediation = false) {
    timer(1).subscribe(() => {
      if (comp) {
        comp.checked = policy.isWorkflowEnabled
      }
    })
    const panelRef = this.rightPanelService.open({
      type: policy.policyGroupType === 'OutOfTheBoxPolicy' ? EditPolicyComponent : NewCustomPolicyComponent,
      data: {
        width: '100%',
        policyId: policy.id,
        changedRemediation: !!comp || goToRemediation,
        isPolicyProvided: false,
        patchPolicy: patch,
      },
    })
    return panelRef.afterClosed().pipe(filter((x) => !!x))
  }

  checkThresholdForIcon(policy: Policy): string {
    if (!policy.isThresholdEnabled) {
      return 'manage_search'
    } else if (policy.isThresholdExceeded) {
      if (policy.thresholdSeverity === ThresholdSeverity.Informational) return 'info'
      return 'warning'
    }
    return 'check_circle'
  }

  calculateDescriptions(p: ExtendedPolicy): ExtendedPolicy {
    if (p.policyDescription) {
      p.policyDescription.policy = new Function('data', 'translateHelper', p.policyDescription.policyJavascriptFunction || '')(
        p,
        this.translateHelper
      )
      if (this.isPolicyScheduled(p)) {
        p.policyDescription.scheduling = new Function(
          'data',
          'translateHelper',
          'scheduleString',
          p.policyDescription.schedulingJavascriptFunction || ''
        )(p, this.translateHelper, this.cronHelper.getSchedulingString(p.schedule))
      }
      p.policyDescription.threshold =
        new Function('data', 'translateHelper', p.policyDescription.thresholdJavascriptFunction || '')(p, this.translateHelper) +
        (p?.thresholdType === 'Percentage' ? '%' : '')
      p.policyDescription.workflow = p.workflowName
    } else if (p.scheduleReport) {
      this.createCronExpressionFromScheduleReport(p).subscribe((cronExpres: string) => {
        p.policyDescription = {
          policy: p.description,
          workflow: p.workflowName || '',
          threshold: this.getThresholdString(p) + (p?.thresholdType === 'Percentage' ? '%' : ''),
          scheduling: this.cronHelper.getScheduleReportString(cronExpres, p.scheduleReport),
        }
      })
    } else {
      p.policyDescription = {
        policy: p.description,
        workflow: p.workflowName || '',
        threshold: this.getThresholdString(p) + (p?.thresholdType === 'Percentage' ? '%' : ''),
        scheduling: this.cronHelper.getSchedulingString(p.schedule),
      }
    }
    return p
  }

  isPolicyScheduled(policy: Policy): boolean {
    let isScheduled = policy.isPolicyEnabled && policy.scheduleType === ScheduleType.Scheduled
    if (policy.schedule?.endDatetime) {
      isScheduled = isScheduled && dayjs(policy.schedule.endDatetime) > dayjs()
    }
    if (policy.scheduleReport?.endTime) {
      isScheduled = isScheduled && dayjs(policy.scheduleReport.endTime) > dayjs()
    }
    return isScheduled
  }

  createChart(): any {
   return {
      config: {
        chart: {
          id: 'matchedItemsRemediationOvertime',
          type: 'line',
        },
        title: {
          text: '',
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        legend: {
          enabled: true,
          align: 'center',
          verticalAlign: 'bottom',
          layout: 'horizontal',
          itemStyle: {
            whiteSpace: 'nowrap',
          },
          x: 10,
          width: 500,
          itemWidth: 200,
          maxHeight: 250,
        },
      },
      resultItem: 'chart',
      useDataCategories: true,
      useDataSeries: true,
    }    
  }

  createChartData(responseData: any, timeRangeType: string): any {
    const data: any = {}
    switch (timeRangeType) {
      case 'Monthly':
        data.categories = Object.keys(responseData.matchedItemsSnapshotsValues).map((x) =>
          dayjs
            .utc(x + '01')
            .format('MM-YYYY')
            .toString()
        )
        break
      case 'Daily':
        data.categories = Object.keys(responseData.matchedItemsSnapshotsValues).map((x) => dayjs.utc(x).format('MMM D').toString())
        break
      case 'Weekly':
      default:
        data.categories = Object.keys(responseData.matchedItemsSnapshotsValues).map(
          (x) => dayjs.utc(x.split('-')[0]).format('MMM D') + ' - ' + dayjs.utc(x.split('-')[1]).subtract(1, 'day').format('MMM D')
        )
        break
    }

    data.series = [
      {
        name: this.translateHelper.instant('playbook_matchedItems'),
        data: Object.values(responseData.matchedItemsSnapshotsValues),
        color: '#B01F24',
        marker: {
          enabled: false,
        },
        lineWidth: 5,
      },
      {
        name: this.translateHelper.instant('playbook_succededRemediation'),
        data: Object.values(responseData.workflowExecutionsSnapshotsValues),
        color: '#3B7C79',
        marker: {
          enabled: false,
        },
        lineWidth: 5,
      },
    ]
    return data
  }

  getPath(policy: Policy | undefined, menuInput: NavItem | undefined, extraState: any, playbook: any): string[] {
    let path = []
    if (policy) {
      path.push({ text: this.translateHelper.instant(menuInput?.title || ''), route: menuInput?.route, queryParams: extraState })
    }
    let menu = menuInput?.parent
    while (menu) {
      path.push(this.translateHelper.instant(menu.title))
      menu = menu.parent || undefined
    }
    if (playbook) {
      const pb = playbook
      const playbookId = pb.substring(0, pb.indexOf(':'))
      const playbookName = pb.substring(pb.indexOf(':') + 1)
      path = [{ text: playbookName, route: 'governance', queryParams: { playbookId } }, ...path]
    }
    return path.reverse()
  }

  public getSendWhen(policy: Policy): string {
    const str = this.translateHelper.instant('reports_SendWhen') + ': '
    const sendWhen = policy.scheduleReport?.sendWhen ? policy.scheduleReport.sendWhen : 'Always'
    return str + this.sendReportSuggestions[sendWhen]
  }

  private getMonthControl(policy: Policy): string | null {
    if (policy?.scheduleReport?.frequency !== 4) {
      return null
    }
    if (policy?.scheduleReport.lastDay) {
      return 'lastDay'
    }
    if (policy?.scheduleReport.monthDays) {
      return 'dayMonth'
    }
    return 'daysOfWeek'
  }

  private getThresholdString(policy: ExtendedPolicy) {
    const operatorMap: Record<string, string> = {
      '<': 'common_LessThan',
      '>': 'common_GreaterThan',
      '>=': 'common_GreaterThanOrEqual',
      '<=': 'common_LessThanOrEqual',
    }

    const operatorKey = operatorMap[policy.thresholdOperator || '<=']
    const operator = this.translateHelper.instant(operatorKey).toLowerCase()

    return this.translateHelper.instant('playbook_ThresholdDescription', {
      operator: operator,
      value: policy.thresholdValue,
    })
  }

  private setRecurrenceValues(recurrence: any, hours: string, dayOfMonth: string, month: string, dayOfWeek: string) {
    if (hours.includes('/')) {
      recurrence.frequency = '1'
      recurrence.interval = hours.substring(hours.indexOf('/') + 1)
    } else if (dayOfMonth.includes('/')) {
      recurrence.frequency = '2'
      recurrence.interval = dayOfMonth.substring(dayOfMonth.indexOf('/') + 1)
    } else if (month.includes('/')) {
      this.setMonthlyRecurrenceValues(recurrence, dayOfMonth, month, dayOfWeek)
    } else {
      recurrence.frequency = '3'
      recurrence.interval = '1'
      recurrence.weekdaySelected = this.dayOfWeeksMap[dayOfWeek]
    }
  }

  private setMonthlyRecurrenceValues(
    recurrence: { frequency: string; interval: any; monthControl: string; dayMonth: number; ordinal: any; weekdayMonthSelected: any },
    dayOfMonth: string,
    month: string,
    dayOfWeek: string,
  ) {
    recurrence.frequency = '4'
    recurrence.interval = month.substring(month.indexOf('/') + 1)

    if (!['0', '?', '*'].includes(dayOfMonth)) {
      if (dayOfMonth === 'L') {
        recurrence.monthControl = 'lastDay'
      } else {
        recurrence.monthControl = 'dayMonth'
        recurrence.dayMonth = Number(dayOfMonth)
      }
    } else if (dayOfWeek.includes('#')) {
      recurrence.monthControl = 'daysOfWeek'
      recurrence.ordinal = this.ordinalMap[dayOfWeek.substring(dayOfWeek.indexOf('#') + 1)]
      recurrence.weekdayMonthSelected = dayOfWeek.substring(0, dayOfWeek.indexOf('#'))
    } else {
      console.error('wrong monthly cronExpression')
    }
  }
}

/* eslint-disable @typescript-eslint/member-ordering, @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { TreeService } from '../tree.service'
import { FlatTreeControl } from '@angular/cdk/tree'
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree'
import { SelectionModel } from '@angular/cdk/collections'
import { cloneDeep, values, isEqual, uniqBy } from 'lodash-es'
import { Tree, TreeNode } from '../models/Tree'
import { Subject, ReplaySubject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators'
import { TranslateHelper } from '@coreview/coreview-library'
import { MatCheckboxChange } from '@angular/material/checkbox'

@Component({
  selector: 'app-tree-view-multi',
  styleUrls: ['./tree-view-multi.component.sass'],
  templateUrl: 'tree-view-multi.component.html',
})
export class TreeViewMultiComponent<T extends { text: string | number; children: T[] }> implements OnInit, OnChanges {
  @Input()
  data!: T[]
  @Input()
  selected: (number | string)[] = []
  @Input()
  disabled: (number | string)[] = []
  @Input()
  readonly = false
  @Input()
  hideSelectAll = false
  @Input()
  expandItems = true
  @Input()
  searchable = false
  @Input()
  searchLabel = 'common_Search'
  @Input()
  preventTranslation = false

  @Input() selectAll$: ReplaySubject<boolean> = new ReplaySubject()

  @Output()
  selectionChanged: EventEmitter<TreeNode[]> = new EventEmitter()

  searchFilter: Subject<string> = new Subject<string>()
  searchValue = ''

  firstLvItems = new Map<TreeNode, Tree>()
  nestedItems = new Map<Tree, TreeNode>()
  treeControl: FlatTreeControl<TreeNode>
  treeFlattener: MatTreeFlattener<Tree, TreeNode>
  dataSource: MatTreeFlatDataSource<Tree, TreeNode>
  checklistSelection = new SelectionModel<TreeNode>(true)

  constructor(private translateHelper: TranslateHelper, private treeService: TreeService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren)
    this.treeControl = new FlatTreeControl<TreeNode>(this.getLevel, this.isExpandable)
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selected &&
      !isEqual(
        changes.selected.currentValue,
        this.checklistSelection.selected.map((x) => x.ref.id)
      )
    ) {
      this.deselectAll(false)
      const nodes = this.treeControl.dataNodes?.filter(
        (x) => (this.selected || []).indexOf(x.ref.id) >= 0 && this.treeControl.getDescendants(x).length === 0
      )
      if (nodes) {
        this.checklistSelection.select(...nodes)
        nodes.forEach((node) => this.expandAndSelectAscendants(node))
      }
    }
  }

  ngOnInit() {
    this.treeService.init(this.data)

    this.treeService.dataChange.subscribe((data) => {
      this.dataSource.data = data
    })

    if (this.selected?.length) {
      const nodes = this.treeControl.dataNodes.filter((x) => this.selected.indexOf(x.ref.id) >= 0)
      this.checklistSelection.select(...nodes)
      nodes.forEach((node) => this.expandAndSelectAscendants(node))
    }

    this.searchFilter.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      this.searchValue = value
      if (value) {
        this.treeService.filterByName(value, this.treeControl)
      } else {
        this.clearFilter()
      }
    })

    this.selectAll$.pipe(filter((x) => !!x)).subscribe(() => {
      // selects all nodes (even filtered ones)
      this.treeControl.dataNodes.forEach((node) => {
        if (!this.checklistSelection.isSelected(node)) {
          this.checklistSelection.toggle(node)
        }
      })
      this.emitInformation()
    })
  }

  getLevel = (node: TreeNode) => node.level

  isExpandable = (node: TreeNode) => node.expandable

  getChildren = (node: Tree): Tree[] => node.children

  hasChild = (level: number, node: TreeNode) => node.expandable

  transformer = (node: Tree, level: number) => {
    const existingNode = this.nestedItems.get(node)
    const flatNode = existingNode && existingNode.name === node.item ? existingNode : new TreeNode()

    flatNode.name = !this.preventTranslation ? this.translateHelper.instant(node.item) : node.item
    flatNode.level = level
    flatNode.expandable = !!node.children?.length
    flatNode.icon = node.icon
    flatNode.ref = cloneDeep(node)

    this.firstLvItems.set(flatNode, node)
    this.nestedItems.set(node, flatNode)
    return flatNode
  }

  emitInformation() {
    const selection = [...this.checklistSelection.selected]

    const parentsSelection: Record<string, any> = {}

    selection.forEach((x) => {
      let node: TreeNode | null = x
      while (node) {
        node = this.treeService.getParentNode(node, this.treeControl)
        if (node) {
          parentsSelection[node.ref?.id] = node
        }
      }
    })

    this.selectionChanged.emit(uniqBy([...selection, ...values(parentsSelection)], 'ref.id'))
  }

  descendantsAllSelected(node: TreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node)
    return (
      descendants.length > 0 &&
      descendants.every(
        (child) =>
          this.checklistSelection.isSelected(child) ||
          (this.treeControl.getDescendants(node).length > 0 && this.descendantsAllSelected(child))
      )
    )
  }

  descendantsPartiallySelected(node: TreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node)
    const result = descendants.some((child) => this.checklistSelection.isSelected(child))
    return result && !this.descendantsAllSelected(node)
  }

  todoItemSelectionToggle(node: TreeNode): void {
    this.checklistSelection.toggle(node)
    const descendants = this.treeControl.getDescendants(node).filter((x) => !x.hidden)

    if (this.checklistSelection.isSelected(node)) {
      this.checklistSelection.select(...descendants)
    } else {
      this.checklistSelection.deselect(...descendants)
    }

    descendants.forEach((child) => this.checklistSelection.isSelected(child))
    this.checkAllParentsSelection(node)

    this.emitInformation()
  }

  todoLeafItemSelectionToggle(node: TreeNode): void {
    this.checklistSelection.toggle(node)
    this.checkAllParentsSelection(node)

    this.emitInformation()
  }

  checkAllParentsSelection(node: TreeNode): void {
    let parent: TreeNode | null = this.treeService.getParentNode(node, this.treeControl)
    while (parent) {
      this.checkRootNodeSelection(parent)
      parent = this.treeService.getParentNode(parent, this.treeControl)
    }
  }

  checkRootNodeSelection(node: TreeNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node)
    const descendants = this.treeControl.getDescendants(node)
    const descAllSelected = descendants.length > 0 && descendants.every((child) => this.checklistSelection.isSelected(child))
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node)
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node)
    }
  }

  filterChanged(newFilter: string) {
    this.searchFilter.next(newFilter)
  }

  areAllVisibleNodesSelected() {
    return (
      !!this.treeControl.dataNodes?.some((x) => !x.hidden) &&
      this.treeControl.dataNodes?.filter((x) => !x.hidden).every((x) => this.checklistSelection.isSelected(x))
    )
  }

  areVisibleNodesPartiallySelected() {
    return (
      !!this.treeControl.dataNodes?.some((x) => !x.hidden) &&
      this.treeControl.dataNodes?.filter((x) => !x.hidden).some((x) => this.checklistSelection.isSelected(x)) &&
      !this.areAllVisibleNodesSelected()
    )
  }

  onSelectAllChanged(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectAll()
    } else {
      this.deselectAll()
    }
  }

  isNodeReadonly(node: TreeNode): boolean {
    return this.disabled.length > 0 && this.disabled.indexOf(node.ref.id) >= 0
  }

  isShowingNodes(): boolean {
    return !!this.treeControl.dataNodes?.some((x) => !x.hidden)
  }

  private expandAndSelectAscendants(node: TreeNode) {
    const parent = this.treeService.getParentNode(node, this.treeControl)
    if (parent) {
      if (this.expandItems) {
        this.treeControl.expand(parent)
      }
      if (this.descendantsAllSelected(parent)) {
        this.checklistSelection.select(parent)
      }
      this.expandAndSelectAscendants(parent)
    }
  }

  private clearFilter(): void {
    this.treeControl.dataNodes.forEach((x) => (x.hidden = false))
  }

  private selectAll(): void {
    this.treeControl.dataNodes
      ?.filter((x) => !x.hidden)
      .forEach((node) => {
        if (!this.checklistSelection.isSelected(node)) {
          this.checklistSelection.toggle(node)
        }
        //this.treeControl.expand(node)
      })
    this.emitInformation()
  }

  private deselectAll(emit: boolean = true): void {
    this.treeControl.dataNodes
      ?.filter((x) => !x.hidden)
      .forEach((node) => {
        if (this.checklistSelection.isSelected(node)) {
          this.checklistSelection.toggle(node)
        }
        //this.treeControl.collapse(node)
      })
    if (emit) {
      this.emitInformation()
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { Organization } from '@app/core/models/Organization'
import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { ServerResponse } from '@app/core/models/ServerResponse'
import { ReportsService } from '@app/core/services/reports.service'
import { selectOnlineuserColumns } from '@app/store/onlineuser-columns/onlineuser-columns.selectors'
import { selectOrganizationById } from '@app/store/organizations/organizations.selectors'
import { RootState } from '@app/store/RootState.type'
import { selectSelectedTenant } from '@app/store/tenants/tenants.selectors'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-datagrid-dialog',
  templateUrl: './datagrid-dialog.component.html',
  styleUrls: ['../base-dialog/base-dialog.component.sass', './datagrid-dialog.component.sass']
})
export class DatagridDialogComponent implements OnInit {
  title!: string
  subTitle!: string
  gridDefinition!: (ReportDefinition & { apiUrl: string } & { since?: string } & { to?: string }) | undefined
  timeRangeFilter: any
  organization!: Organization | undefined
  loaded = false

  constructor(
    private reportsService: ReportsService,
    private store: Store<RootState>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      title: string;
      subTitle: string;
      params: any;
      reportDefinition: any;
    }
  ) {
    this.title = dialogData?.title
    this.subTitle = dialogData?.subTitle
    this.gridDefinition = { ...dialogData.reportDefinition, entity: undefined, rowSelection: 'none' }
    this.timeRangeFilter = dialogData.params?.timeRangeFilter
    
    this.store
      .select(selectSelectedTenant)
      .pipe(
        filter((x) => !!x),
        switchMap((x) => this.store.select(selectOrganizationById(x?.managedTenantId || '')).pipe(filter((o) => !!o)))
      )
      .subscribe((org) => {
        this.organization = org
        this.loaded = true
      })
   }

  ngOnInit(): void {
  }

  getItems = (params: ApiDataParameters) => ({
      items: (
        this.reportsService.getData(this.gridDefinition?.url || '', this.gridDefinition?.verb || 'get', this.getReportParams(params)) ||
        of({} as ServerResponse<any>)
      ),
      cols: this.gridDefinition?.isOnlineUsersType ? this.onlineUsersColumns() : null,
    })

  onlineUsersColumns = () => this.store.select(selectOnlineuserColumns)

  private getReportParams(params: ApiDataParameters): any {
    let reportParams = { ...params }
    if (!!this.gridDefinition?.urlParameters) {
      Object.assign(reportParams, this.gridDefinition.urlParameters)
    }
    
    reportParams = { ...reportParams, showSensitiveData: !this.organization?.hideSensitiveData }
    return reportParams
  }

}

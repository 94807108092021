<ng-container>
    <button *ngIf="param?.type === 'button'" (click)="click($event)"> {{ param.label | translate }} </button>

    <a *ngIf="param?.type === 'link'" title="{{displayValue}}" (click)="click($event)">
        <span class="title-menu">{{displayValue}}</span>
    </a>
    <span *ngIf="!param" title="{{displayValue}}" (click)="click($event)">
        <span class="title-menu">{{displayValue}}</span>
    </span>
</ng-container>

import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, } from 'rxjs'
import { map } from 'rxjs/operators'

import { ApiDataParameters } from '@core/models/ApiDataParameters'
import { ServerResponse } from '@core/models/ServerResponse'
import { ApiclientService } from '@core/services/apiclient.service'

import { ScheduleDeleteResponse, ScheduleResponse } from '../models/scheduler.model'
import { Workflow, WorkflowId } from '../models/workflow.model'
import { ImportedItem } from '../models/imported-items'
import { PoliciesTitleItem } from '../models/policy-models'
import { LicensesRes } from '../models/licenses.model'
import { Verb } from '@app/core/models/PageDataCommonClasses'

@Injectable({
  providedIn: 'root',
})
export class WorkflowHttpService {
  private readonly baseUrlV2 = this.apiClient.getWorkflowPlaybookBaseApi()
  private readonly batchBaseUrl = this.apiClient.basePortalApiUrl

  constructor(
    private apiClient: ApiclientService,
    private http: HttpClient
  ) {}

  private get baseUrl() {
    return this.apiClient.getWorkflowBaseApi(false);
  }

  fetchWorkflows(data: any = {}): Observable<Workflow.Dto.V1.Workflows.Response> {
    const params = new HttpParams({
      fromObject: {
        maxresults: 100000,
        ...data,
      },
    })

    return this.http.get<Workflow.Dto.V1.Workflows.Response>(`${this.baseUrl.url}/workflows`, {
      withCredentials: this.baseUrl.withCred,
      params,
    })
  }

  fetchActiveWorkflows(data: any = {}): Observable<Workflow.Dto.V1.Workflows.Response> {
    const params = new HttpParams({
      fromObject: {
        maxresults: 100000,
        status: 'Active',
        ...data,
      },
    })

    return this.http.get<Workflow.Dto.V1.Workflows.Response>(`${this.baseUrl.url}/workflows`, {
      withCredentials: this.baseUrl.withCred,
      params,
    })
  }

  fetchFavourites() : Observable<Workflow.Dto.V2.Workflow[]> {
    return this.http
      .post<Workflow.Dto.V2.WorkflowResponse>(`${this.baseUrl.url}/v2/workflows`, {
        filters: {
          isFavorite: "true"
        },
        draft: false,
        pageSize: 10000
      },
      { withCredentials: this.baseUrl.withCred })
      .pipe(map(response => response?.workflows));
  }

  fetchWorkflowById(
    workflowId: string,
    options?: { withActions?: boolean; edit?: boolean },
    isForPlaybook: boolean = false
  ): Observable<Workflow.Dto.V1.WorkflowDetail.Response> {
    let params = new HttpParams()

    if (options?.withActions) {
      params = params.append('include', 'Actions')
    }

    if (options?.edit) {
      params = params.append('edit', true)
    }

    const urlObj = this.apiClient.getWorkflowBaseApi(false, isForPlaybook);
    return this.http.get<Workflow.Dto.V1.WorkflowDetail.Response>(`${urlObj.url}/workflows/${workflowId}`, {
      withCredentials: urlObj.withCred,
      params,
    })
  }

  fetchWorkflowTemplateById(
    workflowId: string,
    options?: { withActions?: boolean; edit?: boolean }
  ): Observable<Workflow.Dto.V1.WorkflowDetail.Response> {
    let params = new HttpParams()

    if (options?.withActions) {
      params = params.append('include', 'Actions')
    }

    if (options?.edit) {
      params = params.append('edit', true)
    }

    return this.http.get<Workflow.Dto.V1.WorkflowDetail.Response>(`${this.baseUrl.url}/templates/${workflowId}`, {
      withCredentials: this.baseUrl.withCred,
      params,
    })
  }

  saveWorkflow(body: Workflow.Dto.V1.WorkflowSaveEntity.RequestBody): Observable<Workflow.Dto.V1.WorkflowSaveEntity.Response> {
    return this.http.post<Workflow.Dto.V1.WorkflowSaveEntity.Response>(`${this.baseUrl.url}/workflows`, body, {
      withCredentials: this.baseUrl.withCred,
    })
  }

  updateWorkflow(
    workflowId: string,
    body: Workflow.Dto.V1.WorkflowSaveEntity.RequestBody
  ): Observable<Workflow.Dto.V1.WorkflowSaveEntity.Response> {
    return this.http.put<Workflow.Dto.V1.WorkflowSaveEntity.Response>(`${this.baseUrl.url}/workflows/${workflowId}`, body, {
      withCredentials: this.baseUrl.withCred,
    })
  }

  updateTemplate(
    workflowId: string,
    body: Workflow.Dto.V1.WorkflowSaveEntity.RequestBody
  ): Observable<Workflow.Dto.V1.WorkflowSaveEntity.Response> {
    return this.http.put<Workflow.Dto.V1.WorkflowSaveEntity.Response>(`${this.baseUrl.url}/templates/${workflowId}`, body, {
      withCredentials: this.baseUrl.withCred,
    })
  }


  publishWorkflow(workflowId: string): Observable<Workflow.Dto.V1.PublishWorkflow.Response> {
    return this.http.post<Workflow.Dto.V1.PublishWorkflow.Response>(
      `${this.baseUrl.url}/workflows/${workflowId}/publish`,
      {},
      {
        withCredentials: this.baseUrl.withCred,
      }
    )
  }

  favoriteWorkflow(workflowId: WorkflowId): Observable<Workflow.Dto.V1.Favorite.Response> {
    return this.http.put<Workflow.Dto.V1.Favorite.Response>(
      `${this.baseUrl.url}/workflows/${workflowId}/favorite`,
      {},
      {
        withCredentials: this.baseUrl.withCred,
      }
    )
  }

  deleteWorkflow(workflowId: string): Observable<Workflow.Dto.V1.WorkflowAction.Data> {
    return this.http.put<Workflow.Dto.V1.WorkflowAction.Data>(`${this.baseUrl.url}/workflows/${workflowId}/delete`, null, {
      withCredentials: this.baseUrl.withCred,
    })
  }

  deleteDraftWorkflow(workflowId: string): Observable<Workflow.Dto.V1.WorkflowAction.Data> {
    return this.http.delete<Workflow.Dto.V1.WorkflowAction.Data>(`${this.baseUrl.url}/workflows/${workflowId}/delete`, {
      withCredentials: this.baseUrl.withCred,
    })
  }

  fetchWorkflowExecutions(workflowId: WorkflowId, filterKey: string): Observable<Workflow.Dto.V1.WorkflowExecutions.Data> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        filter: filterKey,
      },
    })

    return this.http
      .get<Workflow.Dto.V1.WorkflowExecutions.Response>(`${this.baseUrl.url}/executions/${workflowId}`, {
        params,
        withCredentials: this.baseUrl.withCred,
      })
      .pipe(map((resp) => resp.data))
  }

  fetchWorkflowExecutionDetails(workflowId: WorkflowId, executionId: string, isForPlaybook: boolean = false): Observable<Workflow.Dto.V1.WorkflowExecutionDetail.Data> {
    const urlObj = this.apiClient.getWorkflowBaseApi(false, isForPlaybook);

    return this.http
      .get<Workflow.Dto.V1.WorkflowExecutionDetail.Response>(`${urlObj.url}/executions/${workflowId}/${executionId}`, {
        withCredentials: urlObj.withCred,
      })
      .pipe(map((resp) => resp.data))
  }

  fetchWorkflowExecution(workflowId: WorkflowId, executionId: string): Observable<Workflow.Dto.V1.WorkflowExecution.Data> {
    return this.http
      .get<Workflow.Dto.V1.WorkflowExecution.Response>(`${this.baseUrl.url}/executions/${workflowId}/${executionId}`, {
        withCredentials: this.baseUrl.withCred,
      })
      .pipe(map((resp) => resp.data))
  }

  bulkStopWorkflowExecution(executions: Workflow.Execution[]): Observable<Workflow.Dto.V1.ExecutionStopCommand.Response> {
    return this.http.post<Workflow.Dto.V1.ExecutionStopCommand.Response>(
      `${this.baseUrl.url}/executions/bulkAction/stop`,
      { items: executions },
      {
        withCredentials: this.baseUrl.withCred,
      }
    )
  }

  bulkRestartWorkflowExecution(executions: Workflow.Execution[]): Observable<Workflow.Dto.V1.ExecutionRestartCommand.Response> {
    return this.http.post<Workflow.Dto.V1.ExecutionRestartCommand.Response>(
      `${this.baseUrl.url}/executions/bulkAction/restart`,
      { items: executions },
      {
        withCredentials: this.baseUrl.withCred,
      }
    )
  }

  bulkChangeWorkflowStatus(
    workflowIds: { id: string }[],
    statusAction: 'activate' | 'deactivate' | 'delete'
  ): Observable<Workflow.Dto.V1.WorkflowAction.Response> {
    return this.http.put<Workflow.Dto.V1.WorkflowAction.Response>(`${this.baseUrl.url}/workflows/bulkAction/${statusAction}`, workflowIds, {
      withCredentials: this.baseUrl.withCred,
    })
  }

  changeWorkflowStatus(workflowId: string, statusAction: string): Observable<Workflow.Dto.V1.WorkflowAction.Data> {
    return this.http.put<Workflow.Dto.V1.WorkflowAction.Data>(`${this.baseUrl.url}/workflows/${workflowId}/${statusAction}`, null, {
      withCredentials: this.baseUrl.withCred,
    })
  }

  fetchWorkflowsScheduled(data: ApiDataParameters = {}): Observable<ScheduleResponse> {
    const params = new HttpParams({
      fromObject: {
        ...(data as any),
        maxresults: 1000,
        filter: 'scheduled',
      },
    })

    const url = `${this.baseUrl.url}/workflows`
    return this.http.get<ScheduleResponse>(url, { withCredentials: this.baseUrl.withCred, params })
  }

  createWorkflowSchedule(workflowId: string, data: any = {}): Observable<void> {
    return this.http.put<void>(`${this.baseUrl.url}/workflows/${workflowId}/scheduleconfig`, data, {
      withCredentials: this.baseUrl.withCred,
    })
  }

  deleteWorkflowSchedule(workflowId: string, jobId: string): Observable<ScheduleDeleteResponse> {
    return this.http.delete<ScheduleDeleteResponse>(`${this.baseUrl.url}/workflows/${workflowId}/scheduleconfig?scheduleid=${jobId}`, {
      withCredentials: this.baseUrl.withCred,
    })
  }

  deleteWorkflowSchedules(schedules: { scheduleId: string; workflowId: string }[]): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl.url}/workflows/scheduleconfigs`, {
      body: { items: schedules },
      withCredentials: this.baseUrl.withCred,
    })
  }

  runManualWorkflow(workflowId: string, workflowInput: any): Observable<Workflow.Dto.V1.ManualRun.Response> {
    return this.http.post<Workflow.Dto.V1.ManualRun.Response>(`${this.baseUrl.url}/executions/${workflowId}`, workflowInput, {
      withCredentials: this.baseUrl.withCred,
    })
  }

  uploadFile(data: FormData): Observable<any> {
    const options = {
      responseType: 'text' as 'text',
      reportProgress: true,
      observe: 'events' as 'events',
      withCredentials: true,
    }

    return this.http.post(`${this.batchBaseUrl}/management/uploadCsvFile/`, data, options)
  }

  getImportedItems(id: string, data: ApiDataParameters = {}): Observable<ImportedItem[]> {
    const params: Record<string, any> = {
      correlationId: id,
      pageNumber: 1,
      pageSize: 9999,
      ...data,
    }

    return this.http
      .get<ServerResponse<ImportedItem>>(`${this.batchBaseUrl}/management/getimporteditemsfromcsv/`, {
        params,
        withCredentials: true,
      })
      .pipe(
        map((dataImported: any) => dataImported?.importedItems),
        map((item) => item.map((i: ImportedItem) => ({ currentStatus: i.currentStatus, ...i.properties })))
      )
  }

  fetchWorkflowsActions(workflowId: WorkflowId): Observable<Workflow.Dto.V1.ExecutionInput.Response> {
    const url = `${this.baseUrl.url}/workflows/${workflowId}`
    return this.http.get<Workflow.Dto.V1.ExecutionInput.Response>(url, { withCredentials: this.baseUrl.withCred })
  }

  batchCsv(batchId: string = '', data: Workflow.Dto.V1.BulkCsv.Payload): any {
    const url = `${this.batchBaseUrl}/${batchId}/workflowintegration/batchcsv`
    return this.http.post(url, data, { withCredentials: true })
  }

  batchExecute(companyId: string, data: Workflow.Dto.V1.BatchExecute.Payload): Observable<Workflow.Dto.V1.Common.BatchResponse> {
    const url = `${this.batchBaseUrl}/${companyId}/workflowintegration/batch`

    return this.http.post<Workflow.Dto.V1.Common.BatchResponse>(url, data, { withCredentials: true })
  }

  batchExecuteById(companyId: string, data: Workflow.Dto.V1.BatchExecute.PayloadById): Observable<Workflow.Dto.V1.Common.BatchResponse> {
    const url = `${this.batchBaseUrl}/${companyId}/workflowintegration/batchById`
    return this.http.post<Workflow.Dto.V1.Common.BatchResponse>(url, data, { withCredentials: true })
  }

  batchExecuteByApi(companyId: string, data: Workflow.Dto.V1.BatchExecute.PayloadByApi): Observable<Workflow.Dto.V1.Common.BatchResponse> {
    const url = `${this.batchBaseUrl}/${companyId}/workflowintegration/batchByApi`
    return this.http.post<Workflow.Dto.V1.Common.BatchResponse>(url, data, { withCredentials: true })
  }

  duplicate(workflowId: WorkflowId): Observable<Workflow.Dto.V1.WorkflowDetail.DuplicateRes> {
    const url = `${this.baseUrl.url}/workflows/${workflowId}/duplicate`
    return this.http.post<Workflow.Dto.V1.WorkflowDetail.DuplicateRes>(url, {}, { withCredentials: this.baseUrl.withCred })
  }

  fetchTemplates(): Observable<Workflow.Dto.V1.Template.Response> {
    const url = `${this.baseUrl.url}/templates`

    return this.http.get<Workflow.Dto.V1.Template.Response>(url, { withCredentials: this.baseUrl.withCred })
  }

  fetchTemplateById(id: string, options?: { withActions?: boolean }): Observable<Workflow.Dto.V1.TemplateDetail.Response> {
    let params = new HttpParams()

    if (options?.withActions) {
      params = params.append('include', 'Actions')
    }

    return this.http.get<Workflow.Dto.V1.TemplateDetail.Response>(`${this.baseUrl.url}/templates/${id}`, {
      withCredentials: this.baseUrl.withCred,
      params,
    })
  }

  deleteTemplate(id: string): Observable<Workflow.Dto.V1.TemplateDelete.Response> {
    return this.http.delete<Workflow.Dto.V1.TemplateDelete.Response>(`${this.baseUrl.url}/templates/${id}`, {
      withCredentials: this.baseUrl.withCred,
    })
  }

  saveTemplate(body: Workflow.Dto.V1.TemplateSaveEntity.RequestBody): Observable<Workflow.Dto.V1.TemplateSaveEntity.Response> {
    return this.http.post<Workflow.Dto.V1.TemplateSaveEntity.Response>(`${this.baseUrl.url}/templates`, body, {
      withCredentials: this.baseUrl.withCred,
    })
  }

  checkConnection(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl.url}/batch/checkconnection`, data, { withCredentials: this.baseUrl.withCred })
  }

  getPoliciesTitleByIds(ids: string[]): Observable<PoliciesTitleItem[]> {
    const data = {
      policyIds: ids
    }
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/titles/find`
    return this.http.post<any>(url, data, { withCredentials: true })
  }

  retrieveLicenseInfo(id: string): Observable<LicensesRes> {
    const url = `${this.baseUrl.url}/datasources/templatelicenses`
    const params = new HttpParams({
      fromObject: {
        query: `licenseTemplateId=${id}`
      }
    })
    return this.http.get<LicensesRes>(url, { withCredentials: this.baseUrl.withCred, params })
  }

  getDataWtihMetadata(reportUrl: string, verb: Verb, params?: ApiDataParameters): Observable<ServerResponse<any>> {
    const url = `${this.baseUrlV2 + reportUrl}`

    if (verb === 'post') {
      return this.http.post<any>(url, { ...params, metadata: true } as any, { withCredentials: true })
    }

    return this.http.get<ServerResponse<any>>(url, {
      params: { ...params, metadata: true } as any,
      withCredentials: true,
    })
  }

  validateActionDependency(body: Workflow.Dto.V1.WorkflowSaveEntity.RequestBody) {
    return this.http.put<Workflow.Dto.V1.Workflows.Response>(`${this.baseUrl.url}/v2/workflows/validateactiondependency`, body, {
      withCredentials: this.baseUrl.withCred,
    })
  }

  updateTimeOfNewProcess(workflowId: string, value: number): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl.url}/workflows/${workflowId}/timeOfNewProcess`,
      { value },
      {
        withCredentials: this.baseUrl.withCred,
      }
    )
  }

  updateTimeOfManualProcess(workflowId: string, value: number): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl.url}/workflows/${workflowId}/timeOfManualProcess`,
      { value },
      {
        withCredentials: this.baseUrl.withCred,
      }
    )
  }

  abortAllInQueueExecutions(workflowId: string): Observable<void>{
    return this.http.delete<void>(
      `${this.baseUrl.url}/executions/workflow/${workflowId}/InQueue`,
      {
        withCredentials: this.baseUrl.withCred,
      }
    )
  }
}

import { Constants } from "@app/shared/utilities/constants";
import { Suggestion } from "@coreview/coreview-components";

export interface PolicyOwnerCandidate{
    id: number;
    userName: string;
    role: string;
}

export interface OwnedPolicy {
    id: string;
    title: string;
}

export function ToSuggestion(candidate: PolicyOwnerCandidate) : Suggestion {
    return {
        value: candidate.userName,
        displayValue: candidate.userName,
        otherDisplayValue: candidate.role.toLowerCase() === Constants.roles.TenantAdmin.toLowerCase() 
            ? "TenantAdmin"  
            : "PlaybookAdmin",
    } as Suggestion
}
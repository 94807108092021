import { Component, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core'
import { NgControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms'
import { BaseControlComponent, Suggestion } from '@coreview/coreview-components'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { Subject, merge } from 'rxjs'
import { delay, filter, takeUntil, tap } from 'rxjs/operators'
import { TimeRange } from '../auto-attendant-business-hours-row/auto-attendant-business-hours-row.component'
import { TranslateHelper } from '@coreview/coreview-library'

type TimeRanges = Record<string, TimeRange[] | null>

@Component({
  selector: 'app-auto-attendant-business-hours',
  templateUrl: './auto-attendant-business-hours.component.html',
  styleUrls: ['./auto-attendant-business-hours.component.sass'],
})
export class AutoAttendantBusinessHoursComponent extends BaseControlComponent implements OnInit, OnDestroy {
  public static readonly dayOfWeekLabelMapping: Record<string, string> = {
    sundayHours: 'common_Sunday',
    mondayHours: 'common_Monday',
    tuesdayHours: 'common_Tuesday',
    wednesdayHours: 'common_Wednesday',
    thursdayHours: 'common_Thursday',
    fridayHours: 'common_Friday',
    saturdayHours: 'common_Saturday',
  }

  public static readonly groupedDayOfWeekLabelMapping: Record<string, string> = {
    mondayToFriday: 'common_MondayToFriday',
    saturdayToSunday: 'common_SaturdayToSunday',
  }

  public static readonly dayOfWeekHours: string[] = [
    'sundayHours',
    'mondayHours',
    'tuesdayHours',
    'wednesdayHours',
    'thursdayHours',
    'fridayHours',
    'saturdayHours',
  ]

  types: Suggestion[] = [
    {
      value: 'different',
      displayValue: this.translateHelper.instant('management_DifferentHoursEveryDay'),
    },
    {
      value: 'same',
      displayValue: this.translateHelper.instant('management_SameHoursEveryDay'),
    },
  ]

  public type: string = this.types[0].value

  public full: boolean = false

  @Input()
  get value(): TimeRanges {
    const emptyValue = {
      sundayHours: null,
      mondayHours: null,
      tuesdayHours: null,
      wednesdayHours: null,
      thursdayHours: null,
      fridayHours: null,
      saturdayHours: null,
    }
    const value = this.type === 'same' ? this.getSameFormValue() : this.getFormValue()
    return { ...emptyValue, ...value }
  }

  set value(model: any) {
    if (!!model && Object.keys(model).length) {
      const daysOfWeek = Object.keys(model).filter((x) => x !== 'complementEnabled')
      if (
        daysOfWeek.length === 7 &&
        !(this.isDefaultValue(model.sundayHours) && this.isDefaultValue(model.mondayHours)) &&
        this.hasSameHours(model)
      ) {
        this.type = 'same'
        const mondayHours = this.formatDurations(model.mondayHours || [])
        const saturdayHours = this.formatDurations(model.saturdayHours || [])
        this.sameForm.get('mondayToFriday')?.patchValue(mondayHours)
        this.sameForm.get('saturdayToSunday')?.patchValue(saturdayHours)
      } else {
        this.type = 'different'
        daysOfWeek.forEach((dayOfWeek) => {
          const times = this.formatDurations(model[dayOfWeek] || [])
          this.form.get(dayOfWeek)?.patchValue(times)
        })
      }
      setTimeout(() => {
        this.onChange(this.value)
      }, 0)
    }
  }

  form = this.getDefaultForm()

  sameForm: FormGroup = this.fb.group({
    mondayToFriday: [[{ startTime: '0:00', endTime: '0:00' }]],
    saturdayToSunday: [[{ startTime: '0:00', endTime: '0:00' }]],
  })

  private destroyed$: Subject<boolean> = new Subject()

  constructor(@Optional() @Self() public ngControl: NgControl, private fb: FormBuilder, private translateHelper: TranslateHelper) {
    super(ngControl)
    dayjs.extend(duration)
  }

  ngOnInit(): void {
    merge(this.form.valueChanges, this.sameForm.valueChanges)
      .pipe(delay(1), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.onChange(this.value)
      })

    merge(
      this.form.statusChanges.pipe(filter(() => this.type !== 'same')),
      this.sameForm.statusChanges.pipe(filter(() => this.type === 'same'))
    )
      .pipe(
        tap(() => this.handleStatusChange()),
        takeUntil(this.destroyed$)
      )
      .subscribe()
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  getDayLabel(dayOfWeek: string) {
    return AutoAttendantBusinessHoursComponent.dayOfWeekLabelMapping[dayOfWeek]
  }

  getGroupedDayLabel(dayOfWeek: string) {
    return AutoAttendantBusinessHoursComponent.groupedDayOfWeekLabelMapping[dayOfWeek]
  }

  resetToDefault() {
    const days = this.type === 'same' ? ['mondayToFriday', 'saturdayToSunday'] : AutoAttendantBusinessHoursComponent.dayOfWeekHours
    const defaultValue = days.reduce((prev, dayOfWeekHour) => {
      prev[dayOfWeekHour] = [{ startTime: '0:00', endTime: '0:00' }]
      return prev
    }, {} as Record<string, any>)
    const form = this.type === 'same' ? this.sameForm : this.form
    form.patchValue(defaultValue)
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value
    }
  }

  handleChange(full: boolean) {
    if (full) {
      this.sameForm.patchValue({
        mondayToFriday: [{ startTime: '0:00', endTime: '0:00' }],
        saturdayToSunday: [{ startTime: '0:00', endTime: '0:00' }],
      })
    }
  }

  handleTypeChange() {
    if (this.type === 'same') {
      this.full = false
      this.sameForm.patchValue({
        mondayToFriday: [{ startTime: '0:00', endTime: '0:00' }],
        saturdayToSunday: [{ startTime: '0:00', endTime: '0:00' }],
      })
    } else {
      this.form.patchValue({
        sundayHours: [{ startTime: '0:00', endTime: '0:00' }],
        mondayHours: [{ startTime: '0:00', endTime: '0:00' }],
        tuesdayHours: [{ startTime: '0:00', endTime: '0:00' }],
        wednesdayHours: [{ startTime: '0:00', endTime: '0:00' }],
        thursdayHours: [{ startTime: '0:00', endTime: '0:00' }],
        fridayHours: [{ startTime: '0:00', endTime: '0:00' }],
        saturdayHours: [{ startTime: '0:00', endTime: '0:00' }]
      })
    }
    this.onChange(this.value)
  }

  private getDefaultForm(): FormGroup {
    const defaultValue = AutoAttendantBusinessHoursComponent.dayOfWeekHours.reduce((prev, dayOfWeekHour) => {
      prev[dayOfWeekHour] = [[{ startTime: null, endTime: null }]]
      return prev
    }, {} as Record<string, any>)
    return this.fb.group(defaultValue, { validators: this.validateNonEmptyForm })
  }

  private validateNonEmptyForm(formGroup: FormGroup): ValidationErrors | null {
    let hasDaySet = false
    for (let dayOfWeek of AutoAttendantBusinessHoursComponent.dayOfWeekHours) {
      for (let dayOfWeekValues of formGroup.value[dayOfWeek] || []) {
        if (dayOfWeekValues.startTime != null && dayOfWeekValues.endTime != null) {
          hasDaySet = true
          break
        }
      }
    }

    return hasDaySet ? null : { notEmpty: true }
  }

  // setErrors is called before the validation has run.
  // without the setTimeout the errors get overwritten by the validator
  private setErrors(error: Record<string, any> | null) {
    setTimeout(() => {
      this.ngControl.control?.setErrors(error)
    }, 1)
  }

  private getFormValue() {
    const formValues = this.form.getRawValue()
    const value = Object.entries(formValues).reduce((acc, i) => {
      const [k, v] = i
      const val = this.cleanRanges(v as TimeRange[])
      acc[k] = val.length > 0 ? val : null
      return acc
    }, {} as TimeRanges)
    return value
  }

  private getSameFormValue() {
    const formValues: Record<string, TimeRange[] | null> = this.sameForm.getRawValue()
    const dayMap = {
      mondayToFriday: ['mondayHours', 'tuesdayHours', 'wednesdayHours', 'thursdayHours', 'fridayHours'],
      saturdayToSunday: ['sundayHours', 'saturdayHours'],
    }
    return Object.entries(dayMap).reduce((acc, [key, days]) => {
      const timeRanges = formValues[key]
      if (timeRanges) {
        days.forEach((day) => (acc[day] = this.cleanRanges(timeRanges)))
      }
      return acc
    }, {} as TimeRanges)
  }

  private cleanRanges(ranges?: TimeRange[]): TimeRange[] {
    return (ranges || []).filter((range) => !!range?.startTime)
  }

  private handleStatusChange() {
    const form = this.type === 'same' ? this.sameForm : this.form

    if (this.ngControl?.control) {
      if (!form.valid) {
        this.setErrors({ invalid: true })
      } else {
        this.setErrors(null)
      }
    }
  }

  private areTimeRangesEqual(tr1: TimeRange[] | null, tr2: TimeRange[] | null): boolean {
    return (
      !!tr1 &&
      !!tr2 &&
      tr1.length === tr2.length &&
      tr1.every((range, index) => range.startTime === tr2[index].startTime && range.endTime === tr2[index].endTime)
    )
  }

  private hasSameHours(daysOfWeek: TimeRanges): boolean {
    const { sundayHours, mondayHours, tuesdayHours, wednesdayHours, thursdayHours, fridayHours, saturdayHours } = daysOfWeek

    if (!this.areTimeRangesEqual(sundayHours, saturdayHours)) return false

    const weekdayHours = [mondayHours, tuesdayHours, wednesdayHours, thursdayHours, fridayHours]

    return weekdayHours.every((dayHours, _, arr) => this.areTimeRangesEqual(dayHours, arr[0]))
  }

  private isDefaultValue(range?: TimeRange[] | null): boolean {
    const defaultValue = { startTime: 'PT0S', endTime: 'P1D' }
    return range?.length === 1 && range[0].startTime === defaultValue.startTime && range[0].endTime === defaultValue.endTime
  }

  private formatDurations(ranges: TimeRange[]): TimeRange[] {
    return ranges.map((range) => this.formatDuration(range))
  }

  private formatDuration(range: { startTime?: string | null; endTime?: string | null }): { startTime: string; endTime: string } {
    if (!range.startTime || !range.endTime) {
      return { startTime: '0:00', endTime: '0:00' }
    }
    const startTime = dayjs.duration(range.startTime).format('H:mm')
    const endTime = dayjs.duration(range.endTime).format('H:mm')
    return { startTime, endTime }
  }
}

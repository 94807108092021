<div class="elements-container" [ngClass]="{ 'elements-container-empty': type === 'Empty' || type === 'Empty2' }">
  <div class="title-container">
    <img class="flex-item" src="{{ getIconImg(type) }}" alt="{{ type !== 'Empty' && type !== 'Empty2' ? (type | translate) : '' }}" />
    <span class="flex-item title" translate [ngClass]="{ empty: type === 'Empty' || type === 'Empty2' }">{{
      type !== 'Empty' && type !== 'Empty2' ? type : ''
    }}</span>
    <span class="flex-item m-l-5" *ngIf="limit && items.length > limit">({{ limit }}+)</span>
    <span class="flex-item line-2 m-l-10" style="width: 100%"></span>
    <span
      class="flex-item m-l-10 see-all-results"
      style="white-space: nowrap"
      *ngIf="limit && items.length > limit"
      (click)="clickSeeAllResults(type)"
      translate
      >SeeMoreResults</span
    >
  </div>
  <div class="report-items-container">
    <div class="report-items-repeater" *ngFor="let i of items | slice: 0:limit; index as j" (click)="clickManageItem($event, i)"
      (mouseenter)="showOnHover[j] = true"
      (mouseleave)="showOnHover[j] = false">
      <div class="reports-item spacer" title="{{ i.name }}">
        <div class="reports-item-child" [ngClass]="showOnHover[j] && isElmentManageable(i) ? 'manageHover' : ''">
        <span class="circle-icon-container"
          ><em class="circle-icon circle-icon-small" [ngClass]="type === 'Empty' || type === 'Empty2' ? 'empty ms-Icon' : i.classIcon"></em
        ></span>
        <div *ngIf="type !== 'Empty' && type !== 'Empty2'" class="result-name-container">
          <div class="result-name" [innerHtml]="i.name">{{ i.name }}</div>
          <div class="result-name-sub" *ngIf="i.email">{{ i.email }}</div>
          <div class="result-name-sub" *ngIf="i.category">{{ i.category }}</div>
        </div>
        <div class="result-name-container" *ngIf="type === 'Empty' || type === 'Empty2'">
          <div class="result-name-empty"></div>
        </div>
      </div>
        <cv-button *ngIf="isElmentManageable(i) && showOnHover[j]" 
          class="manage-btn"
          buttonType="tertiary" 
          [leftIcon]="'settings'" 
          [text]="'Manage'"
          (click)="clickManageableElement(i)"></cv-button>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TreeViewComponent } from '@shared/components/tree-view/tree-view.component'
import { TreeViewSingleComponent } from '@app/shared/components/tree-view/single/tree-view-single.component'
import { MatTreeModule } from '@angular/material/tree'
import { MatIconModule } from '@angular/material/icon'
import { CoreViewComponentsModule } from '@coreview/coreview-components'
import { TreeViewMultiComponent } from '@shared/components/tree-view/multi/tree-view-multi.component'
import { TreeService } from '@shared/components/tree-view/tree.service'
import { MatFormFieldModule } from '@angular/material/form-field'
import { TranslateModule } from '@ngx-translate/core'
import { MatRadioModule } from '@angular/material/radio'
import { FormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'

@NgModule({
  declarations: [TreeViewComponent, TreeViewSingleComponent, TreeViewMultiComponent],
  imports: [
    CommonModule,
    MatTreeModule,
    MatIconModule,
    CoreViewComponentsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    TranslateModule,
    FormsModule
  ],
  providers: [TreeService],
  exports: [TreeViewComponent],
})
export class TreeViewModule {}

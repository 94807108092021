import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-login-skeleton',
  templateUrl: './loading-login-skeleton.component.html',
  styleUrls: ['./loading-login-skeleton.component.sass']
})
export class LoadingLoginSkeletonComponent {

}

import { createFeatureSelector, createSelector } from '@ngrx/store'
import { values } from 'lodash-es'

import { userSettingsAdapter } from './userSettings.reducer'
import { UserSettingsState } from './userSettings.types'


const defaultSelectors = userSettingsAdapter.getSelectors()

export const selectUserSettingsState = createFeatureSelector<UserSettingsState>('userSettings')

export const selectUserSettings = createSelector(selectUserSettingsState, (state: UserSettingsState) => values(state.entities)[0])

export const selectUserSettingsLoading = createSelector(selectUserSettingsState, (state) => state.isFetching)

export const selectUserSettingsError = createSelector(selectUserSettingsState, (state) => state.error)

export const selectUserSettingsColumnsByEntity = (entityName: string) =>
  createSelector(selectUserSettingsState, (state: UserSettingsState) =>
    entityName !== 'newCustomReport' ? values(state.entities)[0]?.columnsDef[entityName] : undefined
  )

export const selectShowSensitiveData = createSelector(selectUserSettingsState, (state) => state.showSensitiveData)

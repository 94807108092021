import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

@Component({
  selector: 'app-tooltip-renderer',
  templateUrl: './tooltip-renderer.component.html',
  styleUrls: ['./tooltip-renderer.component.sass'],
})
export class TooltipRendererComponent implements AgRendererComponent {
  public params!: any
  public value!: string

  constructor() {}

  refresh(params: ICellRendererParams): boolean {
    return true
  }

  agInit(params: any): void {
    this.value = params.value
  }
}

import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonColour, Suggestion } from '@coreview/coreview-components';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dropdown-selector',
  templateUrl: './dropdown-selector.component.html',
  styleUrls: ['./dropdown-selector.component.sass']
})

export class DropdownSelectorComponent {
  @Input() mainTitle: string;
  @Input() subTitle: string;
  @Input() upperTitle: string;
  @Input() underTitle: string;
  @Input() isDeleting: boolean;
  @Input() comboBoxTitle: string;
  @Input() comboBoxValue?: string;
  @Input() comboBoxValues: Suggestion[];
  @Input() searchValuesFunc?: (message: string) => Observable<Suggestion[]>;

  public readonly buttonColor: ButtonColour;

  constructor(public dialogRef: MatDialogRef<DropdownSelectorComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.mainTitle = data.mainTitle;
    this.subTitle = data.subTitle;
    this.underTitle = data.underTitle;
    this.comboBoxTitle = data.comboBoxTitle;
    this.comboBoxValues = data.comboBoxValues;
    this.isDeleting = data.isDeleting;
    this.searchValuesFunc = data.searchValuesFunc;
    this.upperTitle = data.upperTitle;
    
    if (!data.comboBoxValues) {
      this.comboBoxValues = []
      this.onInputChanged('')
    }

    this.buttonColor = this.isDeleting ? "alert" : "info";
  }

  onCloseDialog(): void {
    this.dialogRef.close(null);
  }

  onActionButtonClick(): void {
    this.dialogRef.close(this.comboBoxValue);
  }

  onInputChanged(newInput: string) {
    if (this.searchValuesFunc) {
      this.searchValuesFunc(newInput)
          .subscribe(newValues => {
            this.comboBoxValues.length = 0;
            if (newValues.length > 0) {
              this.comboBoxValues.push(...newValues);
            }
          })
    }
  }
}

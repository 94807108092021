import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ReportsService } from '@app/core/services/reports.service'
import { ManagementActionsService } from '@app/core/services/management-actions.service'
import dayjs from 'dayjs'
import { forkJoin, Subject } from 'rxjs'
import { basemapLayer } from 'esri-leaflet'
import { MapConfiguration } from '../../map-configuration.interface'
import { tileLayer, latLng, marker, MarkerClusterGroup, latLngBounds } from 'leaflet'
import { Helpers } from '@app/shared/utilities/helpers'
import { orderBy } from 'lodash-es'
import { BadgeVariant } from '@coreview/coreview-components'

@Component({
  selector: 'app-intune-device-actions-status',
  templateUrl: './intune-device-actions-status.component.html',
  styleUrls: ['./intune-device-actions-status.component.sass'],
})
export class IntuneDeviceActionsStatusComponent implements OnInit, OnDestroy {
  @Input()
  rowData!: any

  dataResult!: any
  canViewLocateDevice = false
  locateDeviceErrorMessage = ''
  retrievingLocation = false
  lastLocateDeviceAction: any

  customParameters: any = {}
  mapsData: MapConfiguration[] = []

  private destroyed$ = new Subject<boolean>()

  constructor(private reportsService: ReportsService, private managementActionService: ManagementActionsService) {}

  ngOnInit(): void {
    this.loadData()
  }

  loadData = () => {
    forkJoin([
      this.reportsService.getDeviceActions({ identifier: this.rowData.managedDeviceId }),
      this.managementActionService.canViewAction(1816),
    ]).subscribe(([res, canViewLocateDevice]) => {
      this.dataResult = res
      this.canViewLocateDevice = canViewLocateDevice

      if (this.dataResult.deviceActionFailures && this.dataResult.deviceActionFailures.length > 0) {
        const locateDeviceErrorMessage = this.dataResult.deviceActionFailures.find((err: any) => err.action === 'LocateDevice')
        if (locateDeviceErrorMessage && locateDeviceErrorMessage.errorType === 'ActionNotSupported') {
          this.locateDeviceErrorMessage =
            this.dataResult.operatingSystem &&
            (this.dataResult.operatingSystem.toLowerCase() === 'ios' || this.dataResult.operatingSystem.toLowerCase() === 'ipados')
              ? 'common_ActionNotSupportedIosIpadInfoMessage'
              : 'common_ActionNotSupportedGenericInfoMessage'
        }
      }

      const locateDeviceActions = this.dataResult.deviceActionResults.filter(
        (el: any) =>
          // verifies that the action type is locate device and that it was updated in the last 24 hours
          el.oDataType === '#microsoft.graph.locateDeviceActionResult' &&
          dayjs.utc(el.lastUpdatedDateTime).add(24, 'hours').isAfter(dayjs.utc())
      )

      if (locateDeviceActions && locateDeviceActions.length > 0) {
        const lastLocateDevice = orderBy(locateDeviceActions, 'lastUpdatedDateTime', 'desc')[0]
        this.retrievingLocation = lastLocateDevice.actionState === 'Pending'
        if (lastLocateDevice.deviceLocation && (lastLocateDevice.actionState === 'Active' || lastLocateDevice.actionState === 'Done')) {
          this.lastLocateDeviceAction = lastLocateDevice
          this.createMap()
        }
      }
    })
  }

  locateDevice = () => {
    this.retrievingLocation = false

    this.reportsService.locateDevice({ identifier: this.rowData.managedDeviceId }).subscribe((res: any) => {
      this.retrievingLocation = !!res.success

      if (res.errorMessage) {
        alert(res.errorMessage)
      }
    })
  }

  getBadgeVariant = (actionState: string): BadgeVariant => {
    switch (actionState) {
      case 'Failed':
      case 'Canceled':
        return 'red'
      case 'Pending':
        return 'yellow'
      case 'Active':
      case 'Done':
        return 'green'
      case 'None':
      case 'NotSupported':
        return 'grey'
      default:
        return 'grey'
    }
  }

  onMapReady(map: L.Map, mapConfiguration: MapConfiguration) {}

  markerClusterReady(markerCluster: MarkerClusterGroup, mapConfiguration: MapConfiguration) {}

  createMap = () => {
    this.mapsData = []
    const markers = [
      marker(latLng(this.lastLocateDeviceAction.deviceLocation.latitude, this.lastLocateDeviceAction.deviceLocation.longitude)),
    ]

    const southWest = latLng(this.lastLocateDeviceAction.deviceLocation.latitude, this.lastLocateDeviceAction.deviceLocation.longitude)
    const northEast = latLng(this.lastLocateDeviceAction.deviceLocation.latitude, this.lastLocateDeviceAction.deviceLocation.longitude)

    this.mapsData.push({
      markers,
      options: {
        layers: [
          basemapLayer('Streets', {
            detectRetina: true,
          }),
          basemapLayer('Streets'),
          tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 14 }),
          ...[],
        ],
        zoom: 16,
        center: latLng(20, 0),
        maxBounds: latLngBounds(southWest, northEast),
      },
      clusterOptions: {
        spiderfyOnMaxZoom: false,
        animate: true,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: false,
      },
    })
  }

  formatDateAndHours = (date: string) => Helpers.formatDate(date)

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  reload(): void {
    this.dataResult = undefined
    this.loadData()
  }
}

import { Injectable } from '@angular/core'
import { ColDef, ColumnApi, SortDirection, ColumnState, GridApi } from '@ag-grid-community/core'
import { Constants } from './constants'
import { ReportComponent, TranslateHelper } from '@coreview/coreview-library'
import { BuildColAgGrid, BuildColParameter } from './build-col-ag-grid'
import { CoreViewColumn } from '@coreview/coreview-library/models/CoreViewColumn'
import { SelectionAction, ReportDefinition } from '@coreview/coreview-library/models/ReportDefinition'
import { ClientDatagridComponent } from '../components/client-datagrid/client-datagrid.component'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { Subject, of } from 'rxjs'
import { ReportsService } from '@app/core/services/reports.service'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { ServerResponse } from '@app/core/models/ServerResponse'
import { catchError, filter, map, takeUntil } from 'rxjs/operators'
import { ToastService } from '@coreview/coreview-components'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { selectedOrganizationSkus } from '@app/store/organizations/organizations.selectors'

export interface ReportField {
  key: string
  value: string
  type?: string
  sort?: SortDirection
  cellRenderer?: string
  cellRendererParams?: Record<string, any>
}

@Injectable({
  providedIn: 'root',
})
export class ReportsComponentHelper {
  public reportComponent!: ReportComponent;
  reportDefinition: ReportDefinition | undefined

  colDefs = new Map([

    [
      'QuarantinedMessages',
      [
        { key: 'selection', value: '' },
        { key: 'received', value: 'Received', type: 'date', sort: 'desc' },
        { key: 'expires', value: 'Expires', type: 'date' },
        { key: 'subject', value: 'Subject', type: 'number' },
        { key: 'senderAddress', value: 'SenderAddress', type: 'string' },
        { key: 'type', value: 'Type', type: 'string' },
        { key: 'size', value: 'Size', type: 'number' },
        { key: 'recipientAddress', value: 'RecipientAddress', type: 'array' },
        { key: 'quarantinedUser', value: 'QuarantinedUser', type: 'array' },
        { key: 'releasedUser', value: 'ReleasedUser', type: 'array' },
      ],
    ],
  ])

  pivotSettings: ColumnState[] = []
  pivotFilterModel: any
  pivotParamsForBuildColDef: BuildColParameter = {
    allcols: [],
  }
  
  scheduleAction: Omit<SelectionAction, 'onClick'> = {
    text: 'common_Schedule',
    buttonType: 'tertiary',
    icon: 'event',
    visibility: 'custom'
  }

  exportAction: Omit<SelectionAction, 'onClick'> = {
    text: 'common_Export',
    buttonType: 'tertiary',
    icon: 'download',
    visibility: 'noRow',
    options: Constants.exportOptions
  }

  exportPivotAction: Omit<SelectionAction, 'onClick'> = {
    isMenuButton: true,
    text: 'common_Export',
    buttonType: 'tertiary',
    icon: 'download',
    visibility: 'noRow',
    options: Constants.exportOptionsNoPdf,
    isPivotAction: true,
    isVisible: () => true,
  }

  saveCustomReportAction: Omit<SelectionAction, 'onClick'> = {
    text: 'reports_SaveCustomReport',
    buttonType: 'tertiary',
    icon: 'save',
    visibility: 'custom'
  }

  saveCustomReportPivotAction: Omit<SelectionAction, 'onClick'> = {
    ...this.saveCustomReportAction,
    isPivotAction: true,
  }

  private destroyed$: Subject<boolean> = new Subject()

  constructor(
    private translateHelper: TranslateHelper,
    private buildColumnsHelper: BuildColAgGrid,
    private reportsService: ReportsService,
    private toastService: ToastService,
    private store: Store<RootState>
  ) {
    this.setActionsVisibility()
  }

  setActionsVisibility(): void {
    this.store.select(selectedOrganizationSkus)
      .pipe(
        takeUntil(this.destroyed$),
        filter((x) => !!x)
      )
      .subscribe((skus) => {
        this.saveCustomReportAction.isVisible = (selectedRows: any[]) => selectedRows.length === 0 ?? false
        this.saveCustomReportPivotAction.isVisible = (selectedRows: any[]) => selectedRows.length === 0 ?? false
        this.scheduleAction.isVisible = (selectedRows: any[]) => selectedRows.length === 0 ?? false
      })
  }

  getColDefByReportName(name: string) {
    const fields = this.colDefs.get(name) as ReportField[]

    if (!!fields) {
      return this.buildClientGridColDefsByFields(fields)
    }
    return []
  }

  getVisibleFields(columnApi: ColumnApi): string[] {
    return columnApi
      .getAllDisplayedColumns()
      .map((x) => (x.getUserProvidedColDef() as ColDef & CoreViewColumn)?.originalName)
      .filter((x) => !!x && !Constants.columnsToExcludeInExport.includes(x)
    )
  }

  generateGridColumnsDef(f: ReportField): ColDef {
    const key = f.type || 'string'
    const filterConf = this.buildColumnsHelper.generalFilters.get(key)
    return {
      field: f.key,
      filter: filterConf?.filter || 'agTextColumnFilter',
      type: f.type,
      filterParams: { ...(filterConf?.filterParams || {}), suppressAndOrCondition: true },
      floatingFilterComponent: 'genericFloatingFilter',
      floatingFilterComponentParams: { suppressFilterButton: false },
      headerName: this.translateHelper.instant(f.value),
      lockVisible: true,
      menuTabs: [],
      originalName: f.value,
      sortable: true,
      sortingOrder: ['asc', 'desc'],
      sort: f.sort,
      suppressMenu: true,
      valueFormatter: f.type ? this.buildColumnsHelper.valueFormatterMap[f.type] : null,
      cellRenderer: f.cellRenderer,
      cellRendererParams: f.cellRendererParams,
    } as ColDef
  }

  onPivotGridReady(clientGrid: ClientDatagridComponent) {
    if (this.pivotFilterModel) {
      clientGrid.gridApi.setFilterModel(this.pivotFilterModel)
    }
    if (this.pivotSettings.length > 0) {
      clientGrid.columnApi?.applyColumnState({
        state: this.pivotSettings,
        applyOrder: true,
      })
    }
  }

  getPivotData(reportDefinition: ReportDefinition, pivotParams: ApiDataParameters) {
    pivotParams.fields = pivotParams.fields?.filter((f) => f !== 'selection')
    pivotParams.pageSize = 100000000
    const verb = reportDefinition?.isOnlineUsersType ? 'get' : reportDefinition?.verb || 'post'
    if (
      (!!pivotParams.filters && verb === 'get' && reportDefinition?.verb === 'post') ||
      (typeof pivotParams.filters === 'object' && verb === 'get')
    ) {
      if (JSON.stringify(pivotParams.filters) !== JSON.stringify({})) {
        pivotParams.filters = JSON.stringify(pivotParams.filters)
      } else {
        pivotParams.filters = ''
      }
      if (pivotParams.reportTreeFilters && typeof pivotParams.reportTreeFilters === 'object' && pivotParams.reportTreeFilters !== null) {
        pivotParams.reportTreeFilters = JSON.stringify(pivotParams.reportTreeFilters)
      } else {
        pivotParams.reportTreeFilters = ''
      }
      delete pivotParams.pagingParameter
      delete pivotParams.bsonFilters
    }
    delete (pivotParams as any).savedReportParams
    delete (pivotParams as any).savedReportId

    const url = this.reportsService.getPivotUrl(reportDefinition)
    return this.getPivotItems$(url, verb, pivotParams, '')
  }

  exportPivotReport(format: string, clientGrid?: ClientDatagridComponent, fileName?: string) {
    if (!!clientGrid && !!format) {
      const exportParams = fileName ? { fileName } : undefined
      if (format === 'xlsx') {
        clientGrid.gridApi.exportDataAsExcel(exportParams)
      } else if (format === 'csv') {
        clientGrid.gridApi.exportDataAsCsv(exportParams)
      }
    }
  }

  getSelectedRowsCount(api?: GridApi): number {
    let count = 0

    if (api) {
      if (api.getModel().getType() === 'serverSide' && api.getServerSideSelectionState()) {
        count = api.getServerSideSelectionState()?.toggledNodes?.length ?? 0
      } else {
        count = api.getSelectedRows().length
      }
    }

    return count
  }

  private getPivotItems$(reportUrl: string, verb: Verb, params: any, dataCenterUrl?: string) {
    return (this.reportsService.getData(reportUrl, verb, { ...params }, dataCenterUrl) || of({} as ServerResponse<any>)).pipe(
      catchError((_err) => {
        this.toastService.open({
          id: 'error',
          variant: 'error',
          title: this.translateHelper.instant('common_Error'),
          message: this.translateHelper.instant('common_ErrorMessage'),
        })
        return of({} as ServerResponse<any>)
      })
    )
  }

  private buildClientGridColDefsByFields(fields: ReportField[]) {
    const gridColumnsDefs: ColDef[] = []
    fields.forEach((f) => {
      if (f.key === 'selection') {
        gridColumnsDefs.push({
          type: 'undefined',
          field: 'selection',
          hide: false,
          colId: 'selection',
          checkboxSelection: true,
          headerComponentParams: {
            keepSelectionBetweenPages: true,
          },
          ...Constants.defaultOperationColumnsDefinition,
        })
      } else {
        gridColumnsDefs.push(this.generateGridColumnsDef(f))
      }
    })

    return gridColumnsDefs
  }
}

import { Injectable } from '@angular/core'
import { AuthHelperService } from '@app/core/services/auth.helper.service'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { ToastService } from '@coreview/coreview-components'
import { Constants } from './utilities/constants'
import { TranslateHelper, ScrollSyncService } from '@coreview/coreview-library'

@Injectable({ providedIn: 'root' })
export class SharedHelperService {
  constructor(
    private storage: LocalstorageService,
    private authHelperService: AuthHelperService,
    private translateHelper: TranslateHelper,
    private toastService: ToastService,
    private scrollSyncService: ScrollSyncService
  ) {}

  isDemoCompany(): boolean {
    return !!this.storage.selectedOrganization?.isDemo
  }

  isDemoUser(): boolean {
    return this.isDemoCompany() && this.storage.getLoggedUser()?.userName?.indexOf('demo') >= 0
  }

  isAdmin(): boolean {
    return this.authHelperService.hasRole(Constants.roles.TenantAdmin)
  }

  isPortalAdmin(): boolean {
    return this.authHelperService.hasRole(Constants.roles.PortalAdmin)
  }

  isPlaybookAdmin(): boolean {
    return this.authHelperService.hasRole(Constants.roles.PlaybookAdmin)
  }

  isOnlyPlaybookManager(): boolean {
    return (
      this.authHelperService.hasRole(Constants.roles.PlaybookManager) &&
      !this.isPlaybookAdmin() &&
      !this.isAdmin() &&
      !this.authHelperService.hasRole(Constants.roles.PlaybookGlobalViewOnly)
    )
  }

  checkRoles(roles: string[]) {
    if (!roles || roles.length === 0) {
      return true
    }
    let hasRoles = false
    roles.forEach((role) => {
      if (this.authHelperService.hasRole(role)) {
        hasRoles = true
      }
    })
    return hasRoles
  }

  isManagement(): boolean {
    return this.authHelperService.hasRole(Constants.roles.Management) || this.isAdmin()
  }

  isGlobalLicenses(): boolean {
    return this.authHelperService.hasRole(Constants.roles.GlobalLicenses) || this.isAdmin()
  }

  isAudit(): boolean {
    return this.authHelperService.hasRole(Constants.roles.Audit)
  }

  isWorkflowEditor(): boolean {
    return this.authHelperService.hasRole(Constants.roles.WorkflowEditor)
  }

  isWorkflowPublisher(): boolean {
    return this.authHelperService.hasRole(Constants.roles.WorkflowPublisher)
  }

  checkIfCanSelectAllGridRows(totalRowsCount: number) {
    if (totalRowsCount > Constants.maximumRowsForSelectAll) {
      this.showCannotSelectAllRowsWarning()
      // returns empty observable to avoid it's subscription
      return false
    }
    return true
  }

  private showCannotSelectAllRowsWarning() {
    this.toastService.open({
      id: 'maxRows',
      title: this.translateHelper.instant('common_Warning'),
      message: this.translateHelper.genericCombineTranslations(
        'reports_MaxRowsSelectAllWarning',
        {},
        { rows: Constants.maximumRowsForSelectAll.toString() }
      ),
      variant: 'warning',
    })
  }

  manageScroll(classWhereApply: string, selectorNotApply?: string): void {
    setTimeout(() => {
      const elements = document.querySelectorAll(classWhereApply)
      elements.forEach((el) => {
        if ((el as any)._isScrollHandlerAdded) {
          return
        }

        const boundScrollHandler = this.handleScroll.bind(this, el, selectorNotApply)
        el.addEventListener('scroll', boundScrollHandler, true)
        ;(el as any)._boundScrollHandler = boundScrollHandler
        ;(el as any)._isScrollHandlerAdded = true
      })
    }, 600)
  }

  cleanupScrollListeners(classWhereApply: string): void {
    const elements = document.querySelectorAll(classWhereApply)
    elements.forEach((el) => {
      if ((el as any)._boundScrollHandler) {
        el.removeEventListener('scroll', (el as any)._boundScrollHandler, true)
        delete (el as any)._boundScrollHandler
        ;(el as any)._isScrollHandlerAdded = false
      }
    })
  }

  handleScroll = (el: Element | null, selectorNotApply?: string) => {
    let notApplyElement

    if (selectorNotApply) notApplyElement = Array.from(document.querySelectorAll(selectorNotApply))

    if (!notApplyElement?.some((el) => el.matches(':hover')) && !this.scrollSyncService.isChildScrolling) {
      el?.classList.add('scroll')
      setTimeout(function () {
        el?.classList.remove('scroll')
      }, 600)
    }
  }
}

<mat-icon
  *ngIf="!hideIcon()"
  [class.disabled]="disabled || inProgress"
  (click)="disabled ? null : reset()"
  class="button_row"
  [matMenuTriggerFor]="menu"
  (menuClosed)="disabled || inProgress ? null : menuClosed()"
  >{{ icon }}</mat-icon
>

<mat-menu #menu="matMenu" yPosition="below" class="button-menu">
  <app-base-dialog
    *ngIf="!disabled"
    [title]="title"
    [text]="text"
    [centered]="centered"
    [type]="type"
    [primaryButton]="primaryButton"
    [secondaryButton]="secondaryButton"
    (clicked)="click($event)"
  ></app-base-dialog>
</mat-menu>

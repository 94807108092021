import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { getOperatorById, getOperatorByIdInvalidate, getOperatorByIdResponse } from '@app/store/operators/operators.actions'
import { AuthenticationService } from '@app/core/services/authentication.service'

/**
 * The following injectable class defined and contains all the "tenants" state related side effects.
 */
@Injectable()
export class OperatorsEffects {

  /**
   * When the getManagedOperators action is dispatched, it takes care of performing
   * the related actions to fetch and bring the data to the store
   */
  getOperator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOperatorById),
      mergeMap(({ userName }) => this.authenticationService.getOperator()
        .pipe(
          map(getOperatorByIdResponse),
          catchError((error) => of(getOperatorByIdInvalidate({ error: { code: error.status, message: error.message }})
            ))
        ))
    )
  );

  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService
  ) {
  }
}

<ng-container *ngIf="loading">
  <div class="left-panel"></div>
  <div class="right-panel">
    <div class="content-container loading" style="padding: 10px 20px">
      <div class="panel-title-container">
        <div
          style="color: var(--opaque-light-contrast-color); background: var(--opaque-light-contrast-color); width: 120px; height: 22px"
          class="panel-title uppercase"
        ></div>
      </div>
      <div style="display: grid; grid-template-columns: repeat(3, 1fr); flex: 1">
        <div style="background: var(--opaque-light-contrast-color); margin: 10px 15px 10px 0px"></div>
        <div style="background: var(--opaque-light-contrast-color); margin: 10px 15px"></div>
        <div style="background: var(--opaque-light-contrast-color); margin: 10px 15px"></div>
      </div>
    </div>
    <div class="footer-container" style="height: 36px">
      <div
        style="
          margin: 0 8px;
          width: 100px;
          height: 36px;
          border-radius: 10px;
          background-color: var(--opaque-light-contrast-color);
          float: left;
        "
      ></div>
      <div
        style="
          margin: 0 8px;
          width: 100px;
          height: 36px;
          border-radius: 10px;
          background-color: var(--opaque-light-contrast-color);
          float: right;
        "
      ></div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!loading">
  <div class="left-panel">
    <ng-container *ngFor="let step of steps; let i = index">
      <div class="progress-block" [class.prev]="i < activeIndex" [class.active]="i === activeIndex">
        <span class="icon material-icons"></span>
        <span *ngIf="i !== steps.length - 1" class="bar-bottom"></span>
        <div class="progress-title" [class.active-step]="i === activeIndex" (click)="clickTab(i)">
          <div>{{ step.title }}</div>
          <div *ngIf="step.status === 'NotAvailable'" class="progress-sub-title" translate>common_ComingSoon</div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="right-panel">
    <form [formGroup]="form" class="content-container" [ngSwitch]="activeIndex">
      <div class="panel-title-container">
        <span class="panel-title uppercase" translate>playbook_CreateCustomPolicy</span>
      </div>
      <ng-container *ngSwitchCase="0">
        <div formGroupName="policyDetails" style="display: grid; grid-template-columns: repeat(3, 1fr); flex: 1">
          <div class="d-fl fl-d-col fl-1" style="gap: 10px; padding: 10px 15px 10px 0px">
            <div class="m-b-40" style="font-size: 16px" translate>playbook_GeneralInfo</div>
            <cv-input
              [disabled]="readMode"
              [required]="true"
              [errorMessage]="
                ((formControls.policyDetails.get('title')?.value?.length || 0) === 0
                  ? ''
                  : (formControls.policyDetails.get('title')?.value?.length || 0) < 4
                  ? 'playbook_PolicyTitleMinLength'
                  : (formControls.policyDetails.get('title')?.errors | keys)?.includes('titleExists')
                  ? 'playbook_PolicyTitleExists'
                  : ''
                ) | translate
              "
              (inputChange)="onChangeTitle()"
              style="width: 100%"
              [label]="'playbook_Name' | translate"
              formControlName="title"
            ></cv-input>
            <cv-textarea
              [disabled]="readMode"
              [required]="true"
              (inputChange)="onChangeDescription()"
              style="width: 100%"
              [label]="'playbook_Description' | translate"
              formControlName="description"
            ></cv-textarea>
            <cv-textarea
              style="width: 100%"
              [label]="'playbook_UsefulnessDescription' | translate"
              formControlName="usefulnessDescription"
            ></cv-textarea>
          </div>
          <div class="d-fl fl-d-col fl-1" style="border-left: 1px solid var(--opaque-light-contrast-color); gap: 10px; padding: 10px 15px">
            <div class="m-b-40" style="font-size: 16px" translate>playbook_Categorization</div>
            <cv-switch *ngIf="canCreatePlaybook && !readMode" [(ngModel)]="allowNewPlaybooks" [ngModelOptions]="{ standalone: true }">
              {{ 'playbook_AllowToAddNewPlaybooks' | translate }}
            </cv-switch>
            <cv-multi-input
              [disabled]="readMode"
              [suggestions]="filteredCustomPlaybooks"
              style="width: 100%; padding-bottom: 10px"
              [label]="'playbook_Playbook' | translate"
              formControlName="playbooks"
              [forceSelection]="!allowNewPlaybooks"
              [rightIcon]="!allowNewPlaybooks ? 'expand_more' : ''"
              [errorMessage]="getCreatePlaybookErrorMessage()"
              [required]="true"
              (inputChange)="filterPlaybooks($event)"
              [debounceMilliseconds]="100"
              defaultValueOnInsert="-1"
            ></cv-multi-input>
            <cv-switch *ngIf="!readMode" [(ngModel)]="allowNewCategories" [ngModelOptions]="{ standalone: true }">
              {{ 'playbook_AllowToAddNewCategory' | translate }}
            </cv-switch>
            <cv-multi-input
              [disabled]="readMode"
              [suggestions]="filteredCategories"
              style="width: 100%"
              [label]="'playbook_Category' | translate"
              formControlName="categories"
              [forceSelection]="!allowNewCategories"
              [rightIcon]="!allowNewCategories ? 'expand_more' : ''"
              (inputChange)="filterCategories($event)"
              [debounceMilliseconds]="100"
              defaultValueOnInsert="-1"
            />
            <div *ngIf="isPlaybookAdmin()" translate>playbook_CreatePlaybookAdminWarning</div>
          </div>
          <div class="d-fl fl-d-col fl-1" style="border-left: 1px solid var(--opaque-light-contrast-color); gap: 10px; padding: 10px 15px">
            <div class="m-b-40" style="font-size: 16px" translate>playbook_Settings</div>
            <cv-switch formControlName="isPolicyEnabled">
              {{ 'playbook_enablePolicy' | translate }}
            </cv-switch>
            <div style="margin-left: 48px; color: #6a6a6a; margin-top: -10px; margin-bottom: 10px" translate>
              playbook_EnablePolicyDescription
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="1">
        <cv-alert-bar [hideCloseButton]="true" *ngIf="customReportId || defaultTarget === 'Audit'" style="margin: 10px 0">
          <span translate>playbook_customReportWarning</span>
        </cv-alert-bar>
        <div
          formGroupName="policyDefinition"
          style="display: grid; grid-template-columns: repeat(3, 1fr); grid-template-rows: max-content 1fr; flex: 1"
        >
          <div class="d-fl fl-d-col fl-1" style="gap: 10px; padding: 10px 15px 10px 0px">
            <span style="font-size: 16px">
              {{ 'playbook_Target' | translate }}
              <mat-icon
                class="info-icon material-icons-outlined"
                tooltip="{{ 'playbook_TargetTooltipDesc' | translate }}"
                placement="bottom"
                >info
              </mat-icon>
            </span>
            <cv-select
              style="width: 100%"
              (selectionChanged)="targetChanged($any($event))"
              [label]="'management_Target' | translate"
              [suggestions]="targetSuggestions"
              formControlName="entity"
              [disabled]="!!defaultTarget || readMode"
              cv-data-test="targetSelect"
              [required]="true"
              #target
            ></cv-select>
            <cv-autocomplete-string-input
              *ngIf="target.value === 'CustomReports'"
              style="width: 100%"
              (selectionChanged)="customReportSelected($event)"
              [label]="'playbook_SelectCustomReport' | translate"
              [suggestions]="customReports"
              formControlName="customReportId"
              [required]="target.value === 'CustomReports'"
              (inputChange)="searchCustomReport($event || '')"
              [disabled]="readMode"
            >
            </cv-autocomplete-string-input>
            <cv-input
              *ngIf="target.value === 'Reports'"
              style="width: 100%"
              [label]="'playbook_Report' | translate"
              formControlName="reportName"
              [disabled]="true"
            >
            </cv-input>
          </div>
          <div class="d-fl fl-d-col fl-1" style="gap: 10px; padding: 10px 15px 10px 0px">
            <ng-container *ngIf="showGrid && target.value !== 'Audit'">
              <span style="font-size: 16px; margin-bottom: 10px">
                {{ 'playbook_setAcceptableThreshold' | translate }}
                <mat-icon
                  class="info-icon material-icons-outlined"
                  tooltip="{{ 'playbook_thresholdTooltipDesc' | translate }}"
                  placement="bottom"
                  >info
                </mat-icon>
              </span>
              <div style="color: var(--grey-text-color); margin-top: -10px; margin-bottom: 10px" translate>
                playbook_thresholdWarningDesc
              </div>
              <cv-select
                style="width: 100%"
                formControlName="thresholdSeverity"
                cv-data-test="thresholdSeverity"
                [label]="'playbook_thresholdSeverity' | translate"
                [suggestions]="thresholdSeverities"
                [disabled]="readMode"
                [icon]="severityIcon.icon"
                [iconColor]="severityIcon.color"
                #severity
                [required]="true"
              >
              </cv-select>
              <cv-checkbox
                formControlName="isthresholdEnabled"
                cv-data-test="isthresholdEnabled"
                [info]="'playbook_ApplyThresholdTooltipDesc' | translate"
                [disabled]="readMode || severity.value !== 'Informational'"
                style="width: 100%"
                (checkedChange)="clearValueIfNonePicked($event)"
                [required]="severity.value !== 'Informational'"
              >
                {{'playbook_ApplyViolationThreshold'}}
              </cv-checkbox>
              <div class="threshold-info">
                <cv-select
                  *ngIf="formControls.policyDefinition.get('isthresholdEnabled')?.value"
                  style="width: 100%"
                  formControlName="thresholdOperator"
                  cv-data-test="thresholdOperator"
                  [label]="'playbook_thresholdOperation' | translate"
                  [suggestions]="thresholdOperations"
                  [disabled]="readMode"
                  [required]="formControls.policyDefinition.get('isthresholdEnabled')?.value"
                />
                <cv-input
                  *ngIf="formControls.policyDefinition.get('isthresholdEnabled')?.value"
                  style="width: 100%"
                  [label]="'playbook_thresholdValue' | translate"
                  (input)="validateThreshold($event)"
                  formControlName="thresholdValue"
                  cv-data-test="thresholdValue"
                  inputType="number"
                  [min]="'0'"
                  [pattern]="getThresholdPattern()"
                  [errorMessage]="
                    (formControls.policyDefinition.get('thresholdValue')?.errors?.pattern ? 'common_IncorrectValue' : '') | translate
                  "
                  [disabled]="readMode"
                  [required]="formControls.policyDefinition.get('isthresholdEnabled')?.value"
                />
              </div>
            </ng-container>
          </div>
          <div class="d-fl fl-d-col fl-1" style="gap: 10px; padding: 10px 15px 10px 0px">
            <ng-container *ngIf="showGrid && target.value !== 'Audit'">
              <span style="font-size: 16px"
                >{{ 'playbook_AdvancedSettings' | translate }}
                <cv-button
                  buttonType="tertiary"
                  [text]="(advancedFilterOpen ? 'common_Hide' : 'common_Show') | translate"
                  (click)="advancedFilterOpen = !advancedFilterOpen"
                  [rightIcon]="advancedFilterOpen ? 'expand_less' : 'expand_more'"
                >
                </cv-button>
              </span>
              <div *ngIf="advancedFilterOpen">
                <cv-switch
                  [(ngModel)]="definePolicyKey"
                  (ngModelChange)="clearPolicyExceptionFields()"
                  [ngModelOptions]="{ standalone: true }"
                  [disabled]="readMode"
                >
                  {{ 'playbook_DefinePolicyKey' | translate }}
                </cv-switch>
                <div style="margin-left: 48px; color: var(--grey-text-color); margin-bottom: 10px" translate>
                  playbook_DefinePolicyKeyDescription
                </div>
                <cv-multi-select
                  [style.display]="definePolicyKey ? null : 'none'"
                  style="width: 100%"
                  [label]="'management_PolicyKey' | translate"
                  [suggestions]="policyKeySuggestions"
                  [required]="definePolicyKey"
                  formControlName="policyExceptionFields"
                  [disabled]="readMode"
                ></cv-multi-select>
              </div>
            </ng-container>
          </div>
          <div
            *ngIf="(showGrid && !policyKeySuggestions.length) || customReportLoading"
            class="loading"
            style="flex: 1; background: var(--opaque-light-contrast-color); height: 100%; grid-column: span 3"
          ></div>
          <div class="grid-content">
            <cv-alert-bar [hideCloseButton]="true" *ngIf="datagridAlertbarIsVisible()">
              <span translate>{{ returnDatagridAlertbarMessage() }}</span>
            </cv-alert-bar>
            <app-datagrid
              *ngIf="showGrid"
              class="grow-1"
              [class.grid-loading]="!policyKeySuggestions.length || customReportLoading"
              [getItems]="getItems()"
              [gridDefinition]="gridDefinition"
              (columnDefsDefined)="getPolicyKeySuggestionsAndBindValues($event)"
              (metadataChanged)="lastRequest = $event.queryRequest"
              [rangeFilters]="rangeFilter"
            >
              <div extraFilters>
                <app-range-filter
                  *ngIf="!!gridDefinition?.calendarType"
                  [calendarType]="gridDefinition?.calendarType"
                  [daysList]="gridDefinition?.daysList"
                  [defaultDaysFilter]="gridDefinition?.defaultDaysFilter"
                  [defaultDates]="
                    gridDefinition?.since || gridDefinition?.to
                      ? { startDate: gridDefinition?.since, endDate: gridDefinition?.to }
                      : undefined
                  "
                  (rangeFilterChanged)="rangeFilterChanged($event)"
                ></app-range-filter>
              </div>
            </app-datagrid>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="2">
        <cv-alert-bar
          [hideCloseButton]="true"
          *ngIf="formControls.policyDefinition.get('entity')?.value === 'Audit'"
          style="margin: 10px 0"
        >
          <span translate>playbook_EventBasedPolicyDisclaimer</span>
        </cv-alert-bar>
        <app-datagrid
          *ngIf="showGrid && !policyKeySuggestions.length"
          style="max-height: 0px; visibility: hidden"
          [getItems]="getItems()"
          [gridDefinition]="gridDefinition"
          (columnDefsDefined)="getPolicyKeySuggestionsAndBindValues($event)"
          (metadataChanged)="lastRequest = $event.queryRequest"
          [rangeFilters]="rangeFilter"
        ></app-datagrid>
        <div formGroupName="remediationAction" style="display: grid; grid-template-columns: repeat(3, 1fr); flex: 1; overflow-y: auto">
          <div class="d-fl fl-d-col fl-1 p-10" style="gap: 10px">
            <div class="m-b-40" style="font-size: 16px" translate>playbook_RemediationAction</div>
            <cv-switch 
              formControlName="isWorkflowEnabled" 
              (checkedChange)="updateFormValidity($event)"
              [ngClass]="!!policy && !policy.canEnableRemediation ? missingRemediationPermission: ''"
              [disabled]="(!!policy && !policy.canEnableRemediation)"
            >
              {{ 'playbook_enablePolicyWorkflow' | translate }}
            </cv-switch>
            <div style="margin-left: 48px; color: var(--grey-text-color); margin-top: -10px; margin-bottom: 10px" translate>
              playbook_enablePolicyWorkflowDescription
            </div>
            <div class="setting-selector" *ngIf="formControls.remediationAction.get('isWorkflowEnabled')?.value">
              <cv-select
                *ngIf="remediationTypeSuggestion.length > 0"
                class="grow-1"
                formControlName="remediationType"
                [label]="'playbook_remediationType' | translate"
                [suggestions]="remediationTypeSuggestion"
                [required]="true"
                (selectionChanged)="onRemediationTypeChange($event)"
                [disabled]="readMode"
              >
              </cv-select>
              <div *ngIf="remediationTypeSuggestion.length === 0">
                <cv-alert-bar variant="warning" [hideCloseButton]="true">
                  <span translate>playbook_auditSelectWorkloadAndOperation</span>
                </cv-alert-bar>
              </div>
            </div>
            <div
              *ngIf="
                formControls.remediationAction.get('isWorkflowEnabled')?.value &&
                formControls.remediationAction.get('remediationType')?.value === 'Workflow'
              "
            >
              <div style="display: flex; flex-direction: row">
                <cv-button
                  [text]="'playbook_CreateNewWorkflow' | translate"
                  [tooltip]="'playbook_CreateNewWorkflowInfo' | translate"
                  (click)="openCreateWorkflowPage()"
                  leftIcon="add"
                  rightIcon="open_in_new"
                  class="margin-top: -10px"
                  buttonType="secondary"
                ></cv-button>
                <cv-button
                  *ngIf="showRefreshWorkflowList"
                  [text]="'common_Refresh' | translate"
                  (click)="refreshWorkflowList()"
                  leftIcon="refresh"
                  class="margin-top: -10px"
                  buttonType="tertiary"
                ></cv-button>
              </div>
              <cv-alert-bar *ngIf="showRefreshWorkflowList" [hideCloseButton]="true" style="margin: 10px 0">
                <span translate>playbook_RefreshWorkflowListDisclaimer</span>
              </cv-alert-bar>

              <cv-autocomplete-input
                (selectionChanged)="workflowSelected($event)"
                (inputChange)="filterWorkflows($event)"
                [(ngModel)]="selectedWorkflow"
                (ngModelChange)="clearSelectedWorkflow($event)"
                [forceSelection]="true"
                [showToggle]="true"
                [suggestions]="workflowList"
                [emptyLabel]="workflowList.length ? '' : ('common_NoFilterAvailable' | translate)"
                [emptySuggestion]="workflowList.length ? '' : ('common_NoFiltersWarning' | translate)"
                style="display: block; width: 100%; margin: 10px 0px"
                [label]="'playbook_SelectWorkflow' | translate"
                [required]="formControls.remediationAction.get('isWorkflowEnabled')?.value"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="readMode"
              ></cv-autocomplete-input>
              <cv-button
                *ngIf="!!selectedWorkflow"
                [text]="'playbook_Preview' | translate"
                (click)="openWorkflow()"
                leftIcon="visibility"
                class="margin-top: -10px"
                buttonType="secondary"
              ></cv-button>
            </div>
            <div *ngIf="formControls.remediationAction.get('remediationType')?.value === 'SendNotification'">
              <cv-multi-input
                (inputChange)="usersInputChanged($event)"
                [suggestions]="userSuggestions"
                class="form-element-spacer"
                style="width: 100%"
                type="mail"
                [label]="'playbook_Recipients' | translate"
                formControlName="notificationRecipients"
                [forceSelection]="false"
                [rightIcon]="'expand_more'"
                [required]="formControls.remediationAction.get('remediationType')?.value === 'SendNotification'"
                [disabled]="readMode"
              ></cv-multi-input>
            </div>
            <div *ngIf="formControls.remediationAction.get('remediationType')?.value === 'ScheduleReport'">
              <div class="setting-selector">
                <cv-select
                  class="grow-1"
                  formControlName="sendReport"
                  [label]="'reports_SendWhen' | translate"
                  [suggestions]="sendReportSuggestions"
                  [disabled]="readMode"
                >
                </cv-select>
              </div>
              <div class="setting-selector form-element-spacer">
                <cv-select
                  class="grow-1"
                  formControlName="format"
                  [label]="'playbook_format' | translate"
                  [suggestions]="sendReportFormatSuggestions"
                  [disabled]="readMode"
                >
                </cv-select>
              </div>
              <cv-textarea
                class="form-element-spacer"
                style="width: 100%"
                [label]="'playbook_EmailBody' | translate"
                formControlName="emailBody"
                [disabled]="readMode"
              ></cv-textarea>

              <cv-multi-input
                (inputChange)="usersInputChanged($event)"
                [suggestions]="userSuggestions"
                class="form-element-spacer"
                style="width: 100%"
                [label]="'playbook_Recipients' | translate"
                formControlName="recipients"
                [forceSelection]="false"
                type="mail"
                [rightIcon]="'expand_more'"
                [required]="formControls.remediationAction.get('remediationType')?.value === 'ScheduleReport'"
                [disabled]="readMode"
              ></cv-multi-input>
            </div>
          </div>
          <div
            class="d-fl fl-d-col fl-1 p-10"
            style="gap: 10px"
            *ngIf="
              hasExecutionInputs &&
              isWorkflowSelected &&
              formControls.remediationAction.get('isWorkflowEnabled')?.value &&
              formControls.remediationAction.get('remediationType')?.value === 'Workflow'
            "
          >
            <div class="m-b-40" style="font-size: 16px" translate>playbook_WorkflowExecutionInput</div>
            <cv-button
              (click)="handleAutobinding()"
              buttonType="secondary"
              [text]="'playbook_AutomapAllFields' | translate"
              leftIcon="flash_on"
              [disabled]="readMode"
            ></cv-button>
            <ng-container
              *ngIf="
                formControls.remediationAction.get('workflowInputMappings') &&
                formControls.remediationAction.get('isWorkflowEnabled')?.value &&
                !readMode
              "
              [formGroup]="$any(formControls.remediationAction.get('workflowInputMappings'))"
            >
              <cv-smart-input
                *ngFor="let input of wfInputSuggestions; index as i"
                [label]="input.displayValue"
                [inputType]="input.inputType"
                [optionsType]="input.optionsType"
                style="width: 100%"
                [bindPrefix]="'report'"
                [bindValues]="wfBindValues"
                [formControlName]="input.value"
                [required]="input.required"
                [maxInsertedValues]="1"
              ></cv-smart-input>
            </ng-container>
          </div>
          <div
            class="d-fl fl-d-col fl-1 p-10"
            style="gap: 10px"
            *ngIf="
              (isWorkflowSelected &&
                formControls.remediationAction.get('isWorkflowEnabled')?.value &&
                formControls.remediationAction.get('remediationType')?.value === 'Workflow') ||
              formControls.remediationAction.get('remediationType')?.value === 'ScheduleReport'
            "
          >
            <div class="m-b-40" *ngIf="checkIfScheduleIsVisible()" style="font-size: 16px" translate>playbook_workflowSchedule</div>
            <div class="schedule-info" *ngIf="checkIfScheduleIsVisible()">
              <cv-alert-bar [hideCloseButton]="true" variant="warning" style="margin: 10px 0" *ngIf="policy && !policy.canSchedule">
                <span translate>playbook_OnlyOwnerCanSchedule</span>
              </cv-alert-bar>
              <cv-select
                class="grow-1"
                (selectionChanged)="scheduleChanged()"
                formControlName="scheduleType"
                [label]="'playbook_workflowRecurrence' | translate"
                [suggestions]="scheduleOptions"
                [disabled]="readMode"
              >
              </cv-select>
            </div>
            <div
              class="schedule-form"
              style="flex-direction: column"
              *ngIf="
                (isScheduleEnabled && formControls.remediationAction.get('isWorkflowEnabled')?.value) ||
                formControls.remediationAction.get('remediationType')?.value === 'ScheduleReport'
              "
            >
              <app-recurrence
                [hideParticularScheduleWeeks]="true"
                [onlyRecurring]="true"
                [checkStartDate]="false"
                formControlName="recurrenceData"
                [customEndDateErrorMessage]="'playbook_EndDateError'"
                [disabled]="readMode"
              ></app-recurrence>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="3">
        <app-datagrid
          *ngIf="showGrid && !policyKeySuggestions.length"
          style="max-height: 0px; visibility: hidden"
          [getItems]="getItems()"
          [gridDefinition]="gridDefinition"
          (columnDefsDefined)="getPolicyKeySuggestionsAndBindValues($event); preparateSummaryCards()"
          (metadataChanged)="lastRequest = $event.queryRequest"
          [rangeFilters]="rangeFilter"
        ></app-datagrid>
        <div *ngIf="!!policyKeySuggestions.length" style="display: grid; grid-template-columns: repeat(3, 1fr); flex: 1; overflow: hidden">
          <div
            *ngFor="let card of summaryCards"
            class="d-fl fl-d-col fl-1"
            style="gap: 10px; padding: 10px 15px 10px 0px; overflow: hidden"
          >
            <app-summary-card [cardInfo]="card"></app-summary-card>
          </div>
        </div>
      </ng-container>
    </form>
    <div class="footer-container">
      <cv-button
        *ngIf="activeIndex === 0"
        [text]="'common_Cancel' | translate"
        (click)="cancel()"
        buttonType="secondary"
        leftIcon="close"
        style="float: left"
        class="m-r-10"
      ></cv-button>
      <cv-button
        *ngIf="activeIndex !== 0"
        style="float: left"
        [text]="'common_Back' | translate"
        leftIcon="navigate_before"
        buttonType="secondary"
        (click)="changeTab(-1)"
      ></cv-button>
      <cv-button
        *ngIf="activeIndex !== steps.length - 1"
        style="float: right"
        [text]="'common_Next' | translate"
        rightIcon="navigate_next"
        buttonType="secondary"
        [disabled]="!isStepValid(activeIndex)"
        cv-data-test="next-step"
        (click)="changeTab(1)"
      ></cv-button>
      <cv-button
        *ngIf="activeIndex === steps.length - 1"
        (click)="save()"
        style="float: right"
        [text]="'common_Save' | translate"
        rightIcon="check"
        [disabled]="!!isSaving || !canSave() || readMode"
      ></cv-button>
    </div>
  </div>
</ng-container>

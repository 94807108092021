import { saveExecutionInputs } from './execution-inputs.actions'
import { createReducer, on } from '@ngrx/store'
import { ExecutionInputsState } from './execution-inputs.types'

export const initialState: ExecutionInputsState = {
  executionInputs: [],
}

export const executionInputsReducer = createReducer(
  initialState,
  on(saveExecutionInputs, (state, { executionInputs }) => ({ executionInputs }))
)

export default executionInputsReducer

<ng-container *ngIf="!!isMultiTarget">
  <mat-expansion-panel
    [expanded]="isPanelExpandedByDefault"
    class="info-panel m-t-25"
    (opened)="onAfterExpandSelectedTargetsView()"
    (afterCollapse)="onAfterCollapseSelectedTargetsView()"
    displayMode="flat"
    cv-data-test="selected-targets-panel"
  >
    <mat-expansion-panel-header collapsedHeight="41px" expandedHeight="41px">
      <div style="justify-content: space-between; display: flex; flex: 1">
        <span translate [translateParams]="{ count: getSelectedTargetsCount() }">management_ItemsSelectedCount</span>
        <span class="m-r-10 hidden-shown-text">
          <span *ngIf="!isSelectedTargetsViewOpen" translate>common_ShowSelected</span>
          <span *ngIf="!!isSelectedTargetsViewOpen" translate>common_ShowAll</span>
        </span>
      </div>
    </mat-expansion-panel-header>
    <div class="flex-full-height">
      <app-client-datagrid
        #selectedTargetsGrid
        *ngIf="selectedTargetsGridColumnsDefs && selectedTargetsGridColumnsDefs.length > 0"
        [getItems]="getSelectedTargets"
        [selectionActions]="selectedTargetsGridSelectionActions"
        [columnDefsBuiltExternally]="selectedTargetsGridColumnsDefs"
        [hideColumnsSelector]="true"
        [autosizeAllColumns]="true"
        (gridReady)="onSelectedTargetsGridReady()"
        [detailCellRenderer]="options?.selectedTargetsDetailCellRenderer"
        [detailCellRendererParams]="options?.selectedTargetsDetailCellRenderParams"
        [isRowMaster]="selectedTargetsIsRowMaster"
        style="flex: 1"
        cvDataTest="mgmt-selected-targets"
      >
      </app-client-datagrid>
    </div>
  </mat-expansion-panel>
</ng-container>

<div [ngClass]="{ hidden: !!isSelectedTargetsViewOpen }" class="flex-full-height">
  <app-client-datagrid
    #targetsToSelectGridClient
    *ngIf="!!targetsToSelectDefinition.isClientDatagrid"
    [getItems]="getTargetsToSelectClient"
    [selectionActions]="targetsToSelectGridClientActions"
    [rowSelection]="rowSelection"
    (gridReady)="onTargetsToSelectGridClientReady()"
    [columnDefsBuiltExternally]="targetsGridColumnsDefs"
    [hideColumnsSelector]="true"
    [autosizeAllColumns]="true"
    (pageDataChanged)="checkSelectionDataGrid()"
    style="flex: 1"
    cvDataTest="mgmt-targets-to-select"
  >
  </app-client-datagrid>

  <app-datagrid
    #targetsToSelectGrid
    *ngIf="!targetsToSelectDefinition.isClientDatagrid"
    [getItems]="refreshDataGrid"
    [gridDefinition]="targetsToSelectDefinition"
    [routeParamsFilters]="targetsToSelectDefinition.defaultFilters"
    [keepSelectionBetweenPages]="!!isMultiTarget"
    (gridReady)="onTargetsToSelectGridServerReady()"
    [hideColumnsSelector]="targetsToSelectDefinition.hideColumnsSelector || false"
    (columnDefsDefined)="onTargetColumnDefsDefined($event.columnDefs)"
    (modelUpdated)="onModelUpdated($event)"
    style="flex: 1"
    cvDataTest="mgmt-targets-to-select"
  >
  </app-datagrid>
</div>

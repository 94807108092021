import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'

import { WorkflowActionCategoryExtended } from '@app/core/models/WorkflowActionCategory'
import { WorkflowActionCategoryLookupState } from './workflow-action-category-lookups.types'
import {
  getWorkflowActionCategoryLookupById,
  getWorkflowActionCategoryLookups,
  getWorkflowActionCategoryLookupsInvalidate,
  getWorkflowActionCategoryLookupsResponse,
} from './workflow-action-category-lookups.actions'

export const workflowActionCategoryLookupsAdapter: EntityAdapter<WorkflowActionCategoryExtended> =
  createEntityAdapter<WorkflowActionCategoryExtended>({
    selectId: ({ categoryId }) => categoryId,
  })

export const initialState: WorkflowActionCategoryLookupState = workflowActionCategoryLookupsAdapter.getInitialState({
  didInvalidate: false,
  isFetching: false,
  error: null,
  loaded: false,
})

export const workflowActionCategoryLookupsReducer = createReducer(
  initialState,
  on(getWorkflowActionCategoryLookups, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(getWorkflowActionCategoryLookupsResponse, (state, categoryLookups) =>
    workflowActionCategoryLookupsAdapter.setAll(categoryLookups.lookups, state)
  ),
  on(getWorkflowActionCategoryLookupsInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true })),
  on(getWorkflowActionCategoryLookupById, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null }))
)

export default workflowActionCategoryLookupsReducer

<ng-container *ngIf="isSequence">
  <app-execution-event-sequence [event]="event" [isCatch]="isCatch" [sequenceEvents]="sequenceEvents"></app-execution-event-sequence>
</ng-container>
<ng-container *ngIf="!isSequence && event.action.id !== 'item::if-else'">
  <app-execution-event-card [event]="event" [isCatch]="isCatch"> </app-execution-event-card>
</ng-container>

<app-execution-event-if-else
  *ngIf="event.action.id === 'item::if-else'"
  [ifElseEvent]="event"
  [showMergeLine]="showMergeLine"
  [isCatch]="isCatch"
>
</app-execution-event-if-else>

<div class="cv-dialog-div-container">
    <div class="panel-container">
        <div class="content">
          <div class="title-container">
            <div class="title-close">
              <div class="title" translate>playbook_ExceptionDetail</div>              
              <cv-close-button (click)="close()" class="close-btn"></cv-close-button>
            </div>
            <div>{{ exception.displayName }}</div>
          </div>         
          <div class="content" [formGroup]="form">  
            <cv-switch formControlName="isNeverExpire">{{ 'common_NeverExpires' | translate }}</cv-switch>
            <cv-input *ngIf="!exception.isNeverExpire" formControlName="exceptionExpirationDate" class="content-element"></cv-input>
            <cv-textarea [label]="'Note'" formControlName="exceptionNotes" class="content-element"></cv-textarea>          
          </div>
        <div class="footer-buttons">
          <cv-button buttonType="secondary" text="{{ 'common_Close' | translate }}" (click)="close()"></cv-button>
          <cv-button text="{{ 'playbook_manageException' | translate }}" (click)="manageException()"></cv-button>
        </div>
      </div>
    </div>
</div>

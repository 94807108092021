import { ColDef, ColGroupDef, GridOptions } from '@ag-grid-community/core'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { UserSettings } from '@coreview/coreview-library/models/user-settings'
import { ApplicationInsightService } from '@app/core/services/application-insight.service'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { ReportsService } from '@app/core/services/reports.service'
import { SuggesterService } from '@app/core/services/suggester.service'
import { Constants } from '@app/shared/utilities/constants'
import { Helpers } from '@app/shared/utilities/helpers'
import { TranslateHelper } from '@coreview/coreview-library'
import { selectLastMessage } from '@app/store/messages/messages.selectors'
import { RootState } from '@app/store/RootState.type'
import { selectUserSettings } from '@app/store/userSettings/userSettings.selectors'
import { Store } from '@ngrx/store'
import { ToastService } from '@coreview/coreview-components'
import { Observable, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators'
import { ReportsComponent } from '../../reports.component'
import { ReportField, ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper'

@Component({
  selector: 'app-calendar-permission',
  templateUrl: './calendar-permission.component.html',
  styleUrls: ['./calendar-permission.component.sass'],
})
export class CalendarPermissionComponent implements OnInit, OnDestroy {
  @ViewChild(ReportsComponent) reportsComponent!: ReportsComponent

  userSettings$!: Observable<UserSettings | undefined>
  userSettings?: UserSettings
  localeIso!: string

  configuration!: {
    gridOptions: GridOptions;
    columnDefs?: (ColDef | ColGroupDef)[];
    getParameters: () => any;
    isRowMaster: () => boolean;
    leftPanel?: string;
    rightPanel?: string;
  }

  filters!: {
    userPrincipalName?: string;
    userWithAccess?: string;
  }

  error!: string | undefined
  resetInProgress!: boolean

  form = new UntypedFormGroup({
    userPrincipalName: new UntypedFormControl(null, [Validators.required]),
    userWithAccess: new UntypedFormControl(null),
  })

  userPrincipalNamesSuggestions: any = []
  userPrincipalNameSelectionUpdate = new Subject<string>()

  userWithAccessSuggestions: any = []
  userWithAccessSelectionUpdate = new Subject<string>()

  private destroyed$: Subject<boolean> = new Subject()

  constructor(
    private store: Store<RootState>,
    private localstorageService: LocalstorageService,
    private reportsService: ReportsService,
    private suggesterService: SuggesterService,
    private appInsights: ApplicationInsightService,
    private toastService: ToastService,
    public translateHelper: TranslateHelper,
    private reportHelper: ReportsComponentHelper
  ) {}

  ngOnInit(): void {
    this.userPrincipalNameSelectionUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      this.getUserPrincipalNamesSuggestion(value)
    })

    this.userWithAccessSelectionUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      this.getUsersWithAccessSuggestion(value)
    })

    this.initializeFilters()
    this.setFormData()

    this.userSettings$ = this.store.select(selectUserSettings)

    this.userSettings$.pipe(takeUntil(this.destroyed$)).subscribe((userSettings) => {
      if (userSettings) {
        const regionalSetting = Constants.regionalSettings.find((us) => us.fullLabel === userSettings.regionalSettings)
        this.localeIso = this.localeIso = Helpers.isoRegionalSetting(regionalSetting)
      }
    })

    this.store
      .select(selectLastMessage)
      .pipe(filter((x) => !!x))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((x: any) => {
        if (x?.type === 'NotifyTaskStatus' && x.body.title === 'GetCalendarPermissions' && x.body.state === 'Finished') {
          this.reportsComponent?.clientGrid?.refresh()
        }
      })

    this.loadData()
  }

  initializeFilters(isReset?: boolean) {
    if (
      !!this.localstorageService.getCalendarPermissionSearchCorrelationId() &&
      !!this.localstorageService.getCalendarPermissionSearchFilters() &&
      !isReset
    ) {
      this.filters = this.localstorageService.getCalendarPermissionSearchFilters()
    } else {
      this.filters = {}
    }
  }

  setFormData() {
    if (this.filters.userPrincipalName)
      {this.form.get('userPrincipalName')?.setValue({ value: this.filters.userPrincipalName, displayValue: this.filters.userPrincipalName })}

    if (this.filters.userWithAccess)
      {this.form.get('userWithAccess')?.setValue({ value: this.filters.userWithAccess, displayValue: this.filters.userWithAccess })}
  }

  getUserPrincipalNamesSuggestion(value: string | null) {
    if (!value) {
      this.userPrincipalNamesSuggestions = []
    } else {
      const params = {
        searchedString: value,
        excludeItems: this.form.get('userWithAccess')?.value ? [this.form.get('userWithAccess')?.value] : [],
        pageSize: 5,
      }

      this.suggesterService.getOnlineUsersByRecipientTypeSuggestion(params).subscribe((suggestions: any) => {
        this.userPrincipalNamesSuggestions = suggestions.map((s: any) => ({
            value: s,
            displayValue: s,
          }))
      })
    }
  }

  getUsersWithAccessSuggestion(value: string | null) {
    if (!value) {
      this.userWithAccessSuggestions = []
    } else {
      const params = {
        searchedString: value,
        excludeItems: this.form.get('userPrincipalName')?.value ? [this.form.get('userPrincipalName')?.value] : [],
        pageSize: 5,
      }

      this.suggesterService.getGrantAccessSuggestions(params).subscribe((suggestions: any) => {
        this.userWithAccessSuggestions = suggestions.map((s: any) => ({
            value: s,
            displayValue: s,
          }))
      })
    }
  }

  onChangeUserPrincipalName(event: any): void {}

  onChangeUserWithAccess(event: any): void {}

  userPrincipalNameSelectionChanged(newValue: any): void {
    if(newValue) {
      this.form.get('userPrincipalName')?.setValue({ value: newValue.value, displayValue: newValue.displayValue })
    }
  }

  userWithAccessSelectionChanged(newValue: any): void {
    if(newValue) {
      this.form.get('userWithAccess')?.setValue({ value: newValue.value, displayValue: newValue.displayValue })
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  loadData() {
    this.configuration = {
      gridOptions: {
        defaultColDef: {
          sortable: true,
          resizable: false,
          filter: true,
          floatingFilter: true,
          filterParams: {
            suppressAndOrCondition: true,
          },
        },
      },
      isRowMaster: () => false,
      columnDefs: this.buildColDefsByFields(
        [
          { key: 'userPrincipalName', value: 'UserPrincipalName', type: 'string' },
          { key: 'folderName', value: 'FolderName', type: 'string' },
          { key: 'user', value: 'User', type: 'string' },
          { key: 'displayName', value: 'DisplayName', type: 'string' },
          { key: 'accessRights', value: 'AccessRights', type: 'string' },
        ],
        true
      ),
      getParameters: () => ({ correlationId: this.localstorageService.getCalendarPermissionSearchCorrelationId() }),
      leftPanel: 'CustomSmartPanel',
    }
  }

  getError() {
    if (this.isSearchDisabled() && this.form.dirty) {
      // if (!this.form.get('userPrincipalName')?.value && !this.form.get('userWithAccess')?.value) {
      //   return 'common_AtLeastOneFieldRequired'
      // }
    }

    return null
  }

  isSearchDisabled() {
    // if (this.form.invalid || Validators.email(this.form.get('userPrincipalName')?.value || '') ||
    //   Validators.email(this.form.get('userWithAccess')?.value || ''))
    //   return true

    // return false
    // // return !this.form.get('userPrincipalName')?.value && !this.form.get('userWithAccess')?.value

    return (
      this.form.invalid ||
      Validators.email(this.form.get('userPrincipalName')?.value || '') != null ||
      Validators.email(this.form.get('userWithAccess')?.value || '') != null
    )
  }

  search() {
    this.error = undefined

    if (this.form.get('userPrincipalName')?.value) {this.filters.userPrincipalName = this.form.get('userPrincipalName')?.value?.value}
    else {this.filters.userPrincipalName = undefined}

    if (this.form.get('userWithAccess')?.value) {this.filters.userWithAccess = this.form.get('userWithAccess')?.value?.value}
    else {this.filters.userWithAccess = undefined}

    setTimeout(() => {
      this.reportsService.getDataSimplified('/management/userCalendar/', 'post', this.filters).subscribe({
        next: (res) => {
          if (res.taskId) {
            this.localstorageService.setCalendarPermissionSearchFilters(this.filters)
            this.localstorageService.setCalendarPermissionSearchCorrelationId(res.taskId)

            this.toastService.open({
              id: 'success',
              variant: 'success',
              title: this.translateHelper.instant('common_Success'),
              message: this.translateHelper.instant('reports_CalendarPermissionRequestSuccessfullySent'),
            })

            this.reportsComponent.clientGrid?.refresh()
          } else {
          }
        },
        error: (error) => {
          this.appInsights.trackError('Search calendar permission trace failed with error: : ' + error)
          this.toastService.open({
            id: 'err',
            variant: 'error',
            title: this.translateHelper.instant('reports_CalendarPermissionSearch'),
            dismiss: (toast: { id: any }) => this.toastService.close(toast.id),
          })

          console.log(error.error.responseStatus.message)
        },
      })
    }, 1)
  }

  reset() {
    this.resetInProgress = true
    this.error = undefined

    setTimeout(() => {
      this.initializeFilters(true)

      this.form.reset({})

      this.form.markAsPristine()

      this.resetInProgress = false
    }, 1)
  }

  buildColDefsByFields(
    fields: ReportField[],
    hasCheckbox = false
  ) {
    const gridColumnsDefs: ColDef[] = []

    if (!!hasCheckbox) {
      gridColumnsDefs.push({
        type: 'undefined',
        field: 'selection',
        hide: false,
        colId: 'selection',
        checkboxSelection: true,
        headerComponentParams: {
          keepSelectionBetweenPages: true,
        },
        ...Constants.defaultOperationColumnsDefinition,
      })
    }

    fields.forEach((f) => {
      gridColumnsDefs.push({ ...this.reportHelper.generateGridColumnsDef(f), unSortIcon: true })
    })

    return gridColumnsDefs
  }

  dateFormatter = (params: any): string => Helpers.formatDate(params.value) || this.translateHelper.instant('common_NeverUsed')

  valueFormatterMap: Record<string, any> = {
    date: this.dateFormatter,
  }
}

import { ApiclientService } from './../../services/apiclient.service'
import { Component } from '@angular/core'

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.sass'],
})
export class ForbiddenComponent {
  constructor(private apiclientService: ApiclientService) {}

  goToPortalV1(): void {
    window.location.href = this.apiclientService.portalV1Url
  }
}

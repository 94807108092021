import { Component } from '@angular/core';

@Component({
  selector: 'app-secure-by-default',
  templateUrl: './secure-by-default.component.html',
  styleUrls: ['./secure-by-default.component.sass']
})
export class SecureByDefaultComponent {

}

import { Component, Input } from '@angular/core'
import { BuildColParameter } from '@app/shared/utilities/build-col-ag-grid'
import { of } from 'rxjs'

@Component({
  selector: 'app-custom-action-submit-error-detail',
  templateUrl: './custom-action-submit-error-detail.component.html',
  styleUrls: ['./custom-action-submit-error-detail.component.sass'],
})
export class CustomActionSubmitErrorDetailComponent {
  @Input() errors!: { severity: string; message: string }[]

  errorMessagesColumnDefs: BuildColParameter = {
    selectedCols: ['severity', 'message'],
    allcols: [
      {
        originalName: 'severity',
        name: 'severity',
        translate: 'common_Severity',
        type: 'string',
        filter: { type: 'string', name: 'severity' },
      },
      {
        originalName: 'message',
        name: 'message',
        translate: 'management_ErrorMessage',
        type: 'string',
        filter: { type: 'string', name: 'message' },
        agColDef: {
          cellRenderer: 'tooltipRenderer'
        },
      },
    ],
  }

  getErrorMessages = (): any => of(this.errors)
}

<div *ngIf="searchable" class="three-columns-grid p-b-10">
  <cv-input
    [label]="searchLabel | translate"
    [clearButton]="true"
    (inputChange)="filterChanged($event)"
    rightIcon="search"
    class="first-column"
  ></cv-input>
</div>
<mat-radio-group [(ngModel)]="selected">
  <mat-tree class="fl-1" [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodePaddingIndent="28px"
      matTreeNodePadding
      [style.display]="node.hidden ? 'none' : 'flex'"
      [ngClass]="{ selected: isChecked(node) }"
    >
      <mat-radio-button [value]="node.ref.id" [checked]="isChecked(node)" [disabled]="readonly" (change)="handleChange(node, $event)" class="m-l-10">
        <ng-container *ngIf="node.icon"> <em class="{{ node.icon }} node-icon"></em>&nbsp; </ng-container>
        {{ node.name }}
      </mat-radio-button>
    </mat-tree-node>

    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      matTreeNodePaddingIndent="28px"
      [style.display]="node.hidden ? 'none' : 'flex'"
    >
      <button matTreeNodeToggle class="btn">
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <ng-container *ngIf="node.icon"> <em class="{{ node.icon }} node-icon"></em>&nbsp;</ng-container>
      {{ node.name }}
    </mat-tree-node>
  </mat-tree>
</mat-radio-group>

import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-action-property-list',
  templateUrl: './action-property-list.component.html',
  styleUrls: ['./action-property-list.component.sass'],
})
export class ActionPropertyListComponent {
  @Input() items: any[] = []
}

import { RightPanelService } from '@app/core/services/right-panel.service'
import { ManagePricePerUnitComponent } from '../components/subscription-center/manage-price-per-unit/manage-price-per-unit.component'
import { TargetSelectionDefinition } from '@app/shared/components/target-selection/target-selection.component'
import { of } from 'rxjs'
import { Constants } from '@app/shared/utilities/constants'
import { ManagePriceHistoryComponent } from '../components/subscription-center/manage-price-history/manage-price-history.component'

export const getSubscriptionCenterDefinition = (
  dateFormatter: (params: any) => string
): any => {
  return {
    tabs: [
      {
        menuId: Constants.menuIds.licenseSubscriptionOverview,
        title: 'ActiveSubscriptions',
        entity: 'subscriptionsActive',
        tooltip: 'ActiveSubscriptions_info',
        sortField: 'pricePerUnit',
        sortOrder: 'desc',
        verb: 'get',
        responseItemProp: 'activeSubcriptionDetails',
        apiUrl: '/subscriptions/active',
        reportDefinition$: of({
          entity: 'subscriptionsActive',
          url: '/subscriptions/active',
          isNonVTenantSensitive: true,
          sortField: 'PercentageUsed',
          sortOrder: 'desc',
          verb: 'get',
          responseItemProp: 'activeSubcriptionDetails',
          rowSelection: 'none',
          selectionActions: ['Export', 'SaveCustomReport', 'Schedule'],
          filterActions: [],
          fields: ['Sku', 'PercentageUsed', 'PricePerUnit', 'Currency', 'ActiveUnits', 'ConsumedUnits', 'RemainingUnits', 'WarningUnits'],
        }),
        hideColumnsSelector: true,
        hideManagementPanel: true,
        isNonVTenantSensitive: true,
        customSelectionActions: [
          {
            build: (refreshTable: any, rightPanelService: RightPanelService): any => ({
              text: 'licenses_ManagePricePerUnit',
              buttonType: 'tertiary',
              icon: 'settings',
              onClick: (data: any) => {
                rightPanelService
                  .open({
                    type: ManagePricePerUnitComponent,
                    data: {
                      configuration: {
                        targetSelectionDefinition: {
                          isClientDatagrid: true,
                          url: '/accountsku/',
                          verb: 'get',
                          responseItemProp: 'accountSkuDetails',
                          sortField: 'pricePerUnit',
                          sortOrder: 'desc',
                          rowSelection: 'multiple',
                          targetPropertyId: 'skuId',
                          entity: 'LicensesEntity',
                          selectionActions: [],
                          getTargetsToSelect: of([]),
                          fields: [],
                        } as TargetSelectionDefinition,
                        targetSelectionConfiguration: {
                          selectedItemsFields: [
                            { key: 'sku', value: 'Sku' },
                            { key: 'pricePerUnit', value: 'PricePerUnit', type: 'number', sort: 'desc' },
                            { key: 'currency', value: 'Currency', type: 'string' },
                          ],
                          fields: [
                            { key: 'sku', value: 'Sku' },
                            { key: 'pricePerUnit', value: 'PricePerUnit', type: 'number', sort: 'desc' },
                            { key: 'currency', value: 'Currency', type: 'string' },
                          ],
                        },
                      },
                      width: '100%',
                    },
                  })
                  .afterClosed()
                  .subscribe(() => {
                    refreshTable()
                  })
              },
            }),
          },
        ],
      },
      {
        menuId: Constants.menuIds.licenseSubscriptionsHistory,
        hideManagementPanel: true,
        title: 'SubscriptionHistory',
        entity: 'subscriptionsActiveHistory',
        tooltip: 'SubscriptionHistory_info',
        sortField: 'pricePerUnit',
        sortOrder: 'desc',
        verb: 'get',
        responseItemProp: 'activeSubcriptionDetails',
        apiUrl: '/subscriptions/history',
        reportDefinition$: of({
          entity: 'subscriptionsActiveHistory',
          url: '/subscriptions/history',
          isNonVTenantSensitive: true,
          sortField: 'PricePerUnit',
          sortOrder: 'desc',
          verb: 'get',
          responseItemProp: 'activeSubcriptionDetails',
          detailCellRenderer: 'customDetail',
          detailCellRenderParams: {
            tableDataField: 'historyInfo',
            columnDefs: [
              { field: 'pricePerUnit', sort: 'asc', type: 'int', name: 'pricePerUnit', translate: 'PricePerUnit', suppressMenu: true },
              { field: 'currency', type: 'string', name: 'currency', translate: 'Currency', suppressMenu: true },
              { field: 'status', type: 'string', name: 'status', translate: 'Status', suppressMenu: true },
              {
                field: 'dateCreated',
                type: 'date',
                name: 'dateCreated',
                translate: 'PurchaseDate',
                suppressMenu: true,
                valueFormatter: dateFormatter,
              },
              { field: 'totalLicenses', type: 'int', name: 'totalLicenses', translate: 'PurchasedLicenses', suppressMenu: true },
              {
                field: 'nextLifecycleDate',
                type: 'date',
                name: 'nextBillDate',
                translate: 'NextBillDate',
                suppressMenu: true,
                valueFormatter: dateFormatter,
              },
            ],
          },
          isOnlineUsersType: true,
          rowSelection: 'none',
          selectionActions: ['Export', 'SaveCustomReport', 'Schedule'],
          filterActions: [],
          fields: ['Sku', 'PricePerUnit', 'Currency', 'ActiveUnits', 'ConsumedUnits', 'RemainingUnits', 'WarningUnits'],
        }),
        isNonVTenantSensitive: true,
        columnDefs: [
          {
            originalName: 'Sku',
            name: 'sku',
            translate: 'Sku',
            filter: { type: 'string', name: 'Sku' },
            type: 'tableDetail',
          },
          {
            originalName: 'PricePerUnit',
            name: 'pricePerUnit',
            translate: 'PricePerUnit',
            filter: { type: 'int', name: 'PricePerUnit' },
            type: 'int',
          },
          {
            originalName: 'Currency',
            name: 'currency',
            translate: 'Currency',
            filter: { type: 'string', name: 'Currency' },
            type: 'string',
          },
          {
            originalName: 'ActiveUnits',
            name: 'activeUnits',
            translate: 'ActiveUnits',
            filter: { type: 'int', name: 'ActiveUnits' },
            type: 'int',
          },
          {
            originalName: 'ConsumedUnits',
            name: 'consumedUnits',
            translate: 'ConsumedUnits',
            filter: { type: 'int', name: 'ConsumedUnits' },
            type: 'int',
          },
          {
            originalName: 'RemainingUnits',
            name: 'remainingUnits',
            translate: 'RemainingUnits',
            filter: { type: 'int', name: 'RemainingUnits' },
            type: 'int',
          },
          {
            originalName: 'WarningUnits',
            name: 'warningUnits',
            translate: 'WarningUnits',
            filter: { type: 'int', name: 'WarningUnits' },
            type: 'int',
          },
        ],
        selectionActions: ['Export', 'SaveCustomReport', 'Schedule'],
        filterActions: [],
        hideColumnsSelector: true,
        customSelectionActions: [
          {
            build: (refreshTable: any, rightPanelService: RightPanelService): any => ({
              text: 'licenses_ManagePriceHistory',
              buttonType: 'tertiary',
              icon: 'settings',
              onClick: (data: any) => {
                rightPanelService
                  .open({
                    type: ManagePriceHistoryComponent,
                    data: {
                      configuration: {
                        targetSelectionDefinition: {
                          isClientDatagrid: true,
                          url: '/accountsku/',
                          verb: 'get',
                          responseItemProp: 'accountSkuDetails',
                          sortField: 'pricePerUnit',
                          sortOrder: 'desc',
                          rowSelection: 'single',
                          targetPropertyId: 'skuId',
                          entity: 'LicensesEntity',
                          selectionActions: [],
                          getTargetsToSelect: of([]),
                          fields: [],
                        } as TargetSelectionDefinition,
                        targetSelectionConfiguration: {
                          isPanelExpandedByDefault: false,
                          fields: [
                            { key: 'sku', value: 'Sku' },
                            { key: 'pricePerUnit', value: 'PricePerUnit', type: 'number', sort: 'desc' },
                            { key: 'currency', value: 'Currency', type: 'string' },
                          ],
                        }
                      },
                      width: '100%',
                    },
                  })
                  .afterClosed()
                  .subscribe(() => {
                    refreshTable()
                  })
              },
            }),
          },
        ],
      }
    ],
  }
}

export interface ButtonGroup {
    buttons: string[];
    buttonsVisible?: number;
    align?: string;
}

export interface FroalaToolbarButtons {
    moreText: ButtonGroup;
    moreParagraph: ButtonGroup;
    moreRich: ButtonGroup;
    moreMisc: ButtonGroup;
    trackChanges: ButtonGroup;
}

export const froalaToolbarButtons: FroalaToolbarButtons = {
    moreText: {
        buttons: [
            "bold",
            "italic",
            "underline",
            "strikeThrough",
            "subscript",
            "superscript",
            "fontFamily",
            "fontSize",
            "textColor",
            "backgroundColor",
            "inlineClass",
            "inlineStyle",
            "clearFormatting"
        ]
    },
    moreParagraph: {
        buttons: [
            "alignLeft",
            "alignCenter",
            "formatOLSimple",
            "alignRight",
            "alignJustify",
            "formatOL",
            "formatUL",
            "paragraphFormat",
            "paragraphStyle",
            "lineHeight",
            "outdent",
            "indent",
            "quote"
        ]
    },
    moreRich: {
        buttons: [
            "trackChanges",
            "html",
            "markdown",
            "insertLink",
            "insertImage",
            "insertVideo",
            "insertTable",
            "emoticons",
            "fontAwesome",
            "specialCharacters",
            "embedly",
            "insertHR"
        ],
        buttonsVisible: 5
    },
    moreMisc: {
        buttons: [
            "selectAll",
            "fullscreen",
            "print",
            "spellChecker",
            "help"
        ],
        align: "right",
        buttonsVisible: 2
    },
    trackChanges: {
        buttons: [
            "showChanges",
            "applyAll",
            "removeAll",
            "applyLast",
            "removeLast"
        ],
        buttonsVisible: 0
    }
};

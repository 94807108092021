<div class="right-panel">
    <div class="content-container" style="overflow-y: auto">
        <div class="panel-title-container">
          <span class="panel-title uppercase" translate>administration_GrantAccessDetails</span>
        </div>
    
        <div class="grid-container">        
            <div class="f-w-600" translate>administration_GrantedBy</div>
            <div>{{item.grantedBy}}</div>
            
            <div class="f-w-600" translate>administration_Status</div>
            <div>{{item.status}}</div>
            
            <div class="f-w-600" translate>administration_Roles</div>
            <div>
                <mat-chip-listbox aria-label="roles" hideSingleSelectionIndicator [selectable]="false">
                    <mat-chip-option *ngFor="let i of item.roles"
                        color="{{i.name === 'TenantAdmin' ? 'accent' : 'primary'}}"
                        >
                            {{i}}
                    </mat-chip-option>
                </mat-chip-listbox>
            </div>
            
            <div class="f-w-600" translate>administration_Permissions</div>
            <div>
                <ng-container *ngFor="let p of item.permissions">
                    <div class="permission">{{ p.name }} </div>
                </ng-container>                
            </div>

            <div class="f-w-600" translate>administration_VTenants</div>
            <div>
                <ng-container *ngFor="let t of item.vTenants">
                    <div class="tenant">{{ t.name }} </div>
                </ng-container>                
            </div>

            <div class="f-w-600" translate>administration_AccessGranted</div>
            <div>{{formatDate(item.grantedOn)}}</div>
            
            <div class="f-w-600" translate>administration_AccessUntil</div>
            <div>{{formatDate(item.expiration)}}</div>
        </div>
    </div>
</div>
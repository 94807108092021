/* eslint-disable max-len */
import { MissingSkusDialogComponent } from './../components/dialogs/missing-skus-dialog/missing-skus-dialog.component'
import { MissingPermissionsDialogComponent } from './../components/dialogs/missing-permissions-dialog/missing-permissions-dialog.component'
import { ReportsService } from '@app/core/services/reports.service'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'

import { LocalstorageService } from '../services/localstorage.service'
import { Helpers } from '@app/shared/utilities/helpers'
import { MatDialog } from '@angular/material/dialog'
import { NotAllowedDialogComponent } from '../components/dialogs/not-allowed-dialogs/not-allowed-dialog.component'
import { map } from 'rxjs/operators'
import { Constants } from '@app/shared/utilities/constants'
import { LoadingService } from '../services/loading.service'
import { ApiclientService } from '../services/apiclient.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private storage: LocalstorageService,
    private reportsService: ReportsService,
    private apiClientService: ApiclientService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.data.accessLevel.includes(Constants.noServiceCheck)) {
      return true
    }

    const access = this.storage.getLoggedUser().roles.some((r) => route.data.accessLevel.includes(Helpers.downcase(r)))
    if (!access) {
      this.dialog.open(NotAllowedDialogComponent, { width: '50%' })
    }
    if (access) {
      this.loadingService.loading$.emit(true)
     return this.reportsService.canViewReport(route, state).pipe(
        map((res) => {
          if (!res.canViewReport) {
            if (res.reason === 'Permission') {
              this.dialog.open(MissingPermissionsDialogComponent, { width: '50%' })
            } else if (res.reason === 'SecureByDefault') {
              this.router.navigate([{ outlets: { forbidden: 'securebydefault' }}], { skipLocationChange: true }).catch((_: any) => _)
            } else {
              this.dialog.open(MissingSkusDialogComponent, { width: '50%' })
            }
          }
          this.loadingService.loading$.emit(false)

          this.apiClientService.trackAction(Constants.trackActons.navigate, state.url).subscribe(() => {})

          return res.canViewReport
        })
      )
    }
    return false
  }
}

import { Component, Input, OnChanges, Optional, Self, SimpleChanges } from '@angular/core'
import { AbstractControl, AbstractControlOptions, FormBuilder, FormGroup, NgControl, ValidationErrors, Validators } from '@angular/forms'
import { timeToMinutes } from '@app/modules/management/utils/time.utils'
import { TranslateHelper } from '@coreview/coreview-library'
import { BaseControlComponent, Suggestion } from '@coreview/coreview-components'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { flatten, range } from 'lodash-es'

export interface TimeRange {
  startTime: string | null
  endTime: string | null
}

@Component({
  selector: 'app-auto-attendant-business-hours-row',
  templateUrl: './auto-attendant-business-hours-row.component.html',
  styleUrls: ['./auto-attendant-business-hours-row.component.sass'],
})
export class AutoAttendantBusinessHoursRowComponent extends BaseControlComponent implements OnChanges {
  @Input() fieldsDisabled: boolean = false

  @Input()
  get value(): TimeRange {
    return this.form.value
  }

  set value(rangeVal: any) {
    if (rangeVal && typeof rangeVal === 'object') {
      const { startTime, endTime } = rangeVal
      this.form.patchValue({ startTime, endTime })
    }
  }

  hoursList: Suggestion[] = []

  form: FormGroup

  constructor(@Optional() @Self() public ngControl: NgControl, private fb: FormBuilder, private translate: TranslateHelper) {
    super(ngControl)
    dayjs.extend(duration)
    this.form = this.fb.group(
      {
        startTime: [null, Validators.required],
        endTime: [null, Validators.required],
      },
      { validator: this.validateTimeRange } as AbstractControlOptions
    )

    this.form.valueChanges.subscribe((_) => {
      this.onChange(this.value)
    })

    this.form.statusChanges.subscribe((status) => {
      if (this.ngControl?.control) {
        const error = status === 'INVALID' ? { invalidRange: true } : null
        this.ngControl.control.setErrors(error)
      }
    })

    this.hoursList = [{ value: null, displayValue: this.translate.instant('common_None') }, ...this.getBusinessHours()]
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.fieldsDisabled && !changes.fieldsDisabled.firstChange) {
      if(changes.fieldsDisabled.currentValue) {
        this.form.disable()
      } else {
        this.form.enable()
      }
    }
}

  private getBusinessHours() {
    const hours = range(0, 24)
    const minutes = [0, 15, 30, 45]
    return flatten(
      hours.map((h) =>
        minutes.map((m) => ({
          value: `${h}:${m.toString().padEnd(2, '0')}`,
          displayValue: dayjs().set('hour', h).set('minute', m).format('hh:mm A'),
        }))
      )
    )
  }

  private validateTimeRange(control: AbstractControl): ValidationErrors | null {
    const { startTime, endTime } = control.value
    const startTimeControl = control.get('startTime')
    const endTimeControl = control.get('endTime')
    if (!startTime || !endTime) {
      startTimeControl?.markAsTouched()
      endTimeControl?.markAsTouched()
      return { required: true}
    }
    if (startTime && endTime && timeToMinutes(startTime) >= timeToMinutes(endTime) && endTime !== '0:00') {
      startTimeControl?.setErrors(null)
      endTimeControl?.setErrors(null)
      startTimeControl?.markAsTouched()
      endTimeControl?.markAsTouched()
      return { invalidRange: true }
    }
    startTimeControl?.markAsTouched()
    endTimeControl?.markAsTouched()
    return null
  }
}

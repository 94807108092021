/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
  export enum CalendarType {
    ChooseLastDays,
    Calendar,
    CalendarRange
  }

  export type Verb = 'get' |'post' | 'put' | 'delete' | 'patch'

  export type SortOrder = 'asc' | 'desc'

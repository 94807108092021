import { createFeatureSelector, createSelector } from '@ngrx/store'
import { tenantsAdapter } from './tenants.reducer'
import { TenantsState } from '@app/store/tenants/tenants.types'
import { ManagedTenant } from '@app/core/models/ManagedTenant'

const defaultSelectors = tenantsAdapter.getSelectors()

/**
 * Selects the whole 'tenants' state
 */
export const selectTenantsState = createFeatureSelector<TenantsState>('tenants')

/**
 * Select all the available entities
 */
export const selectTenantsEntities = createSelector(selectTenantsState, defaultSelectors.selectEntities)

/**
 * Select the "default" tenant
 */
export const selectSelectedTenant = createSelector(selectTenantsState, (state: TenantsState) =>
  state.selected ? state.entities[state.selected] : state.default ? state.entities[state.default] : undefined
)

export const selectDefaultTenant = createSelector(selectTenantsState, (state: TenantsState) =>
  state.default ? state.entities[state.default] : undefined
)

/**
 * Select the error within the TenantsState
 */
export const selectTenantError = createSelector(selectTenantsState, (state: TenantsState) => state.error)

/**
 * Select the fetching flag of the TenantsState
 */
export const selectTenantLoading = createSelector(selectTenantsState, (state: TenantsState) => state.isFetching)

/**
 * Select the "managed" tenant
 */
export const selectManagedTenants = createSelector(selectTenantsState, (state: TenantsState) =>
  state.managed.map((id) => state.entities[id] as ManagedTenant)
)

import { of } from 'rxjs'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { getDashboards, getDashboardsInvalidate, getDashboardsResponse } from '@app/store/dashboards/dashboards.actions'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { DashboardService } from '@app/core/services/dashboard.service'

@Injectable()
export class DashboardsEffects {
  getDashboards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDashboards),
      mergeMap(() =>
        this.dashboardService.getDashboards().pipe(
          map((actions) => getDashboardsResponse({ actions })),
          catchError((error) => of(getDashboardsInvalidate({ error: { code: error.status, message: error.message }})))
        )
      )
    )
  )

  constructor(private actions$: Actions, private dashboardService: DashboardService) {}
}

<form [formGroup]="form" class="content-container" style="display: flex; flex:1">
    <cv-select #sel style="padding: 5px;width: 150px;" [suggestions]="membershipOptions" formControlName="membership"
        [label]="'common_Operation' | translate" [errorMessage]="'common_Required' | translate"
        (selectionChanged)="membershipChanged($event)">
    </cv-select>
    <cv-select #sel style="padding: 5px; flex:1" [suggestions]="groupTypes" formControlName="groupType"
        [label]="'common_GroupType' | translate" [errorMessage]="'common_Required' | translate"
        (selectionChanged)="membershipChanged($event)">
    </cv-select>
    <cv-autocomplete-input #inpvalue style="flex: 1; padding: 5px" (inputChange)="filterValue($event, inpvalue)"
        formControlName="value" [label]="'common_Value' | translate" [errorMessage]="'common_Required' | translate"
        [debounceMilliseconds]="500"></cv-autocomplete-input>
</form>
/* eslint-disable @typescript-eslint/naming-convention */

import { Helpers } from '@app/shared/utilities/helpers'

// eslint-disable-next-line no-shadow
export enum GroupType {
  DistributionGroup = 0,
  Office365Group = 1,
  SecurityGroup = 2,
}

export enum GroupType2 {
  DistributionGroup = '0',
  Office365Group = '1',
  SecurityGroup = '2',
  Teams = '3',
  User = 'User', //Not used
  Audit = 'Audit', // Not used
}

export enum GroupTypeO365 {
  O365Group = '1',
}

export enum TargetEntityAuditType {
  Audit = 'Audit',
}

//String value as arrive from api (TargetEntity in report definition)
export enum TargetEntityBaseType {
  DistributionGroup = 'DistributionGroup',
  SecurityGroup = 'SecurityGroup',
  Teams = 'Teams',
  User = 'User',
}

export enum CollectionNameType {
  User = "OnlineUsers"
}

export enum TargetEntityType {
  Office365Group = 'Office365Group',
}

export enum TargetEntityCustomActionType {
  Mailbox = 'Mailbox',
  MailContact = 'MailContact',
  O365Group = 'O365Group',
  SkypeForBusiness = 'SkypeForBusiness',
  SharePoint = 'SharePoint',
}

export enum TargetEntityNoneType {
  None = 'None',
}

export enum TargetEntityWorkflowType {
  Office365DeletedGroup = 'Office365DeletedGroup',
  Mobile = 'Mobile'
}

export enum TargetEntityPolicyType {
  Mailbox = 'Mailbox',
  MailContact = 'MailContact',
  SharePointSites = 'SharePointSites',
  SharePointInactiveUsers = 'SharePointInactiveUsers',
}

export enum TargetEntityTeamsType {
  TeamsGroupChannel = 'TeamsGroupChannel'
}

const membersMapCustomAction: Record<string, string> = {
  Office365GroupMembers: 'O365Group',
  DistributionGroupMembers: 'DistributionGroup',
  SecurityGroupMembers: 'SecurityGroup',
  TeamsGroupMembers: 'Teams',
}

const membersMapReportsFilter: Record<string, TargetEntityBaseType> = {
  Office365GroupMembers: TargetEntityBaseType.User,
  DistributionGroupMembers: TargetEntityBaseType.User,
  SecurityGroupMembers: TargetEntityBaseType.User,
  TeamsGroupMembers: TargetEntityBaseType.User,
}

export const convertToGroupType = (value: TargetEntityType | TargetEntityBaseType | TargetEntityAuditType) => GroupType2[value]

export const convertFromGroupType2 = (value: string): string => Helpers.enumFromStringValue<GroupType2>(GroupType2, value) as string

export const convertOffice365ToO365 = (value: string): string =>
  (Helpers.enumFromStringValue<GroupTypeO365>(GroupTypeO365, convertToGroupType(value as any)) as string) || value

export const convertFromMembersTypeGroup = (value: string): string | undefined => membersMapCustomAction[value]

export const convertFromMembersTypeUser = (value: string): TargetEntityBaseType | undefined => membersMapReportsFilter[value]

export const convertTargetEntityForActions = (value: string): string => convertFromMembersTypeGroup(value) ?? convertOffice365ToO365(value)

/* eslint-disable @typescript-eslint/naming-convention */
import { HttpErrorResponse } from '@angular/common/http'
import { PlaybookFilters, PolicyDescription, ThresholdSeverity } from './../../../../core/models/playbook'
import { PolicyDialogComponent } from './../policy-dialog/policy-dialog.component'
import { Observable, of, timer, BehaviorSubject, Subject } from 'rxjs'
import { PlaybookService } from './../../services/playbook.service'
import { Component, DoCheck, EventEmitter, Input, OnInit, Output, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Policy } from '@app/core/models/playbook'
import { TranslateHelper } from '@coreview/coreview-library'
import { cloneDeep } from 'lodash-es'
import { filter, map, switchMap, first, takeUntil } from 'rxjs/operators'
import { DialogComponent, SwitchComponent, ToastService } from '@coreview/coreview-components'
import dayjs from 'dayjs'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { selectLastMessageOfType } from '@app/store/messages/messages.selectors'
import { ActivatedRoute, Router } from '@angular/router'
import { selectedOrganizationSkus } from '@app/store/organizations/organizations.selectors'
import { getPlaybookItems } from '@app/store/playbooks/playbooks.actions'
import { WorkflowPreviewDialogComponent } from '../workflow-preview-dialog/workflow-preview-dialog.component'
import { PolicyService } from '../../services/policy.service'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { FeatureFlags } from '../../playbook-constants'
import { Constants } from '@app/shared/utilities/constants'

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.sass'],
})
export class PolicyComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {
  @Input()
  isAdmin = false

  @Input()
  policiesObservable: Observable<Policy[]> = this.playbookService.getPolicies()

  @Input()
  isMonitoring = false

  @Input()
  isGovernance = false

  @Input()
  filters!: PlaybookFilters

  @Output()
  selectedResults = new EventEmitter<number>()

  @Output()
  policySaved = new EventEmitter<void>()

  @Output()
  learnMoreClicked = new EventEmitter<Policy>()

  @Output()
  policiesLoaded = new EventEmitter<void>()

  @Input()
  beforeGoToFullReport!: (policy: Policy) => void

  @Input()
  prefixUrl: string = ''

  @ViewChild('container')
  set setContainer(el: ElementRef) {
    this.container = el
  }

  missingRemediationPermission = Constants.MissingRemediationPermission
  loaded = false
  loading$ = new BehaviorSubject<boolean>(false)
  showOnHover: { [key: number]: boolean } = {}
  oldFilters!: PlaybookFilters
  policies: ExtendedPolicy[] = []
  filteredPolicies: ExtendedPolicy[] = []
  policiesDetails: { [key: number]: boolean } = {}
  container!: ElementRef

  skus: readonly string[] | undefined = []

  shortPolicies = false

  private destroyed$: Subject<boolean> = new Subject()
  constructor(
    private translateHelper: TranslateHelper,
    private dialog: MatDialog,
    private playbookService: PlaybookService,
    private toastService: ToastService,
    private store: Store<RootState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public policyService: PolicyService,
    private sharedHelperService: SharedHelperService
  ) {}

  ngAfterViewInit(): void {
    this.checkShortPolicies()
    const resizeObserver = new ResizeObserver(() => this.checkShortPolicies())
    resizeObserver.observe(this.container.nativeElement)
  }

  ngOnInit(): void {
    if (this.filters) {
      this.oldFilters = cloneDeep(this.filters)
    }

    this.store
      .select(selectedOrganizationSkus)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((skus) => (this.skus = skus))

    this.store
      .select(selectLastMessageOfType('PolicyMetricsUpdated'))
      .pipe(
        filter((x) => !!x),
        switchMap((res) =>
          this.loading$.value
            ? this.loading$.pipe(
                filter((x) => !x),
                map(() => res),
                first()
              )
            : of(res)
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe((message) => {
        if (!message?.body) {
          return
        }
        const { policyId, metrics } = message?.body as {
          policyId: string
          metrics: {
            isThresholdExceeded: boolean
            matchedItemsCount: number
            exceptionItemsCount: number
            totalItemsCount: number
            lastUpdatedDateUtc: Date
          }
        }
        this.policies = this.policies.map((x) => {
          if (x.id === policyId) {
            x.matchedItemsCount = metrics.matchedItemsCount
            x.exceptionItemsCount = metrics.exceptionItemsCount
            x.lastUpdatedDateUtc = new Date(metrics.lastUpdatedDateUtc)
            x.isThresholdExceeded = metrics.isThresholdExceeded
            x.calculatingMetrics = false
          }
          return x
        })
        this.applyFilters()
      })
    this.load()
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  ngDoCheck(): void {
    if (this.filters) {
      this.applyFilters()
      this.oldFilters = cloneDeep(this.filters)
      this.selectedResults.emit(this.filteredPolicies.length)
    }
  }

  goToFullReport(policy: Policy, showExceptions = false) {
    if (this.beforeGoToFullReport) {
      this.beforeGoToFullReport(policy)
    }
    this.router
      .navigate([this.prefixUrl + (policy.policyGroupType === 'LegacyPolicy' ? `policy/legacy/${policy.id}` : `policy/${policy.id}`)], {
        relativeTo: this.activatedRoute,
        queryParams: showExceptions ? { showExceptions: 'true' } : {},
      })
      .catch((_: any) => _)
  }

  isPolicyScheduled(policy: Policy) {
    return this.policyService.isPolicyScheduled(policy)
  }

  matchedItemsTitle(policy: Policy): string {
    return `${this.reduceNumber(policy.matchedItemsCount || 0)}`
  }

  matchedExceptionsTitle(policy: Policy): string {
    return `${policy.exceptionItemsCount || 0 < 100 ? policy.exceptionItemsCount || 0 : '99+'}`
  }

  showPolicyDetails(i: number): boolean {
    return (this.policiesDetails[i] = !this.policiesDetails[i])
  }

  openDialog(policy: Policy, reportType: string) {
    this.dialog.open(PolicyDialogComponent, {
      data: {
        policy,
        reportType,
        policyComponent: this,
      },
    })
  }

  openEditPolicyPanel(policy: Policy, patch: any = {}, comp: SwitchComponent | null = null, goToRemediation = false) {
    if (this.checkRoles(['tenantAdmin', 'playbookAdmin'])) {
      //
      this.policyService.openEditPolicyPanel(policy, patch, comp, goToRemediation)
        .subscribe((id) => {
          this.toastService.open({
            id: 'success' + new Date().toUTCString(),
            variant: 'success',
            title: this.translateHelper.instant('playbook_PolicyEditedTitle'),
            message: this.translateHelper.instant('playbook_PolicyEditedMessage'),
          })
          this.loadPolicy(id)
          this.policySaved.emit()
          this.store.dispatch(getPlaybookItems())
        })
    }
  }

  calculateMetrics(policy: Policy) {
    this.playbookService
      .calculateMetrics(policy.id || '')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        () => {
          if (policy.reportUrl && policy.reportUrl.includes('api/onlineusers')) {
            this.dialog.open(DialogComponent, {
              width: '50%',
              data: {
                title: this.translateHelper.instant('common_SaveChange'),
                text: this.translateHelper.instant('playbook_OnlineUsersUpdated'),
                primaryText: this.translateHelper.instant('common_Continue'),
                type: 'success',
                centered: true,
              },
            })
          }
        },
        (err) => {
          if (err.status === 429) {
            const retryAfterSeconds = err.headers.get('Retry-After')
            this.toastService.open({
              id: new Date().toString(),
              variant: 'warning',
              matIcon: 'warning',
              title: this.translateHelper.instant('playbook_PolicyRetryAfterTitle'),
              message: this.translateHelper.instant('playbook_PolicyRetryAfterMessage', {
                mins: dayjs(0).add(retryAfterSeconds, 'seconds').format('mm:ss'),
              }),
            })
          }
        }
      )
  }

  runWorkflow(policy: ExtendedPolicy): void {
    this.dialog
      .open(DialogComponent, {
        width: '50%',
        data: {
          title: this.translateHelper.instant('playbook_RunWorkflow'),
          text: this.translateHelper.instant('playbook_RunWorkflowDescription', {
            workflow: policy.policyDescription?.workflow?.toLocaleLowerCase(),
            count: policy.matchedItemsCount,
          }),
          primaryText: this.translateHelper.instant('common_Proceed'),
          secondaryText: this.translateHelper.instant('common_Cancel'),
          type: 'info',
          centered: false,
        },
      })
      .afterClosed()
      .pipe(
        takeUntil(this.destroyed$),
        filter((x) => !!x),
        switchMap(() => this.playbookService.runPolicyWorkflowAllItems(policy.id || ''))
      )
      .subscribe(() => {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '30%',
          data: {
            title: this.translateHelper.instant('common_WorkFlowIsBeingProcessed'),
            text: this.translateHelper.instant('common_WorkflowRunRemediationAsyncInfo'),
            primaryText: this.translateHelper.instant('common_OkGotIt'),
            type: 'info',
            centered: true,
          },
        })
      })
  }

  load() {
    this.loading$.next(true)
    this.policiesObservable
      .pipe(
        takeUntil(this.destroyed$),
        map((policies) => {
          policies.forEach((p: ExtendedPolicy) => {
            this.policyService.calculateDescriptions(p)
          })
          return policies
        })
      )
      .subscribe((ps) => {
        ps.forEach((p) => {
          const oldPolicy = (this.policies || []).find((x) => x.id === p.id)
          if (oldPolicy) {
            Object.assign(oldPolicy, p)
          } else {
            this.policies.push(p)
          }
        })
        this.policies = this.policies.filter((x) => ps.map((xx) => xx.id).includes(x.id)).sort(this.sortByEnablement)
        this.filteredPolicies = cloneDeep(this.policies)

        this.loading$.next(false)
        this.loaded = true
        this.policiesLoaded.emit()
      })
  }

  patchPolicy(policy: Policy, updatedEnablement: boolean = false) {
    this.policyService.patchPolicy({ ...policy }).subscribe(() => this.loadPolicy(policy.id || '', updatedEnablement))
  }

  disableWorkflow(policy: Policy) {
    this.policyService.patchPolicy({ ...policy, isWorkflowEnabled: false }).subscribe(() => this.loadPolicy(policy.id || '', false))
    this.store.dispatch(getPlaybookItems())
  }

  deletePolicy(policy: Policy) {
    const text = policy.isWorkflowEnabled
      ? this.translateHelper.instant('playbook_removeCustomWithRemediation')
      : this.translateHelper.instant('playbook_removeCustomPlaybookMessage')
    this.dialog
      .open(DialogComponent, {
        width: '30%',
        data: {
          title: this.translateHelper.instant('playbook_removeCustomPlaybookTitle'),
          text,
          primaryText: this.translateHelper.instant('common_Yes'),
          secondaryText: this.translateHelper.instant('common_Cancel'),
          type: 'alert',
          centered: false,
        },
      })
      .afterClosed()
      .pipe(
        filter((x) => !!x),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        ;(policy.policyGroupType === 'LegacyPolicy'
          ? this.playbookService.deleteLegacyPolicy(policy.id || '')
          : this.playbookService.deleteCustomPolicy(policy.id || '')
        ).subscribe(() => {
          this.policies = this.policies.filter((x) => x.id !== policy.id)
          this.applyFilters()
        })
      })
  }

  learnMore = (policy: Policy) => {
    this.learnMoreClicked.emit(policy)
  }

  convertAsTemplate(policyId: string) {
    this.playbookService
      .createTemplateFromPolicy(policyId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        () =>
          this.toastService.open({
            id: 'success',
            variant: 'success',
            title: this.translateHelper.instant('common_Success'),
            message: this.translateHelper.instant('playbook_TemplateCreated'),
          }),
        (error: HttpErrorResponse) =>
          this.dialog.open(DialogComponent, {
            width: '50%',
            data: {
              title: this.translateHelper.instant('common_Error'),
              text: error?.error?.responseStatus?.message,
              primaryText: this.translateHelper.instant('common_Continue'),
              type: 'alert',
              centered: true,
            },
          })
      )
  }

  canConvertAsTemplate() {
    return this.skus?.includes('FT:CREATETEMPLATEFROMPOLICY')
  }

  openWorkflow(policy: ExtendedPolicy): void {
    this.dialog.open(WorkflowPreviewDialogComponent, {
      width: '70%',
      height: '70%',
      data: { workflowId: policy.workflowId, isForPlaybook: true },
    })
  }

  loadPolicy(id: string, updatedEnablement: boolean = false) {
    this.loading$.next(true)
    this.playbookService
      .getPolicy(id, false)
      .pipe(
        map((p) => this.policyService.calculateDescriptions(p)),
        takeUntil(this.destroyed$)
      )
      .subscribe((p) => {
        const oldPolicy = (this.policies || []).find((x) => x.id === p.id)
        if (oldPolicy) {
          if (oldPolicy.oldIsEnabled === undefined) {
            oldPolicy.oldIsEnabled = oldPolicy.isPolicyEnabled
          }
          Object.assign(oldPolicy, p)
        } else {
          this.policies.push(p)
        }
        this.applyFilters()
        this.loading$.next(false)
        this.showToastEnablement(updatedEnablement, p)
      })
  }

  private checkShortPolicies() {
    timer(10).subscribe(() => (this.shortPolicies = this.container.nativeElement.offsetWidth < 800))
  }

  private showToastEnablement(updatedEnablement: boolean, p: ExtendedPolicy) {
    if (updatedEnablement) {
      this.toastService.open({
        id: 'success' + new Date().toUTCString(),
        variant: 'success',
        title: this.translateHelper.instant(p.isPolicyEnabled ? 'playbook_SuccessPolicyEnablement' : 'playbook_SuccessPolicyDisablement'),
        message: this.translateHelper.instant(
          p.isPolicyEnabled ? 'playbook_SuccessPolicyEnablementMessage' : 'playbook_SuccessPolicyDisablementMessage'
        ),
      })
    }
  }

  getPolicyTag(policy: Policy): string {
    switch (policy.policyGroupType) {
      case 'OutOfTheBoxPolicy':
        return 'CoreView'
      case 'CustomPolicy':
        return 'Custom'
      case 'MspPolicy':
        return 'MSP'
      case 'LegacyPolicy':
        return 'Legacy'
      case 'UnknownType':
      default:
        return ''
    }
  }

  checkRoles(roles: string[]) {
    return this.sharedHelperService.checkRoles(roles)
  }

  shouldSeeEditButton(policy: Policy, detailIndex: number) {
    return (
      !this.isMonitoring &&
      policy.policyGroupType !== 'LegacyPolicy' &&
      this.checkRoles(['tenantAdmin', 'playbookAdmin']) &&
      this.skus?.includes(FeatureFlags.editPolicy)
    )
  }

  shouldSeeDeleteButton(policy: Policy) {
    return (
      this.isAdmin && !this.isGovernance && policy.policyGroupType !== 'OutOfTheBoxPolicy' && this.skus?.includes(FeatureFlags.editPolicy)
    )
  }

  private applyFilters() {
    if (!this.filters) {
      this.filteredPolicies = [...this.policies]
      return
    }

    const playbooks = this.filters?.playbooks?.filter((p) => p.isFilterActive === true).map((p) => p.id) || []
    const categoriesIds = this.filters?.categories?.filter((c) => c.isFilterActive === true).map((c) => c.id) || []
    const uncheckStatuses = this.filters?.statuses?.filter((s) => s.isFilterActive === false).map((s) => s.id) || []
    this.filteredPolicies = this.policies.flatMap((policy) => {
      if (
        !playbooks.some((p) => policy.playbookIds?.includes(p)) &&
        !(playbooks.includes('PLAYBOOK_NOT_ASSIGNED') && policy.policyGroupType === 'LegacyPolicy' && policy.playbookIds?.length === 0)
      ) {
        return []
      }
      if (
        policy.policyGroupType !== 'LegacyPolicy' &&
        !categoriesIds.some((c) => (c === 'playbook_NoCategory' && !policy.categoryIds?.length) || policy.categoryIds?.includes(c))
      ) {
        return []
      }
      if (
        this.filters.search !== null &&
        this.filters.search !== '' &&
        !policy.title.toLowerCase().includes(this.filters.search.toLowerCase())
      ) {
        return []
      }
      return this.filterPoliciesByStatuses(uncheckStatuses, policy)
    })
    switch (this.filters?.sort) {
      case 'name_asc': {
        this.filteredPolicies.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
        break
      }
      case 'name_desc': {
        this.filteredPolicies.sort((a, b) => -1 * a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
        break
      }
      case 'create_desc': {
        this.filteredPolicies.sort((a, b) => this.sortDates(a, b, 'creation'))
        break
      }
      case 'update_desc': {
        this.filteredPolicies.sort((a, b) => this.sortDates(a, b, 'update'))
        break
      }
      default:
        break
    }
    this.filteredPolicies.sort(this.sortByEnablement)
  }

  private sortByEnablement(a: ExtendedPolicy, b: ExtendedPolicy) {
    if (
      (a.oldIsEnabled === undefined ? a.isPolicyEnabled : a.oldIsEnabled) &&
      !(b.oldIsEnabled === undefined ? b.isPolicyEnabled : b.oldIsEnabled)
    ) {
      return -1
    } else if (
      !(a.oldIsEnabled === undefined ? a.isPolicyEnabled : a.oldIsEnabled) &&
      (b.oldIsEnabled === undefined ? b.isPolicyEnabled : b.oldIsEnabled)
    ) {
      return 1
    } else {
      return 0
    }
  }

  private filterPoliciesByStatuses(uncheckStatuses: string[], policy: ExtendedPolicy) {
    for (const status of uncheckStatuses) {
      if (this.shouldExcludePolicy(status, policy)) {
        return []
      }
    }
    return policy
  }

  private shouldExcludePolicy(status: string, policy: ExtendedPolicy): boolean {
    switch (status) {
      case 'policy-enable-on':
        return policy.isPolicyEnabled
      case 'policy-enable-off':
        return !policy.isPolicyEnabled
      case 'policy-workflow-on':
        return policy.isWorkflowEnabled === true
      case 'policy-workflow-off':
        return policy.isWorkflowEnabled === false
      case 'policy-threshold-ok':
        return !policy.isThresholdEnabled || !!(policy.isThresholdEnabled && policy.isThresholdExceeded)
      case 'policy-threshold-ko':
        return !policy.isThresholdEnabled || (policy.isThresholdEnabled && !policy.isThresholdExceeded)
      case 'policy-threshold-critical-ko':
        return !policy.isThresholdEnabled || policy.thresholdSeverity !== ThresholdSeverity.Critical || !policy.isThresholdExceeded
      case 'policy-threshold-warning-ko':
        return !policy.isThresholdEnabled || policy.thresholdSeverity !== ThresholdSeverity.Warning || !policy.isThresholdExceeded
      case 'policy-threshold-informational-ko':
        return !policy.isThresholdEnabled || policy.thresholdSeverity !== ThresholdSeverity.Informational || !policy.isThresholdExceeded
      default:
        return false
    }
  }

  private sortDates(a: ExtendedPolicy, b: ExtendedPolicy, type: 'creation' | 'update') {
    const dateA = dayjs(type === 'creation' ? a.creationDateUtc : a.lastModifiedDateUtc).valueOf()
    const dateB = dayjs(type === 'creation' ? b.creationDateUtc : b.lastModifiedDateUtc).valueOf()
    if ((type === 'creation' ? a.creationDateUtc : a.lastModifiedDateUtc) === undefined) {
      return 1
    }
    if ((type === 'creation' ? b.creationDateUtc : b.lastModifiedDateUtc) === undefined) {
      return -1
    }
    if (dateA < dateB) {
      return 1
    }
    if (dateA > dateB) {
      return -1
    }
    return 0
  }

  private reduceNumber(valueNumber: number, fix: number = 1) {
    if (valueNumber < 1000) {
      return valueNumber.toString()
    }

    const suffixes = ['K', 'M', 'G', 'T', 'P', 'E']
    const exp = Math.floor(Math.log(valueNumber) / Math.log(1000))

    valueNumber = valueNumber / Math.pow(1000, exp)

    return (Number.isInteger(valueNumber) ? valueNumber : valueNumber.toFixed(fix)) + ' ' + suffixes[exp - 1]
  }
}

export type ExtendedPolicy = Omit<Policy, 'policyDescription'> & {
  policyDescription?: PolicyDescription & {
    policy?: string
    threshold?: string
    workflow?: string
    scheduling?: string
  }
  oldIsEnabled?: boolean
}

import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { Observable, of } from 'rxjs'
import { SavedReport } from '@app/core/models/saved-report'
import { ReportsService } from '@app/core/services/reports.service'

export const SavedReportResolver: ResolveFn<SavedReport | null> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  reportsService: ReportsService = inject(ReportsService)
): Observable<SavedReport | null> => {
  if (route.queryParams.SavedReportId) {
    return reportsService.getSavedReportById(route.queryParams.SavedReportId)
  } else {
    return of(null)
  }
}

import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from '@app/app.module'
import { loadTranslator } from '@app/app.translation-loader'
import { Configuration } from '@app/core/services/environment-configuration.service'
import { environment } from '@environments/environment'

if (environment.production) {
  enableProdMode()
}



// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function mergeEnvironment(configuration: Configuration) {
  const merge = Object.assign(environment, configuration)
  return merge
}

fetch('/assets/configuration/environment.json')
  .then(response => response.json())
  .then(json => {
    mergeEnvironment(json)

    loadTranslator().subscribe(() => {
      platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err))
    })
  });
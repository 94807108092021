<div class="cv-dialog-div-container">
  <img src="../assets/img/no-permissions.svg" alt="no permission" />
</div>
<div class="message-error">
  <h2>{{ 'generic_NoPermissions' | translate }}</h2>
  <div class="m-t-20 m-b-10" [innerHTML]="'generic_NoPermissionsDescription' | translate"></div>
  <div class="buttons-div">
    <cv-button
      leftIcon="home"
      buttonType="secondary"
      text="{{ 'common_GoToHomepage' | translate }}"
      size="tiny"
      (click)="gotoHome()"
    ></cv-button>
    <cv-button class="m-l-30" leftIcon="close" text="{{ 'Close' | translate }}" size="tiny" (click)="closeDialog()"></cv-button>
  </div>
</div>
<cv-close-button (click)="closeDialog()"></cv-close-button>

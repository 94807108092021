import { UserGroupsEffects } from './userGroups/userGroups.effect'
import { userGroupsReducer } from './userGroups/userGroups.reducer'
import { OrganizationSettingsEffects } from './organizationSettings/organizationSettings.effect'
import { MenuEffects } from './menu/menu.effect'
import { menuReducer } from './menu/menu.reducer'
import { OnlineUserColumnsEffects } from './onlineuser-columns/onlineuser-columns.effect'
import { onlineuserColumnsReducer } from './onlineuser-columns/onlineuser-columns.reducer'
import { DashboardsEffects } from './dashboards/dashboards.effect'
import { dashboardsReducer } from './dashboards/dashboards.reducer'
import { managementActionsReducer } from './management-actions/management-actions.reducer'
import { ManagementActionsEffects } from './management-actions/management-actions.effect'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { environment } from '@environments/environment'
import { OrganizationsEffects } from '@app/store/organizations/organizations.effects'
import { TenantsEffects } from '@app/store/tenants/tenants.effects'
import organizationsReducer from '@app/store/organizations/organizations.reducer'
import tenantsReducer from '@app/store/tenants/tenants.reducer'
import { OperatorsEffects } from './operators/operators.effect'
import operatorsReducer from './operators/operators.reducer'
import reportsDefinitionReducer from './reports/reports-definition.reducer'
import { ReportsDefinitionEffects } from './reports/reports-definition.effect'
import userSettingsReducer from './userSettings/userSettings.reducer'
import { UserSettingsEffects } from './userSettings/userSettings.effect'
import organizationSettingsReducer from './organizationSettings/organizationSettings.reducer'
import messagesReducer from './messages/messages.reducer'
import { ManagementStatusEffects } from './management-status/management-status.effect'
import managementStatusReducer from './management-status/management-status.reducer'
import { ManagementTypeEffects } from './management-type/management-type.effect'
import managementTypeReducer from './management-type/management-type.reducer'
import workflowActionCategoryLookupsReducer from './workflow-action-category-lookups/workflow-action-category-lookups.reducer'
import { WorkflowActionCategoryLookupsEffects } from './workflow-action-category-lookups/workflow-action-category-lookups.effect'
import executionInputsReducer from './execution-inputs/execution-inputs.reducer'
import { PlaybooksEffects } from './playbooks/playbooks.effect'
import { playbookReducer } from './playbooks/playbooks.reducer'
import reportFilterReduce from './report-filters/report-filters.reducer'
import { licensePoolCenterReducer } from './license-pool-center/license-pool-center.reducer'
import { LicensePoolCenterEffects } from './license-pool-center/license-pool-center.effect'
import demoModeReducer from './demo-mode/demo-mode.reducers'
import userCardReducer from './userCard/user-card.reducers'

// reducers
const combinedReducers = {
  organizations: organizationsReducer,
  tenants: tenantsReducer,
  operators: operatorsReducer,
  reportsDefinitions: reportsDefinitionReducer,
  managementActions: managementActionsReducer,
  onlineuserColumns: onlineuserColumnsReducer,
  dashboards: dashboardsReducer,
  userSettings: userSettingsReducer,
  menu: menuReducer,
  organizationSettings: organizationSettingsReducer,
  messages: messagesReducer,
  userGroups: userGroupsReducer,
  managementStatus: managementStatusReducer,
  managementType: managementTypeReducer,
  workflowActionCategoryLookups: workflowActionCategoryLookupsReducer,
  executionInputs: executionInputsReducer,
  playbooks: playbookReducer,
  reportFilters: reportFilterReduce,
  licensePoolCenter: licensePoolCenterReducer,
  demoMode: demoModeReducer,
  userCard: userCardReducer
}

// effects
const availableEffects = [
  OrganizationsEffects,
  TenantsEffects,
  OperatorsEffects,
  ReportsDefinitionEffects,
  ManagementActionsEffects,
  OnlineUserColumnsEffects,
  DashboardsEffects,
  UserSettingsEffects,
  MenuEffects,
  OrganizationSettingsEffects,
  UserGroupsEffects,
  ManagementStatusEffects,
  ManagementTypeEffects,
  WorkflowActionCategoryLookupsEffects,
  PlaybooksEffects,
  LicensePoolCenterEffects
]


const options = environment.production ? {} : {
  runtimeChecks: {
    strictStateImmutability: true,
    strictActionImmutability: true,
    strictStateSerializability: true,
    strictActionSerializability: true,
    strictActionWithinNgZone: true,
    strictActionTypeUniqueness: true,
  }
}
/**
 * The following module imports all the required dependencies to run the application's NgRx global store
 * and declares the involved states and effects (using the `combinedReducers` and `availableEffects` constants).
 * The purpose of this module is to centralise the declarations of reducers and effects.
 * It should be imported into `app.module.ts`
 */
@NgModule({
  imports: [
    StoreModule.forRoot(combinedReducers, options),
    EffectsModule.forRoot(availableEffects),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
})
export class RootStoreModule {}

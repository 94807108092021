import { createFeatureSelector, createSelector } from '@ngrx/store'
import { onlineuserColumnsAdapter } from './onlineuser-columns.reducer'
import { OnlineuserColumnState } from './onlineuser-columns.types'
import { values } from 'lodash-es'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'

const defaultSelectors = onlineuserColumnsAdapter.getSelectors()

export const selectOnlineuserColumnstate = createFeatureSelector<OnlineuserColumnState>('onlineuserColumns')

export const selectOnlineuserColumns = createSelector(
    selectOnlineuserColumnstate,
    state => values(state.entities) as CoreViewColumn[]
)

export const selectOnlineuserColumnsLoading = createSelector(
  selectOnlineuserColumnstate,
  state => state.isFetching
)

export const selectonlineuserColumnsError = createSelector(
    selectOnlineuserColumnstate,
    state => state.error
  )

<mat-chip-listbox style="margin: auto 0" aria-label="items" [selectable]="false" hideSingleSelectionIndicator>
  <mat-chip-option
    style="font-size: 12px"
    *ngFor="let i of items"
    [tooltip]="i.tooltip | translate"
    [hideDelay]="500"
    [color]="getChipColor(i)"
    [selected]="true"
    [disableRipple]="true"
  >
    {{ i.name }}
  </mat-chip-option>
</mat-chip-listbox>

import { createReducer, on } from '@ngrx/store'
import { ManagementTypeState } from './management-type.types'
import { getManagementType, getManagementTypeInvalidate, getManagementTypeResponse, setManagementType } from './management-type.actions'

export const initialState: ManagementTypeState = {
  managementType: null,
  didInvalidate: false,
  isFetching: false,
  error: null,
}

export const managementTypeReducer = createReducer(
  initialState,
  on(getManagementType, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(getManagementTypeResponse, (state, managementType) => ({ ...state, managementType: managementType.data })),
  on(getManagementTypeInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true })),
  on(setManagementType, (state, action) => {
    return {
      ...state,
        managementType: {
          ...state.managementType!,
          managementType: action.value
        }
    }
  })
)

export default managementTypeReducer

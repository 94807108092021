import { UserGroup } from './../../core/models/UserGroup'
import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'

import { UserGroupsState } from './userGroups.types'
import { getUserGroups, getUserGroupsResponse, getUserGroupsInvalidate } from './userGroups.actions'

export const userGroupsAdapter: EntityAdapter<UserGroup> = createEntityAdapter<UserGroup>({
  selectId: ({ id }) => id,
})

export const initialState: UserGroupsState = userGroupsAdapter.getInitialState({
  didInvalidate: false,
  isFetching: false,
  error: null,
  loaded: false,
})

export const userGroupsReducer = createReducer(
  initialState,
  on(getUserGroups, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(getUserGroupsResponse, (state, userGroups) => ({ ...userGroupsAdapter.setAll(userGroups.data, state), loaded: true })),
  on(getUserGroupsInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true, loaded: true }))
)

export default userGroupsReducer

<div attr.cv-data-test="{{ colId }}">
  <select
    [disabled]="filterOptions.length === 1"
    *ngIf="filterOptions && filterOptions.length > 0"
    [(ngModel)]="type"
    (ngModelChange)="value = null; modelChanged()"
    style="
      font-size: 13px;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
      width: 100%;
      margin: auto 0px;
      height: 27.6px;
      border-radius: 5px;
      padding-left: 6px;
      border: 1px solid var(--opaque-light-contrast-color);
      background: var(--default-surface-color);
      color: var(--default-text-color);
    "
    cv-data-test="select-filter-optionns"
    #select
  >
    <option *ngFor="let o of filterOptions" [ngValue]="o.key" translate>{{ o.value }}</option>
  </select>
  <span
    *ngIf="filterOptions && filterOptions.length > 0"
    class="ag-icon ag-icon-small-down"
    style="position: absolute; right: 14px; top: 55px; pointer-events: none"
  ></span>

  <ng-container *ngIf="type && !['isEmpty', 'isNotEmpty', 'contains'].includes(type)">
    <select
      [disabled]="filterOptions.length > 0 && !type"
      [class.disabled]="filterOptions.length > 0 && !type"
      [(ngModel)]="value"
      (ngModelChange)="filterOptions.length === 0 || type ? modelChanged() : null"
      style="
        font-size: 13px;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
        width: 100%;
        margin: auto 0px;
        height: 27.6px;
        border-radius: 5px;
        padding-left: 6px;
        border: 1px solid var(--opaque-light-contrast-color);
        background: var(--default-surface-color);
        color: var(--default-text-color);
      "
      [ngStyle]="{ 'margin-top': filterOptions && filterOptions.length > 0 ? '10px' : '0px' }"
      cv-data-test="select-values"
    >
      <ng-container *ngIf="filterOptions.length === 0 || type">
        <option *ngFor="let option of options" [value]="option.value">{{ option.display }}</option>
      </ng-container>
    </select>
    <span class="ag-icon ag-icon-small-down" style="position: absolute; right: 14px; top: 92px; pointer-events: none"></span>
  </ng-container>

  <ng-container *ngIf="type === 'contains'">
    <input
      style="
        font-size: 13px;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
        width: 100%;
        height: 26px;
        border-radius: 5px;
        border: 1px solid var(--opaque-light-contrast-color);
        margin: 10px 0px auto;
        padding-left: 6px;
        box-sizing: border-box;
        background: var(--default-surface-color);
        color: var(--default-text-color);
      "
      [(ngModel)]="value"
      (input)="onInputBoxChanged()"
      attr.cv-data-test="{{ 'input-value' }}"
    />
  </ng-container>
</div>

import { ICellEditorAngularComp } from '@ag-grid-community/angular'
import { AfterViewInit, Component, HostListener, ViewChild, ViewContainerRef } from '@angular/core'
import { Observable } from 'rxjs'
import { CellCtrl, GridApi, RowNode } from '@ag-grid-community/core'

@Component({
  selector: 'app-persistable-cell-editor',
  templateUrl: './persistable-cell-editor.component.html',
  styleUrls: ['./persistable-cell-editor.component.sass'],
})
export class PersistableCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('input', { read: ViewContainerRef })
  input!: ViewContainerRef

  value!: any
  valueType: 'text' | 'number' = 'text'
  minValue?: number
  maxValue?: number

  submitFunction?: (rowData: any, newValue: any, node: RowNode, api: GridApi) => Observable<boolean>
  submitting = false

  private params: any

  agInit(params: any): void {
    CellCtrl.prototype.onCellFocused = function (event) {
      // overriting cell focused event because for now there is no way to suppress ag-grid
      // default behavior of stopping the edit (and keeping the value) when other cell if focused
      // and in this component we need to only stop editting on cancel/submit clicks
      // Ref: https://www.ag-grid.com/javascript-data-grid/cell-editing-start-stop/#stop-editing
      // Other Cell Focus: If focus in the grid goes to another cell, the editing will stop.
    }

    this.params = params
    this.setInitialState(this.params)
  }

  getValue(): any {
    return this.value
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.element.nativeElement.focus()
    })
  }

  isValid() {
    let minValid = true
    let maxValid = true
    let numberValid = true

    if (this.valueType === 'number') {
      numberValid = this.isCharNumeric(this.value)

      if (this.minValue !== undefined) {
        minValid = Number(this.value) >= this.minValue
      }
      if (this.maxValue !== undefined) {
        maxValid = Number(this.value) <= this.maxValue
      }
    }

    return minValid && maxValid && numberValid
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnterKeydown = (event: KeyboardEvent) => {
    event.stopPropagation()
    this.submit()
  }

  submit() {
    if (!this.submitting && this.isValid()) {
      if (this.submitFunction) {
        this.submitting = true
        this.submitFunction(this.params.data, this.value, this.params.node, this.params.api).subscribe((saveResult: boolean) => {
          if (saveResult) {
            this.params.api.stopEditing()
          } else {
            this.cancel()
          }
          this.submitting = false
        })
      } else {
        this.params.api.stopEditing()
      }
    }
  }

  cancel() {
    this.params.api.stopEditing(true)
  }

  private setInitialState(params: any) {
    this.value = params.value
    this.maxValue = params.getMaxValue instanceof Function ? params.getMaxValue(params.data) : undefined
    this.minValue = params.getMinValue instanceof Function ? params.getMinValue(params.data) : undefined
    if (params.valueType) {
      this.valueType = params.valueType
    }
    if (params.submitFunction instanceof Function) {
      this.submitFunction = params.submitFunction
    }
  }

  private isCharNumeric(charStr: string): boolean {
    return !!/\d/.test(charStr)
  }
}

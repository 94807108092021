import { TranslateHelper, AggregatedAsyncTask, AsyncTaskState } from '@coreview/coreview-library'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map, pluck } from 'rxjs/operators'
import { ApiclientService } from './apiclient.service'

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  constructor(private apiclient: ApiclientService, private httpClient: HttpClient, private translateHelper: TranslateHelper) { }

  getNotificationTasks(
    params: any = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      filters: JSON.stringify({ IsBackground: false }),
      pageSize: 10,
      pageNumber: 1,
      sort: 'StartDate',
      sortOrder: 'desc',
      days: 7,
    }, aggregationdId?: string
  ): Observable<AggregatedAsyncTask[]> {
    return this.httpClient
      .get<any>(`${this.apiclient.basePortalApiUrl}/aggregatedasynctasks/${aggregationdId || ''}?metadata=true`, {
        withCredentials: true,
        params,
      })
      .pipe(
        pluck('aggregatedAsyncTaskList'),
        map((res) => {
          ; (res || []).forEach((x: AggregatedAsyncTask) => {
            if (x.isAggregated) {
              x.state =
                x.total !== (x.totalFailed || 0) + (x.totalFinished || 0)
                  ? AsyncTaskState.Progress
                  : (x.totalFailed || 0) > 0
                    ? AsyncTaskState.Failed
                    : AsyncTaskState.Finished
              if (x.state === AsyncTaskState.Failed) {
                x.shortDescription = this.translateHelper.genericCombineTranslations('common_FailedTasks', {
                  failed: (x.totalFailed || 0).toString(),
                  total: (x.total || 0).toString(),
                })
              }
            }
          })
          return res
        })
      )
  }
}

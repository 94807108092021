<div class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" [class.ag-indeterminate]="indeterminate" [class.ag-checked]="checked">
  <input
    [ngModel]="checked"
    (click)="handleSelectThisPage()"
    class="ag-input-field-input ag-checkbox-input"
    type="checkbox"
    [tooltip]="getSelectedRowsText()"
    placement="bottom"
    [autoPlacement]="false"
    [display]="!!getSelectedRowsText()"
    tooltipClass="select-all-tooltip"
    cv-data-test="select-page-header"
    attr.aria-label="{{ 'common_SelectThisPage' | translate }}"
  />

  <mat-icon
    [matMenuTriggerFor]="menu"
    class="pointer selection-options-icon"
    cv-data-test="selection-actions-trigger"
    aria-label="Row selection options"
    >keyboard_arrow_down</mat-icon
  >
  <mat-menu #menu="matMenu" yPosition="below">
    <ng-container *ngFor="let o of selectionActions">
      <mat-option (click)="handleSelectionActions(o.key)">
        <span attr.aria-label="{{ o.text }}">{{ o.text }}</span>
      </mat-option>
    </ng-container>
    <ng-content></ng-content>
  </mat-menu>
</div>

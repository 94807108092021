/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core'
import { FormControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { FiltersComponent } from '@app/core/components/filters/filters.component'
import { convertToGroupType, GroupType2, TargetEntityAuditType, TargetEntityBaseType, TargetEntityType } from '@app/core/enums/group-type'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { childFilter, QueryFilter, QueryFilter2 } from '@app/core/models/QueryFilter'
import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { ReportsFilters } from '@app/core/models/reports-filters'
import { ServerResponse } from '@app/core/models/ServerResponse'
import { AdministrationsService } from '@app/core/services/administrations.service'
import { ReportsService } from '@app/core/services/reports.service'
import { RightPanelRef } from '@app/core/services/right-panel.service'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { TranslateHelper } from '@coreview/coreview-library'
import { selectOnlineuserColumns } from '@app/store/onlineuser-columns/onlineuser-columns.selectors'
import { selectLoggedOperator } from '@app/store/operators/operators.selectors'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { Suggestion, ToastService } from '@coreview/coreview-components'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators'
import { GroupMemebeshipFilterComponent } from '../group-memebeship-filter/group-memebeship-filter.component'
import { cloneDeep } from 'lodash-es'
import { FieldHelper } from '@app/shared/utilities/field-helper'
import { DialogHelperService } from '@app/shared/dialog-helper.service'

@Component({
  selector: 'app-reports-filters',
  templateUrl: './reports-filters.component.html',
  styleUrls: ['./reports-filters.component.sass'],
})
export class ReportsFiltersComponent implements OnInit, AfterViewInit {
  public static supportedTargetTypes = [
    'User',
    TargetEntityBaseType.DistributionGroup,
    TargetEntityType.Office365Group,
    TargetEntityBaseType.SecurityGroup,
    TargetEntityBaseType.Teams,
    'Audit',
  ]

  @ViewChild(GroupMemebeshipFilterComponent)
  membershipFilterComponent!: GroupMemebeshipFilterComponent

  @ViewChild(DatagridComponent)
  private grid!: DatagridComponent

  readonly = false

  isAdmin = (): boolean => this.sharedHelperService.isAdmin()
  loggedUser$ = this.store.select(selectLoggedOperator)
  userName!: string

  generateActionMenu(data: ReportsFilters) {
    return [
      { key: 'edit', text: this.translateHelper.instant('common_Edit') },
      { key: 'duplicate', text: this.translateHelper.instant('common_Duplicate') },
      { key: 'delete', text: this.translateHelper.instant('common_Delete') },
    ].filter((f) => this.isAdmin() || f.key === 'duplicate' || this.userName === data?.createdBy)
  }

  gridDefinition: ReportDefinition = {
    title: 'notused',
    fields: ['Name', 'TargetEntity', 'CreatedBy', 'IsPublic', 'Actions'],
    defaultHiddenFields: [],
    sortField: 'Name',
    sortOrder: 'asc',
    verb: 'get' as Verb,
    isOnlineUsersType: false,
    responseItemProp: 'reportFilters',
    rowSelection: 'single',
    operationsColumns: [
      {
        type: 'undefined',
        name: 'actions',
        position: 100,
        notSelectable: true,
        translate: 'common_Actions',
        agColDef: {
          headerClass: 'cv-grid-action-header',
          colId: 'actions',
          field: 'actions',
          hide: false,
          sortable: false,
          filter: false,
          resizable: false,
          suppressMovable: true,
          suppressMenu: true,
          suppressColumnsToolPanel: true,
          cellRenderer: 'iconMenuButtonRenderer',
          pinned: 'right',
          cellRendererParams: {
            onClick: (event: any) => {
              this.actionsClickHandler(event.key, event.rowData)
            },
            icon: 'more_horiz',
            optionsFunction: (row: any) => this.generateActionMenu(row),
          },
          cellStyle: () => ({ padding: '0px', 'text-align': 'center', border: 'none !important' }),
        },
      },
    ],
  }

  columns: Suggestion[] = []

  rootFilters!: UntypedFormArray[]

  formControls!: { [k in keyof ReportsFiltersForm]: UntypedFormControl }

  form!: UntypedFormGroup
  groupMembershipChecked = false
  reportsFiltersCheckDisabled = false

  rootCondition!: 'AND' | 'OR' | undefined

  showAndButton = true
  showOrButton = true

  targetEntity!: GroupType2 //  keyof typeof TargetEntityType

  workload!: string
  operations!: string[]

  saveFilterCheck = false
  setAsPublicCheck = false
  newFilterName!: string
  updateFilter = false

  savedFilters!: boolean
  loading = true

  groupTypes = [
    {
      value: GroupType2.DistributionGroup,
      name: 'DistributionGroup',
      displayValue: this.translateHelper.instant('DistributionGroup'),
      valueSuggester: (val: string) =>
        this.reportsService.getGenericSuggestions('distributiongroups', 'distributionGroups', {
          sort: 'PrimarySmtpAddress',
          sortOrder: 'asc',
          searchedString: val,
        }),
      prepareColumns: () => {
        this.reportsFiltersCheckDisabled = true
        this.valueSuggester = (val: any) => this.getFieldValues(val, 'distributiongroup')
        return this.administrationsService.getDistributionGroupFields().pipe(
          take(1),
          tap((fields) => (this.columns = this.fieldHelper.mapFields(fields)))
        )
      },
    },
    {
      value: GroupType2.Office365Group,
      name: 'O365Group',
      displayValue: this.translateHelper.instant('O365Group'),
      valueSuggester: (val: any) =>
        this.reportsService.getGenericSuggestions('office365groups', 'office365Groups', {
          sort: 'DisplayName',
          sortOrder: 'asc',
          searchedString: val,
        }),
      prepareColumns: () => {
        this.reportsFiltersCheckDisabled = true
        this.valueSuggester = (val: any) => this.getFieldValues(val, 'office365Group')
        return this.administrationsService.getOffice365GroupFields().pipe(
          take(1),
          tap((fields) => (this.columns = this.fieldHelper.mapFields(fields)))
        )
      },
    },
    {
      value: GroupType2.SecurityGroup,
      name: 'SecurityGroup',
      displayValue: this.translateHelper.instant('SecurityGroup'),
      valueSuggester: (val: any) =>
        this.reportsService.getGenericSuggestions('securitygroups', 'securityGroups', {
          sort: 'DisplayName',
          sortOrder: 'asc',
          searchedString: val,
        }),
      prepareColumns: () => {
        this.reportsFiltersCheckDisabled = true
        this.valueSuggester = (val: any) => this.getFieldValues(val, 'securityGroup')
        return this.administrationsService.getSecurityGroupFields().pipe(
          take(1),
          tap((fields) => (this.columns = this.fieldHelper.mapFields(fields)))
        )
      },
    },
    {
      value: GroupType2.Teams,
      name: 'Teams',
      displayValue: this.translateHelper.instant('Teams'),
      valueSuggester: (val: any) => this.reportsService.getTeamsGroupSuggestions({ search: val, sort: 'DisplayName', sortOrder: 'asc' }),
      prepareColumns: () => {
        this.valueSuggester = (val: any) => this.getFieldValues(val, 'office365Group')
        return this.administrationsService.getTeamsGroupFields().pipe(
          take(1),
          tap((fields) => (this.columns = this.fieldHelper.mapFields(fields)))
        )
      },
    },
  ]

  targetTypes: (Suggestion & { name: string; prepareColumns: () => void })[] = [
    {
      value: 'User',
      name: 'User',
      displayValue: this.translateHelper.instant('onLineUsers'),
      prepareColumns: () => {
        this.reportsFiltersCheckDisabled = false
        this.valueSuggester = (val: any) => this.administrationsService.getFieldsValues('onlineusers', val?.fieldName, val?.value)
        return this.store.select(selectOnlineuserColumns).pipe(
          filter((x) => !!x),
          take(1),
          tap((columns) => {
            this.columns = columns
              .map((x) => ({ value: x.originalName, displayValue: this.translateHelper.instant(x.translate), data: { type: x.type }}))
              .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
          })
        )
      },
    },
    {
      value: 'Audit',
      name: 'Audit',
      displayValue: this.translateHelper.instant('Audit'),
      prepareColumns: () => {
        this.reportsFiltersCheckDisabled = true
        return this.administrationsService.getAlertConfigurations(this.workload, this.operations).pipe(
          take(1),
          tap(
            (data) =>
              (this.columns = data.alertNotificationConfiguration.availableAlertFields.map((x: any) => ({
                value: x.name,
                displayValue: this.translateHelper.instant(x.translation),
                data: { type: x.type },
              })))
          )
        )
      },
    },
    ...this.groupTypes,
  ]

  reportFilterToEdit!: ReportsFilters

  constructor(
    private administrationsService: AdministrationsService,
    private reportsService: ReportsService,
    private translateHelper: TranslateHelper,
    private sharedHelperService: SharedHelperService,
    private dialogHelperService: DialogHelperService,
    private rightPanelRef: RightPanelRef,
    private store: Store<RootState>,
    private toastService: ToastService,
    private fieldHelper: FieldHelper,
  ) {
    this.loggedUser$.subscribe((n) => (this.userName = n?.userName!))
  }

  getFieldValues = (val: any, caller: string) => this.administrationsService.getFieldsValues(caller, val?.fieldName, val?.value)

  valueSuggester: (value: any) => any = () => {}

  ngOnInit(): void {
    this.formControls = {
      targetEntity: new UntypedFormControl(),
      rootFilters: new UntypedFormControl(),
      reportsFiltersChecked: new FormControl<boolean>(false),
    } as { [k in keyof ReportsFiltersForm]: UntypedFormControl }
    this.form = new UntypedFormGroup(this.formControls)
  }

  getItems = (params: any): any => {
    const p = {
      ...params,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      filters: JSON.stringify({ TargetEntity: `=${this.targetEntity}`, ...(JSON.parse(params.filters || '""') as Record<string, string>) }),
    }
    delete p.reportTreeFilters
    delete p.membershipReportFilters
    const reportFilterNotSaved = () =>
      !!this.reportsService.reportFilter &&
      !this.reportsService.reportFilter.id &&
      (!!this.reportsService.reportFilter.reportTreeFilters || !!this.reportsService.reportFilter.membershipReportFilters)
    const collectionEmpty = (response: ServerResponse<any>) => !!response.reportFilters && response.reportFilters.length === 0
    const filterApplied = !!params.filters
    return {
      items: this.reportsService.getReportsFilters(p).pipe(
        map((response) => {
          if (reportFilterNotSaved()) {
            const reportFilter = cloneDeep(this.reportsService.reportFilter)
            this.fillForm(reportFilter)
          } else if (collectionEmpty(response) && !filterApplied) {
            this.createNewClicked()
          } else {
            this.savedFilters = true
          }
          this.loading = false
          return response
        }),
        catchError((err) => {
          this.savedFilters = false
          this.loading = false
          return of(err)
        })
      ),
    }
  }

  ngAfterViewInit(): void {
    this.grid?.pageDataChanged.subscribe(() => {
      if (!!this.reportsService.reportFilter) {
        this.grid.gridApi?.forEachNode((node) => {
          node.setSelected(this.reportsService.reportFilter?.id === node.data?.id)
        })
      }
    })
  }

  fillForm(reportFilter?: ReportsFilters) {
    this.targetTypeSelected(this.targetEntity).subscribe((_) => {
      this.membershipFilterComponent?.patchValue(reportFilter?.membershipReportFilters)

      this.groupMembershipChecked = !!reportFilter?.membershipReportFilters

      const reportTreeFilters = reportFilter?.reportTreeFilters as any
      if (reportFilter && reportTreeFilters?.queryFilter && reportTreeFilters?.operation.toUpperCase() === 'NONE') {
        reportFilter.reportTreeFilters = {
          operation: 'AND',
          children: [reportTreeFilters],
        }
      }

      const fReports = reportFilter?.reportTreeFilters?.children?.map((c: childFilter) => {
        if (c.queryFilter && c.operation?.toUpperCase() === 'NONE') {
          c = {
            operation: 'AND',
            children: [c],
          }
        }

        this.form.controls.reportsFiltersChecked.setValue(true)
        this.rootCondition =
          reportFilter?.reportTreeFilters?.children?.length! > 1
            ? (reportFilter?.reportTreeFilters?.operation.toUpperCase() as 'AND' | 'OR')
            : undefined
        this.setRootCondition(this.rootCondition)
        return new UntypedFormArray(
          c.children?.map(
            (q: childFilter) => {
              const { value, valueDate } = q.queryFilter || {}
              return new UntypedFormGroup({
                condition: new UntypedFormControl(c.children?.length === 1 ? 'None' : c.operation?.toUpperCase()),
                operation: new UntypedFormControl(q.queryFilter?.operation, Validators.required),
                name: new UntypedFormControl(q.queryFilter?.name, Validators.required),
                value: new UntypedFormControl(value, Validators.required),
                nameObj: new UntypedFormControl(
                  this.columns.find((f) => f.value === q.queryFilter?.name),
                  Validators.required
                ),
                valueObj: new UntypedFormControl({ value, displayValue: value }, Validators.required),
                valueDate: new UntypedFormControl(valueDate, Validators.required),
              })
            }
          ) || []
        )
      })
      this.rootFilters = fReports || [new UntypedFormArray([FiltersComponent.addNewFilterRow('None', this.columns)])]
      this.form.controls.targetEntity.patchValue(this.targetEntity)
      this.form.controls.rootFilters.patchValue(this.rootFilters)
    })
  }

  targetTypeSelected(targetEntity: GroupType2) {
    const te = TargetEntityBaseType[targetEntity as keyof typeof TargetEntityBaseType]
                || TargetEntityType[targetEntity as unknown as keyof typeof TargetEntityType]
                || TargetEntityAuditType[targetEntity as keyof typeof TargetEntityAuditType]
    this.targetEntity = convertToGroupType(te)
    return this.targetTypes.find((f) => f.value === this.targetEntity)?.prepareColumns() || of()
  }

  andClicked() {
    this.showOrButton = false
    this.rootCondition = 'AND'
    this.rootFilters.push(new UntypedFormArray([FiltersComponent.addNewFilterRow('None', this.columns)]))
  }

  orClicked() {
    this.showAndButton = false
    this.rootCondition = 'OR'
    this.rootFilters.push(new UntypedFormArray([FiltersComponent.addNewFilterRow('None', this.columns)]))
  }

  setRootCondition(condition?: string) {
    if (!!condition) {
      if (condition.toUpperCase() === 'AND') {
        this.showAndButton = true
        this.showOrButton = false
      } else {
        this.showAndButton = false
        this.showOrButton = true
      }
    }
  }

  mapFilter(): QueryFilter2 {
    return {
      operation: this.rootCondition?.toUpperCase() || 'AND',
      children: (this.form.value.rootFilters as UntypedFormArray[]).flatMap((v: any) => {
        const operation = !!v.value[1] ? v.value[1].condition : v.value[0].condition
        return {
          operation: operation === 'None' ? 'AND' : operation.toUpperCase(),
          children: v.value.map((f: QueryFilter) => ({
            queryFilter: { operation: f.operation, name: f.name, value: f.value, valueDate: f.valueDate },
          })),
        }
      }),
    } as QueryFilter2
  }

  formReportsFiltersInvalid() {
    const fInv = !!this.form && ((this.form.value.rootFilters as UntypedFormArray[])?.length <= 0 || !!(this.form.value.rootFilters as UntypedFormArray[])?.reduce((p, c) => (p = c.invalid), false))
    return !(this.form.controls.reportsFiltersChecked.value && !fInv)
  }

  formMembershipInvalid() {
    return !!this.membershipFilterComponent && this.membershipFilterComponent.invalid()
  }

  formInvalid() {
    return (
      (!!this.form.controls.reportsFiltersChecked.value && this.formReportsFiltersInvalid()) ||
      (!!this.groupMembershipChecked && this.formMembershipInvalid()) ||
      (!this.groupMembershipChecked && !this.form.controls.reportsFiltersChecked.value)
    )
  }

  filterGroupMembership() {
    this.groupMembershipChecked = !this.groupMembershipChecked
  }

  filterRemoved = (index: number) => {
    if (!!this.rootFilters.length && !!this.rootFilters[index] && this.rootFilters[index].controls.length === 0) {
      this.rootFilters.splice(index, 1)
    }
    if (this.rootFilters.length === 1) {
      this.showAndButton = true
      this.showOrButton = true
      this.rootCondition = undefined
    }
  }

  createNewClicked() {
    this.form.controls.reportsFiltersChecked.setValue(true)
    this.fillForm()
    this.savedFilters = false
  }

  applyClicked() {
    this.reportsService.reportFilter = this.prepareReportFilter()
    this.rightPanelRef.close({ saveFilter: this.saveFilterCheck, sessionFilter: this.targetEntity === 'User', update: this.updateFilter })
  }

  applySelectedClicked() {
    const selected = this.grid.getSelectedRows()
    this.reportsService.reportFilter = selected.length > 0 ? selected[0] : undefined
    this.rightPanelRef.close({ saveFilter: false, sessionFilter: this.reportsService.reportFilter?.targetEntity === 'User' })
  }

  isApplyDisabled(): boolean {
    const selected = this.grid.getSelectedRows()
    return !selected?.length
  }

  cancelClicked() {
    this.rightPanelRef.close({ cancel: true })
  }

  actionsClickHandler(eventKey: string, rowData: ReportsFilters) {
    if (eventKey === 'delete') {
      this.dialogHelperService
        .openDeleteDialog(false, 'reports_ReportFiltersTitle', rowData.name)
        .pipe(
          filter((x) => x),
          switchMap(() => this.reportsService.deleteFiltersReports(rowData.id))
        )
        .subscribe(
          () => {
            this.grid.refresh()
            this.toastService.open({
              id: 'success',
              variant: 'success',
              title: this.translateHelper.instant('common_Success'),
              message: this.translateHelper.combineTranslations(
                'generic_ObjectSuccessfullyRemoved',
                'reports_ReportFiltersTitle',
                rowData.name
              ),
            })
          },
          (err) => {
            this.toastService.open({
              id: 'err',
              variant: 'error',
              title: this.translateHelper.instant('common_Error'),
              message: this.translateHelper.combineTranslations(
                'generic_ObjectCouldNotBeRemoved',
                'reports_ReportFiltersTitle',
                rowData.name
              ),
            })
          }
        )
    } else if (eventKey === 'duplicate') {
      this.prepareForEdit(rowData, this.translateHelper.instant('common_CopyOf') + ' ', false)
    } else if (eventKey === 'edit') {
      this.prepareForEdit(rowData, '', true)
    }
  }

  private prepareForEdit(rowData: ReportsFilters, prefix: string, update: boolean) {
    this.reportFilterToEdit = cloneDeep(rowData)
    this.reportFilterToEdit.name = `${prefix}${this.reportFilterToEdit.name}`
    this.newFilterName = this.reportFilterToEdit.name
    this.saveFilterCheck = true
    this.savedFilters = false
    this.setAsPublicCheck = !!this.reportFilterToEdit.isPublic && this.isAdmin()
    this.updateFilter = update
    this.fillForm(this.reportFilterToEdit)
  }

  private prepareReportFilter() {
    const qf = !this.form.controls.reportsFiltersChecked.value || this.formReportsFiltersInvalid() ? undefined : this.mapFilter()
    const params: ReportsFilters = {
      id: this.reportFilterToEdit?.id,
      name: this.newFilterName,
      isPublic: this.setAsPublicCheck,
      targetEntity: this.targetEntity,
      reportTreeFilters: qf,
      membershipReportFilters:
        this.formMembershipInvalid() || !this.membershipFilterComponent ? undefined : this.membershipFilterComponent.getValue(),
    }
    return params
  }
}

export type ReportsFiltersForm = {
  targetEntity: string;
  rootFilters: string;
  reportsFiltersChecked: boolean;
}

/* eslint-disable @typescript-eslint/naming-convention */

export const WF_DEFINITION_TYPES = {
  workflow: 'workflows',
  template: 'templates'
}

export type WorkflowFlowDefinitionTypes = 'workflows' | 'templates'

export const WF_TEMPLATE_TYPES = {
  custom: 'custom',
  coreView: 'coreView'
}

export const WF_ACTION_VIEW_TYPES = {
  providerSelector: 'providerSelector',
  categorySelector: 'categorySelector',
  actionSelector: 'actionSelector',
  settingsConfig: 'settingsConfig',

  filterConfig: 'filterConfig'
}

export const WF_ACTIONS_PROPERTIES = {
  actions: 'actions',
  catchActions: 'catchActions'
}

export const WF_NAMESPACE_DISCLAIMER_MAP = {
  'Management|Forward365.Common.Messages.Commands.Management.CreateEquipmentMailbox' : 'DelegatesBookingRequestDisclaimer',
  'Management|Forward365.Common.Messages.Commands.Management.CreateRoomMailbox' : 'DelegatesBookingRequestDisclaimer',
  'Management|Forward365.Common.Messages.Commands.Management.AssignServicePlans' : 'ManageServicePlansWithRequiredSKUsDisclaimer',
  'item::stop': 'StopActionDisclaimer',
}

export const WF_ERROR_MESSAGE_INPUT_NAME = 'Workflow_ErrorMessage'

export const WF_REPORT_EXECUTION_TYPES = {
  byTarget: 'reports_WithWorkflow',
  byCollectionName: 'reports_WithWorkflow_CollectionName',
  byApi: 'reports_WithWorkflow_Api'
};

import { Component, Input, OnInit } from '@angular/core';
import { AdministrationsService } from '@app/core/services/administrations.service';
import { LocalstorageService } from '@app/core/services/localstorage.service';
import { RightPanelRef } from '@app/core/services/right-panel.service';
import { Helpers } from '@app/shared/utilities/helpers';

@Component({
  selector: 'app-duplicated-plans-advanced-filters',
  templateUrl: './duplicated-plans-advanced-filters.component.html',
  styleUrls: ['./duplicated-plans-advanced-filters.component.sass']
})
export class DuplicatedPlansAdvancedFiltersComponent implements OnInit {
  @Input() configuration!: { filters?: any; aggregations: any[]; licenseAggregationFilter?: string; skuAggregationFilter?: string }

  skusList: any[] = []
  plansList: any[] = []

  constructor(
    private rightPanelRef: RightPanelRef,
    private administrationsService: AdministrationsService,
    public storage: LocalstorageService
  ) { }

  ngOnInit(): void {
    this.setPlansList(this.configuration.aggregations);
    this.setSkusList(this.configuration.aggregations);
  }

  clickPlan(plan: any) {
    if (this.configuration.licenseAggregationFilter === plan.key) {
      delete this.configuration.licenseAggregationFilter
    }
    else {
      this.configuration.licenseAggregationFilter = plan.key
    }

    this.reloadPlansSkus();
  };

  clickSku(sku: any) {
    if (this.configuration.skuAggregationFilter === sku.key) {
      delete this.configuration.skuAggregationFilter
    }
    else {
      this.configuration.skuAggregationFilter = sku.key
    }

    this.reloadPlansSkus();
  }

  capitalize(str: string) {
    return Helpers.capitalize(str)
  }

  reloadPlansSkus = () => {
    const params: any = {
      licenseAggregationFilter: this.configuration.licenseAggregationFilter,
      skuAggregationFilter: this.configuration.skuAggregationFilter,
      filters: this.configuration.filters
    }

    this.administrationsService.getOnlineUsersByDuplicatedPlans(params, this.storage.selectedOrganization?.id).subscribe((result: any) => {
      this.setPlansList(result.aggregations);
      this.setSkusList(result.aggregations);
    })
  }

  setPlansList(aggregations: any) {
    this.plansList = aggregations.find((a: any) => a.key === 'ServicePlan').items
      .filter((p: any) => this.configuration.licenseAggregationFilter ? p.key === this.configuration.licenseAggregationFilter : true);
  }

  setSkusList(aggregations: any) {
    this.skusList = aggregations.find((a: any) => a.key === 'Skus').items
      .filter((p: any) => this.configuration.skuAggregationFilter ? p.key === this.configuration.skuAggregationFilter : true);
  }

  close() {
    this.rightPanelRef.close()
  }

  submit() {
    this.rightPanelRef.close({
      ...this.configuration
    })
  }
}

/* eslint-disable @typescript-eslint/naming-convention */

import { GridOptions } from '@ag-grid-community/core'
import { Injectable } from '@angular/core'
import { OperationsOnCols, SelectionAction, SelectionActionOptions } from '@app/core/models/ReportDefinition'
import { ReportsService } from '@app/core/services/reports.service'
import { Helpers } from '@app/shared/utilities/helpers'
import { map } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class LicenseHelper {
  constructor(private reportsService: ReportsService) {}

  getFields(
    displayBy: string,
    isClientTable: boolean = false
  ): { col: { text: string; key: string }; headerName?: string; field: string; sort?: string }[] {
    let fields = []
    const fixedFields: { col: { key: string; text: string }; headerName?: string; field: string; sort?: string }[] = [
      { field: 'name', col: { text: 'Name', key: 'name' }, sort: 'asc' },
    ]

    if (!isClientTable) {
      fixedFields.push(
        { field: 'users', col: { text: 'TotalUsers', key: 'totalUsers' }},
        { field: 'unlicensedUsers', col: { text: 'WithoutPrimaryLicenseUsers', key: 'withoutPrimaryLicenseUsers' }}
      )
    }

    switch (displayBy) {
      case 'Currency':
        fields = fixedFields.concat([
          {
            headerName: 'LicensedUsersCost',
            field: 'licensedUsers',
            col: { text: 'WithPrimaryLicenseAccountsCost', key: 'withPrimaryLicenseAccountsCost' },
          },
          {
            headerName: 'DisabledLicensedUsersCost',
            field: 'disabledLicensedUsers',
            col: { text: 'DisabledLicensedAccountsCost', key: 'disabledLicensedAccountsCost' },
          },
          {
            headerName: 'InactiveLicensedUsersCost',
            field: 'inactiveLicensedUsers',
            col: { text: 'InactiveLicensedAccountsCost', key: 'inactiveLicensedAccountsCost' },
          },
          {
            headerName: 'OverLicensedUsersCost',
            field: 'overLicensedUsers',
            col: { text: 'OverLicensedAccountsCost', key: 'overLicensedAccountsCost' },
          },
          { field: 'Currency', col: { text: 'Currency', key: 'currency' }},
        ])
        break
      case 'Percentage':
        fields = fixedFields.concat([
          {
            headerName: 'LicensedUsersPercentage',
            field: 'licensedUsers',
            col: { text: 'WithPrimaryLicenseAccountsPercentage', key: 'withPrimaryLicenseAccountsPercentage' },
          },
          {
            headerName: 'DisabledLicensedUsersPercentage',
            field: 'disabledLicensedUsers',
            col: { text: 'DisabledLicensedAccountsPercentage', key: 'disabledLicensedAccountsPercentage' },
          },
          {
            headerName: 'InactiveLicensedUsersPercentage',
            field: 'inactiveLicensedUsers',
            col: { text: 'InactiveLicensedAccountsPercentage', key: 'inactiveLicensedAccountsPercentage' },
          },
          {
            headerName: 'OverLicensedUsersPercentage',
            field: 'overLicensedUsers',
            col: { text: 'OverLicensedAccountsPercentage', key: 'overLicensedAccountsPercentage' },
          },
        ])
        break
      case 'Number':
      default:
        fields = fixedFields
          .concat([
            {
              headerName: 'LicensedUsers',
              field: 'licensedUsers',
              col: { text: 'WithPrimaryLicenseUsers', key: 'withPrimaryLicenseUsers' },
            },
            {
              headerName: 'DisabledLicensedUsers',
              field: 'disabledLicensedUsers',
              col: { text: 'DisabledLicensedUsers', key: 'disabledLicensedUsers' },
            },
            {
              headerName: 'InactiveLicensedUsers',
              field: 'inactiveLicensedUsers',
              col: { text: 'InactiveLicensedUsers', key: 'inactiveLicensedUsers' },
            },
            { headerName: 'OverLicensedUsers', field: 'overLicensedUsers', col: { text: 'OverLicensedUsers', key: 'overLicensedUsers' }},
          ])
          .concat(
            isClientTable
              ? [
                  { headerName: 'TotalUsers', field: 'users', col: { text: 'TotalUsers', key: 'totalUsers' }},
                  {
                    headerName: 'UnlicensedUsers',
                    field: 'unlicensedUsers',
                    col: { text: 'WithoutPrimaryLicenseUsers', key: 'withoutPrimaryLicenseUsers' },
                  },
                ]
              : []
          )
        break
    }

    return fields
  }

  getColumDefs(
    fields: { headerName?: string; field: string; col: { key: string; text: string }; sort?: string }[],
    snapshotToView: string,
    snapshotToCompare: string,
    displayBy: string
  ) {
    const columnDefs: any[] = []

    fields.forEach((f: { headerName?: string; field: string; col: { key: string; text: string }; sort?: string }) => {
      if (!!f.headerName) {
        columnDefs.push({
          headerName: f.headerName,
          children: [
            {
              field: f.field + '_' + snapshotToCompare,
              headerName: snapshotToCompare,
              headerClass: 'total-users-first',
              cellClass: 'total-users-first',
              filter: 'agNumberColumnFilter',
              valueFormatter: (params: any) => (displayBy === 'Number' ? params.value : params.value.toFixed(2)),
              suppressMenu: true,
              aggFunc: 'sum',
            },
            {
              field: f.field + '_' + snapshotToView,
              headerName: snapshotToView,
              headerClass: 'total-users-second',
              cellClass: 'total-users-second',
              filter: 'agNumberColumnFilter',
              valueFormatter: (params: any) => (displayBy === 'Number' ? params.value : params.value.toFixed(2)),
              suppressMenu: true,
              aggFunc: 'sum',
            },
            {
              field: f.field + '_delta',
              headerName: 'Delta',
              headerClass: 'total-users-third',
              cellClass: 'total-users-third',
              filter: 'agNumberColumnFilter',
              valueFormatter: (params: any) => (displayBy === 'Number' ? params.value : params.value.toFixed(2)),
              suppressMenu: true,
              aggFunc: 'sum',
            },
          ],
        })
      } else {
        columnDefs.push({
          field: f.field,
          filter: 'agTextColumnFilter',
          type: 'string',
          sort: f.sort,
          suppressMenu: true,
        })
      }
    })

    return columnDefs
  }

  buildData(data: {
    toView: { licensePoolSavingOpportunities: any[] };
    toCompare: { licensePoolSavingOpportunities: any[] };
    delta: { licensePoolSavingOpportunities: any[] };
    fields: { headerName?: string; field: string; col: { key: string; text: string }; sort?: string }[];
    snapshotToView: string;
    snapshotToCompare: string;
  }) {
    const rows: any = []

    data?.toView?.licensePoolSavingOpportunities?.forEach((r: any) => {
      const myrow: any = {}

      data.fields.forEach((f: { headerName?: string; field: string; col: { key: string; text: string }; sort?: string }, i: number) => {
        if (f.headerName) {
          myrow[f.field + '_' + data.snapshotToView] = r[f.col.key]
        } else {
          myrow[f.field] = r[f.col.key]
        }
      })

      rows.push(myrow)
    })

    data?.toCompare?.licensePoolSavingOpportunities?.forEach((r: any) => {
      let myrow: any = {}

      data.fields.forEach((f: { headerName?: string; field: string; col: { key: string; text: string }; sort?: string }, i: number) => {
        if (i === 0) {
          myrow = rows.find((row: any) => row[f.col.key] === r[f.col.key])
        } else {
          if (!!myrow) {
            if (f.headerName) {
              myrow[f.field + '_' + data.snapshotToCompare] = r[f.col.key]
            } else {
              myrow[f.field] = r[f.col.key]
            }
          }
        }
      })
    })

    data?.delta?.licensePoolSavingOpportunities?.forEach((r: any) => {
      let myrow: any = {}

      data.fields.forEach((f: { headerName?: string; field: string; col: { key: string; text: string }; sort?: string }, i: number) => {
        if (i === 0) {
          myrow = rows.find((row: any) => row[f.col.key] === r[f.col.key])
        } else {
          if (!!myrow) {
            if (f.headerName) {
              myrow[f.field + '_delta'] = r[f.col.key]
            } else {
              myrow[f.field] = r[f.col.key]
            }
          }
        }
      })
    })

    return rows
  }

  configureOptions(card: any, key: string, filter: string, filterToCheck: string): void {
    const options = card.reportDefinition.filterActions.find((f: SelectionAction) => f.text === filter).options

    options.forEach((o: SelectionActionOptions) => {
      o.isSelected = o.key === key
    })

    const optionsToDisable = card.reportDefinition.filterActions.find((f: SelectionAction) => f.text === filterToCheck).options

    optionsToDisable.forEach((o: SelectionActionOptions) => {
      o.isDisabled = o.text === Helpers.toYYYYMM(new Date(key))
    })
  }

  getItemsCompare(card: any, gridOptionsSnapshots: GridOptions): any {
    const snapshotToCompare = Helpers.toYYYYMM(new Date(card.reportDefinition.urlParameters.source))
    const snapshotToView = Helpers.toYYYYMM(new Date(card.reportDefinition.urlParameters.target))

    const displayBy = card.reportDefinition.urlParameters.displayBy
    const fields = this.getFields(displayBy, true)

    gridOptionsSnapshots.api?.setColumnDefs([])
    gridOptionsSnapshots.api?.setColumnDefs(this.getColumDefs(fields, snapshotToView, snapshotToCompare, displayBy))

    return this.reportsService.getData(card.reportDefinition.url, 'get', card.reportDefinition.urlParameters).pipe(
      map((data: any) => {
        if (data.errorCode === 'NoContent') {
          return data
        }
        return this.buildData({
          toView: data.target as any,
          toCompare: data.source as any,
          delta: data.delta as any,
          fields,
          snapshotToView,
          snapshotToCompare,
        })
      })
    )
  }

  getSavingsOperationsOnCols(): OperationsOnCols[] {
    return [
      {
        name: 'withoutPrimaryLicenseUsers',
        field: 'withoutPrimaryLicenseUsers',
        param: '/reports/users/users',
        props: {
          LicensePoolGroupName: (data: any, queryParams: any) => {
            if (data.name !== 'TOTAL') {
              queryParams.LicensePoolGroupName = '=' + data.name
            }
            return queryParams
          },
        },
        fixedQueryParams: {
          PrimaryLicensesCount: '0',
        },
        type: 'link',
        external: false,
      },
      {
        name: 'withPrimaryLicenseUsers',
        field: 'withPrimaryLicenseUsers',
        param: '/reports/users/users',
        props: {
          LicensePoolGroupName: (data: any, queryParams: any) => {
            if (data.name !== 'TOTAL') {
              queryParams.LicensePoolGroupName = '=' + data.name
            }
            return queryParams
          },
        },
        fixedQueryParams: {
          PrimaryLicensesCount: '>0',
        },
        type: 'link',
        external: false,
      },
      {
        name: 'disabledLicensedUsers',
        field: 'disabledLicensedUsers',
        param: '/reports/users/users',
        props: {
          LicensePoolGroupName: (data: any, queryParams: any) => {
            if (data.name !== 'TOTAL') {
              queryParams.LicensePoolGroupName = '=' + data.name
            }
            return queryParams
          },
        },
        fixedQueryParams: {
          PrimaryLicensesCount: '>0',
          BlockCredential: 'true',
        },
        type: 'link',
        external: false,
      },
      {
        name: 'inactiveLicensedUsers',
        field: 'inactiveLicensedUsers',
        param: '/reports/users/users',
        props: {
          LicensePoolGroupName: (data: any, queryParams: any) => {
            if (data.name !== 'TOTAL') {
              queryParams.LicensePoolGroupName = '=' + data.name
            }
            return queryParams
          },
        },
        fixedQueryParams: {
          PrimaryLicensesCount: '>0',
          BlockCredential: 'false',
          IsActive90: 'false',
        },
        type: 'link',
        external: false,
      },
      {
        name: 'overLicensedUsers',
        field: 'overLicensedUsers',
        param: '/reports/users/users',
        props: {
          LicensePoolGroupName: (data: any, queryParams: any) => {
            if (data.name !== 'TOTAL') {
              queryParams.LicensePoolGroupName = '=' + data.name
            }
            return queryParams
          },
        },
        fixedQueryParams: {
          PrimaryLicensesCount: '>1',
          BlockCredential: 'false',
          IsActive90: 'true',
        },
        type: 'link',
        external: false,
      },
    ]
  }

  getServiceName(msServiceName: string, servicePlans: { msServiceName: string; serviceName: string }[]): string {
    return servicePlans.find((x) => x.msServiceName === msServiceName)?.serviceName || msServiceName
  }

  getLicensesOperationsOnCols(): any[] {
    return [
      {
        type: 'link',
        field: 'userPrincipalName',
        param: 'usercard',
      }
    ]
  }

  getLicenseByUserOperationsOnCols(): any[] {
    return [
      {
        type: 'link',
        field: 'userPrincipalName',
        param: 'usercard',
      }
    ]
  }

  getServiceUsageOperationsOnCols(): any[] {
    return [
      {
        type: 'link',
        field: 'userPrincipalName',
        param: 'usercard',
      }
    ]
  }

}

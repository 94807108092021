import { ColDef, ColGroupDef, GridOptions } from '@ag-grid-community/core'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { UserSettings } from '@coreview/coreview-library/models/user-settings'
import { AdministrationsService } from '@app/core/services/administrations.service'
import { ApplicationInsightService } from '@app/core/services/application-insight.service'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { ReportsService } from '@app/core/services/reports.service'
import { SuggesterService } from '@app/core/services/suggester.service'
import { Constants } from '@app/shared/utilities/constants'
import { Helpers } from '@app/shared/utilities/helpers'
import { TranslateHelper } from '@coreview/coreview-library'
import { selectLastMessage } from '@app/store/messages/messages.selectors'
import { RootState } from '@app/store/RootState.type'
import { selectUserSettings } from '@app/store/userSettings/userSettings.selectors'
import { Store } from '@ngrx/store'
import { Suggestion, ToastService } from '@coreview/coreview-components'
import dayjs from 'dayjs'
import { Observable, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators'
import { ReportsComponent } from '../../reports.component'
import { ReportField, ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper'


@Component({
  selector: 'app-message-trace',
  templateUrl: './message-trace.component.html',
  styleUrls: ['./message-trace.component.sass'],
})
export class MessageTraceComponent implements OnInit, OnDestroy {
  @ViewChild(ReportsComponent) reportsComponent!: ReportsComponent

  userSettings$!: Observable<UserSettings | undefined>
  userSettings?: UserSettings
  localeIso!: string
  defaultRange: 0 | 1 | 60 | 3 | 7 | 14 | 30 | 90 | 180 = 1
  rangeOptions: (1 | 3 | 7 | 14 | 30 | 60 | 90 | 180 | 0)[] = [1, 3, 7]
  minDate: any = dayjs.utc().add(-9, 'days').toISOString()
  maxDate: any = dayjs.utc().toISOString()

  public readonly pattern = /^[^@]*$/

  configuration!: {
    gridOptions: GridOptions
    columnDefs?: (ColDef | ColGroupDef)[]
    getParameters: () => any
    isRowMaster: () => boolean
    leftPanel?: string
    rightPanel?: string
  }

  filters!: {
    startDate: dayjs.Dayjs
    endDate: dayjs.Dayjs
    status?: string
    subject?: string
    senderEmail?: string
    receiverEmail?: string
  }

  statuses = ['Failed', 'Pending', 'Delivered', 'Expanded', 'Quarantined', 'FilteredAsSpam']

  error!: string | undefined
  resetInProgress!: boolean

  form = new UntypedFormGroup({
    sender: new UntypedFormControl(),
    senderDomain: new UntypedFormControl('', Validators.pattern(this.pattern)),
    recipient: new UntypedFormControl(''),
    recipientDomain: new UntypedFormControl('', Validators.pattern(this.pattern)),
    status: new UntypedFormControl(),
    subject: new UntypedFormControl(),
    startDate: new UntypedFormControl('', [Validators.required]),
    endDate: new UntypedFormControl('', [Validators.required]),
  })

  isUserGroupMemberships!: boolean

  sendersSuggestions: any = []
  senderSelectionUpdate = new Subject<string>()

  recipientsSuggestions: any = []
  recipientSelectionUpdate = new Subject<string>()

  statusSuggestions: Suggestion[] = []

  searchInProgress: boolean = false

  public readonly progressMessage = 'reports_MessageTraceSearchInProgress'

  private destroyed$: Subject<boolean> = new Subject()

  constructor(
    private store: Store<RootState>,
    private localstorageService: LocalstorageService,
    private reportsService: ReportsService,
    private suggesterService: SuggesterService,
    private administrationsService: AdministrationsService,
    private appInsights: ApplicationInsightService,
    private toastService: ToastService,
    public translateHelper: TranslateHelper,
    public reportHelper: ReportsComponentHelper,
  ) {}

  ngOnInit(): void {
    this.senderSelectionUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      this.getSendersSuggestion(value)
    })

    this.recipientSelectionUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      this.getRecipientsSuggestion(value)
    })

    this.initializeFilters()
    this.setFormData()
    this.validateFields()
    this.validateEmails()

    this.statuses.forEach((s: string) => {
      this.statusSuggestions.push({ displayValue: this.translateHelper.instant(s), value: s })
    })

    this.userSettings$ = this.store.select(selectUserSettings)

    this.userSettings$.pipe(takeUntil(this.destroyed$)).subscribe((userSettings) => {
      if (userSettings) {
        const regionalSetting = Constants.regionalSettings.find((us) => us.fullLabel === userSettings.regionalSettings)
        this.localeIso = Helpers.isoRegionalSetting(regionalSetting)
      }
    })

    this.store
      .select(selectLastMessage)
      .pipe(
        filter((x) => !!x && x.type === 'NotifyTaskStatus' && x.body.title === 'GetMessageTrace' && x.body.state === 'Finished'),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.searchInProgress = false
        this.reportsComponent?.clientGrid?.refresh()
      })

    this.administrationsService.getUserGroupsLoggedUser().subscribe((groups: any) => {
      this.isUserGroupMemberships = groups?.length > 0
    })

    this.loadData()
  }

  initializeFilters(isReset?: boolean) {
    if (
      !!this.localstorageService.getMessageTraceSearchCorrelationId() &&
      !!this.localstorageService.getMessageTraceSearchFilters() &&
      !isReset
    ) {
      this.filters = this.localstorageService.getMessageTraceSearchFilters()
      this.defaultRange = 0
    } else {
      this.defaultRange = 1
      this.filters = {
        startDate: dayjs.utc().add(-this.defaultRange, 'day'),
        endDate: dayjs.utc(),
      }
    }
  }

  setFormData() {
    this.form.get('startDate')?.setValue(this.filters.startDate)
    this.form.get('endDate')?.setValue(this.filters.endDate)

    const s = this.filters.senderEmail?.split('@')
    if (s) {
      this.form.get('sender')?.setValue({ value: s[0], displayValue: s[0] })
      this.form.get('senderDomain')?.setValue(s[1])
    }

    const v = this.filters.receiverEmail?.split('@')
    if (v) {
      this.form.get('recipient')?.setValue({ value: v[0], displayValue: v[0] })
      this.form.get('recipientDomain')?.setValue(v[1])
    }

    this.form.get('status')?.setValue(this.filters.status?.split(','))
    this.form.get('subject')?.setValue(this.filters.subject)
  }

  getSendersSuggestion(value: string | null) {
    if (!value) {
      this.sendersSuggestions = []
    } else {
      this.suggesterService.getMessageTraceSuggest({ search: value }).subscribe((suggestions: any) => {
        this.sendersSuggestions = suggestions?.options
          ? suggestions.options.map((s: any) => {
              const upn = s.text.includes('<br/>') ? s.text.split('<br/>')[0] : s.text
              return {
                value: upn,
                displayValue: upn,
                otherDisplayValue: s.payload.text,
              }
            })
          : []
      })
    }
  }

  getRecipientsSuggestion(value: string | null) {
    if (!value) {
      this.recipientsSuggestions = []
    } else {
      this.suggesterService.getMessageTraceSuggest({ search: value }).subscribe((suggestions: any) => {
        this.recipientsSuggestions = suggestions?.options
          ? suggestions.options.map((s: any) => {
              const upn = s.text.includes('<br/>') ? s.text.split('<br/>')[0] : s.text
              return {
                value: upn,
                displayValue: upn,
                otherDisplayValue: s.payload.text,
              }
            })
          : []
      })
    }
  }

  onChangeSender(event: any): void {}

  onChangeRecipient(event: any): void {}

  senderSelectionChanged(newValue: any): void {
    const v = newValue?.value?.split('@')
    if (!v) {
      return
    }
    this.form.get('sender')?.setValue({ value: v[0], displayValue: v[0] })
    this.form.get('senderDomain')?.setValue(v[1])
  }

  recipientSelectionChanged(newValue: any): void {
    const v = newValue?.value?.split('@')
    if (!v) {
      return
    }
    this.form.get('recipient')?.setValue({ value: v[0], displayValue: v[0] })
    this.form.get('recipientDomain')?.setValue(v[1])
  }

  statusSelectionChanged(newValue: any): void {}

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  loadData() {
    this.configuration = {
      gridOptions: {
        defaultColDef: {
          sortable: true,
          resizable: false,
          filter: true,
          floatingFilter: true,
          filterParams: {
            suppressAndOrCondition: true,
          },
        },
        masterDetail: true,
        detailCellRenderer: 'customDetail',
        detailCellRendererParams: {
          waitMessage: true,
          correlationObj: 'correlationId',
          apiUrlPushAction: '/psmessagetracedetail',
          verbPushAction: 'post',
          apiUrl: '/messagetracedetails',
          verb: 'get',
          getParams: (obj: any) => ({ correlationId: obj }),
          checkNotification: (x: any) =>
            x?.type === 'NotifyTaskStatus' && x.body.title === 'GetMessageTraceDetail' && x.body.state === 'Finished',
          fieldsToPass: ['messageTraceId', 'recipientAddress'],
          responseItemProp: 'messageTraceDetails',
          columnDefs: [
            { field: 'messageTraceId', type: 'int', name: 'messageTraceId', translate: 'MessageTraceId', suppressMenu: true, resizable: true },
            { field: 'messageId', sort: 'asc', type: 'int', name: 'messageId', translate: 'MessageId', suppressMenu: true, resizable: true },
            {
              field: 'date',
              type: 'date',
              name: 'date',
              translate: 'Date',
              suppressMenu: true,
              resizable: true,
              valueFormatter: this.dateFormatter,
            },
            { field: 'detail', type: 'string', name: 'detail', translate: 'Detail', suppressMenu: true, resizable: true },
            { field: 'event', type: 'string', name: 'event', translate: 'Event', suppressMenu: true, resizable: true },
          ],
        },
      },
      isRowMaster: () => true,
      columnDefs: this.buildColDefsByFields([
        { key: 'fromIP', value: 'FromIP', type: 'string' },
        {
          key: 'messageTraceId',
          value: 'MessageTraceId',
          type: 'tableDetail',
          cellRenderer: 'agGroupCellRenderer',
        },
        { key: 'received', value: 'Received', type: 'date', sort: 'desc' },
        { key: 'recipientAddress', value: 'RecipientAddress', type: 'string' },
        { key: 'senderAddress', value: 'SenderAddress', type: 'string' },
        { key: 'size', value: 'Size', type: 'number' },
        { key: 'status', value: 'Status', type: 'number' },
        { key: 'subject', value: 'Subject', type: 'number' },
      ]),
      getParameters: () => ({ correlationId: this.localstorageService.getMessageTraceSearchCorrelationId() }),
      leftPanel: 'CustomSmartPanel',
    }
  }

  onRangeChange(newRange: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs; days?: string }) {
    this.filters.startDate = newRange.startDate
      .set('hour', this.form.get('startDate')?.value?.utc()?.get('hour'))
      .set('minute', this.form.get('startDate')?.value?.utc()?.get('minute'))
      .set('second', 0)
      .set('millisecond', 0)

    this.filters.endDate = newRange.endDate
      .set('hour', this.form.get('endDate')?.value?.utc()?.get('hour'))
      .set('minute', this.form.get('endDate')?.value?.utc()?.get('minute'))
      .set('second', 0)
      .set('millisecond', 0)

    this.form.get('startDate')?.setValue(this.filters.startDate)
    this.form.get('endDate')?.setValue(this.filters.endDate)
  }

  getError() {
    if (this.isSearchDisabled() && this.form.dirty) {
      if (!this.form.get('senderDomain')?.value && !this.form.get('recipientDomain')?.value) {
        return this.translateHelper.instant('reports_SenderOrReceiverDomainsRequired')
      }

      if ((!this.form.get('sender')?.value?.value || !this.form.get('recipient')?.value?.value) && this.isUserGroupMemberships) {
        return this.translateHelper.instant('reports_NotAllowedToSearchByDomainInsertFullEmail')
      }
    }

    return null
  }

  isSearchDisabled() {
    return (
      this.form.invalid ||
      (!this.form.get('senderDomain')?.value && !this.form.get('recipientDomain')?.value) ||
      (this.isUserGroupMemberships && !this.form.get('sender')?.value && !this.form.get('recipient')?.value)
    )
  }

  calculateTime(time: string) {
    return {
      hours: +time.substring(0, time.indexOf(':')),
      minutes: +time.substring(time.indexOf(':') + 1, time.indexOf(':') + 3),
    }
  }

  search() {
    this.error = undefined

    setTimeout(() => {
      this.filters.senderEmail = this.getEmailValue('sender', 'senderDomain')

      this.filters.receiverEmail = this.getEmailValue('recipient', 'recipientDomain')

      if (this.form.get('status')?.value) {
        this.filters.status = this.form.get('status')?.value.join(',')
      } else {
        this.filters.status = undefined
      }

      if (this.form.get('subject')?.value) {
        this.filters.subject = this.form.get('subject')?.value
      } else {
        this.filters.subject = undefined
      }

      this.filters.startDate = this.form.get('startDate')?.value
      this.filters.endDate = this.form.get('endDate')?.value

      this.reportsService.getDataSimplified('/psmessagetrace', 'post', this.filters).subscribe({
        next: (res) => {
          if (res.responseStatus?.errorCode === 'Success' && res.correlationId) {
            this.localstorageService.setMessageTraceSearchFilters(this.filters)
            this.localstorageService.setMessageTraceSearchCorrelationId(res.correlationId)
            this.searchInProgress = true
            this.reportsComponent.clientGrid?.gridApi?.showLoadingOverlay()

            this.toastService.open({
              id: 'success',
              variant: 'success',
              title: this.translateHelper.instant('common_Success'),
              message: this.translateHelper.instant('reports_MessageTraceRequestSuccessfullySent'),
            })
          }
        },
        error: (error) => {
          this.appInsights.trackError('Search message trace failed with error: : ' + error)
          this.toastService.open({
            id: 'err',
            variant: 'error',
            title: this.translateHelper.instant('reports_MessageTraceSearch'),
            dismiss: (toast: { id: any }) => this.toastService.close(toast.id),
          })
        },
      })
    }, 1)
  }

  reset() {
    this.resetInProgress = true
    this.error = undefined

    setTimeout(() => {
      this.initializeFilters(true)

      this.form.reset({
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
      })

      this.form.markAsPristine()

      this.resetInProgress = false
    }, 1)
  }

  buildColDefsByFields(fields: ReportField[], hasCheckbox = false) {
    const gridColumnsDefs: ColDef[] = []

    if (hasCheckbox) {
      gridColumnsDefs.push({
        type: 'undefined',
        field: 'selection',
        hide: false,
        colId: 'selection',
        checkboxSelection: true,
        headerComponentParams: {
          keepSelectionBetweenPages: true,
        },
        ...Constants.defaultOperationColumnsDefinition,
      })
    }

    fields.forEach((f) => {
      const col = this.reportHelper.generateGridColumnsDef(f)
      col.resizable = true
      gridColumnsDefs.push(col)
    })

    return gridColumnsDefs
  }

  dateFormatter = (params: any): string => Helpers.formatDate(params.value) || this.translateHelper.instant('common_NeverUsed')

  validateFields() {
    const fieldsToValidate = ['sender', 'recipient']
    fieldsToValidate.forEach((fieldName) => {
      const field = this.form.get(fieldName)
      field?.valueChanges.subscribe((val: { value: string; displayValue: string }) => {
        const { value } = val || { value: '' }
        const error = value && !this.pattern.test(value) ? { pattern: true } : null
        field.setErrors(error)
      })
    })
  }

  validateEmails() {
    this.form.valueChanges.subscribe((val) => {
      const { sender, senderDomain, recipient, recipientDomain } = val
      this.validateEmail('sender', sender, senderDomain)
      this.validateEmail('domain', recipient, recipientDomain)
    })
  }

  validateEmail(field: string, user?: { value: string; displayValue: string }, domain?: string) {
    if (user?.value && domain) {
      const value = `${user.value}@${domain}`
      const error = !Constants.emailRegex.test(value) ? { emailPattern: true } : null
      this.form.get(field)?.setErrors(error)
    }
  }

  getEmailValue(userField: string, domainField: string): string | undefined {
    const user = this.form.get(userField)?.value?.value
    const domain = this.form.get(domainField)?.value
    if (domain) {
      const userStr = `${user || ''}@`
      return `${userStr}${domain}`
    } else {
      return undefined
    }
  }
}

import { ColumnApi } from '@ag-grid-community/core'
import { Injectable } from '@angular/core'
import { OperationColumn, SelectionAction } from '@coreview/coreview-library/models/ReportDefinition'
import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { SavedReport } from '@app/core/models/saved-report'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { SaveViewComponent } from '@app/modules/reports/components/save-view/save-view.component'
import { NewSchedulationComponent } from '@app/modules/reports/pages/new-schedulation/new-schedulation.component'
import { ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import * as Selectors from '@app/store/license-pool-center/license-pool-center.selectors'
import * as Actions from '@app/store/license-pool-center/license-pool-center.actions'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { selectedOrganization } from '@app/store/organizations/organizations.selectors'

@Injectable({
  providedIn: 'root',
})
export class LicensePoolHelperService {
  public savedReport: SavedReport | null = null
  public route: string | null | undefined = null
  public reportTitle = ''

  public commonColumnsOperations: OperationColumn[] = [
    {
      name: 'usersInPool',
      field: 'usersInPool',
      param: (url: string, data: any) =>
        `${url}reports/users/users?LicensePoolGroupName=${encodeURIComponent('=' + (data.poolName || data.name))}`,
      type: 'link',
      external: true,
    },
    {
      name: 'assignedToUsers',
      field: 'assignedToUsers',
      param: (url: string, data: any) => {
        let finalUrl = `${url}reports/users/users?LicensePoolGroupName=${encodeURIComponent('=' + (data.poolName || data.name))}`
        if (data.sku) {
          finalUrl = `${finalUrl}&Licenses=${encodeURIComponent('=' + data.sku)}`
        } else {
          finalUrl = `${finalUrl}&Licenses=IsNotEmpty`
        }
        return finalUrl
      },
      type: 'link',
      external: true,
    },
  ]

  public groupedColumnsOperations: OperationColumn[] = [
    {
      name: 'usersInPool',
      field: 'usersInPool',
      param: (url: string, data: any) => {
        let finalUrl = `${url}reports/users/users`
        if (data.poolName) {
          finalUrl = `${finalUrl}?LicensePoolGroupName=${encodeURIComponent('=' + data.poolName)}`
        }
        return finalUrl
      },
      type: 'link',
      external: true,
    },
    {
      name: 'assignedToUsers',
      field: 'assignedToUsers',
      param: (url: string, data: any) => {
        let finalUrl = `${url}reports/users/users`
        finalUrl = `${finalUrl}?Licenses=${encodeURIComponent('=' + data.sku)}`
        if (data.poolName) {
          finalUrl = `${finalUrl}&LicensePoolGroupName=${encodeURIComponent('=' + data.poolName)}`
        }
        return finalUrl
      },
      type: 'link',
      external: true,
    },
  ]

  isOpenInFull = false
  private allowGlobalLicenseOpsToManageLps = false

  private saveViewSubject = new Subject<void>()
  private scheduleSubject = new Subject<void>()

  private destroyed$: Subject<boolean> = new Subject()

  constructor(
    private reportsComponentHelper: ReportsComponentHelper,
    private rightPanelService: RightPanelService,
    private store: Store<RootState>,
    private sharedHelperService: SharedHelperService
  ) {
    this.store
      .select(Selectors.selectIsOpenInFull)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isOpenInFull) => {
        this.isOpenInFull = isOpenInFull
      })

    this.store
      .select(selectedOrganization)
      .pipe(takeUntil(this.destroyed$), filter((x) => !!x))
      .subscribe((company) => {
        this.allowGlobalLicenseOpsToManageLps = !!company?.allowGlobalLicenseOpsToManageLps
      })
  }

  saveView = (metadata: any, reportDefinition: ReportDefinition, columnApi: ColumnApi, pivotSettings = {}) => {
    metadata.queryRequest.fields = this.reportsComponentHelper.getVisibleFields(columnApi)
    const panelRef = this.rightPanelService.open({
      type: SaveViewComponent,
      data: {
        lastRequest: metadata?.queryRequest,
        reportDefinition,
        route: this.route,
        savedReport: this.savedReport,
        ...pivotSettings
      },
    })
    panelRef
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(() => {
        this.saveViewSubject.next()
      })
  }

  get saveViewObservable() {
    return this.saveViewSubject.asObservable()
  }

  schedule = (metadata: any, columnApi: ColumnApi) => {
    metadata.queryRequest.fields = this.reportsComponentHelper.getVisibleFields(columnApi)
    const panelRef = this.rightPanelService.open({
      type: NewSchedulationComponent,
      data: {
        request: metadata?.queryRequest,
        route: this.route,
        initialName: this.reportTitle,
        savedReport: this.savedReport,
        fromReport: true,
      },
    })
    panelRef
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(() => {
        this.scheduleSubject.next()
      })
  }

  get scheduleObservable() {
    return this.scheduleSubject.asObservable()
  }

  getOpenInFullActions() {
    const actions: SelectionAction[] = [
      {
        text: 'common_OpenInFull',
        buttonType: 'tertiary',
        icon: 'open_in_full',
        classIcon: 'mat-icon-no-color',
        leftClassStyle: 'outlined',
        isVisible: () => !this.isOpenInFull,
        onClick: () => {
          this.store.dispatch(Actions.toggleIsOpenInFull())
        },
        visibility: 'custom',
        cvDataTest: 'open-full-mode'
      },
      {
        text: 'common_CloseFullscreen',
        buttonType: 'tertiary',
        icon: 'close_fullscreen',
        classIcon: 'mat-icon-no-color',
        leftClassStyle: 'outlined',
        isVisible: () => this.isOpenInFull,
        onClick: () => {
          this.store.dispatch(Actions.toggleIsOpenInFull())
        },
        visibility: 'custom',
        cvDataTest: 'close-full-mode'
      },
    ]
    return actions
  }

  canManagePools() {
    return this.sharedHelperService.isAdmin() || (this.allowGlobalLicenseOpsToManageLps && this.sharedHelperService.isGlobalLicenses())
  }
}

<div class="details-wrapper">
  <div class="grid-wrapper" *ngFor="let key of dictionaryFieldsKeys">
    <ag-grid-angular
      domLayout="autoHeight"
      class="ag-theme-alpine ag-theme-coreview"
      style="width: 100%; flex: 1"
      [rowData]="rowData[key]"
      [columnDefs]="columnDefs[key]"
      [defaultColDef]="defaultColDef"
      [modules]="modules"
      (gridReady)="onGridReady($event)"
      (rowDataChanged)="rowDataChanged()"
      (gridSizeChanged)="gridSizeChanged()"
      [enableRangeSelection]="true"
      [getContextMenuItems]="getContextMenuItems"
      [processCellForClipboard]="processCellForClipboard"
    >
    </ag-grid-angular>
  </div>
</div>

import { GridApi } from '@ag-grid-community/core'
import { Component, Input, Output, EventEmitter } from '@angular/core'
import { MatSelectChange } from '@angular/material/select'
import { ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper'

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.sass'],
})
export class PaginationComponent {
  @Input() gridApi!: GridApi

  @Input() pageSize = '25'

  @Output() pageSizeChanged = new EventEmitter<number>()

  pages: number[] = [1]

  constructor(private reportsComponentHelper: ReportsComponentHelper) {}

  fromRow(): number {
    return this.gridApi && this.gridApi?.paginationGetRowCount() > 0 ? (this.getCurrentPage() - 1) * this.paginationSize() + 1 : 0
  }

  toRow(): number {
    return Math.min(this.getCurrentPage() * this.paginationSize(), this.gridApi?.paginationGetRowCount() || 0)
  }

  getCurrentPage(): number {
    return (this.gridApi?.paginationGetCurrentPage() || 0) + 1
  }

  paginationSize(): number {
    return this.gridApi?.paginationGetPageSize() || 0
  }

  lastPage(): void {
    this.gridApi?.paginationGoToLastPage()
  }

  firstPage(): void {
    this.gridApi?.paginationGoToFirstPage()
  }

  prevPage(): void {
    if (this.getCurrentPage() !== 1) {
      this.gridApi?.paginationGoToPreviousPage()
    }
  }

  nextPage(): void {
    if (this.getCurrentPage() < this.getPagesTotal()) {
      this.gridApi?.paginationGoToNextPage()
    }
  }

  setPage(page: number): void {
    this.gridApi?.paginationGoToPage(page - 1)
  }

  pageSizeSelected({ value }: MatSelectChange) {
    this.pageSizeChanged.emit(Number(value))
    this.pages = this.getPages()
  }

  getPagesTotal(): number {
    return this.gridApi?.paginationGetTotalPages() || 0
  }

  getPages(): number[] {
    if (!this.getCurrentPage()) {
      return []
    }
    let pages: number[] = []
    const currentPage = this.getCurrentPage() || 0
    const pagesTotal = this.getPagesTotal()
    if (pagesTotal - (currentPage - 1) < 5) {
      for (let i = currentPage; i <= pagesTotal; i++) {
        pages.push(i)
      }
      const pagesLength = pages.length
      for (let j = 1; j <= 5 - pagesLength; j++) {
        if (currentPage - j > 0) {
          pages = [currentPage - j, ...pages]
        }
      }
    } else if (pagesTotal === 5 || currentPage < 4) {
      pages = [1, 2, 3, 4, 5]
    } else {
      pages = [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2]
    }
    return pages.length === 0 ? [1] : pages
  }

  pageChanged() {
    this.pages = this.getPages()
  }

  getSelectedRowsCount() {
    return this.reportsComponentHelper.getSelectedRowsCount(this.gridApi)
  }
}

import { EnhancedJsonFormData } from '@shared/models/enhanced-json-form-data'
import { SchemaPropertyDecorator } from './json-schema-rosetta-v1.class'

export const WF_ERROR_MESSAGE_INPUT_NAME = 'Workflow_ErrorMessage'

export class WorkflowErrorDecorator extends SchemaPropertyDecorator {
  match(schema: EnhancedJsonFormData): boolean {
    return !!schema?.properties && !!schema?.properties[WF_ERROR_MESSAGE_INPUT_NAME]
  }

  /**
   * Decorators may call parent implementation of the operation, instead of
   * calling the wrapped object directly. This approach simplifies extension
   * of decorator classes.
   */
  public getJsonSchema(): EnhancedJsonFormData {
    const schema = super.getJsonSchema()

    if (this.match(schema)) {
      delete schema.properties[WF_ERROR_MESSAGE_INPUT_NAME]
    }

    return schema
  }
}

import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiclientService } from './apiclient.service'
import { ManagementAction } from '../models/management-action'
import { pluck } from 'rxjs/operators'
import { Verb } from '../models/PageDataCommonClasses'
import { ManagementCustomAction } from '../models/ManagementCustomAction'

@Injectable({
  providedIn: 'root',
})
export class ManagementActionsService {
  public static readonly topPredefinedManagementActions: string[] = [
    'ManagePassword',
    'ChangeUserProperties', // ONCLOUD action
    'EditUserProperties', // HYBRID action
    'ManageLicenses',
    'RemoveUser',
    'SetMultiFactorAuthState',
    'AddMembersToDistributionGroup',
    'AddMembersToSecurityGroup',
    'AddMembersToO365Group',
    'RenameUser',
    'RemoveAllLicenses',
  ]

  constructor(private apiClient: ApiclientService, private httpClient: HttpClient) {}

  getActionsV2(actionsTags: string[] = []): Observable<ManagementAction[]> {
    const url = `${this.apiClient.basePortalApiUrl}/v2/manageactions/actions/`
    return this.httpClient.get<any>(url, { params: { actionsTags, ignoreLockMode: true }, withCredentials: true })
  }

  getActionById(actionId: number): Observable<ManagementAction> {
    const url = `${this.apiClient.basePortalApiUrl}/v2/manageactions/action/${actionId}`
    return this.httpClient
      .get<any>(url, { params: { actionItemId: actionId, ignoreLockMode: true }, withCredentials: true })
      .pipe(
        pluck('action')
      )
  }

  getFavoriteActions(): Observable<number[]> {
    const url = `${this.apiClient.basePortalApiUrl}/manageactions/favorites/`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  saveFavoriteAction(actionItemId: number): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/manageactions/favorites/`
    return this.httpClient.post<void>(url, { actionItemId }, { withCredentials: true })
  }

  deleteFavoriteAction(actionItemId: number): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/manageactions/favorites/`
    return this.httpClient.delete<void>(url, { params: { actionItemId }, withCredentials: true })
  }

  getManagementActionNotesFieldMandatoryInfo(companyId: string): Observable<boolean> {
    const url = `${this.apiClient.basePortalApiUrl}/management/${companyId}/actions/notesfield/mandatory`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  submitAction(apiUrl: string, verb: Verb, params: any) {
    const url = `${this.apiClient.basePortalApiUrl}/${apiUrl}`
    return this.httpClient.request(verb, url, { body: params, withCredentials: true })
  }

  removeDistributionGroupMember(params: any): Observable<ManagementAction[]> {
    const url = `${this.apiClient.basePortalApiUrl}/management/removedistributiongroupmember/`
    return this.httpClient.post<any>(url, params, { withCredentials: true })
  }

  removeSecurityGroupMember(params: any): Observable<ManagementAction[]> {
    const url = `${this.apiClient.basePortalApiUrl}/management/removesecuritygroupmember/`
    return this.httpClient.post<any>(url, params, { withCredentials: true })
  }

  removeO365GroupMember(params: any): Observable<ManagementAction[]> {
    const url = `${this.apiClient.basePortalApiUrl}/management/removeoffice365groupmember/`
    return this.httpClient.post<any>(url, params, { withCredentials: true })
  }

  removeMobileDevice(params: any): Observable<ManagementAction[]> {
    const url = `${this.apiClient.basePortalApiUrl}/management/removemobiledevice/`
    return this.httpClient.post<any>(url, params, { withCredentials: true })
  }

  removeMailboxPermissionRights(params: any): Observable<ManagementAction[]> {
    const url = `${this.apiClient.basePortalApiUrl}/management/removemailboxpermission/`
    return this.httpClient.post<any>(url, params, { withCredentials: true })
  }

  canViewAction(actionId: number): Observable<boolean> {
    const url = `${this.apiClient.basePortalApiUrl}/canviewsubaction/`
    return this.httpClient.get<any>(url, { params: { id: actionId }, withCredentials: true })
  }

  canViewActions(actionIds: number[]): Observable<{ [id: number]: boolean }> {
    const url = `${this.apiClient.basePortalApiUrl}/canviewsubactions/`
    return this.httpClient.get<any>(url, { params: { ids: actionIds }, withCredentials: true })
  }

  getTeamsPolicies(): Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/teams/policies/`
    return this.httpClient.get<any>(url, { params: { pageSize: 9999 }, withCredentials: true })
  }

  getReadonlyPolicies(): Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/teams/readonly/policies`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  getCustomAction(companyId: string, customActionId: string): Observable<ManagementCustomAction> {
    return this.httpClient
      .get<ManagementCustomAction>(`${this.apiClient.basePortalApiUrl}/${companyId}/customactions/${customActionId}/`, {
        withCredentials: true,
      })
      .pipe(pluck('customAction'))
  }
}

import { createAction, props } from '@ngrx/store'

import { UserSettings } from '@coreview/coreview-library/models/user-settings'

export const getUserSettings = createAction('[UserSettings] GET request')
export const getUserSettingsResponse = createAction('[UserSettings] GET resp', props<{ data: UserSettings }>())
export const getUserSettingsInvalidate = createAction(
  '[UserSettings] GET invalidates',
  props<{ error: { code: number; message: string } }>()
)

export const saveUserSettings = createAction('[UserSettings] SAVE request', props<{ data: UserSettings }>())
export const saveUserSettingsResponse = createAction('[UserSettings] SAVE response', props<{ data: UserSettings }>())
export const saveUserSettingsInvalidate = createAction(
  '[UserSettings] SAVE invalidates',
  props<{ error: { code: number; message: string } }>()
)

export const setShowSensitiveData = createAction('[UserSettings] set ShowSensitiveData', props<{ showSensitiveData: boolean }>())

export const updateUserSettingsExtra = createAction('[UserSettings] UPDATE setting extra request', props<{ key: string, value: any }>())

export const updateUserSettingsExtraLive = createAction('[UserSettings] UPDATE setting extra live', props<{ key: string, value: any }>())


export const resetUserSettingsExtra = createAction('[UserSettings] RESET setting extra request')

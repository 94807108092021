<ng-container>
  <app-reports *ngIf="configuration" [clientTableConfiguration]="configuration" (reloadButtonClicked)="onReloadButtonClicked($event)">

    <cv-smart-panel [title]="'reports_FilterAssistant' | translate" class="m365-health-filters" [open]="true" customSmartPanel>
      <div style="cursor: default" content>
        <div class="title-container">
          <div class="title" translate>common_FilterBy</div>
        </div>
        <div class="subtitle">
          <span style="display: flex; border-bottom: 1px solid #7A7A7A; padding: 3px 0;">
            <span translate style="font-weight: 600">
              common_Services
            </span>
            <span style="margin-left: 10px">
              <mat-chip-listbox aria-label="Issues" [selectable]="false" hideSingleSelectionIndicator *ngIf="countTotalErrors() > 0">
                <mat-chip-option color="warn" [selected]="true" [disableRipple]="true" style="font-size: 10px;">
                  <span>{{countTotalErrors()}}</span>&nbsp;<span translate>common_Issues</span>
                </mat-chip-option>
              </mat-chip-listbox>
            </span>
          </span>
        </div>

        <div class="select-section">
          <div *ngIf="!workloadStatuses" translate>common_Loading</div>
          <mat-radio-group
            class="p-t-10"
            style="display: flex; flex-direction: column"
            (change)="workloadStatusesChange($event)"
            aria-label="Select an option"
            [value]="selectedWorkload"
            *ngIf="workloadStatuses"
          >
            <mat-radio-button
              style="margin: 5px"
              *ngFor="let w of workloadStatuses"
              [value]="w"
              attr.cv-data-test="checkbox-{{ w.workloadDisplayName }}"
            >
              <span style="display: flex">
                <span style="flex: 50%">{{ w.workloadDisplayName | translate }}</span>

                <span style="flex: 50%; margin-left: 10px">
                  <mat-chip-listbox
                    aria-label="Issues"
                    [selectable]="false"
                    hideSingleSelectionIndicator
                    *ngIf="w.status != 'ServiceOperational' && w.status != 'ServiceRestored'"
                  >
                    <mat-chip-option color="warn" [selected]="true" [disableRipple]="true" style="font-size: 10px">
                      <span>{{countErrors(w.featureStatus)}}</span>&nbsp;<span translate>common_Issues</span>
                    </mat-chip-option>
                  </mat-chip-listbox>
                </span>
              </span>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </cv-smart-panel>

  </app-reports>
</ng-container>

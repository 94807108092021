<form [formGroup]="form" class="range-form">
  <cv-select
    formControlName="startTime"
    [label]="'common_StartAt' | translate"
    [suggestions]="hoursList"
    [errorMessage]="'common_Required' | translate"
  ></cv-select>
  <cv-select
    formControlName="endTime"
    [label]="'common_EndAt' | translate"
    [suggestions]="hoursList"
    [errorMessage]="'common_Required' | translate"
  ></cv-select>
</form>
<div class="error-wrapper" *ngIf="form.errors?.invalidRange">
  <mat-icon class="material-icons-outlined icon">error </mat-icon>
  <span translate>management_StartTimeCantBeLaterThanEndTime</span>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { WorkflowHttpService } from '@app/modules/workflow/http/workflow.http'
import { Workflow } from '@app/modules/workflow/models/workflow.model'
import { WF_DEFINITION_TYPES, WorkflowFlowDefinitionTypes } from '@app/modules/workflow/shared/constants/workflow'

type WorkflowDetail = Workflow.Dto.V1.WorkflowDetail.Response
type TemplateDetail = Workflow.Dto.V1.TemplateDetail.Response

@Component({
  selector: 'app-workflow-preview-loader',
  templateUrl: './workflow-preview-loader.component.html',
  styleUrls: ['./workflow-preview-loader.component.sass'],
})
export class WorkflowPreviewLoaderComponent implements OnInit, OnDestroy {
  @Input() id!: string
  @Input() previewType: WorkflowFlowDefinitionTypes = 'workflows'
  @Input() isForPlaybook: boolean = false

  public actions: Workflow.Dto.V1.Common.Action[] = []
  public catchActions: Workflow.Dto.V1.Common.Action[] = []
  public workflowName = ''
  public workflowDescription = ''
  public showDetails = true
  public entityDetail: WorkflowDetail | TemplateDetail | undefined
  public loadedFirstTime = false

  private destroyed$: Subject<boolean> = new Subject()

  constructor(private workflowHttpService: WorkflowHttpService) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  ngOnInit(): void {
    if (!this.id) {
      throw new Error('missing workflow or template id parameters')
    }

    this.factoryRequest(this.previewType, this.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((resp) => {
        this.entityDetail = resp
        this.actions = resp.data.actions
        this.catchActions = resp.data.catchActions
        this.workflowName = resp.data.workflowName
        this.workflowDescription = resp.data.description
        this.loadedFirstTime = true
      })
  }

  private factoryRequest(type: WorkflowFlowDefinitionTypes, id: string): Observable<WorkflowDetail | TemplateDetail> {
    return type === WF_DEFINITION_TYPES.template
     ? this.workflowHttpService.fetchTemplateById(id, { withActions: true })
     : this.workflowHttpService.fetchWorkflowById(id, { withActions: true, edit: true }, this.isForPlaybook)
  }
}

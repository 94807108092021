<div
  class="container"
  [ngClass]="{
  'workflowCatch': previewType === 'workflows' && isCatch === true,
  'workflowTemplate': previewType === 'templates',
}"
>
  <div class="d-fl" style="width: 100%; gap: 8px; align-items: flex-start">
    <mat-icon style="color: var(--primary-color); font-size: 20px; height: 20px; width: 20px">rule</mat-icon>
    <div class="fl-1" style="display: flex; flex-direction: column">
      <div style="font-weight: 600; font-size: 16px">
        <span>{{ ifElse?.name }}</span>
      </div>
      <div class="m-t-10">
        <app-filter-description [Filter]="ifElse?.filter"/>
      </div>
    </div>
  </div>
</div>
<div style="height: 24px; width: 0; border: 1px solid var(--opaque-light-contrast-color); margin: 0 auto; margin-top: 10px"></div>
<div style="border-top: 2px solid var(--opaque-light-contrast-color); position: relative; width: 390px; margin: auto">
  <cv-badge
    leftIcon="check_circle"
    style="top: -15px; position: absolute; z-index: 1"
    variant="green"
    [text]="'Yes' | translate"
  ></cv-badge>
  <cv-badge
    leftIcon="cancel"
    style="top: -15px; position: absolute; z-index: 1; right: 0"
    variant="red"
    [text]="'No' | translate"
  ></cv-badge>
</div>
<div style="display: flex; margin-top: 25px">
  <div class="fl-1" style="padding: 0 10px" [class.left]="showMergeLine">
    <app-action-list
      [actions]="ifElse?.trueBranch || []"
      [parentUid]="ifElse?.uid"
      [previewType]="previewType"
      [showDetails]="showDetails"
      [isCatch]="isCatch"
      ifElseBranch="true"
    ></app-action-list>
  </div>
  <div class="fl-1" style="padding: 0 10px" [class.right]="showMergeLine">
    <app-action-list
      [actions]="ifElse?.falseBranch || []"
      [parentUid]="ifElse?.uid"
      [previewType]="previewType"
      [showDetails]="showDetails"
      [isCatch]="isCatch"
      ifElseBranch="false"
    ></app-action-list>
  </div>
</div>
<div
  *ngIf="showMergeLine"
  style="border-radius: 0 0 6px 6px; border: 2px solid var(--opaque-light-contrast-color); height: 16px; border-top: 0; width: 348px; margin: auto"
></div>

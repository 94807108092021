import { environment } from '@environments/environment'
import { LoggerService } from './core/services/logger.service'
import { ErrorHandler, Injectable } from '@angular/core'
import { LocalstorageService } from './core/services/localstorage.service'

const LOG_TAG = 'NewUx ErrorHandlerService'
export const GENERIC_ERROR_MESSAGE = 'Uncaught error was found'

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private loggerService: LoggerService, private storage: LocalstorageService,) { }

  handleError(error: Error | string | any): void {
    let errorData: any = { message: GENERIC_ERROR_MESSAGE, details: '' }
    const user = this.storage.getLoggedUser()?.userName ?? 'unknwon'
    if (!error) {
      errorData = { message: GENERIC_ERROR_MESSAGE, details: 'Undefined error' }
    } else if (error instanceof Error) {
      errorData = { message: GENERIC_ERROR_MESSAGE, details: error.message || '', stackTrace: error.stack || '' }
    } else if (typeof error === 'string') {
      errorData = { message: GENERIC_ERROR_MESSAGE, details: error || '' }
    } else {
      try {
        this.loggerService.logError(`${LOG_TAG} | organizationId: ${this.storage.selectedOrganization} | ${user} | version: ${environment.appVersion} | error: ${JSON.stringify(error)}`).subscribe()
      } catch {
        errorData = {
          message: `An unknown error generated a JSON stringify exception (probably a recursive object)`,
          details: `Object type: ${error.toString()}`,
        }
      }
    }
    this.loggerService.logError(`${LOG_TAG} | organizationId: ${this.storage.selectedOrganization} | ${user} | version: ${environment.appVersion} | error: ${JSON.stringify(errorData)}`).subscribe()

    console.error(error)
  }
}

import { Component, HostListener, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { timer } from 'rxjs'

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.sass'],
})
export class RightPanelComponent implements OnInit {
  @ViewChild('panelContainer', { read: ViewContainerRef })
  set panelContainer(container: ViewContainerRef) {
    this.container = container
  }

  @Input()
  component: any

  @Input()
  closeOnOutsideClick: boolean = false

  width = ''

  private container!: ViewContainerRef | null

  constructor(private rightPanelService: RightPanelService) {}

  ngOnInit(): void {
    this.width = this.component.instance.width || '70%'
    timer(10).subscribe(() => this.container?.insert(this.component.hostView))
  }

  public closePanel() {
    this.rightPanelService.close().subscribe()
  }

  @HostListener('document:click', ['$event'])
  onclick = (event: MouseEvent) => {
    if (this.closeOnOutsideClick) {
      this.closeSearchAndPanelsWhenClickingElsewhere(event)
    }
  }

  private closeSearchAndPanelsWhenClickingElsewhere(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement
    if (!clickedElement || clickedElement.closest('.right-panel')) {
      return
    }
    if (!clickedElement || clickedElement.closest('.cdk-overlay-container')) {
      this.closePanel()
    }
  }
}

<div class="right-panel">
  <div class="content-container" style="overflow-y: auto">
    <div class="panel-title-container">
      <span class="panel-title uppercase" translate>reports_ReportFiltersTitle</span>
    </div>
    <app-loading-panel-skeleton *ngIf="loading"></app-loading-panel-skeleton>
    <div class="grid-container" [style.display]="!!savedFilters ? 'grid' : 'none'" style="grid-template-rows: auto 1fr">
      <cv-button
        [text]="'common_CreateNew' | translate"
        buttonType="tertiary"
        leftIcon="add"
        (click)="createNewClicked()"
        cv-data-test="createNewButton"
      ></cv-button>
      <app-datagrid
        #grid
        [hideColumnsSelector]="true"
        [gridDefinition]="gridDefinition"
        [getItems]="getItems"
        cvDataTest="reportsfilters-datagrid"
      >
      </app-datagrid>
    </div>
    <form [style.display]="!loading && !savedFilters ? 'block' : 'none'" [formGroup]="form">
      <div>
        <cv-select
          cv-data-test="targetTypeSelection"
          [label]="'reports_TargetType' | translate"
          [suggestions]="targetTypes"
          formControlName="targetEntity"
          [disabled]="true"
          style="margin: 10px 0; width: 100%; box-sizing: border-box"
        >
        </cv-select>
      </div>
      <div [style.display]="targetEntity === 'User' ? 'flex' : 'none'" style="flex-direction: column" class="p-b-10">
        <cv-checkbox
          (checkedChange)="filterGroupMembership()"
          [value]="groupMembershipChecked"
          class="p-t-10 p-b-10"
          cv-data-test="filterGroupMembership"
        >
          <span translate>reports_FilterGroupsMembership</span>
        </cv-checkbox>
        <div class="box-filter p-10" [style.display]="!!groupMembershipChecked ? 'grid' : 'none'">
          <app-group-memebeship-filter #membershipFilterComponent [groupTypes]="groupTypes" [readonly]="readonly">
          </app-group-memebeship-filter>
        </div>
      </div>
      <cv-checkbox
        class="p-t-10 p-b-10"
        [disabled]="reportsFiltersCheckDisabled"
        formControlName="reportsFiltersChecked"
        cv-data-test="filterTargetProperties"
      >
        <span translate>reports_FilterProperties</span>
      </cv-checkbox>

      <div *ngIf="form.controls.reportsFiltersChecked.value" class="p-t-10">
        <div *ngFor="let frm of rootFilters; let idx = index">
          <div *ngIf="idx !== 0" class="p-t-15 p-b-15 text-grey">
            <label>{{ rootCondition }}</label>
          </div>
          <div class="box-filter p-10">
            <app-filters
              [controller]="frm"
              [readonly]="readonly"
              [columns]="columns"
              [singleLevel]="false"
              [valueChange]="valueSuggester"
              (filterRemoved)="filterRemoved(idx)"
              attr.cv-data-test="app-filters-{{ idx }}"
            >
            </app-filters>
          </div>
        </div>
        <div *ngIf="!!columns" class="p-t-10">
          <cv-button
            *ngIf="showAndButton && !readonly"
            [text]="'common_And' | translate"
            buttonType="secondary"
            leftIcon="add"
            (click)="andClicked()"
            class="p-r-10"
            cv-data-test="andButton"
          ></cv-button>
          <cv-button
            *ngIf="showOrButton && !readonly"
            [text]="'common_Or' | translate"
            buttonType="secondary"
            leftIcon="add"
            (click)="orClicked()"
            cv-data-test="orButton"
          ></cv-button>
        </div>
      </div>
    </form>
  </div>
  <div class="footer-container" *ngIf="!readonly">
    <div *ngIf="!savedFilters">
      <cv-checkbox
        *ngIf="!formInvalid() && targetEntity !== 'Audit'"
        (checkedChange)="saveFilterCheck = !saveFilterCheck"
        [value]="saveFilterCheck"
        cv-data-test="save-filter"
      >
        <span translate>reports_SaveReportFilter</span>
      </cv-checkbox>
      <div *ngIf="saveFilterCheck">
        <cv-input
          [label]="'reports_TypeFilterName' | translate"
          [(ngModel)]="newFilterName"
          style="width: 100%"
          class="p-b-10 p-t-10"
          [required]="!!saveFilterCheck"
        ></cv-input>
        <cv-checkbox
          (checkedChange)="setAsPublicCheck = !setAsPublicCheck"
          [value]="setAsPublicCheck"
          *ngIf="isAdmin()"
          [info]="'reports_SetAsPublicFilterInfo' | translate"
          cv-data-test="save-filter"
        >
          <span translate>reports_SetAsPublic</span>
        </cv-checkbox>
      </div>
    </div>
    <cv-button
      [text]="'common_Cancel' | translate"
      buttonType="secondary"
      leftIcon="close"
      style="float: left"
      class="m-r-10 p-t-10 p-b-10"
      (click)="cancelClicked()"
    ></cv-button>
    <cv-button
      [text]="'common_Apply' | translate"
      primary="secondary"
      rightIcon="check"
      style="float: right"
      class="m-r-10 p-t-10 p-b-10"
      (click)="applyClicked()"
      [disabled]="formInvalid() || (!newFilterName && !!saveFilterCheck)"
      *ngIf="!savedFilters"
    ></cv-button>
    <cv-button
      [text]="'common_Apply' | translate"
      buttonType="primary"
      rightIcon="check"
      style="float: right"
      class="m-r-10 p-t-10 p-b-10"
      color="success"
      (click)="applySelectedClicked()"
      [disabled]="isApplyDisabled()"
      *ngIf="!!savedFilters"
    ></cv-button>
  </div>
</div>

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Workflow } from '@app/modules/workflow/models/workflow.model'
import { WorkflowFlowDefinitionTypes } from '@app/modules/workflow/shared/constants/workflow'

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.sass'],
})
export class ActionListComponent implements OnChanges {
  @Input() actions: Workflow.Dto.V1.Common.Action[] = []
  @Input() previewType: WorkflowFlowDefinitionTypes = 'workflows'
  @Input() parentUid: string | undefined
  @Input() showDetails = true
  @Input() isCatch = false
  @Input() ifElseBranch: 'true' | 'false' | undefined

  public filteredActions: Workflow.Dto.V1.Common.Action[] = []

  ngOnChanges(_: SimpleChanges): void {
    this.filterActions()
  }

  filterActions() {
    if (this.ifElseBranch) {
      this.filteredActions = this.actions
      return
    }
    this.filteredActions = this.parentUid
      ? this.actions.filter((item) => item.parentUid === this.parentUid)
      : this.actions.filter((item) => !item.parentUid)
  }

  getActionIsSequence(action: Workflow.Dto.V1.Common.Action) {
    return action.id === 'item::sequence' && !action.parentUid
  }
}

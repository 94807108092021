<div class="cv-dialog-div-container">
  <div class="title-div">
    <div class="title-container">
      <span class="title">{{ data.title }}</span>
    </div>
    <cv-close-button class="close-icon" [mat-dialog-close]="'cancel'"></cv-close-button>
  </div>
  <div class="dialog-container">
    <app-execution-details-panel
      [workflowId]="data.workflowId"
      [executionId]="data.executionId"
      [isForPlaybook]="true"
    ></app-execution-details-panel>
  </div>
</div>

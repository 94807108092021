<mat-option class="item" (click)="goToSavedReport(rep); $event.stopPropagation()" *ngFor="let rep of savedReports">
  <div style="display: flex;">
    <span class="report-name">{{ rep.reportName }} </span>
    <div class="icon-container m-r-5">
      <mat-icon *ngIf="rep.isShared && !rep.isPredefined">share</mat-icon>
      <mat-icon *ngIf="!rep.isShared && !rep.isPredefined">person</mat-icon>
    </div>
    <div class="icon-container">
      <mat-icon *ngIf="rep.columnsLocked && !rep.isPredefined">lock</mat-icon>
    </div>
  </div>
</mat-option>

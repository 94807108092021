import { createReducer, on } from '@ngrx/store'

import * as LPCActions from './license-pool-center.actions'
import { LicensePoolFilterFullReportType } from '@app/modules/licenses/components/license-left-panel/license-left-panel.component'

export interface LicensePoolCenterState {
  leftPanelIsOpen: boolean
  leftPanelReport: string
  groupedBySkuAreAllRowsExpanded: boolean
  groupedByPoolsAreAllRowsExpanded: boolean
  overAllocatedAreAllRowsExpanded: boolean
  outOfCapacityAreAllRowsExpanded: boolean
  showOnlyPoolsWithAllocation: boolean
  selectedLicenses: string[],
  isSavedReport: boolean,
  isOpenInFull: boolean
}
export const initialState: LicensePoolCenterState = {
  leftPanelIsOpen: true,
  leftPanelReport: LicensePoolFilterFullReportType.FullReportMasterData,
  groupedBySkuAreAllRowsExpanded: true,
  groupedByPoolsAreAllRowsExpanded: true,
  overAllocatedAreAllRowsExpanded: true,
  outOfCapacityAreAllRowsExpanded: true,
  showOnlyPoolsWithAllocation: true,
  selectedLicenses: [],
  isSavedReport:  false,
  isOpenInFull: false
}

export const licensePoolCenterReducer = createReducer(
  initialState,
  on(LPCActions.setLeftPanelIsOpen, (state, { isOpen }) => ({ ...state, leftPanelIsOpen: isOpen })),
  on(LPCActions.setLeftPanelReport, (state, { report }) => ({ ...state, leftPanelReport: report })),
  on(LPCActions.setGroupedBySkuAreAllRowsExpanded, (state, { areExpanded }) => ({ ...state, groupedBySkuAreAllRowsExpanded: areExpanded })),
  on(LPCActions.setGroupedByPoolsAreAllRowsExpanded, (state, { areExpanded }) => ({
    ...state,
    groupedByPoolsAreAllRowsExpanded: areExpanded,
  })),
  on(LPCActions.setOverAllocatedAreAllRowsExpanded, (state, { areExpanded }) => ({
    ...state,
    overAllocatedAreAllRowsExpanded: areExpanded,
  })),
  on(LPCActions.setOutOfCapacityAreAllRowsExpanded, (state, { areExpanded }) => ({
    ...state,
    outOfCapacityAreAllRowsExpanded: areExpanded,
  })),
  on(LPCActions.setShowOnlyPoolsWithAllocation, (state, { isShow }) => ({ ...state, showOnlyPoolsWithAllocation: isShow })),
  on(LPCActions.setIsSavedReport, (state, { isSavedReport }) => ({ ...state, isSavedReport })),
  on(LPCActions.setSelectedLicenses, (state, { licenses }) => ({ ...state, selectedLicenses: licenses })),

  on(LPCActions.convertExtraToLicencePoolState, (state, action) => {
    if (action?.extra) {
      const data = parseExtra(action.extra)
      if (data?.licensePoolCenter) {
        const newData = data?.licensePoolCenter
        delete newData.isOpenInFull

        return {
          ...state,
          ...newData,
        }
      }
    }
    return {
      ...state,
    }
  }),
  on(LPCActions.setIsOpenInFull, (state, { isOpenInFull }) => ({ ...state, isOpenInFull })),
  on(LPCActions.toggleIsOpenInFull, (state ) => ({ ...state, isOpenInFull: !state.isOpenInFull })),
)

const parseExtra = (us?: any): Record<string, any> => {
  if (us) {
    try {
      return JSON.parse(us)
    } catch {
      return {}
    }
  }
  return {}
}



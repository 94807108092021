<div class="dialog-into-dialog-container">
  <div class="title-div">
    <div class="title-container">
      <span class="title">{{ policyWorkflowReport.title }}</span>
    </div>
    <cv-close-button class="close-icon" [mat-dialog-close]="'cancel'"></cv-close-button>
  </div>
  <div class="dialog-container">
    <app-workflow-executions-v2
      class="fl-1"
      [workflowId]="policyWorkflowReport.workflowId"
      [serviceTag]="serviceTag"
      [startingStatus]="startingStatus"
    >
    </app-workflow-executions-v2>
  </div>
</div>

import { ReportsService } from '@app/core/services/reports.service'
import { PolicyItemListComponent } from './../policy-item-list/policy-item-list.component'
import { PolicyComponent } from './../policy/policy.component'
import { WorkflowPreviewDialogComponent } from '../workflow-preview-dialog/workflow-preview-dialog.component'
import { ExecutionType, Policy } from '@app/core/models/playbook'
import { Component, Inject, ViewChild, OnDestroy, Input, OnChanges, Optional } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { AddToExceptionComponent } from '../add-to-exception/add-to-exception.component'
import { ReportDefinition, SelectionAction } from '@app/core/models/ReportDefinition'
import { PlaybookService } from '../../services/playbook.service'
import { TranslateHelper } from '@coreview/coreview-library'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import { DialogComponent, ToastService } from '@coreview/coreview-components'
import { Helpers } from '@app/shared/utilities/helpers'
import { filter, switchMap, tap } from 'rxjs/operators'
import { iif, of } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthHelperService } from '@app/core/services/auth.helper.service'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { Constants } from '@app/shared/utilities/constants'
import { ExceptionDialogComponent } from '../exception-dialog/exception-dialog.component'

@Component({
  selector: 'app-policy-dialog',
  templateUrl: './policy-dialog.component.html',
  styleUrls: ['./policy-dialog.component.sass'],
})
export class PolicyDialogComponent implements OnChanges, OnDestroy {
  @ViewChild(PolicyItemListComponent)
  policyItemList!: PolicyItemListComponent

  @Input() isLoadedInDialog = true
  @Input() policy!: Policy
  @Input() reportType!: 'matchedItems' | 'matchedExceptions'

  metadata!: any

  reportDefinition!: ReportDefinition

  selectionActionsMatchedItems: SelectionAction[] = []

  selectionActionsExceptionsItems: SelectionAction[] = []  

  private policyComponent: PolicyComponent | undefined

  constructor(
    private service: PlaybookService,
    private translateHelper: TranslateHelper,
    private rightPanelService: RightPanelService,
    private dialog: MatDialog,
    @Optional() private dialogRef: MatDialogRef<PolicyDialogComponent>,
    private toastService: ToastService,
    private playbookService: PlaybookService,
    private reportsService: ReportsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authHelperService: AuthHelperService,
    private sharedHelperService: SharedHelperService,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: { policy?: Policy; reportType?: string; policyComponent?: PolicyComponent; policyId?: string }
  ) {
    this.policyComponent = data?.policyComponent
    this.reportType = data?.reportType === 'matchedItems' ? 'matchedItems' : 'matchedExceptions'
    if (data?.policy) {
      this.policy = { ...data.policy }
      this.initSelectionActions()
      this.updateReportDefinition(this.reportType)
    } else if (data?.policyId) {
      this.playbookService.getPolicy(data.policyId, false).subscribe((resp) => {
        this.policy = resp
        this.initSelectionActions()
        this.updateReportDefinition(this.reportType)
      })
    }
  }

  ngOnChanges(): void {
    if (!this.isLoadedInDialog && this.policy) {
      this.initSelectionActions()
      this.updateReportDefinition(this.reportType)
      if (this.policyItemList?.grid) {
        this.policyItemList.grid.clearAndRefresh()
        this.applySelectionAction()
      }
    }
  }

  ngOnDestroy(): void {
    if (this.policy?.globalAndReportTreeFilters || this.policy?.groupMembershipFilter) {
      this.reportsService.deleteSessionFiltersReports().subscribe(() => (this.reportsService.reportFilter = undefined))
    }
  }

  runWorkflow(data: any): void {
    this.dialog
      .open(DialogComponent, {
        width: '50%',
        data: {
          title: this.translateHelper.instant('playbook_RunWorkflow'),
          text: this.translateHelper.instant('playbook_RunWorkflowDescription', {
            workflow: this.getWorkflowDescription(),
            count: data?.length,
          }),
          primaryText: this.translateHelper.instant('common_Proceed'),
          secondaryText: this.translateHelper.instant('common_Cancel'),
          type: 'info',
          centered: false,
        },
      })
      .afterClosed()
      .pipe(
        filter((x) => !!x),
        switchMap(() =>
          this.service.runPolicyWorkflow(
            this.getPolicyId(this.policy),
            data.map((x: any) => x[Helpers.downcase(this.policy.reportDefinition.entityIdField || '')])
          )
        )
      )
      .subscribe(() =>
        this.toastService.open({
          id: 'workflow' + new Date().toString(),
          variant: 'success',
          matIcon: 'check_circle',
          title: this.translateHelper.instant('common_WorkflowRunTitle'),
          message: this.translateHelper.instant('common_WorkflowRunDescription'),
        })
      )
  }

  runWorkflowAll(): void {
    this.dialog
      .open(DialogComponent, {
        width: '50%',
        data: {
          title: this.translateHelper.instant('playbook_RunWorkflow'),
          text: this.translateHelper.instant('playbook_RunWorkflowDescription', {
            workflow: this.getWorkflowDescription(),
            count: this.policy.matchedItemsCount,
          }),
          primaryText: this.translateHelper.instant('common_Proceed'),
          secondaryText: this.translateHelper.instant('common_Cancel'),
          type: 'info',
          centered: false,
        },
      })
      .afterClosed()
      .pipe(
        filter((x) => !!x),
        switchMap(() => this.service.runPolicyWorkflowAllItems(this.getPolicyId(this.policy)))
      )
      .subscribe(() =>
        this.toastService.open({
          id: 'workflow' + new Date().toString(),
          variant: 'success',
          matIcon: 'check_circle',
          title: this.translateHelper.instant('common_WorkflowRunTitle'),
          message: this.translateHelper.instant('common_WorkflowRunDescription'),
        })
      )
  }

  openWorkflow(): void {
    this.dialog.open(WorkflowPreviewDialogComponent, {
      width: '70%',
      height: '70%',
      data: { workflowId: this.policy.workflowId, isForPlaybook: true },
    })
  }

  removeExceptions(data: any[]): void {
    this.dialog
      .open(DialogComponent, {
        width: '50%',
        data: {
          title: this.translateHelper.instant('playbook_removeExceptions'),
          text: this.translateHelper.instant('playbook_removeExceptionsWarningMessage'),
          primaryText: this.translateHelper.instant('common_Yes'),
          secondaryText: this.translateHelper.instant('common_Cancel'),
          type: 'alert',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.service
            .removePolicyExceptions(
              this.getPolicyId(this.policy),
              data.map((x: any) => x[Helpers.downcase(this.policy.reportDefinition.entityIdField ?? '')])
            )
            .subscribe(() => {
              this.policyItemList?.grid?.refresh()
              this.policyItemList?.grid?.gridApi.deselectAll()
              if (this.policyComponent?.loadPolicy) {
                this.policyComponent?.loadPolicy(this.getPolicyId(this.policy))
              }
              if (this.isLoadedInDialog) {
                this.dialogRef.close()
              }
              if (this.policy.reportUrl && this.policy.reportUrl.includes('api/onlineusers')) {
                this.dialog.open(DialogComponent, {
                  width: '50%',
                  data: {
                    title: this.translateHelper.instant('common_SaveChange'),
                    text: this.translateHelper.instant('playbook_OnlineUsersUpdated'),
                    primaryText: this.translateHelper.instant('common_Continue'),
                    type: 'success',
                    centered: true,
                  },
                })
              }
            })
        }
      })
  }

  applySelectionAction() {
    setTimeout(() => {
      if (this.reportType) {
        this.reportDefinition = {
          ...this.reportDefinition,
          selectionActions: this.getSelectionActions(),
        }
      } else {
        this.reportDefinition = {
          ...this.reportDefinition,
          selectionActions:
            this.policy.policyGroupType === 'LegacyPolicy'
              ? [
                  {
                    text: this.translateHelper.instant('playbook_manageException'),
                    buttonType: 'tertiary',
                    icon: 'block',
                    visibility: 'noRow',
                    onClick: () => {
                      this.manageExceptions()
                    },
                  },
                  {
                    text: this.translateHelper.instant('playbook_removeException'),
                    buttonType: 'tertiary',
                    color: 'alert',
                    icon: 'delete_forever',
                    visibility: 'multiRow',
                    onClick: (data: any[]) => {
                      this.removeLegacyExceptions(data)
                    },
                  },
                ]
              : this.selectionActionsExceptionsItems,
        }
      }
    }, 3000)
  }

  metadataChanged(event: any) {
    this.metadata = event
  }

  removeLegacyExceptions(data: any[]): void {
    this.dialog
      .open(DialogComponent, {
        width: '50%',
        data: {
          title: this.translateHelper.instant('playbook_removeExceptions'),
          text: this.translateHelper.instant('playbook_removeExceptionsWarningMessage'),
          primaryText: this.translateHelper.instant('common_Yes'),
          secondaryText: this.translateHelper.instant('common_Cancel'),
          type: 'alert',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.service.removeLegacyPolicyExceptions(this.getPolicyId(this.policy), data, this.metadata.responseType).subscribe(() => {
            this.policyItemList?.grid?.refresh()
            this.policyItemList?.grid?.gridApi.deselectAll()
            if (this.policyComponent?.load) {
              this.policyComponent?.load()
            }
            this.dialogRef.close()
          })
        }
      })
  }

  removeAllExceptions(): void {
    this.dialog
      .open(DialogComponent, {
        width: '50%',
        data: {
          title: this.translateHelper.instant('playbook_removeAllExceptions'),
          text: this.translateHelper.instant('playbook_removeAllExceptionsWarningMessage'),
          primaryText: this.translateHelper.instant('common_Yes'),
          secondaryText: this.translateHelper.instant('common_Cancel'),
          type: 'alert',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.service.removeAllPolicyExceptions(this.getPolicyId(this.policy)).subscribe(() => {
            this.policyItemList?.grid?.refresh()
            this.policyItemList?.grid?.gridApi.deselectAll()
            if (this.policyComponent?.loadPolicy) {
              this.policyComponent?.loadPolicy(this.getPolicyId(this.policy))
            }
            if (this.isLoadedInDialog) {
              this.dialogRef.close()
            }
          })
        }
      })
  }

  manageExceptions(selectedTargets?: any[]): void {
    this.dialogRef.afterClosed().subscribe(() =>
      this.openAddToExceptions(selectedTargets)
        .afterClosed()
        .subscribe(() => {
          if (this.policyComponent?.loadPolicy) {
            this.policyComponent?.loadPolicy(this.getPolicyId(this.policy))
          }
        })
    )
    this.dialogRef.close()
  }

  manageExceptionsFromGovernance(): void {
    this.openAddToExceptions()
      .afterClosed()
      .subscribe(() => {
        this.policyItemList?.grid?.refresh()
        this.policyItemList?.grid?.gridApi.deselectAll()
      })
  }

  editException(data: any) {
    this.dialog.open(
      ExceptionDialogComponent,
      {
        data: {
          exception: data,
        }
      }      
    )
    .afterClosed()
    .pipe(
      filter((x) => x)
    )
    .subscribe((value: any) => {
      this.manageExceptions([value])
    })
  }

  getOperationColumn() {
    const exeptionOperationsColumn: CoreViewColumn = {
      originalName: 'action',
      type: 'undefined',
      name: 'action',
      translate: 'common_Action',
      notSelectable: true,
      position: 100,
    }

    exeptionOperationsColumn.agColDef = {
      ...Constants.defaultActionColumnsDefinition, 
      cellRendererParams: {
      icon: 'more_horiz',
      optionsFunction: () => {
        return [
          {
            key: 'edit',
            text: this.translateHelper.instant('common_SeeDetails'),
            icon: 'description',
          },
          {
            key: 'delete',
            text: this.translateHelper.instant('playbook_RemoveException'),
            icon: 'delete',
            color: 'alert',
          },
        ]
      },
      onClick: (event: any) => {
        if (event.key === 'delete') {
          this.removeExceptions([event.rowData])
        } else {
          this.editException(event.rowData)
        }
      },
    },}
    return exeptionOperationsColumn
  }

  private openAddToExceptions(selectedTargets?: any[]) {
    return this.rightPanelService.open({
      type: AddToExceptionComponent,
      data: {
        width: '100%',
        policy: this.policy,
        selectedTargets: selectedTargets ?? this.policyItemList?.grid?.getSelectedRows(),
        reportType: this.reportType
      },
    })
  }

  private getSelectionActions(): SelectionAction[] | undefined {
    if (this.policy?.hideExceptions) {
      return []
    }

    return this.reportType === 'matchedItems' ? this.selectionActionsMatchedItems : this.selectionActionsExceptionsItems
  }

  private getWorkflowDescription() {
    return this.policy.workflowName
  }

  private updateReportDefinition(reportType: string): void {
    iif(
      () => !!this.policy?.globalAndReportTreeFilters || !!this.policy?.groupMembershipFilter,
      this.reportsService
        .saveSessionFiltersReports({
          targetEntity: this.policy.policyType === ExecutionType.EventBased ? 'Audit' : undefined,
          reportTreeFilters: this.policy.globalAndReportTreeFilters,
          membershipReportFilters: this.policy.groupMembershipFilter,
        })
        .pipe(
          tap(
            () =>
              (this.reportsService.reportFilter = {
                targetEntity: this.policy.policyType === ExecutionType.EventBased ? 'Audit' : undefined,
                reportTreeFilters: this.policy.globalAndReportTreeFilters,
                membershipReportFilters: this.policy.groupMembershipFilter,
              })
          )
        ),
      of(void 0)
    ).subscribe(() => {
      if (reportType === 'matchedItems') {
        this.reportDefinition = {
          ...this.policy.reportDefinition,
          treeFilters: this.policy.reportTreeFilters,
          lockedColumns: this.policy.reportDefinition.fields,
          entity: undefined,
          sortOrder: this.policy.reportDefinition?.sortOrder ?? null,
        }
      } else {
        this.reportDefinition = {
          ...this.policy.reportDefinition,
          treeFilters: this.policy.reportTreeFilters,
          lockedColumns: this.policy.reportDefinition.fields,
          operationsColumns: [this.getOperationColumn()],
          entity: undefined,
          sortOrder: this.policy.reportDefinition?.sortOrder ?? null,
        }
      }
    })
  }

  private initSelectionActions = () => {
    const hasPlaybookAdminManagerRole = this.checkRoles(['tenantAdmin', 'playbookAdmin', 'playbookManager'])
    const isWorkflowRemediation = this.policy.isWorkflowEnabled && this.policy.remediationType === 'Workflow'
    this.selectionActionsMatchedItems = this.filterSelectionActionMatchedItems(hasPlaybookAdminManagerRole, isWorkflowRemediation)
      .filter((item) => item.condition)
      .map(
        ({ condition, textKey, ...rest }) =>
          ({
            text: textKey === '' ? '' : this.translateHelper.instant(textKey),
            ...rest,
          } as SelectionAction)
      )
    this.selectionActionsExceptionsItems = this.filterSelectionActionExceptionsItems(hasPlaybookAdminManagerRole)
      .filter((item) => item.condition)
      .map(
        ({ condition, textKey, ...rest }) =>
          ({
            text: textKey === '' ? '' : this.translateHelper.instant(textKey),
            ...rest,
          } as SelectionAction)
      )
  }

  private goToFullReport(policy: Policy, showExceptions = false) {
    this.router
      .navigate(
        [
          policy.policyGroupType === 'LegacyPolicy'
            ? `playbook/settings/policy/legacy/${policy.id}`
            : `playbook/settings/policy/${policy.id}`,
        ],
        {
          queryParams: showExceptions ? { showExceptions: 'true' } : {},
        }
      )
      .catch((_: any) => _)
  }

  private checkRoles(roles: string[]) {
    return this.sharedHelperService.checkRoles(roles)
  }

  private getPolicyId(policy: Policy) {
    return policy?.id ?? ''
  }

  private filterSelectionActionMatchedItems(hasPlaybookAdminManagerRole: boolean, isWorkflowRemediation: boolean) {
    return [
      {
        condition: isWorkflowRemediation && hasPlaybookAdminManagerRole,
        textKey: 'playbook_runWorkflow',
        buttonType: 'tertiary',
        icon: 'play_arrow',
        visibility: 'multiRow',
        onClick: (dataRows: any[]) => this.runWorkflow(dataRows),
      },
      {
        condition: isWorkflowRemediation,
        textKey: hasPlaybookAdminManagerRole ? '' : 'workflow_PreviewWorkflow',
        buttonType: 'tertiary',
        style: hasPlaybookAdminManagerRole ? 'margin-left: -30px' : null,
        icon: 'help_outline',
        visibility: 'multiRow',
        onClick: (dataRows: any[]) => this.openWorkflow(),
      },
      {
        condition: isWorkflowRemediation && hasPlaybookAdminManagerRole,
        textKey: 'playbook_runWorkflowAllItems',
        buttonType: 'tertiary',
        icon: 'play_arrow',
        visibility: 'custom',
        isVisible: (selectedRows: any[], displayedRows: number) => displayedRows > 0 && selectedRows.length === 0,
        onClick: () => this.runWorkflowAll(),
      },
      {
        condition: isWorkflowRemediation,
        textKey: hasPlaybookAdminManagerRole ? '' : 'workflow_PreviewWorkflow',
        style: hasPlaybookAdminManagerRole ? 'margin-left: -30px' : null,
        buttonType: 'tertiary',
        icon: 'help_outline',
        visibility: 'custom',
        isVisible: (selectedRows: any[], displayedRows: number) => displayedRows > 0 && selectedRows.length === 0,
        onClick: () => this.openWorkflow(),
      },
      {
        condition: hasPlaybookAdminManagerRole,
        textKey: 'playbook_manageException',
        buttonType: 'tertiary',
        icon: 'block',
        visibility: 'custom',
        isVisible: (selectedRows: any[], displayedRows: number) => displayedRows > 0 && !selectedRows.length,
        onClick: () => (this.isLoadedInDialog ? this.manageExceptions() : this.manageExceptionsFromGovernance()),
      },
      {
        condition: hasPlaybookAdminManagerRole,
        textKey: 'playbook_setException',
        buttonType: 'tertiary',
        icon: 'block',
        visibility: 'custom',
        isVisible: (selectedRows: any[], displayedRows: number) => displayedRows > 0 && selectedRows.length > 0,
        onClick: () => (this.isLoadedInDialog ? this.manageExceptions() : this.manageExceptionsFromGovernance()),
      },
      {
        condition: true,
        textKey: 'playbook_GoToFullReport',
        buttonType: 'tertiary',
        icon: 'arrow_forward',
        visibility: 'noRow',
        onClick: (data: any[]) => {
          this.dialog.closeAll()
          if (this.policyComponent?.goToFullReport) {
            this.policyComponent.goToFullReport(this.policy)
          } else {
            this.goToFullReport(this.policy)
          }
        },
      },
    ]
  }

  private filterSelectionActionExceptionsItems(hasPlaybookAdminManagerRole: boolean) {
    return [
      {
        condition: hasPlaybookAdminManagerRole,
        textKey: 'playbook_manageException',
        buttonType: 'tertiary',
        icon: 'block',
        visibility: 'custom',
        onClick: () => (this.isLoadedInDialog ? this.manageExceptions() : this.manageExceptionsFromGovernance()),
      },
      {
        condition: hasPlaybookAdminManagerRole,
        textKey: 'playbook_removeAllException',
        buttonType: 'tertiary',
        color: 'alert',
        icon: 'delete_forever',
        visibility: 'noRow',
        onClick: () => this.removeAllExceptions(),
      },
      {
        condition: hasPlaybookAdminManagerRole,
        textKey: 'playbook_removeException',
        buttonType: 'tertiary',
        color: 'alert',
        icon: 'delete_forever',
        visibility: 'multiRow',
        onClick: (data: any[]) => this.removeExceptions(data),
      },
      {
        condition: true,
        textKey: 'playbook_GoToFullReport',
        buttonType: 'tertiary',
        rightIcon: 'arrow_forward',
        visibility: 'noRow',
        onClick: (data: any[]) => {
          this.dialog.closeAll()
          if (this.policyComponent?.goToFullReport) {
            this.policyComponent.goToFullReport(this.policy)
          } else {
            this.goToFullReport(this.policy)
          }
        },
      },
    ]
  }
}

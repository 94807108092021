/* eslint-disable @typescript-eslint/member-ordering */
import { FlatTreeControl } from '@angular/cdk/tree'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree'
import { TranslateHelper } from '@coreview/coreview-library'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { TreeNode } from '../models/Tree'
import { TreeService } from '../tree.service'

@Component({
  selector: 'app-tree-view-single',
  styleUrls: ['./tree-view-single.component.sass'],
  templateUrl: 'tree-view-single.component.html',
})
export class TreeViewSingleComponent<T extends { text: string | number; icon?: string; children: T[] }> implements OnInit {
  @Input()
  data!: T[]

  @Input()
  selected?: number

  @Input()
  readonly = false

  @Input()
  searchable = false

  @Input()
  searchLabel = 'common_Search'

  @Input()
  preventTranslation = false

  @Output()
  selectionChanged: EventEmitter<TreeNode | null> = new EventEmitter()

  searchFilter: Subject<string> = new Subject<string>()

  private _transformer = (node: T, level: number) =>
    ({
      expandable: !!node.children && node.children.length > 0,
      name: typeof node.text === 'string' && !this.preventTranslation ? this.translateHelper.instant(node.text) : node.text,
      level,
      icon: node.icon,
      ref: node,
    } as TreeNode)

  treeControl = new FlatTreeControl<TreeNode>(
    (node) => node.level,
    (node) => node.expandable
  )
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  )
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener)

  constructor(private translateHelper: TranslateHelper, private treeService: TreeService) {}

  ngOnInit() {
    this.dataSource.data = this.data

    if (this.selected || this.selected === 0) {
      const node = this.treeControl.dataNodes.find((x) => this.selected === x.ref.id)
      if (node) {
        this.expandAscendants(node)
      }
    }

    this.searchFilter.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      if (value) {
        this.treeService.filterByName(value, this.treeControl)
      } else {
        this.clearFilter()
      }
    })
  }

  hasChild = (lv: number, node: TreeNode) => node.expandable

  isChecked(node: TreeNode) {
    return node.ref.id === this.selected
  }

  handleChange(node: TreeNode, event: any) {
    this.selectionChanged.emit(node)
  }

  filterChanged(filter: string) {
    this.searchFilter.next(filter)
  }

  private clearFilter(): void {
    this.treeControl.dataNodes.forEach((x) => (x.hidden = false))
  }

  private expandAscendants(node: TreeNode) {
    const parent = this.treeService.getParentNode(node, this.treeControl)
    if (parent) {
      this.treeControl.expand(parent)
      this.expandAscendants(parent)
    }
  }
}

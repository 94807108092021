import { MessagesState } from './messages.types'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { Message } from '@app/core/models/communication/message'

export const selectMessagesState = createFeatureSelector<MessagesState>('messages')

export const selectMessages = createSelector(selectMessagesState, (state: MessagesState) => state.messages)

export const selectLastMessage = createSelector(selectMessagesState, (state: MessagesState) => state.lastMessage)

export const selectMessagesOfType = (type: string) =>
  createSelector(selectMessages, (state: Record<string, Message[]>) => ({ messages: state[type] }))

export const selectLastMessageOfType = (type: string) =>
  createSelector(selectMessagesOfType(type), (state: { messages: Message[] }) => (!!state.messages?.length ? state.messages[0] : null))

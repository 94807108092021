import { Injectable } from '@angular/core'

import { AuthHelperService } from '@core/services/auth.helper.service'
import { Constants } from '@shared/utilities/constants'

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  constructor(private authHelperService: AuthHelperService) {}

  //#region Workflows and Templates
  public canView(): boolean {
    return this.hasAnyRole([
      Constants.roles.TenantAdmin,
      Constants.roles.Management,
      Constants.roles.WorkflowEditor,
      Constants.roles.WorkflowPublisher,
    ])
  }

  public canCreateEdit(): boolean {
    return this.hasAnyRole([Constants.roles.TenantAdmin, Constants.roles.WorkflowEditor])
  }

  public canExecuteCsv(): boolean {
    return this.hasAnyRole([Constants.roles.TenantAdmin, Constants.roles.Management])
  }

  public canDelete(): boolean {
    return this.hasAnyRole([Constants.roles.TenantAdmin, Constants.roles.WorkflowEditor, Constants.roles.WorkflowPublisher])
  }
  //#endregion

  //#region Workflows
  public canExecuteRetryStopWorkflow(): boolean {
    return this.hasAnyRole([Constants.roles.TenantAdmin, Constants.roles.Management])
  }

  public canDuplicateWorkflow(): boolean {
    return this.hasAnyRole([Constants.roles.TenantAdmin, Constants.roles.WorkflowEditor])
  }

  public canExportWorkflow(): boolean {
    return this.hasAnyRole([Constants.roles.TenantAdmin, Constants.roles.WorkflowEditor])
  }

  public canActivateDeactivateWorkflow(): boolean {
    return this.hasAnyRole([Constants.roles.TenantAdmin, Constants.roles.WorkflowEditor, Constants.roles.WorkflowPublisher])
  }

  public canPublishWorkflow(): boolean {
    return this.hasAnyRole([Constants.roles.TenantAdmin, Constants.roles.WorkflowPublisher])
  }

  public canScheduleWorkflow(): boolean {
    return this.hasAnyRole([Constants.roles.TenantAdmin, Constants.roles.Management])
  }

  public canDeleteScheduleWorkflow(): boolean {
    return this.hasAnyRole([Constants.roles.WorkflowEditor, Constants.roles.WorkflowPublisher, Constants.roles.TenantAdmin])
  }

  public canSaveNotificationSettings(): boolean {
    return this.hasAnyRole([Constants.roles.TenantAdmin, Constants.roles.WorkflowEditor, Constants.roles.WorkflowPublisher])
  }

  public canEditTimeWorkflow(): boolean {
    return this.hasAnyRole([Constants.roles.WorkflowEditor, Constants.roles.Management, Constants.roles.TenantAdmin])
  }
  //#endregion

  //#region Approvals
  public canApproveRejectDeleteApproval(): boolean {
    return this.hasAnyRole([Constants.roles.TenantAdmin])
  }
  //#endregion

  private hasAnyRole(roles: string[]): boolean {
    return roles.some((role) => this.authHelperService.hasRole(role))
  }
}

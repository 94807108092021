<form [formGroup]="form" style="display: grid; row-gap: 15px">
  <cv-switch formControlName="useRulesSyntax" [info]="'management_RulesSyntaxDescription' | translate">
    {{ 'management_RulesSyntax' | translate }}
  </cv-switch>

  <div *ngIf="!useRulesSyntax">
    <ng-container *ngIf="isRuleStringValid">
      <app-filters
        [controller]="ruleList"
        [columns]="columns"
        [singleLevel]="true"
        [customOperations]="builderOperations"
        [hideAlertBar]="true"
        [showAtLeastOneLine]="true"
        cv-data-test="rule-builder"
      >
      </app-filters>
    </ng-container>
    <cv-alert-bar *ngIf="!isRuleStringValid" variant="info" [title]="ruleStringErrorMessage || '' | translate" [hideCloseButton]="true" class="m-b-15">
      <span translate>{{ruleStringErrorMessage ? ruleStringErrorMessage + 'Description' : ''}}</span>
    </cv-alert-bar>
  </div>

  <div class="m-b-15">
    <div class="m-b-5 f-s-16" translate>management_Script</div>
    <cv-textarea
      formControlName="script"
      [label]="'management_RulesSyntax' | translate"
      [required]="true"
      [disabled]="!useRulesSyntax"
      style="width: 100%"
      cv-data-test="rule-script"
    ></cv-textarea>
  </div>
</form>

<div class="m-b-5 f-s-16" translate>management_ExampleRules</div>
<div class="rules-examples">
  <div class="m-b-5">(user.department -eq "sales")</div>
  <div class="m-b-5">(user.extensionAttribute -eq "marketing")</div>
</div>

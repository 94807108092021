/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input } from '@angular/core'
import { Workflow } from '@app/modules/workflow/models/workflow.model'
import { WorkflowFlowDefinitionTypes } from '@app/modules/workflow/shared/constants/workflow'

@Component({
  selector: 'app-action-if-else',
  templateUrl: './action-if-else.component.html',
  styleUrls: ['./action-if-else.component.sass'],
})
export class ActionIfElseComponent {
  @Input() ifElse?: Workflow.Dto.V1.Common.IfElse
  @Input() actions: Workflow.Dto.V1.Common.Action[] = []
  @Input() showDetails = true
  @Input() previewType: WorkflowFlowDefinitionTypes = 'workflows'
  @Input() isCatch = false
  @Input() showMergeLine = false
}

<div #container class="policies-container" [class.loading-skeleton]="!loaded">
  <ng-container *ngIf="!loaded">
    <div *ngFor="let _ of [].constructor(shortPolicies ? 5 : 10)" [class.short]="shortPolicies" class="loading-element"></div>
  </ng-container>
  <cv-alert-bar *ngIf="loaded && !policies.length && !isMonitoring" [hideCloseButton]="true" variant="warning">
    <span translate>playbook_NoPermissionsForPolicies</span>
  </cv-alert-bar>
  <cv-alert-bar *ngIf="loaded && filteredPolicies.length === 0" [hideCloseButton]="true" variant="warning">
    <span translate>playbook_NoPoliciesFound</span>
  </cv-alert-bar>
  <cv-surface
    [attr.id]="'policy-' + policy.id"
    [shadow]="showOnHover[i]"
    *ngFor="let policy of filteredPolicies; index as i"
    (mouseenter)="showOnHover[i] = true"
    (mouseleave)="showOnHover[i] = false"
  >
    <div class="policy-container">
      <div class="policy-info" [class.mid-opacity]="!policy.isPolicyEnabled">
        <div [class.rows]="shortPolicies" class="header-container d-fl">
          <div
            appCheckEllipsis
            [tooltip]="policy.title | translate"
            [display]="ellipsis.isEllipsed"
            tooltipClass="cv-tooltip"
            #ellipsis="appCheckEllipsis"
            class="title fl-1"
            (click)="goToFullReport(policy)"
          >
            {{ policy.title | translate }}
          </div>
          <div class="header-group">
            <span style="line-height: 30px" *ngIf="policy.calculatingMetrics" class="calculating m-r-10">
              <mat-icon style="margin: auto 5px">timelapse</mat-icon> {{ 'playbook_Recalculating' | translate }}
            </span>
            <span *ngIf="!policy.calculatingMetrics" class="badge-container m-r-10">
              <cv-badge
                class="badge-schedule"
                *ngIf="policy.reportDefinition?.target === 'Audit'"
                [hideBorder]="true"
                [variant]="policy.isWorkflowEnabled ? 'lightgreen' : 'grey'"
                [tooltip]="'playbook_EventBased' | translate"
                tooltipClass="cv-tooltip"
                leftIcon="flash_on"
                [tooltip]="
                  (policy.isWorkflowEnabled ? 'playbook_AuditRemediationIsEnabled' : 'playbook_AuditRemediationNotEnabled') | translate
                "
                [text]="policy.isWorkflowEnabled ? 'On' : 'Off'"
                cv-data-test="policyEventBasedBadge"
                [attr.cv-policy-template-id]="policy.policyTemplateId"
                (click)="openEditPolicyPanel(policy, null, null, true)"
              ></cv-badge>
              <cv-badge
                class="badge-number"
                *ngIf="policy.reportDefinition?.target !== 'Audit'"
                [hideBorder]="true"
                [variant]="policyService.checkThresholdForVariant(policy)"
                [tooltip]="
                  (!policy.isThresholdEnabled
                    ? 'playbook_MatchedItems'
                    : !policy.isThresholdExceeded
                    ? 'playbook_PolicyIsCompliant'
                    : 'playbook_Violations'
                  ) | translate
                "
                tooltipClass="cv-tooltip"
                [text]="matchedItemsTitle(policy)"
                [leftIcon]="policyService.checkThresholdForIcon(policy)"
                [leftIconStyle]="policy.isThresholdEnabled ? null : 'outlined'"
                (click)="openDialog(policy, 'matchedItems')"
                cv-data-test="policyMatchedItemsBadge"
                [attr.cv-policy-template-id]="policy.policyTemplateId"
              ></cv-badge>
              <cv-badge
                class="badge-number"
                *ngIf="policy.reportDefinition?.target !== 'Audit'"
                [hideBorder]="true"
                variant="grey"
                [tooltip]="'playbook_Exceptions' | translate"
                tooltipClass="cv-tooltip"
                [text]="matchedExceptionsTitle(policy)"
                leftIcon="block"
                (click)="openDialog(policy, 'matchedExceptions')"
                cv-data-test="policyExceptionsBadge"
                [attr.cv-policy-template-id]="policy.policyTemplateId"
              ></cv-badge>
              <cv-badge
                class="badge-schedule"
                *ngIf="policy.reportDefinition?.target !== 'Audit' && !shortPolicies"
                [ngStyle]="!isAdmin ? { cursor: 'default' } : null"
                [hideBorder]="true"
                [variant]="isPolicyScheduled(policy) ? 'lightgreen' : 'grey'"
                leftIcon="today"
                [text]="isPolicyScheduled(policy) ? 'On' : 'Off'"
                (click)="openEditPolicyPanel(policy, null, null, true)"
                [tooltip]="(isPolicyScheduled(policy) ? 'playbook_RemediationIsScheduled' : 'playbook_RemediationNotScheduled') | translate"
                tooltipClass="cv-tooltip"
                cv-data-test="policyWorkflowScheduledBadge"
                [attr.cv-policy-template-id]="policy.policyTemplateId"
              >
              </cv-badge>
            </span>
          </div>
          <div class="header-group m-r-10" style="width: 60px; min-height: 20px">
            <div class="policy-tag">{{ getPolicyTag(policy) }}</div>
          </div>
        </div>
        <div class="policy-details" *ngIf="policiesDetails[i]">
          <div
            *ngIf="!!policy.createdBy?.trim() || ['OutOfTheBoxPolicy', 'MspPolicy'].includes(policy.policyGroupType || '')"
            style="padding-bottom: 10px"
          >
            <div style="display: flex; align-items: center">
              <mat-icon class="m-r-5">description</mat-icon> <span class="title" translate>playbook_CreatedBy</span>
              <app-edit-policy-owner-button
                *ngIf="shouldSeeEditButton(policy, i) && policy.policyGroupType === 'CustomPolicy'"
                [policy]="policy"
              />
            </div>
            <div class="policy-createdby">
              {{
                policy.policyGroupType === 'OutOfTheBoxPolicy'
                  ? ('playbook_CreatedByCoreview' | translate)
                  : policy.policyGroupType === 'MspPolicy'
                  ? ('playbook_CreatedByMsp' | translate)
                  : policy.createdBy
              }}
            </div>
          </div>
          <div style="padding-bottom: 10px">
            <div style="display: flex; align-items: center">
              <mat-icon class="m-r-5">description</mat-icon> <span class="title" translate>playbook_policyDefinition</span>
            </div>
            <div class="policy-description">{{ policy.policyDescription?.policy }}</div>
          </div>
          <div *ngIf="policy.howThisAffect" style="padding-bottom: 10px">
            <div style="display: flex; align-items: center">
              <mat-icon class="m-r-5">description</mat-icon> <span class="title" translate>playbook_UsefulnessDescription</span>
            </div>
            <div class="policy-description">{{ policy.howThisAffect }}</div>
          </div>
          <div style="padding-bottom: 10px" *ngIf="policy.isThresholdEnabled && policy.reportDefinition?.target !== 'Audit'">
            <div style="display: flex; align-items: center">
              <mat-icon class="m-r-5">warning</mat-icon> <span class="title" translate>playbook_thresholdDefinition</span>
            </div>
            <div class="policy-description">{{ policy.policyDescription?.threshold }}</div>
          </div>
          <div style="padding-bottom: 10px" *ngIf="policy.policyGroupType === 'CustomPolicy' && policy.isWorkflowEnabled">
            <div style="display: flex; align-items: center">
              <mat-icon class="m-r-5">account_tree</mat-icon> <span class="title" translate>playbook_RemediationAction</span>
            </div>
            <div class="policy-description">
              {{
                policy.remediationType === 'ScheduleReport'
                  ? ('playbook_ScheduleReport' | translate)
                  : policy.remediationType === 'SendNotification'
                  ? policyService.getNotificationRemediationMessage(policy)
                  : policy.workflowName
              }}
            </div>
            <div class="policy-description" *ngIf="policy.remediationType === 'ScheduleReport'">
              <div>{{ policyService.getSendWhen(policy) }}</div>
            </div>
          </div>
          <div
            style="padding-bottom: 10px"
            *ngIf="policy.policyGroupType === 'CustomPolicy' && policy.policyExceptionFields && policy.policyExceptionFields.length > 0"
          >
            <div style="display: flex; align-items: center">
              <mat-icon class="m-r-5">remove_red_eye</mat-icon> <span class="title" translate>playbook_PolicyKey</span>
            </div>
            <div *ngFor="let exceptionField of policy.policyExceptionFields" class="policy-description">
              {{ exceptionField | translate }}
            </div>
          </div>
          <div style="padding-bottom: 10px" *ngIf="policy.isWorkflowEnabled && policy.policyGroupType !== 'CustomPolicy'">
            <div style="display: flex; align-items: center">
              <mat-icon class="m-r-5">analytics</mat-icon> <span class="title" translate>playbook_workflowDefinition</span>
            </div>
            <div class="policy-description">
              {{
                policy.remediationType === 'ScheduleReport'
                  ? ('playbook_ScheduleReport' | translate)
                  : policy.remediationType === 'SendNotification'
                  ? policyService.getNotificationRemediationMessage(policy)
                  : policy.workflowName
              }}
            </div>
            <div class="policy-description" *ngIf="policy.remediationType === 'ScheduleReport'">
              <div>{{ policyService.getSendWhen(policy) }}</div>
            </div>
          </div>
          <div style="padding-bottom: 10px" *ngIf="isPolicyScheduled(policy)">
            <div style="display: flex; align-items: center">
              <mat-icon class="m-r-5">history</mat-icon> <span class="title" translate>playbook_workflowRecurrenceDefinition</span>
            </div>
            <div class="policy-description">{{ policy.policyDescription?.scheduling }}</div>
          </div>
        </div>
      </div>
      <div class="policy-toggles">
        <cv-button-menu
          [tooltip]="'playbook_ShowAction' | translate"
          *ngIf="shortPolicies"
          buttonType="tertiary"
          size="tiny"
          leftIcon="more_horiz"
          [hideToggle]="true"
        >
          <button
            *ngIf="!policiesDetails[i]"
            (click)="showPolicyDetails(i)"
            cv-data-test="showPolicyDetails"
            [attr.cv-policy-template-id]="policy.policyTemplateId"
            mat-menu-item
          >
            <div style="display: flex; align-items: center">
              <mat-icon style="color: var(--primary-color); margin-right: 16px; margin-left: 0">expand_more</mat-icon>
              <div style="flex: 1">{{ 'playbook_seeDetails' | translate }}</div>
            </div>
          </button>
          <button
            *ngIf="policiesDetails[i]"
            (click)="showPolicyDetails(i)"
            cv-data-test="showPolicyDetails"
            [attr.cv-policy-template-id]="policy.policyTemplateId"
            mat-menu-item
          >
            <div style="display: flex; align-items: center">
              <mat-icon style="color: var(--primary-color); margin-right: 16px; margin-left: 0">expand_less</mat-icon>
              <div style="flex: 1">{{ 'playbook_hideDetails' | translate }}</div>
            </div>
          </button>
          <button (click)="goToFullReport(policy)" cv-data-test="goToFullReport" mat-menu-item>
            <div style="display: flex; align-items: center">
              <mat-icon style="color: var(--primary-color); margin-right: 16px; margin-left: 0">arrow_forward</mat-icon>
              <div style="flex: 1">{{ 'playbook_GoToFullReport' | translate }}</div>
            </div>
          </button>
          <div *ngIf="shortPolicies" style="display: flex; flex-direction: column">
            <div style="height: 1px; width: 100%; background: var(--opaque-light-contrast-color)"></div>
            <cv-switch
              *ngIf="policy.policyGroupType !== 'LegacyPolicy'"
              [tooltip]="'playbook_enablePolicyTooltip' | translate"
              tooltipClass="cv-tooltip"
              [hideDelay]="0"
              (click)="$event?.preventDefault(); $event?.stopPropagation()"
              (checkedChange)="
                policy.oldIsEnabled === undefined ? (policy.oldIsEnabled = policy.isPolicyEnabled) : null;
                policy.isPolicyEnabled = $event;
                patchPolicy(policy, true)
              "
              [value]="policy.isPolicyEnabled"
              cv-data-test="enablePolicy"
              [attr.cv-policy-template-id]="policy.policyTemplateId"
              [disabled]="!policy.canEdit && !this.checkRoles(['tenantAdmin'])"
              >{{ 'playbook_enablePolicy' | translate }}
            </cv-switch>
            <cv-switch
              *ngIf="policy.policyGroupType !== 'LegacyPolicy'"
              [tooltip]="'playbook_enablePolicyWorkflowTooltip' | translate"
              tooltipClass="cv-tooltip"
              [hideDelay]="0"
              (click)="!policy.isWorkflowEnabled ? null : $event.stopPropagation()"
              (checkedChange)="
                policy.isWorkflowEnabled
                  ? disableWorkflow(policy)
                  : openEditPolicyPanel(policy, { isWorkflowEnabled: $event }, workflowEnabled)
              "
              [value]="policy.isWorkflowEnabled"
              #workflowEnabled
              cv-data-test="enablePolicyWorkflow"
              [attr.cv-policy-template-id]="policy.policyTemplateId"
              [ngClass]="!policy.canEnableRemediation ? missingRemediationPermission : ''"
              [disabled]="!policy.isPolicyEnabled || !policy.canEdit || !policy.canEnableRemediation"
              >{{ 'playbook_enablePolicyWorkflow' | translate }}</cv-switch
            >
            <div
              *ngIf="policy.policyGroupType !== 'LegacyPolicy'"
              style="height: 1px; width: 100%; background: var(--opaque-light-contrast-color)"
            ></div>
            <button
              mat-menu-item
              *ngIf="policy.policyGroupType === 'CustomPolicy' && canConvertAsTemplate() && !isGovernance"
              (click)="convertAsTemplate(policy.id || '')"
              cv-data-test="editPolicy"
              [attr.cv-policy-id]="policy.id"
            >
              <div style="display: flex; align-items: center">
                <mat-icon style="color: var(--primary-color); margin-left: 0px; margin-right: 16px">file_copy</mat-icon>
                <div style="flex: 1">{{ 'playbook_ConvertAsTemplate' | translate }}</div>
              </div>
            </button>
            <button
              mat-menu-item
              *ngIf="policy.isPolicyEnabled && policy.policyGroupType !== 'LegacyPolicy' && policy.reportDefinition?.target !== 'Audit'"
              (click)="calculateMetrics(policy)"
              cv-data-test="refreshPolicy"
              [attr.cv-policy-template-id]="policy.policyTemplateId"
            >
              <div style="display: flex; align-items: center">
                <mat-icon style="color: var(--primary-color); margin-left: 0px; margin-right: 16px">refresh</mat-icon>
                <div style="flex: 1">{{ 'common_Refresh' | translate }}</div>
              </div>
            </button>
            <button
              mat-menu-item
              *ngIf="isAdmin && !isGovernance && policy.policyGroupType !== 'LegacyPolicy'"
              (click)="learnMore(policy)"
              cv-data-test="learnMorePolicy"
            >
              <div style="display: flex; align-items: center">
                <mat-icon style="color: var(--primary-color); margin-left: 0px; margin-right: 16px">info</mat-icon>
                <div style="flex: 1">{{ 'playbook_learnMore' | translate }}</div>
              </div>
            </button>
            <button
              mat-menu-item
              *ngIf="shouldSeeEditButton(policy, i)"
              (click)="openEditPolicyPanel(policy)"
              cv-data-test="editPolicy"
              [disabled]="!policy.canEdit"
              [attr.cv-policy-template-id]="policy.policyTemplateId"
            >
              <div style="display: flex; align-items: center">
                <mat-icon style="color: var(--primary-color); margin-left: 0px; margin-right: 16px">edit</mat-icon>
                <div style="flex: 1">{{ 'common_Edit' | translate }}</div>
              </div>
            </button>
            <ng-container
              *ngIf="
                checkRoles(['tenantAdmin', 'playbookAdmin', 'playbookManager']) &&
                policy.isPolicyEnabled &&
                policy.isWorkflowEnabled &&
                policy.remediationType === 'Workflow' &&
                policy.matchedItemsCount > 0 &&
                policy.policyGroupType !== 'LegacyPolicy'
              "
            >
              <button
                mat-menu-item
                (click)="runWorkflow(policy)"
                cv-data-test="runPolicyWorkflow"
                [attr.cv-policy-template-id]="policy.policyTemplateId"
              >
                <div style="display: flex; align-items: center">
                  <mat-icon style="color: var(--primary-color); margin-left: 0px; margin-right: 16px">play_arrow</mat-icon>
                  <div style="flex: 1">{{ 'playbook_runWorkflowShort' | translate }}</div>
                </div>
              </button>
              <button mat-menu-item (click)="openWorkflow(policy)" [attr.cv-policy-template-id]="policy.policyTemplateId">
                <div style="display: flex; align-items: center">
                  <mat-icon style="color: var(--primary-color); margin-left: 0px; margin-right: 16px">help_outline</mat-icon>
                  <div style="flex: 1">{{ 'playbook_SeeWorkflowPreview' | translate }}</div>
                </div>
              </button>
            </ng-container>
          </div>
          <ng-container *ngIf="shouldSeeDeleteButton(policy)">
            <div style="height: 1px; width: 100%; background: var(--opaque-light-contrast-color)"></div>
            <button mat-menu-item [disabled]="!policy.canDelete" (click)="deletePolicy(policy)" cv-data-test="deletePolicy">
              <div style="display: flex; align-items: center">
                <mat-icon style="color: var(--alert-color); margin-right: 16px; margin-left: 0">delete</mat-icon>
                <div style="flex: 1">{{ 'playbook_DeletePolicy' | translate }}</div>
              </div>
            </button>
          </ng-container>
        </cv-button-menu>
        <cv-button
          *ngIf="!shortPolicies"
          (click)="showPolicyDetails(i)"
          class="icon-adjusted"
          buttonType="tertiary"
          cv-data-test="showPolicyDetails"
          [leftIcon]="policiesDetails[i] ? 'expand_less' : ''"
          [rightIcon]="policiesDetails[i] ? '' : 'expand_more'"
          [text]="(policiesDetails[i] ? 'playbook_hideDetails' : '') | translate"
          [tooltip]="(policiesDetails[i] ? '' : 'playbook_seeDetails') | translate"
          [attr.cv-policy-template-id]="policy.policyTemplateId"
        ></cv-button>
        <ng-container *ngIf="policiesDetails[i] && isAdmin && !isMonitoring && !shortPolicies">
          <cv-button
            (click)="goToFullReport(policy)"
            [text]="'playbook_GoToFullReport' | translate"
            buttonType="tertiary"
            cv-data-test="goToFullReport"
            leftIcon="arrow_forward"
          ></cv-button>
          <cv-switch
            *ngIf="policy.policyGroupType !== 'LegacyPolicy'"
            [tooltip]="'playbook_enablePolicyTooltip' | translate"
            tooltipClass="cv-tooltip"
            [hideDelay]="0"
            (checkedChange)="
              policy.oldIsEnabled === undefined ? (policy.oldIsEnabled = policy.isPolicyEnabled) : null;
              policy.isPolicyEnabled = $event;
              patchPolicy(policy, true)
            "
            [value]="policy.isPolicyEnabled"
            cv-data-test="enablePolicy"
            [attr.cv-policy-template-id]="policy.policyTemplateId"
            [disabled]="!policy.canEdit && !this.checkRoles(['tenantAdmin'])"
            >{{ 'playbook_enablePolicy' | translate }}
          </cv-switch>
          <cv-switch
            *ngIf="policy.policyGroupType !== 'LegacyPolicy'"
            [tooltip]="'playbook_enablePolicyWorkflowTooltip' | translate"
            tooltipClass="cv-tooltip"
            [hideDelay]="0"
            (checkedChange)="
              policy.isWorkflowEnabled
                ? disableWorkflow(policy)
                : openEditPolicyPanel(policy, { isWorkflowEnabled: $event }, workflowEnabled)
            "
            [value]="policy.isWorkflowEnabled"
            #workflowEnabled
            cv-data-test="enablePolicyWorkflow"
            [attr.cv-policy-template-id]="policy.policyTemplateId"
            [ngClass]="!policy.canEnableRemediation ? missingRemediationPermission : ''"
            [disabled]="!policy.isPolicyEnabled || !policy.canEdit || !policy.canEnableRemediation"
            >{{ 'playbook_enablePolicyWorkflow' | translate }}</cv-switch
          >
          <cv-button
            *ngIf="policy.policyGroupType === 'CustomPolicy' && canConvertAsTemplate() && !isGovernance"
            buttonType="tertiary"
            leftIcon="file_copy"
            [text]="'playbook_ConvertAsTemplate' | translate"
            (click)="convertAsTemplate(policy.id || '')"
            cv-data-test="convertAsTemplate"
            [attr.cv-policy-id]="policy.id"
          >
          </cv-button>
          <cv-button
            *ngIf="isAdmin && !isGovernance && policy.policyGroupType !== 'LegacyPolicy'"
            leftIcon="info"
            buttonType="tertiary"
            (click)="learnMore(policy)"
            text="{{ 'playbook_learnMore' | translate }}"
            cv-data-test="learnMorePolicy"
          >
          </cv-button>
          <cv-button
            *ngIf="shouldSeeEditButton(policy, i)"
            leftIcon="edit"
            buttonType="tertiary"
            text="{{ 'common_Edit' | translate }}"
            (click)="openEditPolicyPanel(policy)"
            cv-data-test="editPolicy"
            [attr.cv-policy-template-id]="policy.policyTemplateId"
            [disabled]="!policy.canEdit"
          >
          </cv-button>
          <ng-container
            *ngIf="
              checkRoles(['tenantAdmin', 'playbookAdmin', 'playbookManager']) &&
              policy.isPolicyEnabled &&
              policy.isWorkflowEnabled &&
              policy.remediationType === 'Workflow' &&
              policy.matchedItemsCount > 0 &&
              policy.policyGroupType !== 'LegacyPolicy'
            "
          >
            <cv-button
              leftIcon="play_arrow"
              buttonType="tertiary"
              text="{{ 'playbook_runWorkflowShort' | translate }}"
              (click)="runWorkflow(policy)"
              cv-data-test="runPolicyWorkflow"
              [attr.cv-policy-template-id]="policy.policyTemplateId"
            >
            </cv-button>
            <cv-button
              leftIcon="help_outline"
              buttonType="tertiary"
              text="{{ 'playbook_SeeWorkflowPreview' | translate }}"
              (click)="openWorkflow(policy)"
            >
            </cv-button>
          </ng-container>
          <cv-button
            *ngIf="shouldSeeDeleteButton(policy)"
            leftIcon="delete"
            [disabled]="!policy.canDelete"
            buttonType="tertiary"
            color="alert"
            text="{{ 'playbook_DeletePolicy' | translate }}"
            (click)="deletePolicy(policy)"
            cv-data-test="deletePolicy"
          >
          </cv-button>
        </ng-container>
      </div>
    </div>
    <div class="policy-footer" *ngIf="policiesDetails[i]" [ngClass]="policiesDetails[i] ? 'policy-footer-details' : ''">
      <div>
        <span *ngIf="policiesDetails[i] && policy.reportDefinition?.target !== 'Audit'" [class.mid-opacity]="!policy.isPolicyEnabled">
          {{ 'playbook_policyLastUpdate' | translate }} {{ policy.lastUpdatedDateUtc | date : 'medium' }}
        </span>
        <cv-button
          (click)="calculateMetrics(policy)"
          *ngIf="
            policiesDetails[i] &&
            policy.isPolicyEnabled &&
            policy.policyGroupType !== 'LegacyPolicy' &&
            policy.reportDefinition?.target !== 'Audit' &&
            !shortPolicies
          "
          leftIcon="refresh"
          buttonType="tertiary"
          text="{{ 'common_Refresh' | translate }}"
          cv-data-test="refreshPolicy"
          [attr.cv-policy-template-id]="policy.policyTemplateId"
        >
        </cv-button>
      </div>
    </div>
  </cv-surface>
</div>

import { createReducer, on } from '@ngrx/store'
import { OrganizationSettingsState } from './organizationSettings.types'
import { getOrganizationSettings, getOrganizationSettingsResponse, getOrganizationSettingsInvalidate } from './organizationSettings.actions'

export const initialState: OrganizationSettingsState = {
  organizationSettings: null,
  didInvalidate: false,
  isFetching: false,
  error: null,
}

export const organizationSettingsReducer = createReducer(
  initialState,
  on(getOrganizationSettings, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(getOrganizationSettingsResponse, (state, organizationSettings) => ({ ...state, organizationSettings: organizationSettings.data })),
  on(getOrganizationSettingsInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true }))
)

export default organizationSettingsReducer

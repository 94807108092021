<cv-tertiary-dropdown
  *ngIf="!showCustomRange && !!daysOptions"
  [options]="daysOptions"
  (selectionChanged)="onDaysChange($event)"
  [value]="defaultRange?.toString()"
  placeholder="{{ 'reports_FilterDate' | translate }}"
  leftIcon="event"
>
</cv-tertiary-dropdown>
<cv-custom-daterangepicker
  *ngIf="!!(organization$ | async)?.creationDate && !!showCustomRange && !!localeIso"
  placeholder="{{ 'reports_FilterDate' | translate }}"
  (rangeChange)="onRangeChange($event)"
  [minDate]="(organization$ | async)?.creationDate"
  [localeIso]="localeIso"
  [defaultRange]="defaultRange"
  [defaultDates]="defaultDates"
  [rangeOptions]="rangeOptions"
  [limitDays]="limitDays"
  [limitMonths]="limitMonths"
></cv-custom-daterangepicker>

<span (mouseover)="openCard()" (mouseleave)="closeCard()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <mat-icon [ngClass]="{ 'hover-icon': isCatch === false, 'hover-iconCatch': isCatch === true }">settings</mat-icon>

  <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="showCard">
    <div class="hover-container" (mouseover)="openCard()" (mouseleave)="closeCard()">
      <!-- filters -->
      <app-action-filter-details *ngIf="action.filter" [action]="action"> </app-action-filter-details>
      <!-- settings -->
      <div class="hover-title-container">
        <span class="setting-icon" [ngClass]="iconName | fabricIconClass"></span>
        <span class="title" translate>workflow_Settings</span>
      </div>
      <div class="hover-content-container">
        <ul *ngFor="let propertyName of objectKeys(actionSettings)" class="preview-settings">
          <li style="list-style-type: none; margin-left: 0; display: flex; flex-direction: row; align-items: center">
            <span style="padding-right: 4px">
              <strong>{{ propertyName }}</strong>
            </span>
            <app-action-property
              *ngIf="!isArray(actionSettings[propertyName])"
              [propertyValue]="actionSettings[propertyName]"
            ></app-action-property>
            <app-action-property-list
              *ngIf="isArray(actionSettings[propertyName])"
              [items]="actionSettings[propertyName]"
            ></app-action-property-list>
          </li>
        </ul>
      </div>
    </div>
  </ng-template>
</span>

import { NavItem } from './../../core/models/nav-item'
import { createAction, props } from '@ngrx/store'
import { MenuHelp } from '@app/core/models/menu-help'

export const loadAllMenu = createAction('[Menu] loadAll request')
export const loadAllMenuResponse = createAction('[Menu] loadAll resp', props<{ menu: { menuAll: NavItem[], menuFiltered: NavItem[] }}>())
export const loadAllMenuInvalidate = createAction('[Menu] loadAll invalidates', props<{ error: { code: number; message: string } }>())

export const loadFavorites = createAction('[Menu] loadFavorites request')
export const loadFavoritesResponse = createAction('[Menu] loadFavorites resp', props<{ favoriteMenus: []; favoriteSavedReports: [] }>())
export const loadFavoritesInvalidate = createAction(
  '[Menu] loadFavorites invalidates',
  props<{ error: { code: number; message: string } }>()
)

export const loadMenusHelp = createAction('[Menu] loadHelps request')
export const loadMenusHelpResponse = createAction('[Menu] loadHelps resp', props<{ menu: MenuHelp[] }>())
export const loadMenusHelpInvalidate = createAction('[Menu] loadHelps invalidates', props<{ error: { code: number; message: string } }>())

import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-binding-tag-input',
  template: ` <cv-binding-tag [canTransform]="false" [title]="'Field'" [content]="content"></cv-binding-tag> `,
  styleUrls: ['./binding-tag-input.component.sass'],
})
export class BindingTagInputComponent {
  @Input() set propertyValue(value: any) {
    this.tokens = value.replace('{{', '').replace('}}', '').split('.')
    this.content = `${this.tokens[0]}.${this.tokens[1]}`
  }

  public tokens: string[] = []
  public content = ''
}

import { Component, Input } from '@angular/core'
import { Workflow } from '@app/modules/workflow/models/workflow.model'
import { WorkflowFlowDefinitionTypes } from '@app/modules/workflow/shared/constants/workflow'

@Component({
  selector: 'app-action-sequence',
  templateUrl: './action-sequence.component.html',
  styleUrls: ['./action-sequence.component.sass'],
})
export class ActionSequenceComponent {
  @Input() sequence?: any
  @Input() actions: Workflow.Dto.V1.Common.Action[] = []
  @Input() parentUid: string | undefined
  @Input() showDetails = true
  @Input() previewType: WorkflowFlowDefinitionTypes = 'workflows'
  @Input() isCatch = false
}

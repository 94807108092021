import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import dayjs from 'dayjs'
import { TranslateHelper } from '@coreview/coreview-library'
import { Suggestion } from '@coreview/coreview-components'

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.sass'],
})
export class FiltersComponent implements OnInit, OnChanges {
  @Input() controller!: UntypedFormArray
  @Input() columns: any[] = []
  @Input() readonly = false
  @Input() valueChange: (value: any) => any
  @Input() singleLevel: boolean
  @Input() customOperations: Record<string, { value: string; displayValue: string; valueHidden?: boolean }[]> = {}
  @Input() hideAlertBar?: boolean
  @Input() showAtLeastOneLine?: boolean
  @Output() filterRemoved = new EventEmitter<any>()

  showOrButton = true
  showAndButton = true

  conditions = [
    { value: 'AND', displayValue: 'AND' },
    { value: 'OR', displayValue: 'OR' },
  ]

  defaultOperations: Record<string, { value: string; displayValue: string; valueHidden?: boolean }[]> = {
    rest: [
      { value: 'Equals', displayValue: this.translateHelper.instant('common_Equals') },
      { value: 'DoesNotEqual', displayValue: this.translateHelper.instant('common_DoesNotEqual') },
      { value: 'Contains', displayValue: this.translateHelper.instant('common_Contains') },
      { value: 'DoesNotContain', displayValue: this.translateHelper.instant('common_DoesNotContain') },
      { value: 'GreaterThan', displayValue: this.translateHelper.instant('common_GreaterThan') },
      { value: 'LessThan', displayValue: this.translateHelper.instant('common_LessThan') },
      { value: 'StartsWith', displayValue: this.translateHelper.instant('common_StartsWith') },
      { value: 'EndsWith', displayValue: this.translateHelper.instant('common_EndsWith') },
      { value: 'IsEmpty', displayValue: this.translateHelper.instant('common_IsEmpty'), valueHidden: true },
      { value: 'IsNotEmpty', displayValue: this.translateHelper.instant('common_IsNotEmpty'), valueHidden: true },
    ],
    bool: [
      { value: 'Equals', displayValue: this.translateHelper.instant('common_Equals') },
      { value: 'DoesNotEqual', displayValue: this.translateHelper.instant('common_DoesNotEqual') },
      { value: 'IsEmpty', displayValue: this.translateHelper.instant('common_IsEmpty'), valueHidden: true },
      { value: 'IsNotEmpty', displayValue: this.translateHelper.instant('common_IsNotEmpty'), valueHidden: true },
    ],
    date: [
      { value: 'Equals', displayValue: this.translateHelper.instant('common_Equals') },
      { value: 'DoesNotEqual', displayValue: this.translateHelper.instant('common_DoesNotEqual') },
      { value: 'GreaterThan', displayValue: this.translateHelper.instant('common_GreaterThan') },
      { value: 'LessThan', displayValue: this.translateHelper.instant('common_LessThan') },
      { value: 'NeverUsed', displayValue: this.translateHelper.instant('common_NeverUsed'), valueHidden: true },
      { value: 'IsEmpty', displayValue: this.translateHelper.instant('common_IsEmpty'), valueHidden: true },
      { value: 'IsNotEmpty', displayValue: this.translateHelper.instant('common_IsNotEmpty'), valueHidden: true },
      { value: 'AtLeastOnce', displayValue: this.translateHelper.instant('common_AtLeast_Once'), valueHidden: true },
    ],
  }

  operations: Record<string, { value: string; displayValue: string; valueHidden?: boolean }[]>

  booleanValues = [
    { value: 'true', displayValue: this.translateHelper.instant('common_True') },
    { value: 'false', displayValue: this.translateHelper.instant('common_False') },
  ]

  constructor(private translateHelper: TranslateHelper) {
    this.singleLevel = true
    this.valueChange = () => {}
    this.operations = this.defaultOperations
  }

  static addNewFilterRow(condition: string, columns: any[]) {
    return new UntypedFormGroup({
      condition: new UntypedFormControl(condition, Validators.required),
      name: new UntypedFormControl(columns.length === 1 ? columns[0].value : null, Validators.required),
      nameObj: new UntypedFormControl(columns.length === 1 ? columns[0] : null, Validators.required),
      operation: new UntypedFormControl(null, Validators.required),
      valueObj: new UntypedFormControl(null, Validators.required),
      value: new UntypedFormControl(null, Validators.required),
      valueDate: new UntypedFormControl(null),
    })
  }

  ngOnInit() {
    this.mergeOperations()

    this.controller.controls.forEach((x) => {
      this.setValueRequired(x.value.nameObj, x.value.operation, x)
      this.setRootCondition(x.value.condition)
    })
    
    if (this.showAtLeastOneLine && this.controller.controls.length === 0) {
      this.andClicked()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customOperations) {
      this.mergeOperations()
    }
  }

  mergeOperations() {
    this.operations = { ...this.defaultOperations, ...this.customOperations }
  }

  andClicked(): void {
    this.showOrButton = false
    this.controller.markAllAsTouched()
    if (this.controller.valid) {
      this.controller.push(FiltersComponent.addNewFilterRow('AND', this.columns))
      this.controller.markAsDirty()
    }
  }

  orClicked(): void {
    this.showAndButton = false
    this.controller.markAllAsTouched()
    if (this.controller.valid) {
      this.controller.push(FiltersComponent.addNewFilterRow('OR', this.columns))
      this.controller.markAsDirty()
    }
  }

  setRootCondition(condition: string) {
    if (condition.toUpperCase() === 'AND') {
      this.showAndButton = true
      this.showOrButton = false
    } else if (condition.toUpperCase() === 'OR') {
      this.showAndButton = false
      this.showOrButton = true
    }
  }

  setValueDate(control: any, date: dayjs.Dayjs | null) {
    control.get('valueDate')?.setValue(date ? date.toISOString() : null)
  }

  setValueRequired(inputValue: any, selectValue: any, control: any) {
    const valueHidden = this.isValueHidden(inputValue, selectValue)
    if (!valueHidden && inputValue?.data?.type !== 'date' && inputValue?.data?.type !== 'bool') {
      ;(control.get('valueObj') as UntypedFormControl).setValidators([Validators.required])
    } else {
      ;(control.get('valueObj') as UntypedFormControl).clearValidators()
    }

    if (!valueHidden && inputValue?.data?.type === 'bool') {
      ;(control.get('value') as UntypedFormControl).setValidators([Validators.required])
    } else {
      ;(control.get('value') as UntypedFormControl).clearValidators()
    }

    if (!valueHidden && inputValue?.data?.type === 'date') {
      ;(control.get('valueDate') as UntypedFormControl).setValidators([Validators.required])
    } else {
      ;(control.get('valueDate') as UntypedFormControl).clearValidators()
    }
    ;(control.get('value') as UntypedFormControl).updateValueAndValidity()
    ;(control.get('valueObj') as UntypedFormControl).updateValueAndValidity()
    ;(control.get('valueDate') as UntypedFormControl).updateValueAndValidity()
  }

  isValueHidden(inputValue: any, selectValue: any) {
    if (!inputValue || !selectValue) {
      return false
    }
    const opts = this.operations[inputValue?.data?.type !== 'date' && inputValue?.data?.type !== 'bool' ? 'rest' : inputValue?.data?.type]
    return opts.filter((x) => x.value === selectValue)[0]?.valueHidden
  }

  deleteFilter(index: number): void {
    this.controller.removeAt(index)
    this.controller.markAsDirty()

    if (this.controller.length <= 1) {
      this.showAndButton = true
      this.showOrButton = true
    }

    this.filterRemoved.emit()
  }

  filtersFields(value: string | null) {
    return !!value ? this.columns.filter((x) => x.displayValue.toUpperCase().indexOf(value.toUpperCase()) >= 0) : this.columns
  }

  filterValueField(value: string | null, control: any, inpvalue: any) {
    const name = (control.get('nameObj') as UntypedFormControl)?.value?.value
    //set also value field, for api that doesnt know valueObj field
    ;(control.get('value') as UntypedFormControl)?.setValue(value)
    this.valueChange({ fieldName: name, value })?.subscribe((val: any) => {
      inpvalue.suggestions = val?.values?.map((x: any) => ({ value: x, displayValue: x }))
    })
  }

  filterValueSelected(event: Suggestion | null, control: any) {
    ;(control.get('value') as UntypedFormControl)?.setValue(event?.value)
  }

  handleNameModelChange(d: AbstractControl, event: any) {
    d.get('value')?.setValue(null)
    d.get('valueObj')?.setValue(null)
    d.get('valueDate')?.setValue(null)
    d.get('name')?.setValue(event?.value)
    this.setValueRequired(d.value.nameObj, d.value.operation, d)
  }
}

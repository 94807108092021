<ng-container>
  <app-reports [clientTableConfiguration]="configuration" [searchInProgress]="searchInProgress" [progressMessage]="progressMessage">
    <cv-smart-panel
      [title]="'reports_FilterAssistant' | translate"
      style="padding-top: 10px; overflow: auto"
      [open]="true"
      customSmartPanel
    >
      <div class="filters-container" style="cursor: default" content>
        <form [formGroup]="form">
          <div class="title-container">
            <div class="title" translate>common_FilterAssistant</div>
          </div>
          <div class="subtitle" translate>reports_FilterAssistantMessageTraceSubTitle</div>

          <div class="select-section">
            <cv-custom-daterangepicker
              *ngIf="!resetInProgress"
              placeholder="{{ 'reports_CustomRange' | translate }}"
              (rangeChange)="onRangeChange($event)"
              [minDate]="minDate"
              [localeIso]="localeIso"
              [defaultRange]="defaultRange"
              [rangeOptions]="rangeOptions"
              [showCustomRangeAsLabel]="true"
            >
            </cv-custom-daterangepicker>
          </div>

          <div class="select-section">
            <app-datetime-utc-picker
              [dateLabel]="'common_StartDate' | translate"
              formControlName="startDate"
              [disableUtc]="true"
              [minDate]="minDate"
              [maxDate]="maxDate"
            ></app-datetime-utc-picker>
            <app-datetime-utc-picker
              class="m-t-10"
              [dateLabel]="'common_EndDate' | translate"
              formControlName="endDate"
              [disableUtc]="true"
              [minDate]="minDate"
              [maxDate]="maxDate"
            ></app-datetime-utc-picker>
          </div>

          <div class="select-section">
            <cv-autocomplete-input
              label="{{ 'common_Sender' | translate }}"
              [placeholder]="'common_Sender' | translate"
              [suggestions]="sendersSuggestions"
              leftIcon=""
              formControlName="sender"
              (inputChange)="senderSelectionUpdate.next($event)"
              (ngModelChange)="onChangeSender($event)"
              (selectionChanged)="senderSelectionChanged($event)"
              class="full-width"
              autocomplete="off"
              [required]="false"
            >
            </cv-autocomplete-input>
          </div>

          <div class="select-section">
            <cv-input label="{{ 'common_SenderDomain' | translate }}" formControlName="senderDomain"> </cv-input>
          </div>

          <div class="select-section">
            <cv-autocomplete-input
              label="{{ 'common_Recipient' | translate }}"
              [placeholder]="'common_Recipient' | translate"
              [suggestions]="recipientsSuggestions"
              leftIcon=""
              formControlName="recipient"
              (inputChange)="recipientSelectionUpdate.next($event)"
              (ngModelChange)="onChangeRecipient($event)"
              (selectionChanged)="recipientSelectionChanged($event)"
              class="full-width"
              autocomplete="off"
              [required]="false"
            >
            </cv-autocomplete-input>
          </div>

          <div class="select-section">
            <cv-input label="{{ 'common_RecipientDomain' | translate }}" formControlName="recipientDomain"> </cv-input>
          </div>

          <div class="select-section">
            <cv-multi-select
              class="w100"
              formControlName="status"
              [label]="'common_Status' | translate"
              [suggestions]="statusSuggestions || []"
              (selectionChanged)="statusSelectionChanged($event)"
            >
            </cv-multi-select>
          </div>

          <div class="select-section">
            <cv-input label="{{ 'common_Subject' | translate }}" formControlName="subject"> </cv-input>
          </div>
        </form>
      </div>

      <div footer>
        <div class="error-container" *ngIf="getError()" style="text-align: left">
          <span>{{ getError() }}</span>
        </div>

        <cv-button (click)="reset()" class="m-r-10" buttonType="secondary" [text]="'common_Reset' | translate"> </cv-button>
        <cv-button [disabled]="form.invalid" (click)="search()" primary="secondary" [text]="'common_Search' | translate" [disabled]="isSearchDisabled()"> </cv-button>
      </div>
    </cv-smart-panel>
  </app-reports>
</ng-container>

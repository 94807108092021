<cv-alert-bar *ngIf="!!disclaimer" class="m-b-10" [hideCloseButton]="true">
  <div translate>{{ disclaimer.message }}</div>
  <div class="m-t-10 f-s-12" *ngIf="disclaimer.secondaryMessageTranslated">{{ disclaimer.secondaryMessageTranslated }}</div>
</cv-alert-bar>
<div *ngIf="formReady">
  <div *ngIf="!!form.controls.length" style="display: grid; grid-template-columns: 1fr 1fr 1fr; column-gap: 15px" class="m-b-10">
    <span translate>management_Fields</span>
    <span translate>management_Values</span>
    <span translate>management_Remove</span>
  </div>
  <form
    style="display: grid; grid-template-columns: 1fr 1fr 1fr; column-gap: 15px"
    class="m-b-15"
    [formGroup]="$any(field)"
    *ngFor="let field of form.controls"
  >
    <cv-select
      style="width: 100%"
      formControlName="key"
      [label]="fieldPlaceholder | translate"
      [suggestions]="getAvailableFields(field.get('key')?.value)"
      [disabled]="!!field.get('key')?.value"
      (selectionChanged)="onChangeField($event)"
    ></cv-select>
    <ng-container *ngIf="!!field.get('key')?.value" [ngSwitch]="fields[field.get('key')?.value]?.type">
      <cv-select
        style="width: 100%"
        *ngSwitchCase="'select'"
        formControlName="value"
        [label]="'management_Value' | translate"
        [placeholder]="'management_SelectValue' | translate"
        [suggestions]="getValueOptions(field.get('key')?.value || '')"
      ></cv-select>
      <cv-autocomplete-string-input
        style="width: 100%"
        *ngSwitchCase="'suggester-string'"
        formControlName="value"
        (inputChange)="suggestersSubjects[field.get('key')?.value].next($event)"
        [label]="'management_Value' | translate"
        [placeholder]="'management_SearchValue' | translate"
        [suggestions]="getValueOptions(field.get('key')?.value || '')"
        [forceSelection]="false"
      ></cv-autocomplete-string-input>
      <cv-radio-group
        style="width: 100%; align-self: center;"
        *ngSwitchCase="'bool'"
        formControlName="value"
        orientation="horizontal"
        [suggestions]="booleanSuggestions"
      >
      </cv-radio-group>
      <cv-datepicker style="width: 100%" formControlName="value" *ngSwitchCase="'date'" [localeIso]="localeIso" [label]="'management_Value' | translate">
      </cv-datepicker>
      <cv-input
        style="width: 100%"
        *ngSwitchDefault
        formControlName="value"
        [label]="'management_Value' | translate"
        [inputType]="getInputType(field.get('key')?.value || '')"
      >
      </cv-input>
      <mat-icon (click)="deleteField(field.get('key')?.value || '')" class="delete-icon">close</mat-icon>
    </ng-container>
  </form>
  <cv-button text="{{ 'common_Add' | translate }}" leftIcon="add" [disabled]="!canAddNewControl" (click)="addControl()"></cv-button>
</div>
<div *ngIf="!formReady" style="display: flex">
  <div class="loader m-r-5"></div>
  <span translate>management_LoadingFields</span>
</div>

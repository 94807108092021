<div *ngIf="searchable" class="three-columns-grid p-b-10">
  <cv-input
    [label]="searchLabel | translate"
    [clearButton]="true"
    (inputChange)="filterChanged($event)"
    rightIcon="search"
    class="first-column"
  ></cv-input>
</div>

<div *ngIf="!hideSelectAll">
  <ng-container *ngIf="isShowingNodes(); else noResultsFound">
    <mat-checkbox
      [disabled]="readonly"
      [checked]="areAllVisibleNodesSelected()"
      [indeterminate]="areVisibleNodesPartiallySelected()"
      (change)="onSelectAllChanged($event)"
      >
      <ng-container>{{ (!!searchValue ? 'common_SelectAllSearchResult' : 'common_SelectAll') | translate }}</ng-container>
    </mat-checkbox>
  </ng-container>
  <ng-template #noResultsFound>
    <span>{{ 'generic_NoResults' | translate }}</span>
  </ng-template>
</div>

<mat-tree class="fl-1" [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodeToggle
    matTreeNodePadding
    matTreeNodePaddingIndent="26px"
    [style.display]="node.hidden ? 'none' : 'flex'"
    [ngClass]="{ selected: checklistSelection.isSelected(node) }"
  >
    <mat-checkbox
      [disabled]="readonly || isNodeReadonly(node)"
      class="checklist-leaf-node"
      [checked]="checklistSelection.isSelected(node)"
      (change)="todoLeafItemSelectionToggle(node)"
      ><ng-container *ngIf="node.icon"> <em class="{{ node.icon }} node-icon"></em>&nbsp;</ng-container>
      <ng-container>{{ node.name }}</ng-container>
    </mat-checkbox>
  </mat-tree-node>

  <mat-tree-node
    *matTreeNodeDef="let node; when: hasChild"
    matTreeNodePadding
    matTreeNodePaddingIndent="26px"
    [style.display]="node.hidden ? 'none' : 'flex'"
    [ngClass]="{ selected: descendantsAllSelected(node) }"
    class="expandable"
  >
    <button matTreeNodeToggle class="btn">
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>
    <mat-checkbox
      [disabled]="readonly || isNodeReadonly(node)"
      [checked]="descendantsAllSelected(node)"
      [indeterminate]="descendantsPartiallySelected(node)"
      (change)="todoItemSelectionToggle(node)"
      ><ng-container *ngIf="node.icon"> <em class="{{ node.icon }} node-icon"></em>&nbsp;</ng-container>
      <ng-container>{{ node.name }}</ng-container>
    </mat-checkbox>
  </mat-tree-node>
</mat-tree>

import { Component, OnInit } from '@angular/core'
import { ICellRendererParams } from '@ag-grid-community/core'

@Component({
  selector: 'app-action-cell',
  templateUrl: './action-cell-renderer.component.html',
  styleUrls: ['./action-cell-renderer.component.sass'],
})
export class ActionCellRendererComponent {
  public params: any
  public cellRendererParams: any

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params
    this.cellRendererParams = params.colDef?.cellRendererParams
  }

  buttonClicked($event: any) {
    if (this.cellRendererParams && this.cellRendererParams.func) {
      this.cellRendererParams.func($event, this.params.actionId, this.params.data)
      return
    }
  }
}

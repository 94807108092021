import { Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges } from '@angular/core'
import { NgControl } from '@angular/forms'
import { LicensePoolGroup, LicensePoolInformation } from '@app/core/models/LicensePoolGroup'
import { AccountSkuDetail, ManagementAccountSkuDetail } from '@app/core/models/account-sku-details'
import { AdministrationsService } from '@app/core/services/administrations.service'
import { LicenseService } from '@app/core/services/license.service'
import { TranslateHelper } from '@coreview/coreview-library'
import { BaseControlComponent, Suggestion } from '@coreview/coreview-components'
import { cloneDeep, isArray, isNumber } from 'lodash-es'
import { BehaviorSubject, combineLatest, of } from 'rxjs'
import { filter, first, pluck, switchMap, tap } from 'rxjs/operators'
import { CustomComponentReady } from '@coreview/coreview-dynamoforms'

@Component({
  selector: 'app-licenses-picker',
  templateUrl: './licenses-picker.component.html',
  styleUrls: ['./licenses-picker.component.sass'],
})
export class LicensesPickerComponent extends BaseControlComponent implements OnInit, OnChanges, CustomComponentReady {
  @Input()
  targetsCount = 1

  @Input()
  userPrincipalName?: string | string[]

  @Input()
  licensePoolGroupName?: string

  @Input()
  isCloneAction?: boolean

  @Input()
  isCreateAction?: boolean

  @Input()
  licensesType?: 'default' | 'workflow' | 'workflowV2' = 'default'

  @Input()
  companyId?: string

  selectedTemplateId = ''
  selectedLicensePoolId = ''
  filterLicense = ''

  licensePoolInfoMapping: Record<string, LicensePoolInformation[]> = {}

  loading = true
  valueLoading = false
  hasTemplatesToShow = false

  @Input()
  get value(): LicensePickerValue {
    return this.getValue()
  }

  @Output() customComponentReady = new EventEmitter()

  set value(value: string | string[] | LicensePickerValue) {
    if (value) {
      if (typeof value === 'string') {
        value = JSON.parse(value) as string[]
      }
      // when we reload the picker while having values selected (e.g. on workflows)
      if (this.isWorkflowValue(value) || this.isLicensePickerValue(value)) {
        this.getLicensesFromUserPrincipalName(this.userPrincipalName, value).subscribe()
      }
    }
  }

  ready = new BehaviorSubject<boolean>(false)

  originalLicenses: Record<string, AccountSkuDetail> = {}
  licenses!: License[]

  initialEmptyLicenses!: License[]
  initialMappedLicenses!: License[]

  templateSuggestions: Suggestion[] = []
  licensePoolSuggestions: Suggestion[] = []
  licensePoolMode?: 'Enforce' | 'Assign'

  servicePlans: { msServiceName: string; serviceName: string }[] = []

  licenseOptions: LicenseOption[] = [{ key: 'remove', text: this.translateHelper.instant('common_Remove') }]
  license1TargetOptions: LicenseOption[] = [{ key: 'remove', text: this.translateHelper.instant('common_NotAssigned') }]

  enableLicenseOption: LicenseOption = { key: 'assign', text: this.translateHelper.instant('common_Assign') }
  enableLicense1TargetOption: LicenseOption = { key: 'assign', text: this.translateHelper.instant('common_Assigned') }

  plansOptions: PlanOption[] = [{ key: 'disable', text: this.translateHelper.instant('common_Disable') }]
  plans1TargetOptions: PlanOption[] = [{ key: 'disable', text: this.translateHelper.instant('common_Disabled') }]

  enablePlanOption: PlanOption = { key: 'enable', text: this.translateHelper.instant('common_Enable') }
  enablePlan1TargetOption: PlanOption = { key: 'enable', text: this.translateHelper.instant('common_Enabled') }

  ignoreLicenseOption: LicenseOption = { key: 'ignore', text: this.translateHelper.instant('common_Ignore') }
  ignorePlanOption: PlanOption = { key: 'ignore', text: this.translateHelper.instant('common_Ignore') }

  planActionStatusMapping: Record<ActionPlanStatus, PlanStatus> = {
    enable: 'enabled',
    disable: 'disabled',
    ignore: 'ignored',
  }

  licenseActionStatusMapping: Record<ActionLicenseStatus, LicenseStatus> = {
    assign: 'assigned',
    remove: 'removed',
    ignore: 'ignored',
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private licenseService: LicenseService,
    private administrationService: AdministrationsService,
    private translateHelper: TranslateHelper,
  ) {
    super(ngControl)
  }

  getLicenseStatusFromLicenseType(): LicenseStatus {
    return this.licensesType === 'workflowV2' ? 'ignored' : 'removed'
  }
  getPlanStatusFromLicenseType(): PlanStatus {
    return this.licensesType === 'workflowV2' ? 'ignored' : 'disabled'
  }

  ngOnInit(): void {
    combineLatest([
      this.licenseService.getServicePlans().pipe(pluck('servicePlans')),
      this.licenseService.getAccountSkus(),
      this.licenseService.getMyLicenseTemplates(),
      this.administrationService.getLicensePoolConfiguration().pipe(pluck('licensePoolConfiguration')),
      this.administrationService.getAvailableLicensePools(),
    ])
    .pipe(
      switchMap(([servicePlans, licenses, templatesResponse, licensePoolConfiguration, availableLicensePools]) => {
        this.servicePlans = servicePlans
        this.licenses = licenses?.map((x: AccountSkuDetail) => {
          this.originalLicenses[x.id] = x
          return {
            id: x.id,
            sku: x.sku,
            remainingUnits: x.remainingUnits,
            expanded: false,
            status: this.getLicenseStatusFromLicenseType(),
            plans: x.microsoftServicePlans.map(
              (p: string) =>
              ({
                status: this.getPlanStatusFromLicenseType(),
                oldStatus: this.getPlanStatusFromLicenseType(),
                title: this.getServiceName(p, servicePlans),
                name: p,
              } as Plan)
            ),
            disabled: x.remainingUnits < this.targetsCount && !this.isWorkflow,
            selectedPlansCount: 0,
            hidden: false,
          }
        }).sort(this.sortLicenses)
        this.initialEmptyLicenses = cloneDeep(this.licenses)
        this.setLicensesTemplates(templatesResponse)

        if (!this.isWorkflow) {
          availableLicensePools?.sort(this.licensePoolGroupCompare)
          this.licensePoolSuggestions = availableLicensePools.map((x) => {
            this.licensePoolInfoMapping[x.id] = x.licensePoolInfos
            return {
              value: x.id,
              displayValue: x.name,
              data: x,
            }
          })
          this.licensePoolMode = licensePoolConfiguration?.licensePoolMode

          if (!!this.userPrincipalName && !!this.licensePoolGroupName) {
            this.setLicensePoolFromLicensePoolName()
          } else if (!this.licensePoolGroupName && this.targetsCount === 1) {
            this.licensePoolGroupName = 'Unmanaged'
            this.setLicensePoolFromLicensePoolName()
            this.licensePoolSelected()
          }
        }

        this.ready.next(true)
        if (this.userPrincipalName) {
          return this.getLicensesFromUserPrincipalName(this.userPrincipalName)
        } else {
          return of(null)
        }
      })
    ).subscribe(() => {
      if (this.isWorkflow) {
        this.initialMappedLicenses = cloneDeep(this.licenses)
        this.onChange(this.getValue())
      }
      this.loading = false
      this.customComponentReady.emit()
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userPrincipalName?.currentValue?.length === 1) {
      if (this.licensePoolGroupName) {
        this.setLicensePoolFromLicensePoolName()
      }
      this.getLicensesFromUserPrincipalName(changes.userPrincipalName.currentValue).subscribe()
    }
    if (changes.licensePoolGroupName?.currentValue) {
      this.setLicensePoolFromLicensePoolName()
      this.licensePoolSelected()
    }
  }

  licensePoolGroupCompare(a: LicensePoolGroup, b: LicensePoolGroup): number {
    return a.unmanegedGroup === b.unmanegedGroup ? a.name.localeCompare(b.name) : Number(b.unmanegedGroup) - Number(a.unmanegedGroup)
  }

  planStatusChanged(l: License, p: Plan, action: 'enable' | 'disable' | 'ignore') {
    if (p.status !== this.planActionStatusMapping[action]) {
      p.status = this.planActionStatusMapping[action]
      this.checkLicenseStatus(l)
      this.recalculateSelectedPlansCount(l)
      this.onChange(this.getValue())
    }
  }

  isShowNoResult(): boolean {
    return this.licenses.length > 0 && this.licenses.every((license) => license.hidden)
  }

  isShowNoLicense(): boolean {
    return this.licenses.length === 0
  }

  isShowError(): boolean {
    return this.licenses.length > 0 && this.licenses.every((license) => license.hidden)
  }

  getPlanStatusFromAction(action: ActionLicenseStatus): PlanStatus {
    switch (action) {
      case 'assign':
        return 'enabled'
      case 'remove':
        return 'disabled'
      default:
        return 'ignored'
    }
  }

  licenseStatusChanged(l: License, action: ActionLicenseStatus) {
    if (l.status !== this.licenseActionStatusMapping[action]) {
      if (action === 'assign' && l.remainingUnits < this.targetsCount && !this.isWorkflow) {
        return
      }
      const plansEnabled = l.plans.filter((x) => !x.disabled)
      plansEnabled.forEach((p: Plan) => (p.status = this.getPlanStatusFromAction(action)))
      l.status = this.licenseActionStatusMapping[action]
      this.recalculateSelectedPlansCount(l)
      this.onChange(this.getValue())
    }
  }

  templateSelected(templateId: string) {
    if (templateId) {
      this.licenseService
        .getManagementAccountSkuDetailsTemplate(templateId, this.getUserPrincipalNameForApi(this.userPrincipalName))
        .subscribe((x) => {
          this.licenses = this.convertManagementToLicenses(x).sort(this.sortLicenses)
          this.applyFilter()
          this.onChange(this.getValue())
        })
    } else {
      if (this.userPrincipalName) {
        this.getLicensesFromUserPrincipalName(this.userPrincipalName).subscribe()
      } else {
        this.resetToInitialMappedLicenses()
        this.applyFilter()
      }
    }
  }

  filterChanged(text: string) {
    this.filterLicense = text
    this.applyFilter()
  }

  applyFilter() {
    if (this.filterLicense.length === 0) {
      this.licenses = this.licenses.map(this.getLicenseVisible).sort(this.sortLicenses)
    } else {
      this.licenses = this.licenses.map((l) => {
        if (l.sku.toLowerCase().includes(this.filterLicense.toLowerCase())) {
          return this.getLicenseVisible(l)
        } else {
          const newLicense = {
            ...l,
            plans: l.plans.map((p) => ({
              ...p,
              hidden: !p.title.toLowerCase().includes(this.filterLicense.toLowerCase()),
            })),
          }
          return {
            ...newLicense,
            hidden: !newLicense.plans.some((p) => !p.hidden),
          }
        }
      }).sort(this.sortLicenses)
    }
  }

  getLicenseVisible(license: License): License {
    return {
      ...license,
      hidden: false,
      plans: license.plans.map((plan) => ({
        ...plan,
        hidden: false,
      })),
    }
  }

  getDisablePlanStatus(): PlanStatus {
    return this.ignoreEnable ? 'ignored' : 'disabled'
  }

  licensePoolSelected() {
    this.licensePoolGroupName = this.licensePoolSuggestions.find((x) => x.value === this.selectedLicensePoolId)?.displayValue
    if (this.selectedTemplateId) {
      this.templateSelected(this.selectedTemplateId)
    } else {
      this.licenses = cloneDeep(this.licenses).map((l) => {
        const remainingUnits = this.getRemainingUnits(l.id)
        const license: License = {
          ...l,
          remainingUnits,
          disabled: false,
          plans: l.plans.map((p) => ({
            ...p,
            status:
              p.status === 'enabled' && (p.oldStatus === 'enabled' || this.targetsCount <= remainingUnits)
                ? 'enabled'
                : this.getDisablePlanStatus(),
          })),
        }
        license.disabled =
          !this.isWorkflow && this.targetsCount === 1 && remainingUnits < 1 && !license.plans.find((p) => p.oldStatus === 'enabled')
        this.checkLicenseStatus(license)
        this.recalculateSelectedPlansCount(license)
        return license
      }).sort(this.sortLicenses)
      this.onChange(this.getValue())
    }
    this.applyFilter()
  }

  getEnableLicenseOption(status?: LicenseStatus): LicenseOption {
    if ((this.targetsCount === 1 && status && status === 'assigned') || this.isCreateAction) {
      return this.enableLicense1TargetOption
    } else {
      return this.enableLicenseOption
    }
  }

  getRemoveLicenseOption(status?: LicenseStatus): LicenseOption[] {
    if ((this.targetsCount === 1 && status && status === 'removed') || this.isCreateAction) {
      return this.license1TargetOptions
    } else {
      return this.licenseOptions
    }
  }

  getOptions(remainingUnits: number, persistedStatus?: LicenseStatus) {
    const options =
      this.targetsCount <= remainingUnits || persistedStatus === 'assigned' || this.isWorkflow
        ? this.getRemoveLicenseOption(persistedStatus).concat([this.getEnableLicenseOption(persistedStatus)])
        : this.getRemoveLicenseOption(persistedStatus)
    return this.ignoreEnable ? options.concat([this.ignoreLicenseOption]) : options
  }

  getEnablePlanOption(planStatus?: PlanStatus): PlanOption {
    if ((this.targetsCount === 1 && planStatus && planStatus === 'enabled') || this.isCreateAction) {
      return this.enablePlan1TargetOption
    } else {
      return this.enablePlanOption
    }
  }

  getDisabledPlanOption(planStatus?: PlanStatus): PlanOption[] {
    if ((this.targetsCount === 1 && planStatus && planStatus === 'disabled') || this.isCreateAction) {
      return this.plans1TargetOptions
    } else {
      return this.plansOptions
    }
  }

  getPlansOptions(remainingUnits: number, persistedLicenseStatus?: LicenseStatus, persistedPlanStatus?: PlanStatus) {
    const options =
      this.targetsCount <= remainingUnits || persistedLicenseStatus === 'assigned' || this.isWorkflow
        ? this.getDisabledPlanOption(persistedPlanStatus).concat([this.getEnablePlanOption(persistedPlanStatus)])
        : this.getDisabledPlanOption(persistedPlanStatus)
    return this.ignoreEnable ? options.concat([this.ignorePlanOption]) : options
  }

  public get isWorkflow(): boolean {
    return !!this.licensesType && this.licensesType !== 'default'
  }

  private setLicensesTemplates(templatesResponse: { areAllTemplates: boolean; items: { id: string; name: string }[] }) {
    if (templatesResponse?.areAllTemplates) {
      this.templateSuggestions = [{ value: '', displayValue: this.translateHelper.instant('None') }]
    }
    if (templatesResponse?.items?.length) {
      this.hasTemplatesToShow = true
      this.templateSuggestions.push(...templatesResponse.items.map((x) => ({ value: x.id, displayValue: x.name, data: x })))
    }
    if (this.hasTemplatesToShow) {
      this.selectedTemplateId = this.templateSuggestions[0].value
    }
  }

  private getUserPrincipalNameForApi(userPrincipalName?: string | string[]) {
    const items = isArray(userPrincipalName) ? userPrincipalName[0] : userPrincipalName
    return this.targetsCount === 1 ? items : undefined
  }

  private setValue(value: LicensePickerValue | ManagementAccountSkuDetail[]) {
    if (!!value && !this.isLicensePickerValue(value)) {
      this.licenses = this.convertManagementToLicenses(value as ManagementAccountSkuDetail[]).sort(this.sortLicenses)
      this.onChange(this.getValue())
      this.valueLoading = false
      return
    }

    value = value as LicensePickerValue

    this.resetLicenses()

    if (value?.licenses?.length) {
      value.licenses.sort(this.sortLicenses)
      this.licenses = value.licenses
    }

    value?.licensesToAdd?.forEach((x) => {
      const license = this.licenses.find((l) => l.id === x.id)
      if (license) {
        license?.plans.forEach((p) => {
          if (x.services?.split(',')?.includes(p.name)) {
            p.oldStatus = p.status = 'enabled'
          }
        })
        this.checkLicenseStatus(license)
        this.recalculateSelectedPlansCount(license)
      }
    })

    value?.licensesToDisable?.forEach((x) => {
      const license = this.licenses.find((l) => l.id === x.id)
      if (license) {
        license?.plans.forEach((p) => {
          if (x.services?.split(',')?.includes(p.name)) {
            p.disabled = true
          }
        })
        license.disabled = !!x.disabled
      }
    })
    this.onChange(this.getValue())
    this.valueLoading = false
  }

  private getLicensesFromUserPrincipalName(
    userPrincipalName?: string[] | string,
    previouslySetValues?: LicensePickerValue | ManagementAccountSkuDetail[] | string[]
  ) {
    this.targetsCount = isArray(userPrincipalName) ? userPrincipalName.length : 1;
    const upn = this.getUserPrincipalNameForApi(userPrincipalName);
    const getValue = this.selectedTemplateId
      ? this.licenseService.getManagementAccountSkuDetailsTemplate(this.selectedTemplateId, upn)
      : this.licenseService.getManagementAccountSkuDetails(upn);
    this.valueLoading = true;

    return getValue.pipe(
      tap((accSkuDetails) => {
        if (this.ready.value) {
              this.setValueAndMappedLicenses(accSkuDetails, previouslySetValues, this.isWorkflow);
        } else {
          this.ready
            .pipe(
              filter((x) => !!x),
              first()
            )
            .subscribe((x) => {
              this.setValueAndMappedLicenses(accSkuDetails, previouslySetValues, this.isWorkflow);
            })
        }
      })
    );
  }


  private setValueAndMappedLicenses(
    accountSkuDetails: ManagementAccountSkuDetail[],
    previouslySetValues?: LicensePickerValue | ManagementAccountSkuDetail[] | string[],
    isWorkflow?: boolean
  ) {
    if (isWorkflow) {
      const parsedValue = this.setInitialWorkflowValues(accountSkuDetails, previouslySetValues as string[])
      this.setValue(parsedValue)
    } else {
      this.setValue(accountSkuDetails)
    }
    this.initialMappedLicenses = cloneDeep(this.licenses)
    this.applyFilter()
  }

  private setLicensePoolFromLicensePoolName() {
    const selectedLp = this.licensePoolSuggestions.find((x) => x.displayValue === this.licensePoolGroupName)
    if (selectedLp) {
      this.selectedLicensePoolId = selectedLp.value
    }
  }

  private resetToInitialMappedLicenses() {
    this.licenses = (cloneDeep(this.initialMappedLicenses) || this.licenses).map((l) => ({
      ...l,
      remainingUnits: this.getRemainingUnits(l.id),
    })).sort(this.sortLicenses)
    this.onChange(this.getValue())
  }

  private resetLicenses() {
    this.licenses = cloneDeep(this.initialEmptyLicenses).sort(this.sortLicenses)
  }

  private getToAdd(l: License, p: Plan): boolean {
    return l.status === 'assigned' && p.status === 'enabled' && (l.remainingUnits >= this.targetsCount || l.oldStatus === 'assigned')
  }

  private getToRemove(l: License, p: Plan): boolean {
    return p.status === 'disabled' || (this.targetsCount === 1 && l.status === 'removed' && p.oldStatus !== 'disabled')
  }

  private getSkuToRemoved(l: License): boolean {
    return this.targetsCount == 1 ? l.status === 'removed' && l.oldStatus !== 'removed' : l.status === 'removed'
  }

  private getValue(): LicensePickerValue {
    const licensesToAdd: { id: string; services: string }[] = []
    const licensesToRemove: { id: string; services: string }[] = []
    const licensesToIgnore: { id: string; services: string }[] = []
    const skusToRemove: { id: string }[] = []
    this.licenses.forEach((l) => {
      const toAdd: string[] = []
      const toRemove: string[] = []
      const toIgnore: string[] = []
      if (this.getSkuToRemoved(l)) {
        skusToRemove.push({ id: l.id })
      } else {
        l.plans.forEach((p) => {
          if (p.status !== p.oldStatus || (l.status === 'removed' && p.oldStatus !== 'disabled') || !!this.isCloneAction) {
            if (this.getToAdd(l, p)) {
              toAdd.push(p.name)
            } else if (this.getToRemove(l, p)) {
              toRemove.push(p.name)
            } else {
              toIgnore.push(p.name)
            }
          }
        })
        if (toAdd.length) {
          licensesToAdd.push({ id: l.id, services: toAdd.join(',') })
        }
        if (toRemove.length) {
          licensesToRemove.push({ id: l.id, services: toRemove.join(',') })
        }
        if (toIgnore.length) {
          licensesToIgnore.push({ id: l.id, services: toIgnore.join(',') })
        }
      }
    })


    const licenses = {
      licensesToAdd,
      licensesToRemove,
      licensesToIgnore,
      skusToRemove,
      licenses: this.licenses,
      licensePoolGroupId: this.selectedLicensePoolId,
      licensePoolGroupName: this.licensePoolGroupName,
    }
    if (this.isWorkflow) {
      return {
        ...licenses,
        workflowLicenses: this.formatSelectedLicensesForWorkflow(),
      }
    }
    return licenses
  }

  private getValueFromStatus(pStatus: PlanStatus, enable: any, disable: any, ignore: any, lStatus?: LicenseStatus) {
    if (lStatus === 'assigned' && pStatus === 'enabled') {
      return enable
    }
    if (pStatus === 'disabled') {
      return disable
    }
    return ignore
  }

  private formatSelectedLicensesForWorkflow(): string[] {
    const enableValue = this.licensesType === 'workflow' ? true : 1
    const disableValue = this.licensesType === 'workflow' ? false : 0
    const ignoreValue = this.licensesType === 'workflow' ? false : 2
    return this.licenses
      .map((l) => [
        l.id.replace(`${this.companyId}:`, '##SKU##'),
        ...(l.plans || []).map(
          (p) => p.name + '|' + this.getValueFromStatus(p.status, enableValue, disableValue, ignoreValue, l.status)
        ),
      ])
      .flat()
  }

  private getServiceName(msServiceName: string, servicePlans: { msServiceName: string; serviceName: string }[]): string {
    return servicePlans.find((x) => x.msServiceName === msServiceName)?.serviceName || msServiceName
  }

  private checkLicenseStatusEnabled(license: License): LicenseStatus {
    return license.plans.some((x: Plan) => x.status === 'enabled') ? 'assigned' : 'removed'
  }

  private checkLicenseStatus(license: License) {
    license.status = license.plans.every((x: Plan) => x.status === 'ignored') ? 'ignored' : this.checkLicenseStatusEnabled(license)
  }

  private recalculateSelectedPlansCount(license: License) {
    license.selectedPlansCount = license.status === 'assigned' ? license.plans?.filter((x) => x.status === 'enabled')?.length || 0 : 0
  }

  private getRemainingUnits(skuId: string) {
    const available = this.selectedLicensePoolId
      ? this.licensePoolInfoMapping[this.selectedLicensePoolId]?.find((x: any) => x.skuId === skuId)?.available
      : undefined
    return isNumber(available) ? available : this.originalLicenses[skuId]?.remainingUnits
  }

  private setLicenseStatus(isAssigned?: boolean): LicenseStatus {
    if (isAssigned) {
      return 'assigned'
    }
    return this.ignoreEnable ? 'ignored' : 'removed'
  }

  private setPlanStatus(isEnable?: boolean): PlanStatus {
    if (isEnable) {
      return 'enabled'
    }
    return this.ignoreEnable ? 'ignored' : 'disabled'
  }

  private getIsTemplatePlanEnabled(sku: ManagementAccountSkuDetail, plan: Omit<ManagementAccountSkuDetail, "servicePlans">): boolean {
    return sku.checked && plan.checked
  }

  private convertManagementToLicenses(managementLicenses: ManagementAccountSkuDetail[]) {
    return managementLicenses.map((sku) => {
      const remainingUnits = this.getRemainingUnits(sku.id)
      const l: License = {
        id: sku.id,
        sku: sku.label,
        remainingUnits,
        expanded: false,
        disabled: this.shouldDisabled(sku, remainingUnits),
        status: this.setLicenseStatus(sku.checked),
        oldStatus: this.setLicenseStatus(sku.checked),
        plans: sku.servicePlans.map((p) => {
          const isPlanChecked = this.selectedTemplateId ? this.getIsTemplatePlanEnabled(sku, p) : p.checked
          const status = this.calculateStatus(p)
          return {
            name: p.label,
            title: this.getServiceName(p.label, this.servicePlans),
            status,
            oldStatus: this.setPlanStatus(isPlanChecked),
            disabled: p.disabled,
            alwaysDisabled: p.disabled,
            hidden: false,
          }
        }),
        hidden: false,
      }
      if (!this.selectedTemplateId) {
        this.checkLicenseStatus(l)
      }
      this.recalculateSelectedPlansCount(l)
      return l
    })
  }

  public shouldDisabled(sku: ManagementAccountSkuDetail, remainingUnits: number): boolean{
    if (this.isWorkflow)
      return sku.disabled;

    return sku.disabled || (this.targetsCount === 1 && remainingUnits < 1 && !sku.checked);
  }

  private calculateStatus(p: Omit<ManagementAccountSkuDetail, 'servicePlans'>): PlanStatus {
    if (this.isWorkflow && p.workflowStatus) {
      return p.workflowStatus
    }
    return this.setPlanStatus(p.checked)
  }

  private isLicensePickerValue(value: any) {
    return (
      Object.keys(value || {}).includes('licensesToAdd') ||
      Object.keys(value || {}).includes('licensesToDisable') ||
      Object.keys(value || {}).includes('licensesToIgnore')
    )
  }

  private isWorkflowValue(value: any) {
    return isArray(value) && !!value.length && typeof value[0] === 'string' && value[0].startsWith('##SKU##')
  }

  private getPlanStateFromItems(splittedItem: string[]): PlanStatus {
    if (splittedItem[1] === '1' || splittedItem[1] === 'true') {
      return 'enabled'
    } else if (splittedItem[1] === '0' || splittedItem[1] === 'false' || this.licensesType === 'workflow') {
      return 'disabled'
    } else {
      return 'ignored'
    }
  }

  private getExtendedValues(previouslySetValues: string[]) {
    let index = ''
    return previouslySetValues.reduce((acc, v) => {
      const splittedItem = v.split('|')
      if (splittedItem.length === 2) {
        const state = this.getPlanStateFromItems(splittedItem)
        if (index && acc[index]) {
          acc[index][splittedItem[0]] = state
        }
      } else {
        index = v.split(':')[1]
        if (index) {
          acc[index] = {}
        }
      }
      return acc
    }, {} as Record<string, Record<string, PlanStatus>>)
  }

  private setInitialWorkflowValues(
    accountSkuDetails: ManagementAccountSkuDetail[],
    previouslySetValues: string[]
  ): ManagementAccountSkuDetail[] {
    if (Array.isArray(previouslySetValues)) {
      const extendedValues = this.getExtendedValues(previouslySetValues)
      return accountSkuDetails.map((v) => {
        const valIndex = v.id.split(':')[2]
        v.servicePlans = v.servicePlans.map((sp) => {
          if (valIndex && extendedValues[valIndex] && extendedValues[valIndex][sp.id]) {
            sp.workflowStatus = extendedValues[valIndex][sp.id]
          }
          return sp
        })
        return v
      })
    }
    return accountSkuDetails
  }

  private get ignoreEnable(): boolean {
    return (this.targetsCount > 1 || this.licensesType === 'workflowV2') && this.licensesType !== 'workflow'
  }

  private sortLicenses(a: License, b: License): number {
    if (a.oldStatus === 'assigned' && b.oldStatus === 'assigned') {
      return a.sku.localeCompare(b.sku)
    }

    const statusOrder = Number(a.oldStatus === 'assigned') - Number(b.oldStatus === 'assigned')
    return -statusOrder || Number(a.disabled) - Number(b.disabled) || a.sku.localeCompare(b.sku)
  }
}

export type LicenseStatus = 'assigned' | 'removed' | 'ignored'
export type ActionLicenseStatus = 'assign' | 'remove' | 'ignore'

export type PlanStatus = 'enabled' | 'disabled' | 'ignored'
export type ActionPlanStatus = 'enable' | 'disable' | 'ignore'

export type LicenseOption = { key: ActionLicenseStatus; text: string; icon?: string }
export type PlanOption = { key: ActionPlanStatus; text: string; icon?: string }

export interface License {
  id: string;
  status?: LicenseStatus;
  oldStatus?: LicenseStatus;
  selectedPlansCount?: number;
  sku: string;
  remainingUnits: number;
  expanded: boolean;
  disabled?: boolean;
  plans: Plan[];
  hidden: boolean;
}

export interface Plan {
  name: string;
  title: string;
  status: PlanStatus;
  oldStatus: PlanStatus;
  disabled?: boolean;
  hidden: boolean;
}

export interface LicensePickerValue {
  licensesToAdd: { id: string; services: string }[];
  licensesToRemove: { id: string; services: string }[];
  licensesToIgnore: { id: string; services: string }[];
  licensesToDisable?: { id: string; disabled: boolean; services: string }[];
  skusToRemove: { id: string }[];
  licensePoolGroupId?: string;
  licensePoolGroupName?: string;
  licenses?: License[];
  workflowLicenses?: string[];
}

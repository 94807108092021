import { RightPanelComponent } from './../components/right-panel/right-panel.component'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { ComponentFactoryResolver, EventEmitter, Injectable, Injector, Type } from '@angular/core'
import { Observable, of } from 'rxjs'
import { filter, tap } from 'rxjs/operators'

export abstract class IBeforeClose {
  abstract beforeClose(): Observable<boolean>
}

@Injectable({
  providedIn: 'root',
})
export class RightPanelService {
  private rightPanelItems: { actualComponent: any; overlayRef: OverlayRef }[] = []

  constructor(private resolver: ComponentFactoryResolver, private overlay: Overlay) {}

  open(componentData: { type: Type<any>; data?: any; events?: any; closeOnOutsideClick?: boolean }): RightPanelRef {
    const afterClosed$ = new EventEmitter<any>()

    const injector = Injector.create({
      providers: [
        {
          provide: RightPanelRef,
          useValue: {
            afterClosed: () => afterClosed$.asObservable(),
            close: (value: any = null) => {
              this.close()
                .pipe(filter((x) => !!x))
                .subscribe(() => {
                  afterClosed$.emit(value)
                  afterClosed$.complete()
                })
            },
          } as RightPanelRef,
        },
      ],
    })

    const componentFactory = this.resolver.resolveComponentFactory(componentData.type)
    const actualComponent = componentFactory.create(injector)

    if (componentData.data) {
      Object.assign(actualComponent.instance, componentData.data)
    }
    if (componentData.events) {
      Object.keys(componentData.events).forEach((x) => {
        ;(actualComponent.instance as any)[x].subscribe((e: any) => componentData.events[x](e))
      })
    }

    const overlayRef = this.overlay.create({
      height: '100%',
      width: '100%',
    })
    const componentPortalRef = overlayRef.attach(new ComponentPortal(RightPanelComponent, null, injector))
    componentPortalRef.instance.component = actualComponent
    componentPortalRef.instance.closeOnOutsideClick = componentData.closeOnOutsideClick ?? false

    this.rightPanelItems.push({ actualComponent, overlayRef })

    return {
      afterClosed: () => afterClosed$.asObservable(),
      close: () => {
        this.close()
          .pipe(filter((x) => !!x))
          .subscribe(() => {
            afterClosed$.complete()
          })
      },
    }
  }

  close(): Observable<boolean> {
    const length = this.rightPanelItems.length
    if (length > 0) {
      const { actualComponent, overlayRef } = this.rightPanelItems[length - 1]
      return (actualComponent.instance instanceof IBeforeClose ? actualComponent.instance.beforeClose() : of(true)).pipe(
        tap((x) => {
          if (x) {
            overlayRef.dispose()
            this.rightPanelItems.pop()
          }
        })
      )
    }
    return of(true)
  }
}

export abstract class RightPanelRef {
  abstract close: (value?: any) => void
  abstract afterClosed: () => Observable<any>
}

import { clearMessagesOfType, newMessage } from './messages.actions'
import { createReducer, on } from '@ngrx/store'
import { MessagesState } from './messages.types'

export const initialState: MessagesState = {
  messages: {},
  lastMessage: undefined,
}

export const messagesReducer = createReducer(
  initialState,
  on(newMessage, (state, { message }) =>
    !message
      ? state
      : {
          lastMessage: message,
          messages: { ...state.messages, [message.type]: [message, ...(state.messages[message.type] || [])] },
        }
  ),
  on(clearMessagesOfType, (state, { messageType }) => {
    const { [messageType]: _, ...newMessages } = state.messages || {};
    return {
      lastMessage: state.lastMessage,
      messages: newMessages,
    }
  })
)

export default messagesReducer

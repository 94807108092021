import { createFeatureSelector, createSelector } from '@ngrx/store'
import { values } from 'lodash-es'
import { operatorsAdapter } from './operators.reducer'
import { OperatorState } from './operators.types'

const defaultSelectors = operatorsAdapter.getSelectors()

/**
 * Selects the whole operators state
 */
export const selectOperatorState = createFeatureSelector<OperatorState>('operators')

/**
 * Select all the available entities
 */
export const selectOperators = createSelector(
    selectOperatorState,
    defaultSelectors.selectEntities,
)

/**
 * Selects a specific operators by its id
 */
export const selectOperatorById = (userName: string) => createSelector(
    selectOperatorState,
    (state: OperatorState) => state.entities[userName]
)

export const selectLoggedOperator = createSelector(
    selectOperatorState,
    (state: OperatorState) => values(state.entities)[0]
)


export const selectOperatorError = createSelector(
    selectOperatorState,
    state => state.error
  )

import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { filter, takeUntil, debounceTime, withLatestFrom, map } from 'rxjs/operators'

@Component({
  selector: 'app-hover-card',
  templateUrl: './hover-card.component.html',
  styleUrls: ['./hover-card.component.sass']
})
export class HoverCardComponent implements OnInit, OnDestroy {

  public showCard = false

  private destroyed$: Subject<boolean> = new Subject()
  private open$: Subject<boolean> = new Subject()
  private close$: Subject<boolean> = new Subject()
  private stop$: Subject<boolean> = new Subject()

  ngOnInit(): void {
    this.open$.pipe(
      filter(() => !this.showCard),
      takeUntil(this.destroyed$),
      debounceTime(300),
      withLatestFrom(this.stop$),
      map(([_, second]) => second ? false : true)
    ).subscribe((data) => {
      this.showCard = data
    })

    this.close$.pipe(
      filter(() => this.showCard),
      takeUntil(this.destroyed$),
      debounceTime(300),
      withLatestFrom(this.stop$),
      map(([_, second]) => second ? false : true)
    ).subscribe(data => {
      this.showCard = data
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  openCard() {
    this.open$.next(true)
    this.stop$.next(false)
  }

  closeCard() {
    this.close$.next(true)
    this.stop$.next(true)
  }

}

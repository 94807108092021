import { environment } from '@environments/environment'
import { Recurrence } from '@app/shared/components/recurrence/recurrence.component'
import { FormGroup, FormControl } from '@angular/forms'
import { mergeMap } from 'rxjs/operators'
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable arrow-body-style */
import cloneDeep from 'lodash-es/cloneDeep'
import { map } from 'rxjs/operators'
import {
  defaultHourlyCronExpression,
  defaultDailyCronExpression,
  defaultWeeklyCronExpression,
  defaultMonthlyCronExpression,
  defaultLastDayOfMonthCronExpression,
  defaultLastDaysBeforeEndOfMonthCronExpression,
  defaultDaysOfWeekMonthCronExpression,
} from './cron-helper'
import CronExpressions from './cron-expressions.class'
import { of, ObservableInput } from 'rxjs'
import dayjs, { Dayjs } from 'dayjs'

export enum RepetitionModes {
  dayMonth = 'dayMonth',
  lastDay = 'lastDay',
  daysOfWeek = 'daysOfWeek',
  DaysBeforeEndOfMonthMode = 'DaysBeforeEndOfMonthMode',
}

const dayMonthMode = RepetitionModes.dayMonth
const lastDayOfMonthMode = RepetitionModes.lastDay
const daysOfWeekMode = RepetitionModes.daysOfWeek
const daysBeforeEndOfMonthMode = RepetitionModes.DaysBeforeEndOfMonthMode

const getDefaultCronExpression = (recurrenceInterval: string): string | null => {
  switch (recurrenceInterval) {
    case '1':
      return defaultHourlyCronExpression
    case '2':
      return defaultDailyCronExpression
    case '3':
      return defaultWeeklyCronExpression
    case '4':
      return defaultMonthlyCronExpression
    default:
      return null
  }
}

const getMonthlyCronExpression = (repetitionMode: string): string => {
  switch (repetitionMode) {
    case dayMonthMode:
      return defaultMonthlyCronExpression
    case lastDayOfMonthMode:
      return defaultLastDayOfMonthCronExpression
    case daysOfWeekMode:
      return defaultDaysOfWeekMonthCronExpression
    case daysBeforeEndOfMonthMode:
      return defaultLastDaysBeforeEndOfMonthCronExpression
    default:
      return ''
  }
}

const getMonthDay = (val: number) => {
  if (val > 31) {
    return 31
  }

  if (val < 1) {
    return 1
  }

  return val
}

const getNameOfWeek = (val: string) => {
  switch (val) {
    case '0':
      return 'MON'
    case '1':
      return 'TUE'
    case '2':
      return 'WED'
    case '3':
      return 'THU'
    case '4':
      return 'FRI'
    case '5':
      return 'SAT'
    case '6':
      return 'SUN'
  }
  return ''
}

const getNumberOfOrdinal = (val: string) => {
  switch (val) {
    case 'First':
      return '1'
    case 'Second':
      return '2'
    case 'Third':
      return '3'
    case 'Fourth':
      return '4'
    case 'Last':
      return 'L'
  }
  return ''
}

const maxHours = 24
const minHours = 1
const isOneTime = (value: string) => 'oneTime' === value
const isRecurring = (value: string) => !isOneTime(value)
const isHourly = (value: string) => '1' === value
const isDaily = (value: string) => '2' === value
const isWeekly = (value: string) => '3' === value
const isMonthly = (value: string) => '4' === value

export const hourlyCronExpression = () =>
  map<RecurrenceObject, RecurrenceObject>((resp: RecurrenceObject) => {
    const newSchedule = { ...resp }

    if (isRecurring(resp.recurrenceType) && isHourly(resp.recurrenceInterval || '')) {
      const cronExpressions = new CronExpressions(newSchedule.cronExpression || '')
      let val = +(newSchedule.hourlyInterval || 0)

      if (val > maxHours) {
        val = maxHours
      } else if (val < minHours) {
        val = minHours
      }

      newSchedule.cronExpression = cronExpressions.onHourChange(`*/${val}`).generateExpression()
    }
    return newSchedule
  })

export const dailyCronExpression = () =>
  map<RecurrenceObject, RecurrenceObject>((resp: RecurrenceObject) => {
    const newSchedule = { ...resp }
    if (isRecurring(resp.recurrenceType) && isDaily(resp.recurrenceInterval || '')) {
      const cronExpressions = new CronExpressions(newSchedule.cronExpression || '')
      const val = getMonthDay(+(newSchedule.dailyInterval || 0))
      newSchedule.cronExpression = cronExpressions.onDayOfMonthChange(`*/${val}`).generateExpression()
    }
    return newSchedule
  })

export const weeklyCronExpression = () =>
  map<RecurrenceObject, RecurrenceObject>((resp: RecurrenceObject) => {
    const newSchedule = { ...resp }
    if (isRecurring(newSchedule.recurrenceType) && isWeekly(newSchedule.recurrenceInterval || '') && !!newSchedule.weeklyInterval) {
      //  const weeks = newSchedule.weeklyInterval[0]
      const dayOfWeek = newSchedule.weeklyInterval[1]
      const cronExpressions = new CronExpressions(newSchedule.cronExpression || '')
      if (dayOfWeek) {
        newSchedule.cronExpression = cronExpressions.onDayOfWeekChange(getNameOfWeek(dayOfWeek)).generateExpression()
      }
    }

    return newSchedule
  })

export const monthlyCronExpression = () =>
  map<RecurrenceObject, RecurrenceObject>((resp: RecurrenceObject) => {
    const newSchedule = { ...resp }
    if (isRecurring(newSchedule.recurrenceType) && isMonthly(newSchedule.recurrenceInterval || '')) {
      const monthlyInterval = newSchedule.monthlyInterval
      const repetitionMode = monthlyInterval?.value.repetitionMode
      const months = monthlyInterval?.value.interval
      if (repetitionMode) {
        const expression = getMonthlyCronExpression(repetitionMode)
        const cronExpressions = new CronExpressions(expression)
        if (repetitionMode === dayMonthMode) {
          const dayMonth = monthlyInterval?.value.daysOfMonthDays

          cronExpressions.onDayOfMonthChange(dayMonth)
        }

        if (repetitionMode === daysOfWeekMode && monthlyInterval.value.daysOfWeek) {
          const ordinal = getNumberOfOrdinal(monthlyInterval.value.daysOfWeek[0] || '1')
          const weekDays = monthlyInterval.value.daysOfWeek[1]

          cronExpressions.onDayOfWeekChange(`${weekDays}#${ordinal}`)
        }

        newSchedule.cronExpression = cronExpressions.generateExpression()
      }
      const cronExpressionMonth = new CronExpressions(newSchedule.cronExpression || '')
      newSchedule.cronExpression = cronExpressionMonth.onMonthChange(`*/${months}`).generateExpression()
    }

    return newSchedule
  })

export const defaultCronExpression = () =>
  map<RecurrenceObject, RecurrenceObject>((resp: RecurrenceObject) => {
    const newSchedule = cloneDeep(resp)
    if (isOneTime(newSchedule.recurrenceType)) {
      newSchedule.cronExpression = null
      newSchedule.recurrenceInterval = null
    }

    if (isRecurring(newSchedule.recurrenceType)) {
      // newSchedule.recurrenceInterval = scheduleUtility.recurringInterval[0].value
      newSchedule.cronExpression = getDefaultCronExpression(newSchedule.recurrenceInterval || '')
      const startDayjs = dayjs(newSchedule.scheduleStartTimestamp).utc()

      newSchedule.cronExpression = new CronExpressions(newSchedule.cronExpression || '')
        .onMinuteChange(startDayjs.get('minute'))
        .onHourChange(startDayjs.get('hour'))
        .generateExpression()
    }

    return newSchedule
  })

export const formatData = () =>
  map<Recurrence, RecurrenceObject>((data: Recurrence) => {
    return {
      recurrenceType: data.recurrence,
      startingType: data.starting,
      scheduleStartTimestamp: data.startDateTime,
      scheduleEndTimestamp: data.endDateTime,
      recurrenceInterval: data.frequency,
      hourlyInterval: data.interval,
      dailyInterval: data.interval,
      weeklyInterval: [data.interval, data.weekdaySelected],
      monthlyInterval: new FormGroup({
        repetitionMode: new FormControl(data.monthControl),
        interval: new FormControl(data.interval),
        daysOfMonthDays: new FormControl(data.dayMonth),
        daysOfWeek: new FormControl<any>([data.ordinal, data.weekdayMonthSelected]),
      }),
    }
  })

export const generateCronExpression = () =>
  mergeMap<Recurrence, ObservableInput<RecurrenceObject>>((resp: Recurrence) =>
    of(resp).pipe(
      formatData(),
      defaultCronExpression(),
      hourlyCronExpression(),
      dailyCronExpression(),
      weeklyCronExpression(),
      monthlyCronExpression()
    )
  )

export const generateCronExpressionFromRecurrenceObject = (recurrence: any) => {
  return of(recurrence)
    .pipe(
      formatData(),
      defaultCronExpression(),
      hourlyCronExpression(),
      dailyCronExpression(),
      weeklyCronExpression(),
      monthlyCronExpression(),
      map(recurrObj => recurrObj?.cronExpression ?? '')
    )
}

export type RecurrenceObject = {
  recurrenceType: 'oneTime' | 'recurring';
  startingType: 'now' | 'specificTime';
  scheduleStartTimestamp: dayjs.Dayjs | number | null;
  scheduleEndTimestamp: dayjs.Dayjs | number | null;
  recurrenceInterval: '1' | '2' | '3' | '4' | null;
  hourlyInterval?: number | null;
  dailyInterval?: number | null;
  weeklyInterval?: [number | null | undefined, '0' | '1' | '2' | '3' | '4' | '5' | '6' | null | undefined];
  monthlyInterval?: FormGroup<{
    repetitionMode: FormControl<'dayMonth' | 'daysOfWeek' | 'lastDay' | null | undefined>;
    interval: FormControl<number | null | undefined>;
    daysOfMonthDays: FormControl<number | null | undefined>;
    daysOfWeek: FormControl<
      | ['1' | '2' | '3' | '4' | '0' | '5' | '6' | null | undefined, 'First' | 'Second' | 'Third' | 'Fourth' | 'Last' | null | undefined]
      | null
    >;
  }>
  cronExpression?: string | null;
}

import { Injectable } from '@angular/core'
import { Field } from '@app/core/models/Field';
import { TranslateHelper } from '@coreview/coreview-library'

@Injectable({
  providedIn: 'root',
})
export class FieldHelper {

  constructor(private translateHelper: TranslateHelper) {}

  public mapFields(fields: Field[]): { value: string; displayValue: string; data: { type: string } }[] {
    return (fields || [])
      .map((x) => ({ value: x.name, displayValue: this.translateHelper.instant(x.displayName || x.name), data: { type: x.type }}))
      .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
  }

}

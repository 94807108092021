import { ConnectedPosition } from '@angular/cdk/overlay'
import { Component, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, NgControl, Validators } from '@angular/forms'
import { BaseControlComponent, Suggestion } from '@coreview/coreview-components'
import { delay, distinctUntilChanged, takeUntil } from 'rxjs/operators'
import dayjs from 'dayjs'
import { Subject } from 'rxjs'
import { isEqual } from 'lodash-es'

@Component({
  selector: 'app-auto-attendant-holiday-setting',
  templateUrl: './auto-attendant-holiday-setting.component.html',
  styleUrls: ['./auto-attendant-holiday-setting.component.sass'],
})
export class AutoAttendantHolidaySettingComponent extends BaseControlComponent implements OnInit, OnDestroy {
  @Input() holidays!: {
    companyId: string;
    id: string;
    name: string;
    startEndDates: {
      startDate: Date;
      endDate: Date;
    }[];
  }[]

  @Input()
  get value(): { holiday: string; displayValue: string } | null {
    return this.form.get('holiday')?.value
      ? {
          ...this.form.getRawValue(),
          displayValue: this.holidaySuggestions?.find((x) => x.value === this.form.getRawValue()?.holiday)?.displayValue,
        }
      : null
  }

  set value(model: any) {
      this.form.patchValue(model)
      this.form.markAllAsTouched()
      this.form.updateValueAndValidity()
  }

  form = new UntypedFormGroup({
    holiday: new UntypedFormControl('', [Validators.required]),
  })

  holidaySuggestions: Suggestion[] = []

  isPreviewOpen = false
  public readonly positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
    },
  ]

  private destroyed$: Subject<boolean> = new Subject()

  constructor(@Optional() @Self() public ngControl: NgControl) {
    super(ngControl)
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value
    }
  }

  ngOnInit(): void {
    this.holidaySuggestions = this.holidays?.map((x) => ({
      value: x.id,
      displayValue: x.name,
    }))

    this.form.valueChanges.pipe(delay(1), takeUntil(this.destroyed$)).subscribe(() => {
      this.onChange(this.value)
    })

    this.form.statusChanges.pipe(delay(1), distinctUntilChanged(isEqual), takeUntil(this.destroyed$)).subscribe(() => {
      if (this.ngControl?.control) {
        if (!this.form.valid) {
          this.ngControl.control.addValidators(this.setError)
        } else {
          this.ngControl.control.removeValidators(this.setError)
        }
        this.ngControl.control.updateValueAndValidity()
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  setError = () => ({
    error: true,
  })

  getHolidayRanges(holidayId: string) {
    return this.holidays
      .find((x) => x.id === holidayId)
      ?.startEndDates?.map((range) => `${dayjs(range.startDate).format('LL')} - ${dayjs(range.endDate).format('LL')}`)
  }

  togglePreview() {
    this.isPreviewOpen = !this.isPreviewOpen
  }
}

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { ManagementTypeResponse } from '@app/core/models/ManagementTypeResponse'
import { Organization } from '@app/core/models/Organization'
import { ManagementResponse, ManagementSessionStatus } from '@app/core/models/management-response'
import { OrganizationService } from '@app/core/services/organization.service'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { TranslateHelper } from '@coreview/coreview-library'
import { RootState } from '@app/store/RootState.type'
import { getManagementStatus } from '@app/store/management-status/management-status.actions'
import { selectManagementType } from '@app/store/management-type/management-type.selectors'
import { selectLastMessageOfType } from '@app/store/messages/messages.selectors'
import { getOrganizationById } from '@app/store/organizations/organizations.actions'
import { Store } from '@ngrx/store'
import { DialogComponent, ToastService, Variants } from '@coreview/coreview-components'
import { ClipboardService } from 'ngx-clipboard'
import { Subject } from 'rxjs'
import { delay, filter, switchMap, takeUntil, tap } from 'rxjs/operators'

interface ManagementBanner {
  title: string
  content: string
  type: Variants
}

type LoginType = 'serviceAccount' | 'globalAdmin'

@Component({
  selector: 'app-management-panel',
  templateUrl: './management-panel.component.html',
  styleUrls: ['./management-panel.component.sass'],
})
export class ManagementPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() managementStatus?: ManagementResponse | null
  @Input() organization?: Organization
  @Output() close: EventEmitter<null> = new EventEmitter()

  public managementType?: ManagementTypeResponse
  public enableAutoManagement = false

  public managementCredentialsForm: FormGroup
  public loginType: LoginType = 'serviceAccount'

  public serviceAccount?: string

  public loading?: boolean
  public isCreationInProgress?: boolean
  public createError: 'creation' | 'deletion' | null
  public createErrorData: string | null

  private readonly bannerDatas: Record<string, ManagementBanner> = {
    enabling: {
      title: 'common_ManagementSessionIsEnabling',
      content: 'common_ManagementSessionEnablingToolbarMessage',
      type: 'info',
    },
    disabling: {
      title: 'common_ManagementSessionIsDisabling',
      content: 'common_ManagementSessionDisablingToolbarMessage',
      type: 'info',
    },
    disabledWithoutSA: {
      title: 'common_ManagementSessionIsOff',
      content: 'common_ManagementSessionOffToolbarWarning',
      type: 'warning',
    },
    disabledWithSA: {
      title: 'common_ManagementSessionDisableMFA',
      content: 'common_ManagementSessionDisableMFAWarning',
      type: 'warning',
    },
    enabled: {
      title: 'common_ManagementSessionIsOn',
      content: 'common_ManagementSessionOnToolbarMessage',
      type: 'success',
    },
    failed: {
      title: 'common_ManagementSessionError',
      content: 'common_ManagementSessionErrorMessage',
      type: 'error',
    },
    creationInProgress: {
      title: '',
      content: 'common_ServiceAccountCreationInProgressMessage',
      type: 'info',
    },
    creationFailed: {
      title: 'common_ServiceAccountCreationError',
      content: 'common_ServiceAccountCreationErrorMessage',
      type: 'error',
    },
    creationFailedData: {
      title: 'common_ServiceAccountCreationDataError',
      content: 'common_ServiceAccountCreationDataErrorMessage',
      type: 'error',
    },
    deletionFailed: {
      title: 'common_ServiceAccountDeletionError',
      content: 'common_ServiceAccountDeletionErrorMessage',
      type: 'error',
    },
   deletionFailedData: {
      title: 'common_ServiceAccountDeletionDataError',
      content: 'common_ServiceAccountDeletionDataErrorMessage',
      type: 'error',
    },
  }

  private destroy$ = new Subject<void>()

  constructor(
    private sharedHelperService: SharedHelperService,
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private toastService: ToastService,
    private store: Store<RootState>,
    private translateHelper: TranslateHelper,
    private dialog: MatDialog,
    private clipboard: ClipboardService,
  ) {
    this.managementCredentialsForm = this.initializeCredentialsForm()
    this.loading = true
    this.createError = null
    this.createErrorData = null
  }

  public ngOnInit(): void {
    this.getServiceAccount()

    this.store
      .select(selectLastMessageOfType('NotifyTaskStatus'))
      .pipe(
        filter((message) => {
          const { title, state } = message?.body || {}
          return title === 'ChangeManagementUserPassword' && state !== 'Progress'
        }),
        takeUntil(this.destroy$),
        delay(15000) // wait for Microsfoft update service account availability
      )
      .subscribe(() => {
        this.getServiceAccount()
      })

    this.store
      .select(selectManagementType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((managementType) => {
        if (managementType) {
          this.managementType = managementType
        }
      })
  }

  public getServiceAccount() {
    this.organizationService.getServiceAccount().subscribe((res) => {
      this.serviceAccount = res.serviceAccountName
      this.loading = false
      this.isCreationInProgress = false
    }, () => {
      this.loading = false
      this.isCreationInProgress = false
    })
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.organization) {
      this.enableAutoManagement = changes.organization.currentValue.autoEnableManagementSession
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  public get status(): ManagementSessionStatus {
    return this.managementStatus?.status || 'Disabled'
  }

  public get isLoading(): boolean {
    return !!this.loading || !!this.isCreationInProgress || !!this.managementType?.isChangeInProgress
  }

  public get banner(): ManagementBanner | null {
    let key = this.status.toLowerCase()
    if (key === 'disabled') {
      key = this.serviceAccount ? 'disabledWithSA' : 'disabledWithoutSA'
    }
    if (this.createError === 'creation') {
      key = this.createErrorData ? 'creationFailedData' : 'creationFailed'
    }
    if (this.createError === 'deletion') {
      key = this.createErrorData ? 'deletionFailedData' : 'deletionFailed'
    }
    if (key !== 'enabled' && (this.isCreationInProgress || this.managementType?.isChangeInProgress)) {
      key = 'creationInProgress'
    }
    return key in this.bannerDatas ? this.bannerDatas[key] : null
  }

  public changeLoginType(type: LoginType) {
    this.loginType = type
  }

  public handleClose() {
    this.close.emit()
  }

  public isAdmin = (): boolean => this.sharedHelperService.isAdmin()
  public isManagement = (): boolean => this.sharedHelperService.isManagement()

  public turnOnEnabled = (): boolean => {
    const globalAdminValid = this.loginType !== 'globalAdmin' || this.managementCredentialsForm.valid
    const serviceAccountValid = this.loginType !== 'serviceAccount' || !!this.serviceAccount
    return globalAdminValid && serviceAccountValid
  }

  public retryServiceAccountCreation(event?: Event) {
    this.createError = null
    this.createErrorData = null
    this.createServiceAccount(event)
  }

  public createServiceAccount(event?: Event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.isCreationInProgress = true
    this.organizationService
      .updateManagementType({
        managementType: 'Advanced',
        redirectUrl: `${location.origin}/consent`,
      })
      .subscribe({
        next: (data) => {
          if (!data?.isValid) {
            this.createError = 'creation'
            this.createErrorData = data?.errorData || null
            this.isCreationInProgress = false
          }
        },
        error: () => {
          this.createError = 'creation'
          this.isCreationInProgress = false
        },
      })
  }

  public deleteServiceAccount(event?: Event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    return this.dialog
      .open(DialogComponent, {
        width: '600px',
        data: {
          title: this.translateHelper.instant('common_DisableAdvancedWarningTitle'),
          text: this.translateHelper.instant('common_DisableAdvancedWarningDescription'),
          primaryText: this.translateHelper.instant('common_Proceed'),
          secondaryText: this.translateHelper.instant('common_Cancel'),
          type: 'alert',
          centered: false,
        },
      })
      .afterClosed()
      .pipe(
        filter((x) => x),
        switchMap(() =>
          this.organizationService.updateManagementType({
            managementType: 'None',
            redirectUrl: `${location.origin}/consent`,
          })
        ),
        tap(() => this.isCreationInProgress = true)
      )
      .subscribe({
        next: (data) => {
          if (!data?.isValid) {
            this.createError = 'deletion'
            this.createErrorData = data?.errorData || null
            this.isCreationInProgress = false
          } else {
            this.serviceAccount = undefined
            this.closeManagementSession()
          }
        },
        error: () => {
          this.createError = 'deletion'
          this.isCreationInProgress = false
        },
      })
  }

  public changeAutoEnable() {
    this.organizationService
      .setAutomaticSessionEnabled({
        companyId: this.organization?.guid ?? '',
        autoEnableManagementSession: !this.enableAutoManagement,
      })
      .subscribe()
  }

  startManagementSession(): void {
    this.managementCredentialsForm.markAllAsTouched()
    if (this.loginType !== 'serviceAccount' && !this.managementCredentialsForm.valid) {
      return
    }
    this.organizationService
      .startManagementSession(this.organization?.guid ?? '', {
        companyId: this.organization?.guid ?? '',
        setAutomaticSessionEnabled: this.enableAutoManagement,
        useDifferentAccount: this.loginType !== 'serviceAccount',
        ...(this.loginType === 'serviceAccount' ? {} : this.managementCredentialsForm.value),
      })
      .subscribe(() => {
        this.handleClose()
        this.store.dispatch(getOrganizationById({ id: this.organization?.guid ?? '' }))
        this.store.dispatch(getManagementStatus())
      })
  }

  closeManagementSession(): void {
    this.organizationService.closeManagementSession().subscribe(() => {
      this.handleClose()
      this.store.dispatch(getOrganizationById({ id: this.organization?.guid ?? '' }))
      this.store.dispatch(getManagementStatus())
    })
  }

  copyConsentsUrl() {
    this.clipboard.copyFromContent(this.createErrorData || '')
    this.toastService.open({ id: 'copy', variant: 'success', title: this.translateHelper.instant('common_CopiedToClipboard') })
  }

  private initializeCredentialsForm(): FormGroup {
    return this.fb.group({
      adminUsernameOffice365: ['', [Validators.required, Validators.email]],
      adminPasswordOffice365: ['', Validators.required],
    })
  }
}

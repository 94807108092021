import { TranslateHelper } from '@coreview/coreview-library'
import { ToastService } from '@coreview/coreview-components'
import { newMessage } from './../../store/messages/messages.actions'
import { RootState } from '@app/store/RootState.type'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import * as signalR from '@microsoft/signalr'
import { HubConnection } from '@microsoft/signalr'
import { LocalstorageService } from './localstorage.service'
import { Store } from '@ngrx/store'
import { ApiclientService } from './apiclient.service'

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  private hubConnection!: HubConnection

  private events = [
    'NotifyPartialImportCompleted',
    'NotifyTaskStatus',
    'ReceiveNotification',
    'SetManagementSession',
    'SetComplianceImportStatus',
    'SetReloadActiveDirectoryAuditEventImportStatus',
    'NotifyBroadcast',
    'CustomActionChanged',
    'OnPremisesConfigurationStatusChanged',
    'ActionCompleted',
    'ReloadPolicy',
    'PolicyMetricsUpdated',
    'GrantOrganizationAccessAndPermissionsResult'
  ]

  constructor(
    private storage: LocalstorageService,
    private store: Store<RootState>,
    private toastService: ToastService,
    private translateHelper: TranslateHelper,
    private apiclientService: ApiclientService
  ) {}

  init() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(
        `${environment.baseCommunicationUrl[this.apiclientService.envNameMap[this.apiclientService.basePortalApiUrl]]}/notifications`,
        {
          accessTokenFactory: () => this.storage.authToken,
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        }
      )
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build()

    this.hubConnection
      .start()
      .catch((err: any) => {
        this.toastService.open({
          id: 'err',
          variant: 'warning',
          title: this.translateHelper.instant('common_Warning'),
          message: this.translateHelper.instant('error_SignalRErrorMessage'),
        })
      })
      .then(() => {
        this.hubConnection
          .send(
            'SelectCompany',
            this.storage.selectedOrganization?.id ?? this.storage.getLoggedUser().organizationId
          )
          .catch((err) => {
            console.error(err)
          })
      })

    this.events.forEach((event) => {
      this.hubConnection.on(event, (data: any) => {
        this.store.dispatch(
          newMessage({
            message: {
              timestamp: new Date().toISOString(),
              type: event,
              body: data,
            },
          })
        )
      })
    })
  }
}

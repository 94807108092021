import { Component } from '@angular/core'
import { ICellRendererParams } from '@ag-grid-community/core'
import { AgRendererComponent } from '@ag-grid-community/angular'

@Component({
  selector: 'app-custom-link-renderer',
  templateUrl: './custom-link-renderer.component.html',
  styleUrls: ['./custom-link-renderer.component.sass'],
})
export class CustomLinkRendererComponent implements AgRendererComponent {
  public displayValue!: string
  public params: any
  public errorLabel!: string
  public cvDataTest?: string

  agInit(params: any): void {
    this.params = params
    this.displayValue = params.valueFormatted || params.value
    this.cvDataTest = params.cvDataTest
    if (params.errorLabel) {
      this.errorLabel = params.errorLabel(params.data)
    }
  }

  goToRoute(event: any): void {
    if (this.params.onClick instanceof Function) {
      this.params.onClick({ rowData: this.params.node.data, event })
    }
  }

  refresh(params: ICellRendererParams) {
    return true
  }
}

import { Injectable } from '@angular/core'

import { assert } from './assert'

@Injectable({
  providedIn: 'root',
})
export class SerializerService {
  private readonly inputBindingExpressionRegexString = '{{\\w+(?:\\.\\w+)?}}'
  private readonly actionBindingExpressionRegexString = '{{(input|action)::((?:\\w|-)*)::((?:\\w|\\.|\\[|\\])*)::(.*?)(?:::(.*?))?}}'
  private readonly namedExpressionParts = 'uid,step,title,property,transforms'

  getActionBindingExpressionRegex() {
    return new RegExp(this.actionBindingExpressionRegexString, 'g')
  }

  getInputBindingExpressionRegex() {
    return new RegExp(this.inputBindingExpressionRegexString, 'g')
  }

  isAction(value: string) {
    return this.getActionBindingExpressionRegex().test(value)
  }

  isInput(value: string) {
    return this.getInputBindingExpressionRegex().test(value)
  }

  hasBinding = (value: any): boolean => {
    const inputBindingMatches = value && typeof value === 'string' && value.match(this.getInputBindingExpressionRegex())
    const actionBindingMatches = value && typeof value === 'string' && value.match(this.getActionBindingExpressionRegex())

    return (inputBindingMatches && inputBindingMatches.length > 0) || (actionBindingMatches && actionBindingMatches.length > 0)
  }

  deserialize = (value: any, isPicker: boolean): string => {
    let deserializedValue = value

    if (!assert.isNil(deserializedValue) && assert.isString(deserializedValue)) {
      deserializedValue = deserializedValue.replace(this.getActionBindingExpressionRegex(), (str: string) =>
        this._tagDeserializer(str, isPicker)
      )

      deserializedValue = deserializedValue.replace(this.getInputBindingExpressionRegex(), (str: string) =>
        this._tagDeserializer(str, isPicker)
      )
    }

    return deserializedValue
  }

  _getNamedPartFromBindingExpression(namedPart: string, expression: string): any {
    if (!this.namedExpressionParts.includes(namedPart)) {
      throw new Error('invalid binding expression named part')
    }

    const regex = this.getActionBindingExpressionRegex()
    let m: RegExpExecArray | null
    let parts: { [key: string]: string } = {}

    if ((m = regex.exec(expression)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }
      parts = {
        step: m[1],
        uid: m[2],
        property: m[3],
        title: m[4],
        transforms: m[5],
      }

      return parts[namedPart]
    }
  }

  _tagDeserializer(expression: string, isPicker: boolean = false): string[] {
    const tokens = expression.replace('{{', '').replace('}}', '').split('.')

    return tokens
  }
}

import { Injectable } from '@angular/core'
import { LocalstorageService } from '@core/services/localstorage.service'
import isArray from 'lodash-es/isArray'

@Injectable({
  providedIn: 'root',
})
export class LicensesFormatterService {
  private readonly skuId = '##SKU##'
  private companyId: string
  private companyLicenseId: string
  private companyRegex: RegExp
  private skuRegex: RegExp

  constructor(private localStorageService: LocalstorageService) {
    this.companyId = this.localStorageService.selectedOrganization?.id || ''
    this.companyLicenseId = `${this.companyId}:`
    this.companyRegex = new RegExp(`${this.companyLicenseId}`, 'gi')
    this.skuRegex = new RegExp(`${this.skuId}`, 'gi')
  }

  generateCompactFormat = (licenseGroupIds: any[], selectedLicenses: any[]) => {
    let selections: any[] = []

    licenseGroupIds.forEach((licenseGroupId) => {
      const selLicenseGroup = selectedLicenses
        .filter((selLicense) => selLicense.split('|')[0] === licenseGroupId)
        .map((selLicense) => {
          const dividedSels = selLicense.split('|')
          dividedSels.shift()
          return dividedSels.join('|')
        })

      const newId = licenseGroupId.replace(this.companyRegex, this.skuId)
      selLicenseGroup.unshift(newId)

      selections = [...selections, ...selLicenseGroup]
    })

    return JSON.stringify(selections)
  }

  convertCompactLicenses = (licenses: any[]) => {
    const containsNewSku = licenses.every((license: { id: { match: (arg0: RegExp) => null } }) => license.id.match(this.skuRegex) !== null)

    if (containsNewSku) {
      licenses = licenses.map((license: { id: string }) => {
        license.id = license.id.replace(this.skuRegex, this.companyLicenseId)
        return license
      })
    }

    return licenses
  }

  convertCompactSelections = (selections: any[]) => {
    const containsNewSku = selections.some((sel: { match: (arg0: RegExp) => null }) => sel.match(this.skuRegex) !== null)
    let oldSelFormat: string | any[] = []

    if (containsNewSku) {
      let newId = ''
      selections.forEach((sel: string) => {
        if (sel.match(this.skuRegex)) {
          newId = sel.replace(this.skuRegex, this.companyLicenseId)
          return
        }
        oldSelFormat = [...oldSelFormat, `${newId}|${sel}`]
      })
    }

    return oldSelFormat.length > 0 ? oldSelFormat : selections
  }

  hasLicensesApplied = (value: string | string[]): boolean => {

    if (value === null || value === undefined) return false;

    const valueStr = Array.isArray(value) ? value.join(',') : value;
    
    return valueStr.includes('|true') || 
           valueStr.includes('|1') || 
           valueStr.includes('|false') || 
           valueStr.includes('|0');
  }

  licenseFormatter = (value: string) => {
    const valOutput = this.hasLicensesApplied(value)
      ? value.includes('##SKU##')
        ? // conditional for if workflow licenses are old format
          value
            .split(',')
            .filter((entry: any) => this.hasLicensesApplied(entry))
            .map((entry: string) => entry.split('|')[0].substring(1))
        : value
            .split(',')
            .filter((entry: any) => this.hasLicensesApplied(entry))
            .map((entry: string) => entry.split('|')[1])
      : []

    return valOutput
  }

  getSelectionString = (licenseGroupId: any, servicePlanId: any, checked: any, isV2 = true) => {
    const selectionString = `${licenseGroupId}|${servicePlanId}|${this._getStatusFromChecked(checked, isV2)}`
    return selectionString
  }

  isChecked = (licenseGroupId: any, servicePlanId: any, selections: never[], isV2 = true) => {
    const selection = this.getSelection(licenseGroupId, servicePlanId, selections)
    if (selection) {
      return this.getCheckedFromStatus(selection, isV2)
    }
    return false
  }

  getLicensesSelections = (licenses: any[], selectionsFromValueProp = []) => {
    const selections: string[] = []
    licenses.forEach((license: { data: { servicePlans: any[] }; id: any }) => {
      if (license.data.servicePlans) {
        license.data.servicePlans.forEach((sp: { id: any; disabled: any; checked: any }) => {
          const previousSelection = this.getSelection(license.id, sp.id, selectionsFromValueProp)
          const sel = this.getSelectionString(
            license.id,
            sp.id,
            previousSelection && !sp.disabled ? this.isChecked(license.id, sp.id, selectionsFromValueProp) : sp.checked
            // The checked value coming from the property value (if any) has priority.
            // If no checked value we consider it a 2 status so that the default is don't change anything
          )
          selections.push(sel)
        })
      }
    })
    return selections
  }

  getCheckedFromStatus = (selection: string, isV2: boolean) => {
    if (isV2) {
      if (selection.endsWith('|true')) {
        return true
      }
      if (selection.endsWith('|false')) {
        return false
      }
      if (selection.endsWith('|1')) {
        return true
      }
      if (selection.endsWith('|0')) {
        return false
      }
      if (selection.endsWith('|2')) {
        return undefined
      }
      return undefined
    } else {
      if (selection.endsWith('|true')) {
        return true
      }
      if (selection.endsWith('|false')) {
        return false
      }
      return false
    }
  }

  getSelection = (licenseGroupId: any, servicePlanId: any, selections: any[]): any =>
    isArray(selections) && selections.find((sel: string) => sel.startsWith(`${licenseGroupId}|${servicePlanId}|`))

  _getStatusFromChecked = (checked: any, isV2: boolean) => {
    if (isV2) {
      return typeof checked === 'undefined' ? '2' : checked ? '1' : '0'
    }
    return typeof checked === 'undefined' ? false : checked
  }
}

import { createAction, props } from '@ngrx/store'
import { Organization } from '@core/models/Organization'

/* eslint-disable max-len */

/**
 * Action types related to the `organization` state
 */
export const getOrganizationById = createAction('[Organizations] GET by ID request', props<{ id: string; selected?: boolean }>())
export const getOrganizationByIdResponse = createAction(
  '[Organizations] GET by ID  responses',
  props<{ data: Organization; selected?: boolean }>()
)
export const getOrganizationByIdInvalidate = createAction('[Organizations] GET by ID  invalidates', props<{ error: string }>())

export const updateOrganizationNewUxSettings = createAction('[Organizations] NewUxSettings PUT data', props<{ data: Partial<Organization> }>())
export const updateOrganizationNewUxSettingsResponse = createAction(
  '[Organizations] NewUxSettings PUT responses',
  props<{ data: Partial<Organization> }>()
)
export const updateOrganizationNewUxSettingsInvalidate = createAction('[Organizations] NewUxSettings PUT invalidates', props<{ error: string }>())

/**
 * Action types related to the `organization` state update.
 * The following actions has the convention of receiving the organization updates via the 'data' prop
 * so that the reducer will perform always the same function to update the state and gain performances
 */
export const updateOrganizationGeneralSettings = createAction('[Organizations] GeneralSettings PUT data', props<{ data: Partial<Organization> }>())
export const updateOrganizationGeneralSettingsResponse = createAction(
  '[Organizations] GeneralSettings PUT responses',
  props<{ data: Partial<Organization> }>()
)
export const updateOrganizationGeneralSettingsInvalidate = createAction('[Organizations] GeneralSettings PUT invalidates', props<{ error: string }>())

export const updateOrganizationVirtualTenants = createAction('[Organizations] PUT vir. tenants', props<{ data: Partial<Organization> }>())
export const updateOrganizationVirtualTenantsResponse = createAction(
  '[Organizations] PUT vir. tenants resp',
  props<{ data: Partial<Organization> }>()
)
export const updateOrganizationVirtualTenantsInvalidate = createAction(
  '[Organizations] PUT vir. tenants invalidates',
  props<{ error: string }>()
)

export const updateOrganizationMailSettings = createAction('[Organizations] PUT mail settings', props<{ data: Partial<Organization> }>())
export const updateOrganizationMailSettingsResponse = createAction(
  '[Organizations] PUT mail settings resp',
  props<{ data: Partial<Organization> }>()
)
export const updateOrganizationMailSettingsInvalidate = createAction(
  '[Organizations] PUT mail settings invalidates',
  props<{ error: string }>()
)

export const updateOrganizationManagementActions = createAction(
  '[Organizations] PUT managed acts',
  props<{ data: Partial<Organization> }>()
)
export const updateOrganizationManagementActionsResponse = createAction(
  '[Organizations] PUT managed acts resp',
  props<{ data: Partial<Organization> }>()
)
export const updateOrganizationManagementActionsInvalidate = createAction(
  '[Organizations] PUT managed acts invalidates',
  props<{ error: string }>()
)

export const updateSecureByDefault = createAction('[Organizations] PUT secureByDefault', props<{ data: Partial<Organization> }>())
export const updateSecureByDefaultResponse = createAction(
  '[Organizations] PUT secureByDefault resp',
  props<{ data: Partial<Organization> }>()
)
export const updateSecureByDefaultInvalidate = createAction('[Organizations] PUT secureByDefault invalidates', props<{ error: string }>())

export const updateServiceNowEnabledResponse = createAction(
  '[Organizations] PUT serviceNowEnabled resp',
  props<{ data: Partial<Organization> }>()
)
export const updateAutoEnableManagementSessionResponse = createAction(
  '[Organizations] PUT autoEnableManagementSessionResponse resp',
  props<{ data: Partial<Organization> }>()
)

export const updateSecuritySettings = createAction(
  '[Organizations] PUT security settings',
  props<{
    data: Partial<Organization>;
    serviceAccountPswRotation: { companyId: string; enableAutomaticRotation?: boolean; force?: boolean; days?: number };
  }>()
)

import { IHeaderAngularComp } from '@ag-grid-community/angular'
import { IHeaderParams } from '@ag-grid-community/core'
import { Component, HostBinding } from '@angular/core'

@Component({
  selector: 'app-tooltip-header',
  templateUrl: './tooltip-header.component.html',
  styleUrls: ['./tooltip-header.component.sass'],
})
export class TooltipHeaderComponent implements IHeaderAngularComp {
  @HostBinding('class.ag-cell-label-container')
  params!: IHeaderParams

  tooltip!: string

  constructor() {}

  refresh(params: any): boolean {
    this.params = params
    return true
  }

  agInit(params: any): void {
    this.tooltip = params.tooltip
    this.params = params
  }

  sort() {
    if (this.params.enableSorting) {
      this.params.setSort(this.params.column.isSortAscending() ? 'desc' : 'asc')
    }
  }
}

<app-loading-report-skeleton *ngIf="!loadedFirstTime"></app-loading-report-skeleton>
<div [style.display]="loadedFirstTime ? 'flex' : 'none'" class="workflow-previewLoader-container">
  <div class="workflow-previewLoader-content">
    <app-workflow-preview
      [actions]="actions"
      [isCatch]="false"
      [previewType]="previewType"
      [showDetails]="true"
      [title]="workflowName"
      [description]="workflowDescription"
    />

    <app-workflow-preview
      *ngIf="catchActions.length > 0"
      [actions]="catchActions"
      [isCatch]="true"
      [previewType]="previewType"
      [description]="'workflow_CatchActions'"
      [showDetails]="true"
    ></app-workflow-preview>
  </div>
</div>

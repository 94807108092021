<div class="cv-dialog-div-container">
  <div class="main-wrapper" [ngClass]="activeTab > 1 ? 'grid-view' : ''" *ngIf="!!selectedPolicy">
    <div class="head">
      <div class="title-button-wrapper">
        <div class="badge-title">
          <cv-badge
            *ngIf="playbookTitle"
            variant="grey"
            fontWeight="400"
            [text]="playbookTitle"
            [hideBorder]="true"
          >
          </cv-badge>
          <cv-badge
            *ngIf="categoryTitle"
            variant="grey"
            fontWeight="400"
            [text]="categoryTitle"
            [hideBorder]="true"
          >
          </cv-badge>
        </div>
        <div class="selection-buttons">
          <cv-button
            style="align-self: center"
            buttonType="tertiary"
            leftIcon="refresh"
            [text]="'common_Reload' | translate"
            (clicked)="loadData(selectedPolicy?.id ?? '', timeRangeType)"
          ></cv-button>
          <ng-container *ngIf="policies?.length && policies.length > 1">
            <cv-icon-button buttonType="tertiary" icon="arrow_upward" (click)="upClick($event)"></cv-icon-button>
            <cv-icon-button buttonType="tertiary" icon="arrow_downward" (click)="downClick($event)"></cv-icon-button>
            <div class="title padding-top">{{ pageText }}</div>
          </ng-container>
          <cv-close-button (click)="close()" class="close-btn"></cv-close-button>
        </div>
      </div>
      <div class="title">{{ selectedPolicy.title }}</div>
    </div>
    <cv-horizontal-tab-group type="line" [activeTab]="1" (tabChanged)="tabChanged($event)">
      <cv-tab [title]="'playbook_Description' | translate">
        <ng-template cvTabContent>
          <div class="content-wrapper">
            <div class="content" [ngClass]="{ separator: isAdminProductionMode }">
              <div>
                <div class="policytitle-section">
                  <span class="sub-title" translate>healthcheck_PolicyDescription</span>
                  <cv-button
                    leftIcon="edit"
                    *ngIf="selectedPolicy.canEdit"
                    buttonType="tertiary"
                    [text]="'playbook_editPolicy' | translate"
                    (clicked)="openEditPolicyPanel(selectedPolicy)"
                  ></cv-button>
                </div>
                <div>{{ selectedPolicy.policyDescription?.policy }}</div>
              </div>
              <div class="policyowner-section">
                <div>
                  <span class="sub-title" translate>playbook_CreatedBy</span>
                  <app-edit-policy-owner-button
                    *ngIf="selectedPolicy.canEdit &&
                           selectedPolicy.policyGroupType === 'CustomPolicy'"
                    [policy]="selectedPolicy"
                  />
                </div>
                <div class="policy-createdby">
                  {{
                    selectedPolicy.policyGroupType === 'OutOfTheBoxPolicy'
                      ? ('playbook_CreatedByCoreview' | translate)
                      : selectedPolicy.policyGroupType === 'MspPolicy'
                      ? ('playbook_CreatedByMsp' | translate)
                      : selectedPolicy.createdBy
                  }}
                </div>
              </div>
              <div *ngIf="selectedPolicy.howThisAffect">
                <div class="sub-title" translate [ngStyle]="selectedPolicy.canEdit ? {'padding-bottom': '7px'}: null">playbook_howThisAffect</div>
                <div class="description-field">{{ selectedPolicy.howThisAffect | translate }}</div>
              </div>
              <div>
                <div class="sub-title" translate>playbook_RemediationAction</div>
                <div style="padding: 10px 0; display: flex; flex-direction: row; align-items: center">
                  <cv-switch

                    [tooltip]="'playbook_enablePolicyWorkflowTooltip' | translate"
                    [hideDelay]="0"
                    (checkedChange)="
                      selectedPolicy.isWorkflowEnabled
                        ? disableWorkflow(selectedPolicy)
                        : openEditPolicyPanel(selectedPolicy, { isWorkflowEnabled: $event }, workflowEnabled)
                    "
                    [value]="selectedPolicy.isWorkflowEnabled"
                    #workflowEnabled
                    cv-data-test="enablePolicyWorkflow"
                    [attr.cv-policy-template-id]="selectedPolicy.policyTemplateId"
                    [ngClass]="!selectedPolicy.canEnableRemediation ? missingRemediationPermission: ''"
                    [disabled]="!selectedPolicy.isPolicyEnabled || !selectedPolicy.canEdit || !selectedPolicy.canEnableRemediation"
                    >{{ 'playbook_enablePolicyWorkflow' | translate }}</cv-switch
                  >
                  <cv-button
                    *ngIf="
                        selectedPolicy.isWorkflowEnabled &&
                        selectedPolicy.remediationType === 'Workflow' &&
                        selectedPolicy.policyGroupType !== 'LegacyPolicy'
                      "
                    style="padding-left: 10px"
                    leftIcon="help_outline"
                    buttonType="tertiary"
                    [text]="'workflow_PreviewWorkflow' | translate"
                    (clicked)="openWorkflow(selectedPolicy)"
                  ></cv-button>
                </div>
                <div class="description-field">
                  <cv-badge
                    [ngStyle]="!isAdmin ? { cursor: 'default' } : null"
                    *ngIf="isPolicyScheduled()"
                    [hideBorder]="true"
                    variant="lightgreen"
                    [text]="'playbook_workflowIsScheduled' | translate"
                    leftIcon="today"
                    (click)="openEditPolicyPanel(selectedPolicy)"
                    cv-data-test="policyWorkflowScheduledBadgeHC"
                  >
                  </cv-badge>
                  {{
                    selectedPolicy.remediationType === 'ScheduleReport'
                      ? ('playbook_ScheduleReportRemediation' | translate)
                      : selectedPolicy.remediationType === 'SendNotification'
                      ? ('playbook_SendNotificationRemediation' | translate)
                      : selectedPolicy.workflowName
                  }}
                </div>
              </div>
            </div>
            <div *ngIf="isAdminProductionMode" class="fl-1" style="display: flex; flex-direction: column">
              <div style="display: flex; flex-direction: row">
                <div style="font-size: 14px; font-weight: 600; flex: 1; line-height: 36px" translate>
                  healthcheck_MatcheditemsvsRemediationOverTime
                </div>
                <cv-tertiary-dropdown
                  leftIcon="date_range"
                  [(ngModel)]="timeRangeType"
                  class="selector"
                  [options]="options"
                  (selectionChanged)="loadData(selectedPolicy?.id || '', $event)"
                ></cv-tertiary-dropdown>
              </div>
              <lib-chart [chart]="chart" [data]="chartData"></lib-chart>
            </div>
          </div>
        </ng-template>
      </cv-tab>
      <cv-tab
        [title]="'playbook_matchedItems' | translate"
        [statusContainerClass]="
          selectedPolicy.isThresholdEnabled && selectedPolicy.isThresholdExceeded ? 'tab-items-warning' : 'tab-items-success'
        "
        [statusIcon]="selectedPolicy.isThresholdEnabled && selectedPolicy.isThresholdExceeded ? 'warning' : 'check_circle'"
        [statusText]="selectedPolicy.matchedItemsCount.toString()"
      >
        <ng-template cvTabContent>
          <div style="display: flex; flex: 1">
            <app-policy-dialog
              style="display: flex; flex: 1"
              [isLoadedInDialog]="false"
              [policy]="selectedPolicy"
              reportType="matchedItems"
            ></app-policy-dialog>
          </div>
        </ng-template>
      </cv-tab>
      <cv-tab
        [title]="'playbook_exceptions' | translate"
        statusContainerClass="tab-items-grey"
        [statusText]="selectedPolicy.exceptionItemsCount.toString()"
      >
        <ng-template cvTabContent>
          <div style="display: flex; flex: 1">
            <app-policy-dialog
              style="display: flex; flex: 1"
              [isLoadedInDialog]="false"
              [policy]="selectedPolicy"
              reportType="matchedExceptions"
            ></app-policy-dialog>
          </div>
        </ng-template>
      </cv-tab>
      <cv-tab
        [title]="'playbook_WorkflowExecutions' | translate"
        statusContainerClass="tab-items-teal"
        [statusText]="totalWfExecutionCount.toString()"
        ><ng-template cvTabContent>
          <div style="display: flex; flex: 1">
            <app-workflow-executions-v2 class="fl-1" [workflowId]="selectedPolicy.workflowId" [serviceTag]="getServiceTag(selectedPolicy)">
            </app-workflow-executions-v2>
          </div>
        </ng-template>
      </cv-tab>
    </cv-horizontal-tab-group>
  </div>
</div>

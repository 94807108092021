import { MenuService } from '@app/core/services/menu.service'
import { combineLatest, of } from 'rxjs'
import { catchError, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  loadFavorites,
  loadFavoritesInvalidate,
  loadFavoritesResponse,
  loadAllMenu,
  loadAllMenuResponse,
  loadAllMenuInvalidate,
  loadMenusHelp,
  loadMenusHelpInvalidate,
  loadMenusHelpResponse,
} from './menu.actions'
import { Store } from '@ngrx/store'
import { selectMenuLoaded, selectMenusAll } from './menu.selectors'

@Injectable()
export class MenuEffects {

  loadAllMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllMenu),
      mergeMap(() =>
        this.menuService.getMenusAll().pipe(
          map((menu) => loadAllMenuResponse({ menu })),
          catchError((error) => of(loadAllMenuInvalidate({ error: { code: error.status, message: error.message }})))
        )
      ),
    )
  )

  readonly commonMergeMapLoadMenu = combineLatest([
    this.store.select(selectMenuLoaded).pipe(
      filter((x) => !!x),
      switchMap(() => this.store.select(selectMenusAll)),
      first()
    ),
  ])

  loadFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFavorites),
      mergeMap(() =>
        combineLatest([this.menuService.getFavorites()]).pipe(
          map(([favorites]) => loadFavoritesResponse(favorites)),
          catchError((error) => of(loadFavoritesInvalidate({ error: { code: error.status, message: error.message }})))
        )
      )
    )
  )

  loadMenusHelp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMenusHelp),
      mergeMap(() =>
        this.menuService.getMenuHelp().pipe(
          map((menu) => loadMenusHelpResponse({ menu })),
          catchError((error) => of(loadMenusHelpInvalidate({ error: { code: error.status, message: error.message }})))
        )
      )
    )
  )

  constructor(private actions$: Actions, private menuService: MenuService, private store: Store) {}
}

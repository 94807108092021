import { Injectable } from '@angular/core'

import { FileSaverService } from 'ngx-filesaver'
import { ToastService } from '@coreview/coreview-components'

import { TranslateHelper } from '@coreview/coreview-library'

import { Workflow } from '../models/workflow.model'
import { isValidRestartWorkflowExecution, isValidStopWorkflowExecution } from '../models/workflow.definition'

@Injectable({
  providedIn: 'root',
})
export class HelperExecutionService {
  constructor(private fileSaverService: FileSaverService, private toastService: ToastService, private translateHelper: TranslateHelper) {}

  saveJsonContent(executionId: string, executionData: Workflow.Dto.V1.WorkflowExecution.Data): void {
    if (executionData.executionHistoryEvents || executionData.catchExecutionHistoryEvents) {
      const executionActionsData = executionData.executionHistoryEvents
        ? executionData.executionHistoryEvents.map((item) => ({
            name: item.action.name,
            input: item.input,
            output: item.output,
            status: item.status,
          }))
        : []

      const executionCatchActionsData = executionData.catchExecutionHistoryEvents
        ? executionData.catchExecutionHistoryEvents.map((item) => ({
            name: item.action.name,
            input: item.input,
            output: item.output,
            status: item.status,
          }))
        : []

      const executionExportData = {
        status: this.translateHelper.instant(executionData.status),
        actions: executionActionsData,
        catchActions: executionCatchActionsData,
        workflowInput: executionData.workflowInput,
        workflowOutput: executionData.workflowOutput,
      }

      const split = executionId.split(':')
      const shortenedExecutionId = split.length > 1 ? split[7].slice(0, 6) : executionId
      const executionName = `${this.translateHelper.instant('workflow_execution').toLowerCase()}-${shortenedExecutionId}`

      this.fileSaverService.saveText(JSON.stringify(executionExportData), executionName)

      this.toastService.open({
        id: 'success',
        variant: 'success',
        title: this.translateHelper.instant('common_Success'),
        message: this.translateHelper.instant('workflow_ExportExecutionSuccess'),
      })
    }
  }

  filterValidItemsStopWorkflowExecutionBulk(bulkSelectedItems: Workflow.Dto.V1.WorkflowExecutions.Item[]): Workflow.Execution[] {
    const result = bulkSelectedItems
      .filter((item) => isValidStopWorkflowExecution(item.status))
      .map((execution) => ({ workflowId: execution.workflowId, id: execution.id }))

    if (result.length < 1) {
      this.toastService.open({
        id: 'err',
        variant: 'error',
        title: this.translateHelper.instant('common_Error'),
        message: this.translateHelper.instant('workflow_BulkStopExecutionsWarning'),
      })
    }

    return result
  }

  canExecuteRetryStopWorkflow(): boolean {
    return true
  }

  filterValidItemsRestartWorkflowExecutionBulk(bulkSelectedItems: Workflow.Dto.V1.WorkflowExecutions.Item[]): Workflow.Execution[] {
    const validItems = bulkSelectedItems
      .filter((item) => isValidRestartWorkflowExecution(item.status))
      .map((execution) => ({ workflowId: execution.workflowId, id: execution.id }))

    if (validItems.length < 1) {
      this.toastService.open({
        id: 'err',
        variant: 'error',
        title: this.translateHelper.instant('common_Error'),
        message: this.translateHelper.instant('workflow_BulkRestartExecutionsWarning'),
      })
    }

    return validItems
  }
}

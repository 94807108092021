import { Component, Input } from '@angular/core'
import { SerializerService } from '@app/modules/workflow/services/serializer.service'

@Component({
  selector: 'app-binding-tag-action',
  template: `
    <cv-binding-tag
      [title]="step"
      [content]="title"
      [canTransform]="false"
    ></cv-binding-tag>
  `,
  styleUrls: ['./binding-tag-action.component.sass'],
})
export class BindingTagActionComponent {
  @Input() set propertyValue(value: any) {
    this.step = this.serializerService._getNamedPartFromBindingExpression('step', value)
    this.title = this.serializerService._getNamedPartFromBindingExpression('title', value)
  }

  public step = ''
  public title = ''

  constructor(private serializerService: SerializerService) {}
}

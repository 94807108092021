import { Component } from '@angular/core'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Router } from '@angular/router'
import { AgRendererComponent } from '@ag-grid-community/angular'
import { comparableVerbs } from '@app/core/models/ReportDefinition'

@Component({
  selector: 'app-link-cell-renderer',
  templateUrl: './link-cell-renderer.component.html',
  styleUrls: ['./link-cell-renderer.component.sass'],
})
export class LinkCellRendererComponent implements AgRendererComponent {
  public displayValue!: string
  public param!: {
    param: any
    functionParam: any
    fieldId: string
    props: Record<string, any>
    fixedQueryParams?: Record<string, string>
    external: boolean
    field: string
    type: string
    conditionalType?: any
    conditionalTypeFunction?: any
    label: string
  }

  myparams!: ICellRendererParams & { onClick?: (params: any) => void }

  constructor(readonly router: Router, private location: Location, private window: Window) {}

  public redirect(url: string, target = '_blank'): void {
    this.window.open(url, target)
  }

  refresh(params: ICellRendererParams): boolean {
    return false
  }

  agInit(params: ICellRendererParams): void {
    this.myparams = params

    this.displayValue = params.value

    if (
      params.colDef?.cellRendererParams?.param?.conditionalType &&
      params.colDef.cellRendererParams.param.conditionalType(params.data) !== 'link' &&
      params.colDef.cellRendererParams.param.conditionalType(params.data) !== 'button'
    ) {
      return
    } else if (params.colDef?.cellRendererParams?.param?.conditionalTypeFunction) {
      const type = new Function('data', params.colDef.cellRendererParams.param.conditionalTypeFunction)(this.myparams.data)
      if (type !== 'link' && type !== 'button') return
    }

    this.param = { external: true, ...params.colDef?.cellRendererParams?.param }
  }

  click(event: any): void {
    event.stopPropagation()

    let urlRedirect = ''
    let queryParams: any = {}

    if (!!this.param && (!!this.param.props || !!this.param.fixedQueryParams)) {
      urlRedirect = (this.param.external ? this.location.origin + '/' : '') + this.param.param
      Object.keys(this.param.props).forEach((p: string) => {
        if (typeof this.param.props[p] === 'function') {
          queryParams = this.param.props[p](this.myparams.data, queryParams)
        } else {
          const verb = this.getComparableVerb(p)
          if (verb) {
            queryParams[p] = verb + this.myparams.data[this.param.props[p].replace(verb, '')]
          } else {
            queryParams[p] = this.myparams.data[this.param.props[p]]
          }
        }
      })
      Object.keys(this.param.fixedQueryParams || {}).forEach((p: string) => {
        queryParams[p] = (this.param.fixedQueryParams || {})[p]
      })
    } else if (this.param) {
      let p = ''

      if (this.param.fieldId) {
        p = this.myparams.data[this.param.fieldId]
      } else {
        p = this.displayValue
      }

      if (this.myparams.onClick instanceof Function) {
        const params = {
          event,
          rowData: this.myparams.node.data,
          rowIndex: this.myparams.node.rowIndex,
        }
        this.myparams.onClick(params)
      }

      if (this.param.param) {
        if (typeof this.param.param === 'function') {
          urlRedirect = this.param.param(this.param.external ? this.location.origin + '/' : '', this.myparams.data)
        } else {
          urlRedirect = (this.param.external ? this.location.origin + '/' : '') + this.param.param + '/' + encodeURIComponent(p)
        }
      } else if (this.param.functionParam) {
        urlRedirect = new Function('data', this.param.functionParam)(this.myparams.data)
      } else {
        urlRedirect = p
      }
    }

    if (!urlRedirect) return

    if (this.external(urlRedirect)) {
      this.redirect(urlRedirect)
    } else {
      this.router.navigate([urlRedirect], { queryParams }).catch((_: any) => _)
    }
  }

  private external(url: string): boolean {
    return /^http(?:s)?:\/{2}\S+$/.test(url)
  }

  private getComparableVerb(prop: string): string | undefined {
    return Object.values(comparableVerbs)
      .filter((x) => !!x)
      .find((filter) => this.param.props[prop].startsWith(filter))
  }
}

<div style="display: flex; gap: 15px; justify-content: space-evenly; flex-wrap: wrap; flex: 1" [formGroup]="form">
  <cv-datepicker
    [style.width.%]="displayInline ? '50': '100'"
    [label]="dateLabel || 'reports_StartingOnDate' | translate"
    [errorMessage]="isError ? null : ('common_Required' | translate)"
    [isError]="!isValid()"
    [disabled]="isDisabled()"
    [minDate]="minDate"
    [maxDate]="maxDate"
    formControlName="date"
    [required]="required"
  ></cv-datepicker>
  <cv-select
    icon="schedule"
    [suggestions]="timesOptions"
    class="flex"
    [label]="'reports_Time' | translate"
    formControlName="time"
    [errorMessage]="isError ? null : ('common_Required' | translate)"
    [isError]="!isValid()"
    [disabled]="isDisabled()"
    [required]="required"
  ></cv-select>
  <cv-select
    *ngIf="!disableUtc"
    class="flex"
    icon="public"
    [suggestions]="offsetOptions"
    [label]="'reports_UTC' | translate"
    formControlName="utc"
    [errorMessage]="isError ? null : ('common_Required' | translate)"
    [isError]="!isValid()"
    [disabled]="isDisabled()"
    [required]="required"
  ></cv-select>
</div>
<div *ngIf="dayJsValue" style="align-self: end">
  <span [class.error]="isError && !isDisabled()" style="font-size: 12px">
    <span translate>common_LocalTime</span>:
    <span>{{ getStringDatetime() }}</span>
  </span>
</div>

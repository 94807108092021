import { TargetEntityAuditType, TargetEntityBaseType, TargetEntityPolicyType, TargetEntityType } from '../enums/group-type';
import { QueryFilter2 } from './QueryFilter'
import { ReportDefinitionBase } from '@coreview/coreview-library/models/ReportDefinition'
import { MembershipReportFilters } from './reports-filters'

export interface ReportDefinitionPolicy extends ReportDefinitionBase {
  sortOrder: 'asc' | 'desc' | null;
  entityIdField: string;
  target?: TargetEntityBaseType | TargetEntityType | TargetEntityPolicyType | TargetEntityAuditType;
  customReportId?: string;
  customReportName?: string;
  reportName?: string;
  reportDefinitionId?: string;
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum ScheduleType {
  None = 'None',
  Scheduled = 'Scheduled',
}

export enum RemediationType {
  Workflow = 'Workflow',
  ScheduleReport = 'ScheduleReport',
  SendNotification = 'SendNotification',
}

export enum ExecutionType {
  ScheduleBased = 'ScheduleBased',
  EventBased = 'EventBased',
}

export enum ThresholdType {
  IsNumber = 'Number',
  IsPercentage = 'Percentage',
}

export enum ThresholdSeverity {
  Informational = 'Informational',
  Warning = 'Warning',
  Critical = 'Critical',
}

export interface ExceptionDetails {
  selectedExceptions: any[];
  isNeverExpire: boolean;
  expirationDate?: Date;
  notes: string;
}

export interface ScheduleDetails {
  cronExpression: string;
  startDatetime: Date;
  endDatetime: Date | null;
}

export interface PolicyDescription {
  policyJavascriptFunction?: string;
  thresholdJavascriptFunction?: string;
  workflowJavascriptFunction?: string;
  schedulingJavascriptFunction?: string;
}

export interface Policy {
  id?: string;
  description?: string;
  companyId: string;
  creationDateUtc: Date;
  createdBy?: string;
  policyTemplateId?: string;
  policySchema?: any;
  policySettings?: Record<string, string>;
  policyDefinitionRequestFunction?: string;
  title: string;
  policyDescription?: PolicyDescription;
  isThresholdEnabled: boolean;
  isThresholdExceeded?: boolean;
  thresholdSeverity: ThresholdSeverity;
  thresholdType?: ThresholdType;
  thresholdOperator?: string;
  thresholdValue?: number;
  isPolicyEnabled: boolean;
  isWorkflowEnabled: boolean;
  workflowSchema?: any;
  workflowSettings?: Record<string, string>;
  workflowId?: string;
  workflowName?: string;
  sendEmailWhenWorkflowFails: boolean;
  emailAddressWhenWorkflowFails?: string;
  scheduleType: ScheduleType;
  schedule?: ScheduleDetails | null;
  lastModifiedBy: string;
  lastModifiedDateUtc: Date;
  lastUpdatedDateUtc: Date;
  matchedItemsCount: number;
  exceptionItemsCount: number;
  categoryIds?: string[];
  playbookIds?: string[];
  request?: any;
  reportDefinition: ReportDefinitionPolicy;
  reportUrl?: string;
  remediationType?: string;
  policyType?: string;
  policyExceptionFields?: string[];
  javascriptFunctionForFilter?: string;
  javascriptFunctionForWorkflow?: string;
  reportFilters?: Record<string, string>;
  reportTreeFilters?: any;
  urlParameters?: Record<string, string>;
  matchedReportFilters?: { key: string; value: string };
  exceptionReportFilters?: { key: string; value: string };
  workflowInputMappings?: Record<string, string>;
  workflowInputItemNameField: string;
  nextWorkflowRunDateTimeUtc?: Date;
  exceptionsDisplayFields?: string[];
  sortIndex?: number;
  howThisAffect?: string;
  scheduleReport?: PolicyScheduleReport;
  notificationRecipients?: string[];
  timeRangeFilter?: { since?: string; to?: string; days?: number };
  globalAndReportTreeFilters?: QueryFilter2;
  groupMembershipFilter?: MembershipReportFilters;
  calculatingMetrics?: boolean;
  canEdit?: boolean;
  canSchedule?: boolean;
  canDelete?: boolean;
  canEnableRemediation?: boolean;
  hideExceptions?: boolean;
  policyGroupType?: PolicyGroupType;
  reportFieldsForWorkflow?: string[];
}

export type PolicyGroupType = 'UnknownType' | 'OutOfTheBoxPolicy' | 'CustomPolicy' | 'MspPolicy' | 'LegacyPolicy'

export interface WorkflowReport {
  workflowId: string;
  running: number;
  succeeded: number;
  failed: number;
  timedOut?: number;
  aborted?: number;
  pending?: number;
}

export interface PolicyWorkflowReport extends WorkflowReport {
  policyId: string;
  title: string;
  workflowNextRun: Date | string | null;
  workflowInputItemNameField: string;
  isPolicyEnabled: boolean;
  sortIndex?: number;
}

export interface PolicyScheduleReport {
  format?: string;
  recipients?: string[];
  sendWhen?: string;
  text?: string;
  days?: number[];
  frequency: number;
  interval?: number;
  endTime?: Date;
  startTime: Date;
  reportRequest?: any;
  type?: any;
  monthDays?: number[];
  lastDay?: boolean;
  monthlyOccurrences?: { day: number; occurrence?: number }[];
}

export interface CustomPolicyRequest {
  title: string;
  description: string;
  howThisAffect: string;
  playbookIds: string[];
  newPlaybookTitles: string[];
  newCategoryTitles: string[];
  categoryIds: string[];
  companyId: string;
  isPolicyEnabled: boolean;
  scheduleType: ScheduleType;
  schedule?: ScheduleDetails;
  isThresholdEnabled: boolean;
  thresholdSeverity: ThresholdSeverity;
  thresholdType?: ThresholdType;
  thresholdOperator?: string;
  thresholdValue?: number;
  reportUrl: string;
  reportDefinition: ReportDefinitionPolicy;
  policyExceptionFields?: string[];
  reportFilters: Record<string, string>;
  reportTreeFilters?: QueryFilter2;
  urlParameters?: Record<string, string>;
  reportFieldsForWorkflow: string[];
  isWorkflowEnabled: boolean;
  workflowId?: string;
  workflowInputMappings: Record<string, string>;
  exceptionsDisplayFields: string[];
  remediationType: string;
  notificationRecipients?: string[];
  scheduleReport?: PolicyScheduleReport | null;
  policyType: string;
  timeRangeFilter?: { since?: string; to?: string; days?: number };
  globalAndReportTreeFilters?: QueryFilter2;
  groupMembershipFilter?: MembershipReportFilters;
}

export interface PlaybookFilters {
  statuses: { id: string; title: string; isFilterActive: boolean; parentId?: string, isHidden?: boolean }[];
  categories: { id: string; title: string; isCustomCategory: boolean; isFilterActive: boolean }[];
  playbooks: (Playbook & { isFilterActive: boolean })[];
  sort: 'name_asc' | 'name_desc' | 'create_desc' | 'update_desc' | '';
  search: string;
  results: number;
}
export interface HierarchicalFilters {
  title: string;
  id: string;
  isFilterActive: boolean;
  isHidden?: boolean;
  children: HierarchicalFilters[];
}
export interface Playbook {
  id: string;
  title: string;
  description?: string;
  playbookGroupType: PlaybookGroupType;
}

export interface Category {
  id: string; 
  title: string; 
  isCustomCategory: boolean
}

export interface PlaybookTitleWithPolicyCount {
  id: string;
  title: string;
  policyCount: number;
  playbookGroupType: PlaybookGroupType;
}

export type PlaybookGroupType = 'UnknownType' | 'OutOfTheBoxPlaybook' | 'CustomPlaybook' | 'LegacyPlaybook' | 'MspPlaybook';

export const basicFilters: PlaybookFilters = {
  playbooks: [],
  categories: [],
  statuses: [
    { id: 'policy-enable-on', title: 'playbook_enablePolicy', isFilterActive: true },
    { id: 'policy-enable-off', title: 'playbook_enablePolicy', isFilterActive: true },
    { id: 'policy-workflow-on', title: 'playbook_enablePolicyWorkflow', isFilterActive: true, parentId: 'policy-enable-on' },
    { id: 'policy-workflow-off', title: 'playbook_enablePolicyWorkflow', isFilterActive: true, parentId: 'policy-enable-on' },
  ],
  search: '',
  sort: 'name_asc',
  results: 0,
}

import { Component, Input, OnInit } from '@angular/core';
import { ReportsService } from '@app/core/services/reports.service';
import { Helpers } from '@app/shared/utilities/helpers';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-operational-reports-info',
  templateUrl: './operational-reports-info.component.html',
  styleUrls: ['./operational-reports-info.component.sass']
})
export class OperationalReportsInfoComponent implements OnInit {
  @Input() rowData!: any
  @Input() type!: 'serviceHealthIssues' | 'microsoft365Messages' | 'microsoft365ServiceHealth'

  item!: any
  title!: string

  private destroyed$ = new Subject<boolean>()

  constructor(private reportsService: ReportsService, private window: Window) { }

  ngOnInit(): void {
    this.loadData()
  }

  loadData = () => {
    if (this.type === 'serviceHealthIssues')
      this.reportsService.getServiceHealthIssueById({ id: this.rowData.id }).subscribe((res: any) => {
        this.item = res.issue
      })
    else if (this.type === 'microsoft365Messages')
      this.reportsService.getMicrosoft365MessageById({ id: this.rowData.id }).subscribe((res: any) => {
        this.item = res.message
      })
    else 
      this.item = this.rowData
  }

  formatDate = (date: any): string => Helpers.formatDate(date)

  goToLink(url: string): void {
    this.window.open(url, '_blank')
  }

  getChipColorByStatus(status: string): string {
    switch (status) {
      case 'ServiceOperational':
      case 'ServiceRestored':
      case 'FalsePositive':
      case 'PostIncidentReviewPublished':
        return 'accent'
      case 'RestoringDevice':
      case 'ExtendedRecovery':
        return 'primary'
      case 'ServiceDegradation':
        return 'warn'
      case 'Informational':
      default:
        return ''
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
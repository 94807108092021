import { Component, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core'
import { NgControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { RuleConverterService } from '@app/modules/management/services/rule-converter.service'
import { FormHelpers } from '@app/shared/utilities/form-helpers'
import { BaseControlComponent, Suggestion } from '@coreview/coreview-components'
import { TranslateHelper } from '@coreview/coreview-library'
import { Subject } from 'rxjs'
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-dynamic-group-rule-builder',
  templateUrl: './dynamic-group-rule-builder.html',
  styleUrls: ['./dynamic-group-rule-builder.sass'],
})
export class DynamicGroupRuleBuilderComponent extends BaseControlComponent implements OnInit, OnDestroy {
  columns: Suggestion[] = []

  defaultOperations: Suggestion[] = []
  builderOperations: Record<string, { value: string; displayValue: string; valueHidden?: boolean }[]> = {}

  form: UntypedFormGroup = new UntypedFormGroup({
    useRulesSyntax: new UntypedFormControl(true),
    script: new UntypedFormControl('', [
      FormHelpers.conditionalValidator(() => this.form?.get('useRulesSyntax')?.value, Validators.required),
    ]),
    ruleList: new UntypedFormArray([]),
  })

  isRuleStringValid = true
  ruleStringErrorMessage: string | null = null;

  @Input()
  get value(): string {
    return this.form.get('script')?.value?.trim()
  }

  set value(script: string) {
    if (script) {
      this.form.get('script')?.setValue(script.trim(), { emitEvent: false })
    }
  }

  private destroyed$: Subject<boolean> = new Subject()

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private translateHelper: TranslateHelper,
    private ruleConverterService: RuleConverterService
  ) {
    super(ngControl)
    this.initializeBuilder()
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.onChange(this.value)
    })

    this.form.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe((status) => {
      const error = status === 'INVALID' ? { invalidRule: true } : null
      this.ngControl.control?.setErrors(error)
    })

    this.handleRuleListChanges()
    this.handleUseRulesSyntaxChanges()
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  initializeBuilder() {
    this.defaultOperations = this.ruleConverterService.getAvailableOperations()
    this.builderOperations = {
      rest: this.defaultOperations,
      date: this.defaultOperations,
      bool: [
        { value: 'Equals', displayValue: this.translateHelper.instant('common_Equals') },
        { value: 'DoesNotEqual', displayValue: this.translateHelper.instant('common_DoesNotEqual') },
      ],
    }
    this.columns = this.ruleConverterService.getColumnDefinitions()
  }

  get ruleList(): UntypedFormArray {
    return this.form.get('ruleList') as UntypedFormArray
  }

  get useRulesSyntax(): boolean {
    return this.form.get('useRulesSyntax')?.value
  }

  private convertRulesToScript(rules: any[]): string {
    const finalScript = this.ruleConverterService.convertObjToRuleString(rules)
    return finalScript
  }

  private convertScriptToRules(script: string): any[] {
    if (script) {
      script = script.trim();
      const validationResult = this.ruleConverterService.isRuleStringValid(script);
      this.isRuleStringValid = validationResult.isValid;

      if (!this.isRuleStringValid) {
        this.ruleStringErrorMessage = validationResult.errorMessageKey || '';
      } else {
        this.ruleStringErrorMessage = null;
        const rules = this.ruleConverterService.parseRuleStringToObj(script).rules;
        return rules;
      }
    } else {
      this.isRuleStringValid = true;
      this.ruleStringErrorMessage = null;
    }
    return [];
  }
  
  private updateRuleList(rules: any[]): void {
    const ruleList = this.form.get('ruleList') as UntypedFormArray
    ruleList.clear()
    rules.forEach((rule) => {
      const columnData = this.columns.find((col) => col.value === rule.name)
      const value = typeof rule.value === 'boolean' ? rule.value.toString() : rule.value
      const newGroup = new UntypedFormGroup({
        condition: new UntypedFormControl(rule.condition || 'AND', Validators.required),
        name: new UntypedFormControl(rule.name, Validators.required),
        nameObj: new UntypedFormControl(
          {
            value: rule.name,
            displayValue: columnData?.displayValue || rule.name,
            data: { type: columnData?.data?.type || 'string' },
          },
          Validators.required
        ),
        operation: new UntypedFormControl(rule.operation, Validators.required),
        valueObj: new UntypedFormControl(
          {
            value,
            displayValue: value,
          },
          Validators.required
        ),
        value: new UntypedFormControl(value, Validators.required),
        valueDate: new UntypedFormControl(null),
      })

      ruleList.push(newGroup)
      ruleList.updateValueAndValidity({ onlySelf: true, emitEvent: false })
    })
  }

  private handleRuleListChanges(): void {
    this.form
      .get('ruleList')
      ?.valueChanges.pipe(
        filter(() => !this.form.get('useRulesSyntax')?.value && !!this.form.get('ruleList')?.valid && this.isRuleStringValid),
        takeUntil(this.destroyed$)
      )
      .subscribe((value) => {
        const finalScript = this.convertRulesToScript(value)
        this.form.get('script')?.setValue(finalScript, { emitEvent: true })
      })
  }

  private handleUseRulesSyntaxChanges(): void {
    this.form
      .get('useRulesSyntax')
      ?.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((useSyntax) => {
        if (!useSyntax) {
          const rules = this.convertScriptToRules(this.form.get('script')?.value)
          this.updateRuleList(rules)
        } else if (this.isRuleStringValid && !!this.form.get('ruleList')?.valid) {
          const finalScript = this.convertRulesToScript(this.form.get('ruleList')?.value)
          this.form.get('script')?.setValue(finalScript, { emitEvent: false })
        }
      })
  }
}

export interface DynamicGroupRuleValue {
  useRulesSyntax: boolean
  script: string
  rulesList: any[]
}

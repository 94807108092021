<div class="panel-container">
    <div class="panel">
        <div class="title p-l-20" style="display: flex; line-height: 69px">
            <div style="flex: 1; font-size: 14px; font-weight: 600" class="uppercase">
                <span translate>licenses_Filters</span>
            </div>
        </div>
        <div class="p-20" style="height: 100%; overflow-y: scroll;">
            <div class="section">
                <div class="subtitle">
                    <div translate>
                        <span style="line-height: 24px" translate>common_Users</span>
                        <mat-icon class="history">history</mat-icon>
                    </div>
                </div>
                <cv-switch style="--margin-top: 10px" [(ngModel)]="configuration.includeNewUsers">
                    {{ 'licenses_IncludeNewUsers' | translate }}
                </cv-switch>
            </div>

            <div class="section">
                <div class="subtitle">
                    <div translate>
                        <span style="line-height: 24px" translate>licenses_Costs</span>
                        <mat-icon class="history">history</mat-icon>
                    </div>
                </div>

                <cv-switch style="--margin-top: 10px" [(ngModel)]="configuration.includeCosts">
                    {{ 'licenses_DisplayCosts' | translate }}
                </cv-switch>             
            </div>
        </div>

        <div class="footer-container">
            <cv-button (click)="close()" class="m-r-10" buttonType="secondary" [text]="'common_Cancel' | translate">
            </cv-button>
            <cv-button (click)="submit()" primary="secondary" [text]="'common_Apply' | translate">
            </cv-button>
        </div>
    </div>
</div>
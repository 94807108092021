import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

@Component({
  selector: 'app-icon-renderer',
  templateUrl: './icon-renderer.component.html',
  styleUrls: ['./icon-renderer.component.sass']
})
export class IconRendererComponent implements AgRendererComponent {

  public icon!: string
  public iconDescription = ''
  public isFabricIcon = false
  public params: any
  public showValue: boolean = false

  agInit(params: ICellRendererParams): void {
    this.params = params
    this.icon = this.params.icon || null
    this.isFabricIcon = this.params.isFabricIcon
    this.showValue = this.params.showValue || this.showValue
  }

  refresh(params?: any): boolean {
    return true
  }

  getIcon(): string {
    if (this.params.getIcon instanceof Function) {
      return this.params.getIcon(this.params.node.data)
    } else {
      return this.icon
    }
  }

  getIconClass(): string {
    if (this.params.getIconClass instanceof Function) {
      return this.params.getIconClass(this.params.node.data)
    } else if (this.params.iconClass) {
      return this.params.iconClass
    }
    return 'material-icons-outlined'
  }

  hideIcon(): boolean {
    if (this.params.hideIcon instanceof Function) {
      return this.params.hideIcon(this.params.node.data)
    }
    return false
  }

  getColor(): string {
    if(!!this.params.color || this.params.colorMapperFunc) {
      if (!!this.params.colorMapperFunc) {
        return Function(
          'event',          
          this.params.colorMapperFunc
        )(this.params.node.data)
      } else {
        return this.params.color
      }
      
    } 
    return 'inherit'
  }
}

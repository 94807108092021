import { createReducer, on } from '@ngrx/store'
import { MenuState } from './menu.types'
import {
  loadFavorites,
  loadFavoritesInvalidate,
  loadFavoritesResponse,
  loadAllMenu,
  loadAllMenuInvalidate,
  loadAllMenuResponse,
} from './menu.actions'

export const initialState: MenuState = {
  menu: { menuAll: [], menuFiltered: [] },
  favoriteMenus: [],
  menuHelps: [],
  favoriteSavedReports: [],
  didInvalidate: false,
  isFetching: false,
  error: null,
  loaded: false,
  loadedPlaybooks: false,
}

export const menuReducer = createReducer(
  initialState,
  on(loadAllMenu, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(loadAllMenuResponse, (state, { menu }) => ({ ...state, menu, loaded: true, loadedPlaybooks: true })),
  on(loadAllMenuInvalidate, (state, action) => ({
    ...state,
    error: action.error,
    didInvalidate: true,
    loaded: true,
    loadedPlaybooks: true,
  })),
  on(loadFavorites, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(loadFavoritesResponse, (state, favorites) => ({ ...state, ...favorites })),
  on(loadFavoritesInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true }))
)

export default menuReducer

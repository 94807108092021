import { Injectable } from '@angular/core'
import { ApiclientService } from './apiclient.service'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class CommercialPortalSkusService {
  constructor(private apiClient: ApiclientService, private httpClient: HttpClient) {}

  getCommercialPortalSkus(): Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/commercial/portalskus`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  activateDemoMode(cskusIds: string[]) {
    const params = { commercialSkusIds: cskusIds }
    return this.httpClient.post<any>(`${this.apiClient.basePortalApiUrl}/organization/demomode/activate`, params, { withCredentials: true })
  }

  exitDemoMode() {
    return this.httpClient.post<any>(`${this.apiClient.basePortalApiUrl}/organization/demomode/exit`, {}, { withCredentials: true })
  }
}

import { SavedReport } from '@app/core/models/saved-report'
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
export interface Schedulation {
  savedReportId: string;
  reportName: string;
  description: string;
  schedulationInfo: SchedulationInfo;
}

export interface SchedulationInfo {
  id?: string;
  recipients?: string[];
  text?: string;
  sendWhen: SendWhenCondition;
  format: ReportFormat;
  jobInfo?: JobInfo;
  workflowConfig?: any;
  isShared: boolean;
  userName?: string;
}

export type SendWhenCondition = 'Always' | 'IsEmpty' | 'IsNotEmpty' | 'IsComplianceReport'
export type ReportFormat = 'PDF' | 'CSV' | 'XLSX'

export interface JobInfo {
  id?: string;
  recurrence?: JobRecurrence;
  startTime?: string;
  state?: JobState;
}

export enum JobState {
  Enabled = 0,
  Disabled = 1,
  Faulted = 2,
  Completed = 3,
  Unknown = 4,
}

export interface JobRecurrence {
  count?: number;
  endTime?: string;
  frequency?: JobRecurrenceFrequency;
  interval?: number;
  schedule?: JobRecurrenceSchedule;
}

export enum JobRecurrenceFrequency {
  Minute = 0,
  Hour = 1,
  Day = 2,
  Week = 3,
  Month = 4,
  Year = 5,
}

export interface JobRecurrenceSchedule {
  days?: JobScheduleDay[];
  hours?: number[];
  minutes?: number[];
  monthDays?: number[];
  monthlyOccurrences?: JobScheduleMonthlyOccurrence[];
  months?: number[];
  lastDay?: boolean;
}

export interface JobScheduleMonthlyOccurrence {
  day?: JobScheduleDay;
  occurrence?: number;
}

export enum JobScheduleDay {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6,
}

export interface ScheduledReport {
  report: SavedReport;
  schedulationInfo: SchedulationInfo;
}

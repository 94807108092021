import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AccountSkuDetails } from '@app/core/models/AccountSkuDetails'
import { ApiclientService } from '@app/core/services/apiclient.service'
import { Constants } from '@app/shared/utilities/constants'
import { Observable, ReplaySubject } from 'rxjs'
import { pluck } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class LicensePoolService {
  private static tablookup: Record<string, number[]> = {
    optimization: [
      Constants.menuIds.licenseSavingsOpportunities,
      Constants.menuIds.licenseOptimization,
      Constants.menuIds.licenseByUser,
      Constants.menuIds.licenseServiceUsage,
      Constants.menuIds.licenseDuplicatedPlans,
    ],
    poolCenter: [
      Constants.menuIds.licensePoolReport,
      Constants.menuIds.licensePoolCostReport,
      Constants.menuIds.licensePoolReportSnapshots,
    ],
  }

  private permissionsLoadedSubject = new ReplaySubject<boolean>();

  private permissionTabs!: number[]

  constructor(private apiclient: ApiclientService, private httpClient: HttpClient) {
    this.getPermissionsTabs().subscribe((tabsIds) => {
      this.permissionTabs = tabsIds;
      this.permissionsLoadedSubject.next(true)
    })
  }

  arePermissionsLoaded(): Observable<boolean> {
    return this.permissionsLoadedSubject;
  }

  getLicensePoolsGroupByName(params: any = {}, reportType: string, companyId: string) {
    return this.httpClient.post<any>(
      `${this.apiclient.basePortalApiUrl}/licensepoolcenter/groupbypoolname/${reportType}/${companyId}?metadata=true`,
      params,
      {
        withCredentials: true,
      }
    )
  }

  getAllUsersForLicensePoolPage(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.apiclient.basePortalApiUrl}/users/?metadata=true&includeMasterTenantUsers=true`, {
      withCredentials: true,
    })
  }

  getLicensePoolSnapshotTimestamps(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.apiclient.basePortalApiUrl}/licensepool/report/snapshots/timestamps`, {
      withCredentials: true,
    })
  }

  getLicensePoolSnapshot(params: any = {}) {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/licensepool/report/snapshots?metadata=true`, {
      withCredentials: true,
      params,
    })
  }

  getLicensePoolsV3Data(params: any, reportType: string) {
    return this.httpClient.post<any>(
      `${this.apiclient.basePortalApiUrl}/licensepoolcenterv3/${reportType}`,
      { ...params, metadata: true },
      { withCredentials: true }
    )
  }

  getOnlineUsersGrouped(params: any = {}) {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/onlineusers/grouped?metadata=true`, {
      withCredentials: true,
      params,
    })
  }

  updateAssignedLicensePools(params: any) {
    params.shouldSkipCost = true
    return this.httpClient.put<any>(`${this.apiclient.basePortalApiUrl}/licensepool/updateassigned`, params, {
      withCredentials: true,
    })
  }

  canSeeTab(id: number) {
    return !this.permissionTabs?.length || this.permissionTabs.includes(id)
  }

  tabIndex(parentReport: string, id: number) {
    if (!this.permissionTabs?.length) {
      return LicensePoolService.tablookup[parentReport].indexOf(id)
    } else {
      return this.permissionTabs.indexOf(id)
    }
  }

  buildSnapshotsData(
    snapshots: { toView: { licensePoolGroupReports: any }; toCompare?: { licensePoolGroupReports: any } },
    snapshotToView: string,
    snapshotToCompare: string
  ) {
    const rows: any = []

    snapshots?.toView?.licensePoolGroupReports?.forEach((r: any) => {
      const myrow: any = { name: r.name }

      myrow['users_' + snapshotToView] = r.users
      myrow['assignedLicenses_' + snapshotToView] = r.totalAsigned
      myrow['consumedLicenses_' + snapshotToView] = r.totalConsumed
      myrow['availableLicenses_' + snapshotToView] = r.totalAsigned - r.totalConsumed

      myrow.items = []
      r.items?.forEach((i: any) => {
        const myitem: any = { sku: i.sku }

        myitem['assignedLicenses_' + snapshotToView] = i.value
        myitem['consumedLicenses_' + snapshotToView] = i.consumed
        myitem['availableLicenses_' + snapshotToView] = i.value - i.consumed

        myrow.items.push(myitem)
      })

      rows.push(myrow)
    })

    snapshots?.toCompare?.licensePoolGroupReports?.forEach((r: any) => {
      const myrow = rows.find((row: any) => row.name === r.name)
      if (!myrow) {
        return
      }

      myrow['users_' + snapshotToCompare] = r.users
      myrow['assignedLicenses_' + snapshotToCompare] = r.totalAsigned
      myrow['consumedLicenses_' + snapshotToCompare] = r.totalConsumed
      myrow['availableLicenses_' + snapshotToCompare] = r.totalAsigned - r.totalConsumed

      r.items?.forEach((i: any) => {
        const myitem = myrow.items.find((item: any) => item.sku === i.sku)
        if (!myitem) {
          return
        }

        myitem['assignedLicenses_' + snapshotToCompare] = i.value
        myitem['consumedLicenses_' + snapshotToCompare] = i.consumed
        myitem['availableLicenses_' + snapshotToCompare] = i.value - i.consumed
      })
    })

    return rows
  }

  buildLpData(data: any) {
    data.licensePoolGroupReports?.forEach((l: any) => {
      l.items?.forEach((i: any) => {
        const accountSku = data.accountSkuDetails.find((item: any) => item.accountSkuId === i.accountSkuId)
        i.active = accountSku.activeUnits
      })
    })
    return data
  }

  getAccountSkuDetails(): Observable<AccountSkuDetails[]> {
    return this.httpClient
      .get<any>(`${this.apiclient.basePortalApiUrl}/accountskudetails`, {
        withCredentials: true,
        params: { pageSize: 100000 },
      })
      .pipe(pluck('accountSkuDetails'))
  }

  private getPermissionsTabs() {
    return this.httpClient
      .get<any>(`${this.apiclient.basePortalApiUrl}/securitymanager/licensetabs`, {
        withCredentials: true,
      })
      .pipe(
        pluck('licenseTabs')
      )
  }
}

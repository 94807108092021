import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'
import isObject from 'lodash-es/isObject'

@Component({
  selector: 'app-link-array-cell-renderer',
  templateUrl: './array-link-cell-renderer.component.html',
  styleUrls: ['./array-link-cell-renderer.component.sass'],
})
export class ArrayLinkCellRendererComponent implements AgRendererComponent {
  public values!: string[]
  public params!: any

  constructor(private location: Location, private window: Window) {}

  click(event: any, index: number): void {
    event.preventDefault()
    event.stopImmediatePropagation()
    if (this.params.onClick instanceof Function) {
      const params = {
        event,
        rowData: this.params.node.data,
        index
      }
      this.params.onClick(params)
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return true
  }

  agInit(params: ICellRendererParams): void {
    this.params = params
    this.values = (params.value || []).map((x: any) => (isObject(x) ? JSON.stringify(x) : x))
  }
}

import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

@Component({
  selector: 'app-task-title-renderer',
  templateUrl: './task-title-renderer.component.html',
  styleUrls: ['./task-title-renderer.component.sass'],
})
export class TaskTitleRendererComponent implements AgRendererComponent {
  public params!: any
  public value!: string
  public text!: string
  public warning!: boolean
  public isAggregated!: boolean

  constructor() {}

  refresh(params: ICellRendererParams): boolean {
    return true
  }

  agInit(params: any): void {
    const { data } = params
    this.params = params
    this.value = params.value
    this.text = params.getText ? params.getText(this.value) : this.value
    this.warning = data && !!data.warningMessage
    this.isAggregated = data && !!data.isAggregated
  }
}

<div class="left-panel">
  <span style="font-size: 14px; font-weight: 600" class="uppercase m-t-20 m-b-20">{{ titles[mode] }}</span>

  <ng-container *ngFor="let step of steps; let i = index">
    <div
      *ngIf="mode !== 'view'"
      class="progress-block"
      [class.prev]="i < activeIndex"
      [class.active]="i === activeIndex"
      [class.not-available]="step.status === 'NotAvailable'"
      [class.invalid]="isInvalidStep(i)"
    >
      <span class="icon material-icons"></span>
      <span *ngIf="i !== steps.length - 1" class="bar-bottom"></span>
      <div
        class="progress-title"
        [class.active-step]="i === activeIndex"
        [class.m-t-10]="step.status === 'NotAvailable'"
        (click)="clickTab(i)"
      >
        <div>{{ step.title }}</div>
        <div *ngIf="step.status === 'NotAvailable'" class="progress-sub-title" translate>common_Unavailable</div>
        <span class="error-sub-title" *ngIf="isInvalidStep(i)" translate>common_FieldsNeedToBeReviewed</span>
      </div>
    </div>
    <div
      *ngIf="mode === 'view'"
      class="linear-block"
      [class.prev]="i < activeIndex"
      [class.active]="i === activeIndex"
      [class.not-available]="step.status === 'NotAvailable'"
    >
      <div class="linear-title" (click)="clickTab(i)">
        <div>{{ step.title }}</div>
        <div *ngIf="step.status === 'NotAvailable'" class="linear-sub-title" translate>common_Unavailable</div>
      </div>
    </div>
  </ng-container>
</div>
<div class="right-panel">
  <form [formGroup]="form" class="content-container" [ngSwitch]="activeIndex">
    <div class="panel-title-container">
      <span class="panel-title uppercase">{{ steps[activeIndex].title }}</span
      ><span *ngIf="activeIndex === 0 && mode !== 'view'">*</span>
    </div>
    <ng-container *ngSwitchCase="0">
      <div class="tab">
        <div style="overflow: auto; flex: 1; padding: 0 12px; margin: 0 -12px">
          <mat-checkbox
            *ngIf="!!savedReport && !!savedReport.guid && !!canOverwrite"
            style="display: block; margin: 10px 0px"
            formControlName="overwriteExistingReport"
            ><span translate>reports_OverwriteExistingSavedReport</span></mat-checkbox
          >
          <cv-input
            style="margin: 10px 0; width: 100%; box-sizing: border-box"
            label="{{ 'reports_ReportName' | translate }}*"
            [errorMessage]="'common_Required' | translate"
            formControlName="reportName"
          ></cv-input>
          <cv-textarea
            style="margin: 10px 0; width: 100%; box-sizing: border-box"
            [label]="'Description' | translate"
            formControlName="description"
          ></cv-textarea>
          <cv-select
            [suggestions]="schedulationService.sendWhenOptions"
            style="margin: 10px 0; width: 100%; box-sizing: border-box"
            [label]="'reports_SendWhen' | translate"
            formControlName="sendWhen"
          ></cv-select>
          <app-recurrence [noEndDatecheck]="false" formControlName="recurrenceData"></app-recurrence>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <div class="tab">
        <div style="overflow: auto; flex: 1; padding: 0 12px; margin: 0 -12px">
          <cv-multi-input
            (inputChange)="usersInputChanged($event)"
            [forceSelection]="false"
            [suggestions]="userSuggestions"
            style="margin: 10px 0; width: 100%; box-sizing: border-box"
            [label]="'reports_SubscribeUsersEmail' | translate"
            [errorMessage]="'common_Required' | translate"
            type="mail"
            formControlName="users"
          ></cv-multi-input>
          <cv-textarea
            style="margin: 10px 0; width: 100%; box-sizing: border-box"
            [label]="'reports_EmailTextComment' | translate"
            formControlName="text"
          ></cv-textarea>
          <cv-select
            [suggestions]="schedulationService.formatOptions"
            style="margin: 10px 0; width: 100%; box-sizing: border-box"
            label="{{ 'reports_ReportFormat' | translate }}*"
            [errorMessage]="'common_Required' | translate"
            formControlName="format"
          ></cv-select>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <div class="tab">
        <div style="overflow: auto; flex: 1; padding: 0 12px; margin: 0 -12px">
          <div
            style="
              margin: 10px 0;
              width: 100%;
              box-sizing: border-box;
              border: 1px solid rgba(0, 0, 0, 0.12);
              border-radius: 4px;
              padding: 15px;
            "
          >
            <div style="display: flex; line-height: 24px">
              <mat-icon class="summary-icon">event</mat-icon
              ><span style="flex: 1" class="uppercase" translate>reports_ScheduleSettings</span>
              <cv-button
                (click)="clickTab(0)"
                style="margin-top: -5px; margin-right: -5px"
                buttonType="tertiary"
                leftIcon="edit"
                [text]="'common_Edit' | translate"
              ></cv-button>
            </div>
            <cv-alert-bar
              *ngIf="recurrenceData?.recurrence === 'recurring' && !recurrenceData?.interval && recurrenceData?.frequency !== '3'"
              [hideCloseButton]="true"
              variant="error"
              >{{ 'reports_ReviewFields' | translate }}: {{ getErrorFields() }}</cv-alert-bar
            >
            <span
              *ngIf="recurrenceData?.recurrence !== 'recurring' || recurrenceData?.interval || recurrenceData?.frequency === '3'"
              style="font-size: 12px"
              [innerHtml]="getSchedulationSummary()"
            ></span>
          </div>

          <div
            style="
              margin: 10px 0;
              width: 100%;
              box-sizing: border-box;
              border: 1px solid rgba(0, 0, 0, 0.12);
              border-radius: 4px;
              padding: 15px;
            "
          >
            <div style="display: flex; line-height: 24px">
              <mat-icon class="summary-icon">email</mat-icon
              ><span style="flex: 1" class="uppercase" translate>reports_ConfigureSubscription</span>
              <cv-button
                (click)="clickTab(1)"
                style="margin-top: -5px; margin-right: -5px"
                buttonType="tertiary"
                leftIcon="edit"
                [text]="'common_Edit' | translate"
              ></cv-button>
            </div>
            <cv-alert-bar *ngIf="!formControls.users.value?.length" [hideCloseButton]="true" variant="error">{{
              'reports_RecipientsRequired' | translate
            }}</cv-alert-bar>
            <div *ngIf="formControls.users.value?.length">
              <div style="font-size: 12px; width: 100%; box-sizing: border-box; border-bottom: 1px solid rgba(0, 0, 0, 0.12)" translate>
                reports_SubscribedUsers
              </div>
              <div style="font-size: 12px" *ngFor="let user of form.get('users')?.value">
                {{ user.value }}
              </div>
              <div
                style="margin-top: 10px; font-size: 12px; width: 100%; box-sizing: border-box; border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
                translate
              >
                reports_EmailTextComment
              </div>
              <div style="font-size: 12px">{{ form.get('text')?.value || 'reports_NoTextWillBeAttachedEmail' | translate }}</div>
              <div
                style="margin-top: 10px; font-size: 12px; width: 100%; box-sizing: border-box; border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
              >
                <span translate>reports_ReportFormat</span>*
              </div>
              <div style="font-size: 12px">{{ form.get('format')?.value }}</div>
            </div>
          </div>
          <mat-checkbox style="display: inline-block" formControlName="public"><span translate>reports_SetAsPublic</span> </mat-checkbox>
          <mat-icon
            [tooltip]="'common_ScheduleReportPublic' | translate"
            style="width: 20px; height: 20px; font-size: 20px; margin-left: 5px; vertical-align: middle"
            class="material-icons-outlined info"
            >info</mat-icon
          >
        </div>
      </div>
    </ng-container>
  </form>
  <div class="footer-container" *ngIf="mode !== 'view'">
    <cv-button
      *ngIf="activeIndex === 0"
      [text]="'common_Cancel' | translate"
      (click)="cancel()"
      buttonType="secondary"
      leftIcon="close"
      style="float: left"
      class="m-r-10"
    ></cv-button>
    <cv-button
      *ngIf="activeIndex === steps.length - 1"
      (click)="clickTab(0)"
      buttonType="secondary"
      leftIcon="first_page"
      style="float: left"
      class="m-r-10"
    ></cv-button>
    <cv-button
      *ngIf="activeIndex !== 0"
      style="float: left"
      [text]="'common_Back' | translate"
      leftIcon="navigate_before"
      buttonType="secondary"
      (click)="changeTab(-1)"
    ></cv-button>
    <cv-button
      *ngIf="activeIndex !== steps.length - 1"
      style="float: right"
      [text]="'common_Next' | translate"
      rightIcon="navigate_next"
      buttonType="secondary"
      (click)="changeTab(1)"
    ></cv-button>
    <cv-button
      *ngIf="activeIndex === steps.length - 1"
      (click)="save()"
      style="float: right"
      [text]="'common_Save' | translate"
      rightIcon="check"
      [disabled]="form.invalid"
    ></cv-button>
  </div>
</div>

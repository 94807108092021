import { FlatTreeControl } from '@angular/cdk/tree'
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Tree, TreeNode } from './models/Tree'

@Injectable()
export class TreeService {
  dataChange = new BehaviorSubject<Tree[]>([])

  get data(): Tree[] {
    return this.dataChange.value
  }

  init(initialData: any[]) {
    const data = this.createTree(initialData)
    this.dataChange = new BehaviorSubject(data)
  }

  getParentNode(node: TreeNode, treeControl: FlatTreeControl<TreeNode, TreeNode>): TreeNode | null {
    const currentLevel = treeControl.getLevel(node)

    if (currentLevel < 1) {
      return null
    }

    const startIndex = treeControl.dataNodes.indexOf(node) - 1

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = treeControl.dataNodes[i]

      if (treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode
      }
    }
    return null
  }

  /*
    we show expandable nodes when:
      term is found on the name OR term found in any children OR term is found on parent

    we show not expandable nodes (last level) when:
      term is found on the name
        OR
      term is found on the parent
  */
  filterByName(term: string, treeControl: FlatTreeControl<TreeNode, TreeNode>): void {
    treeControl.collapseAll()
    treeControl.dataNodes.forEach((x) => {
      x.hidden = x.expandable
        ? !this.findInName(x, term) &&
          !this.hasChildrenToShow(x, term, treeControl) &&
          !this.findInParentNameRecursive(x, term, treeControl)
        : !this.shouldShowChildNode(x, term, treeControl)
    })
    treeControl.dataNodes
      .filter((x) => x.expandable && !x.hidden)
      .forEach((node) => {
        treeControl.expand(node)
      })
  }

  private hasChildrenToShow(node: TreeNode, term: string, treeControl: FlatTreeControl<TreeNode, TreeNode>): boolean {
    const descendants = treeControl.getDescendants(node)
    return descendants.some((descendantNode) => this.findInName(descendantNode, term))
  }

  private shouldShowChildNode(node: TreeNode, term: string, treeControl: FlatTreeControl<TreeNode, TreeNode>): boolean {
    const nameIncludesFilter = this.findInName(node, term)
    if (nameIncludesFilter) {
      return true
    } else {
      return this.findInParentNameRecursive(node, term, treeControl)
    }
  }

  private findInParentNameRecursive(node: TreeNode, term: string, treeControl: FlatTreeControl<TreeNode, TreeNode>): boolean {
    const parentNode = this.getParentNode(node, treeControl)
    if (parentNode) {
      if (this.findInName(parentNode, term)) {
        return true
      }
      return this.findInParentNameRecursive(parentNode, term, treeControl)
    }
    return false
  }

  private findInName(node: TreeNode, term: string): boolean {
    return node.name.toString().toLowerCase().indexOf(term.toLowerCase()) > -1
  }

  private createTree(items: any[] = []): Tree[] {
    return items.map((item: any) => {
      const tree = new Tree()
      tree.item = item.text
      tree.id = item.id || undefined
      tree.icon = item.icon || undefined
      tree.inheritedIds = item.inheritedIds
      if (item.children) {
        tree.children = this.createTree(item.children)
      }

      return tree
    })
  }
}

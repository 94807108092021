import { ReportsService } from './../../core/services/reports.service'
import { LocalstorageService } from './../../core/services/localstorage.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Icon, icon, Layer } from 'leaflet'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
export interface IReportMapDefinition {
  getData: (params: ApiDataParameters) => Observable<any>;
  resultItem?: string;
  mapData: { disableAllControls?: boolean; maxZoom?: number; customLayers?: Layer[] };
  pageSize?: number;
  clusterClick: boolean;
  hideMap?: boolean;
}

export const mapIcon = {
  icon: icon({
    ...Icon.Default.prototype.options,
		iconUrl: '../assets/marker-icon.png',
		iconRetinaUrl: '../assets/marker-icon-2x.png',
		shadowUrl: '../assets/marker-shadow.png'
  })
}

@Injectable({
  providedIn: 'root',
})
export class ReportsMapService {
  definitionsMap: Record<string, IReportMapDefinition> = {
    '/v2/azurereporting/signIns': {
      getData: (params) => this.reportsService.getAzureReportingSignInCoordinatesEvent(this.storage.selectedOrganization?.id, params),
      clusterClick: true,
      mapData: {},
      pageSize: 9999
    },
  }

  constructor(private storage: LocalstorageService, private reportsService: ReportsService) {}
}

export class Tree {
  id: number | string = 0
  item = ''
  icon?: string
  children: Tree[] = []
  // used for management actions permissions, where the same action has multiple related actions
  // (because of different starting points) therefore multiple related ids.
  inheritedIds?: (string | number)[]
}

export class TreeNode {
  expandable = false
  name: string | number = ''
  level = 0
  icon?: string | null = ''
  ref: any
  hidden?: boolean
}

import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections'
import { FlatTreeControl } from '@angular/cdk/tree'
import { Injectable } from '@angular/core'
import { BehaviorSubject, merge, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { OrganizationalUnit } from '../models/organizational-unit'
import { OrganizationalUnitService, OrganizationalUnitsResponse } from './organizational-unit.service'

export class OUTreeNode {
  constructor(
    public id: string,
    public name: string,
    public distinguishedName: string,
    public icon: string,
    public level: number,
    public expandable: boolean,
    public selected: boolean,
    public disabled: boolean
  ) {}
}

@Injectable({ providedIn: 'root' })
export class OrganizationalUnitTreeService {
  isConfiguring = false

  get isConfiguringOus() {
    return this.isConfiguring
  }

  set isConfiguringOus(isConfiguring: boolean) {
    this.isConfiguring = isConfiguring
  }

  constructor(private organizationalUnitService: OrganizationalUnitService) {}

  initialData(companyId: string, domainController?: string, selectedOrganizationalUnit?: string): Observable<OrganizationalUnitsResponse> {
    return this.organizationalUnitService.getOrganizationalUnits(companyId, domainController, selectedOrganizationalUnit)
  }

  getChildren(companyId: string, node: OUTreeNode, selectedOrganizationalUnit?: string): Observable<OUTreeNode[]> {
    return this.organizationalUnitService.getOrganizationalUnits(companyId, undefined, undefined, node.id).pipe(
      map((response) => response.organizationalUnits || []),
      map((ous: OrganizationalUnit[]) => this.ouMapper(ous, node.level + 1, selectedOrganizationalUnit))
    )
  }

  ouMapper(organizationalUnits: OrganizationalUnit[], level: number, selectedOrganizationalUnit?: string): OUTreeNode[] {
    organizationalUnits.sort((a, b) => a.name.localeCompare(b.name))
    return organizationalUnits.map(
      (ou) =>
        new OUTreeNode(
          ou.id,
          ou.name,
          ou.distinguishedName,
          ou.distinguishedName.toLocaleLowerCase().startsWith('dc=') ? 'dns' : '',
          level,
          ou.hasChild,
          ou.distinguishedName === selectedOrganizationalUnit,
          ou.deleted || (!this.isConfiguring && (!ou.userFilterEnabled || ou.distinguishedName === 'DC=local'))
        )
    )
  }

  isExpandable(node: OUTreeNode): boolean {
    return node.expandable
  }
}

export class OrganizationalUnitTreeDataSource implements DataSource<OUTreeNode> {
  dataChange = new BehaviorSubject<OUTreeNode[]>([])

  get data(): OUTreeNode[] {
    return this.dataChange.value
  }
  set data(value: OUTreeNode[]) {
    this.treeControl.dataNodes = value
    this.dataChange.next(value)
  }

  constructor(
    private treeControl: FlatTreeControl<OUTreeNode>,
    private organizationalUnitTreeService: OrganizationalUnitTreeService,
    private companyId: string,
    private selectedOrganizationalUnit?: string
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<OUTreeNode[]> {
    this.treeControl.expansionModel.changed.subscribe((change) => {
      if (change.added || change.removed) {
        this.handleTreeControl(change)
      }
    })
    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data))
  }

  disconnect(collectionViewer: CollectionViewer): void {
    /* cannot remove this as it's required by DataSource */
  }

  handleTreeControl(change: SelectionChange<OUTreeNode>) {
    if (change.added) {
      change.added.forEach((node) => this.toggleNode(node, true))
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false))
    }
  }

  toggleNode(node: OUTreeNode, expand: boolean) {
    const index = this.data.indexOf(node)
    if (index < 0) {
      // if cannot find the node, nothing to do
      return
    }

    if (expand) {
      this.organizationalUnitTreeService.getChildren(this.companyId, node, this.selectedOrganizationalUnit).subscribe((nodes) => {
        this.data.splice(index + 1, 0, ...nodes)
        this.dataChange.next(this.data)
        if (!!this.selectedOrganizationalUnit && !this.data.find((x) => x.distinguishedName === this.selectedOrganizationalUnit)) {
          this.data
            .filter((n) => (this.selectedOrganizationalUnit || '').includes(n.distinguishedName))
            .forEach((nodeToExpand) => {
              this.treeControl.expand(nodeToExpand)
            })
        }
      })
    } else {
      let count = 0
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {
        /* empty because it servers the purpose of updating the count */
      }
      this.data.splice(index + 1, count)
      this.dataChange.next(this.data)
    }
  }
}

<mat-icon
  [class.disabled]="disabled"
  *ngIf="!isFabricIcon && !hideIcon()"
  style="vertical-align: middle; cursor: pointer"
  (click)="disabled ? null : click($event)"
  [ngClass]="params.iconClass ? params.iconClass : 'material-icons-outlined'"
  [tooltip]="tooltip"
  [display]="!!tooltip"
  [hideDelay]="0"
  [style.color]="color"
  class="f-s-20"
>
  {{ getIcon() }}
</mat-icon>
<em
  [class.disabled]="disabled"
  *ngIf="isFabricIcon && !hideIcon()"
  style="vertical-align: middle; margin-bottom: 4px; cursor: pointer"
  (click)="disabled ? null : click($event)"
  [ngClass]="getIcon()"
  [tooltip]="tooltip"
  [display]="!!tooltip"
  [hideDelay]="0"
  [style.color]="color"
  class="f-s-20"
>
</em>

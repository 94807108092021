import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core'
import { FormControl, Validators, UntypedFormGroup, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms'

import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

import { Suggestion, ToastService } from '@coreview/coreview-components'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'

import { clone, keys } from 'lodash-es'

import { RightPanelRef } from '@core/services/right-panel.service'
import { LocalstorageService } from '@core/services/localstorage.service'
import { ReportsService } from '@core/services/reports.service'
import { ApplicationInsightService } from '@core/services/application-insight.service'

import { SavedReport } from '@core/models/saved-report'
import { JobRecurrence, JobRecurrenceFrequency, JobRecurrenceSchedule, JobScheduleDay, SchedulationInfo } from '@core/models/Schedulation'

import { Constants } from '@app/shared/utilities/constants'
import { Recurrence } from '@app/shared/components/recurrence/recurrence.component'
import { TranslateHelper } from '@coreview/coreview-library'
import { SchedulationService } from '../../services/schedulation.service'

@Component({
  selector: 'app-new-schedulation',
  templateUrl: './new-schedulation.component.html',
  styleUrls: ['./new-schedulation.component.sass'],
})
export class NewSchedulationComponent implements OnInit, OnDestroy {
  @Input()
  savedReport!: SavedReport
  @Input()
  schedulationInfo!: SchedulationInfo
  @Input()
  request!: any
  @Input()
  route!: string
  @Input()
  mode: 'create' | 'view' | 'duplicate' | 'edit' = 'create'
  @Input()
  initialName!: string
  @Input()
  fromReport?: boolean
  @Output()
  schedulationSaved = new EventEmitter()

  activeIndex = 0

  titles: Record<string, string> = {
    create: this.translate('reports_ScheduleReport'),
    view: this.translate('reports_ViewScheduleReport'),
    duplicate: this.translate('reports_DuplicateScheduledReport'),
    edit: this.translate('reports_EditScheduledReport'),
  }

  steps = [
    { title: this.translate('reports_ScheduleSettings'), status: 'Active' },
    { title: this.translate('reports_SubscriptionSettings'), status: 'Active' },
    { title: this.translate('common_ReviewAndComplete'), status: 'Active' },
  ]

  editSteps = [
    { title: this.translate('reports_ScheduleSettings'), status: 'Active' },
    { title: this.translate('reports_SubscriptionSettings'), status: 'Active' },
  ]

  userSuggestions!: Suggestion[]

  errorTabPosition: Record<string, number> = {
    interval: 1,
    reportName: 1,
    users: 3,
    format: 3,
  }

  validateInterval: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const frequency = control.value.frequency
    const interval = control.value.interval
    const recurrence = control.value.recurrence

    if (recurrence === 'recurring' && frequency !== '3' && !interval) {
      return { error: true }
    }

    return null
  };

  formControls = {
    overwriteExistingReport: new FormControl(false),
    reportName: new FormControl('', Validators.required),
    description: new FormControl(''),
    sendWhen: new FormControl('Always'),
    users: new FormControl([], Validators.required),
    text: new FormControl(),
    format: new FormControl('PDF', Validators.required),
    public: new FormControl(true),
    recurrenceData: new FormControl({
      recurrence: 'oneTime',
      starting: 'now',
      particularScheduleWeeks: false,
      frequency: '1',
      interval: '',
      weekdaySelected: '',
      startDateTime: dayjs().add(dayjs().utcOffset(), 'minute'),
      endDateTime: dayjs().add(1, 'day').utc().set('hour', 0).set('minute', 0),
      ordinal: '',
      dayMonth: { value: null, disabled: true },
      weekdayMonthSelected: '',
      monthControl: '',
    }, { validators: this.validateInterval}),
  } as { [k in keyof SchedulationForm]: FormControl }

  form: UntypedFormGroup = new UntypedFormGroup(this.formControls)
  canOverwrite = false
  isSaving = false
  lastDayMonth!: string

  private destroyed$ = new Subject<boolean>()

  constructor(
    private reportsService: ReportsService,
    private localstorageService: LocalstorageService,
    private translateHelper: TranslateHelper,
    private rightPanelRef: RightPanelRef,
    private toastService: ToastService,
    private appInsights: ApplicationInsightService,
    public schedulationService: SchedulationService
  ) {}

  invalidFn = () => ({ invalid: true })

  ngOnInit(): void {
    dayjs.extend(customParseFormat)
    dayjs.extend(localeData)
    if (this.savedReport) {
      this.setSavedReportDetails()
      if (this.canOverwriteReport()) {
        this.canOverwrite = true
        this.formControls.overwriteExistingReport?.setValue(true)
        this.formControls.overwriteExistingReport?.disable()
        this.formControls.reportName.disable()
      } else {
        this.formControls.overwriteExistingReport?.disable()
      }
    } else if (this.initialName) {
      this.formControls.reportName.setValue(this.initialName)
    }

    if (this.mode !== 'create' || !this.request) {
      this.formControls.description.disable()
      this.formControls.reportName.disable()
    }

    this.formControls.overwriteExistingReport?.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((overwrite) => {
      if (overwrite) {
        this.setSavedReportDetails()
        this.formControls.description.disable()
        this.formControls.reportName.disable()
      } else {
        this.formControls.reportName.enable()
        this.formControls.description.enable()
      }
    })

    if (this.mode === 'create') {
      this.formControls.users.setValue([
        { value: this.localstorageService.getLoggedUser().userName, displayValue: this.localstorageService.getLoggedUser().userName },
      ])
    }

    this.formControls.users.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((values: Pick<Suggestion, 'value'>[]) => {
      if (values?.length && !values.map((x) => x.value).includes(this.localstorageService.getLoggedUser().userName)) {
        this.formControls.public.setValue(true)
        this.formControls.public.disable()
      }
    })

    if (this.schedulationInfo) {
      this.form.patchValue(this.schedulationInfo)
      this.formControls.users?.setValue(this.schedulationInfo.recipients?.map((x) => ({ value: x, displayValue: x })))
      const dataRecurrence: Record<string, any> = {}
      if (this.schedulationInfo?.jobInfo?.recurrence) {
        dataRecurrence.recurrence = 'recurring'
        const recurrence = this.schedulationInfo.jobInfo.recurrence
        const frequencyString = recurrence.frequency?.toString() || ''
        dataRecurrence.frequency = JobRecurrenceFrequency[frequencyString as keyof typeof JobRecurrenceFrequency].toString()
        dataRecurrence.interval = recurrence.interval
        if (recurrence.endTime) {
          dataRecurrence.endDateTime = dayjs(recurrence.endTime)
        }
        if (recurrence.schedule?.days && recurrence.schedule?.days.length > 0) {
          dataRecurrence.particularScheduleWeeks = true
          const dayString = recurrence.schedule?.days[0].toString()
          dataRecurrence.weekdaySelected = JobScheduleDay[dayString as keyof typeof JobScheduleDay].toString()
        }
        else if(recurrence.schedule?.monthlyOccurrences && recurrence.schedule?.monthlyOccurrences.length > 0) {
          const monthDayString = recurrence.schedule?.monthlyOccurrences[0].day?.toString()
          dataRecurrence.weekdayMonthSelected = JobScheduleDay[monthDayString as keyof typeof JobScheduleDay].toString()
          dataRecurrence.ordinal = this.getOccurrenceString(recurrence.schedule?.monthlyOccurrences[0].occurrence)
          dataRecurrence.monthControl = 'daysOfWeek'
        }
        else if(recurrence.schedule?.monthDays && recurrence.schedule.monthDays.length > 0) {
          dataRecurrence.dayMonth = recurrence.schedule.monthDays[0]
          dataRecurrence.monthControl = 'dayMonth'
        }
      } else {
        dataRecurrence.recurrence = 'oneTime'
      }

      if (this.schedulationInfo.jobInfo?.startTime) {
        dataRecurrence.starting = 'specificTime'
        dataRecurrence.startDateTime = dayjs(this.schedulationInfo.jobInfo.startTime)
      }
      else {
        dataRecurrence.starting = 'now'
      }
      this.formControls.recurrenceData?.setValue(dataRecurrence)
      this.formControls.public?.setValue(this.schedulationInfo.isShared)
    }

    if (this.mode === 'view') {
      this.form.disable()
      this.steps = this.editSteps
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  clickTab(index: number) {
    if (this.steps[index].status === 'NotAvailable') {
      return
    }
    this.activeIndex = index
  }

  changeTab(amount: number) {
    const index = this.activeIndex + amount
    if (index < 0 || index >= this.steps.length) {
      return
    }
    if (this.steps[index].status === 'NotAvailable') {
      this.changeTab(amount + amount)
      return
    }
    this.activeIndex = index
  }

  getActualTimeValue() {
    const date = dayjs()
    return `${date.hour()}:${date.minute() >= 30 ? '30' : '00'}`
  }

  usersInputChanged(input: string) {
    if (input?.trim()?.length >= 3) {
      this.reportsService
        .getUserSuggestions({
          searchedString: input,
          excludeItems: this.formControls.users?.value?.map((x: Suggestion) => x.value),
        })
        .subscribe((res) => {
          this.userSuggestions = res.map((x) => ({ value: x.userPrincipalName, displayValue: x.userPrincipalName }))
        })
    } else {
      this.userSuggestions = []
    }
  }

  save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {

      const firstError = keys(this.form.controls).filter((x) => this.form.get(x)?.status === 'INVALID')[0]
      this.activeIndex = this.errorTabPosition[firstError] - 1
      return
    }
    this.isSaving = true
    const value = this.form.getRawValue() as SchedulationForm
    const formValue = { ...value, ...value.recurrenceData }

    if(formValue.frequency === '3')
      formValue.interval = '1'

    const schedulation = {
      savedReportId: this.savedReport?.guid,
      schedulationInfo: {
        id: this.mode === 'edit' ? this.schedulationInfo.id : undefined,
        recipients: formValue.users?.map((x: Suggestion) => x.value),
        text: formValue.text,
        format: formValue.format,
        sendWhen: formValue.sendWhen,
        jobInfo: {
          recurrence:
            formValue.recurrence === 'recurring'
              ? {
                  endTime: formValue.endDateTime?.toISOString(),
                  frequency: Number(formValue.frequency || 0),
                  interval: Number(formValue.interval || 0),
                  schedule: this.getRecurrenceSchedule(formValue)                   
                }
              : undefined,
          startTime: formValue.starting === 'specificTime' ? formValue.startDateTime?.toISOString() : undefined,
        },
        isShared: formValue.public,
      },
      reportName: formValue.reportName,
      description: formValue.description,
      category: Constants.savedReportsCategories.PortalV2,
    } as any

    let overwriting = false
    if (this.request && this.route) {
      this.request.savedReportParams = {
        reportName: formValue.reportName,
        description: formValue.description,
        reportUrl: this.route,
        schedulations: [clone(schedulation.schedulationInfo)],
      }
      if (!!this.canOverwrite && !!formValue.overwriteExistingReport) {
        overwriting = true
        this.request.savedReportParams.guid = this.savedReport.guid
      } else {
        delete schedulation.savedReportId
      }
      schedulation.request = this.request
      this.reportsService.saveReport(schedulation).subscribe(
        (x) => {
          this.isSaving = false
          this.schedulationSaved.emit()
          this.rightPanelRef.close(true)
          this.showScheduleSuccess(!!overwriting || this.mode === 'edit', formValue.reportName)
        },
        (err) => {
          this.isSaving = false
          this.showScheduleError(!!overwriting || this.mode === 'edit', formValue.reportName)
          this.appInsights.trackError(err)
        }
      )
    } else {
      this.reportsService.saveSchedulation(schedulation).subscribe(
        (x) => {
          this.isSaving = false
          this.schedulationSaved.emit()
          this.rightPanelRef.close(true)
          this.showScheduleSuccess(!!overwriting || this.mode === 'edit', formValue.reportName)
        },
        (err) => {
          this.isSaving = false
          this.showScheduleError(!!overwriting || this.mode === 'edit', formValue.reportName)
          this.appInsights.trackError(err)
        }
      )
    }
  }

  private occurrenceMappings: { [key: string]: number } = {
    'First': 1,
    'Second': 2,
    'Third': 3,
    'Fourth': 4,
    'Last': 5
  };
  
  private getOccurrenceValue(input: string): number {
    return this.occurrenceMappings[input];
  }
  
  private getOccurrenceString(value: number | undefined): string | null {
    const entry = Object.entries(this.occurrenceMappings).find(([_, val]) => val === value);
    return entry ? entry[0] : null;
  }
  
  getErrorFields() {
    const fields = []
    if (this.recurrenceData?.recurrence === 'recurring' && !this.recurrenceData.interval && this.recurrenceData?.frequency !== '3') {
      fields.push(this.translateHelper.instant('common_Interval'))
    }
    return fields.join(', ')
  }

  getSchedulationSummary() {
    const formValue = this.form.getRawValue() as SchedulationForm
    return `${this.translate('reports_TheReport')} <span class="f-w-600">${formValue.reportName}</span> ${this.translate(
      'reports_WillBeSent'
    )}${formValue.sendWhen !== 'Always' ? ' ' + this.translate('common_When') + ' ' : ' '}${this.getSendWhenString(formValue.sendWhen)} ${
      formValue.recurrenceData.recurrence === 'oneTime' ? ' ' + this.translate('common_OneTime') : ' '
    }${formValue.recurrenceData.recurrence === 'recurring' ? ' ' + this.getRecurringString(formValue) : ''} ${this.translate(
      'common_Starting'
    )} ${formValue.recurrenceData.starting === 'now' ? this.translate('common_Now') : ''}${
      formValue.recurrenceData.starting === 'specificTime' ? this.getSpecificTime(formValue) : ''
    }${formValue.recurrenceData.recurrence === 'recurring' ? this.getRecurringEnd(formValue) : ''}`
  }

  cancel() {
    this.rightPanelRef.close()
  }

  isInvalidStep = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        if (this.activeIndex > 0) {
          this.formControls.reportName.markAsTouched()
          this.formControls.recurrenceData.markAsTouched()
        }
        return (
          ((this.formControls.reportName.invalid && this.formControls.reportName.touched) ||
            (this.formControls.recurrenceData.invalid && this.formControls.recurrenceData.touched)) &&
          this.activeIndex >= 0
        )
      case 1:
        if (this.activeIndex > 1) {
          this.formControls.users.markAllAsTouched()
        }
        return this.formControls.users.invalid && this.formControls.users.touched && this.activeIndex >= 0
      case 2:
        return false
      default:
        return false
    }
  }

  private getRecurringString(formValue: SchedulationForm) {
    const reccurenceDate: Recurrence = formValue.recurrenceData

    const jobInfo: JobRecurrence = { 
      frequency: Number(reccurenceDate.frequency),
      interval: Number(reccurenceDate.interval || 0),
      schedule: this.getRecurrenceSchedule(reccurenceDate)
    }

    if(jobInfo.frequency === JobRecurrenceFrequency.Week) {
      jobInfo.interval = 1
    }
    
    return this.schedulationService.getRecurringDataString(jobInfo)
  }

  private getRecurrenceSchedule(reccurenceDate: Recurrence) : JobRecurrenceSchedule | undefined {
    const frequency: JobRecurrenceFrequency = Number(reccurenceDate.frequency)
    
    if (frequency === JobRecurrenceFrequency.Day || frequency === JobRecurrenceFrequency.Hour) {
      return {}
    }

    if (frequency === JobRecurrenceFrequency.Week) {
      return this.getWeeklyRecurrenceSchedule(reccurenceDate)
    } 
    
    if (frequency === JobRecurrenceFrequency.Month) {
      return this.getMonthlyRecurrenceSchedule(reccurenceDate)
    }

    return undefined
  }

  private getWeeklyRecurrenceSchedule(reccurenceDate: Recurrence) : JobRecurrenceSchedule {
    if (reccurenceDate.particularScheduleWeeks) {
      return {
        days: [Number(reccurenceDate.weekdaySelected)]
      }
    }

    return {}
  }

  private getMonthlyRecurrenceSchedule(reccurenceDate: Recurrence) : JobRecurrenceSchedule  {
    if (reccurenceDate.dayMonth && reccurenceDate.monthControl === 'dayMonth') {
      return {
        monthDays: [reccurenceDate.dayMonth]
      }
    } 
    
    if (reccurenceDate.monthControl === 'daysOfWeek' && reccurenceDate.ordinal !== undefined) {
      return {
        monthlyOccurrences: [{
          day: Number(reccurenceDate.weekdayMonthSelected),
          occurrence: this.getOccurrenceValue(reccurenceDate.ordinal!)
        }]
      }
    } 
    
    if (reccurenceDate.monthControl === 'lastDay') {
      return {
        lastDay: true,
        monthlyOccurrences: []
      }
    }

    return {}
  }

  public get recurrenceData(): Recurrence {
    return this.form.get('recurrenceData')?.value
  }

  private getSendWhenString(sendWhen: string) {
    return this.schedulationService.sendWhenOptions.find((x) => x.value === sendWhen)?.displayValue || sendWhen
  }

  private getSpecificTime(formValue: SchedulationForm) {
    return `${formValue.recurrenceData.startDateTime?.utc().format('L hh:mm A UTC Z')}`
  }

  private getRecurringEnd(formValue: SchedulationForm) {
    return ` ${this.translate('common_And').toLowerCase()} ${this.translate('common_EndingOn')} ${this.recurrenceData.endDateTime
      ?.utc()
      .format('L hh:mm A UTC Z')}`
  }

  private translate(text: string) {
    return this.translateHelper.instant(text)
  }

  private canOverwriteReport(): boolean {
    return (
      !!this.fromReport &&
      this.savedReport.userName === this.localstorageService.getLoggedUser().userName &&
      (!this.schedulationInfo || this.schedulationInfo.userName === this.localstorageService.getLoggedUser().userName)
    )
  }

  private setSavedReportDetails() {
    this.formControls.reportName.setValue(this.savedReport.reportName)
    this.formControls.description.setValue(this.savedReport.description)
  }

  private showScheduleError(isEdit: boolean, reportName: string) {
    this.toastService.open({
      id: 'err',
      variant: 'error',
      title: this.translateHelper.instant('common_Error'),
      message: this.translateHelper.combineTranslations(
        isEdit ? 'generic_ObjectCouldNotBeModified' : 'generic_ObjectCouldNotBeCreated',
        'reports_Schedulation',
        reportName
      ),
    })
  }

  private showScheduleSuccess(isEdit: boolean, reportName: string) {
    this.toastService.open({
      id: 'succ',
      variant: 'success',
      title: this.translateHelper.instant('common_Success'),
      message: this.translateHelper.combineTranslations(
        isEdit ? 'generic_ObjectSuccessfullyModified' : 'generic_ObjectSuccessfullyCreated',
        'reports_Schedulation',
        reportName
      ),
    })
  }
}

export type SchedulationForm = {
  reportName: any;
  description: any;
  sendWhen: any;
  users: any;
  text: any;
  format: any;
  public: any;
  overwriteExistingReport?: boolean;
  recurrenceData: any;
}

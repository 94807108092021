import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.sass'],
})
export class BaseDialogComponent {
  @Input() title!: string
  @Input() text!: string
  @Input() primaryButton!: { key: string; text: string }
  @Input() secondaryButton!: { key: string; text: string }
  @Input() type: 'info' | 'alert' | 'success' = 'info'
  @Input() centered = false
  @Output() clicked = new EventEmitter<{ event: any; key: string }>()

  iconByStatus: { [status: string]: string } = {
    ['error']: 'error',
    ['alert']: 'error',
    ['warning']: 'warning',
    ['info']: 'info',
    ['success']: 'check_circle',
  }

  constructor() {}

  click(event: any, key: string) {
    this.clicked.emit({ event, key })
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GroupType2 } from '@app/core/enums/group-type';
import { MembershipReportFilters } from '@app/core/models/reports-filters';
import { TranslateHelper } from '@coreview/coreview-library';
import { AutocompleteInputComponent } from '@coreview/coreview-components';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-group-memebeship-filter',
  templateUrl: './group-memebeship-filter.component.html',
  styleUrls: ['./group-memebeship-filter.component.sass']
})
export class GroupMemebeshipFilterComponent implements OnInit {
  @Input() readonly = false
  @Input() groupTypes!: { value: GroupType2; displayValue: any; prepareColumns: () => void; valueSuggester: (val: any) => Observable<any> }[]

  form = new UntypedFormGroup({
    membership: new UntypedFormControl('isMember'),
    groupType: new UntypedFormControl('groupType', Validators.required),
    value: new UntypedFormControl('', Validators.required)
  })

  membershipOptions = [{ value: 'isMember', displayValue: this.translateHelper.instant('reports_isMember') },
  { value: 'notMember', displayValue: this.translateHelper.instant('reports_notMember') }]

  constructor(private translateHelper: TranslateHelper) { }

  ngOnInit(): void {
  }

  patchValue(membershipReportFilters?: MembershipReportFilters) {
    if (!!membershipReportFilters) {
      this.form.get('membership')?.patchValue(membershipReportFilters?.isMember ? 'isMember' : 'notMember')
      this.form.get('groupType')?.patchValue(membershipReportFilters?.groupType)
      this.form.get('value')?.patchValue({ value: membershipReportFilters?.groupId, displayValue: membershipReportFilters?.displayName })
    }
  }

  membershipChanged(value: string) {
    //do nothing
  }

  filterValue(value: string, inpvalue: AutocompleteInputComponent) {
    const groupT = this.form.get('groupType')?.value
    this.groupTypes.find(g => groupT === g.value)?.valueSuggester(value)
      .subscribe((val: { id: string, displayName?: string, name?: string }[] | null) => {
        inpvalue.suggestions = !!val ? val.map((x: any) => ({ value: x.id, displayValue: x.displayName || x.name, ...x })) : null
      })
  }

  invalid = () => this.form.invalid

  getValue() {
    return {
      isMember: this.form.value.membership === 'isMember' ? true : false,
      groupType: this.form.value.groupType,
      groupId: this.form.value.value.value,
      displayName: this.form.value.value.displayValue
    }
  }
}

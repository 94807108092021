<app-datagrid
  #matchedItems
  *ngIf="!!reportDefinition"
  [gridDefinition]="reportDefinition"
  [getItems]="getItems"
  [keepSelectionBetweenPages]="true"
  (rowSelected)="rowSelected.emit($event)"
  (columnDefsDefined)="columnDefsDefined.emit($event.columnDefs)"
  (modelUpdated)="modelUpdated.emit($event)"
  [routeParamsFilters]="reportType === 'matchedExceptions' ? exceptionItemsFilters() : matchedItemsFilters()"
  style="flex: 1"
  (metadataChanged)="metadataChanged.emit($event)"
  [rangeFilters]="policy.timeRangeFilter"
  (gridReady)="gridReady.emit($event)"
>
</app-datagrid>

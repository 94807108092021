/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { PolicyWorkflowReport } from '@app/core/models/playbook'

@Component({
  selector: 'app-workflow-dialog',
  templateUrl: './workflow-dialog.component.html',
  styleUrls: ['./workflow-dialog.component.sass'],
})
export class WorkflowDialogComponent {
  policyWorkflowReport: PolicyWorkflowReport
  startingStatus!: string
  serviceTag!: string

  constructor(@Inject(MAT_DIALOG_DATA) public data: { policyWorkflowReport: PolicyWorkflowReport; wftype?: string }) {
    this.policyWorkflowReport = data.policyWorkflowReport
    if (data?.wftype) {
      this.startingStatus = data.wftype
    }

    if(this.policyWorkflowReport.policyId){
      this.serviceTag = 'policyid:' + this.policyWorkflowReport.policyId
    }
  }
}

import { Injectable } from '@angular/core'
import { Helpers } from '@app/shared/utilities/helpers'
import { Base64 } from 'js-base64'
import { AuthenticatedUser, Role } from '../models/authenticated-user'
import { LocalstorageService } from './localstorage.service'

@Injectable({ providedIn: 'root' })
export class AuthHelperService {
  constructor(private storage: LocalstorageService) {  }

  authorize = (user: AuthenticatedUser, accessLevel: any) => {
    if (!user.roles) { return false }
    const result = 0

    user.roles.some(r => accessLevel.includes(Helpers.downcase(r)))
    return result
  }

  hasRole(role: string): boolean {
    const user = this.storage.getLoggedUser()
    return user.roles?.filter((r: Role) => r.toLowerCase() === role.toLowerCase()).length > 0
  }

  getUrlParameterByName(name: string) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)', 'g');
    let results;
    let lastResult;
    while ((results = regex.exec(location.search)) !== null) {
        lastResult = results;
    }
    return lastResult === undefined ? '' : decodeURIComponent(lastResult[1].replace(/\+/g, ' '));
  }

  base64Decode = (tkn: string) => {
    const bytes = Base64.toUint8Array(tkn)
    return new (TextDecoder)('utf-8').decode(bytes)
  }

  padToNextMod4 = (tkn: string) => {
    if (tkn.length % 4 > 0) {
      return tkn.padEnd(tkn.length + (4 - tkn.length % 4), '=')
    }

    return tkn
  }

  gotoPage(url: string) {
    window.location.href = url
  }
}

<cv-form-builder *ngIf="jsonReady" (formReady)="formReady.next(true)" [jsonFormData]="json" [form]="form"></cv-form-builder>
<ng-container *ngIf="!jsonReady">
  <div
    class="loading-skeleton"
    style="display: grid; gap: 10px"
    [style.gridTemplateColumns]="'repeat(' + (options?.gridColumnsNumber || 1) + ', 1fr)'"
  >
    <div *ngFor="let _ of [].constructor(4)" style="height: 48px; width: 100%; border-radius: 4px; background-color: #dfdfdf"></div>
  </div>
</ng-container>

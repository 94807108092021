<div class="cv-dialog-div-container">
  <div class="title-div">
    <span class="title" style="flex: 1">{{ title | translate }}</span>
    <cv-close-button class="close-icon" [mat-dialog-close]></cv-close-button>
  </div>
  <div class="mat-mdc-dialog-content mdc-dialog__content">
    <span *ngIf="!!subTitle">{{ subTitle | translate }}</span>
  </div>
  <div class="dialog-container">
    <app-datagrid
      *ngIf="loaded"
      style="flex: 1"
      [getItems]="getItems"
      [allCols]="onlineUsersColumns() | async"
      [gridDefinition]="gridDefinition"
      [keepSelectionBetweenPages]="true"
      [rangeFilters]="timeRangeFilter"
    >
    </app-datagrid>
  </div>
</div>

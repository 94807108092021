/* eslint-disable no-shadow */

import { EnhancedJsonFormData } from '@app/shared/models/enhanced-json-form-data';
import { Variants } from '@coreview/coreview-components'
import { Verb } from './PageDataCommonClasses'

/* eslint-disable @typescript-eslint/naming-convention */
export interface ManagementAction {
  id: string;
  icon: string;
  title: string;
  translate: string; //TODO where this come from?
  description?: string;
  clientFunction: string;
  actionItemId: number;
  sortId: number;
  scope?: string;
  cloudIcon?: string;
  accessLevel: string;
  /**
   * @deprecated Not used in V2
   */
  isNew: boolean;
  maxNumberOfItem?: number;
  tags: string[];
  /**
   * @deprecated Not used in V2
   */
  webSections: string[];
  powershellChannels: string[];
  rules: string[];

  actionItems?: (ManagementAction | CustomManagementAction)[];
  target?: ManagementActionTarget;
  visibility?: OrganizationType;
  counterActionItemId?: number;
  clientFunctionArgs?: string;
  workflowItems?: ManagementActionWorkflowItem[];
  locked?: boolean;
  category?: string;
  translatedCategory?: string;
  iconCategory?: string;
  status?: string;
  favorite?: boolean;
  reportTags?: string[];
  formSchema: string;

  //#region Target Selection properties
  targetSelectionType: ManagementActionTargetSelectionType;
  targetSelectionStepKey?: string;
  onlineUserTarget?: boolean;
  requiredFields?: string[];
  fields?: string[];
  filters?: Record<string, string>;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  targetPropertyId?: string;
  targetPropertyDisplayValue?: string[];
  responseObjectName?: string;
  apiUrl?: string;

  //#endregion

  //#region Init Data properties

  initDataApiUrl?: string;
  initDataGetApiParams?: string;
  initDataResponseObjectName?: string;
  initDataMap?: string;
  initDataCalls?: InitApiCallDefinition[];

  //#endregion

  //#region Submit properties

  submitApiUrl: string;
  submitVerb: Verb;
  submitDataMap?: string;

  //#endregion

  summaryDisclaimerTitle?: string;
  summaryDisclaimerContent?: string;
  summaryDisclaimerType?: Variants;
  summaryStepMap?: Record<string, string>;

  //#region CallToAction buttons

  targetActionBar?: (DownloadFileButtonDef | UploadCsvButtonDef)[];

  //#endregion
}

export interface TargetActionBar {
  title: string;
  actionType: 'download' | 'upload';
  buttonType: 'tertiary';
  icon?: string;
}
export interface DownloadFileButtonDef extends TargetActionBar {
  actionType: 'download';
  url: string;
  formSchema: undefined;
}
export interface UploadCsvButtonDef extends TargetActionBar {
  actionType: 'upload';
  url: string;
  formSchema: EnhancedJsonFormData;
}

export function isUpload(button: DownloadFileButtonDef | UploadCsvButtonDef): button is UploadCsvButtonDef { return button.actionType === 'upload'}
export function isDownload(button: DownloadFileButtonDef | UploadCsvButtonDef): button is DownloadFileButtonDef { return button.actionType === 'download'}

export interface CustomManagementAction extends ManagementAction {
  customActionId: string;
  companyIds: string[];
}

export enum ManagementActionTarget {
  DistributionGroup = 0,
  Mailbox = 1,
  MailContact = 2,
  O365Group = 3,
  SecurityGroup = 4,
  SkypeForBusiness = 5,
  User = 6,
  None = 7,
  Mobile = 8,
  OneDrive = 9,
  Teams = 10,
  SharePoint = 11,
  OnPremises = 12,
  AdminReadOnly = 13,
  DistributionGroupMembers = 14,
  SecurityGroupMembers = 15,
  TeamsGroupMembers = 16,
  Office365GroupMembers = 17,
  TeamsGroupChannel = 18,
  TeamsCallQueue = 19,
  TeamsAutoAttendant = 20,
  Office365DeletedGroup = 21,
  OneDriveExternalUser = 22,
}

export enum OrganizationType {
  Company,
  Tier0,
  Tier1,
  Tier2,
  Distributor, //this is gonna be deleted
  Partner, //this is gonna be deleted
  Customer,
}

export type ManagementActionTargetSelectionType = 'None' | 'Single' | 'Multiple'

export interface ManagementActionWorkflowItem {
  companyid: string;
  workflowId: string;
  workflowName: string;
  description: string;
  owner: string;
  updatedBy: string;
}

export interface InitApiCallDefinition extends ApiCallDefinition {
  initCallType: 'Always' | 'Single' | 'Multiple';
}

export interface ApiCallDefinition {
  apiUrl: string;
  mapApiParams: string;
  responseObjectName?: string;
  verb: Verb;
}

export type ApiErrorCode = 'Success' | 'Failure' | 'ManagementActionNotAllowed' | 'VirtualTenantError' | 'ServiceError' | 'Warning'

export interface ApiErrorResponse {
  errorCode: ApiErrorCode;
  fieldName?: string;
  message: string;
}

import { of, combineLatest } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { OrganizationService } from '@core/services/organization.service'
import { ToastService } from '@coreview/coreview-components'
import {
  getOrganizationById,
  getOrganizationByIdInvalidate,
  getOrganizationByIdResponse,
  updateOrganizationGeneralSettings,
  updateOrganizationGeneralSettingsInvalidate,
  updateOrganizationGeneralSettingsResponse,
  updateOrganizationMailSettings,
  updateOrganizationMailSettingsInvalidate,
  updateOrganizationMailSettingsResponse,
  updateOrganizationManagementActions,
  updateOrganizationManagementActionsInvalidate,
  updateOrganizationManagementActionsResponse,
  updateOrganizationNewUxSettings,
  updateOrganizationNewUxSettingsResponse,
  updateOrganizationVirtualTenants,
  updateOrganizationVirtualTenantsInvalidate,
  updateOrganizationVirtualTenantsResponse,
  updateSecureByDefault,
  updateSecureByDefaultInvalidate,
  updateSecureByDefaultResponse,
  updateSecuritySettings,
} from './organizations.actions'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { TranslateHelper } from '@coreview/coreview-library'

/**
 * The following injectable class defined and contains all the "organizations" state
 * related side effects.
 */
@Injectable()
export class OrganizationsEffects {
  /**
   * When the getOrganizationById action is dispatched, it takes care of performing
   * the related actions to fetch and bring the data to the store
   */
  getOrganizationById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrganizationById),
      mergeMap(({ id, selected }) =>
        this.organizationService.getOrganization(id).pipe(
          map((data) => getOrganizationByIdResponse({ data, selected })),
          catchError(this.catchCannotManageTenantErrorAndNotify(getOrganizationByIdInvalidate))
        )
      )
    )
  )

  refreshPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getOrganizationByIdResponse),
        tap(({ data, selected }) => {
          if (selected) {
            this.storage.selectedOrganization = { id: data.guid, text: data.displayName, ...data }
          }
        })
      ),
    { dispatch: false }
  )

  updateOrganizationNewUxSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganizationNewUxSettings),
      mergeMap(({ data }) =>
        this.organizationService.updateNewUxSettings(data).pipe(
          map(() => updateOrganizationNewUxSettingsResponse({ data })),
          tap(() =>
            this.toastService.open({
              id: 'succ',
              variant: 'success',
              title: this.translateHelper.instant('administration_NewUxSettingsUpdated'),
            })
          ),
          catchError(this.catchErrorAndNotify(updateOrganizationGeneralSettingsInvalidate))
        )
      )
    )
  )

  /**
   * When the updateOrganizationGeneralSettings action is dispatched, takes care of performing
   * the related actions to update the generic organization data and bring them back to the store,
   * then notify the result of the action
   */
  updateOrganizationGeneralSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganizationGeneralSettings),
      mergeMap(({ data }) =>
        this.organizationService.updateGeneralSettings(data).pipe(
          map(() => updateOrganizationGeneralSettingsResponse({ data })),
          tap(() =>
            this.toastService.open({
              id: 'succ',
              variant: 'success',
              title: this.translateHelper.instant('administration_GeneralSettingsUpdated'),
            })
          ),
          catchError(this.catchErrorAndNotify(updateOrganizationGeneralSettingsInvalidate))
        )
      )
    )
  )

  /**
   * When the updateOrganizationVirtualTenants action is dispatched, takes care of performing
   * the related actions to update the virtual tenants information and bring them back to the store,
   * then notify the result of the action
   */
  updateOrganizationVirtualTenants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganizationVirtualTenants),
      mergeMap(({ data }) =>
        this.organizationService.updateVirtualTenants(data).pipe(
          map(() => updateOrganizationVirtualTenantsResponse({ data })),
          tap(() =>
            this.toastService.open({
              id: 'succ',
              variant: 'success',
              title: this.translateHelper.instant('administration_VirtualTenantsUpdated'),
            })
          ),
          catchError(this.catchErrorAndNotify(updateOrganizationVirtualTenantsInvalidate))
        )
      )
    )
  )

  /**
   * When the updateOrganizationVirtualTenants action is dispatched, takes care of performing
   * the related actions to update the email settings information and bring them back to the store,
   * then notify the result of the action
   */
  updateOrganizationEmailSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganizationMailSettings),
      mergeMap(({ data }) =>
        this.organizationService.updateMailSettings(data).pipe(
          map(() => updateOrganizationMailSettingsResponse({ data })),
          tap(() =>
            this.toastService.open({
              id: 'succ',
              variant: 'success',
              title: this.translateHelper.instant('administration_EmailSettingsUpaded'),
            })
          ),
          catchError(this.catchErrorAndNotify(updateOrganizationMailSettingsInvalidate))
        )
      )
    )
  )

  /**
   * When the updateOrganizationManagementActions action is dispatched, takes care of performing
   * the related actions to update the managed action settings and bring them back to the store,
   * then notify the result of the action
   */
  updateOrganizationManagementActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganizationManagementActions),
      mergeMap(({ data }) =>
        this.organizationService.updateManagementActions(data).pipe(
          map(() => updateOrganizationManagementActionsResponse({ data })),
          tap(() =>
            this.toastService.open({
              id: 'succ',
              variant: 'success',
              title: this.translateHelper.instant('administration_ManagementActionsUpdated'),
            })
          ),
          catchError(this.catchErrorAndNotify(updateOrganizationManagementActionsInvalidate))
        )
      )
    )
  )

  updateSecureByDefaultActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSecureByDefault),
      mergeMap(({ data }) =>
        this.organizationService.updateSecureByDefault(data).pipe(
          map(() => updateSecureByDefaultResponse({ data })),
          tap(() =>
            this.toastService.open({
              id: 'succ',
              variant: 'success',
              title: this.translateHelper.instant('administration_VirtualTenantsUpdated'),
            })
          ),
          catchError(this.catchErrorAndNotify(updateSecureByDefaultInvalidate))
        )
      )
    )
  )

  updateSecuritySettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSecuritySettings),
      mergeMap(({ data, serviceAccountPswRotation }) =>
        combineLatest([
          this.organizationService.updateGeneralSettings(data),
          this.organizationService.updateSecureByDefault(data),
          this.organizationService.updateServiceAccountPswRotation(serviceAccountPswRotation)
        ]).pipe(
          map(() => updateOrganizationGeneralSettingsResponse({ data })),
          map(() => updateSecureByDefaultResponse({ data })),
          tap(() =>
            this.toastService.open({
              id: 'succ',
              variant: 'success',
              title: this.translateHelper.instant('administration_SecuritySettingsUpdated'),
            })
          ),
          catchError(this.catchErrorAndNotify(updateOrganizationGeneralSettingsInvalidate))
        )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private organizationService: OrganizationService,
    private toastService: ToastService,
    private storage: LocalstorageService,
    private translateHelper: TranslateHelper
  ) {}

  catchCannotManageTenantErrorAndNotify(action: any) {
    return (error: { message: string }) => {
      this.toastService.open({
        id: 'err',
        variant: 'error',
        title: this.translateHelper.instant('common_Error'),
        message: this.translateHelper.instant('common_CannotManageOrganizationError'),
      })

      return of(action({ error: error.message }))
    }
  }

  /**
   * The following thunk takes an action and returns a new function that receive an error, notify it via the
   * toastService before dispatching it to the action
   */
  private catchErrorAndNotify(action: any) {
    return (error: { message: string }) => {
      this.toastService.open({
        id: 'err',
        variant: 'error',
        title: this.translateHelper.instant('generic_SomethingWentWrong'),
        message: error.message,
      })

      return of(action({ error: error.message }))
    }
  }
}

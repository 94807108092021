import { ColDef, ColGroupDef, GridOptions } from '@ag-grid-community/core'
import { Component, OnInit, ViewChild } from '@angular/core'
import { FilterAggregation, FilterItem, UserAnalyzerConfig } from '@app/core/models/usersAnalyzer'
import { ReportsService } from '@app/core/services/reports.service'
import { TranslateService } from '@ngx-translate/core'
import { Suggestion } from '@coreview/coreview-components'
import { ReportsComponent } from '../../reports.component'
import { cloneDeep, unionBy } from 'lodash-es'

@Component({
  selector: 'app-users-analyzer',
  templateUrl: './users-analyzer.component.html',
  styleUrls: ['./users-analyzer.component.sass'],
})
export class UsersAnalyzerComponent implements OnInit {
  @ViewChild(ReportsComponent, { static: false }) reportsComponent!: ReportsComponent

  public configurationLoaded: boolean

  configuration!: {
    gridOptions: GridOptions
    columnDefs?: (ColDef | ColGroupDef)[]
    getParameters: () => any
    isRowMaster: () => boolean
    leftPanel?: string
    rightPanel?: string
  }

  public suggestions: Suggestion[] = []
  public searchAggregation: string
  public extraAggregations: { name: string; keyword: string; size: number }[] = []
  public filterAggregations: FilterAggregation[] = []

  public selectedFilters: Map<string, FilterItem> = new Map()
  public fulltext: string

  public isSearchDisabled: boolean

  public config?: UserAnalyzerConfig

  private originalAggregations: FilterAggregation[] = []
  private originalExtraAggregations: FilterAggregation[] = []

  constructor(private reportsService: ReportsService, private translateService: TranslateService) {
    this.configurationLoaded = false
    this.configuration = this.generateConf()
    this.searchAggregation = ''
    this.fulltext = ''
    this.isSearchDisabled = false
  }

  ngOnInit(): void {
    this.reportsService.getAnalyzeSearchData().subscribe((res) => {
      this.config = res.items[0]
      this.suggestions = this.generateSuggestions()
      this.configurationLoaded = true
      setTimeout(() => {
        if (this.reportsComponent !== undefined) {
          this.reportsComponent.onPrepareApiParams(this.prepareParams)
        }
      }, 0)
    })
  }

  public reset(): void {
    this.fulltext = ''
    this.selectedFilters.clear()
    this.generateAggregationsLabel([...this.originalAggregations, ...this.originalExtraAggregations])
  }

  public search(): void {
    this.updateGrid()
  }

  public prepareParams = (params: Record<string, any>) => {
    Object.assign(params, this.extraParams())
  }

  public handleAddAggregation(key: string | null) {
    this.searchAggregation = ''
    const extraAggreation = (this.config?.properties || []).find((property) => property.keyword === key)
    if (extraAggreation) {
      this.extraAggregations.push({ ...extraAggreation, size: 5 })
      this.updateGrid()
    }
  }

  public handleResChanged(event: { aggregations?: FilterAggregation[] }) {
    const aggregations = event.aggregations || []
    this.saveOriginalAggregations(aggregations)
    this.generateAggregationsLabel(aggregations)
    this.isSearchDisabled = false
  }

  public handleLoadItems(aggregation: FilterAggregation) {
    const isExtraAggregations = this.extraAggregations.map((filter) => filter.name).includes(aggregation.key)
    if (isExtraAggregations) {
      this.extraAggregations.forEach((agg) => {
        if (agg.name === aggregation.key) {
          agg.size = aggregation.otherDocuments || 0
        }
        return agg
      })
    } else if (this.config) {
      this.config?.aggregations.forEach((agg) => {
        if (agg.name === aggregation.key) {
          agg.size = aggregation.otherDocuments || 0
        }
        return agg
      })
    }

    this.reportsService
      .getUserAnalyserAggregations({
        queryItem: {
          aggregations: [...this.extraAggregations, ...(this.config?.aggregations || [])],
          filters: [],
          sourceType: 'Forward365.Services.BusinessEntities.OnlineUser',
        },
      })
      .subscribe((items) => {
        this.generateAggregationsLabel(items.aggregations)
      })
  }

  public handleCheck(item: FilterItem) {
    const key = `${item.parentkey}-${item.key}`
    if (this.selectedFilters.has(key)) {
      this.selectedFilters.delete(key)
    } else {
      this.selectedFilters.set(key, item)
    }
  }

  public handleRadioCheck(item: FilterItem) {
    const key = `${item.parentkey}-${item.key}`
    if (this.selectedFilters.has(key)) {
      this.selectedFilters.delete(key)
    } else {
      for (let selectedFilter of this.selectedFilters.keys()) {
        if (selectedFilter.startsWith(item.parentkey)) {
          this.selectedFilters.delete(selectedFilter)
        }
      }
      this.selectedFilters.set(key, item)
    }
  }

  private updateGrid(): void {
    this.isSearchDisabled = true
    this.reportsComponent?.grid?.refresh()
  }

  private extraParams = () => ({
    additionalFilters: this.generateAdditionalFilters(),
    managedUserField: this.config?.managedUserField,
    destinationType: this.config?.destinationType,
    queryItem: {
      filters: this.generateFilters(),
      fulltext: this.fulltext,
      sourceType: this.config?.sourceType,
      aggregations: [...(this.config?.aggregations || []), ...this.extraAggregations],
    },
  })

  private generateAdditionalFilters(): { key: string; value: string }[] {
    const additionalFilters = [] as { key: string; value: string }[]
    this.selectedFilters.forEach((filter) => {
      if (filter.filters.length === 0) {
        if (this.config?.aggregations?.length || this.extraAggregations.length) {
          const aggregation: any = [...(this.config?.aggregations ?? []), ...this.extraAggregations]?.find(
            (a) => a.name === filter.parentkey
          )
          additionalFilters.push({ key: aggregation?.keyword || filter.parentkey, value: filter.keyAsString ?? filter.key })
        }
      }
    })
    return additionalFilters
  }

  private generateFilters(): Record<string, any>[] {
    const filters = [] as Record<string, any>[]
    this.selectedFilters.forEach((filter) => {
      if (filter.filters.length > 0) {
        filters.push(...filter.filters)
      }
    })
    return filters
  }

  private generateConf() {
    return {
      gridOptions: {
        defaultColDef: {
          sortable: true,
          resizable: false,
          filter: true,
          floatingFilter: true,
          filterParams: {
            suppressAndOrCondition: true,
          },
        },
      },
      isRowMaster: () => false,
      columnDefs: [],
      getParameters: () => {},
      leftPanel: 'CustomSmartPanel',
    }
  }

  private generateSuggestions(): Suggestion[] {
    return (this.config?.properties || []).map((p) => ({ value: p.keyword, displayValue: p.name }))
  }

  private capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  private generateAggregationsLabel(aggregations: FilterAggregation[]) {
    const selectedFilterKeys = Array.from(this.selectedFilters.values()).map((filter) => filter.parentkey)
    this.filterAggregations = aggregations
      .map((aggregation) => ({
        ...aggregation,
        componentType: this.config?.aggregations.find((a) => a.name === aggregation.key)?.componentType || 'Checkbox',
        items: aggregation.items.map((item) => ({
          ...item,
          hidden: selectedFilterKeys.includes(item.parentkey) && !this.selectedFilters.has(`${item.parentkey}-${item.key}`),
          label: item.displayTextType
            ? `${this.capitalize(this.translateService.instant('common_Last'))} ${item.key} ${this.translateService.instant('common_Days')}`
            : item.keyAsString ?? item.key,
        })),
      }))
      .sort((a, b) => {
        const aIndex = this.extraAggregations.findIndex((agg) => agg.name === a.key)
        const bIndex = this.extraAggregations.findIndex((agg) => agg.name === b.key)

        if (aIndex !== -1 && bIndex !== -1) {
          // Both are extra aggregations, sort by reverse index (most recent first)
          return bIndex - aIndex
        } else if (aIndex !== -1) {
          // Only a is an extra aggregation, it should come first
          return -1
        } else if (bIndex !== -1) {
          // Only b is an extra aggregation, it should come first
          return 1
        }
        // Neither are extra aggregations, keep original order
        return 0
      })
  }

  private saveOriginalAggregations(aggregations: FilterAggregation[]) {
    if (!this.originalAggregations.length) {
      this.originalAggregations = cloneDeep(aggregations)
    }

    if (this.extraAggregations.length) {
      const selectedFilterKeys = Array.from(this.selectedFilters.values()).map((filter) => filter.parentkey)
      const extraAggregations = aggregations.filter(
        (agg) => this.extraAggregations.find((extra) => extra.name === agg.key) && !selectedFilterKeys.includes(agg.key)
      )
      this.originalExtraAggregations = unionBy(this.originalExtraAggregations, extraAggregations, 'key')
    }
  }
}

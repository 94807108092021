import { Component, Input, OnChanges, OnInit, Optional, Self, SimpleChanges } from '@angular/core'
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  UntypedFormArray,
  ValidationErrors,
} from '@angular/forms'
import { BaseControlComponent } from '@coreview/coreview-components'
import { TimeRange } from '../auto-attendant-business-hours-row/auto-attendant-business-hours-row.component'
import { timeToMinutes } from '@app/modules/management/utils/time.utils'
import { TranslateHelper } from '@coreview/coreview-library'

@Component({
  selector: 'app-auto-attendant-business-hours-group',
  templateUrl: './auto-attendant-business-hours-group.component.html',
  styleUrls: ['./auto-attendant-business-hours-group.component.sass'],
})
export class AutoAttendantBusinessHoursGroupComponent extends BaseControlComponent implements OnInit, OnChanges {
  @Input() dayOfWeek: string = ''

  @Input() fieldsDisabled: boolean = false

  @Input()
  get value(): TimeRange[] {
    const { open, ranges } = this.form.value
    return open ? ranges : null
  }

  set value(ranges: any) {
    if (Array.isArray(ranges)) {
      while (this.ranges.length < ranges.length) {
        this.ranges.push(this.getNewControl())
      }
      while (this.ranges.length > ranges.length) {
        this.ranges.removeAt(this.ranges.length - 1)
      }
      this.form.get('ranges')?.setValue(ranges)
      if (ranges.length === 1 && ranges[0].startTime === null && ranges[0].endTime === null) {
        this.form.get('open')?.setValue(false)
      }
    }
  }

  public form: FormGroup

  public readonly closedLabel = this.translateHelper.instant('common_Closed')

  constructor(@Optional() @Self() public ngControl: NgControl, private fb: FormBuilder, private translateHelper: TranslateHelper) {
    super(ngControl)
    this.form = this.fb.group(
      {
        open: [true],
        ranges: this.fb.array([this.getNewControl()]),
      },
      { validator: this.validateRanges } as AbstractControlOptions
    )
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe((_) => {
      if (this.isClosedValue(this.value)) {
        this.form.get('open')?.setValue(false, { emitEvent: false })
        this.onChange(this.value)
      } else {
        this.onChange(this.value)
      }
    })

    this.form.get('open')?.valueChanges.subscribe((open) => {
      if (this.form.enabled) {
        this.value = [{startTime: '0:00', endTime: '0:00'}]
      }
    })

    this.form.statusChanges.subscribe((status) => {
      if (this.ngControl?.control) {
        const error = status === 'INVALID' ? { invalidRange: true } : null
        this.ngControl.control?.setErrors(error)
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.fieldsDisabled && !changes.fieldsDisabled.firstChange) {
      if(changes.fieldsDisabled.currentValue) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  get ranges(): UntypedFormArray {
    return this.form.get('ranges') as UntypedFormArray
  }

  get open(): boolean {
    return !!this.form.get('open')?.value
  }

  public handleAddTime() {
    this.ranges.push(this.getNewControl())
  }

  public handleDeleteTime(i: number) {
    this.ranges.removeAt(i)
  }

  public getOpenHours(): string | null {
    if (this.form.invalid) {
      return null
    }
    const openMinutes = this.ranges.value.filter((range: TimeRange) => !!range.startTime && !!range.endTime).reduce((acc: number, range: TimeRange) => {
      return acc + this.calculateOpeningHoursTotal(range.startTime || '', range.endTime || '')
    }, 0)
    return this.formatOpenTime(openMinutes)
  }

  public getClosingHours(): string | null {
    if (this.form.invalid) {
      return null
    }
    const sortedRanges = this.ranges.value
      .filter((range: TimeRange) => !!range.startTime && !!range.endTime)
      .sort((a: TimeRange, b: TimeRange) => this.timeToMinutes(a.startTime) - this.timeToMinutes(b.startTime))

    const closedHours = sortedRanges.reduce((acc: string, currentRange: TimeRange, i: number, arr: TimeRange[]) => {
      const nextRange = arr[(i + 1) % arr.length]
      const closingInterval = currentRange.endTime !== nextRange.startTime ? `${this.formatTime(currentRange.endTime)} - ${this.formatTime(nextRange.startTime)}<br/>` : ''
      return acc + closingInterval
    }, '')
    return closedHours ? `<div>${this.closedLabel}</div><div>${closedHours}</div>` : null
  }

  private calculateOpeningHoursTotal(startTime: string, endTime: string): number {
    let startMinutes = this.timeToMinutes(startTime)
    let endMinutes = this.timeToMinutes(endTime)

    if (endMinutes <= startMinutes) {
      endMinutes += 24 * 60
    }

    return endMinutes - startMinutes
  }

  private formatOpenTime(diffMinutes: number): string {
    const hours = Math.floor(diffMinutes / 60)
    const minutes = diffMinutes % 60

    return `${this.printTime(hours, 'h')} ${this.printTime(minutes, 'm')}`
  }

  private timeToMinutes(time: string | null): number {
    if (!time) return 0
    const [hours, minutes] = time.split(':').map(Number)
    return hours * 60 + minutes
  }

  private printTime(time: number, unit: string): string {
    return time ? `${time}${unit}` : ''
  }

  private formatTime(time: string | null): string {
    if (!time) return ''
    let [hours, minutes] = time.split(':').map(Number)
    const ampm = hours >= 12 ? 'PM' : 'AM'
    hours = (hours % 12) || 12
    return `${hours}:${minutes === 0 ? '00' : minutes} ${ampm}`
  }

  private getNewControl(): FormControl {
    return this.fb.control({ startTime: null, endTime: null })
  }

  private validateRanges(control: AbstractControl): ValidationErrors | null {
    const { ranges } = control.value
    let hasOverlap = false
    const sortedRanges = (ranges || []).filter((range: TimeRange) => !!range.startTime && !!range.endTime).sort((a: TimeRange, b: TimeRange) => timeToMinutes(a.startTime) - timeToMinutes(b.startTime))
    for (let i = 0; i < sortedRanges.length - 1; i++) {
      const nextRange = sortedRanges[(i + 1) % sortedRanges.length]
      const start = timeToMinutes(nextRange.startTime)
      const end = timeToMinutes(sortedRanges[i].endTime) > 0 ? timeToMinutes(sortedRanges[i].endTime) : 24 * 60
      if (end > start) {
        hasOverlap = true
      }
    }
    if (hasOverlap) {
      return { hasOverlap: true }
    }
    return null
  }

  private isClosedValue(range: TimeRange[]): boolean {
    return range?.length === 1 && (range[0].startTime === null || range[0].endTime === null)
  }
}

<div class="loading-skeleton" style="display: flex; flex-direction: column; flex: 1">
  <div style="height: 62px; border-radius: 12px;" class="loading-bg loading-box-shadow"></div>
  <div *ngIf="!hasCharts" style="margin-top: 20px; display: flex">
    <div style="margin: 0 8px; width: 91px; height: 34px" class="loading-button loading-bg"></div>
    <div style="margin: 0 8px; width: 91px; height: 34px" class="loading-button loading-bg"></div>
  </div>
  <div
    *ngIf="hasCharts"
    class="loading-bg loading-box-shadow"
    style="
      margin-top: 20px;
      flex: 1;
      min-height: 250px;
      max-height: 250px;
      border-radius: 6px;
    "
  ></div>
  <div style="margin-top: 15px; display: flex">
    <div style="flex: 1">
      <div style="margin: 0 8px; width: 160px; height: 34px" class="loading-button loading-bg"></div>
    </div>
    <div style="margin: 0 8px; width: 160px; height: 34px" class="loading-button loading-bg"></div>
  </div>
  <div style="margin-top: 13px; display: flex">
    <ng-container *ngFor="let _ of [].constructor(5)">
      <div style="flex: 1">
        <div style="margin: 0 8px; width: 160px; height: 19px" class="loading-button loading-bg"></div>
      </div>
    </ng-container>
  </div>
  <div style="margin-top: 9px; margin-bottom: 6px; height: 1px" class="loading-bg"></div>
  <div style="display: flex">
    <ng-container *ngFor="let _ of [].constructor(5)">
      <div style="flex: 1">
        <div style="margin: 0 8px; width: 222px; height: 32px" class="loading-button loading-bg"></div>
      </div>
    </ng-container>
  </div>
  <div class="loading-grid"></div>
  <div *ngIf="!hasCharts" style="display: flex">
    <div style="flex: 1">
      <div style="margin: 0 8px; width: 160px; height: 19px" class="loading-button loading-bg"></div>
    </div>
    <div style="flex: 1">
      <div style="margin: 0 auto; width: 272px; height: 19px" class="loading-button loading-bg"></div>
    </div>
    <div style="flex: 1; margin-bottom: 10px">
      <div style="margin-left: auto; margin-right: 8px; width: 160px; height: 19px" class="loading-button loading-bg"></div>
    </div>
  </div>
</div>

/* eslint-disable guard-for-in */
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { Workflow } from '@app/modules/workflow/models/workflow.model'
import { TranslateHelper } from '@coreview/coreview-library'
import { ToastService } from '@coreview/coreview-components'

interface ItemType {
  key: string
  value: string
}
@Component({
  selector: 'app-execution-detail',
  templateUrl: './execution-detail.component.html',
  styleUrls: ['./execution-detail.component.sass'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ExecutionDetailComponent implements OnInit, OnChanges {
  @Input() data!: Workflow.Dto.V1.WorkflowExecutionDetail.Data
  @Input() definitionActions: Workflow.Dto.V1.WorkflowExecutionDetail.Action[] = []

  public hasInput = false
  public hasOutput = false
  public hasError = false

  public inputItems: ItemType[] = []
  public outputItems: ItemType[] = []

  constructor(private translateHelper: TranslateHelper, private toastService: ToastService) {}

  ngOnInit(): void {
    this.convertValues()
  }

  generateIOsArray = (io: any) => {
    let exeDetailsIOsArray: ItemType[] = []

    if (io.skipped) {
      exeDetailsIOsArray.push({ key: 'Skipped', value: io.filterResults })
    } else {
      for (const key in io) {
        let value = io[key] ? io[key] : ''

        if (Array.isArray(value)) {
          const resolvedValue = ['[ ', ' ]']

          resolvedValue.splice(
            1,
            0,
            value
              .map((item) => {
                if (typeof item === 'object' && item !== null) {
                  return Object.keys(item).map((keyNested) => ` { ${keyNested}: ${item[keyNested]} } `)
                } else {
                  return item
                }
              })
              .join('')
          )

          value = resolvedValue.join('')
        } else if (typeof value == 'object') {
          value = JSON.stringify(value)
        } else if (typeof value !== 'string') {
          value = value.toString()
        }

        exeDetailsIOsArray.push({ key, value })
      }
    }

    // If the action is "Execute Nested Workflow", set action's output as "No data available"
    if (exeDetailsIOsArray.length > 0 && exeDetailsIOsArray.findIndex((item) => item.key === 'Output') > 0) {
      exeDetailsIOsArray = [
        {
          key: '',
          value: this.translateHelper.instant('workflow_ExecutionNoDataAvailable'),
        },
      ]
    }

    return exeDetailsIOsArray
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.convertValues()
  }

  convertValues() {
    this.inputItems = []
    this.hasInput = false
    if (this.data?.workflowInput) {
      const inputs = JSON.parse(this.data.workflowInput)
      this.hasInput = Object.keys(inputs).length > 0
      this.inputItems = this.generateIOsArray(inputs)
    }

    this.outputItems = []
    this.hasOutput = false
    if (this.data?.workflowOutput) {
      const outputs = JSON.parse(this.data.workflowOutput)
      this.hasOutput = Object.keys(outputs).length > 0
      this.outputItems = this.generateIOsArray(outputs)
    }
    this.hasError = this.data?.error || this.data?.cause ? true : false
  }

  copyExecutionDetails(log: string) {
    navigator.clipboard
      .writeText(log)
      .then(() => {
        this.toastService.open({ id: 'copy', variant: 'success', title: this.translateHelper.instant('common_CopiedToClipboard') })
      })
      .catch(() => {
        this.toastService.open({ id: 'copy', variant: 'error', title: this.translateHelper.instant('common_Error') })
      })
  }
}

<cv-alert-bar
  *ngIf="showNoOUDisclaimer"
  variant="info"
  [title]="'management_NoOrganizationalUnitsFound' | translate"
  [hideCloseButton]="true"
>
</cv-alert-bar>
<ng-container *ngIf="loading">
  <div class="loading-skeleton" style="width: 35%">
    <div style="display: grid; row-gap: 10px">
      <div style="height: 24px; width: 40%; border-radius: 4px; background-color: var(--opaque-light-contrast-color)"></div>
      <div style="height: 24px; width: 60%; border-radius: 4px; background-color: var(--opaque-light-contrast-color); margin-left: 30px"></div>
      <div style="height: 24px; border-radius: 4px; background-color: var(--opaque-light-contrast-color); margin-left: 60px"></div>
      <div style="height: 24px; border-radius: 4px; background-color: var(--opaque-light-contrast-color); margin-left: 60px"></div>
      <div style="height: 24px; border-radius: 4px; background-color: var(--opaque-light-contrast-color); margin-left: 60px"></div>
      <div style="height: 24px; border-radius: 4px; background-color: var(--opaque-light-contrast-color); margin-left: 60px"></div>
    </div>
  </div>
</ng-container>
<div *ngIf="!showNoOUDisclaimer" [class.hidden]="loading" style="display: grid; grid-template-columns: repeat(3, 1fr); column-gap: 10px">
  <div style="grid-column: 1">
    <cv-select
      *ngIf="isMultiForest && !isEdit && !!domainControllers.length"
      class="p-b-10"
      [suggestions]="domainControllers"
      [label]="'management_Forest' | translate"
      [placeholder]="'management_SelectedForest' | translate"
      [value]="selectedDomainController"
      (selectionChanged)="onDomainControllerChange($event)"
      style="width: 100%"
    ></cv-select>
  </div>
  <div *ngIf="!!value" style="grid-column: 1" class="p-b-10">
    <label class="f-w-600">{{ 'management_SelectedOU' | translate }}</label
    ><br />
    <span>{{ value }}</span>
  </div>
  <div class="p-b-10" *ngIf="!!previousValues.organizationalUnit && !hideReset" style="grid-column: 1">
    <cv-button
      [fontWeight]="600"
      (click)="resetSelection()"
      buttonType="tertiary"
      color="alert"
      leftIcon="close"
      text="{{ 'common_ResetSelection' | translate }}"
      attr.cv-data-test="reset-selection"
    >
    </cv-button>
  </div>
  <div style="grid-column: span 3">
    <mat-radio-group [(ngModel)]="value">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node
          *matTreeNodeDef="let node"
          matTreeNodePadding
          [ngClass]="{ expandable: treeControl.isExpandable(node), selected: node.distinguishedName === selectedOrganizationalUnit }"
        >
          <mat-radio-button [value]="node.distinguishedName" [disabled]="node.disabled">
            <ng-container>
              <mat-icon class="node-icon">
                {{
                  !!node.icon ? node.icon : treeControl.isExpandable(node) && treeControl.isExpanded(node) ? 'folder_open' : 'folder'
                }} </mat-icon
              >&nbsp;
            </ng-container>
            {{ node.name }}
          </mat-radio-button>
        </mat-tree-node>

        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding
          [ngClass]="{ expandable: treeControl.isExpandable(node), selected: node.distinguishedName === selectedOrganizationalUnit }"
        >
          <button mat-icon-button matTreeNodeToggle class="btn">
            <mat-icon>
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          <mat-radio-button [value]="node.distinguishedName" [disabled]="node.disabled">
            <ng-container>
              <mat-icon class="node-icon">
                {{
                  !!node.icon ? node.icon : treeControl.isExpandable(node) && treeControl.isExpanded(node) ? 'folder_open' : 'folder'
                }} </mat-icon
              >&nbsp;
            </ng-container>
            {{ node.name }}
          </mat-radio-button>
        </mat-tree-node>
      </mat-tree>
    </mat-radio-group>
  </div>
</div>

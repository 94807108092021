/* eslint-disable @typescript-eslint/naming-convention */
import {
  TargetEntityAuditType,
  TargetEntityBaseType,
  TargetEntityPolicyType,
  TargetEntityType,
} from '@app/core/enums/group-type'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { ReportDefinition } from '@app/core/models/ReportDefinition'

export class TargetHelper {
  static defaultGridDefinition: ReportDefinition = {
    title: 'notused',
    fields: [],
    defaultHiddenFields: [],
    sortField: '',
    sortOrder: 'asc',
    verb: 'post' as Verb,
    isOnlineUsersType: false,
    responseItemProp: '',
    rowSelection: 'none',
  }

  static SharePointDefinition = {
    ...TargetHelper.defaultGridDefinition,
    fields: ['SiteUrl', 'Title'],
    sortField: 'SiteUrl',
    responseItemProp: 'items',
    apiUrl: '/sharepoint/siteusage/details/',
    operationsColumns: [
      {
        type: 'string',
        name: 'siteUrl',
        notSelectable: true,
      },
      {
        type: 'string',
        name: 'title',
        notSelectable: true,
      },
    ],
    propertyId: 'siteUrl',
    targetEntity: "SecurityGroup" as TargetEntityBaseType,
  }

  static Office365GroupDefinition = {
    ...TargetHelper.defaultGridDefinition,
    fields: ['DisplayName', 'Name'],
    sortField: 'DisplayName',
    responseItemProp: 'office365Groups',
    apiUrl: '/office365Groups',
    operationsColumns: [
      {
        type: 'string',
        name: 'displayName',
        notSelectable: true,
      },
      {
        type: 'string',
        name: 'name',
        notSelectable: true,
      },
    ],
    propertyId: 'id',
    targetEntity: "Office365Group" as TargetEntityType
  }

  static targetMap: Record<
    TargetEntityPolicyType | TargetEntityType | TargetEntityBaseType | TargetEntityAuditType,
    ReportDefinition & { apiUrl: string; propertyId: string }
  > = {
    Audit: {
      ...TargetHelper.defaultGridDefinition,
      calendarType: 'ChooseLastDays',
      daysList: [1, 7, 14, 30, 60, 90, 180] as (0 | 1 | 7 | 14 | 30 | 60 | 90 | 180 | 3)[],
      defaultDaysFilter: 7 as 0 | 1 | 60 | 3 | 7 | 14 | 30 | 90 | 180 | undefined,
      fields: ['Workload', 'Operation'],
      sortField: 'Operation',
      responseItemProp: 'auditActivities',
      apiUrl: 'auditactivities',
      operationsColumns: [
        {
          type: 'string',
          name: 'workload',
          notSelectable: true,
        },
        {
          type: 'string',
          name: 'operation',
          notSelectable: true,
        },
      ],
      propertyId: 'id',
      entity: 'Audit',
      verb: 'post' as Verb,
      targetEntity: 'Audit' as TargetEntityAuditType
    },
    DistributionGroup: {
      ...TargetHelper.defaultGridDefinition,
      fields: ['DisplayName', 'Name'],
      sortField: 'Name',
      responseItemProp: 'distributionGroups',
      apiUrl: '/distributiongroups',
      operationsColumns: [
        {
          type: 'string',
          name: 'displayName',
          notSelectable: true,
        },
        {
          type: 'string',
          name: 'name',
          notSelectable: true,
        },
      ],
      propertyId: 'id',
      targetEntity: "DistributionGroup" as TargetEntityBaseType
    },
    Mailbox: {
      ...TargetHelper.defaultGridDefinition,
      isOnlineUsersType: true,
      fields: ['UserPrincipalName'],
      sortField: 'UserPrincipalName',
      responseItemProp: 'onlineUsers',
      apiUrl: '/onlineusers',
      filters: { recipientType: { filterType: 'string', type: 'equals', filter: 'UserMailbox' }},
      operationsColumns: [
        {
          type: 'string',
          name: 'userPrincipalName',
          notSelectable: true,
        },
      ],
      propertyId: 'userPrincipalName',
      targetEntity: "User" as TargetEntityBaseType,
    },
    MailContact: {
      ...TargetHelper.defaultGridDefinition,
      fields: ['PrimarySmtpAddress'],
      sortField: 'PrimarySmtpAddress',
      responseItemProp: 'recipients',
      apiUrl: '/recipients',
      operationsColumns: [
        {
          type: 'string',
          name: 'primarySmtpAddress',
          notSelectable: true,
        },
      ],
      propertyId: 'id',
      verb: 'get' as Verb,
      targetEntity: "User" as TargetEntityBaseType,
    },
    Office365Group: this.Office365GroupDefinition,
    SecurityGroup: {
      ...TargetHelper.defaultGridDefinition,
      fields: ['DisplayName', 'Name'],
      sortField: 'DisplayName',
      responseItemProp: 'securityGroups',
      apiUrl: '/securityGroups',
      operationsColumns: [
        {
          type: 'string',
          name: 'displayName',
          notSelectable: true,
        },
        {
          type: 'string',
          name: 'name',
          notSelectable: true,
        },
      ],
      propertyId: 'objectId',
      targetEntity: "SecurityGroup" as TargetEntityBaseType
    },
    User: {
      ...TargetHelper.defaultGridDefinition,
      isOnlineUsersType: true,
      fields: ['UserPrincipalName'],
      sortField: 'UserPrincipalName',
      responseItemProp: 'onlineUsers',
      apiUrl: '/onlineusers',
      operationsColumns: [
        {
          type: 'string',
          name: 'userPrincipalName',
          notSelectable: true,
        },
      ],
      propertyId: 'userPrincipalName',
      targetEntity: "User" as TargetEntityBaseType,
    },
    SharePointSites: this.SharePointDefinition,
    SharePointInactiveUsers: {
      ...TargetHelper.defaultGridDefinition,
      isOnlineUsersType: true,
      fields: ['DisplayName', 'UserPrincipalName', 'SharePointPlan', 'SharePointLastAction'],
      sortField: 'DisplayName',
      responseItemProp: 'onlineUsers',
      apiUrl: '/sharepoint/inactiveusers',
      operationsColumns: [
        {
          type: 'string',
          name: 'displayName',
          notSelectable: true,
        },
        {
          type: 'string',
          name: 'userPrincipalName',
          notSelectable: true,
        },
        {
          type: 'string',
          name: 'sharePointPlan',
          notSelectable: true,
        },
        {
          type: 'string',
          name: 'sharePointLastAction',
          notSelectable: true,
        },
      ],
      propertyId: 'userPrincipalName',
      verb: 'get' as Verb,
      targetEntity: "User" as TargetEntityBaseType,
    },
    Teams: {
      ...TargetHelper.defaultGridDefinition,
      fields: ['DisplayName'],
      sortField: 'DisplayName',
      responseItemProp: 'items',
      apiUrl: '/teams/groups/',
      operationsColumns: [
        {
          type: 'string',
          name: 'displayName',
          notSelectable: true,
        },
      ],
      propertyId: 'id',
      targetEntity: "User" as TargetEntityBaseType,
    },
  }
}

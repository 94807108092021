/* eslint-disable @typescript-eslint/naming-convention */
import { ToastService } from '@coreview/coreview-components'
import { getUserGroups } from './store/userGroups/userGroups.actions'
import { selectLastMessageOfType } from './store/messages/messages.selectors'
import { LoadingService } from './core/services/loading.service'
import { cloneDeep, flattenDeep } from 'lodash-es'
import { selectFavorites, selectMenusAll } from './store/menu/menu.selectors'
import { loadFavorites } from './store/menu/menu.actions'
import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { combineLatest, Observable, of } from 'rxjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/en'
import 'dayjs/locale/de'
import 'dayjs/locale/es'
import 'dayjs/locale/it'
import 'dayjs/locale/fr'
import { RootState } from './store/RootState.type'
import { getOperatorById } from './store/operators/operators.actions'
import { selectOperatorError } from '@app/store/operators/operators.selectors'
import { selectUserSettings } from './store/userSettings/userSettings.selectors'
import { getManagedTenants } from './store/tenants/tenants.actions'
import { getReportsDefinition } from '@app/store/reports/reports-definition.actions'
import { Constants } from './shared/utilities/constants'
import { TranslateHelper } from '@coreview/coreview-library'
import { AuthenticationService } from '@app/core/services/authentication.service'
import { LocalstorageService } from './core/services/localstorage.service'
import { filter, pluck } from 'rxjs/operators'
import { selectSelectedTenant } from './store/tenants/tenants.selectors'
import { NavItem } from './core/models/nav-item'
import { UserSettings } from '@coreview/coreview-library/models/user-settings'
import { getOnlineuserColumns } from './store/onlineuser-columns/onlineuser-columns.actions'
import { getOrganizationById } from '@app/store/organizations/organizations.actions'
import * as Highcharts from 'highcharts'
import { selectOrganizationById, selectedOrganization } from './store/organizations/organizations.selectors'
import { Organization } from './core/models/Organization'
import { NavigationEnd, Router } from '@angular/router'
import * as isYesterday from 'dayjs/plugin/isYesterday'
import * as isToday from 'dayjs/plugin/isToday'
import bulletChart from 'highcharts/modules/bullet'
import * as ExportingModule from 'highcharts/modules/exporting'
import { getManagementStatus } from './store/management-status/management-status.actions'
import { getManagementType } from './store/management-type/management-type.actions'
import { SharedHelperService } from './shared/shared.helper.service'
import { ReportExportService } from './core/services/report-export.service'
import { ReportsService } from './core/services/reports.service'
import { selectReportsDefinition } from './store/reports/reports-definition.selectors'
import { Message } from './core/models/communication/message'
import { AuthHelperService } from './core/services/auth.helper.service'
import { Helpers } from './shared/utilities/helpers'
import { loadTranslator } from './app.translation-loader'
import { UserSettingsService } from './core/services/user-settings.service'
import { getManagementActions } from './store/management-actions/management-actions.actions'

declare let pendo: any
export let churnZeroOnce = true
declare let zE: any
export let zdOnce = true

export function declareChurnZero(window: any, salesForceID: string, userName: string) {
  if (churnZeroOnce) {
    window.ChurnZero = []
    window.ChurnZero.push(['setAppKey', '1!nL19Myvtb6xShqjwSQVPyH4Pb8f1IbKl9CVlZLgHzNMtD6A'])
    window.ChurnZero.push(['setContact', salesForceID, userName])
    churnZeroOnce = false
  }
}

export function declareZenDesk() {
  if (zdOnce) {
    const body = <HTMLDivElement>document.body
    const script = document.createElement('script')
    script.innerHTML = ''
    script.id = 'ze-snippet'
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=86dfe39c-eb0f-479f-893e-3776d6a04a92'
    script.async = false
    script.defer = true
    body.appendChild(script)

    zdOnce = false
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  public menus: NavItem[] = []
  public title = 'CoreView'
  public loggedUserError$: Observable<{ code: number; message: string } | null> = of(null)
  public userSettings$: Observable<UserSettings | undefined> = of()
  public errorStatus = -1
  public startUpCompleted = false
  public showConsent = false

  public isMenuOpen = false
  public darkMode = false

  public myOrganization?: Organization

  constructor(
    private store: Store<RootState>,
    private authenticationService: AuthenticationService,
    private sharedHelperService: SharedHelperService,
    private storage: LocalstorageService,
    private translateHelper: TranslateHelper,
    private router: Router,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private reportExportService: ReportExportService,
    private reportsService: ReportsService,
    private authHelperService: AuthHelperService,
    private userSettingsService: UserSettingsService
  ) {
    combineLatest([this.store.select(selectMenusAll), this.store.select(selectFavorites)]).subscribe(([{ menu }, favorites]) => {
      this.menus = cloneDeep(menu.menuFiltered)
      this.addFavoriteMenus(favorites)
    })

    this.loggedUserError$ = this.store.select(selectOperatorError)
    this.userSettings$ = this.store.select(selectUserSettings)

    bulletChart(Highcharts)
    ExportingModule.default(Highcharts)

    Highcharts.setOptions({
      colors: [
        'var(--primary-color, #306AC8)',
        'var(--highcharts-extra-color-one, #2A2D34)',
        'var(--highcharts-extra-color-two, #6D6BC3)',
        'var(--warning-color, #F5A623)',
        'var(--highcharts-extra-color-three, #404040)',
        'var(--highcharts-extra-color-four, #018786)',
        'var(--highcharts-extra-color-five, #3D8E8F)',
        'var(--highcharts-extra-color-six, #525092)',
        'var(--highcharts-extra-color-seven, #B87D1A)',
        'var(--highcharts-extra-color-eight, #386CAA)',
      ],
      credits: {
        enabled: false,
      },
      chart: {
        style: {
          fontFamily: 'Open Sans',
          color: 'var(--default-text-color, #404040)',
        },
        backgroundColor: 'var(--default-surface-color, #ffffff)',
      },
      legend: {
        itemHoverStyle: {
          color: 'var(--default-text-color, #404040)',
          opacity: 1,
        },
        itemStyle: {
          color: 'var(--default-text-color, #404040)',
          opacity: 0.8,
        },
      },
      title: {
        style: {
          color: 'var(--default-text-color, #404040)',
        },
      },
      subtitle: {
        style: {
          color: 'var(--default-text-color, #404040)',
        },
      },
      xAxis: {
        title: {
          style: {
            color: 'var(--default-text-color, #404040)',
          },
        },
        labels: {
          style: {
            color: 'var(--grey-text-color, #808080)',
          },
        },
        gridLineColor: 'var(--highcharts-line-color, #e6e6e6)',
      },
      yAxis: {
        title: {
          style: {
            color: 'var(--default-text-color, #404040)',
          },
        },
        labels: {
          style: {
            color: 'var(--grey-text-color, #808080)',
          },
        },
        gridLineColor: 'var(--highcharts-line-color, #e6e6e6)',
      },
      zAxis: {
        title: {
          style: {
            color: 'var(--default-text-color, #404040)',
          },
        },
        labels: {
          style: {
            color: 'var(--grey-text-color, #808080)',
          },
        },
        gridLineColor: 'var(--highcharts-line-color, #e6e6e6)',
      },
      exporting: {
        buttons: {
          contextButton: {
            symbolStroke: 'var(--primary-color, #306AC8)',
            symbolStrokeWidth: 2,
            theme: {
              fill: 'var(--default-surface-color, #FFFFFF)',
            },
          },
        },
      },
      navigation: {
        menuStyle: {
          padding: '0',
          background: 'var(--option-background-color, #ffffff)',
        },
        menuItemStyle: {
          padding: '10px',
          color: 'var(--default-text-color, #404040)',
        },
        menuItemHoverStyle: {
          background: 'var(--option-highlight-color, #DBE9F9)',
        },
      },
      plotOptions: {
        series: {
          borderColor: 'var(--default-surface-color, #ffffff)',
        },
        pie: {
          dataLabels: {
            style: {
              color: '#ffffff',
              textOutline: '1px #000000',
            },
          },
        },
        bar: {
          dataLabels: {
            style: {
              color: 'var(--highcharts-label-color, #000000)',
              textOutline: '1px var(--highcharts-label-outline-color, #ffffff)',
            },
          },
        },
        column: {
          dataLabels: {
            style: {
              color: '#ffffff',
              textOutline: '1px #000000',
            },
          }
        }
      },
    });
    

    this.router.events.pipe(filter((eventRes: any) => eventRes instanceof NavigationEnd)).subscribe((ev: any) => {
      if (ev.urlAfterRedirects && ev.urlAfterRedirects.indexOf('/consent') === 0) {
        this.showConsent = true
      }

      this.loadingService.loading$.emit(false)
    })

    this.showNotifications()
  }

  ngOnInit(): void {
    this.errorStatus = this.authenticationService.loginStatus
    if (this.errorStatus === 0) {
      dayjs.extend(utc)
      dayjs.extend(localizedFormat)
      dayjs.extend(isToday)
      dayjs.extend(isYesterday)

      this.checkSecureByDefault()
      this.manageUserSettings()

      this.dispatchCalls()
      //Clearing possible reportFilters for session
      if (!this.storage.getSelectedGlobalFilter()) {
        this.reportsService.deleteSessionFiltersReports().subscribe()
      }

      this.storeSubscriptions()

      this.externalScriptsStuff()
    } else {
      this.startUpCompleted = true
    }
  }

  public handleNotifications(x: Partial<Message>) {
    if (x.body.category === 'PublicApiKeyCompleted') {
      return
    } else if (x.body?.category === 'AsyncExportReports') {
      this.toastService.open(
        {
          id: x.timestamp || '',
          variant: x.body.state === 'Success' ? 'success' : 'error',
          matIcon: x.body.state === 'Success' ? 'check_circle' : 'error',
          title: this.translateHelper.instant('common_' + x.body.title),
          message: this.translateHelper.instant('common_' + x.body.description),
          displayCTA: true,
          actionLabel: this.translateHelper.instant('common_Download'),
          dismissLabel: this.translateHelper.instant('common_Dismiss'),
          action: (toast: { id: any }) => {
            this.reportExportService.downloadFile(x.body.url)
            this.toastService.close(toast.id)
          },
          dismiss: (toast: { id: any }) => this.toastService.close(toast.id),
        },
        {
          autoclose: false,
          timeout: 100,
        }
      )
    } else {
      this.toastService.open({
        id: x.timestamp || '',
        variant: x.body.state === 'Success' ? 'success' : 'error',
        matIcon: x.body.state === 'Success' ? 'check_circle' : 'error',
        title: this.translateHelper.instant('common_' + x.body.title),
        message: this.translateHelper.instant('common_' + x.body.description),
      })
    }
  }

  storeSubscriptions() {
    this.store
      .select(selectSelectedTenant)
      .pipe<string>(pluck('managedTenantId'))
      .pipe(filter((x) => !!x))
      .subscribe((orgId) => {
        this.store.dispatch(getManagementType({ orgId }))
      })

    this.store
      .select(selectedOrganization)
      .pipe(filter((x) => !!x))
      .subscribe((x) => {
        this.myOrganization = x

        if (x?.faviconUrl) {
          document.querySelector('#favicon')?.setAttribute('href', x.faviconUrl + '?' + new Date().getTime())
        }
      })

    this.store
      .select(selectReportsDefinition)
      .pipe(filter((x) => !!x))
      .subscribe((_) => (this.startUpCompleted = true))
  }

  dispatchCalls() {
    this.store.dispatch(getOrganizationById({ id: this.storage.getLoggedUser().organizationId }))
    this.store.dispatch(loadFavorites())
    this.store.dispatch(getReportsDefinition())
    this.store.dispatch(getOnlineuserColumns())
    this.store.dispatch(getOperatorById({ userName: this.storage.getLoggedUser().userName }))
    this.store.dispatch(getManagedTenants())
    if (this.sharedHelperService.isManagement()) {
      this.store.dispatch(getManagementStatus())
      this.store.dispatch(getManagementActions())
    }
    if (this.sharedHelperService.isAdmin()) {
      this.store.dispatch(getUserGroups())
    }
  }

  manageUserSettings() {
    this.userSettings$.subscribe((us: UserSettings | undefined) => {
      const preferredIsoLang = this.storage.preferredLanguage
      this.isMenuOpen = this.userSettingsService.getExtraValue('isMenuOpen', us)
      this.darkMode = this.userSettingsService.getExtraValue('preferredTheme', us) === 'dark'
      if (us?.language) {
        const isoLang = Constants.languages.find((l) => l.fullLabel === us.language)?.iso || Constants.languages[0].iso
        if (preferredIsoLang !== isoLang) {
          this.storage.preferredLanguage = isoLang
          loadTranslator().subscribe(() => {
            this.translateHelper.reloadLang(isoLang)
            this.translateHelper.use(isoLang)
          })
        }
      } else {
        this.translateHelper.reloadLang(preferredIsoLang || Constants.languages[0].iso)
        this.translateHelper.use(preferredIsoLang || Constants.languages[0].iso)
      }

      if (us?.regionalSettings) {
        const rs = Constants.regionalSettings.find((l) => l.fullLabel === us.regionalSettings)
        const iso = Helpers.isoRegionalSetting(rs)

        dayjs.locale(iso)
      } else {
        dayjs.locale(Constants.regionalSettings[0].iso)
      }
    })
  }

  getUserRoleForPendo() {
    if (this.authHelperService.hasRole(Constants.roles.TenantAdmin)) {
      return 'TenantAdmin'
    } else if (this.authHelperService.hasRole(Constants.roles.Management)) {
      return 'DelegatedAdmin'
    } else {
      return 'Executive'
    }
  }

  getOrganizationSizeForPendo(myOrganization: Organization) {
    if (myOrganization.purchased4365Licenses > 5000) {
      return '>5k'
    } else if (myOrganization.purchased4365Licenses >= 1000 && myOrganization.purchased4365Licenses <= 5000) {
      return '1-5k'
    } else {
      return '<1k'
    }
  }

  externalScriptsStuff() {
    ;(function (apiKey) {
      ;(function (p: any, e, n, d: any, o?: any) {
        let v: any, w, x, y, z: any
        o = p[d] = p[d] || {}
        o._q = o._q || []
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)))
              }
          })(v[w])
        y = e.createElement(n) as any
        y.async = !0
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js'
        z = e.getElementsByTagName(n)[0]
        z.parentNode.insertBefore(y, z)
      })(window, document, 'script', 'pendo')
    })('f4a55d49-2540-4dc6-4e21-93683d9820ee')
    ;(function (a: any, b: any, c: any, d: any) {
      var cz = a.createElement(b)
      cz.type = c
      cz.async = true
      cz.src = d
      var s = a.getElementsByTagName(b)[0]
      s.parentNode.insertBefore(cz, s)
    })(document, 'script', 'text/javascript', '//analytics.churnzero.net/churnzero.js')

    this.store
      .select(selectOrganizationById(this.storage.getLoggedUser().organizationId))
      .pipe(filter((x) => !!x))
      .subscribe((x: any) => {
        const myOrganization = x as Organization
        const loggedUser = this.storage.getLoggedUser()

        const role = this.getUserRoleForPendo()

        const hasChat = this.authHelperService.hasRole(Constants.roles.SupportChat)
        const hasFeedback = this.authHelperService.hasRole(Constants.roles.Feedback)
        const hasTenantAdmin = this.authHelperService.hasRole(Constants.roles.TenantAdmin)
        const hasManagement = this.authHelperService.hasRole(Constants.roles.Management)
        const hasGlobalLicenses = this.authHelperService.hasRole(Constants.roles.GlobalLicenses)
        const hasWFeditor = this.authHelperService.hasRole(Constants.roles.WorkflowEditor)
        const hasWFpublisher = this.authHelperService.hasRole(Constants.roles.WorkflowPublisher)
        const hasPBAdmin = this.authHelperService.hasRole(Constants.roles.PlaybookAdmin)
        const hasPBManager = this.authHelperService.hasRole(Constants.roles.PlaybookManager)
        const hasPBViewer = this.authHelperService.hasRole(Constants.roles.PlaybookGlobalViewOnly)

        const hasCoreSuite = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:CORESUITE') >= 0
        const hasEssential = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('CSKU:ESSENTIALS') >= 0
        const hasEssentialMSP = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('CSKU:ESSENTIALSMSP') >= 0
        const hasProfessional = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('CSKU:PROFESSIONAL') >= 0
        const hasProfessionalMSP = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('CSKU:PROFESSIONALMSP') >= 0
        const hasEnterprise = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('CSKU:ENTERPRISE') >= 0
        const hasSuite = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('CSKU:SUITE') >= 0
        const hasAudit = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('CSKU:AUDIT') >= 0
        const hasOnPremAgent = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:ONPREM') >= 0
        const hasWorkflow = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:WF') >= 0
        const hasCoreTeams = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:CORETEAMS') >= 0
        const hasDataConnector = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:DATACONNECTOR') >= 0
        const hasMultiTenant = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:MULTITENANT') >= 0
        const hasCustomActionApproval = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('FT:CUSTOMACTIONAPPROVAL') >= 0

        const hasEssential2025 = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:ESSENTIALS2025') >= 0
        const hasEssentialMSP2025 = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:ESSENTIALSMSP2025') >= 0
        const hasProfessional2025 = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:PROFESSIONAL2025') >= 0
        const hasProfessionalMSP2025 = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:PROFESSIONALMSP2025') >= 0
        const hasEnterprise2025 = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:ENTERPRISE2025') >= 0
        const hasDelegation2025 = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:DELEGATION2025') >= 0
        const hasOnPrem2025 = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:ONPREM2025') >= 0
        const hasFree2025 = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:FREE2025') >= 0
        const hasDataConnector2025 = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:DATACONNECTOR2025') >= 0
        const hasAudit2025 = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:AUDIT2025') >= 0
        const hasEntraApps = myOrganization.portalSkus && myOrganization.portalSkus.indexOf('SKU:ENTRAAPPS') >= 0

        const creationDate = myOrganization.creationDate ? myOrganization.creationDate.split('T')[0] : null

        try {
          pendo.initialize({
            visitor: {
              id: loggedUser.userName,
              email: loggedUser.primaryEmail,
              full_name: loggedUser.displayName,
              role,
              hasTenantAdmin,
              hasManagement,
              hasGlobalLicenses,
              hasWFeditor,
              hasWFpublisher,
              hasChat,
              hasFeedback,
              hasPBAdmin,
              hasPBManager,
              hasPBViewer,
            },
            account: {
              id: myOrganization.guid,
              name: myOrganization.displayName,
              demo: myOrganization.isDemo,
              subscription_level: myOrganization.subscriptionLevel,
              organization_type: myOrganization.organizationType,
              purchasedLicenses: myOrganization.purchased4365Licenses,
              expirationDate: myOrganization.expirationDate,
              hasCoreSuite,
              hasEssential,
              hasEssentialMSP,
              hasProfessional,
              hasProfessionalMSP,
              hasEnterprise,
              hasSuite,
              hasAudit,
              hasOnPremAgent,
              hasWorkflow,
              hasCoreTeams,
              hasDataConnector,
              hasMultiTenant,
              hasCustomActionApproval,
              hasEssential2025,
              hasEssentialMSP2025,
              hasProfessional2025,
              hasProfessionalMSP2025,
              hasEnterprise2025,
              hasDelegation2025,
              hasOnPrem2025,
              hasFree2025,
              hasDataConnector2025,
              hasAudit2025,
              hasEntraApps,
              creationDate,
              notForResell: myOrganization.notForResell,
              countryCode: myOrganization.countryLetterCode,
              salesForceID: myOrganization.salesForceID,
              managementType: myOrganization.managementType,
              is_paying: true,
            },
          })

          declareZenDesk()
          declareChurnZero(window, myOrganization.salesForceID ?? '', loggedUser.userName)
        } catch (exc) {
          console.error(exc)
          //do nothing
        }
      })
  }

  addFavoriteMenus(favorites: any) {
    const flat = flattenDeep(this.menus.map((x) => [x, x.children?.map((y) => [y, y.children ?? []]) ?? []]))
    const favMenu = flat?.find((x) => x.id === Constants.menuIds.favorites)
    if (favMenu) {
      favMenu.children = []
      const favMenus = cloneDeep(
        favorites.favoriteMenus.map((favId: number) => flat?.find((y) => y.id === favId)).filter((x: any) => !!x)
      ).map((x: NavItem) => {
        x.icon = 'material-icons star'
        return x
      })

      const favSavedReports = cloneDeep(favorites.favoriteSavedReports)
        .map(
          (x: any, index: number) =>
            ({
              id: x.id,
              sortId: Math.pow(10, 5) + index,
              title: x.title,
              route: x.route,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              queryParams: { SavedReportId: x.savedReportId },
              icon: 'material-icons save',
            } as NavItem)
        )
        .filter((x: any) => !!x)
      favMenu.children = [...favMenus, ...favSavedReports].sort((a: NavItem, b: NavItem) =>
        (this.translateHelper.instant(a.title) as string).localeCompare(this.translateHelper.instant(b.title) as string)
      )
    }
  }

  showNotifications() {
    this.store
      .select(selectLastMessageOfType('ReceiveNotification'))
      .pipe(filter((x) => !!x))
      .subscribe((x) => {
        this.handleNotifications(x!)
      })

    this.store
      .select(selectLastMessageOfType('NotifyTaskStatus'))
      .pipe(filter((x) => !!x))
      .subscribe((x) => {
        if (x?.body.title === 'ChangeManagementUserPassword' && x?.body?.state !== 'Progress') {
          this.reloadOrganization()
        }
      })
  }

  reloadOrganization() {
    this.store.dispatch(getOrganizationById({ id: this.storage.getLoggedUser().organizationId }))
    this.store.dispatch(getManagementType({ orgId: this.storage.getLoggedUser().organizationId }))
  }

  checkSecureByDefault() {
    this.authenticationService.checkSecureByDefault().subscribe((res) => {
      if (!res) {
        this.router.navigate([{ outlets: { forbidden: 'securebydefault' } }], { skipLocationChange: true }).catch((_: any) => _)
      }
    })
  }

  preferredThemeChanged(newTheme: 'light' | 'dark') {
    this.darkMode = newTheme === 'dark'
    this.userSettingsService.updateExtraUserSettings('preferredTheme', newTheme).subscribe(() => {
      document.querySelector('body')?.setAttribute('cv-data-theme', newTheme)
    })
  }
}

/* eslint-disable @typescript-eslint/member-delimiter-style */
import { isFilterable } from '@app/modules/workflow/utils/filterable-utils'
import { Verb } from '@core/models/PageDataCommonClasses'
import {
  EnhancedJsonFormData,
  EnhancedPropertyFormDescriptor,
  EnhancedPropertyFormDescriptorOptions,
} from '@shared/models/enhanced-json-form-data'
import { isNil } from 'lodash-es'
import { CvSchemaAttribute, SchemaPropertyDecorator } from './json-schema-rosetta-v1.class'

type ApiRoutePath = {
  name: string
  route: string
  verb?: Verb
  params?: string
  format: 'suggester' | 'suggester-string' | 'suggester-button' | 'text' | 'extended'
  options: Partial<EnhancedPropertyFormDescriptorOptions>
  baseUrl?: string
}

export class FilterableSourceDecorator extends SchemaPropertyDecorator {
  private filterableMapDataSources: Array<ApiRoutePath> = [
    {
      name: 'targetfieldsbyentity',
      route: '/datasources/targetfieldsbyentity',
      params: 'return { query: query }',
      format: 'suggester',
      verb: 'get',
      options: {
        enumResponseKeyProp: 'id',
        enumResponseDisplayProp: 'data',
        enumResponseProp: ['data','items']
      },
      baseUrl: 'workflow'
    },
    {
      name: 'teamspolicyvalues',
      route: '/datasources/teamspolicyvalues',
      params: 'return { filter: query }',
      format: 'suggester',
      verb: 'get',
      options: {
        enumResponseKeyProp: 'id',
        enumResponseDisplayProp: 'data',
        enumResponseProp: ['data','items']
      },
      baseUrl: 'workflow'
    },
    {
      name: 'assignablephonenumbers',
      route: '/datasources/assignablephonenumbers',
      params: 'return { filter: query }',
      format: 'suggester',
      verb: 'get',
      options: {
        enumResponseKeyProp: 'id',
        enumResponseDisplayProp: 'data',
        enumResponseProp: ['data','items']
      },
      baseUrl: 'workflow'
    }
  ]

  get filterableDataSources(): string {
    return this.filterableMapDataSources.map((item) => item.name).join()
  }

  getFilterableDataSource(name: string | undefined): ApiRoutePath | undefined {
    if (isNil(name)) {
      return name
    }

    return this.filterableMapDataSources.find((data) => data.name.toLowerCase() === name)
  }

  isDataSourceProp([, prop]: [string, EnhancedPropertyFormDescriptor]): boolean {
    const key = prop[CvSchemaAttribute.xCvDataSource]?.toLowerCase()
    return !!key
  }

  isCustomListProp([, prop]: [string, EnhancedPropertyFormDescriptor]): boolean {
    const key = prop[CvSchemaAttribute.xCvCustomList]?.toLowerCase()
    return !!key
  }

  isValidProp(value: [string, EnhancedPropertyFormDescriptor]): boolean {
    return this.isCustomListProp(value) || this.isDataSourceProp(value)
  }

  isFilterableProp([, prop]: [string, EnhancedPropertyFormDescriptor]): boolean {
    return isFilterable(prop)
  }

  match(schema: EnhancedJsonFormData): boolean {
    const properties = schema?.properties ?? {}

    return Object.entries(properties).some((value) => this.isValidProp(value))
  }

  /**
   * Decorators may call parent implementation of the operation, instead of
   * calling the wrapped object directly. This approach simplifies extension
   * of decorator classes.
   */
  public getJsonSchema(): EnhancedJsonFormData {
    const schema = super.getJsonSchema()

    if (this.match(schema)) {
      const properties = Object.entries(schema.properties)
        .map((value) => {
          if (this.isDataSourceProp(value)) {
            const [key, prop] = value
            const slug = prop[CvSchemaAttribute.xCvDataSource]?.toLowerCase()
            const dataSource = this.getFilterableDataSource(slug)
            const options: EnhancedPropertyFormDescriptorOptions = dataSource ? {
              enumApi: dataSource.route,
              enumApiVerb: dataSource?.verb ?? 'post',
              enumResponseProp: dataSource.name,
              enumGetApiParams: dataSource.params,
              enumDatacenterUrl: dataSource.baseUrl,
              cleanValueAfterSelection: false,
              ...dataSource.options,
            } : {
              enumApi: `/datasources/${slug}`,
              enumApiVerb: 'get',
              enumGetApiParams: this.isFilterableProp(value) ? 'return {filter: query}' : '',
              enumDatacenterUrl: 'workflow',
              cleanValueAfterSelection: false,
              enumResponseKeyProp: 'id',
              enumResponseDisplayProp: this.getDisplayProp(slug),
              enumResponseProp: ['data','items'],
            }
            const newProp = {
              // ...omit(prop, CvSchemaAttribute.xCvDataSource, CvSchemaAttribute.xCvDataSourceFilterable),
              ...prop,
              format: dataSource?.format || 'extended',
              options,
            }

            return [key, newProp]
          } else if (this.isCustomListProp(value)){
            const [key, prop] = value
            const id = prop[CvSchemaAttribute.xCvCustomList]?.toLowerCase()
            const options: EnhancedPropertyFormDescriptorOptions = {
              enumApi: `/v2/customlists/${id}`,
              enumApiVerb: 'get',
              enumGetApiParams: '',
              enumDatacenterUrl: 'workflow',
              cleanValueAfterSelection: false,
              enumResponseKeyProp: '',
              enumResponseDisplayProp: '',
              enumResponseProp: ['data', 'values'],
            }
            const newProp = {
              ...prop,
              format: 'extended',
              options,
            }

            return [key, newProp]
          }
          return value
        })
        .reduce((acc, [key, prop]) => ({ ...acc, [`${key}`]: prop }), {})

      schema.properties = properties
    }

    return schema
  }

  private getDisplayProp(slug?: string): string {
    if (slug) {
      if (['msoldevices', 'intunedevices', 'autopilotdevices'].includes(slug)){
        return 'data.displayValue'
      }
      if (['licenses'].includes(slug)){
        return 'data.label'
      }
      if (['organizationalunits'].includes(slug)){
        return 'data.distinguishedName'
      }
      if (['emailtemplates'].includes(slug)) {
        return 'data.name'
      }
    }
    return 'data'
  }
}

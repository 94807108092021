import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { pluck } from 'rxjs/operators'

import { ApiclientService } from './apiclient.service'

@Injectable({
  providedIn: 'root',
})
export class SuggesterService {
  constructor(private apiClient: ApiclientService, private httpClient: HttpClient) {}

  public getSupportoperators(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/supportoperators/`, { withCredentials: true })
  }

  public getUsersDistributionGroupsAndRecipients(params: any): Observable<any> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/completionSuggest/`, { withCredentials: true, params })
      .pipe(pluck('elasticSearchResult'))
  }

  public getGroupOperators(params: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.apiClient.basePortalApiUrl}/suggest/groups/operators?metadata=true`, params, { withCredentials: true })
      .pipe(pluck('suggestResponse'))
  }

  public getMessageTraceSuggest(params: any): Observable<any> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/completionSuggest?metadata=true`, { withCredentials: true, params })
      .pipe(pluck('elasticSearchResult'))
  }

  public getTeamsPersonVoiceAppRedirectionSuggest(params: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.apiClient.basePortalApiUrl}/suggest/teams/redirectcallqueue`, params, { withCredentials: true })
      .pipe(pluck('items'))
  }

  public getTeamsVoicemailRedirectionSuggest(params: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.apiClient.basePortalApiUrl}/management/voicemail/groups/suggestion`, params, { withCredentials: true })
      .pipe(pluck('items'))
  }

  public getOnlineUsersByRecipientTypeSuggestion(params: any): Observable<any> {
    return this.httpClient.get<any>(`${ this.apiClient.basePortalApiUrl }/onlineusersbyrecipientype/suggestion?metadata=true`,
      { withCredentials: true, params})
      .pipe(
        pluck('items')
      )
  }

  public getGrantAccessSuggestions(params: any): Observable<any> {
    return this.httpClient.get<any>(`${ this.apiClient.basePortalApiUrl }/management/grantaccess/suggestions?metadata=true`,
      { withCredentials: true, params})
      .pipe(
        pluck('items')
      )
  }
}

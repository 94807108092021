import { Component } from '@angular/core'

@Component({
  selector: 'app-exporting-dialog',
  templateUrl: './exporting-dialog.component.html',
  styleUrls: ['./exporting-dialog.component.sass'],
})
export class ExportingDialogComponent {

}

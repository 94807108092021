<div class="container" [ngClass]="{ 'workflow-previewAction-sequence': inSequence }">
  <div
    style="display: flex; width: 100%; box-sizing: border-box"
    *ngIf="action"
    (click)="handleClick()"
    [ngClass]="{
      'workflow-previewAction-disabled': action.forceSkip,
      'workflow-previewAction-workflow': previewType === 'workflows' && isCatch === false,
      'workflow-previewAction-workflowCatch': previewType === 'workflows' && isCatch === true,
      'workflow-previewAction-template': previewType === 'templates',
    }"
    class="workflow-previewAction"
  >
    <span
      [ngClass]="{
        'workflow-preview-categoryIcon': isCatch === false,
        'workflow-preview-categoryIconCatch': isCatch === true
      }"
      [class]="action.categoryIcon | fabricIconClass"
    ></span>

    <span class="workflow-preview-text"><ng-content></ng-content></span>
    <ng-content select="[extra-icon]"></ng-content>
    <div class="vertical-line"></div>

    <ng-content select="[command-icon]"></ng-content>
  </div>
  <div *ngIf="!!action?.customDescription?.trim()?.length" style="background: var(--default-background-color); padding: 10px; overflow-wrap: break-word">
    {{ action?.customDescription }}
  </div>
</div>

<ng-container *ngIf="editable === false && !isLast">
  <div class="arrow-container">
    <mat-icon class="arrow-icon">arrow_downward</mat-icon>
  </div>
</ng-container>

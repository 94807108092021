<div class="report-container">
    <cv-surface class="title-bar" [padding]="false" [shadow]="false" [rounded]="false" [noBorder]="true" *ngIf="dashboardDefinition">
      <div class="title-bar-content">
        <div class="first-line">
          {{ reportTitle }}
          <mat-icon
            *ngIf="!!reportDescription"
            (click)="showDescription = !showDescription"
            [ngClass]="{ 'info-active': !!showDescription }"
            class="m-l-5 info material-icons-outlined"
            >info
          </mat-icon>
        </div>
        <div class="m-t-10 f-s-14" *ngIf="!!showDescription">{{ reportDescription }}</div>
      </div>
      <div>
        <app-favorite *ngIf="menu" [menuId]="menu.id" [isFavorite]="menu.isFavorite" entity="dashboards"> </app-favorite>
      </div>
    </cv-surface>

    <cv-horizontal-tab-group type="line" (tabChanged)="tabChanged($event)" [activeTab]="tabSelectedIndex + 1" *ngIf="!!dashboardDefinition">
      <cv-tab title="{{ 'licenses_Summary' | translate }}">
        <div class="tab-container">
          <div class="main-container" [ngClass]="!canSeeLeftPanel() ? 'mainContainer-two' : 'mainContainer-three'" *ngIf="tabSelectedIndex == 0">
            <cv-smart-panel *ngIf="canSeeLeftPanel()" [title]="'reports_CustomReports' | translate" style="padding-top: 10px">
              <div style="cursor: default" content>
                <div class="title-container">
                  <div class="title" translate>reports_CustomReports</div>
                  <mat-icon [tooltip]="'reports_SavedViewsInfo' | translate" class="info material-icons-outlined">info </mat-icon>
                </div>
                <div class="m-l-15 m-b-20" translate>reports_SelectReport</div>
                <div class="saved-report-container">
                  <app-custom-reports-list [savedReports]="savedReports"></app-custom-reports-list>
                </div>
              </div>
            </cv-smart-panel>

            <div class="grid-container" *ngIf="reportDefinition">
              <app-datagrid
                  #gridSummary
                  [getItems]="getSummaryItems"
                  [hideColumnsSelector]="true"
                  [gridDefinition]="reportDefinition"
                  [savedReportFilterSortModel]="savedReportFilterSortModel"
                  (metadataChanged)="metadata = $event"
                  (rowSelected)="rowSelectedSummary()"
                  style="flex: 1"
                ></app-datagrid>
            </div>

            <app-management-panel
              [targetEntity]="reportDefinition?.targetEntity"
              [collectionName]="reportDefinition?.dbCollectionName"
              [reportDefinition]="reportDefinition"
              [entityIdField]="reportDefinition?.entityIdField"
              [reportTitle]="reportDefinition?.title"
              [workflowItemsSelectionColumnDefinition]="managementColumnDefinition"
              [selectedRows]="selectedRowsSummary"
              [tagsByDefault]="reportDefinition?.actionsTags || []"
            ></app-management-panel>
          </div>
        </div>
      </cv-tab>

      <cv-tab title="{{ 'licenses_Detail' | translate }}">
        <div class="tab-container">
          <div class="main-container" [ngClass]="!canSeeLeftPanel() ? 'mainContainer-two' : 'mainContainer-three'" *ngIf="tabSelectedIndex === 1">
            <cv-smart-panel *ngIf="canSeeLeftPanel()" [title]="'reports_CustomReports' | translate" style="padding-top: 10px">
              <div style="cursor: default" content>
                <div class="title-container">
                  <div class="title" translate>reports_CustomReports</div>
                  <mat-icon [tooltip]="'reports_SavedViewsInfo' | translate" class="info material-icons-outlined">info </mat-icon>
                </div>
                <div class="m-l-15 m-b-20" translate>reports_SelectReport</div>
                <div class="saved-report-container">
                  <app-custom-reports-list [savedReports]="savedReports"></app-custom-reports-list>
                </div>
              </div>
            </cv-smart-panel>

            <div class="grid-container" *ngIf="reportDefinition">
              <app-datagrid
                  #gridDetail
                  [getItems]="getDetailItems"
                  [hideColumnsSelector]="true"
                  [gridDefinition]="reportDefinition"
                  [savedReportFilterSortModel]="savedReportFilterSortModel"
                  (metadataChanged)="metadata = $event"
                  (rowSelected)="rowSelectedDetail()"
                  style="flex: 1"
              ></app-datagrid>
            </div>

            <app-management-panel
              [targetEntity]="reportDefinition?.targetEntity"
              [collectionName]="reportDefinition?.dbCollectionName"
              [reportDefinition]="reportDefinition"
              [entityIdField]="reportDefinition?.entityIdField"
              [reportTitle]="reportDefinition?.title"
              [workflowItemsSelectionColumnDefinition]="managementColumnDefinition"
              [selectedRows]="selectedRowsDetail"
              [tagsByDefault]="reportDefinition?.actionsTags || []"
            ></app-management-panel>
          </div>
        </div>
      </cv-tab>
    </cv-horizontal-tab-group>
  </div>

/* eslint-disable @typescript-eslint/member-ordering */
import { FilterReportColumn, SelectionAction } from '@app/core/models/ReportDefinition'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import { LocalstorageService } from './../../../core/services/localstorage.service'
import { PaginationComponent } from './../pagination/pagination.component'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import {
  ColDef,
  ColumnApi,
  ColumnGroupOpenedEvent,
  ColumnValueChangedEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  ICellRendererComp,
  ICellRendererFunc,
  MenuItemDef,
  ProcessCellForExportParams,
  SideBarDef,
  SortDirection,
} from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { of, Subject } from 'rxjs'
import { Constants } from '@app/shared/utilities/constants'
import { BuildColAgGrid, BuildColParameter } from '@app/shared/utilities/build-col-ag-grid'
import { LibBuildColAgGrid, Helpers } from '@coreview/coreview-library'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import { Store } from '@ngrx/store'
import { RootState } from '@app/store/RootState.type'
import { selectUserSettings, selectUserSettingsColumnsByEntity } from '@app/store/userSettings/userSettings.selectors'
import { distinctUntilChanged, takeUntil } from 'rxjs/operators'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { GridChartsModule } from '@ag-grid-enterprise/charts'
import { ActivatedRoute } from '@angular/router'
import { cloneDeep, isEqual, sumBy } from 'lodash-es'
import { UserSettingColumn } from '@coreview/coreview-library/models/user-settings'
import { ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper'
import { UserSettingsService } from '@app/core/services/user-settings.service'

@Component({
  selector: 'app-client-datagrid',
  templateUrl: './client-datagrid.component.html',
  styleUrls: ['./client-datagrid.component.sass'],
})
export class ClientDatagridComponent implements OnInit, OnDestroy {
  @Input() responseItemProp!: string
  @Input() columnsParameters!: BuildColParameter
  @Input() autosizeColumns?: string[]
  @Input() autosizeAllColumns?: boolean
  @Input() gridOptions!: GridOptions
  @Input() hideColumnsSelector = false
  @Input() customFrameworkComponents?: any
  @Input() detailCellRenderer!: string | (new () => ICellRendererComp) | ICellRendererFunc
  @Input() detailCellRendererParams: any
  @Input() selectionActions: SelectionAction[] = []
  @Input() groupedActions: SelectionAction[] = []
  @Input() filterActions: SelectionAction[] = []
  @Input() entityName?: string
  @Input() autoHeight = false
  @Input() columnDefsBuiltExternally?: ColDef[]
  @Input() cvDataTest = 'client-datagrid'
  @Input() lockedColumns?: string[]
  @Input() enablePivotMode = false
  @Input() pivotModeActive = false
  @Input() rowSelection?: 'single' | 'multiple' = 'multiple'
  @Input() rowMultiSelectWithClick = true
  @Input() hidePagination = false
  @Input() filters?: FilterReportColumn
  @Input() getRowStyle?: (params: any) => any = () => undefined
  @Input() keepDefaultCols = false
  @Output() gridReady = new EventEmitter<any>()
  @Output() rowSelected = new Subject<{ rowData: any; isSelected: boolean }>()
  @Output() pivotModeChanged = new EventEmitter<any>()
  @Output() metadataChanged = new EventEmitter<any>()
  @Output() public pageDataChanged = new EventEmitter<{ currentPage: number; currentPageSize: number; data: any[]; dataChart: any }>()

  @ViewChild(PaginationComponent)
  pagination!: PaginationComponent

  public columnDefs!: (ColDef & CoreViewColumn)[]
  public defaultCols!: (ColDef & CoreViewColumn)[]
  public gridApi!: GridApi
  public columnApi!: ColumnApi
  public paginationPageSize = Constants.paginationPageSize
  public cacheBlockSize = Constants.cacheBlockSize
  defaultColDef = Constants.defaultColumnsDefinition
  sideBar: SideBarDef | undefined = undefined
  originalColumnsParameters!: BuildColParameter

  frameworkComponents: any
  modules = [
    ClientSideRowModelModule,
    SetFilterModule,
    MasterDetailModule,
    ColumnsToolPanelModule,
    CsvExportModule,
    ExcelExportModule,
    RangeSelectionModule,
    ClipboardModule,
    MenuModule,
    GridChartsModule,
  ]

  userSettingsColumns?: UserSettingColumn[] = []
  public selectedColumnsChanged$: Subject<{ newColumns: (ColDef & CoreViewColumn)[]; visibilityUpdated: boolean }> = new Subject<{
    newColumns: (ColDef & CoreViewColumn)[]
    visibilityUpdated: boolean
  }>()

  private isSavedReport = false
  private destroyed$: Subject<boolean> = new Subject()

  constructor(
    private localstorageService: LocalstorageService,
    private buildColumnsHelper: BuildColAgGrid,
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private reportsComponentHelper: ReportsComponentHelper,
    private userSettingsService: UserSettingsService
  ) {
    this.frameworkComponents = buildColumnsHelper.frameworkComponents
    this.isSavedReport = !!this.activatedRoute.snapshot.queryParams.SavedReportId
  }

  @Input() isRowMaster: (data: any) => boolean = () => false
  @Input() getItems: () => any = () => of(null)
  @Input() manageError!: { propToCheck: string; messageProp: string }

  errorMessageToShowInTable!: string

  stopUpdate = false

  getRowData = () => {
    this.gridApi?.showLoadingOverlay()
    this.getItems().subscribe((response: any) => {
      if (response) {
        this.metadataChanged.emit(response.metadata)

        if (this.manageError && response[this.manageError.propToCheck]) {
          this.errorMessageToShowInTable = response[this.manageError.messageProp]

          this.gridApi?.setRowData([])
          this.gridApi?.hideOverlay()
          return
        }

        this.errorMessageToShowInTable = ''
        const model = this.gridApi?.getFilterModel()
        this.gridApi?.setRowData(this.responseItemProp ? response[this.responseItemProp] : response)
        this.gridApi?.setFilterModel(model)
        if (this.columnDefsBuiltExternally) {
          this.gridApi?.setColumnDefs(this.columnDefsBuiltExternally)
        } else if (response.configs && !this.keepDefaultCols && !this.enablePivotMode) {
          this.columnsParameters = {
            allcols: response.configs,
            selectedCols: response.metadata.fields,
            isClientDataGrid: true,
          }
          this.columnDefs = this.buildColumnsHelper.buildCols(
            this.columnsParameters,
            undefined,
            this.localstorageService.selectedOrganization?.id,
            this.userSettingsColumns,
            this.lockedColumns
          )
          this.gridApi?.setColumnDefs(this.columnDefs)
        }
        if (response.metadata?.totalCount === 0) {
          this.gridApi?.showNoRowsOverlay()
        } else {
          this.gridApi?.hideOverlay()
        }
        this.rowSelected.next()
      } else {
        this.gridApi?.hideOverlay()
      }
    })
  }

  pageChanged($event: any) {
    this.manageColumnsWidth()
    this.pagination?.pageChanged()
    if ($event.newData) {
      const data: any[] = []

      const currentPage = 0
      const currentPageSize = this.gridApi.paginationGetRowCount()
      for (let index = currentPage * currentPageSize; index < (currentPage + 1) * currentPageSize; index++) {
        const row = this.gridApi?.getModel().getRow(index)
        if (row?.data) {
          data.push(row.data)
        }
      }
      this.pageDataChanged.emit({
        currentPage,
        currentPageSize,
        data,
        dataChart: null,
      })
    }
  }

  ngOnInit(): void {
    this.store.select(selectUserSettings).pipe(takeUntil(this.destroyed$)).subscribe((userSettings) => {
      const rowsPerPagePreference = this.userSettingsService.getRowsPerPagePreference(userSettings, this.pivotModeActive)
      this.paginationPageSize = rowsPerPagePreference
      this.cacheBlockSize = rowsPerPagePreference
    })

    this.originalColumnsParameters = cloneDeep(this.columnsParameters)
    this.frameworkComponents = { ...this.buildColumnsHelper.frameworkComponents, ...(this.customFrameworkComponents || []) }

    this.setInitialColumns()

    this.sideBar = this.enablePivotMode
      ? {
          defaultToolPanel: 'columns',
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: false,
                suppressValues: false,
                suppressPivots: false,
                suppressPivotMode: true,
                suppressColumnFilter: false,
                suppressColumnSelectAll: false,
                suppressColumnExpandAll: false,
              },
            },
          ],
          position: 'left',
          hiddenByDefault: false,
        }
      : undefined

    this.gridOptions = {
      ...(this.gridOptions || {}),
      accentedSort: true,
      ...Constants.defaultGridOptions,
    }

    if (this.gridOptions.defaultColDef) {
      this.defaultColDef = this.gridOptions.defaultColDef
    }

    if (this.enablePivotMode) {
      this.gridOptions = {
        ...this.gridOptions,
        enableCharts: true,
        onColumnEverythingChanged: (a: any) => {
          if (!this.stopUpdate) this.updateColDefs()
        },
      }
    }
  }

  updateColDefs() {
    if (!this.gridApi) return
    this.stopUpdate = true

    const colDefs = this.gridApi?.getColumnDefs() || []
    colDefs.forEach((v: any) => {
      const col = this.columnsParameters.allcols.find((c: any) => c.name === v.colId)

      if (v.aggFunc === 'sum' || v.aggFunc === 'avg' || v.aggFunc === 'count') {
        v.filter = 'agNumberColumnFilter'
        v.filterParams = { ...v.filterParams, filterOptions: this.buildColumnsHelper.filterOptionsNumber.filterOptions }
      } else {
        v.filter = 'agTextColumnFilter'
        if (col?.type === 'bool')
          v.filterParams = { ...v.filterParams, filterOptions: this.buildColumnsHelper.filterOptionsBool.filterOptions }
        else v.filterParams = { ...v.filterParams, filterOptions: this.buildColumnsHelper.filterOptionsString.filterOptions }
      }
    })
    this.gridApi?.setColumnDefs(colDefs)

    setTimeout(() => {
      this.stopUpdate = false
    }, 500)
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  onGridReady(params: any) {
    this.gridApi = params.api
    this.columnApi = params.columnApi
    this.getRowData()
  }

  firstDataRendered() {
    this.gridReady.emit()
  }

  rowDataChanged = () => {
    this.manageColumnsWidth()
  }

  manageColumnsWidth = () => {
    if (this.autosizeColumns && this.autosizeColumns.length > 0) {
      this.columnApi?.autoSizeColumns(this.autosizeColumns)
    } else if (this.autosizeAllColumns) {
      this.columnApi?.autoSizeAllColumns()
    } else {
      this.gridApi?.sizeColumnsToFit()
    }
    this.resizeColumnsToFit()
  }

  refresh() {
    this.getRowData()
  }

  reloadColumns() {
    this.setColumnsDefs()
  }

  refreshRowModel = () => {
    this.gridApi.refreshClientSideRowModel()
    this.gridApi.setColumnDefs(this.columnDefs)
    this.manageColumnsWidth()    
  }

  getSelectedRow(): any[] {
    return this.gridApi?.getSelectedRows()
  }

  gridSizeChanged = () => {
    this.manageColumnsWidth()
  }

  pageSizeChanged(pageSize: number) {
    this.userSettingsService.updateExtraUserSettings('rowsPerPagePreference', pageSize).subscribe()
    this.gridApi?.paginationSetPageSize(pageSize)
  }

  filterChanged(event: any) {
    if (event.api.rowModel.rowsToDisplay?.length === 0) {
      this.gridApi.showNoRowsOverlay()
    } else {
      this.gridApi.hideOverlay()
    }
  }

  sortChanged(event: any) {
    if (['uiColumnSorted'].includes(event.source) && !this.enablePivotMode) {
      this.selectedColumnsChanged$.next({
        newColumns: LibBuildColAgGrid.getUpdatedVisibleColumnsBySorting(this.columnApi),
        visibilityUpdated: false,
      })
    }
  }

  columnMoved(event: any) {
    if (['uiColumnDragged', 'uiColumnMoved'].includes(event.source) && !this.enablePivotMode) {
      this.selectedColumnsChanged$.next({
        newColumns: LibBuildColAgGrid.getUpdatedVisibleColumns(this.columnApi),
        visibilityUpdated: false,
      })
    }
  }

  columnVisible(event: any) {
    if (['uiColumnDragged', 'uiColumnMoved'].includes(event.source) && !this.enablePivotMode) {
      this.selectedColumnsChanged$.next({ newColumns: LibBuildColAgGrid.getUpdatedVisibleColumns(this.columnApi), visibilityUpdated: true })
      this.resizeColumnsToFit()
    }
  }
  

  onRowSelected(event: any) {
    this.rowSelected.next({ rowData: event.data, isSelected: event.node.isSelected() })
  }

  onPivotModeChange = (val: boolean) => {
    this.pivotModeChanged.emit(val)
  }

  onColumnGroupOpened = (event: ColumnGroupOpenedEvent) => {
    this.manageColumnsWidth()
  }

  getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    if (!params.node) {
      return []
    }
    const pivotMenu = ['separator', 'pivotChart']
    let menu: (string | MenuItemDef)[] = this.buildColumnsHelper.getContextMenuItems(this.gridApi)
    if (this.enablePivotMode) {
      menu = menu.concat(pivotMenu)
    }
    return menu
  }

  processCellForClipboard = (params: ProcessCellForExportParams) => this.buildColumnsHelper.processCellForClipboard(params)

  onColumnValueChanged(event: ColumnValueChangedEvent) {
    const { column } = event
    if (!column) return

    const aggFunc = column.getAggFunc()
    const affectedColDef = this.columnDefs.find((colDef) => colDef.field === column.getColDef().field)
    const filter = aggFunc === 'first' || aggFunc === 'last' ? 'agTextColumnFilter' : 'agNumberColumnFilter'
    if (filter !== column.getColDef().filter && affectedColDef) {
      event.api.destroyFilter(column)
      affectedColDef.filter = filter
      const state = event.columnApi.getColumnState()
      event.api.setColumnDefs(this.columnDefs)
      event.columnApi.applyColumnState({ state, applyOrder: true })
    }
  }

  public autoGroupColumnDef: ColDef = {
    comparator: (v1, v2, r1) => {
      if (!r1.group) return 0
      if (!v1) return -1
      if (!v2) return 1
      const comp = v1.localeCompare(v2)
      return comp
    },
    minWidth: 250,
  }

  public postSortRows(params: any) {
    LibBuildColAgGrid.postSortRows(params)
  }

  exportClientReport = (fileName: string, key: string) => {
    const columnKeys = this.reportsComponentHelper.getVisibleFields(this.columnApi)?.map(Helpers.downcase)

    if (key) {
      if (key === 'xlsx') {
        this.gridApi.exportDataAsExcel({ fileName, columnKeys })
      } else if (key === 'csv') {
        this.gridApi.exportDataAsCsv({ fileName, columnKeys })
      }
    }
  }

  private resizeColumnsToFit() {
    if (!this.gridApi) {
      return
    }
    const panel = (this.gridApi as any).gridBodyCtrl
    const availableWidth = panel.eBodyViewport.clientWidth
    const displayedColumns = this.columnApi.getAllDisplayedColumns()
    const usedWidth = sumBy(displayedColumns, 'actualWidth')

    if (usedWidth < availableWidth) {
      this.gridApi.sizeColumnsToFit()
    }
  }

  private setInitialColumns() {
    if (!!this.entityName && !this.isSavedReport) {
      this.store
        .select(selectUserSettingsColumnsByEntity(this.entityName))
        .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
        .subscribe((columns) => {
          if (columns) {
            this.userSettingsColumns = columns
            this.setColumnsParameters()
          } else {
            this.setColumnsDefs()
          }
        })
    } else {
      this.setColumnsDefs()
    }
  }

  private setColumnsParameters() {
    if (this.columnsParameters) {
      this.columnsParameters.allcols.forEach((c) => {
        if (c.originalName) {
          c.position = this.userSettingsColumns?.find((usColumn) => usColumn.originalName === c.originalName)?.position
        }
      })
      let newSelectedCols = this.userSettingsColumns?.map((x) => x.originalName).filter((x) => !!x)
      if (!isEqual(this.columnsParameters.selectedCols, newSelectedCols)) {
        this.columnsParameters.selectedCols = newSelectedCols
        this.setColumnsDefs()
      }
    }
  }

  private setColumnsDefs() {
    this.columnDefs = this.columnDefsBuiltExternally
      ? (this.columnDefsBuiltExternally as (ColDef & CoreViewColumn)[])
      : this.buildColumnsHelper.buildCols(
          this.columnsParameters,
          undefined,
          this.localstorageService.selectedOrganization?.id,
          this.userSettingsColumns,
          this.lockedColumns
        )
    if (this.enablePivotMode) {
      this.addPivotAttributes(this.columnDefs)
    }

    if (this.userSettingsColumns?.some((col) => col.sort)) {
      this.columnDefs.forEach((col) => {
        col.sort = undefined
        col.sortIndex = undefined
      })
    }

    if (this.userSettingsColumns) {
      this.addUserSettingAttributes(this.columnDefs)
    }

    if (this.keepDefaultCols) {
      this.defaultCols = this.columnDefsBuiltExternally
        ? (this.columnDefsBuiltExternally as (ColDef & CoreViewColumn)[])
        : this.buildColumnsHelper
            .buildCols(this.originalColumnsParameters, undefined, this.localstorageService.selectedOrganization?.id, [], this.lockedColumns)
            .filter((x) => !x.hide)
    } else {
      this.defaultCols = this.columnDefs.filter((x) => !x.hide)
    }

    this.columnApi?.setColumnsVisible(
      this.defaultCols.map((x) => x.field || ''),
      true
    )
  }

  private addUserSettingAttributes = (cols: (ColDef & CoreViewColumn)[]) => {
    cols.forEach((col) => {
      if (col.originalName) {
        const found = this.userSettingsColumns?.find((c) => c.originalName.toLowerCase() === col.originalName.toLowerCase())
        if (found) {
          if (found.position !== undefined) col.position = found.position as any
          if (found.pinned) col.pinned = found.pinned as any
          if (found.sort) col.sort = found.sort as SortDirection
          if (found.sortIndex !== undefined) col.sortIndex = found.sortIndex
        }
      }
    })
  }

  //#region Pivot
  private addPivotAttributes = (cols: (ColDef & CoreViewColumn)[]) => {
    let isRowColSetted = false
    cols.forEach((col) => {
      col.hide = false
      col.enablePivot = true
      col.enableRowGroup = true
      col.enableValue = true
      if (col.originalName?.toLowerCase() === 'userprincipalname') {
        col.rowGroup = true
        isRowColSetted = true
        col.aggFunc = 'count'
      }
    })
    if (!isRowColSetted && cols.length > 0) {
      cols[0].rowGroup = true
      cols[0].aggFunc = 'count'
    }
  }
}

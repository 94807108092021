import { IHeaderAngularComp } from '@ag-grid-community/angular'
import { Events, IHeaderParams, IRowNode } from '@ag-grid-community/core'
import { Component } from '@angular/core'
import { ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper'
import { TranslateHelper } from '@coreview/coreview-library'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-select-all-header',
  templateUrl: './select-all-header.component.html',
  styleUrls: ['./select-all-header.component.sass'],
})
export class SelectAllHeaderComponent implements IHeaderAngularComp {
  indeterminate = false
  checked = false
  params!: IHeaderParams & {
    keepSelectionBetweenPages?: boolean;
    expandAllOnSelectAll?: boolean;
    getAllFilteredItems: () => Observable<any>;
    rowIdField: string;
  }

  isServerSide = false
  isExpanding = false

  selectionActions: { key: string; text: string; icon: string; iconPosition: 'left' | 'right' }[]

  constructor(private translateHelper: TranslateHelper, private reportsComponentHelper: ReportsComponentHelper) {
    this.selectionActions = []

  }

  agInit(
    params: IHeaderParams & {
      keepSelectionBetweenPages?: boolean;
      expandAllOnSelectAll?: boolean;
      getAllFilteredItems: () => Observable<any>;
      rowIdField: string;
    }
  ): void {
    this.params = params
    this.isServerSide = this.params.api.getModel().getType() === 'serverSide'

    this.UpdateSelectionActions()

    params.api.addEventListener('paginationChanged', (event: any) => {
      this.UpdateSelectionActions()

      if (event.newData || event.newPage) {
        if (!this.params.keepSelectionBetweenPages && this.isServerSide) {
          params.api.deselectAll()
        } else {
          this.checkSelectAll()
        }
      } else {
        if (this.params.api.getRenderedNodes()?.length && !!this.params.api.getRenderedNodes()[0].data) {
          this.checkSelectAll()
        }
      }
    })

    params.api.addEventListener('rowSelected', () => this.checkSelectAll())

    params.api.addEventListener('filterChanged', () => {
      if (!this.params.keepSelectionBetweenPages && this.isServerSide) {
        params.api.deselectAll()
      } else if (!this.isServerSide) {
        // server side check is done on paginationChanged event
        // as filterChanged arrives too early
        this.checkSelectAll()
      }
    })
  }

  refresh(params: IHeaderParams): boolean {
    return true
  }

  selectThisPage(event: boolean) {
    if (!this.isServerSide) {
      if (event) {
        this.params.api.selectAllOnCurrentPage()
      } else {
        this.params.api.deselectAllOnCurrentPage()
      }
    } else {
      this.getCurrentPageRows().filter((node) => node.selectable).forEach((node) => node.setSelected(event))
    }
  }

  getSelectedRowsText() {
    const count = this.reportsComponentHelper.getSelectedRowsCount(this.params.api)
    if (count === 0) {
      return ''
    }
    return count === 1 ? this.translateHelper.instant('common_CountItemSelectedSingular', { count }) : this.translateHelper.instant('common_CountItemsSelectedPlural', { count })
  }

  handleSelectThisPage() {
    const currentPageNodes = this.getCurrentPageRows().filter(x => x.selectable)
    const currentPageSelectedNodes = currentPageNodes.filter(row => row.isSelected())
    const isEveryRowInThisPageSelected = !!currentPageSelectedNodes.length && currentPageSelectedNodes.length === currentPageNodes.length
    this.selectThisPage(!isEveryRowInThisPageSelected)
  }

  handleSelectionActions = (action: string): void => {
    switch (action) {
      case 'selectAllPages':
        this.clearSelection()
        if (this.isServerSide) {
          this.params.getAllFilteredItems().subscribe((x) => {
            if (x?.rowData?.length) {
              this.params.api.setServerSideSelectionState({
                selectAll: false,
                toggledNodes: x.rowData.map((rowData: any) => rowData[this.params.rowIdField]),
              })
            }
          })
        } else {
          this.params.api.selectAllFiltered()
        }
        break
      case 'selectThisPage':
        this.selectThisPage(true)
        break
      case 'selectNone':
        this.clearSelection()
        this.checkSelectAll()
        break
    }
  }

  private checkSelectAll() {
    let everySelected = true
    let everyDeselected = true
    if (!this.isServerSide) {
      this.params.api.forEachNodeAfterFilter((n: IRowNode<any>) => {
        if (n.isSelected()) {
          everyDeselected = false
        } else {
          everySelected = false
        }
      })

      this.indeterminate = !everySelected && !everyDeselected
      this.checked = everySelected
        && (this.params.api.paginationGetRowCount() !== 0 
            // this is a safenet for the case where the pagination is off and 'paginationGetRowCount- returns
            || this.params.api.getDisplayedRowCount() !== 0)
    } else {
      const serverSideSelectedNodes = this.params.api.getServerSideSelectionState()?.toggledNodes ?? []
      this.indeterminate = !!serverSideSelectedNodes.length && serverSideSelectedNodes.length !== this.params.api.paginationGetRowCount()
      this.checked = !!serverSideSelectedNodes.length && serverSideSelectedNodes.length > 0
    }
  }

  private getCurrentPageRows() {
    const rows = []
    const currentPage = this.params.api?.paginationGetCurrentPage()
    const currentPageSize = this.params.api?.paginationGetPageSize()
    for (let index = currentPage * currentPageSize; index < (currentPage + 1) * currentPageSize; index++) {
      const row = this.params.api.getModel().getRow(index)
      if (row) {
        rows.push(row)
      }
    }
    return rows
  }

  private clearSelection() {
    if (this.isServerSide) {
      this.params.api.setServerSideSelectionState({
        selectAll: false,
        toggledNodes: [],
      })

      const rowSelected = {
        type: Events.EVENT_ROW_SELECTED,
        api: this.params.api,
        columnApi: this.params.columnApi,
        data: {},
        node: {
          isSelected: () => false
        }
      }
      this.params.api.dispatchEvent(rowSelected)
    } else {
      this.params.api.deselectAll()
    }
  }

  private UpdateSelectionActions() {
    const commonActions = [
      {
        key: 'selectThisPage',
        text: this.translateHelper.instant(this.params.api.paginationGetTotalPages() > 1 
          ? 'common_SelectThisPage' 
          : 'common_SelectAll'),
        icon: 'close',
        iconPosition: 'left'as 'left' | 'right',
      },
      {
        key: 'selectNone',
        text: this.translateHelper.instant('common_DeselectAll'),
        icon: 'restore',
        iconPosition: 'left'as 'left' | 'right',
      },
    ];
  
    if (this.params.api.paginationGetTotalPages() > 1) {
      this.selectionActions = [
        {
          key: 'selectAllPages',
          text: this.translateHelper.instant('common_SelectAllPages'),
          icon: 'check',
          iconPosition: 'left' as 'left' | 'right',
        },
        ...commonActions, 
      ];
    } else {
      this.selectionActions = commonActions;
    }
  }
}

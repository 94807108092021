import { createAction, props } from '@ngrx/store'
import { ManagedTenant } from '@app/core/models/ManagedTenant'

/**
 * Action types related to the `tenants` state
 */
export const getManagedTenants = createAction('[Tenants] GET request')
export const getManagedTenantsResponse = createAction(
  '[Tenants] GET resp',
  props<{ managedTenants: ManagedTenant[]; defaultTenant: ManagedTenant; selected?: string | null }>()
)
export const getManagedTenantsInvalidate = createAction('[Tenants] GET invalidate', props<{ error: string }>())

export const requestManagedTenantChange = createAction('[Tenants] request managed tenant change', props<{ tenant: ManagedTenant }>())
export const requestTenantSessionSuccess = createAction('[Tenants] request tenant session changed', props<{ tenant: ManagedTenant }>())
export const changeManagedTenantSuccess = createAction('[Tenants] managed tenant successfully changed', props<{ tenant: ManagedTenant }>())
export const changeManagedTenantInvalidate = createAction('[Tenants] managed tenant change invalidate', props<{ error: string }>())

import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { AdministrationsService } from '@app/core/services/administrations.service'
import { ApplicationInsightService } from '@app/core/services/application-insight.service'
import { ReportsService } from '@app/core/services/reports.service'
import { IBeforeClose, RightPanelRef } from '@app/core/services/right-panel.service'
import { PanelStep } from '@app/shared/models/panel-step'
import { TranslateHelper } from '@coreview/coreview-library'
import { Suggestion, ToastService } from '@coreview/coreview-components'
import { ServerResponse } from '@app/core/models/ServerResponse'
import { combineLatest, Observable, of } from 'rxjs'
import { pluck, take } from 'rxjs/operators'
import { AccountSkuDetails, AccountSkuDetailsAllocator } from '@app/core/models/AccountSkuDetails'
import { TargetSelectionDefinition } from '@app/shared/components/target-selection/target-selection.component'
import { PanelStepsComponent } from '@app/shared/components/panel-steps/panel-steps.component'
import { DialogHelperService } from '@app/shared/dialog-helper.service'

@Component({
  selector: 'app-manage-price-per-unit',
  templateUrl: './manage-price-per-unit.component.html',
  styleUrls: ['./manage-price-per-unit.component.sass'],
})
export class ManagePricePerUnitComponent extends IBeforeClose implements OnInit {
  @ViewChild(PanelStepsComponent) set panelStepsComp(panelSteps: PanelStepsComponent) {
    if (panelSteps) {
      this.panelSteps = panelSteps
    }
  }

  @Input() configuration!: { targetSelectionDefinition: TargetSelectionDefinition; targetSelectionConfiguration: any }

  selectedTargetsObject: any
  isSaving = false
  allocatorData: any = {}
  licensesToSave: any[] = []
  submitted = false
  form!: UntypedFormGroup
  currencies!: Suggestion[]

  panelSteps?: PanelStepsComponent
  steps: PanelStep[] = []

  allocatorProps: { keyCurrent: string; keyNew: string; titleCurrent: string; titleNew: string }[] = [
    {
      keyCurrent: 'pricePerUnit',
      keyNew: 'pricePerUnitNew',
      titleCurrent: 'licenses_CurrentPricePerUnit',
      titleNew: 'licenses_NewPricePerUnit',
    },
    { keyCurrent: 'currency', keyNew: 'currencyNew', titleCurrent: 'licenses_CurrentCurrency', titleNew: 'licenses_NewCurrency' },
  ]

  constructor(
    private translateHelper: TranslateHelper,
    private administrationsService: AdministrationsService,
    private rightPanelRef: RightPanelRef,
    private toastService: ToastService,
    private reportsService: ReportsService,
    private appInsights: ApplicationInsightService,
    private dialogHelperService: DialogHelperService
  ) {
    super()
  }

  ngOnInit(): void {
    this.steps = [
      {
        title: this.translateHelper.instant('licenses_SelectTarget'),
        status: 'Active',
        isRequired: true,
        sort: 0,
        stepKey: 'licenses_SelectTarget',
        isValid: () => this.selectedTargetsObject?.licenses?.length > 0,
      },
      {
        title: this.translateHelper.instant('licenses_SetPrice'),
        status: 'Active',
        sort: 1,
        isRequired: true,
        stepKey: 'licenses_SetPrice',
        isValid: () => !!this.form?.valid,
      },
      {
        title: this.translateHelper.instant('common_ReviewAndComplete'),
        status: 'Active',
        onEnterStep: this.onEnterReviewAndComplete,
        sort: 2,
        isRequired: false,
        stepKey: 'complete',
        canProceed: this.canSubmit,
      },
    ]

    this.selectedTargetsObject = {
      licenses: [],
    }

    this.administrationsService.getCurrencies().subscribe((res) => {
      this.currencies = res.currencies.map((c: any) => ({ value: c.alp3IsoCode, displayValue: c.alp3IsoCode }))
    })

    this.configuration.targetSelectionDefinition.getTargetsToSelect = this.getClientGridData$()
  }

  getClientGridData$ = () => {
    const params: any = {
      pageSize: 10000,
      pageNumber: 1,
      sortField: this.configuration.targetSelectionDefinition.sortField,
      sortOrder: this.configuration.targetSelectionDefinition.sortOrder,
    }

    return this.getItems$(
      this.configuration.targetSelectionDefinition.url!,
      this.configuration.targetSelectionDefinition.verb,
      params
    ).pipe(pluck(this.configuration.targetSelectionDefinition.responseItemProp))
  }

  getItems$ = (reportUrl: string, verb: Verb, params: any, dataCenterUrl?: string) =>
    (this.reportsService.getData(reportUrl, verb, { ...params }, dataCenterUrl) || of({} as ServerResponse<any>))

  onSelectedTargetsChanged(selectedTargets: any) {
    if (this.panelSteps?.activeStep?.stepKey === 'licenses_SelectTarget') {
      this.selectedTargetsObject.licenses = selectedTargets
    }

    this.form = new UntypedFormGroup({})

    this.selectedTargetsObject.licenses.sort((a: AccountSkuDetails, b: AccountSkuDetails) => -a.pricePerUnit + b.pricePerUnit)

    this.selectedTargetsObject.licenses?.forEach((lp: any) => {
      this.allocatorProps.forEach((p: { keyCurrent: string; keyNew: string; titleCurrent: string; titleNew: string }) => {
        lp[p.keyNew] = lp[p.keyCurrent]

        this.form.addControl(lp.skuId + '_' + p.keyNew, new UntypedFormControl('', Validators.required))
      })
    })

    this.allocatorData = {
      items: this.selectedTargetsObject.licenses,
      props: this.allocatorProps,
      form: this.form,
      currencies: this.currencies,
      propId: 'skuId',
      propDisplay: 'sku',
    }
  }

  canSubmit = (): boolean => !this.isSaving && this.licensesToSave.length > 0 && this.form.valid

  save() {
    this.isSaving = true

    const calls: Observable<void>[] = []

    this.licensesToSave.forEach((l: AccountSkuDetailsAllocator) => {
      const license = { ...l }
      const licenseToSave = { accountSkuId: license.accountSkuId, pricePerUnit: license.pricePerUnitNew, currency: license.currencyNew }
      calls.push(this.administrationsService.updateAccountSku(licenseToSave))
    })

    combineLatest(calls)
      .pipe(take(1))
      .subscribe(
        () => {
          this.submitted = true
          this.rightPanelRef.close()
          this.toastService.open({
            id: 'succ',
            variant: 'success',
            title: this.translateHelper.instant('common_Success'),
          })
          this.isSaving = false
        },
        (error) => {
          this.toastService.open({
            id: 'err',
            variant: 'error',
            title: this.translateHelper.instant('common_Error'),
            message: error?.error?.responseStatus?.message || 'license_SubmitError',
          })
          this.isSaving = false
          this.appInsights.trackError(error)
        }
      )
  }

  clickTab(index: number) {
    this.panelSteps?.clickStep(this.steps[index])
  }

  canDeactivate(): boolean {
    return !this.form?.dirty || this.submitted;
  }

  beforeClose(): Observable<boolean> {
    if (this.submitted || this.canDeactivate()) {
      return of(true)
    }
    return this.dialogHelperService.openConfirmationDialog()
  }

  private onEnterReviewAndComplete = (): void => {
    this.licensesToSave = []
    this.allocatorData?.items?.forEach((lp: any) => {
      const lpToSave: any = {}
      this.allocatorProps
        .filter((prop: any) => lp[prop.keyCurrent] !== lp[prop.keyNew])
        .forEach((prop: any) => {
          lpToSave[prop.keyNew] = lp[prop.keyNew]
        })

      if (Object.keys(lpToSave).length) {
        this.licensesToSave.push({ ...lp, ...lpToSave })
      }
    })
  }
}

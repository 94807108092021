export enum WorkflowPath {
  root = 'workflow',
  list = 'list',
  scheduleList = 'schedules',
  templates = 'templates',
  builder = 'builder',
  approvals = 'approvals',
  customLists = 'custom-lists',
  QueueExecutions = 'queue-executions',
}

export const generateWorkflowPath = (...paths: string[]): string => paths.join('/')

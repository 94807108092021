import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ManagementAction } from '@app/core/models/management-action'
import { BaseItem } from '@app/core/models/universal-search'
import { BreadcrumbService, TranslateHelper } from '@coreview/coreview-library'
import { ManagementActionsService } from '@app/core/services/management-actions.service'
import { UniversalSearchService } from '@app/core/services/universal-search.service'
import { UserProfileService } from '@app/core/services/user-profile.service'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { RootState } from '@app/store/RootState.type'
import { selectManagementActionsFlat } from '@app/store/management-actions/management-actions.selectors'
import { selectedOrganizationSkus } from '@app/store/organizations/organizations.selectors'
import { Store } from '@ngrx/store'
import { difference } from 'lodash-es'
import { Subject, combineLatest, iif } from 'rxjs'
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators'
import { ButtonColour } from '@coreview/coreview-components'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
})
export class HomeComponent implements OnInit, OnDestroy {
  actionCard: HomeCard = {
    visible: false,
    groups: [],
    color: 'info',
    icon: 'auto_fix_high',
    title: 'common_QuickActions',
    hasButtonAction: true,
    leftIcon: 'visibility',
    actionButtonLabel: 'common_seeAllActions',
    onClick: (card: HomeCard) => {
      this.router.navigate(['management/actions']).catch((_: any) => _)
    },
  }
  reportCard: HomeCard = {
    visible: false,
    groups: [],
    color: 'info',
    title: 'common_MyTopReports',
    icon: 'analytics',
  }

  homecards: HomeCard[] = [this.reportCard]
  public loading = true
  private destroyed$: Subject<boolean> = new Subject()

  private readonly homeHealthCheckSku = 'FT:HOMEHEALTHCHECK'
  private readonly homeGovernanceSku = 'FT:HOMEGOVERNANCE'

  

  constructor(
    private breadcrumbService: BreadcrumbService,
    private universalSearchService: UniversalSearchService,
    private store: Store<RootState>,
    private translateHelper: TranslateHelper,
    private sharedHelperService: SharedHelperService,
    private router: Router,
    private shareHelper: SharedHelperService,
    private userProfileService: UserProfileService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.updatePath([])

    if (this.sharedHelperService.isManagement()) {
      this.homecards = this.homecards.concat(this.actionCard)
    }

    this.universalSearchService.getReportScores().subscribe((reports) => {
      if (!!reports && reports.length > 0) {
        this.createReportCard(reports)
        this.reportCard.visible = true
      }
    })

    this.store
      .select(selectManagementActionsFlat)
      .pipe(takeUntil(this.destroyed$))
      .pipe(
        tap((x) => {
          this.universalSearchService.setManagementAction(x)
        }),
        switchMap(() => iif(() => this.universalSearchService.managementActions.length > 0, this.universalSearchService.getActionScores())),
        map((actions) => actions.slice(0, 10))
      )
      .subscribe((actions) => {
        this.createTopActions(actions).subscribe((topActions) => {
          if (!!topActions && topActions.length > 0) {
            this.createActionCard(topActions)
            this.actionCard.visible = true
          }
        })
      })

      combineLatest([
        this.store.select(selectedOrganizationSkus).pipe(filter((portalSkus) => !!portalSkus?.length)).pipe(takeUntil(this.destroyed$)),
        this.userProfileService.getFirstPage(),
      ])        
        .subscribe(([portalSkus, firstPage]) => {          
          if (firstPage && firstPage !== '/') {
            this.router.navigateByUrl(firstPage).then((x) => {
              this.loading = false
            })
          } else  {
            const hasHomeHealthCheckSku = portalSkus!.includes(this.homeHealthCheckSku)
            const hasHomeGovernanceSku = portalSkus!.includes(this.homeGovernanceSku)
            let startUrl = ''
            if(hasHomeGovernanceSku) {
              startUrl = 'governance'
            } else {
              const isTenantAdmin = this.shareHelper.isAdmin()
              startUrl = isTenantAdmin && hasHomeHealthCheckSku ? 'healthcheck' : 'home'
            }
            this.router.navigate([startUrl]).then((x) => {
              this.loading = false
            })
          } 
        })     

  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  goToLink(url: string) {
    location.href = url
  }

  manageItem = (item: any) => {
    if (item) {
      this.universalSearchService.manageItem(item)
    }
  }
  onCardClick = (card: HomeCard) => {
    if (card.onClick) {
      card.onClick(card)
    }
  }

  private createTopActions = (actions: any) => {
    const diff = difference(ManagementActionsService.topPredefinedManagementActions, actions)
    const actionsMerged = [...actions, ...diff].slice(0, 10)
    return this.universalSearchService.funcsSeeAll.Actions().pipe(map((i) => i.items?.filter((x: any) => actionsMerged.includes(x.title))))
  }

  private createActionCard = (actions: ManagementAction[]) => {
    const group: CardGroup = { children: [] }
    actions.forEach((action: any) => {
      action.name = this.translateHelper.instant(`management_${action.title}`)
      group.children!.push(action)
    })
    if (group.children!.length > 0) {
      this.actionCard.groups.splice(0, this.actionCard.groups.length)
      this.actionCard.groups.push(group)
    }
  }

  private createReportCard = (
    reports: {
      name: string
      title: string
      classIcon: string
      type: string
      route: string
      parent: string
    }[]
  ) => {
    this.reportCard.groups.splice(0, this.actionCard.groups.length)
    reports.slice(0, 10).forEach((report: any) => {
      report.name = this.translateHelper.instant(report.title)
      const parent = this.universalSearchService.menuItems.find((x) => x.route === report.route)?.parent
      const parentGroup = this.reportCard.groups.find((x: any) => x.title === parent)
      if (!!parentGroup && !!parentGroup.children) {
        parentGroup.children.push(report)
      } else {
        this.reportCard.groups.push({
          title: parent,
          children: [report],
        })
      }
    })
  }
}

export type CardGroup = {
  title?: string
  children?: (Pick<BaseItem, 'name' | 'classIcon' | 'type' | 'parent'> & { route: string | null | undefined })[]
}

export type HomeCard = {
  title?: string
  icon?: string
  color: ButtonColour
  groups: CardGroup[]
  hasButtonAction?: boolean
  onClick?: (card: HomeCard) => void
  actionButtonLabel?: string
  leftIcon?: string
  visible: boolean
}

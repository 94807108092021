<app-datagrid-actions
  [hideColumnsSelector]="hideColumnsSelector"
  [columnDefs]="columnDefs"
  [defaultCols]="defaultCols"
  [columnApi]="columnApi"
  [gridApi]="gridApi"
  [actions]="selectionActions || []"
  [filterActions]="filterActions || []"
  [refresh]="refreshRowModel"
  [entityName]="entityName"
  [selectedColumnsChanged$]="selectedColumnsChanged$"
  [pivotModeEnabled]="enablePivotMode"
  [pivotMode]="true"
  (pivotModeChanged)="onPivotModeChange($event)"
  [groupedActions]="groupedActions || []"
  [lockedColumns]="lockedColumns"
  [filters]="filters"
  [getSelectedRows]="getSelectedRow"
  [rowSelected$]="rowSelected"
>
  <div toolbar>
    <ng-content select="[toolbar]"></ng-content>
  </div>
</app-datagrid-actions>

<cv-alert-bar
  *ngIf="errorMessageToShowInTable"
  variant="error"
  [title]="'Error' | translate"
  [hideCloseButton]="true"
>
  <span translate>{{errorMessageToShowInTable}}</span>
</cv-alert-bar>


<ag-grid-angular
  *ngIf="!errorMessageToShowInTable"
  style="width: 100%"
  class="ag-theme-alpine ag-theme-coreview"
  [ngClass]="{ 'single-selection': rowSelection === 'single' }"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [pagination]="hidePagination ? false : true"
  [paginationPageSize]="paginationPageSize"
  [cacheBlockSize]="cacheBlockSize"
  [animateRows]="true"
  [rowSelection]="rowSelection"
  [rowMultiSelectWithClick]="rowMultiSelectWithClick"
  (gridReady)="onGridReady($event)"
  (firstDataRendered)="firstDataRendered()"
  (rowDataChanged)="rowDataChanged()"
  (gridSizeChanged)="gridSizeChanged()"
  [components]="frameworkComponents"
  [gridOptions]="gridOptions"
  [suppressPaginationPanel]="true"
  [modules]="modules"
  (paginationChanged)="pageChanged($event)"
  [masterDetail]="!!detailCellRenderer"
  [isRowMaster]="isRowMaster"
  [detailCellRenderer]="detailCellRenderer"
  [detailCellRendererParams]="detailCellRendererParams"
  [detailRowAutoHeight]="true"
  [detailRowHeight]="30"
  [rowHeight]="32"
  [suppressColumnVirtualisation]="true"
  [overlayLoadingTemplate]="'common_GridLoading' | translate"
  [overlayNoRowsTemplate]="'common_NoRowsToShow' | translate"
  (filterChanged)="filterChanged($event)"
  (sortChanged)="sortChanged($event)"
  (columnMoved)="columnMoved($event)"
  (columnVisible)="columnVisible($event)"
  (columnPinned)="columnMoved($event)"
  [domLayout]="autoHeight ? 'autoHeight' : 'normal'"
  [attr.cv-data-test]="cvDataTest"
  [suppressDragLeaveHidesColumns]="true"
  (rowSelected)="onRowSelected($event)"
  [suppressPropertyNamesCheck]="true"
  [pivotMode]="enablePivotMode"
  [groupIncludeFooter]="enablePivotMode"
  [groupIncludeTotalFooter]="enablePivotMode"
  [sideBar]="sideBar"
  (columnGroupOpened)="onColumnGroupOpened($event)"
  [suppressClickEdit]="true"
  [suppressCopyRowsToClipboard]="true"
  [enableRangeSelection]="true"
  [keepDetailRows]="true"
  [getContextMenuItems]="getContextMenuItems"
  [processCellForClipboard]="processCellForClipboard"
  (columnValueChanged)="onColumnValueChanged($event)"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [getRowStyle]="getRowStyle"
  [suppressMultiSort]="true"
  [postSortRows]="postSortRows"
></ag-grid-angular>
<app-pagination
*ngIf="!errorMessageToShowInTable && !hidePagination"
(pageSizeChanged)="pageSizeChanged($event)" [gridApi]="gridApi" [pageSize]="''+paginationPageSize"></app-pagination>

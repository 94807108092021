import { TranslateHelper } from '@coreview/coreview-library'
import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { ReportsService } from '@app/core/services/reports.service'
import { Policy } from '@app/core/models/playbook'
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { Store } from '@ngrx/store'
import { RootState } from '@app/store/RootState.type'
import { selectOnlineuserColumns } from '@app/store/onlineuser-columns/onlineuser-columns.selectors'
import { map, switchMap, catchError } from 'rxjs/operators'
import { cloneDeep } from 'lodash-es'
import { of } from 'rxjs'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'
import { ServerResponse } from '@app/core/models/ServerResponse'
import { PlaybookService } from '../../services/playbook.service'
import { Helpers } from '@app/shared/utilities/helpers'
import dayjs from 'dayjs'

@Component({
  selector: 'app-policy-item-list',
  templateUrl: './policy-item-list.component.html',
  styleUrls: ['./policy-item-list.component.sass'],
})
export class PolicyItemListComponent implements OnInit {
  @Input()
  policy!: Policy
  @Input()
  reportDefinition!: ReportDefinition
  @Input()
  reportType!: 'matchedItems' | 'matchedExceptions'

  @Output()
  rowSelected = new EventEmitter<any>()
  @Output()
  columnDefsDefined = new EventEmitter<any>()
  @Output()
  modelUpdated = new EventEmitter<any>()
  @Output() gridReady = new EventEmitter<any>()
  @Output() metadataChanged = new EventEmitter<any>()

  @ViewChild(DatagridComponent)
  set datagrid(datagrid: DatagridComponent) {
    if (!!datagrid) {
      this.grid = datagrid
    }
  }

  grid!: DatagridComponent

  params!: ApiDataParameters

  constructor(
    private reportsService: ReportsService,
    private playbookService: PlaybookService,
    private store: Store<RootState>,
    private translateHelper: TranslateHelper
  ) {}

  ngOnInit(): void {
    if (this.reportType === 'matchedExceptions') {
      this.reportDefinition.operationsColumns = [
        ...(this.reportDefinition.operationsColumns || []),
        {
          type: 'string',
          name: 'exceptionExpirationDate',
          notSelectable: true,
          translate: 'ExceptionExpirationDate',
          agColDef: {
            field: 'exceptionExpirationDate',
          },
        },
        {
          type: 'string',
          name: 'exceptionNotes',
          notSelectable: true,
          translate: 'ExceptionNotes',
          agColDef: {
            field: 'exceptionNotes',
          },
        },
      ]
    }
    if (this.policy.policyType === 'EventBased') {
      this.reportDefinition.operationsColumns = []
    }
  }

  matchedItemsFilters = () => ({
    [this.policy.matchedReportFilters?.key || '']: this.policy.matchedReportFilters?.value || '',
    ...this.policy.reportFilters,
  })
  exceptionItemsFilters = () => ({
    [this.policy.exceptionReportFilters?.key || '']: this.policy.exceptionReportFilters?.value || '',
    ...this.policy.reportFilters,
  })

  getItems = (params: ApiDataParameters) => {
    if (!!this.policy?.urlParameters) {
      Object.assign(params, this.policy.urlParameters)
    }
    this.params = params
    return {
      items: (
        this.reportsService.getData(this.reportDefinition?.url || '', this.reportDefinition?.verb || 'get', params) ||
        of({} as ServerResponse<any>)
      ).pipe(
        switchMap((x) => {
          if (this.reportType === 'matchedExceptions') {
            return (
              this.policy.policyGroupType === 'LegacyPolicy'
                ? of([])
                : this.playbookService.getPolicyExceptions(
                    this.policy?.id || '',
                    x[this.reportDefinition.responseItemProp].map(
                      (i: any) => i[Helpers.downcase(this.policy.reportDefinition.entityIdField)]
                    )
                  )
            ).pipe(
              catchError(() => of([])),
              map((exceptions) => {
                x[this.reportDefinition.responseItemProp].forEach((d: any) => {
                  const exception = exceptions?.find((e) => e.entityId === d[Helpers.downcase(this.policy.reportDefinition.entityIdField)])
                  d.exceptionNotes = exception?.notes
                  d.exceptionExpirationDate = !exception
                    ? ''
                    : exception?.expirationDateUtc
                    ? dayjs(exception?.expirationDateUtc).utc(false).format('LL')
                    : this.translateHelper.instant('common_NeverExpires')
                  d.isNeverExpire = d.exceptionExpirationDate === this.translateHelper.instant('common_NeverExpires')
                })
                return x
              })
            )
          } else {
            return of(x)
          }
        })
      ),
      cols: this.reportDefinition.isOnlineUsersType
        ? this.store.select(selectOnlineuserColumns).pipe(map((cols) => cloneDeep(cols)))
        : of([]),
    }
  }
}

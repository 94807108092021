<div [formGroup]="form" [ngClass]="{'range-inline': displayInline}">
  <app-datetime-utc-picker style="margin: 10px 0" [isError]="startDatePast" formControlName="startDatetime" [disableUtc]="disableUtc" [displayInline]="displayInline" [required]="required"></app-datetime-utc-picker>
  <cv-alert-bar [hideCloseButton]="true" variant="error" *ngIf="startDatePast">{{ 'common_StartInPast' | translate }}</cv-alert-bar>

  <app-datetime-utc-picker
    style="margin: 10px 0"
    [dateLabel]="'reports_EndingOnDate'"
    [isError]="endDatePast || incompatibleDates"
    formControlName="endDatetime"
    [disableUtc]="disableUtc"
    [displayInline]="displayInline"
    [required]="required"
  ></app-datetime-utc-picker>
  <cv-alert-bar [hideCloseButton]="true" variant="error" *ngIf="endDatePast">
    {{ 'common_EndInPast' | translate }}
  </cv-alert-bar>

  <cv-alert-bar class="range-alert" [hideCloseButton]="true" variant="error" *ngIf="incompatibleDates">
    {{ 'common_IncompatibleDates' | translate }}
  </cv-alert-bar>
</div>

import { EventEmitter, Injectable } from '@angular/core'
import { ToolbarType } from '../models/toolbar/toolbar-type'

@Injectable({
  providedIn: 'root',
})
export class ToolbarService {
  toggleToolbarPanel$ = new EventEmitter<{ open: boolean; toolbarType: ToolbarType }>()

  open(toolbarPanelType: ToolbarType) {
    this.toggleToolbarPanel$.emit({ open: true, toolbarType: toolbarPanelType })
  }

  close(toolbarPanelType: ToolbarType) {
    this.toggleToolbarPanel$.emit({ open: false, toolbarType: toolbarPanelType })
  }
}

<mat-icon slot="button" class="copy-log" (click)="copyExecutionDetails(execDetails.innerText)"> content_copy </mat-icon>
<div class="executionDetail-container" #execDetails>
  <ng-container *ngIf="hasError">
    <h4 class="title">{{ 'common_ErrorTitle' | translate }}</h4>
    <div class="content">
      <div *ngIf="data.error">
        <span class="data-error">{{ data.error }}</span>
      </div>
      <div *ngIf="data.cause">
        <span class="data-cause">{{ data.cause }}</span>
      </div>
    </div>
  </ng-container>

  <ng-container>
    <h4 class="title">{{ 'workflow_Input' | translate }}</h4>

    <ng-container *ngIf="hasInput; else noDataAvailable">
      <div *ngIf="data.workflowId" class="content">
        <ng-container *ngFor="let item of inputItems">
          <app-execution-detail-input-item [key]="item.key" [value]="item.value"></app-execution-detail-input-item>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <h4 class="title">{{ 'workflow_Output' | translate }}</h4>

  <ng-container *ngIf="hasOutput; else noDataAvailable">
    <div *ngIf="data.workflowId" class="content">
      <ng-container *ngFor="let item of outputItems">
        <app-execution-detail-output-item
          [key]="item.key"
          [value]="item.value"
          [definitionActions]="definitionActions"
        ></app-execution-detail-output-item>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="data?.context; else noDataAvailable">
    <h4 class="title">{{ 'workflow_Context' | translate }}</h4>
    <div class="content">
      {{ data.context }}
    </div>
  </ng-container>
</div>

<ng-template #noDataAvailable>
  <div class="content">
    <h4>{{ 'workflow_ExecutionNoDataAvailable' | translate }}</h4>
  </div>
</ng-template>

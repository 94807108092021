/* eslint-disable max-len */
/* eslint-disable no-new-func */
import { NewCustomPolicyComponent } from './../../../modules/playbook/components/new-custom-policy/new-custom-policy.component'
import { PolicyDialogComponent } from './../../../modules/playbook/components/policy-dialog/policy-dialog.component'
import { PolicyComponent } from './../../../modules/playbook/components/policy/policy.component'
import { ReportsService } from './../../../core/services/reports.service'
import { EditPolicyComponent } from './../../../modules/playbook/components/edit-policy/edit-policy.component'
import { Component, Inject, ViewChild, Type, OnInit } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { BuildColParameter } from '@app/shared/utilities/build-col-ag-grid'
import { TranslateHelper } from '@coreview/coreview-library'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { ClientDatagridComponent } from '../client-datagrid/client-datagrid.component'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import { AddToExceptionComponent } from '@app/modules/playbook/components/add-to-exception/add-to-exception.component'
import { buffer, filter, map, debounceTime } from 'rxjs/operators'
import { SelectionAction } from '@app/core/models/ReportDefinition'
import * as lodash from 'lodash-es'
import dayjs from 'dayjs'
import { ToastService } from '@coreview/coreview-components'
import { CronExpressionHelper } from '@app/shared/utilities/cron-expression-helper'
import { TimeSavingDialogComponent } from '@app/modules/playbook/components/time-saving-dialog/time-saving-dialog.component'
import { WorkflowDialogComponent } from '@app/modules/playbook/components/workflow-dialog/workflow-dialog.component'
import { Store } from '@ngrx/store'
import { RootState } from '@app/store/RootState.type'
import { selectLastMessageOfType } from '@app/store/messages/messages.selectors'
import { Router } from '@angular/router'
import { WorkflowExecutionDetailsDialogComponent } from '@app/modules/playbook/components/workflow-execution-details-dialog/workflow-execution-details-dialog.component'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { PolicyListDetailsDialogComponent } from '@app/modules/healthcheck/components/policy-list-details-dialog/policy-list-details-dialog.component'

@Component({
  selector: 'app-client-data-grid-dialog',
  templateUrl: './client-data-grid-dialog.component.html',
  styleUrls: ['./client-data-grid-dialog.component.sass'],
})
export class ClientDataGridDialogComponent implements OnInit {
  @ViewChild(ClientDatagridComponent) dataGrid!: ClientDatagridComponent
  title: string
  subTitle: string
  columnParams!: BuildColParameter
  hideColumnSelector = true
  selectionActions!: SelectionAction[]
  filterActions!: SelectionAction[]
  detailCellRenderer!: string
  detailCellRenderParams!: any
  data!: any
  body!: any
  rawData: any[] = []
  parentRef!: any | undefined

  mapper: Record<string, Type<any>> = {
    'edit-policy': EditPolicyComponent,
    'custom-policy': NewCustomPolicyComponent,
    policy: PolicyComponent,
    'policy-dialog': PolicyDialogComponent,
    'add-to-exception': AddToExceptionComponent,
    'workflow-dialog': WorkflowDialogComponent,
    'execution-details-dialog': WorkflowExecutionDetailsDialogComponent,
    'policy-list-details-dialog': PolicyListDetailsDialogComponent
  }

  requestBody = undefined

  /*
    IMPORTS ARE NEEDED FOR THE FUNCTION GENERATED BY THE CONSTRUCTOR
  */
  constructor(
    private translateHelper: TranslateHelper,
    private service: ReportsService,
    private rightPanelService: RightPanelService,
    private toastService: ToastService,
    private cronHelper: CronExpressionHelper,
    private dialog: MatDialog,
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<ClientDataGridDialogComponent>,
    private router: Router,
    private sharedHelperService: SharedHelperService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      title: string
      subTitle: string
      params: any
      data: any
      parentRef?: any
      body: any
    }
  ) {
    this.parentRef = dialogData.parentRef
    this.title = dialogData.title
    this.subTitle = dialogData.subTitle
    this.data = dialogData.data
    this.body = dialogData.body
    this.initDataGrid(dialogData.params)
  }

  ngOnInit(): void {
    const obs = this.store.select(selectLastMessageOfType('ReloadPolicy')).pipe(filter((x) => !!x))
    obs
      .pipe(
        buffer(obs.pipe(debounceTime(200))),
        filter((x) => !!x?.length)
      )
      .subscribe((messages) => {
        this.dataGrid?.refresh()
      })
  }

  isRowMaster = () => false
  getItems = () => {}

  openTimeSavingEditor = () => {
    this.dialogData.params.rawData = []
    this.dataGrid.gridApi.forEachNode(this.getRawData)
    this.dialog
      .open(TimeSavingDialogComponent, {
        data: {
          title: 'playbook_customizeTimeSavingPerItem',
          params: { ...this.dialogData.params },
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.dataGrid.refresh()
      })
  }

  applyFilters() {
    if (this.data.filters) {
      const prevFilters = this.dataGrid.gridApi.getFilterModel()
      const newFilters = {
        [this.data.filters.filteredColumn]: {
          type: 'equals',
          filter: this.data.filters.selectedElement,
        },
      }
      this.dataGrid.gridApi.setFilterModel({ ...prevFilters, ...newFilters })
    }
  }

  private initDataGrid(params: any): void {
    if (params.detailCellRenderer) {
      this.detailCellRenderer = params.detailCellRenderer
    }
    if (params.detailCellRenderParams) {
      this.detailCellRenderParams = this.parseDetailCellRenderParams(lodash.cloneDeep(params.detailCellRenderParams))
    }
    if (params.isRowMaster) {
      this.isRowMaster = () => true
    }
    if (params.columnSelector) {
      this.hideColumnSelector = false
    }
    this.requestBody = lodash.cloneDeep({ ...params.getItems?.body, ...this.body })
    if (params.selectionActions) {
      this.selectionActions = this.parseSelectionActionFunctions(lodash.cloneDeep(params.selectionActions))
    }
    if (params.filterActions) {
      this.filterActions = this.parseSelectionActionFunctions(lodash.cloneDeep(params.filterActions))
    }
    this.columnParams = this.parseColumnOnClickFunctions(lodash.cloneDeep(params.columnDefinition))
    if (params.dataColumnDefinition) {
      this.updateColumnDefinition(params.dataColumnDefinition)
    }
    this.getItems = (): any => this.getItemMethod(params.getItems)
  }

  private getItemMethod(getItems: any) {
    const url = this.getUrl(getItems.url)

    return this.service.getData(url, getItems?.verb || 'get', this?.requestBody).pipe(
      map((data: any) => {
        if (getItems.mapResultFunction) {
          const ref = this
          return new Function('data', 'ref', 'lodash', 'dayjs', getItems.mapResultFunction)(data, ref, lodash, dayjs)
        }
        return data
      })
    )
  }

  private getUrl = (originalUrl: string): string => {
    let url = originalUrl
    ;(url.match(/\{([A-z]+)\}/g) || []).forEach((x: string) => {
      url = url.replace(x, this.data[x.substring(1, x.length - 1)])
    })
    return url
  }

  private parseDetailCellRenderParams(detailCellRenderParams: any): any {
    const ref = this
    const innerHtmlFunc = detailCellRenderParams.innerHtml as string
    detailCellRenderParams.innerHtml = (event: any) =>
      new Function('ref', 'lodash', 'dayjs', 'event', innerHtmlFunc)(ref, lodash, dayjs, event)
    return detailCellRenderParams
  }

  private parseColumnOnClickFunctions(columnsParameters: BuildColParameter): BuildColParameter {
    const ref = this
    if (columnsParameters.cellRendererParams) {
      const newCellRendererParams: any = {}
      Object.entries(columnsParameters.cellRendererParams).forEach(([key, obj]) => {
        if (obj.onClick) {
          const strFuncCellRender = obj.onClick as string
          obj.onClick = (event: any) => new Function('ref', 'lodash', 'dayjs', 'event', strFuncCellRender)(ref, lodash, dayjs, event)
        }
        if (obj.errorLabel) {
          const strFuncErrorLabel = obj.errorLabel as string
          obj.errorLabel = (data: any) => new Function('ref', 'lodash', 'dayjs', 'data', strFuncErrorLabel)(ref, lodash, dayjs, data)
        }
        newCellRendererParams[key] = obj
      })
      columnsParameters.cellRendererParams = newCellRendererParams
    }
    columnsParameters.allcols.forEach((column) => {
      if (column.agColDef?.cellRenderer) {
        // if cellRederer is a very long string is a function
        if (column.agColDef.cellRenderer.length > 30) {
          const strRenderer = column.agColDef.cellRenderer as string
          column.agColDef.cellRenderer = ({ data }: any): any =>
            new Function('ref', 'lodash', 'dayjs', 'event', strRenderer)(ref, lodash, dayjs, data)
        }
      }
      if (column.agColDef?.cellRendererParams) {
        this.convertPropertiesToLambdas(column.agColDef.cellRendererParams, ref)
        if (column.agColDef.cellRendererParams.options) {
          column.agColDef.cellRendererParams.options = column.agColDef.cellRendererParams.options.map((option: any) => ({
            ...option,
            text: this.translateHelper.instant(option.text),
          }))
        }
      }
      return column
    })
    return columnsParameters
  }

  private convertPropertiesToLambdas(cellRendererParams: any, ref: this) {
    if (cellRendererParams.getIcon) {
      const strFuncGetIcon = cellRendererParams.getIcon as string
      cellRendererParams.getIcon = (event: any) => new Function('ref', 'lodash', 'dayjs', 'event', strFuncGetIcon)(ref, lodash, dayjs, event)
    }
    if (cellRendererParams.getIconClass) {
      const strFuncGetIconClass = cellRendererParams.getIconClass as string
      cellRendererParams.getIconClass = (event: any) => new Function('ref', 'lodash', 'dayjs', 'event', strFuncGetIconClass)(ref, lodash, dayjs, event)
    }
    if (cellRendererParams.onClick) {
      const strFuncOnClick = cellRendererParams.onClick as string
      cellRendererParams.onClick = (event: any) => new Function('ref', 'lodash', 'dayjs', 'event', strFuncOnClick)(ref, lodash, dayjs, event)
    }
    if (cellRendererParams.optionsFunction) {
      const strFunc = cellRendererParams.optionsFunction as string
      cellRendererParams.optionsFunction = (data: any) => new Function('ref', 'data', strFunc)(ref, data)
    }
    if (cellRendererParams.disabledFunction) {
      const strFunc = cellRendererParams.disabledFunction as string
      cellRendererParams.disabledFunction = (data: any) => new Function('ref', 'data', strFunc)(ref, data)
    }
  }

  private parseSelectionActionFunctions(actions: any[]): SelectionAction[] {
    return actions.map((action) => {
      const onClickStrFunction = action.onClick as string
      const ref = this
      action.onClick = (event: any) =>
        new Function('ref', 'lodash', 'dayjs', 'event', 'data', onClickStrFunction)(ref, lodash, dayjs, event, this.data)
      if (action.visibility === 'custom') {
        const visibleStrFunction = action.isVisible as string
        action.isVisible = () => new Function('ref', 'lodash', 'dayjs', visibleStrFunction)(ref, lodash, dayjs)
      }
      return action
    })
  }

  private updateColumnDefinition(colDefParam: any): void {
    let url = colDefParam.url
    ;(url.match(/\{([A-z]+)\}/g) || []).forEach((x: string) => {
      url = url.replace(x, this.data[x.substring(1, x.length - 1)])
    })

    const ref = this
    this.service
      .getData(url, colDefParam?.verb || 'get', { ...colDefParam?.body, ...this.body })
      .pipe(
        map((data: any) => {
          if (colDefParam.mapResultFunction) {
            return new Function('data', 'ref', 'lodash', 'dayjs', colDefParam.mapResultFunction)(data, ref, lodash, dayjs)
          }
        })
      )
      .subscribe((data: CoreViewColumn[]) => {
        // data needs to be an array of CoreViewColumns or new cases need to be impl.
        this.columnParams.allcols = [...this.columnParams.allcols, ...data]
        this.columnParams.selectedCols = [...this.columnParams.allcols.map((col) => col.originalName)]
        this.dataGrid.reloadColumns()
      })
  }

  private checkRoles(roles: string[]) {
    return this.sharedHelperService.checkRoles(roles)
  }

  private getRawData = (node: any, index: number) => {
    this.dialogData.params.rawData.push(node.data)
  }
}

import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'
import isObject from 'lodash-es/isObject'

@Component({
  selector: 'app-array-cell-renderer',
  templateUrl: './array-cell-renderer.component.html',
  styleUrls: ['./array-cell-renderer.component.sass'],
})
export class ArrayCellRendererComponent implements AgRendererComponent {
  public values!: string[]
  public params!: any

  constructor() {}

  refresh(params: ICellRendererParams): boolean {
    return true
  }

  agInit(params: ICellRendererParams): void {
    this.params = params

    this.values = (params.value || []).map((x: any) => (isObject(x) ? JSON.stringify(x) : x))
  }
}

import { Component, ViewChild } from '@angular/core'
import { ICellRendererParams } from '@ag-grid-community/core'
import { AgRendererComponent } from '@ag-grid-community/angular'
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-icon-menu-button-renderer',
  templateUrl: './icon-menu-button-renderer.component.html',
  styleUrls: ['./icon-menu-button-renderer.component.sass'],
})
export class IconMenuButtonRendererComponent implements AgRendererComponent {
  @ViewChild('trigger') menuTrigger?: MatMenuTrigger;

  public icon!: string
  public disabled = false
  public options: { key: string; text: string; icon?: string; color?: string; isSeparator?: boolean; isDisabled?: boolean }[] = []

  public params: any

  agInit(params: ICellRendererParams): void {
    this.params = params
    this.icon = this.params.icon || null
    this.options = this.params.options
    if (this.params.optionsFunction) {
      this.options = this.params.optionsFunction(params.data)
    }
    this.disabled = this.params.disabled
  }

  refresh(params?: any): boolean {
    return true
  }

  click(key: string, clickEvent: any, isDisabled?: boolean): void {
    if (isDisabled) return;
    clickEvent?.stopPropagation()
    this.menuTrigger?.closeMenu()

    if (this.params.onClick instanceof Function) {
      const clickParams = {
        clickEvent,
        key,
        rowData: this.params.node.data,
        component: this,
      }
      this.params.onClick(clickParams)
    }
  }
}

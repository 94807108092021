/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input } from '@angular/core'
import { Workflow } from '@app/modules/workflow/models/workflow.model'
import { SerializerService } from '@app/modules/workflow/services/serializer.service'
import { TranslateHelper } from '@coreview/coreview-library'

@Component({
  selector: 'app-action-filter-condition',
  template: `
    <li>
      <strong>{{ conditionTitle }}</strong> -> {{ conditionOperator }} -> <strong>{{ conditionString }}</strong>
    </li>
  `,
  styleUrls: ['./action-filter-condition.component.sass'],
})
export class ActionFilterConditionComponent {
  @Input() actions: Workflow.Dto.V1.Common.Action[] = []

  @Input() set condition(value: any) {
    const condition = value
    if (condition && condition.property) {
      const conditionPath = this.getConditionPath(condition.property)
      const [displayName, displayValue] = conditionPath.split('_')

      this.conditionTitle = `${displayName} ${displayValue}`
      this.conditionString = this.getConditionString(condition.value)
      this.conditionOperator = this.translateHelper.instant(this.casesStrategy[condition.operator])
    } else {
      this.conditionTitle = ''
      this.conditionOperator = ''
      this.conditionString = ''
    }
  }

  public conditionTitle = ''
  public conditionString = ''
  public conditionOperator = ''

  public casesStrategy: Record<string, string> = {
    Contains: 'common_Contains',
    NotContains: 'common_NotContains',
    StartsWith: 'common_StartsWith',
    EndsWith: 'common_EndsWith',
    IsEqual: 'common_IsEqual',
    IsNotEqual: 'common_IsNotEqual',
    IsEmpty: 'common_IsEmpty',
    IsNotEmpty: 'common_IsNotEmpty',
    IsGreaterThen: 'common_IsGreaterThen',
    IsLessThen: 'common_IsLessThen',
    IsGreaterOrEqualThen: 'common_IsGreaterOrEqualThen',
    IsLessOrEqualThen: 'common_IsLessOrEqualThen',
  }

  constructor(private serializerService: SerializerService, private translateHelper: TranslateHelper) {}

  getConditionPath(boundPath: string) {
    const bindingProperty = boundPath.split('::')[2]
    return `${this.configureDisplay(boundPath)}_${bindingProperty}`
  }

  getConditionString(value: any) {
    let resolvedValue = value
    if (value && this.serializerService.hasBinding(value)) {
      const bindingProperty = value.split('::')[2]
      resolvedValue = `${this.configureDisplay(value)} ${bindingProperty}`
    }
    return value ? resolvedValue : ''
  }

  configureDisplay(boundPath: string) {
    const actionUid = boundPath.split('::')[1]
    const actionIndex = this.actions.findIndex((action) => action.uid === actionUid)
    let displayString = boundPath.split('::')[0].split('{{')[1]
    displayString = displayString.charAt(0).toUpperCase() + displayString.slice(1)
    return actionIndex !== -1 ? `${displayString} (${actionIndex + 1}):` : `${displayString}:`
  }
}

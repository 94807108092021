<div class="cv-dialog-div-container">
  <div class="error-container">
    <div class="error-page">
      <div>
        <img src="../assets/img/403.svg" alt="not allowed image" />
      </div>
      <div class="message-error">
        <h2>{{ 'generic_notAllowedTitle' | translate }}</h2>
        <div class="m-t-20 m-b-10" [innerHTML]="'generic_notAllowedDescription' | translate"></div>
        <div class="buttons-div">
          <cv-button
            leftIcon="home"
            buttonType="secondary"
            text="{{ 'common_GoToHomepage' | translate }}"
            size="tiny"
            (click)="gotoHome()"
          ></cv-button>
          <cv-button leftIcon="close" text="{{ 'Close' | translate }}" size="tiny" (click)="closeDialog()"></cv-button>
        </div>
      </div>
    </div>
    <cv-close-button (click)="closeDialog()"></cv-close-button>
  </div>
</div>

import { CustomManagementAction, ManagementAction } from '@app/core/models/management-action'
import { Dictionary } from '@ngrx/entity'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { flatMap, uniq } from 'lodash-es'
import { managementActionsAdapter } from './management-actions.reducer'
import { ManagementActionState } from './management-actions.types'
import { Constants } from '@app/shared/utilities/constants'

const defaultSelectors = managementActionsAdapter.getSelectors()

export const selectManagementActionState = createFeatureSelector<ManagementActionState>('managementActions')

export const selectManagementActions = createSelector(selectManagementActionState, defaultSelectors.selectEntities)
    
export const selectManagementCustomActions = createSelector(selectManagementActionState, (state) => [...state.customActions].sort((a, b) => a.translate.localeCompare(b.translate)))

export const selectManagementActionsFlat = createSelector(
  selectManagementActions,
  (actions: Dictionary<ManagementAction>) => flatMap(actions, 'actionItems').sort((a, b) => a.translate.localeCompare(b.translate)) as ManagementAction[]
)

export const selectManagementActionsFlatByReportTags = (reportTags: string[]) =>
  createSelector(selectManagementActions, (actions: Dictionary<ManagementAction>) => {
    const filtered = flatMap(actions, 'actionItems').filter(
      (actionItem) =>
        !!actionItem?.reportTags?.length &&
        !actionItem.reportTags.some(
          (tag: string) => tag.toLowerCase() === 'needv2api' || tag === Constants.RunCustomActionActivityTag
        ) &&
        (reportTags.length === 0 || reportTags.some((tag) => actionItem.reportTags.includes(tag)))
    )
    return filtered.sort((a, b) => a.translate.localeCompare(b.translate))
  })

export const selectRequiredFieldsByReportTags = (reportTags: string[]) =>
  createSelector(
    selectManagementActionsFlatByReportTags(reportTags),
    (actions: ManagementAction[]) => uniq([
        ...(
          actions.filter((x) => !!x.rules?.length)
            .flatMap((x) => x.rules)
            .map((rule) => rule.split(':')[0]) || []
        ).filter((x) => !!x),
        ...(actions.filter((x) => !!x.requiredFields?.length).flatMap((x) => x.requiredFields || []) || []).filter((x) => !!x),
      ])
  )

export const selectManagementActionsByIds = (actionIds: number[]) =>
  createSelector(selectManagementActions, (actions: Dictionary<ManagementAction>) => {
    const filtered = flatMap(actions, 'actionItems').filter((actionItem) => actionIds.find((id) => id === actionItem.actionItemId))
    return filtered
  })

export const selectCustomActionsByTarget = (targetEntity: string) =>
  createSelector(selectManagementCustomActions, (actions: CustomManagementAction[]) => {
    return actions?.filter((action) => (action.target as unknown as string) === targetEntity)
  })

export const selectHasActionsOrCustomActions = (reportTags: string[], targetEntity: string) =>
  createSelector(
    selectManagementActionsFlatByReportTags(reportTags),
    selectCustomActionsByTarget(targetEntity),
    (actions: ManagementAction[], customActions: CustomManagementAction[]) => {
      return !!actions.length || !!customActions.length
  })

export const selectManagementActionsLoading = createSelector(selectManagementActionState, (state) => state.isFetching)

export const selectManagementActionsError = createSelector(selectManagementActionState, (state) => state.error)

<div class="actionList-wrapper">
  <ng-container *ngFor="let action of filteredActions; let i = index">
    <ng-container *ngIf="getActionIsSequence(action)">
      <app-action-sequence
        [sequence]="action"
        [actions]="actions"
        [parentUid]="action.uid"
        [isCatch]="isCatch"
        [previewType]="previewType"
        [showDetails]="true"
      ></app-action-sequence>
      <div class="arrow-container" *ngIf="i < filteredActions.length - 1">
        <mat-icon class="arrow-icon m-t-10">arrow_downward</mat-icon>
      </div>
    </ng-container>

    <ng-container *ngIf="!getActionIsSequence(action) && action.id !== 'item::if-else'">
      <div
        style="width: 100%; box-sizing: border-box"
        [ngClass]="{ 'actionList-catchContainer': isCatch, 'actionList-container': !parentUid }"
      >
        <app-preview-action
          [action]="action"
          [isLast]="action?.uid === filteredActions[filteredActions.length - 1]?.uid"
          [isCatch]="isCatch"
          [previewType]="previewType"
        >
          <app-details-icon command-icon [action]="action" [isCatch]="isCatch"></app-details-icon>
          <span>{{ action.name }}</span>
        </app-preview-action>
      </div>
    </ng-container>

    <ng-container *ngIf="action.id === 'item::if-else' && !ifElseBranch">
      <app-action-if-else
        [actions]="actions"
        [ifElse]="$any(action)"
        [isCatch]="isCatch"
        [previewType]="previewType"
        [showMergeLine]="i < filteredActions.length - 1"
      ></app-action-if-else>
      <div class="arrow-container" *ngIf="i < filteredActions.length - 1">
        <mat-icon class="arrow-icon m-t-10">arrow_downward</mat-icon>
      </div>
    </ng-container>
  </ng-container>
</div>

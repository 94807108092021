import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { pluck } from 'rxjs/operators'

import { CustomMenu } from '../models/CustomMenu'
import { LicensePool } from '../models/LicensePool'
import { ServerResponse } from '../models/ServerResponse'
import { UserProfile } from '../models/userProfile'
import { ApiclientService } from './apiclient.service'
import { GroupOperator } from '../models/GroupOperator'

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(private apiclient: ApiclientService, private httpClient: HttpClient) {}

  getUserProfile(params: any = {}): Observable<UserProfile> {
    const queryParams = params.id ? '?id=' + params.id : params.username ? '?username=' + params.username : ''
    const url = `${this.apiclient.basePortalApiUrl}/register/${queryParams}`
    return this.httpClient.get<UserProfile>(url, { withCredentials: true })
  }

  getUserLicensePools(userId: string): Observable<LicensePool[]> {
    const url = `${this.apiclient.basePortalApiUrl}/securitymanager/licensepools/user?
      metadata=true&pageNumber=1&pageSize=10000&sort=Name&sortOrder=asc&userId=${userId}`
    return this.httpClient.get<LicensePool[]>(url, { withCredentials: true }).pipe(pluck('licensePoolGroups'))
  }

  getUserCustomMenu(userId: string): Observable<CustomMenu[]> {
    const url = `${this.apiclient.basePortalApiUrl}/securitymanager/customMenu/?metadata=true&userId=${userId}`
    return this.httpClient.get<CustomMenu[]>(url, { withCredentials: true }).pipe(pluck('customMenus'))
  }

  getUserCardItems(): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/usercard/items`
    return this.httpClient.get<any>(url, { withCredentials: true })
    // return of(permissions ? permissions : undefined)
  }

  getUserCardPermission(): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/usercard/configuration`
    return this.httpClient.get<any>(url, { withCredentials: true })
    // return of(permissions ? permissions : undefined)
  }

  getFirstPage(): Observable<string> {
    const url = `${this.apiclient.basePortalApiUrl}/V2/securitymanager/firstloggedpage`
    return this.httpClient.get<any>(url, { withCredentials: true }).pipe(pluck('route'))
  }

  addUser(params: any): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/register`
    return this.httpClient.post<UserProfile>(url, params, { withCredentials: true })
  }

  modifyUser(params: any): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/register`
    return this.httpClient.put<UserProfile>(url, params, { withCredentials: true })
  }

  deleteUserById(id: string): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/register/?id=${id}`
    return this.httpClient.delete<UserProfile>(url, { withCredentials: true })
  }

  addUserToLicensePools(params: any): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/licensepool/membership`
    return this.httpClient.put<any>(url, params, { withCredentials: true })
  }

  getUserGroupsfOperator(id: string): Observable<{ companyName: string; tenantId: string; userGroupId: string; userGroupName: string }[]> {
    const url = `${this.apiclient.basePortalApiUrl}/securitymanager/usergroups/user`
    return this.httpClient.get<any>(url, { withCredentials: true, params: { userId: id }})
  }

  addUserToGroups(params: any): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/securitymanager/usergroups/membership`
    return this.httpClient.put<any>(url, params, { withCredentials: true })
  }

  addUserToPermissions(params: any): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/securitymanager/customMenus/membership`
    return this.httpClient.put<any>(url, params, { withCredentials: true })
  }

  getGroupOfOperators(id: string): Observable<GroupOperator> {
    const url = `${this.apiclient.basePortalApiUrl}/operatorsGroup/?id=${id}`
    return this.httpClient.get<GroupOperator>(url, { withCredentials: true })
  }

  saveGroupOfOperators(isEdit: boolean, params: any): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/operatorsGroup`
    if (isEdit) {
      return this.httpClient.put<any>(url, params, { withCredentials: true })
    }
    return this.httpClient.post<any>(url, params, { withCredentials: true })
  }

  saveGroupOfOperatorsBulk(isEdit: boolean, params: any): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/operatorsGroupBulk`
    if (isEdit) {
      return this.httpClient.put<any>(url, params, { withCredentials: true })
    }
    return this.httpClient.post<any>(url, params, { withCredentials: true })
  }

  deleteGroupOfOperators(id: string, organizationId: string): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/operatorsGroup/?Id=${id}&OrganizationId=${organizationId}`
    return this.httpClient.delete<any>(url, { withCredentials: true })
  }

  deleteNoteById(id: string): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/notes/user/?id=${id}`
    return this.httpClient.delete<any>(url, { withCredentials: true })
  }

  saveNote(note: any): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/notes/user/`

    if(!!note.id)
      return this.httpClient.put<any>(url, note, { withCredentials: true })

    return this.httpClient.post<any>(url, note, { withCredentials: true })
  }

  organizationCheck(): Observable<{isRunningImport: boolean}> {
    const url = `${this.apiclient.basePortalApiUrl}/organization/info`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { GridOptions } from "@ag-grid-community/core"
import { Router } from "@angular/router"
import { CoreViewColumn } from "@app/core/models/CoreViewColumn"
import { BuildColParameter } from "@app/shared/utilities/build-col-ag-grid"

export const getColumnsParametersLicensePoolClientLicense = (router: Router): BuildColParameter => ({
  selectedCols: ['sku', 'totalConsumed', 'active'],
  allcols: [
    {
      originalName: 'sku',
      name: 'sku',
      translate: 'Sku',
      type: 'tableDetail',
      filter: { type: 'string', name: 'sku' },
      agColDef: {
        sort: 'asc',
      },
    },
    {
      originalName: 'totalConsumed',
      name: 'totalConsumed',
      translate: 'TotalConsumed',
      type: 'number',
      filter: { type: 'number', name: 'users' },
    },
    {
      originalName: 'active',
      name: 'active',
      translate: 'Active',
      type: 'number',
      filter: { type: 'number', name: 'active' },
    },
  ],
  cellRenderer: {
    totalConsumed: 'customLinkRendererComponent',
  },
  cellRendererParams: {
    totalConsumed: {
      onClick: (event: any) => {
        router.navigate(['/reports/users/users'], { queryParams: { Licenses: event.rowData.sku }}).catch((_: any) => _)
        event.event.stopPropagation()
      },
    },
  },
})

export const getColumnsParametersLicensePoolCostClient = (router: Router): BuildColParameter => ({
  selectedCols: ['name', 'users', 'totalConsumedCost', 'currency'],
  allcols: [
    {
      originalName: 'name',
      name: 'name',
      translate: 'Name',
      type: 'tableDetail',
      filter: { type: 'string', name: 'name' },
      agColDef: {
        sort: 'asc',
      },
    },
    {
      originalName: 'users',
      name: 'users',
      translate: 'Users',
      type: 'number',
      filter: { type: 'number', name: 'users' },
    },
    {
      originalName: 'totalConsumedCost',
      name: 'totalConsumedCost',
      translate: 'TotalConsumedCost',
      type: 'double',
      filter: { type: 'number', name: 'users' },
    },
    {
      originalName: 'currency',
      name: 'currency',
      translate: 'Currency',
      type: 'string',
      filter: { type: 'string', name: 'currency' },
    },
  ],
  cellRenderer: {
    name: 'agGroupCellRenderer',
    users: 'customLinkRendererComponent',
  },
  cellRendererParams: {
    users: {
      onClick: (event: any) => {
        router.navigate(['/reports/users/users'], { queryParams: { LicensePoolGroupName: event.rowData.name }}).catch((_: any) => _)
        event.event.stopPropagation()
      },
    },
  },
})

export const getGridOptionsLicensePoolCostClient: GridOptions = {
  suppressRowClickSelection: true,
  defaultColDef: {
    sortable: true,
    resizable: false,
    filter: true,
    floatingFilter: true,
    filterParams: {
      suppressAndOrCondition: true,
    },
    suppressMovable: true,
  },
  masterDetail: true,
  detailCellRenderer: 'customDetail',
  detailCellRendererParams: {
    getTableData: (data: any) => data.items.sort((a: any, b: any) => a.sku.localeCompare(b.sku)),
    columnDefs: [
      { field: 'sku', sort: 'asc', type: 'string', name: 'sku', translate: 'Licenses' },
      { field: 'consumed', type: 'double', name: 'consumed', translate: 'Consumed' },
      { field: 'totalConsumedCost', type: 'double', name: 'totalConsumedCost', translate: 'TotalConsumedCost' },
    ],
  },
}

export const getGridOptionsSnapshots: GridOptions = {
  suppressRowClickSelection: true,
  defaultColDef: {
    sortable: true,
    resizable: false,
    filter: true,
    suppressMovable: true,
  },
  masterDetail: true,
  columnDefs: [],
  detailCellRenderer: 'customDetail',
  detailCellRendererParams: {
    detailGridOptions: {
      columnDefs: [
        { field: 'sku', sort: 'asc', type: 'string', name: 'sku', translate: 'Licenses' },
        { field: 'value', type: 'int', name: 'value', translate: 'Assigned' },
        { field: 'consumed', type: 'int', name: 'consumed', translate: 'Consumed' },
      ],
    },
  },
}

export const getColumnDefsLicensePoolsCost: CoreViewColumn[] = [
  {
    originalName: 'Name',
    name: 'name',
    translate: 'Name',
    filter: { type: 'string', name: 'name' },
    type: 'tableDetail',
    agColDef: {
      suppressMovable: true,
    },
  },
  {
    originalName: 'Users',
    name: 'users',
    translate: 'Users',
    type: 'int',
    sortName: 'no_sort',
    agColDef: {
      suppressMovable: true,
    },
  },
  {
    originalName: 'ActiveUsers',
    name: 'activeUsers',
    translate: 'ActiveUsers',
    type: 'int',
    sortName: 'no_sort',
    agColDef: {
      suppressMovable: true,
    },
  },
  {
    originalName: 'TotalAssignedCost',
    name: 'totalAssignedCost',
    translate: 'TotalAssignedCost',
    type: 'double',
    sortName: 'no_sort',
    agColDef: {
      suppressMovable: true,
    },
  },
  {
    originalName: 'TotalConsumedCost',
    name: 'totalConsumedCost',
    translate: 'ConsumedLicensesCost',
    type: 'double',
    sortName: 'no_sort',
    agColDef: {
      suppressMovable: true,
    },
  },
  {
    originalName: 'Currency',
    name: 'currency',
    translate: 'Currency',
    type: 'string',
    sortName: 'no_sort',
    agColDef: {
      suppressMovable: true,
    },
  },
]

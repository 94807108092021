export type ManagementSessionStatus = 'Enabling' | 'Enabled' | 'Disabling' | 'Disabled' | 'Expired' | 'Failed' | 'NotAllowed'

export interface ManagementResponse {
  status: ManagementSessionStatus
  sessionId: string
}

const managementSessionStatusMapping: Record<number, ManagementSessionStatus> = {
  1: 'Enabling',
  2: 'Enabled',
  3: 'Disabling',
  4: 'Disabled',
  5: 'Expired',
  6: 'Failed',
  7: 'NotAllowed',
}

export const convertFromNumberToStatus = (statusEnum: number): ManagementSessionStatus =>
  managementSessionStatusMapping[statusEnum] ?? 'Failed'

export interface UpdateManagenentRes {
  isValid?: boolean
  errorData?: string
  errorMessage?: string
}

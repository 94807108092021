import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'
import { BadgeVariant } from '@coreview/coreview-components'

@Component({
  selector: 'app-badge-renderer',
  templateUrl: './badge-renderer.component.html',
  styleUrls: ['./badge-renderer.component.sass'],
})
export class BadgeRendererComponent implements AgRendererComponent {
  public params!: any
  public value!: string
  public variant!: BadgeVariant
  public icon!: string
  public text!: string

  constructor() {}

  refresh(params: ICellRendererParams): boolean {
    return true
  }

  agInit(params: any): void {
    this.params = params
    this.value = params.value
    this.text = params.getText ? params.getText(this.value) : this.value
    this.variant = params.getVariant ? params.getVariant(this.value) : 'grey'
    this.icon = params.getIcon ? params.getIcon(this.value) : ''
  }
}

/* eslint-disable @typescript-eslint/member-ordering */
import { FlatTreeControl } from '@angular/cdk/tree'
import { Component, Input, OnInit } from '@angular/core'
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree'
import { TranslateHelper } from '@coreview/coreview-library'
import { TreeNode } from '../tree-view/models/Tree'

@Component({
  selector: 'app-light-readonly-treeview',
  templateUrl: './light-readonly-treeview.component.html',
  styleUrls: ['./light-readonly-treeview.component.sass'],
})
export class LightReadonlyTreeviewComponent<T extends { text: string | number; icon?: string; children: T[] }> implements OnInit {
  @Input()
  data!: T[]

  @Input()
  showIcons = false

  @Input()
  preventTranslation = false

  private _transformer = (node: T, level: number) =>
    ({
      expandable: !!node.children && node.children.length > 0,
      name: typeof node.text === 'string' && !this.preventTranslation ? this.translateHelper.instant(node.text) : node.text,
      level,
      icon: this.showIcons ? node.icon : '',
      ref: node,
    } as TreeNode)

  treeControl = new FlatTreeControl<TreeNode>(
    (node) => node.level,
    (node) => node.expandable
  )
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  )
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener)

  constructor(private translateHelper: TranslateHelper) {}

  ngOnInit(): void {
    this.dataSource.data = this.data
  }

  hasChild = (level: number, node: TreeNode) => node.expandable
}

import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-loading-report-skeleton',
  templateUrl: './loading-report-skeleton.component.html',
  styleUrls: ['./loading-report-skeleton.component.sass'],
})
export class LoadingReportSkeletonComponent implements OnInit {
  @Input() hasCharts = false

  constructor() {}

  ngOnInit(): void {}
}

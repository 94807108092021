import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Workflow } from '@app/modules/workflow/models/workflow.model'
import { TranslateHelper } from '@coreview/coreview-library'

@Component({
  selector: 'app-execution-detail-output-item',
  template: `
  <div class="executionDetailOutputItem-container">
  <div *ngIf="key === 'Skipped'" >
    <h4>{{ 'workflow_ExecutionActionWasSkipped' | translate }}</h4>
    <h4>{{ 'workflow_ExecutionReasons' | translate }}</h4>

    <h4 *ngIf="skippedResult === 'Manual'">{{ 'workflow_ExecutionManual' | translate }}</h4>
    <div *ngIf="skippedResult === 'List'">

    <div  *ngFor="let groupResults of value.results; let groupIndex = index">
        <strong *ngIf="groupIndex > 0">OR</strong>
        <ul>
          <li *ngFor="let item of groupResults.results">
            {{ formatReason(item.reason) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="key !== 'Skipped'">
    <span  class="key-field">{{ key }}</span>: <span class="value">{{ value }}</span>
  </div>    
</div>
`,
  styleUrls: ['./execution-detail-output-item.component.sass'],
})
export class ExecutionDetailOutputItemComponent implements OnChanges {
  @Input() key = ''
  @Input() value: any
  @Input() definitionActions: Workflow.Dto.V1.WorkflowExecutionDetail.Action[] = []

  public skippedResult: 'Manual' | 'List' | 'None' = 'None'

  constructor(private translateHelper: TranslateHelper) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.convertValue()
  }

  convertValue() {
    if (this.key === 'Skipped') {
      if (this.isSkippedManual(this.value)) {
        this.skippedResult = 'Manual'
      } else {
        this.skippedResult = 'List'
      }
    }
  }

  isSkippedManual(skippedResults: any): boolean {
    return typeof skippedResults === 'undefined' || skippedResults === '{}' || typeof skippedResults.results === 'undefined'
  }

  formatReason(reason: string) {
    if (reason.includes('-> action:')) {
      const regex = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/gm
      let m

      if ((m = regex.exec(reason)) !== null)  {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++
        }

        if (m.length > 0) {
          const actionUid = m[0]
          const actionIndex = this.definitionActions.findIndex((item) => item.uid === actionUid)
          return actionIndex > -1 ? `Action ${actionIndex + 1} - ${this.definitionActions[actionIndex].name}\n${reason}` : reason
        }
      }
      return ''
    } else {
      return `${this.translateHelper.instant('workflow_ExecutionInput')}\n${reason}`
    }
  }

}

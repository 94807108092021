import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { ApiclientService } from '@core/services/apiclient.service'
import { Workflow } from '../models/workflow.model'

@Injectable({
  providedIn: 'root',
})
export class WorkflowBuilderHttpService {
  constructor(private apiClient: ApiclientService, private http: HttpClient) {}

  fetchTargetOptions(type: string = 'targetoptions'): Observable<Workflow.Dto.V1.TargetOptions.Response> {
    const baseUrl = this.baseUrl()
    return this.http.get<Workflow.Dto.V1.TargetOptions.Response>(`${baseUrl.url}/datasources/${type}`, {
      withCredentials: baseUrl.withCred,
    })
  }

  fetchTargetFields(query: string): Observable<Workflow.Dto.V1.TargetFields.Response> {
    const params = { query }
    const baseUrl = this.baseUrl()
    return this.http.get<Workflow.Dto.V1.TargetFields.Response>(`${baseUrl.url}/datasources/targetfields`, {
      params,
      withCredentials: baseUrl.withCred,
    })
  }

  fetchDatasources(): Observable<Workflow.Dto.V1.Datasources.Response> {
    const baseUrl = this.baseUrl()
    return this.http.get<Workflow.Dto.V1.Datasources.Response>(`${baseUrl.url}/datasources`, {
      withCredentials: baseUrl.withCred,
    })
  }

  fetchCatalogProviders(): Observable<Workflow.Dto.V1.Providers.Response> {
    const baseCatalogsUrl = this.baseCatalogsUrl()
    return this.http.get<Workflow.Dto.V1.Providers.Response>(`${baseCatalogsUrl.url}/catalog/providers`, {
      withCredentials: baseCatalogsUrl.withCred
    })
  }

  fetchProviderCategories(providerId: string): Observable<Workflow.Dto.V1.Categories.Response> {
    const baseCatalogsUrl = this.baseCatalogsUrl()
    return this.http.get<Workflow.Dto.V1.Categories.Response>(`${baseCatalogsUrl.url}/catalog/providers/${providerId}/categories`, {
      withCredentials: baseCatalogsUrl.withCred
    })
  }

  fetchCategoryAction(providerId: string, action: string): Observable<Workflow.Dto.V1.CategoryAction.Response> {
    const baseCatalogsUrl = this.baseCatalogsUrl()
    return this.http.get<Workflow.Dto.V1.CategoryAction.Response>(
      `${baseCatalogsUrl.url}/catalog/providers/${providerId}/categories/${action}/actions`,
      {
        withCredentials: baseCatalogsUrl.withCred
      }
    )
  }

  fetchActions(): Observable<Workflow.Dto.V1.CategoryAction.Response> {
    const baseUrl = this.baseUrl()
    return this.http.get<Workflow.Dto.V1.CategoryAction.Response>(`${baseUrl.url}/catalog/actions`, {
      withCredentials: baseUrl.withCred,
    })
  }

  fetchNotificationSettings(workflowId: string): Observable<Workflow.Dto.V1.NotificationSettings.Response> {
    const baseUrl = this.baseUrl()
    return this.http.get<Workflow.Dto.V1.NotificationSettings.Response>(`${baseUrl.url}/workflows/${workflowId}/notificationconfig`, {
      withCredentials: baseUrl.withCred,
    })
  }

  saveNotificationSettings(
    workflowId: string,
    data: Workflow.Dto.V1.NotificationSettings.Data
  ): Observable<Workflow.Dto.V1.UpdateNotificationSettings.Response> {
    const baseUrl = this.baseUrl()
    return this.http.put<Workflow.Dto.V1.UpdateNotificationSettings.Response>(
      `${baseUrl.url}/workflows/${workflowId}/notificationconfig`,
      { ...data },
      {
        withCredentials: baseUrl.withCred,
      }
    )
  }

  private readonly baseUrl = () => this.apiClient.getWorkflowBaseApi(false)
  private readonly baseCatalogsUrl = () => this.apiClient.getWorkflowCatalogsBaseApi()

}

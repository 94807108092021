import { Component, Input, OnDestroy } from '@angular/core';
import { Helpers } from '@coreview/coreview-library';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-grant-access-details',
  templateUrl: './grant-access-details.component.html',
  styleUrls: ['./grant-access-details.component.sass']
})
export class GrantAccessDetailsComponent implements OnDestroy {
  @Input() item: any

  private destroyed$: Subject<boolean> = new Subject()

  constructor() {}

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  formatDate = (date: any): string => Helpers.formatDate(date)
}

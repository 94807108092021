/* eslint-disable max-len */
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { comparableDateVerbs, comparableVerbs, comparableVerbsWithNoValues, FilterDefinition } from '@app/core/models/ReportDefinition'
import { Constants } from './constants'
import { LocaleInfo } from '@app/core/models/LocaleInfo'
import { ValidationErrors } from '@angular/forms'
import { isEmpty, isEqual, isEqualWith } from 'lodash-es'
import { ColDef } from '@ag-grid-community/core'
import { CoreViewColumn } from '@coreview/coreview-library/models/CoreViewColumn'


export class Helpers {
  /**
   * Returns passed string with first letter uppercase.
   *
   * @param word - The string to be modified
   *
   * @return The passed string with first letter uppercase
   */
  public static readonly capitalize = (word: string) => word[0].toUpperCase() + word.slice(1)

  /**
   * Returns passed string with first letter lowercase.
   *
   * @param word - The string to be modified
   *
   * @return The passed string with first letter lowercase
   */
  public static readonly downcase = (word: string) => word[0].toLowerCase() + word.slice(1)

  /**
   * Returns most recent date between array of dates.
   *
   * @param word - The array of dates
   *
   * @return The most recent date
   */
  public static readonly getMostRecentDateFromArray = (dates: Date[]) =>
    new Date(Math.max(...dates.map((e: any) => (e ? new Date(e).getTime() : 0))))

  /**
   * Returns older date between array of dates.
   *
   * @param word - The array of dates
   *
   * @return The older date
   */
  public static readonly getOlderDateFromArray = (dates: Date[]) =>
    new Date(Math.min(...dates.map((e: any) => (e ? new Date(e).getTime() : 0))))

  /**
   * Returns date with days added.
   *
   * @param word - The days to add
   * @param word - The date to modify
   *
   * @return The new date
   */
  public static readonly addDaysToDate = (days: number, date?: Date) => {
    const result = date ? new Date(date) : new Date()
    result.setDate(result.getDate() + days)
    return result
  }

  /**
   * Returns UTC date formated as YYYY-MM-DD HH:mm:ss from a Unix timestamp (10 digits)
   *
   * @param date - the day as a Unix timestamp (10 digits) to format
   *
   * @returns formatted date
   */
  public static readonly getUnixTimestampDate = (unixTimestamp: any): dayjs.Dayjs | null =>
    unixTimestamp && dayjs.unix(unixTimestamp).isAfter('1970-01-01') ? dayjs.unix(unixTimestamp).utc(true) : null

  /**
   * Returns UTC date formated as YYYY-MM-DD HH:mm:ss
   *
   * @param date - the day object to format
   *
   * @returns formatted date
   */
  public static readonly formatDate = (date: any, format: string | null = null): string =>
    date && dayjs(date).isAfter('1970-01-01')
      ? dayjs(date)
          .utc()
          .format(format || 'lll')
      : ''

  /**
   * Returns UTC date formated as YYYY-MMMM (Ex. 2023 July)
   *
   * @param date - the day object to format
   *
   * @returns formatted date YYYY-MMMM (Ex. 2023 July)
   */
  public static readonly dateToYearMonth = (date: string): string =>
    date && dayjs(date).isAfter('1970-01-01') ? dayjs(date).utc().format('YYYY MMMM') : ''

  /**
   * Returns seconds from a string in format HH:mm
   *
   * @param value - the string
   *
   * @returns seconds
   */
  public static readonly toSeconds = (value: string): number => {
    const str = value.split(':')
    return +str[0] * 60 + +str[1]
  }

  /**
   * Returns hours and minutes formatted as HH:mm
   *
   * @param seconds - the seconds
   *
   * @returns formatted date
   */
  public static readonly toHHss = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60)
    seconds = seconds - minutes * 60
    return Helpers.zeroPad(minutes, 2) + ':' + Helpers.zeroPad(seconds, 2)
  }

  /**
   * Returns number with padding 0 as string
   *
   * @param number number
   * @param padd number of zeros
   *
   * @returns formatted value
   */
  public static readonly zeroPad = (num: number, padd: number): string => num.toString().padStart(padd, '0')

  /**
   * Returns date from a string
   *
   * @param value string
   * @param format string
   *
   * @returns formatted date
   */
  public static readonly parseDate = (value: string, format: string = 'L LT'): string => {
    const isodate = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+/

    return isodate.test(value) && dayjs(value).isValid() && dayjs(value).isBefore('2010-01-01', 'year')
      ? ''
      : isodate.test(value) && dayjs(value).isValid() && dayjs(value).isAfter('2010-01-01', 'year')
      ? dayjs.utc(value).format(format)
      : value
  }

  /**
   * Returns a humanize length of time from a milliseconds time
   *
   * @param value number
   *
   * @returns humanize duration
   */
  public static readonly humanizeDuration = (value: number): string => {
    dayjs.extend(duration)
    dayjs.extend(relativeTime)

    return dayjs.duration(value, 'milliseconds').humanize()
  }

  /**
   * Returns a duration time formatted in hours, minutes and seconds
   *
   * @param value number
   * @param unit type of value
   *
   * @returns formatted duration time
   */
  public static readonly formatDuration = (value: number, unit: duration.DurationUnitType = 'seconds'): string => {
    dayjs.extend(duration)
    dayjs.extend(relativeTime)
    const interval = dayjs.duration(value, unit)
    const hour = interval.days() * 24 + interval.hours()
    return (
      (hour > 0 ? `${hour}h ` : '') +
        (interval.minutes() > 0 ? `${interval.minutes()}` + 'm ' : '') +
        (interval.seconds() > 0 ? `${interval.seconds()}` + 's' : '') || '0s'
    )
  }

  /**
   * Returns a duration time formatted in hours, minutes and seconds
   *
   * @param value number
   * @param unit type of value
   *
   * @returns formatted duration time
   */
  public static readonly formatDurationDays = (value: number, unit: duration.DurationUnitType = 'seconds'): string => {
    dayjs.extend(duration)
    dayjs.extend(relativeTime)
    const interval = dayjs.duration(value, unit)

    if (interval.months() > 0) {
      return `${interval.days() + interval.months() * 30}d ${interval.hours()}h`
    }

    return interval.days() > 0 ? `${interval.days()}d ${interval.hours()}h` : `${interval.hours()}h ${interval.minutes()}m`
  }

  /**
   * Returns rounded number
   *
   * @param num number
   * @param decimals number of decimals
   *
   * @returns rounded number
   */
  public static readonly round = (num: number, decimals: number = 2): string => {
    const multiplier = Math.pow(10, 2)
    const b = (Math.round(num * multiplier) / multiplier).toFixed(decimals)
    return b
  }

  /**
   * Returns number with units
   *
   * @param num number
   * @param decimals unit
   *
   * @returns calculated number
   */
  public static readonly formatUnitByte = (val: number, unit?: 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB'): string => {
    if (!unit) {
      unit = 'B'
    }

    if (!val || val === 0) {
      return val + ' ' + unit
    }

    const s = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

    val = val * Math.pow(1024, s.indexOf(unit))

    let e = Math.floor(Math.log(val) / Math.log(1024))
    let value = (val / Math.pow(1024, Math.floor(e))).toFixed(2)
    e = e < 0 ? -e : e
    value += ' ' + s[e]

    return value
  }

  /**
   * Returns year and month formatted as YYYY-MM
   *
   * @param date - the date
   *
   * @returns formatted date
   */
  public static readonly toYYYYMM = (date: Date): string => date.getUTCFullYear() + '-' + Helpers.zeroPad(date.getUTCMonth() + 1, 2)

  /**
   * Returns object grouped by prop
   *
   * @param arr - the array
   * @param key - the prop key
   *
   * @returns grouped object
   */
  public static readonly groupBy = (arr: any, key: any) =>
    arr.reduce((rv: any, x: any) => {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})

  public static getStringFilterFromModel(parentModel: FilterDefinition | undefined): string {
    if (parentModel?.type) {
      if (parentModel.filterType === 'date') {
        return this.getDateModelAsString(parentModel)
      } else if (!!(parentModel as any).modelAsString) {
        return (parentModel as any).modelAsString
      }
      return (
        (comparableVerbs[parentModel.type] || '') +
        (comparableVerbsWithNoValues.hasOwnProperty(parentModel.type as string)
          ? comparableVerbsWithNoValues[parentModel.type as string]
          : parentModel.filter + (parentModel.filterTo || parentModel.filterTo === 0 ? ' & ' + parentModel.filterTo : ''))
      )
    } else {
      return ''
    }
  }

  public static getDateFiltersValue = (filterModel: FilterDefinition): any => {
    if (filterModel.type === 'equals') {
      filterModel.dateFrom = dayjs(filterModel.dateFrom, { utc: true }).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      filterModel.dateTo = dayjs(filterModel.dateFrom, { utc: true }).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      return filterModel.dateFrom + ' & ' + filterModel.dateTo
    } else if (filterModel.type === 'inRange') {
      filterModel.dateFrom = dayjs(filterModel.dateFrom, { utc: true }).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      filterModel.dateTo = dayjs(filterModel.dateTo, { utc: true }).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      return filterModel.dateFrom + ' & ' + filterModel.dateTo
    } else if (['notInLastNDays', 'lastNDays', 'nextNDays'].includes(filterModel.type)) {
      return filterModel.filter
    } else if (comparableVerbsWithNoValues.hasOwnProperty(filterModel.type)) {
      return comparableVerbsWithNoValues[filterModel.type]
    } else if (['greaterThan'].includes(filterModel.type)) {
      return dayjs(filterModel.dateFrom, { utc: true }).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    } else if (['greaterThanOrEqual'].includes(filterModel.type)) {
      return dayjs(filterModel.dateFrom, { utc: true }).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    } else if (['lessThan'].includes(filterModel.type)) {
      return dayjs(filterModel.dateFrom, { utc: true }).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    } else if (['lessThanOrEqual'].includes(filterModel.type)) {
      return dayjs(filterModel.dateFrom, { utc: true }).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    } else {
      return dayjs(filterModel.dateFrom, { utc: true }).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    }
  }

  public static enumFromStringValue<T>(enm: { [s: string]: string }, value: string): keyof T | undefined {
    const en = Object.entries(enm).find((e) => e[1] === value)
    return en ? (en[0] as keyof T) : undefined
  }

  public static isNumeric(value: string) {
    return /^-?\d+$/.test(value)
  }

  public static getStringsWithFirst2LettersCapitalized(list: string[]): string[] {
    return (
      list?.filter(
        (c) =>
          c.startsWith(c.charAt(0).toUpperCase()) && c.charAt(1) === c.charAt(1).toUpperCase() && !Helpers.isNumeric(c.charAt(1) as any)
      ) || []
    )
  }

  public static getDateModelAsString(model: any): string {
    return !model.type ? '' : this.getDateFiltersVerb(model) + this.getDateFiltersValue(model)
  }

  public static getLocationSearchValue(): string {
    return window.location.search
  }

  public static getSelectedTenantFromUrl(): string | null {
    const params = new URLSearchParams(this.getLocationSearchValue())
    return params.get('selectedTenant')
  }

  public static getDateFiltersVerb = (filterModel: any): string => comparableDateVerbs[filterModel.type]

  public static isoRegionalSetting(rs?: LocaleInfo) {
    if (rs) {
      if (rs.iso === 'cn') {
        return rs.locale
      } else {
        return rs.iso
      }
    } else {
      return Constants.regionalSettings[0].iso
    }
  }

  public static hasAnySkus(orgSkus: ReadonlyArray<string>, skus: string[]): boolean {
    return orgSkus.some((s) => skus.includes(s))
  }

  /**
   * Returns difference between two dates
   *
   * @param dateToCheck - the date to check
   * @param targetDate - the target date
   *
   * @returns days
   */
  public static readonly differenceDays = (dateToCheck: dayjs.Dayjs, targetDate: dayjs.Dayjs): number =>
    dayjs.utc(dateToCheck).diff(dayjs.utc(targetDate), 'day')

  /**
   * Returns form validator for date in the past
   *
   * @param dateToCheck - the date to check
   *
   * @returns ValidationErrors
   */
  public static readonly pastValidator = (dateToCheck: dayjs.Dayjs): ValidationErrors | null =>    
      dateToCheck < dayjs() ? { past: { value: true }} : null

  /**
   * Returns form validator for max date exceed
   *
   * @param dateToCheck - the date to check
   * @param maxDate - the target date
   *
   * @returns ValidationErrors
   */
  public static readonly maxDateValidator = (dateToCheck: dayjs.Dayjs, maxDate: dayjs.Dayjs): ValidationErrors | null =>    
      dateToCheck > maxDate ? { maxDateExcedeed: { value: true }} : null

  /**
   * Compares two objects for equality, considering undefined and empty objects as equal.
   *
   * @param obj1 - The first object to compare.
   * @param obj2 - The second object to compare.
   *
   * @return true if both objects are equal, or if both are undefined or empty; otherwise, false.
   */
  public static areObjectsEqual(obj1: any, obj2: any): boolean {
    if ((obj1 === undefined || isEmpty(obj1)) && (obj2 === undefined || isEmpty(obj2))) {
      return true
    }
    return isEqual(obj1, obj2)
  }

  /**
   * Customizer function for lodash isEqualWith to consider null, undefined, empty strings,
   * and arrays as equal.
   *
   * @param objValue - The first value to compare.
   * @param othValue - The second value to compare.
   *
   * @return true if values are considered equal, otherwise undefined to fall back to default comparison.
   */
  public static readonly customizer = (objValue: any, othValue: any): boolean | undefined => {
    if (Helpers.areBothNullOrEmpty(objValue, othValue)) {
      return true;
    }

    if (Helpers.areBothArrays(objValue, othValue)) {
      return Helpers.arraysAreEqual(objValue, othValue);
    }

    if (Helpers.areBothObjects(objValue, othValue)) {
      return Helpers.objectsAreEqual(objValue, othValue);
    }

    return undefined;
  };

  private static areBothNullOrEmpty = (objValue: any, othValue: any): boolean => {
    return (objValue === null || objValue === undefined || objValue === '') &&
           (othValue === null || othValue === undefined || othValue === '');
  };

  private static areBothArrays = (objValue: any, othValue: any): boolean => {
    return Array.isArray(objValue) && Array.isArray(othValue);
  };

  private static arraysAreEqual = (objValue: any[], othValue: any[]): boolean => {
    return objValue.length === othValue.length &&
           objValue.every((item, index) => isEqualWith(item, othValue[index], Helpers.customizer));
  };

  private static areBothObjects = (objValue: any, othValue: any): boolean => {
    return typeof objValue === 'object' && objValue !== null &&
           typeof othValue === 'object' && othValue !== null;
  };

  private static objectsAreEqual = (objValue: any, othValue: any): boolean => {
    const objKeys = Object.keys(objValue);
    const othKeys = Object.keys(othValue);

    if (objKeys.length !== othKeys.length) {
      return false;
    }

    return objKeys.every(key => isEqualWith(objValue[key], othValue[key], Helpers.customizer));
  };

  /**
   * Checks if two objects are equal considering custom rules.
   *
   * @param obj1 - The first object to compare.
   * @param obj2 - The second object to compare.
   *
   * @return true if both objects are equal based on the custom rules.
   */

  public static areObjectsEqualCustom(obj1: any, obj2: any): boolean {
    return isEqualWith(obj1, obj2, Helpers.customizer)
  }

  public static getSavedColumns(columns: (ColDef<any> & CoreViewColumn)[], columnDefs: (ColDef & CoreViewColumn)[], excludedColumns: string[]) {
    return [...columns, ...columnDefs.filter((x) => !!x.notSelectable)]
      .filter((x) => !!x)
      .filter((x) => !!x.originalName && !excludedColumns.includes(x.originalName))
  }
}

import { ValidatorFn } from '@angular/forms'

export class FormHelpers {
   /**
    * A conditional validator generator. Assigns a validator to the form control if the predicate function returns true
    * on the moment of validation
    *
    * @example
    * Here if the myCheckbox is set to true, the myEmailField will be required and also the text will have to have
    * the word 'mason' in the end.
    * If it doesn't satisfy these requirements, the errors will placed to the dedicated `groupedError` namespace.
    * Also the myEmailField will always have `maxLength`, `minLength` and `pattern` validators.
    * ngOnInit() {
    *   this.myForm = this.fb.group({
    *    myCheckbox: [''],
    *    myEmailField: ['', [
    *       Validators.maxLength(250),
    *       Validators.minLength(5),
    *       Validators.pattern(/.+@.+\..+/),
    *       conditionalValidator(() => this.myForm.get('myCheckbox').value,
    *                            Validators.compose([
    *                            Validators.required,
    *                            Validators.pattern(/.*mason/)
    *         ]),
    *        'groupedError')
    *        ]]
    *     })
    * }
    * @param predicate
    * @param validator
    * @param errorNamespace optional argument that creates own namespace for the validation error
    */
    public static conditionalValidator(predicate: () => boolean, validator: ValidatorFn, errorNamespace?: string): ValidatorFn {
      return (formControl => {
        if (!formControl.parent) {
          return null
        }
        let error = null
        if (predicate()) {
          error = validator(formControl)
        }
        if (errorNamespace && error) {
          const customError = {};
          (customError as any)[errorNamespace] = error
          error = customError
        }
        return error
      })
    }

    /**
     * Function that verifies password strength following the rules:
     * Must contain from 9 to 16 characters
     * a combination of letters and at least one number or symbol.
     *
     * @param password - the password string to be validated
     *
     * @returns true if password is weak, false if password is strong
     */
    public static passwordIsWeak(password: string): boolean {
      if (!password) {
        return true
      } else {
        const regex = /[$-/:-?{-~!"^_`\[\]]/g
        const length = password.length >= 8 && password.length <= 16
        const lowerLetters = /[a-z]+/.test(password)
        const upperLetters = /[A-Z]+/.test(password)
        const numbers = /[0-9]+/.test(password)
        const symbols = regex.test(password)

        if (length && (lowerLetters || upperLetters) && (numbers || symbols)) {
          return false
        } else {
          return true
        }
      }
    }
}

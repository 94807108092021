/* eslint-disable @typescript-eslint/naming-convention */
import { LicensePoolGroup } from './../models/LicensePoolGroup'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { UserGroup, VTenantPhoneNumber } from './../models/UserGroup'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { combineLatest, Observable, of } from 'rxjs'
import { filter, map, mergeMap, pluck } from 'rxjs/operators'
import { ApiclientService } from './apiclient.service'
import { DistributionGroup } from '../models/DistributionGroup'
import { ServerResponse } from '../models/ServerResponse'
import { SecurityGroup } from '../models/SecurityGroup'
import { Office365Group } from '../models/Office365Group'
import { UserMember } from '../models/UserMember'
import { Field } from '../models/Field'
import { ApiDataParameters } from '../models/ApiDataParameters'
import { CustomMenu } from '../models/CustomMenu'
import { LicensePool, LicenseTemplateResponse } from '../models/LicensePool'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { selectUserSettings } from '@app/store/userSettings/userSettings.selectors'
import { Verb } from '../models/PageDataCommonClasses'
import { GraphManagementConfig } from '../models/GraphManagementConfig'

@Injectable({
  providedIn: 'root',
})
export class AdministrationsService {
  constructor(
    private apiclient: ApiclientService,
    private storage: LocalstorageService,
    private store: Store<RootState>,
    private httpClient: HttpClient
  ) {}

  get basePortalApiUrl(): string {
    return this.apiclient.basePortalApiUrl
  }
  /**
   * Fetches operators and groups of operators
   *
   * @returns array of operators
   */
  getOperatorsAndGroups(): Observable<UserMember[]> {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/operatorsAndGroups`, { withCredentials: true })
  }

  getUserGroups(params: ApiDataParameters): Observable<ServerResponse<UserGroup>[]> {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/securitymanager/usergroups/selectedcompany?metadata=true`, {
      params: params as any,
      withCredentials: true,
    })
  }

  getManagedTenantsUserGroups(params = { pageNumber: 1, pageSize: 10000 }): Observable<UserGroup[]> {
    return this.httpClient
      .get<any>(`${this.apiclient.basePortalApiUrl}/securitymanager/usergroups`, {
        params,
        withCredentials: true,
      })
      .pipe(pluck('userGroups'))
  }

  getUserGroup(id: string): Observable<UserGroup> {
    return this.httpClient
      .get<any>(`${this.apiclient.basePortalApiUrl}/securitymanager/usergroups/${id}?metadata=true`, {
        withCredentials: true,
      })
      .pipe(pluck('userGroup'))
  }

  saveUserGroup(userGroup: UserGroup): Observable<UserGroup> {
    if (userGroup.id) {
      return this.httpClient
        .put<any>(
          `${this.apiclient.basePortalApiUrl}/securitymanager/usergroups`,
          { userGroup },
          {
            withCredentials: true,
          }
        )
        .pipe(pluck('userGroup'))
    }
    return this.httpClient
      .post<any>(
        `${this.apiclient.basePortalApiUrl}/securitymanager/usergroups/?metadata=true`,
        { userGroup },
        {
          withCredentials: true,
        }
      )
      .pipe(pluck('userGroup'))
  }

  deleteUserGroup(id: string): Observable<void> {
    return this.httpClient.delete<any>(`${this.apiclient.basePortalApiUrl}/securitymanager/usergroups/?id=${id}`, {
      withCredentials: true,
    })
  }

  getDistributionGroups(params: ApiDataParameters): Observable<ServerResponse<DistributionGroup>> {
    return this.httpClient.get<ServerResponse<DistributionGroup>>(`${this.apiclient.basePortalApiUrl}/distributiongroups?metadata=true`, {
      params: params as any,
      withCredentials: true,
    })
  }

  getSecurityGroups(params: ApiDataParameters): Observable<ServerResponse<SecurityGroup>> {
    return this.httpClient.get<ServerResponse<SecurityGroup>>(`${this.apiclient.basePortalApiUrl}/securitygroups?metadata=true`, {
      params: params as any,
      withCredentials: true,
    })
  }

  getOffice365Groups(params: ApiDataParameters): Observable<ServerResponse<Office365Group>> {
    return this.httpClient.get<ServerResponse<Office365Group>>(`${this.apiclient.basePortalApiUrl}/office365Groups?metadata=true`, {
      params: params as any,
      withCredentials: true,
    })
  }

  getMembers(): Observable<UserMember[]> {
    return this.httpClient.get<UserMember[]>(
      `${this.apiclient.basePortalApiUrl}/users/?metadata=true&includeMasterTenantUsers=true
      `,
      {
        withCredentials: true,
      }
    )
  }

  getAcceptedDomains(): Observable<ServerResponse<any>> {
    return this.httpClient.get<any>(
      `${this.apiclient.basePortalApiUrl}/accepteddomains
      `,
      {
        withCredentials: true,
      }
    )
  }

  getDistributionGroupFields(): Observable<Field[]> {
    return this.httpClient
      .get<any[]>(
        `${this.apiclient.basePortalApiUrl}/distributiongroup/fields?metadata=true
      `,
        {
          withCredentials: true,
        }
      )
      .pipe(pluck('fields'))
  }

  getSecurityGroupFields(): Observable<Field[]> {
    return this.httpClient
      .get<any[]>(
        `${this.apiclient.basePortalApiUrl}/securityGroup/fields?metadata=true
      `,
        {
          withCredentials: true,
        }
      )
      .pipe(pluck('fields'))
  }

  getTeamsGroupFields(): Observable<Field[]> {
    return this.httpClient
      .get<any[]>(
        `${this.apiclient.basePortalApiUrl}/teamsGroup/fields?metadata=true
      `,
        {
          withCredentials: true,
        }
      )
      .pipe(pluck('fields'))
  }

  getOffice365GroupFields(): Observable<Field[]> {
    return this.httpClient
      .get<any[]>(
        `${this.apiclient.basePortalApiUrl}/office365Group/fields?metadata=true
      `,
        {
          withCredentials: true,
        }
      )
      .pipe(pluck('fields'))
  }

  getDevicesFields(): Observable<Field[]> {
    return this.httpClient
      .get<any[]>(
        `${this.apiclient.basePortalApiUrl}/msoldevices/fields?metadata=true
      `,
        {
          withCredentials: true,
        }
      )
      .pipe(pluck('fields'))
  }

  getPhoneNumbersFields(): Observable<Field[]> {
    return this.httpClient
      .get<any[]>(
        `${this.apiclient.basePortalApiUrl}/teamsphonenumbers/fields?metadata=true
      `,
        {
          withCredentials: true,
        }
      )
      .pipe(pluck('fields'))
  }

  getConsumedNumbers(params: ApiDataParameters, userGroup: UserGroup): Observable<ServerResponse<any>> {
    return this.httpClient.post<any>(
      `${this.apiclient.basePortalApiUrl}/teams/teamsphonenumbersvtenantassigned?metadata=true
      `,
      {
        ...params,
        userGroup,
      },
      {
        withCredentials: true,
      }
    )
  }

  getSpareNumbersFromVTenantData(params: ApiDataParameters, userGroup: UserGroup): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiclient.basePortalApiUrl}/teams/teamsphonenumbersvtenantunassigned?metadata=true
      `,
      {
        ...params,
        userGroup,
      },
      {
        withCredentials: true,
      }
    )
  }

  getSpareNumbersFromVTenantId(params: ApiDataParameters, userGroupId: string): Observable<any> {
    return this.httpClient.get<any[]>(
      `${this.apiclient.basePortalApiUrl}/teams/teamsphonenumbersvtenantunassignedstats?metadata=true
      `,
      {
        withCredentials: true,
        params: {
          ...params,
          userGroupId,
        } as any,
      }
    )
  }

  getSpareNumbersForDynamic(params: ApiDataParameters, vTenantPhoneNumbers: VTenantPhoneNumber): Observable<any> {
    return this.httpClient.post<any[]>(
      `${this.apiclient.basePortalApiUrl}/teams/teamsphonenumbersunassigned?metadata=true`,
      { ...params, vTenantPhoneNumbers },
      { withCredentials: true }
    )
  }

  downloadVTenants(managedTenantId: string, ids: string[]): Observable<{ category: string; resources: any }> {
    return this.httpClient.put<any>(
      `${this.apiclient.basePortalApiUrl}/model/export/${managedTenantId}/VTenant/`,
      { ids },
      {
        withCredentials: true,
      }
    )
  }

  uploadVTenants(managedTenantId: string, resources: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiclient.basePortalApiUrl}/model/import/${managedTenantId}/VTenant/?metadata=true`,
      { resources },
      {
        withCredentials: true,
      }
    )
  }

  /**
   * Fetches roles
   *
   * @returns list of roles
   */
  getRoles(): Observable<string[]> {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/roles`, { withCredentials: true }).pipe(pluck('roleNames'))
  }

  /**
   * Fetches custom menus (permissions)
   *
   * @returns list of custom menus (permissions)
   */
  getCustomMenus(params = { pageNumber: 1, pageSize: 10000, sort: 'Name', sortOrder: 'asc' }): Observable<CustomMenu[]> {
    return this.getCustomMenusWithMetadata(params).pipe(pluck('customMenus'))
  }

  getCustomMenusWithMetadata(params: any = {}): Observable<ServerResponse<CustomMenu>> {
    delete params.reportTreeFilters
    return this.httpClient.get<ServerResponse<CustomMenu>>(`${this.apiclient.basePortalApiUrl}/securitymanager/customMenus?metadata=true`, {
      withCredentials: true,
      params,
    })
  }

  getCustomMenuMembership(params: any = {}): Observable<CustomMenu[]> {
    return this.httpClient
      .get<ServerResponse<CustomMenu>>(`${this.apiclient.basePortalApiUrl}/securitymanager/customMenu`, { withCredentials: true, params })
      .pipe(pluck('customMenus'))
  }

  getCustomMenu(id: string): Observable<CustomMenu> {
    return this.httpClient
      .get(`${this.apiclient.basePortalApiUrl}/securitymanager/customMenus/${id}`, { withCredentials: true })
      .pipe(pluck('customMenu'))
  }

  downloadCustomMenu(managedTenantId: string, ids: string[]): Observable<{ category: string; resources: any }> {
    return this.httpClient.put<any>(
      `${this.apiclient.basePortalApiUrl}/model/export/${managedTenantId}/Permission/`,
      { ids },
      {
        withCredentials: true,
      }
    )
  }

  uploadCustomMenu(managedTenantId: string, resources: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiclient.basePortalApiUrl}/model/import/${managedTenantId}/Permission/?metadata=true`,
      { resources },
      {
        withCredentials: true,
      }
    )
  }

  downloadLicenseTemplate(managedTenantId: string, ids: string[]): Observable<{ category: string; resources: any }> {
    return this.httpClient.put<any>(
      `${this.apiclient.basePortalApiUrl}/model/export/${managedTenantId}/LicenseTemplate/`,
      { ids },
      {
        withCredentials: true,
      }
    )
  }

  uploadLicenseTemplate(managedTenantId: string, resources: any): Observable<any> {
    const params = {
      metadata: true,
      reportNameContainer: 'Manage license templates',
    }
    return this.httpClient.post<any>(
      `${this.apiclient.basePortalApiUrl}/model/import/${managedTenantId}/LicenseTemplate`,
      { Resources: resources },
      {
        withCredentials: true,
        params,
      }
    )
  }

  saveCustomMenu(customMenu: CustomMenu): Observable<CustomMenu> {
    if (customMenu.id) {
      return this.httpClient.put<any>(
        `${this.apiclient.basePortalApiUrl}/securitymanager/customMenus/`,
        { customMenu },
        {
          withCredentials: true,
        }
      )
    }
    return this.httpClient.post<any>(
      `${this.apiclient.basePortalApiUrl}/securitymanager/customMenus/?metadata=true`,
      { customMenu },
      {
        withCredentials: true,
      }
    )
  }

  deleteCustomMenu(id: string): Observable<void> {
    return this.httpClient.delete<any>(`${this.apiclient.basePortalApiUrl}/securitymanager/customMenus/?id=${id}`, {
      withCredentials: true,
    })
  }

  getFieldsValues(entity: string, fieldName?: string, searchedValue?: string): Observable<any> {
    const url = `${this.apiclient.basePortalApiUrl}/${entity}/fieldvalues/`

    if (!fieldName || !searchedValue || searchedValue.length < 2) {
      return of({
        values: [],
      })
    }

    const params = {
      metadata: true,
      fieldName,
      searchedValue,
    } as any

    return this.httpClient.get<any>(url, { withCredentials: true, params }).pipe(
      map((data) => ({
        values: data?.values?.filter((value: string) => value.trim() !== '') || [],
      }))
    )
  }

  /**
   * Fetches License Pools
   *
   * @returns list of License Pools
   */
  getLicensePoolGroups(params = { pageNumber: 1, pageSize: 10000 }): Observable<LicensePool[]> {
    return this.httpClient
      .get<ServerResponse<LicensePool>>(`${this.apiclient.basePortalApiUrl}/licensepool/groups`, { params, withCredentials: true })
      .pipe(pluck('licensePoolGroups'))
  }

  getLicensePools(params: any = {}) {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/licensepool/groups?metadata=true`, {
      withCredentials: true,
      params,
    })
  }

  getAlertConfigurations(workload: string, operations: string[]) {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/alertnotifications/configuration`, {
      withCredentials: true,
      params: {
        objectName: workload,
        actionsName: operations,
      },
    })
  }

  getLicensePoolsReport(params: any = {}) {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/licensepool/report?metadata=true`, {
      withCredentials: true,
      params,
    })
  }

  getLicensePoolInfos(params: any = {}): Observable<ServerResponse<any>> {
    // params = this.stringifyParams(params)
    return this.httpClient.get<ServerResponse<any>>(
      `${this.apiclient.basePortalApiUrl}/securitymanager/licensepools/?metadata=true&pageNumber=1&pageSize=1000`,
      {
        withCredentials: true,
        params,
      }
    )
  }

  getLicensePoolFilters(): Observable<ServerResponse<any>> {
    return this.httpClient.get<ServerResponse<any>>(`${this.apiclient.basePortalApiUrl}/licensepool/filters/?metadata=true`, {
      withCredentials: true,
    })
  }

  getLicensePoolConfiguration(): Observable<ServerResponse<any>> {
    return this.httpClient.get<ServerResponse<any>>(`${this.apiclient.basePortalApiUrl}/licensepool/configuration`, {
      withCredentials: true,
    })
  }

  getCurrencies(): Observable<ServerResponse<any>> {
    return this.httpClient.get<ServerResponse<any>>(`${this.apiclient.basePortalApiUrl}/currencies`, {
      withCredentials: true,
    })
  }

  updateAssignedMultipleLicensePools(params: any) {
    return this.httpClient.put<any>(`${this.apiclient.basePortalApiUrl}/licensepool/multipleupdateassigned`, params, {
      withCredentials: true,
    })
  }

  updateAccountSku(params: any) {
    return this.httpClient.put<any>(`${this.apiclient.basePortalApiUrl}/accountSku`, params, { withCredentials: true })
  }

  submitAction(apiUrl: string, verb: Verb, params: any) {
    const url = `${this.apiclient.basePortalApiUrl}/${apiUrl}`
    if (verb === 'put') {
      return this.httpClient.put<any>(url, params, { withCredentials: true })
    }
    return this.httpClient.post<any>(url, params, { withCredentials: true })
  }

  deleteLicensePool(ids: string[]): Observable<void> {
    const params = { idList: ids }
    return this.httpClient.post<any>(`${this.apiclient.basePortalApiUrl}/licensepool/remove/?metadata=true`, params, {
      withCredentials: true,
    })
  }

  clearLicensePoolConfiguration(): Observable<any> {
    return this.httpClient.post<any>(`${this.apiclient.basePortalApiUrl}/licensepool/configuration/clear/`, null, {
      withCredentials: true,
    })
  }

  getLicensePoolNames() {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/licensepool/groupsnames`, {
      withCredentials: true,
    })
  }

  getCompanyLicensePools(): Observable<LicensePoolGroup[]> {
    return this.httpClient
      .get<any[]>(`${this.apiclient.basePortalApiUrl}/securitymanager/licensepools/company`, { withCredentials: true })
      .pipe(pluck('licensePoolGroups'))
  }

  getAvailableLicensePools(): Observable<LicensePoolGroup[]> {
    return this.httpClient
      .get<any>(`${this.apiclient.basePortalApiUrl}/securitymanager/licensepools/available/`, {
        withCredentials: true,
      })
      .pipe(pluck('licensePoolGroups'))
  }

  setCompanyLicensePoolsFilter(filterLicense: { licensePoolGroupId: string } | null): Observable<void> {
    if (filterLicense) {
      return this.httpClient.post<void>(
        `${this.apiclient.basePortalApiUrl}/securitymanager/licensepoolgroupfilters/select`,
        filterLicense,
        {
          withCredentials: true,
        }
      )
    } else {
      return this.httpClient.delete<void>(`${this.apiclient.basePortalApiUrl}/securitymanager/licensepoolgroupfilters`, {
        withCredentials: true,
      })
    }
  }

  getUserGroupsSelectedCompany(params = { pageNumber: 1, pageSize: 10000, sort: 'Name', sortOrder: 'asc' }): Observable<UserGroup[]> {
    return this.httpClient
      .get<any[]>(`${this.apiclient.basePortalApiUrl}/securitymanager/usergroups/selectedcompany/`, { params, withCredentials: true })
      .pipe(pluck('userGroups'))
  }

  getUserGroupsLoggedUser(): Observable<{ companyName: string; tenantId: string; userGroupId: string; userGroupName: string }[]> {
    return this.httpClient.get<any[]>(`${this.apiclient.basePortalApiUrl}/securitymanager/usergroups/loggeduser/`, {
      withCredentials: true,
    })
  }

  setUserGroups(userGroupIds: string[] | null) {
    if (userGroupIds && userGroupIds.length > 0) {
      return this.httpClient.put<void>(
        `${this.apiclient.basePortalApiUrl}/securitymanager/usergroups/membership`,
        { user: this.storage.getLoggedUser().userName, userGroupIds, excludedManagedTenants: true },
        {
          withCredentials: true,
        }
      )
    } else {
      return this.httpClient.post<void>(
        `${this.apiclient.basePortalApiUrl}/securitymanager/usergroups/clear`,
        { user: this.storage.getLoggedUser().userName },
        {
          withCredentials: true,
        }
      )
    }
  }

  getVtenantSubfilter() {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/securitymanager/usergroups/subfilter`, {
      withCredentials: true,
    })
  }

  setVTenantSubfilter(userGroupId: string) {
    const url = `${this.apiclient.basePortalApiUrl}/securitymanager/usergroups/subfilter`
    return this.httpClient.post<void>(url, { userGroupId }, { withCredentials: true })
  }

  setCustomMenusMembership(customMenuIds: string[] | null) {
    if (customMenuIds && customMenuIds.length > 0) {
      return this.httpClient.put<void>(
        `${this.apiclient.basePortalApiUrl}/securitymanager/customMenus/membership`,
        { user: this.storage.getLoggedUser().userName, customMenuIds },
        {
          withCredentials: true,
        }
      )
    } else {
      return this.store.select(selectUserSettings).pipe(
        filter((x) => !!x),
        mergeMap((settings) =>
          this.httpClient.post<void>(
            `${this.apiclient.basePortalApiUrl}/securitymanager/customMenus/clear`,
            { user: this.storage.getLoggedUser().userName, defaultCustomMenuId: settings?.defaultCustomMenuId },
            {
              withCredentials: true,
            }
          )
        )
      )
    }
  }

  removeUserFromAllFilters() {
    return this.store.select(selectUserSettings).pipe(
      filter((x) => !!x),
      mergeMap((settings) =>
        this.httpClient.post<void>(
          `${this.apiclient.basePortalApiUrl}/securitymanager/removeallfilters`,
          { user: this.storage.getLoggedUser().userName, defaultCustomMenuId: settings?.defaultCustomMenuId },
          {
            withCredentials: true,
          }
        )
      )
    )
  }

  uploadLogo(formData: FormData): Observable<void> {
    return this.httpClient.post<void>(`${this.apiclient.basePortalApiUrl}/organization/logo/`, formData, {
      withCredentials: true,
    })
  }

  deleteLogo(): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiclient.basePortalApiUrl}/organization/logo/`, {
      withCredentials: true,
    })
  }

  uploadSmallLogo(formData: FormData): Observable<void> {
    return this.httpClient.post<void>(`${this.apiclient.basePortalApiUrl}/organization/smallLogo/`, formData, {
      withCredentials: true,
    })
  }

  deleteSmallLogo(): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiclient.basePortalApiUrl}/organization/smallLogo/`, {
      withCredentials: true,
    })
  }

  uploadFavicon(formData: FormData): Observable<void> {
    return this.httpClient.post<void>(`${this.apiclient.basePortalApiUrl}/organization/favicon/`, formData, {
      withCredentials: true,
    })
  }

  deleteFavicon(): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiclient.basePortalApiUrl}/organization/favicon/`, {
      withCredentials: true,
    })
  }

  getAdminReadonlyRecreateAllStatus(): Observable<{
    isInProgress: boolean
    isSuccessful: boolean
    lastExecutionDate?: string
    message?: string
    responseStatus?: { errorCode: string; message: string }
  }> {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/v2/management/adminreadonlyrecreateallstatus`, {
      withCredentials: true,
    })
  }

  adminconsentCall = () =>
    this.httpClient.get<{ isCvGraphApiEnabled: boolean; cvGraphApiConsentUrl: string; isCvRegistrationAppEnabled: boolean; cvRegistrationAppConsentUrl: string }>(
      `${this.apiclient.basePortalApiUrl}/adminconsenturls/${this.storage.selectedOrganization.id}?redirectUrl=${location.origin}/consent`,
      { withCredentials: true }
    )

  private enablemanagementCall = (apiEnabledNeeded: boolean) =>
    apiEnabledNeeded
      ? this.httpClient.get<{ url: string }>(
          `${this.apiclient.basePortalApiUrl}/generateenablemanagementurl?redirectUrl=${location.origin}/consent`,
          { withCredentials: true }
        )
      : of({ url: '' })

  private graphManagementCall = (isGraphManagementConsentGiven: boolean) =>
    isGraphManagementConsentGiven
      ? this.httpClient.post<{ isConsentNeeded: boolean; url: string }>(
          `${this.apiclient.basePortalApiUrl}/graphManagement/consentUrl`,
          { RedirectUrl: `${location.origin}/consent`, Application: 'Intune' },
          { withCredentials: true }
        )
      : of({ isConsentNeeded: false, url: '' })

  private sharepointCall = () =>
    this.httpClient.get<{ isConsentNeeded: boolean; consentUrl: string }>(
      `${this.apiclient.basePortalApiUrl}/sharepoint/consentUrl?redirectUrl=${location.origin}/consent`,
      {
        withCredentials: true,
      }
    )

  private bitlockerCall = () =>
    this.httpClient.get<{ isConsentNeeded: boolean; consentUrl: string }>(
      `${this.apiclient.basePortalApiUrl}/graph/bitLockerConsentUrl?redirectUrl=${location.origin}/consent`,
      {
        withCredentials: true,
      }
    )
  private exchangeCall = (companyId: string, tenantID: string) =>
    this.httpClient.get<{ name: string; isConsentNeeded: boolean; consentUrl: string }[]>(
      `${this.apiclient.basePortalApiUrl}/organization/exchangeintegrationapp`,
      {
        withCredentials: true,
        params: { companyId: companyId, tenantID: tenantID },
      }
    )
  private teamsCall = (companyId: string, tenantID: string) =>
    this.httpClient.get<{ name: string; isConsentNeeded: boolean; consentUrl: string }[]>(
      `${this.apiclient.basePortalApiUrl}/organization/teamsintegrationapp`,
      {
        withCredentials: true,
        params: { companyId: companyId, tenantID: tenantID },
      }
    )
  getGivenConsents(
    apiEnabledNeeded: boolean,
    isGraphManagementConsentGiven: boolean,
    companyId: string,
    tenantID: string,
    portalSku: readonly string[]
  ) {
    const calls = [
      this.enablemanagementCall(apiEnabledNeeded),
      this.adminconsentCall(),
      this.graphManagementCall(isGraphManagementConsentGiven),
      this.sharepointCall(),
      this.bitlockerCall(),
      this.exchangeCall(companyId, tenantID),
    ]
    if (portalSku.includes('SKU:CORETEAMS')) {
      calls.push(this.teamsCall(companyId, tenantID))
    }

    return combineLatest(calls).pipe(
      map((consent) => {
        const resConsent = []
        resConsent.push({
          name: 'CoreView Api Integration',
          isConsentNeeded: apiEnabledNeeded,
          consentUrl: (consent[0] as { url: string }).url,
        })
        resConsent.push({
          name: 'CoreView Graph Consent',
          isConsentNeeded: !(consent[1] as { isCvGraphApiEnabled: boolean; cvGraphApiConsentUrl: string }).isCvGraphApiEnabled,
          consentUrl: (consent[1] as { isCvGraphApiEnabled: boolean; cvGraphApiConsentUrl: string }).cvGraphApiConsentUrl,
        })
        resConsent.push({
          name: 'CoreView Endpoint Management',
          isConsentNeeded: (consent[2] as { isConsentNeeded: boolean; url: string }).isConsentNeeded,
          consentUrl: (consent[2] as { isConsentNeeded: boolean; url: string }).url,
        })
        resConsent.push({ name: 'CoreView SharePoint Integration', ...consent[3] })
        if (consent[6] && (consent[6] as { name: string; isConsentNeeded: boolean; consentUrl: string }[]).length > 0) {
          resConsent.push(
            ...(consent[6] as { name: string; isConsentNeeded: boolean; consentUrl: string }[]).map((x) => ({
              ...x,
              consentUrl: `${x.consentUrl}&redirect_uri=${location.origin}/consent`,
            }))
          )
        }
        resConsent.push({ name: 'CoreView BitLocker Integration', ...consent[4] })
        resConsent.push(
          ...(consent[5] as { name: string; isConsentNeeded: boolean; consentUrl: string }[]).map((x) => ({
            ...x,
            consentUrl: `${x.consentUrl}&redirect_uri=${location.origin}/consent`,
          }))
        )
        return resConsent
      })
    )
  }

  getSharepointConsentUrl(): Observable<{ url: string }> {
    const url = `${this.apiclient.basePortalApiUrl}/sharepoint/consentUrl/`
    return this.httpClient.post<{ url: string }>(url, { redirectUrl: `${location.origin}/consent` }, { withCredentials: true })
  }

  getGraphManagementConfig(companyId: string): Observable<GraphManagementConfig> {
    const url = `${this.apiclient.basePortalApiUrl}/organization/noparam/graphmanagement/configuration?companyId=${companyId}`
    return this.httpClient.get<GraphManagementConfig>(url, { withCredentials: true })
  }

  deleteGraphManagementConfig(companyId: string) {
    const url = `${this.apiclient.basePortalApiUrl}/organization/noparam/graphmanagement/configuration?companyId=${companyId}`
    return this.httpClient.delete<any>(url, { withCredentials: true })
  }

  saveGraphManagementConfig(params: { companyId: string; clientId: string; clientSecret: string; expirationDate: Date }) {
    const url = `${this.apiclient.basePortalApiUrl}/organization/noparam/graphmanagement/configuration?companyId=${params.companyId}`
    return this.httpClient.post<any>(url, params, { withCredentials: true })
  }

  recreateAllAdmins(): Observable<void> {
    return this.httpClient.put<any>(`${this.apiclient.basePortalApiUrl}/v2/management/adminreadonlyrecreateall`, null, {
      withCredentials: true,
    })
  }

  setExchangeRole(): Observable<void> {
    return this.httpClient.post<any>(`${this.apiclient.basePortalApiUrl}/management/setexchangerole`, null, {
      withCredentials: true,
    })
  }

  getLicenseTemplateById(id?: string): Observable<LicenseTemplateResponse> {
    const qs = id ? `?templateId=${id}` : ''
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/licences/items/template/${qs}`, {
      withCredentials: true,
    })
  }

  getLicenseTemplates(params: any = {}) {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/licences/items/templates?metadata=true`, {
      withCredentials: true,
      params,
    })
  }

  getCheckLicenseTemplateUsage(templateIds: string[]): Observable<boolean> {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/licences/items/template/usage`, {
      withCredentials: true,
      params: {
        templateIds,
      },
    })
  }

  clearLicenseTemplateFromPermission(templateIds: string[]): Observable<void> {
    const params = { templateIds }
    return this.httpClient.put<any>(`${this.apiclient.basePortalApiUrl}/licences/items/template/clear/permission`, params, {
      withCredentials: true,
    })
  }

  deleteLicenseTemplate(id: string): Observable<void> {
    return this.httpClient.delete<any>(`${this.apiclient.basePortalApiUrl}/licences/items/template/?templateId=${id}`, {
      withCredentials: true,
    })
  }

  duplicateLicenseTemplate(id: string): Observable<void> {
    const params = { id }
    return this.httpClient.post<any>(`${this.apiclient.basePortalApiUrl}/licences/items/template/duplicate/`, params, {
      withCredentials: true,
    })
  }

  saveLicenseTemplate(licenseTemplate: any): Observable<void> {
    const params = { licenseTemplate }
    return this.httpClient.put<any>(`${this.apiclient.basePortalApiUrl}/licences/items/template/`, params, {
      withCredentials: true,
    })
  }

  getOnlineUsersByActivePlansGroup(params: any, companyId: string) {
    return this.httpClient.post<any>(
      `${this.apiclient.basePortalApiUrl}/licenses/optmization/${companyId}/onlineusersbyactiveplansgroup?metadata=true`,
      params,
      {
        withCredentials: true,
      }
    )
  }

  getLicenseOptimizationAvailableSkus(companyId: string): Observable<ServerResponse<any>> {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/licenses/optmization/${companyId}/availableskus?metadata=true`, {
      withCredentials: true,
    })
  }

  getLicenseOptimizationActiveServices(companyId: string, sku: string, params: any = {}): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiclient.basePortalApiUrl}/licenses/optmization/${companyId}/activeservices/${encodeURIComponent(sku)}?metadata=true`,
      params,
      {
        withCredentials: true,
      }
    )
  }

  getLicenseOptimizationInactiveOnlineUsersCountBySkus(companyId: string, params: any = {}): Observable<void> {
    return this.httpClient.get<any>(
      `${this.apiclient.basePortalApiUrl}/licenses/optmization/${companyId}/inactiveonlineuserscountbyskus?metadata=true`,
      {
        withCredentials: true,
        params,
      }
    )
  }

  getLicenseByUser(params: any = {}) {
    return this.httpClient.post<any>(`${this.apiclient.basePortalApiUrl}/licences/?metadata=true`, params, {
      withCredentials: true,
    })
  }

  getLicensesSkuService(companyId: string): Observable<ServerResponse<any>> {
    return this.httpClient.get<any>(
      `${this.apiclient.basePortalApiUrl}/aggregatedreports/licenses/skuservice/?organizationIds=${companyId}&metadata=true`,
      {
        withCredentials: true,
      }
    )
  }

  getLicensesStatuses(): Observable<ServerResponse<any>> {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/licenses/statuses`, {
      withCredentials: true,
    })
  }

  getOnlineUsersByDuplicatedPlans(params: any, companyId: string) {
    return this.httpClient.post<any>(
      `${this.apiclient.basePortalApiUrl}/licenses/optmization/${companyId}/onlineusersduplicatedplans?metadata=true`,
      params,
      {
        withCredentials: true,
      }
    )
  }

  getOneDriveExternalUsers(params: any = {}) {
    return this.httpClient.get<any>(`${this.apiclient.basePortalApiUrl}/onedrive/externalusers?metadata=true`, {
      withCredentials: true,
      params,
    })
  }

  getSelectedDemoModeSkus(): Observable<string[]> {
    return this.httpClient
      .get<string[]>(`${this.apiclient.basePortalApiUrl}/organization/demomode/selectedskus`, { withCredentials: true })
      .pipe(pluck('skus'))
  }

  getGrantSupportAccess(): Observable<any[]> {
    return this.httpClient
      .get<any[]>(`${this.apiclient.basePortalApiUrl}/grantsupportaccess`, { withCredentials: true })
      .pipe(pluck('items'))
  }

  getGrantSupportAccessById(id: string): Observable<any> {
    return this.httpClient
      .get<any[]>(`${this.apiclient.basePortalApiUrl}/grantsupportaccess/${id}`, { withCredentials: true })
  }

  grantSupportAccess(supportAccess: any){ 
    return this.httpClient.post<string>(`${this.apiclient.basePortalApiUrl}/grantsupportaccess`,
    { supportAccess },
    {
      withCredentials: true,
    }
  )}

  grantDelegatedSupportAccess(supportAccess: any){ 
    return this.httpClient.post<void>(`${this.apiclient.basePortalApiUrl}/grantdelegatedsupportaccess`,
    { supportAccess },
    {
      withCredentials: true,
    }
  )}

  revokeAccess(id: string){ 
    return this.httpClient.put<string>(`${this.apiclient.basePortalApiUrl}/revokesupportaccess`,
    { id },
    {
      withCredentials: true,
    }
  )}

  extendAccess(id: string, newExpiration: string){ 
    return this.httpClient.put<string>(`${this.apiclient.basePortalApiUrl}/extendsupportaccess`,
    { id, newExpiration },
    {
      withCredentials: true,
    }
  )}

  getGrantedTenants(){ 
    return this.httpClient.get<any[]>(`${this.apiclient.basePortalApiUrl}/supportmanagedcompanies`, { withCredentials: true }
  )}
}

import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import {
  ApiErrorResponse,
  DownloadFileButtonDef,
  isDownload,
  isUpload,
  ManagementAction,
  UploadCsvButtonDef,
} from '@app/core/models/management-action'
import { ApplicationInsightService } from '@app/core/services/application-insight.service'
import { ManagementActionsService } from '@app/core/services/management-actions.service'
import { IBeforeClose, RightPanelRef, RightPanelService } from '@app/core/services/right-panel.service'
import { PanelStepsComponent } from '@app/shared/components/panel-steps/panel-steps.component'
import { EnhancedJsonFormData, EnhancedPropertyFormDescriptor } from '@app/shared/models/enhanced-json-form-data'
import { PanelStep } from '@app/shared/models/panel-step'
import { SummaryCard } from '@app/shared/models/summary-card'
import { Helpers } from '@app/shared/utilities/helpers'
import { TranslateHelper } from '@coreview/coreview-library'
import { selectedOrganization } from '@app/store/organizations/organizations.selectors'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { ToastService } from '@coreview/coreview-components'
import { FormBuilderComponent } from '@coreview/coreview-dynamoforms'
import { cloneDeep, get, isEqual, maxBy, set, union, uniq, uniqBy } from 'lodash-es'
import { Observable, of, timer } from 'rxjs'
import { filter, first, take } from 'rxjs/operators'
import { ManagementHelperService } from '../../utilities/management.helper.service'
import { UuidService } from '@app/shared/utilities/uuid.service'
import { SubmitErrorMessageDetailComponent } from './submit-error-message-detail/submit-error-message-detail.component'
import { DynamoFormHelperService } from '@app/shared/utilities/dynamo-form.helper.service'
import { ApiclientService } from '@app/core/services/apiclient.service'
import { TargetSelectionComponent, TargetSelectionDefinition } from '../target-selection/target-selection.component'
import { ActionButtonVisibility } from '@app/core/models/ReportDefinition'
import { Constants } from '@app/shared/utilities/constants'
import { JsonFormData } from '@coreview/coreview-dynamoforms/interfaces/json-form-data.interface'
import { DynamoFormSummaryHelperService } from '@app/shared/utilities/dynamo-form-summary.helper.service'
import { DialogHelperService } from '@app/shared/dialog-helper.service'

@Component({
  selector: 'app-action-panel',
  templateUrl: './action-panel.component.html',
  styleUrls: ['./action-panel.component.sass'],
})
export class ActionPanelComponent extends IBeforeClose implements OnInit {
  @ViewChildren(FormBuilderComponent)
  set setFormBuilders(forms: QueryList<FormBuilderComponent>) {
    if (forms?.length > 0) {
      this.formBuilder = forms.last
    }
  }

  @ViewChild(TargetSelectionComponent) set targetSelectionComp(component: TargetSelectionComponent) {
    if (!this.targetSelection) {
      this.targetSelection = component
    }
  }

  @ViewChild(PanelStepsComponent) set panelStepsComp(panelSteps: PanelStepsComponent) {
    if (panelSteps) {
      this.panelSteps = panelSteps
    }
  }

  @Input() isPreview = false
  @Input() action!: ManagementAction
  @Input() selectedTargets: any[] = []
  @Input() isCustomAction = false

  $notesFieldMandatory: Observable<boolean> = of()
  companyId?: string

  targetSelection?: TargetSelectionComponent
  targetSelectionDefinition!: TargetSelectionDefinition
  steps: PanelStep[] = []
  targetSelectionSubStep: PanelStep[] = []
  panelSteps?: PanelStepsComponent

  isSaving = false

  jsonFormData!: EnhancedJsonFormData | undefined
  form: UntypedFormGroup = this.fb.group({})
  uploadForm: UntypedFormGroup = this.fb.group({})
  formBuilder?: FormBuilderComponent
  propertiesToWatch: { [key: string]: EnhancedPropertyFormDescriptor } = {}

  initialValuesLoaded = false
  initialValues: any = {}
  summaryCards: SummaryCard[] = []
  panelSubtitle?: string
  errorMessage?: string
  reloadSelectTarget = false
  selectedTargetsChangedOnForm = false
  formBuilderReady = false
  isNotesRequired = true
  showPreviewBadge = false
  previewBadgeIdentifier = ''
  initialFormBuilderValues: any

  reviewForm = new UntypedFormGroup({
    note: new UntypedFormControl('', [Validators.required]),
  })

  basePortalApiUrl: string

  private submitted = false

  constructor(
    private translateHelper: TranslateHelper,
    private dynamoFormsHelper: DynamoFormHelperService,
    private dynamoFormSummaryHelperService: DynamoFormSummaryHelperService,
    private fb: UntypedFormBuilder,
    private managementHelperService: ManagementHelperService,
    private managementActionsService: ManagementActionsService,
    private toastService: ToastService,
    private appInsights: ApplicationInsightService,
    private rightPanelRef: RightPanelRef,
    private store: Store<RootState>,
    private router: Router,
    private uuidService: UuidService,
    private rightPanelService: RightPanelService,
    private apiClient: ApiclientService,
    private dialogHelperService: DialogHelperService,
  ) {
    super()
    this.basePortalApiUrl = apiClient.basePortalApiUrl
  }

  ngOnInit(): void {
    this.apiClient.trackAction(Constants.trackActons.actionSelected, Helpers.downcase(this.action.title)).subscribe(() => {})

    // when action comes from the store, it won't allow to modify any of it's properties
    this.action = { ...this.action }
    this.showPreviewBadge = !!this.action.reportTags?.find((tag) => tag.toLowerCase() === 'preview')
    this.previewBadgeIdentifier = this.action.title

    if (this.action.targetSelectionType !== 'None') {
      this.buildTargetSelectionDefinition()
    }

    this.store
      .select(selectedOrganization)
      .pipe(
        filter((x) => !!x),
        first()
      )
      .subscribe((company) => {
        this.companyId = company?.guid
        this.$notesFieldMandatory = !this.isCustomAction
          ? this.managementActionsService.getManagementActionNotesFieldMandatoryInfo(company?.guid || '')
          : of(false)
      })

    this.$notesFieldMandatory.pipe(take(1)).subscribe((notesMandatory) => {
      this.isNotesRequired = !!notesMandatory
      if (!notesMandatory) {
        this.reviewForm.controls.note.clearValidators()
      }

      if (!!this.action.formSchema && this.action.formSchema !== 'null') {
        this.dynamoFormsHelper.getFormattedJsonFormData(this.action.formSchema).subscribe((formData) => {
          if (formData) {
            this.jsonFormData = formData
            this.buildSteps(notesMandatory)
            this.propertiesToWatch = this.dynamoFormsHelper.getPropertiesToWatch(this.jsonFormData.properties)
          }
        })
      } else {
        this.buildSteps(notesMandatory)
      }
    })
  }

  canEnterStepPropertiesStep = () => this.action.targetSelectionType === 'None' || this.selectedTargets.length > 0

  buildSteps(notesMandatory: boolean) {
    this.buildSelectTargetsStep()

    if (!!this.action.formSchema && this.action.formSchema !== 'null') {
      const parentSort = maxBy(this.targetSelectionSubStep, 'sort')?.sort || 1
      const subSteps = this.dynamoFormsHelper.getSubSteps(
        this.jsonFormData as EnhancedJsonFormData,
        'setProps',
        parentSort,
        this.subStepIsValid,
        this.canEnterStepPropertiesStep
      )
      this.steps.push({
        sort: parentSort,
        title: this.jsonFormData?.title?.startsWith('management_')
          ? this.translateHelper.instant(this.jsonFormData.title)
          : this.translateHelper.instant('management_SetProperties'),
        status: 'Active',
        stepKey: 'setProps',
        isRequired: subSteps.length > 0 ? subSteps.some((x) => x.isRequired) : (this.jsonFormData?.required?.length || 0) > 0,
        subSteps,
        isValid: this.formIsValid,
        canEnterStep: this.canEnterStepPropertiesStep,
        // this is to give the time to target selection to send selected targets event
        onEnterStep: () => timer(30).subscribe(() => this.onEnterSetPropertiesStep()),
        onExitStep: (isBack: boolean = false) => {
          if (isBack) {
            this.form = this.fb.group({})
          }
        },
        showSchema: true,
        isDefaultStep: !!this.selectedTargets.length,
      })
    }

    this.steps.push({
      sort: 9999,
      title: this.translateHelper.instant('management_ReviewAndComplete'),
      status: 'Active',
      stepKey: 'complete',
      isRequired: notesMandatory,
      onEnterStep: this.prepareSummary,
      canProceed: this.canSubmit,
      isDefaultStep: !!this.selectedTargets.length && (!this.action.formSchema || this.action.formSchema === 'null'),
    })
  }

  recalculateSteps() {
    const step = this.steps.find((x) => x.stepKey === 'setProps')
    if (step) {
      const expandedStepKeys = step.subSteps?.filter((x) => x.expanded)?.map((x) => x.stepKey)
      step.subSteps = this.dynamoFormsHelper.getSubSteps(
        this.jsonFormData as EnhancedJsonFormData,
        'setProps',
        step.sort,
        this.subStepIsValid,
        this.canEnterStepPropertiesStep,
        expandedStepKeys
      )
      this.steps = [...this.steps]
    }
  }

  onSelectedTargetsChanged(selectedTargets: any[]) {
    this.selectedTargets = selectedTargets
    this.panelSubtitle = ''
    this.initialValuesLoaded = false
    this.form = this.fb.group({})
  }

  onActionTabButtonsClicked(actionButton: DownloadFileButtonDef | UploadCsvButtonDef) {
    if (isUpload(actionButton)) {
      this.panelSteps?.addSubSteps(this.steps[0], this.targetSelectionSubStep)
      this.panelSteps?.clickStep(this.targetSelectionSubStep[0])
      this.targetSelectionSubStep[0].jsonFormData = actionButton.formSchema
    } else if (isDownload(actionButton)) {
      window.open(actionButton.url)
    }
  }

  onEnterSetPropertiesStep = () => {
    this.errorMessage = undefined
    if (!this.initialValuesLoaded) {
      this.initData()
    }
  }

  initData = () => {
    this.managementHelperService.getInitialDataForAction(this.action, this.selectedTargets)?.subscribe((initialData: any) => {
      this.initialValues = this.action.initDataMap
        ? this.managementHelperService.getMappedDataForAction(this.action.initDataMap, initialData, this.selectedTargets)
        : initialData

      if (this.initialValues) {
        if (Object.keys(this.jsonFormData?.options?.templates || {}).length) {
          FormBuilderComponent.replaceTemplates(this.jsonFormData as JsonFormData, this.jsonFormData?.options?.templates as any)
        }
        this.dynamoFormsHelper.bindInitialData(this.jsonFormData as EnhancedJsonFormData, this.initialValues)
      }
      if (this.selectedTargets.length === 1) {
        this.panelSubtitle = this.getDisplayValue()
      } else if (this.action.targetSelectionType !== 'None') {
        this.panelSubtitle = this.translateHelper.genericCombineTranslations(
          'management_ItemsCount',
          {},
          { count: this.selectedTargets.length.toString() }
        )
      }
      this.initialValuesLoaded = true
      this.selectedTargetsChangedOnForm = false
    })
  }

  getDisplayValue() {
    let displayValue = ''
    if (!!this.action.targetPropertyDisplayValue && this.action.targetPropertyDisplayValue.length > 0) {
      this.action.targetPropertyDisplayValue.forEach((key) => {
        if (displayValue) {
          return
        }
        displayValue = (this.initialValues || ({} as any))[key] || this.selectedTargets[0][key]
      })
    }

    if (displayValue) {
      return displayValue
    }

    return this.action.targetPropertyId
      ? (this.initialValues || ({} as any))[this.action.targetPropertyId] || this.selectedTargets[0][this.action.targetPropertyId]
      : ''
  }

  onDataChanged(event: { key: string; value: any }) {
    if (this.propertiesToWatch[event.key]) {
      if (this.propertiesToWatch[event.key].options?.useValueAs) {
        Object.keys(this.propertiesToWatch[event.key].options?.useValueAs as { [s: string]: string }).forEach((key: string) => {
          this.handleUseValueAs(event, key)
        })
      }
    }
  }

  onEventFired(event: { key: string; event: { type: string; data: any } }) {
    if (
      !!this.propertiesToWatch[event.key] &&
      (event.event.type === 'inputChange' || event.event.type === 'filterChanged' || event.event.type === 'loadMore')
    ) {
      if (
        this.dynamoFormsHelper.isSuggester(this.propertiesToWatch[event.key].format) &&
        (typeof event.event.data === 'object' || event.event.data?.length >= 3)
      ) {
        this.dynamoFormsHelper
          .getUpdatedSuggesterProp(
            this.propertiesToWatch[event.key],
            this.formBuilder?.getValue(),
            event.event.data,
            this.selectedTargets,
            event.event.type === 'loadMore'
          )
          ?.subscribe((populatedProperty) => {
            this.formBuilder?.patchPropertyData(event.key, populatedProperty)
          })
      }
    }
    if (event.event.type === 'updateSelectedTargets' || event.event.type === 'replaceSelectedTargets') {
      const newTargets = event?.event?.data?.event?.data || []
      if (event.event.type === 'replaceSelectedTargets') {
        this.updateSelectedTargets(newTargets)
        this.targetSelection?.onModelUpdated({})
      } else if (this.selectedTargets.length !== newTargets.length) {
        this.updateSelectedTargets(
          this.selectedTargets.filter((x) =>
            newTargets.find((nt: any) => nt[this.action.targetPropertyId || ''] === x[this.action.targetPropertyId || ''])
          )
        )
      }
    }
  }

  formIsValid = (): boolean => !!this.formBuilder?.isValid()

  subStepIsValid = (step: string) => !!this.formBuilder?.isValidProperty(step)

  isInvalidStep(step: any): boolean {
    return typeof step.isValid === 'function' && !step.isValid()
  }

  prepareSummary = () => {
    this.summaryCards = []
    if (
      this.selectedTargets?.length > 1 ||
      !this.action.formSchema ||
      (this.action.formSchema === 'null' && !!this.action.targetPropertyId)
    ) {
      const displayValueProperties = this.action.targetPropertyDisplayValue?.length
        ? this.action.targetPropertyDisplayValue
        : [this.action.targetPropertyId]

      this.summaryCards = this.summaryCards.concat(
        this.hasCustomSummary('targets') && this.action.summaryStepMap
          ? this.dynamoFormSummaryHelperService.customSummaryMapping[this.action.summaryStepMap.targets](
              this.formBuilder?.getValue(),
              this.jsonFormData as EnhancedJsonFormData,
              this.selectedTargets,
              this.getGoToTargetsStepFunction()
            )
          : [
              {
                title: 'management_Targets',
                properties: [
                  {
                    ['Targets']: {
                      headers: (displayValueProperties || []).map((x) => ({
                        name: x as string,
                        displayValue: Helpers.capitalize(x || ''),
                      })),
                      values: this.selectedTargets,
                    },
                  },
                ],
                editFunction: this.getGoToTargetsStepFunction(),
              },
            ]
      )
    }

    if (this.jsonFormData) {
      const propsStep = this.steps.find((x) => x.stepKey === 'setProps')
      if ((propsStep?.subSteps?.length || 0) > 0) {
        propsStep?.subSteps?.forEach((subStep) => {
          if (!subStep.subSteps?.length) {
            this.summaryCards = this.summaryCards.concat(
              this.hasCustomSummary(subStep.stepKey) && this.action.summaryStepMap
                ? this.dynamoFormSummaryHelperService.customSummaryMapping[this.action.summaryStepMap[subStep.stepKey]](
                    this.formBuilder?.getValue(),
                    this.jsonFormData as EnhancedJsonFormData,
                    this.selectedTargets,
                    this.getGoToTargetsStepFunction(),
                    () => this.panelSteps?.clickStep(subStep)
                  )
                : [
                    {
                      title: subStep.title,
                      properties: this.dynamoFormSummaryHelperService.getSummaryProperties(
                        this.jsonFormData?.properties[subStep.stepKey] as EnhancedJsonFormData,
                        this.formBuilder?.getValue()
                      ),
                      editFunction: () => {
                        this.panelSteps?.clickStep(subStep)
                      },
                    },
                  ]
            )
          } else {
            this.summaryCards = this.summaryCards.concat(
              ...subStep.subSteps.map((x) =>
                this.hasCustomSummary(x.stepKey) && this.action.summaryStepMap
                  ? this.dynamoFormSummaryHelperService.customSummaryMapping[this.action.summaryStepMap[x.stepKey]](
                      this.formBuilder?.getValue(),
                      this.jsonFormData as EnhancedJsonFormData,
                      this.selectedTargets,
                      this.getGoToTargetsStepFunction(),
                      () => this.panelSteps?.clickStep(x)
                    )
                  : [
                      {
                        title: x.summaryCardTitlePreffix
                          ? `${this.translateHelper.instant(x.summaryCardTitlePreffix)} - ${this.translateHelper.instant(x.title)}`
                          : x.title,
                        properties: this.dynamoFormSummaryHelperService.getSummaryProperties(
                          ((this.jsonFormData?.properties || {})[subStep.stepKey]?.properties || {})[x.stepKey] as EnhancedJsonFormData,
                          this.formBuilder?.getValue()
                        ),
                        editFunction: () => {
                          this.panelSteps?.clickStep(x)
                        },
                      },
                    ]
              )
            )
          }
        })
      } else {
        if (!!this.action.summaryStepMap && !!Object.keys(this.action.summaryStepMap).length) {
          Object.keys(this.action.summaryStepMap).forEach((summKey) => {
            if (this.dynamoFormSummaryHelperService.customSummaryMapping[(this.action.summaryStepMap || {})[summKey]] instanceof Function) {
              this.summaryCards = this.summaryCards.concat(
                this.dynamoFormSummaryHelperService.customSummaryMapping[(this.action.summaryStepMap || {})[summKey]](
                  this.formBuilder?.getValue(),
                  this.jsonFormData as EnhancedJsonFormData,
                  this.selectedTargets,
                  this.getGoToTargetsStepFunction(),
                  propsStep ? () => this.panelSteps?.clickStep(propsStep) : () => {}
                )
              )
            }
          })
        } else {
          this.summaryCards.push({
            title: this.jsonFormData?.title?.startsWith('management_') ? this.jsonFormData.title : 'management_Properties',
            properties: this.dynamoFormSummaryHelperService.getSummaryProperties(this.jsonFormData, this.formBuilder?.getValue()),
            editFunction: () => {
              if (propsStep) {
                this.panelSteps?.clickStep(propsStep)
              }
            },
          })
        }
      }
    }
  }

  canSubmit = (): boolean =>
    !this.isPreview &&
    !this.isSaving &&
    this.reviewForm.valid &&
    (!this.jsonFormData || (!!this.jsonFormData && !!this.formBuilder?.isValid()))

  submit() {
    this.dialogHelperService.openActionSubmitDialogIfTargetsExceed(this.action.translate || this.action.title, this.selectedTargets.length).subscribe((proceed) => {
      if (proceed) {
        this.isSaving = true
        this.errorMessage = undefined
        const formValues = this.getFormValues()
        const actionData = this.managementHelperService.getSubmitData(
          this.action.submitDataMap,
          { ...this.initialValues, ...formValues },
          this.initialValues,
          this.selectedTargets
        )
        const commonSubmitActionParams = {
          companyId: this.companyId,
          note: this.reviewForm.value.note,
          actionId: this.action.actionItemId,
          aggregationId: this.uuidService.v4(),
          actionName: this.action.title,
          category: get(
            this.action.reportTags?.filter((r) => r.includes('category_')),
            0,
            'category_Undefined'
          ).slice(9),
        }

        let model: any
        if (Array.isArray(actionData) && actionData[0] instanceof FormData) {
          model = actionData[0]
          model.append(
            'model',
            JSON.stringify({
              ...actionData[1],
              ...commonSubmitActionParams,
            })
          )
        } else {
          model = {
            ...actionData,
            ...commonSubmitActionParams,
          }
        }
        this.managementActionsService.submitAction(this.action.submitApiUrl, this.action.submitVerb, model).subscribe(
          (response: any) => {
            this.handleSubmitApiResponse(response)
          },
          (errorResponse) => {
            this.handleSubmitApiError(errorResponse)
          }
        )
      }
    })
  }

  canDeactivate(): boolean {
    if (!this.formBuilderReady) {
      return true
    }
    const currentData = this.getSubmitData()
    return Helpers.areObjectsEqualCustom(currentData, this.initialFormBuilderValues)
  }

  beforeClose(): Observable<boolean> {
    if (this.submitted || this.canDeactivate()) {
      return of(true)
    }
    return this.dialogHelperService.openConfirmationDialog()
  }

  onFormBuilderReady() {
    setTimeout(() => {
      this.formBuilderReady = true
      const initialData = this.getSubmitData()
      this.initialFormBuilderValues = cloneDeep(initialData)
    }, 100)
  }

  private getSubmitData() {
    const rawValues = cloneDeep(this.formBuilder?.getValue())
    return this.managementHelperService.getSubmitData(
      this.action.submitDataMap,
      { ...this.initialValues, ...rawValues },
      this.initialValues,
      this.selectedTargets
    )
  }

  allowQuickSubmit(): boolean {
    if (this.panelSteps?.activeStep) {
      return (
        this.panelSteps.activeStep.stepKey === 'setProps' ||
        this.panelSteps.activeStep.parentStepKey === 'setProps' ||
        this.panelSteps.activeStep.parentStepKeys.includes('setProps')
      )
    }
    return false
  }

  private getFormValues(): any {
    if (this.action.reportTags?.find((tag) => tag.toLowerCase() === 'clearvalues')) {
      const formValues: any = {}
      Object.entries(this.formBuilder?.getValue()).forEach(([key, value]) => {
        formValues[key] = this.getPropValue(this.initialValues[key], value)
      })
      return formValues
    }
    return this.formBuilder?.getValue()
  }

  private getPropValue(oldValue: any, newValue: any) {
    if (newValue) {
      return newValue
    }
    return this.isCleaningValue(oldValue, newValue) ? '' : null
  }

  private isCleaningValue(oldValue: any, newValue: any) {
    return !newValue && !isEqual(oldValue || null, newValue || null)
  }

  private handleUseValueAs(sourceProperty: { key: string; value: any }, targetPropertyKey: string) {
    const targetPropertyData = this.dynamoFormsHelper.getPropertyData(this.jsonFormData as EnhancedJsonFormData, targetPropertyKey)
    if (targetPropertyData) {
      const keyToSet = get(this.propertiesToWatch[sourceProperty.key].options?.useValueAs, targetPropertyKey)
      if (keyToSet === 'enum') {
        this.handleUseValueAsEnum(targetPropertyData, targetPropertyKey, sourceProperty.value)
      } else {
        set(
          targetPropertyData,
          get(this.propertiesToWatch[sourceProperty.key].options?.useValueAs, targetPropertyKey) || '',
          sourceProperty.value
        )
        this.formBuilder?.patchPropertyData(targetPropertyKey, targetPropertyData)
      }
    }
  }

  private handleUseValueAsEnum(targetPropertyData: EnhancedPropertyFormDescriptor, targetPropertyKey: string, sourceValue: any) {
    const newPropertyData = this.dynamoFormsHelper.getUpdatedEnumProp(targetPropertyData, sourceValue, this.formBuilder?.getValue())
    if (
      targetPropertyData.type !== 'array' &&
      !newPropertyData.enum?.find((x) => x === targetPropertyData.default?.value || x === targetPropertyData.default)
    ) {
      newPropertyData.default = null
    }
    this.formBuilder?.patchPropertyData(targetPropertyKey, newPropertyData)
  }

  private updateSelectedTargets(newTargets: any[]) {
    this.selectedTargets = newTargets
    this.selectedTargetsChangedOnForm = true
    this.panelSubtitle = this.translateHelper.genericCombineTranslations(
      'management_ItemsCount',
      {},
      { count: newTargets.length.toString() || '0' }
    )
  }

  private openErrorMessageDetail(errors: ApiErrorResponse[]) {
    this.rightPanelService.open({
      type: SubmitErrorMessageDetailComponent,
      data: {
        action: this.action,
        errors,
        width: '750px',
      },
    })
  }

  private hasCustomSummary(summaryKey: string): boolean {
    return (
      !!this.action.summaryStepMap &&
      !!this.action.summaryStepMap[summaryKey] &&
      this.dynamoFormSummaryHelperService.customSummaryMapping[this.action.summaryStepMap[summaryKey]] instanceof Function
    )
  }

  private actionBarButton = (tab: DownloadFileButtonDef | UploadCsvButtonDef) => ({
    text: this.translateHelper.instant(tab.title),
    buttonType: tab.buttonType,
    icon: tab.icon,
    visibility: 'always' as ActionButtonVisibility,
    cvDataTest: `targets-${tab.title}`,
    onClick: (data: any[]) => {
      this.onActionTabButtonsClicked(tab)
    },
  })

  private getGoToTargetsStepFunction() {
    return this.steps[0].stepKey === 'selectTargets' ? () => this.panelSteps?.clickStep(this.steps[0]) : undefined
  }

  private getSelectTargetsStepTitleKey(): string {
    if (this.action.targetSelectionStepKey) {
      return this.action.targetSelectionStepKey
    }
    return this.action.targetSelectionType === 'Single' ? 'management_SelectOneTarget' : 'management_SelectAtLeastOneTarget'
  }

  private allowsUploadCsvFile(): boolean {
    return (
      this.action.targetSelectionType === 'Multiple' &&
      (!!this.action.onlineUserTarget || !!this.action.reportTags?.find((tag) => tag.toLowerCase() === 'allowuploadcsv')) &&
      !this.action?.targetActionBar?.length
    )
  }

  private buildTargetSelectionDefinition() {
    if (this.allowsUploadCsvFile()) {
      this.action.targetActionBar = ManagementHelperService.uploadUsersFromCsvActionBar
    }

    this.targetSelectionDefinition = {
      entity: `management/actions/${this.action.category}/${this.action.title}`,
      url: this.action.apiUrl,
      verb: 'post',
      fields: union(this.action.requiredFields || [], this.action.fields || []),
      lockedColumns: this.action.requiredFields,
      responseItemProp: this.action.responseObjectName || '',
      sortField: this.action.sortField || '',
      sortOrder: this.action.sortOrder || null,
      isOnlineUsersType: this.action.onlineUserTarget,
      rowSelection: this.action.targetSelectionType === 'Multiple' ? 'multiple' : 'single',
      selectionActions: [],
      targetPropertyId: this.action.targetPropertyId,
      entityIdField: this.action.targetPropertyId,
      defaultFilters: this.action.filters,
    }

    this.action.targetActionBar?.forEach((tab) => {
      this.targetSelectionDefinition.selectionActions?.push(this.actionBarButton(tab))
    })
  }

  private exitUploadCsvSummaryStep = (isBack: boolean) => {
    if (!isBack) {
      this.panelSteps?.clearSubSteps(this.steps[0])
      if (this.panelSteps?.activeStep?.stepKey === 'fileUpload') {
        // to avoid calling onExitStep and submit the file again
        this.panelSteps.activeStep = { ...this.steps[0], parentSort: 0, parentStepKeys: [], isFirst: true }
      }
      if (this.reloadSelectTarget) {
        this.panelSteps?.clickStep(this.steps[0])
        this.targetSelection?.refreshTargetsToSelectDataGrid()
      } else if (this.uploadForm.getRawValue()?.selectedTargets?.length) {
        this.onSelectedTargetsChanged(this.uploadForm.getRawValue().selectedTargets)
        this.targetSelection?.selectedTargetsGrid?.refresh()
        this.panelSteps?.clickStep(this.steps[1])
      } else {
        this.panelSteps?.clickStep(this.steps[0])
      }
    } else {
      this.uploadForm.reset()
    }
  }

  private buildUploadCsvFileSteps() {
    ;(this.action.targetActionBar?.filter((ab) => isUpload(ab)) as Array<UploadCsvButtonDef>)?.forEach((ab: UploadCsvButtonDef) => {
      this.targetSelectionSubStep = this.dynamoFormsHelper.getSubSteps(ab.formSchema, 'selectTargets', 0)
      this.reloadSelectTarget = ab.formSchema.options?.reloadSelectTarget || this.reloadSelectTarget
      this.steps[0].jsonFormData = ab.formSchema

      this.targetSelectionSubStep[0].onExitStep = () => {
        const file = this.uploadForm.get('fileDescription')?.value
        if (file) {
          const f = new FormData()
          f.append(file.name, file)
          this.managementActionsService.submitAction(ab.url, 'post', f).subscribe((res) => {
            this.uploadForm.patchValue(res)
          })
        } else {
          this.panelSteps?.clearSubSteps(this.steps[0])
          this.panelSteps?.clickStep(this.steps[0])
        }
      }

      this.targetSelectionSubStep[1].nextStep = 'selectTargets'
      this.targetSelectionSubStep[1].canEnterStep = () => !!this.formBuilder?.isValid()
      this.targetSelectionSubStep[1].onExitStep = (isBack: boolean = false) => this.exitUploadCsvSummaryStep(isBack)
    })
  }

  private buildSelectTargetsStep() {
    // Show targets step for multi target action and single target when target were not pre-selected
    if (!this.selectedTargets.length && this.action.targetSelectionType !== 'None') {
      this.steps.push({
        sort: 0,
        title: this.translateHelper.instant(this.getSelectTargetsStepTitleKey()),
        status: 'Active',
        stepKey: 'selectTargets',
        isRequired: true,
        canProceed: () =>
          this.action.targetSelectionType === 'Single' ? this.selectedTargets.length === 1 : this.selectedTargets.length > 0,
        onEnterStep: () => {
          this.errorMessage = undefined
          if (this.selectedTargetsChangedOnForm) {
            this.panelSubtitle = ''
            this.initialValuesLoaded = false
            this.form = this.fb.group({})
          }
        },
      })
      this.buildUploadCsvFileSteps()
    }
  }

  private handleSubmitApiResponse(response: any) {
    if (response?.responseStatus?.errorCode === 'Error') {
      this.errorMessage = response.responseStatus.message || 'management_ManagementSubmitError'
      if (this.panelSteps?.activeStep?.stepKey !== 'complete') {
        this.panelSteps?.clickStep(this.steps[this.steps.length - 1])
      }
    } else {
      this.apiClient.trackAction(Constants.trackActons.actionExecuted, Helpers.downcase(this.action.title)).subscribe((res) => {})
      this.submitted = true
      this.rightPanelRef.close()
      this.toastService.open({
        id: 'succ',
        variant: 'success',
        matIcon: 'timelapse',
        title: this.translateHelper.instant('management_WeAreProcessingAction'),
        message: this.translateHelper.instant('management_YouWillBeNotifiedOnceCompleted'),
        displayCTA: true,
        actionLabel: this.translateHelper.instant('management_SeeProgress'),
        action: () => this.router.navigate(['management/tasksProgress']).catch((_: any) => _),
        dismiss: (toast: { id: any }) => this.toastService.close(toast.id),
      })
    }
    this.isSaving = false
  }

  private handleSubmitApiError(errorResponse: any) {
    this.appInsights.trackError(`[MANAGEMENT] ${this.action.title} failed with error: ${errorResponse}`)
    const errors: ApiErrorResponse[] = errorResponse?.error?.responseStatus?.errors || []

    if (!!errors.length && !!errors.some((x) => x.message.includes('--'))) {
      this.handleVirtualTenantValidationError(errors)
    } else {
      const uniqueErrors = uniqBy(errorResponse?.error?.responseStatus?.errors, (x: any) => x.fieldName)
      this.errorMessage = uniqueErrors.length
        ? uniqueErrors.map((err) => err.message).join(', ')
        : errorResponse?.error?.responseStatus?.message || errorResponse?.error?.message || 'management_ManagementSubmitError'

      if (this.panelSteps?.activeStep?.stepKey !== 'complete') {
        this.panelSteps?.clickStep(this.steps[this.steps.length - 1])
      }
    }

    this.isSaving = false
  }

  private handleVirtualTenantValidationError(errors: ApiErrorResponse[]) {
    this.submitted = true
    this.rightPanelRef.close()
    const errorOcurredOnAllTargets =
      uniq(errors.map((actionError: ApiErrorResponse) => (actionError.message.split('--') || [])[0]?.trim())).length ===
      this.selectedTargets.length
    this.toastService.open(
      {
        id: 'error',
        variant: 'error',
        matIcon: 'error',
        title: this.translateHelper.genericCombineTranslations('management_IssueFoundOnTargets', {
          countInfo: errorOcurredOnAllTargets ? 'common_All' : 'common_Some',
        }),
        message: this.translateHelper.genericCombineTranslations('management_ItemsNotProcessed', {
          countInfo: errorOcurredOnAllTargets ? 'common_All' : 'common_Some',
        }),
        displayCTA: true,
        actionLabel: this.translateHelper.instant('management_SeeDetails'),
        action: () => {
          this.toastService.close('error')
          this.openErrorMessageDetail(errors)
        },
        dismiss: (toast: { id: any }) => this.toastService.close(toast.id),
      },
      {
        autoclose: false,
        timeout: 50000,
      }
    )
  }
}

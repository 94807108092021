import { Component, Input } from '@angular/core'
import { SerializerService } from '@app/modules/workflow/services/serializer.service';

interface PropertyValue {
  type: 'text' | 'action' | 'input';
  value: string;
}

@Component({
  selector: 'app-action-property',
  templateUrl: './action-property.component.html',
  styleUrls: ['./action-property.component.sass'],
})
export class ActionPropertyComponent {
  @Input() set propertyValue(val: string) {
    this.items = this.parseValue(val)
  }

  public items: PropertyValue[] = []

  constructor(private serializerService: SerializerService) {}

  private parseValue(value: string): PropertyValue[] {
    const strippedValue = new DOMParser().parseFromString(value, 'text/html');
    const regexp = new RegExp(/({{[^}]*}}|[^{]*)/g)
    const matches = strippedValue.body.textContent ? strippedValue.body.textContent.match(regexp) : []
    return (matches || []).filter(val => val !== '').map(match => {
      if (this.serializerService.getActionBindingExpressionRegex().test(match)) {
        return { type: 'action', value: match }
      }
      if (this.serializerService.getInputBindingExpressionRegex().test(match)) {
        return { type: 'input', value: match }
      }
      return { type: 'text', value: match }
    })
  }
}

<mat-icon 
    *ngIf="!isFabricIcon && !hideIcon()"
    style="vertical-align: middle; margin-bottom: 4px;" 
    [ngClass]="getIconClass()"
    [ngStyle]="{'color': getColor()}">
    {{ getIcon() }}
</mat-icon>
<em 
    *ngIf="isFabricIcon && !hideIcon()" 
    style="vertical-align: middle; margin-bottom: 4px;" 
    [ngClass]="icon">
</em>
<span *ngIf="showValue">
    {{params.getValue()}}
</span>

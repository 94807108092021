/* eslint-disable @typescript-eslint/naming-convention */
import { TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core'
import { HttpClient } from '@angular/common/http'
import { ApplicationInsightService } from './core/services/application-insight.service'
import { Observable, from, of } from 'rxjs'
import { environment } from '@environments/environment'

export type TranslationMap = Record<string, string>

export type TranslationApiResponse = {
  translation: {
    translationItems: TranslationMap;
  };
}

let trans: any

export const loadTranslator = () => {
  const isoCode = localStorage.getItem('cv-preferred-language') || 'en'
  const url = `${environment.baseCentralUrl}api/V2/translations/all/${isoCode}?format=json`

  return from(fetch(url, {
    method: 'get',
    headers: {
      Accept: 'application.json',
      'Content-Type': 'application/json'
    },
    cache: 'default'
  })
    .then(response => response.json())
    .then(json => {
      trans = json.translation.translationItems
    }).catch((ex) => { 
      console.error(ex)
     }))
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions,@typescript-eslint/naming-convention
// export function CoreViewTranslationLoaderFactory(http: HttpClient, apiclient: ApiclientService, local: boolean) {
//   return new CoreViewTranslationsLoader(http, apiclient, local)
// }

/**
 * Loads the translations
 */
export class CoreViewTranslationsLoader implements TranslateLoader {
  constructor(private http: HttpClient, private local: boolean) {
  }

  getTranslation(lang: string): Observable<TranslationMap> {
    if (this.local) {
      return this.loadLocalFile(lang)
    }

    return of(trans)
  }

  loadLocalFile(lang: string) {
    const errorFile = ['en', 'fr', 'it', 'es', 'de'].includes(lang) ? lang : 'en'
    const jsonFile = `assets/translation/${errorFile}.json`;
    return this.http.get<TranslationMap>(jsonFile)
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions,@typescript-eslint/naming-convention
export function CoreViewMissingTranslationHandlerFactory(appInsights: ApplicationInsightService) {
  return new CoreViewMissingTranslationHandler(appInsights)
}

const uniqueKeys: string[] = []
/**
 * Log for when a translation is missing
 */
export class CoreViewMissingTranslationHandler implements MissingTranslationHandler {
  constructor(private appInsights: ApplicationInsightService) { }
  handle(params: MissingTranslationHandlerParams) {
    if (!uniqueKeys.find(f => f === params.key)) {
      this.appInsights.trackWarning('Missing translation', { key: params.key })
      uniqueKeys.push(params.key)
    }
    return params.key
  }
}

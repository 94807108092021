<app-loading-report-skeleton *ngIf="!loadedFirstTime" [hasCharts]="hasCharts"> </app-loading-report-skeleton>

<div class="report-container" [style.display]="loadedFirstTime ? 'flex' : 'none'">
  <app-report-title
    [reportDefinition]="reportDefinition"
    [menu]="menu"
    [savedReportId]="savedReportId"
    [reportTitle]="reportTitle"
    [reportDescription]="reportDescription"
    (dataCenterSelected)="selectDataCenter($event)"
    (rangeFilterChanged)="onRangeFilterChange($event)"
    [isNonVTenantSensitive]="selectedTab?.isNonVTenantSensitive || false"
    [hasCharts]="hasCharts"
    [chartPreference]="chartPreference"
    (chartVisibilityChanged)="updateChartVisibilityPreference($event)"
  >
  </app-report-title>

  <div
    class="main-container"
    [ngClass]="!canSeeLeftPanel() ? 'mainContainer-two' : 'mainContainer-three'"
  >
    <cv-smart-panel
      *ngIf="canSeeLeftPanel()"
      [title]="'reports_CustomReports' | translate"
      [open]="isOpenPanelCustomReport"
      (openChange)="openChangePanelCustomReport($event)"
      style="padding-top: 10px"
    >
      <div style="cursor: default" content>
        <div class="title-container">
          <div class="title" translate>reports_CustomReports</div>
          <mat-icon [tooltip]="'reports_SavedViewsInfo' | translate" class="info material-icons-outlined">info </mat-icon>
        </div>
        <div class="m-l-15 m-b-20" translate>reports_SelectReport</div>
        <div class="saved-report-container">
          <app-custom-reports-list [savedReports]="savedReports"></app-custom-reports-list>
        </div>
      </div>
    </cv-smart-panel>
    <div class="grid-container">
      <cv-horizontal-tab-group type="line" (tabChanged)="tabChanged($event)" [activeTab]="tabSelectedIndex + 1">
        <cv-tab *ngFor="let tab of tabs" title="{{ tab.title | translate }} {{ tab.isBeta ? ' (beta)' : ''}}" [tooltipString]="tab.tooltip | translate" #tab>
          <ng-template cvTabContent>
            <div
              *ngIf="hasCharts && !isPivotModeActive && !selectedTab?.hideCustomReportsPanel && chartPreference"
              style="margin-inline: auto; width: 100%; margin-bottom: 30px"
              [style.minHeight]="
                hasCharts
                  ? reportDefinition && reportDefinition.chart && reportDefinition.chart.length > 1 && reportDefinition.chart[0].areTabbable
                    ? '360px'
                    : '300px'
                  : '0'
              "
            >
              <span
                *ngIf="
                  reportDefinition && reportDefinition.chart && reportDefinition.chart.length > 1 && reportDefinition.chart[0].areTabbable
                "
              >
                <cv-horizontal-tab-group
                  type="line"
                  [activeTab]="getDefaultTabChartIndex() + 1"
                  (tabChanged)="changeChart($event)"
                  style="height: auto"
                >
                  <cv-tab title="{{ c.name || 'tab' | translate }}" *ngFor="let c of reportDefinition?.chart" style="padding-top: 20px">
                  </cv-tab>
                </cv-horizontal-tab-group>
              </span>
              <div style="display: flex">
                <span
                  [hidden]="!allChartsLoaded()"
                  *ngFor="let chart of chartsContainer | keyvalue"
                  [style.width]="calculateChartsContainerWidth()"
                >
                  <lib-chart
                    *ngIf="chart"
                    [chart]="getChart(chart.value)"
                    (click)="onClickChartEvent($event)"
                    [data]="getChartData(chart.value)"
                    [dataChart]="getChartDataChart(chart.value)"
                  >
                  </lib-chart>
                </span>
              </div>
            </div>

            <app-datagrid
              (rowSelected)="rowSelected()"
              *ngIf="!!(tab.reportDefinition$ | async) && !isPivotModeActive"
              [customParameters]="customParameters"
              (pageDataChanged)="pageDataChanged($event)"
              [getItems]="refreshData"
              [gridDefinition]="reportDefinition"
              (metadataChanged)="metadata = $event"
              [allCols]="selectedTab?.columnDefs ? selectedTab.columnDefs : allCols"
              [rangeFilters]="rangeFilters"
              [routeParamsFilters]="routeParamsFilters"
              [customFilters]="customFilters"
              [savedReportFilterSortModel]="savedReportFilterSortModel"
              [autoHeight]="false"
              [hideColumnsSelector]="hideColumnsSelector"
              [enablePivotMode]="enablePivotMode"
              (pivotModeChanged)="onPivotModeChanged($event)"
              (columnDefsDefined)="onAppDataGridColumnDefined($event)"
            >
            </app-datagrid>

            <app-client-datagrid
              *ngIf="!!!(tab.reportDefinition$ | async) && !isPivotModeActive"
              [entityName]="selectedTab?.entity"
              [columnDefsBuiltExternally]="selectedTab?.columnDefs"
              [gridOptions]="gridOptionsClientDataGrid"
              [getItems]="getClientGridData"
              [detailCellRenderer]="detailCellRendererClientDataGrid"
              [detailCellRendererParams]="detailCellRenderParamsClientDataGrid"
              [isRowMaster]="isRowMaster"
              [rowSelection]="rowSelection"
              [hideColumnsSelector]="true"
              [autosizeAllColumns]="true"
              (metadataChanged)="metadata = $event"
              [groupedActions]="groupedActions"
              [selectionActions]="selectionActions"
              [filterActions]="filterActions"
              [responseItemProp]="selectedTab.responseItemProp"
              (gridReady)="onGridReady()"
              #clientGrid
            ></app-client-datagrid>

            <app-client-datagrid
              *ngIf="isPivotModeActive"
              [getItems]="getPivotData"
              [columnsParameters]="reportsComponentHelper.pivotParamsForBuildColDef"
              [responseItemProp]="pivotResponseItemProp"
              [enablePivotMode]="enablePivotMode"
              (pivotModeChanged)="onPivotModeChanged($event)"
              [groupedActions]="pivotSelectionActions"
              [autosizeAllColumns]="true"
              [gridOptions]="{ suppressRowClickSelection: true }"
            >
            </app-client-datagrid>
          </ng-template>
        </cv-tab>
      </cv-horizontal-tab-group>
    </div>
    <app-management-panel
      *ngIf="!isPivotModeActive && !selectedTab?.hideManagementPanel && canManage()"
      [selectedRows]="selectedRows"
      cv-data-test="management-panel"
      [tagsByDefault]="reportDefinition?.actionsTags || []"
      [targetEntity]="reportDefinition?.targetEntity"
      [collectionName]="reportDefinition?.dbCollectionName"
      [reportDefinition]="reportDefinition"
      [reportTitle]="reportDefinition?.title"
      [entityIdField]="reportDefinition?.entityIdField"
      [workflowItemsSelectionColumnDefinition]="managementColumnDefinition"
    ></app-management-panel>
  </div>
</div>

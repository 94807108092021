import { createReducer, on } from '@ngrx/store'
import { ManagementStatusState } from './management-status.types'
import { getManagementStatus, getManagementStatusInvalidate, getManagementStatusResponse } from './management-status.actions'

export const initialState: ManagementStatusState = {
  entities: [],
  didInvalidate: false,
  isFetching: false,
  error: null,
}

export const managementStatusReducer = createReducer(
  initialState,
  on(getManagementStatus, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(getManagementStatusResponse, (state, managementStatus) => ({ ...state, entities: [managementStatus.actions, ...state.entities] })),
  on(getManagementStatusInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true }))
)

export default managementStatusReducer

import { Component } from '@angular/core'
import { ICellRendererParams } from '@ag-grid-community/core'
import { AgRendererComponent } from '@ag-grid-community/angular'

@Component({
  selector: 'app-icon-dialog-button-renderer',
  templateUrl: './icon-dialog-button-renderer.component.html',
  styleUrls: ['./icon-dialog-button-renderer.component.sass'],
})
export class IconDialogButtonRendererComponent implements AgRendererComponent {
  public icon!: string
  public title!: string
  public text!: string
  public primaryButton!: { key: string; text: string }
  public secondaryButton!: { key: string; text: string }
  public type!: 'info' | 'alert' | 'success'
  public centered = false
  public inProgress = false
  public disabled = false;

  public params: any

  iconByStatus: Record<string, string> = {
    error: 'error',
    alert: 'error',
    warning: 'warning',
    info: 'info',
    success: 'check_circle',
  }

  agInit(params: ICellRendererParams): void {
    this.params = params
    this.icon = this.params.icon || null
    this.title = this.params.title || null
    this.text = this.params.text || null
    this.primaryButton = this.params.primaryButton || null
    this.secondaryButton = this.params.secondaryButton || null
    this.type = this.params.type || 'info'
    this.centered = this.params.centered
    this.disabled = this.params.disabled
  }

  refresh(params?: any): boolean {
    return true
  }

  menuClosed() {
    this.params.api.redrawRows({ rowNodes: [this.params.node] })
  }

  click(event: any): void {
    if (this.params.onClick instanceof Function && event.key === this.primaryButton.key) {
      const clickParams = {
        clickEvent: event.event,
        key: event.key,
        rowData: this.params.node.data,
        component: this,
      }
      this.params.onClick(clickParams)
      this.inProgress = true
      this.icon = 'timelapse'
      this.params.node.data.inProgress = true
    }
  }

  reset(): void {
    if (this.inProgress) {
      return
    }
    this.agInit(this.params)
  }

  hideIcon(): boolean {
    if (this.params.hideIcon instanceof Function) {
      return this.params.hideIcon(this.params.node.data)
    }
    return false
  }
}

<span style="font-size: 14px; font-weight: 600; margin-bottom: 20px; margin-left: 40px; margin-top: 20px" class="uppercase">{{
  'common_Filters' | translate
}}</span>
<div class="fl-1" style="overflow: auto">
  <div style="border: 1px solid var(--opaque-light-contrast-color); margin-top: 20px; border-radius: 4px; margin-right: 40px; margin-left: 40px">
    <div class="m-15" style="margin-bottom: 0 !important">
      <span class="ms-Icon ms-Icon--OfficeLogo office-icon"></span>
      <span style="vertical-align: middle" translate>dashboard_FilterByServices</span>
    </div>
    <div class="p-l-20">
      <app-tree-view
        *ngIf="products"
        type="multi"
        [multiSelected]="selectedProducts"
        [hideSelectAll]="true"
        [expandItems]="false"
        [data]="products"
        (selectionChanged)="handleProductsChange($event)"
        style="padding: 1rem;"
      ></app-tree-view>
    </div>
  </div>

  <div style="border: 1px solid var(--opaque-light-contrast-color); margin-top: 20px; border-radius: 4px; margin-right: 40px; margin-bottom: 20px; margin-left: 40px">
    <div class="m-15" style="margin-bottom: 0 !important">
      <mat-icon class="calendar-icon">calendar_today</mat-icon>
      <span style="vertical-align: middle" translate>dashboard_FilterByYearMonth</span>
    </div>
    <div class="p-l-20">
      <app-tree-view
        *ngIf="products"
        type="multi"
        [multiSelected]="selectedDates"
        [hideSelectAll]="true"
        [expandItems]="false"
        [data]="dates"
        (selectionChanged)="handleDatesChange($event)"
        style="padding: 1rem;"
      ></app-tree-view>
    </div>
  </div>
</div>
<div>
  <div style="display: flex; justify-content: flex-end; padding: 20px 50px 20px 20px; box-shadow: 0px -1px 4px rgb(116 112 112 / 20%)">
    <cv-button
      (click)="clear()"
      buttonType="tertiary"
      color="alert"
      leftIcon="close"
      [text]="'common_ClearAllFilters' | translate"
    ></cv-button>
    <cv-button (click)="apply()" class="m-l-10" buttonType="primary" leftIcon="done" [text]="'common_Apply' | translate"></cv-button>
  </div>
</div>

/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { LicensePoolCostAllocator } from '@app/core/models/LicensePool'
import { AdministrationsService } from '@app/core/services/administrations.service'
import { ApplicationInsightService } from '@app/core/services/application-insight.service'
import { IBeforeClose, RightPanelRef } from '@app/core/services/right-panel.service'
import { PanelStepsComponent } from '@app/shared/components/panel-steps/panel-steps.component'
import { TargetSelectionDefinition, TargetSelectionOptions } from '@app/shared/components/target-selection/target-selection.component'
import { PanelStep } from '@app/shared/models/panel-step'
import { TranslateHelper } from '@coreview/coreview-library'
import { Suggestion, ToastService } from '@coreview/coreview-components'
import { Observable, of } from 'rxjs'
import cloneDeep from 'lodash-es/cloneDeep'
import { DialogHelperService } from '@app/shared/dialog-helper.service'

@Component({
  selector: 'app-manage-costs',
  templateUrl: './manage-costs.component.html',
  styleUrls: ['./manage-costs.component.sass'],
})
export class ManageCostsComponent extends IBeforeClose implements OnInit {
  @ViewChild(PanelStepsComponent) set panelStepsComp(panelSteps: PanelStepsComponent) {
    if (panelSteps) {
      this.panelSteps = panelSteps
    }
  }

  @Input() configuration!: {
    licensePools: { targetSelectionDefinition: TargetSelectionDefinition; targetSelectionConfiguration: TargetSelectionOptions }
    licenses: { targetSelectionDefinition: TargetSelectionDefinition; targetSelectionConfiguration: TargetSelectionOptions }
  }

  steps: PanelStep[] = []
  panelSteps?: PanelStepsComponent

  selectedTargetsObject: any
  allocatorData: any = {}
  lpsToSave!: any[]
  allocatorProps: { keyCurrent: string; keyNew: string; titleCurrent: string; titleNew: string }[] = [
    {
      keyCurrent: 'pricePerUser',
      keyNew: 'pricePerUserNew',
      titleCurrent: 'licenses_CurrentPricePerUser',
      titleNew: 'licenses_NewPricePerUser',
    },
    {
      keyCurrent: 'pricePerActiveUser',
      keyNew: 'pricePerActiveUserNew',
      titleCurrent: 'licenses_CurrentPricePerActiveUser',
      titleNew: 'licenses_NewPricePerActiveUser',
    },
    {
      keyCurrent: 'pricePerLicensePool',
      keyNew: 'pricePerLicensePoolNew',
      titleCurrent: 'licenses_CurrentPricePerLicensePool',
      titleNew: 'licenses_NewPricePerLicensePool',
    },
    { keyCurrent: 'currency', keyNew: 'currencyNew', titleCurrent: 'licenses_CurrentCurrency', titleNew: 'licenses_NewCurrency' },
  ]

  isSaving = false

  private submitted = false

  private form?: UntypedFormGroup
  private currencies!: Suggestion[]

  constructor(
    private translateHelper: TranslateHelper,
    private administrationsService: AdministrationsService,
    private rightPanelRef: RightPanelRef,
    private toastService: ToastService,
    private appInsights: ApplicationInsightService,
    private dialogHelperService: DialogHelperService
  ) {
    super()
  }

  ngOnInit(): void {
    this.steps = [
      {
        title: this.translateHelper.instant('licenses_SelectTarget'),
        status: 'Active',
        isRequired: true,
        sort: 0,
        stepKey: 'licenses_SelectTarget',
        isValid: () => this.selectedTargetsObject?.licensePools?.length > 0,
      },
      {
        title: this.translateHelper.instant('licenses_ManageCosts'),
        status: 'Active',
        sort: 1,
        isRequired: true,
        stepKey: 'licenses_ManageCosts',
        isValid: () => !!this.form?.valid,
      },
      {
        title: this.translateHelper.instant('common_ReviewAndComplete'),
        status: 'Active',
        onEnterStep: this.onEnterReviewAndComplete,
        sort: 2,
        isRequired: false,
        stepKey: 'complete',
        canProceed: this.canSubmit,
      },
    ]

    this.selectedTargetsObject = {
      licensePools: [],
    }

    this.administrationsService.getCurrencies().subscribe((res) => {
      this.currencies = res.currencies.map((c: any) => ({ value: c.alp3IsoCode, displayValue: c.alp3IsoCode }))
      if (this.configuration?.licensePools?.targetSelectionDefinition?.selectedItems?.length) {
        this.onSelectedTargetsChanged(this.configuration.licensePools.targetSelectionDefinition.selectedItems)
        this.clickTab(1)
      }
    })
  }

  onSelectedTargetsChanged(selectedTargets: any) {
    this.selectedTargetsObject.licensePools = selectedTargets

    this.form = new UntypedFormGroup({})
    this.selectedTargetsObject.licensePools?.forEach((lp: any) => {
      this.allocatorProps.forEach((p: { keyCurrent: string; keyNew: string; titleCurrent: string; titleNew: string }) => {
        lp[p.keyNew] = lp[p.keyCurrent]

        this.form!.addControl(lp.name + '_' + p.keyNew, new UntypedFormControl('', Validators.required))
      })
    })

    this.allocatorData = {
      items: this.selectedTargetsObject.licensePools,
      props: this.allocatorProps,
      form: this.form,
      currencies: this.currencies,
      propId: 'name',
      propDisplay: 'name',
    }
  }

  canSubmit = (): boolean => !this.isSaving && this.lpsToSave?.length > 0 && !!this.form?.valid

  save() {
    this.isSaving = true

    const itemsToSave: any[] = []

    this.lpsToSave.forEach((lp: LicensePoolCostAllocator) => {
      const lpToSave: LicensePoolCostAllocator = {
        ...lp,
        pricePerUser: lp.pricePerUserNew,
        pricePerActiveUser: lp.pricePerActiveUserNew,
        pricePerLicensePool: lp.pricePerLicensePoolNew,
        currency: lp.currencyNew,
        items: [],
      }

      itemsToSave.push(lpToSave)
    })

    this.administrationsService.updateAssignedMultipleLicensePools({ items: itemsToSave }).subscribe(
      () => {
        this.submitted = true
        this.rightPanelRef.close(true)
        this.toastService.open({
          id: 'succ',
          variant: 'success',
          title: this.translateHelper.instant('common_Success'),
        })
        this.isSaving = false
      },
      (error) => {
        this.toastService.open({
          id: 'err',
          variant: 'error',
          title: this.translateHelper.instant('common_Error'),
          message: error?.error?.responseStatus?.message || 'license_SubmitError',
        })
        this.isSaving = false
        this.appInsights.trackError(error)
      }
    )
  }

  clickTab(index: number) {
    this.panelSteps?.clickStep(this.steps[index])
  }

  canDeactivate(): boolean {
    const costValue = cloneDeep(this.form?.getRawValue())
    if (!costValue || Object.keys(costValue).length === 0) {
      return true
    }
    const hasNonEmptyValue = Object.values(costValue).some((value) => value !== '')
    return !hasNonEmptyValue
  }

  beforeClose(): Observable<boolean> {
    if (this.submitted || this.canDeactivate()) {
      return of(true)
    }
    return this.dialogHelperService.openConfirmationDialog()
  }

  private onEnterReviewAndComplete = (): void => {
    this.lpsToSave = []
    this.allocatorData?.items?.forEach((lp: any) => {
      const lpToSave: any = {}
      this.allocatorProps
        .filter((prop: any) => lp[prop.keyCurrent] !== lp[prop.keyNew])
        .forEach((prop: any) => {
          lpToSave[prop.keyNew] = lp[prop.keyNew]
        })

      if (Object.keys(lpToSave).length) {
        this.lpsToSave.push({ ...lp, ...lpToSave })
      }
    })
  }
}

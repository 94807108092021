import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Organization } from '@app/core/models/Organization'
import { CalendarType } from '@app/core/models/PageDataCommonClasses'
import { UserSettings } from '@coreview/coreview-library/models/user-settings'
import { Constants } from '@app/shared/utilities/constants'
import { Helpers } from '@app/shared/utilities/helpers'
import { TranslateHelper } from '@coreview/coreview-library'
import { selectedOrganization } from '@app/store/organizations/organizations.selectors'
import { RootState } from '@app/store/RootState.type'
import { selectUserSettings } from '@app/store/userSettings/userSettings.selectors'
import { Store } from '@ngrx/store'
import dayjs from 'dayjs'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-range-filter',
  templateUrl: './range-filter.component.html',
  styleUrls: ['./range-filter.component.sass'],
})
export class RangeFilterComponent implements OnInit, OnDestroy {
  @Input() calendarType!: string | undefined
  @Input() daysList!: (1 | 3 | 7 | 14 | 30 | 60 | 90 | 180 | 0)[] | undefined
  @Input() defaultDaysFilter!: 1 | 3 | 7 | 14 | 30 | 60 | 90 | 180 | 0 | undefined
  @Input() limitDays!: number | undefined
  @Input() limitMonths!: number | undefined
  @Input() defaultDates!: { startDate?: string; endDate?: string } | undefined

  @Output() rangeFilterChanged = new EventEmitter<{ since?: string; to?: string; days?: number; isOnStart?: boolean }>()

  userSettings$!: Observable<UserSettings | undefined>
  public organization$!: Observable<Organization | undefined>

  userSettings?: UserSettings
  rangeFilters?: { since?: string; to?: string; days?: number; isOnStart?: boolean }
  defaultRange!: 0 | 1 | 60 | 3 | 7 | 14 | 30 | 90 | 180 | undefined
  rangeOptions!: (1 | 3 | 7 | 14 | 30 | 60 | 90 | 180 | 0)[]
  daysOptions!: { key: string; text: string }[]
  localeIso!: string
  showCustomRange = false

  private destroyed$: Subject<boolean> = new Subject()

  constructor(private store: Store<RootState>, private translateHelper: TranslateHelper) {}

  ngOnInit(): void {
    this.rangeSettings()

    this.organization$ = this.store.select(selectedOrganization)
    this.userSettings$ = this.store.select(selectUserSettings)

    this.userSettings$.pipe(takeUntil(this.destroyed$)).subscribe((userSettings) => {
      if (userSettings) {
        const regionalSetting = Constants.regionalSettings.find((us) => us.fullLabel === userSettings.regionalSettings)
        this.localeIso = this.localeIso = Helpers.isoRegionalSetting(regionalSetting)
      }
      else {
        this.localeIso = Constants.regionalSettings[0].iso
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  setInitialRangeFilter() {
    this.rangeFilters = {
      since: this.defaultRange ? dayjs.utc().subtract(this.defaultRange, 'day').startOf('day').toISOString() : this.defaultDates?.startDate,
      to: this.defaultDates ? this.defaultDates.endDate : dayjs.utc().endOf('day').toISOString(),
      isOnStart: true,
      days: this.defaultRange,
    }
    this.rangeFilterChanged.emit(this.rangeFilters)
  }

  setInitialDaysFilter() {
    this.rangeFilters = { days: this.defaultRange, isOnStart: true }
    this.rangeFilterChanged.emit(this.rangeFilters)
  }

  onRangeChange(newRange: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs; days?: string }) {
    this.rangeFilters = { since: newRange.startDate.toISOString(), to: newRange.endDate.toISOString() }
    if (newRange.days) {
      this.rangeFilters.days = +newRange.days
    }
    this.rangeFilterChanged.emit(this.rangeFilters)
  }

  onDaysChange(selectedDay: string) {
    this.rangeFilters = selectedDay !== '0' ? { days: Number(selectedDay) } : undefined
    this.rangeFilterChanged.emit(this.rangeFilters)
  }

  getDaysOptions = () => {
    this.daysOptions = this.rangeOptions.map((element) => ({
      key: element.toString(),
      text: this.translateHelper.instant(Constants.rangesTranslationKeyMap[element]),
    }))
  }
  
  private rangeSettings() {
    const defaultDate = () => this.defaultDates ? undefined : 30

    if (this.calendarType) {
      this.defaultRange = this.defaultDaysFilter ? this.defaultDaysFilter : defaultDate()
      if (this.calendarType.toString() !== CalendarType[CalendarType.ChooseLastDays]) {
        this.rangeOptions = this.daysList && this.daysList.length > 0 ? this.daysList : Constants.defaultRangeOptions
        this.showCustomRange = true
        this.setInitialRangeFilter()
      } else {
        this.rangeOptions = this.daysList && this.daysList.length > 0 ? this.daysList : Constants.defaultDaysOptions
        this.getDaysOptions()
        this.setInitialDaysFilter()
      }
    }


  }
}

import { omit } from 'lodash-es'
import { EnhancedJsonFormData, EnhancedPropertyFormDescriptor } from '@app/shared/models/enhanced-json-form-data'
import { CvSchemaAttribute, SchemaPropertyDecorator } from './json-schema-rosetta-v1.class'

export class PasswordDecorator extends SchemaPropertyDecorator {
  match(schema: EnhancedJsonFormData): boolean {
    const properties = schema?.properties ?? {}

    return Object.entries(properties).some((value) => this.isDataSourceProp(value))
  }

  getJsonSchema(): EnhancedJsonFormData {
    const schema = super.getJsonSchema()

    if (this.match(schema)) {
      const properties = Object.entries(schema.properties)
        .map((value) => {
          if (!this.isDataSourceProp(value)) {
            return value
          }

          const [key, prop] = value
          const passwordProp = {
            ...omit(prop, CvSchemaAttribute.xCvPassword),
            format: 'password',
          }

          return [key, passwordProp]
        })
        .reduce((acc, [key, prop]) => ({ ...acc, [`${key}`]: prop }), {})

      schema.properties = properties
    }

    return schema
  }

  private isDataSourceProp([, prop]: [string, EnhancedPropertyFormDescriptor]): boolean {
    const key = prop[CvSchemaAttribute.xCvPassword]

    return key === true
  }
}

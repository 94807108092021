import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { TranslateHelper } from '@coreview/coreview-library'
import { SelectionAction } from '@app/core/models/ReportDefinition'
import { ClientDatagridComponent } from '@app/shared/components/client-datagrid/client-datagrid.component'
import { ApiclientService } from '@app/core/services/apiclient.service'
import { HttpClient } from '@angular/common/http'
import { of } from 'rxjs'
import { ToastService } from '@coreview/coreview-components'

@Component({
  selector: 'app-time-saving-dialog',
  templateUrl: './time-saving-dialog.component.html',
  styleUrls: ['./time-saving-dialog.component.sass'],
})
export class TimeSavingDialogComponent implements OnInit {
  @ViewChild(ClientDatagridComponent) dataGrid!: ClientDatagridComponent
  title: string
  selectionActions!: SelectionAction[]
  columnParams = {
    selectedCols: ['policyName', 'timeSavingMinutes'],
    allcols: [
      {
        originalName: 'policyName',
        name: 'policyName',
        translate: 'playbook_PolicyName',
        type: 'string',
        position: -999,
        filter: { type: 'string', name: 'policyName' },
      },
      {
        originalName: 'timeSavingMinutes',
        name: 'timeSavingMinutes',
        translate: 'playbook_timeSpentInManualProcess',
        type: 'number',
        agColDef: {
          cellRenderer: 'editCellRendererer',
        },
      },
    ],
  }

  constructor(
    private translateHelper: TranslateHelper,
    private apiClient: ApiclientService,
    private httpClient: HttpClient,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; params: any }
  ) {
    this.title = data.title
    this.initDataGrid(data.params)
  }

  getItems = () => {}

  ngOnInit(): void {}

  private initDataGrid(params: any): void {
    this.getItems = (): any => this.getItemMethod(params)
    this.selectionActions = [
      {
        text: 'common_resetToDefault',
        buttonType: 'tertiary',
        icon: 'refresh',
        visibility: 'custom',
        isVisible: () => true,
        onClick: () => this.resetValues(),
      },
      {
        text: 'common_SaveChanges',
        cvDataTest: 'playbookCardCustomizeTimeSaveChanges',
        icon: 'save',
        visibility: 'custom',
        isVisible: () => true,
        onClick: () => this.saveChanges(),
      },
    ]
  }

  private getItemMethod(params: any) {
    return of(params.rawData)
  }

  private resetValues = () => {
    const defaultData = this.data.params.rawData.map((x: any) => {
      x[this.data.params.timeSavingField] = x[this.data.params.defaultTimeSavingField]
      return x
    })
    this.dataGrid.gridApi.setRowData(defaultData)
  }
  private saveChanges = () => {
    const updates = this.dataGrid.gridApi.getRenderedNodes().map(this.getActualGridData)

    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/updateTimeSpent`
    this.httpClient.post(url, { updates }, { withCredentials: true }).subscribe((data: any) => {
      this.toastService.open({
        id: 'success',
        variant: 'success',
        title: this.translateHelper.instant('common_Success'),
        message: this.translateHelper.instant('playbook_timeSavingSavedSuccess'),
      })
    })
  }

  private getActualGridData = (node: any, index?: number) => {
    node.data.timeSpent = node.data[this.data.params.timeSavingField]
    return node.data
  }
}

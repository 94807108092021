import { RightPanelService } from '@app/core/services/right-panel.service'
import { DashboardDefinition } from './../../../../core/models/DashboardDefinition'
import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core'
import { combineLatest, Subject, of } from 'rxjs'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter, startWith, takeUntil, tap } from 'rxjs/operators'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { NavItem } from '@app/core/models/nav-item'
import { ButtonComponent, HorizontalTabGroupComponent, SmartPanelComponent } from '@coreview/coreview-components'
import { BreadcrumbService, TranslateHelper } from '@coreview/coreview-library'
import { cloneDeep, trimStart } from 'lodash-es'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { ReportsService } from '@app/core/services/reports.service'
import { ServerResponse } from '@app/core/models/ServerResponse'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'
import { ColumnState } from '@ag-grid-community/core'
import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { Helpers } from '@app/shared/utilities/helpers'
import { AdministrationsService } from '@app/core/services/administrations.service'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import * as _ from 'lodash-es'
import { ReportExportService } from '@app/core/services/report-export.service'
import { DataCenter } from '@app/core/models/data-center'
import { SaveViewComponent } from '@app/modules/reports/components/save-view/save-view.component'
import { SavedReport } from '@app/core/models/saved-report'
import { Constants } from '@app/shared/utilities/constants'
import { selectMenuByRoute, selectFavorites, selectFlatMenusAll } from '@app/store/menu/menu.selectors'
import { NewSchedulationComponent } from '@app/modules/reports/pages/new-schedulation/new-schedulation.component'
import { ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper'
import { WorkflowItemsSelectionColumnsDefinition } from '@app/shared/models/workflow-target-selection-definition'
import { selectedOrganizationSkus } from '@app/store/organizations/organizations.selectors'

@Component({
  selector: 'app-one-drive-shared-with-external-users',
  templateUrl: './one-drive-shared-with-external-users.component.html',
  styleUrls: ['./one-drive-shared-with-external-users.component.sass'],
})
export class OneDriveSharedWithExternalUsersComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild(HorizontalTabGroupComponent)
  set setTabGroup(tabGroup: HorizontalTabGroupComponent) {
    this.tabGroup = tabGroup
  }

  @ViewChild('gridSummary')
  set setGridSummary(grid: DatagridComponent) {
    if (grid) {
      this.gridSummary = grid
    }
  }

  @ViewChild('gridDetail')
  set setGridDetail(grid: DatagridComponent) {
    if (grid) {
      this.gridDetail = grid
    }
  }

  @ViewChildren(SmartPanelComponent) panels!: QueryList<SmartPanelComponent>

  reportTitle!: string
  reportDescription!: string
  tabSelectedIndex = 0

  gridSummary!: DatagridComponent
  gridDetail!: DatagridComponent

  params: any
  tabGroup!: HorizontalTabGroupComponent

  dashboardDefinition!: DashboardDefinition | undefined
  reportDefinition: ReportDefinition | undefined

  menu!: NavItem | undefined
  showDescription = false

  savedReportIdFilter?: string
  savedReportId?: string
  savedReport!: SavedReport | null
  savedReports: SavedReport[] = []
  savedReportFilterSortModel?: {
    filterModel: any;
    sortModel: { sortField: string; sortOrder: 'asc' | 'desc' };
    filterKeysToKeepCapitized?: string[];
  }
  isPivotModeActive = false
  pivotSettings: ColumnState[] = []

  metadata!: any

  managementColumnDefinition!: WorkflowItemsSelectionColumnsDefinition

  selectedRowsSummary: any[] = []
  selectedRowsDetail: any[] = []

  private destroyed$: Subject<boolean> = new Subject()
  route!: string
  private selectedDataCenter?: DataCenter
  selectedUpn?: string
  canSeeCustomReportsBySku = true //#bug 40906

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<RootState>,
    private breadcrumbService: BreadcrumbService,
    private translateHelper: TranslateHelper,
    private rightPanelService: RightPanelService,
    private administrationsService: AdministrationsService,
    public storage: LocalstorageService,
    private reportExportService: ReportExportService,
    private cdref: ChangeDetectorRef,
    private service: ReportsService,
    private reportsComponentHelper: ReportsComponentHelper
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.activatedRoute = activatedRoute
    this.savedReportIdFilter = this.activatedRoute.snapshot.queryParams.SavedReportId
    this.savedReportId = this.activatedRoute.snapshot.queryParams.SavedReportId
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges()
  }

  ngOnInit(): void {
    const event = this.router.events.pipe(
      filter((eventRes: any) => eventRes instanceof NavigationEnd),
      startWith(this.router)
    )

    combineLatest([event, this.store.select(selectFlatMenusAll), this.store.select(selectFavorites), this.activatedRoute.data])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([eventRes, { flatMenuAll }, favorites, data]) => {
        this.route = eventRes.url?.split('?')[0]

        if (eventRes.url?.split('?userPrincipalName').length > 1 && eventRes.url?.split('?userPrincipalName')[1] !== '') {
          this.selectedUpn = eventRes.url?.split('?userPrincipalName=')[1]
        }

        this.savedReport = data.savedReport

        const definition = this.getDefinition()
        this.dashboardDefinition = cloneDeep(definition)

        this.reportTitle = this.savedReport ? this.savedReport.reportName : this.translateHelper.instant(definition.title || '')
        this.reportDescription = this.savedReport
          ? this.savedReport.description
          : this.translateHelper.instant(definition.description || '')

        if (this.selectedUpn || (this.savedReport && this.savedReport.request.isSummary === false)) {
          this.tabSelectedIndex = 1

          if (this.dashboardDefinition?.tabs && this.dashboardDefinition?.tabs[1].cards) {
            this.reportDefinition = this.dashboardDefinition?.tabs[1].cards[0].reportDefinition
          }

          if (this.reportDefinition) {
            if (this.selectedUpn) {
              this.reportDefinition.filters = {
                ...this.reportDefinition.filters,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                UPN: {
                  filterType: 'text',
                  type: 'equals',
                  filter: this.selectedUpn,
                },
              }
            }
          }
        } else {
          this.tabSelectedIndex = 0

          if (this.dashboardDefinition?.tabs && this.dashboardDefinition?.tabs[0].cards) {
            this.reportDefinition = this.dashboardDefinition?.tabs[0].cards[0].reportDefinition
          }
        }

        this.setMenu(flatMenuAll, favorites)
        this.setSavedReportsParams()
      })

    this.breadcrumbService.addComponent({
      type: ButtonComponent,
      data: {
        buttonType: 'tertiary',
        text: this.translateHelper.instant('common_Reload'),
        leftIcon: 'autorenew',
        size: 'tiny',
      },
      events: {
        clicked: (e: any) => this.reload(),
      },
    })
  }

  ngOnDestroy() {
    this.breadcrumbService.clear()
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  tabChanged(params: any) {
    setTimeout(() => {
      if (this.selectedUpn) {
        this.selectedUpn = undefined
        this.goToUrlClick('reports/oneDrive/oneDriveSharedWithExternalUsers')
        return
      }

      this.selectedRowsSummary = []
      this.selectedRowsDetail = []

      this.savedReport = null
      this.savedReportId = undefined

      this.reportTitle = this.translateHelper.instant(this.dashboardDefinition?.title || '')
      this.reportDescription = this.translateHelper.instant(this.dashboardDefinition?.description || '')

      this.tabSelectedIndex = params.index

      if (this.tabSelectedIndex === 0) {
        if (this.dashboardDefinition?.tabs && this.dashboardDefinition?.tabs[0].cards) {
          this.reportDefinition = this.dashboardDefinition?.tabs[0].cards[0].reportDefinition
        }
      } else {
        if (this.dashboardDefinition?.tabs && this.dashboardDefinition?.tabs[1].cards) {
          this.reportDefinition = this.dashboardDefinition?.tabs[1].cards[0].reportDefinition
        }
      }

      this.setSavedReportsParams()
      this.loadSavedReports()
    }, 1)
  }

  getPageData$(card: any): any {
    return of(card.reportDefinition)
  }

  getItems$ = (reportUrl: string, verb: Verb, params: any) => this.service.getData(reportUrl, verb, params) || of({} as ServerResponse<any>)

  getReportParams(card: any, params: ApiDataParameters): any {
    const reportParams = { ...params }

    if (this.savedReportIdFilter) {
      Object.assign(reportParams, { savedReportId: this.savedReportIdFilter })
      this.savedReportIdFilter = undefined
    }

    if (card?.reportDefinition?.urlParameters) {
      Object.assign(reportParams, card.reportDefinition.urlParameters)
    }

    if (this.reportDefinition?.urlParameters) {
      Object.assign(reportParams, this.reportDefinition.urlParameters)
    }

    return reportParams
  }

  refreshTable() {
    if (this.tabSelectedIndex === 0) {
      this.gridSummary?.refresh()
    } else {
      this.gridDetail?.refresh()
    }
  }

  reload() {
    this.refreshTable()
  }

  loadMenuAndFavorite() {
    combineLatest([this.store.select(selectMenuByRoute(trimStart(this.route, '/'))), this.store.select(selectFavorites)])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([menu, favorites]) => {
        this.menu = menu
        if (this.menu) {
          this.menu.isFavorite = !!favorites.favoriteMenus.find((x: any) => this.menu?.id === x)
        }
        this.breadcrumbService.updatePath(this.getPath())
      })
  }

  setIsFavorite(favorites: any) {
    if (this.menu) {
      if (this.savedReportId) {
        this.menu.isFavorite = !!favorites.favoriteSavedReports.find(
          (x: any) =>
            this.menu?.route === x.route.replace(`${Constants.savedReportString}${this.savedReportId}`, '') &&
            this.savedReportId === x.savedReportId
        )
      } else {
        this.menu.isFavorite = !!favorites.favoriteMenus.find((x: any) => this.menu?.id === x)
      }
    }
  }

  setMenu(flatMenuAll: NavItem[], favorites: any) {
    this.menu = flatMenuAll.find((x: NavItem) => {
      const urlToFind = this.savedReportId ? this.route.replace(`${Constants.savedReportString}${this.savedReportId}`, '') : this.route
      return '/' + x.route === urlToFind
    })
    if (this.menu) {
      this.setIsFavorite(favorites)
      this.loadSavedReports()
      this.breadcrumbService.updatePath([...this.getPath()])
    } else {
      this.breadcrumbService.updatePath([this.translateHelper.instant('common_Reports')])
    }
  }

  private getPath(): string[] {
    const path = []
    let menu = this.menu
    while (menu) {
      path.push(this.translateHelper.instant(menu.title))
      menu = menu.parent || undefined
    }
    return path.reverse()
  }

  rowSelectedSummary() {
    this.selectedRowsSummary = this.gridSummary?.getSelectedRows()
  }

  rowSelectedDetail() {
    this.selectedRowsDetail = this.gridDetail?.getSelectedRows()
  }

  getSummaryItemsCall(params: ApiDataParameters): any {
    this.params = {
      ...this.getReportParams(null, params),
    }

    return this.administrationsService.getOneDriveExternalUsers(this.params).pipe(
      tap((x: any) => {
        if (this.reportDefinition) {
          this.reportDefinition.fields = x.configs.filter((c: any) => c.visible).map((c: any) => c.originalName)
          this.params.fields = this.reportDefinition.fields.map((c: any) => Helpers.capitalize(c))
          x.metadata.fields = this.reportDefinition.fields
        }

        this.managementColumnDefinition = {
          configs: x?.configs,
          fields: x?.metadata.fields,
          isOnlineUsersType: false,
        }
      })
    )
  }

  getSummaryItems = (params: ApiDataParameters): any => ({
    items: this.getSummaryItemsCall(params),
    cols: of([]),
  })

  getDetailItemsCall(params: ApiDataParameters): any {
    this.params = {
      ...this.getReportParams(null, params),
    }

    const apiToCall = this.administrationsService.getOneDriveExternalUsers(this.params)

    return apiToCall.pipe(
      tap((x) => {
        if (this.reportDefinition) {
          this.reportDefinition.fields = x.configs.filter((c: any) => c.visible).map((c: any) => c.originalName)
          this.params.fields = this.reportDefinition.fields.map((c: any) => Helpers.capitalize(c))
          x.metadata.fields = this.reportDefinition.fields
        }
        this.managementColumnDefinition = {
          configs: x?.configs,
          fields: x?.metadata.fields,
          isOnlineUsersType: false,
        }
      })
    )
  }

  getDetailItems = (params: ApiDataParameters): any => ({
    items: this.getDetailItemsCall(params),
    cols: of([]),
  })

  getRefreshData(card: any): any {
    return (params: ApiDataParameters) => ({
      // eslint-disable-next-line max-len
      items: this.getItems$(card.reportDefinition?.url || '', card.reportDefinition?.verb || 'post', this.getReportParams(card, params)),
      cols: null,
    })
  }

  goToUrlClick(url: string, params?: any) {
    this.router.navigate([url], { queryParams: params }).catch((_: any) => _)
  }

  getDefinition(): any {
    /* eslint-disable @typescript-eslint/quotes */
    const licenseOptimizationDashboardDefinition = {
      route: '/reports/oneDrive/oneDriveSharedWithExternalUsers/*',
      title: 'OneDriveSharedWithExternalUsers',
      description: 'licenses_OneDriveSharedWithExternalUsersDescription',
      tabs: [
        {
          title: 'licenses_OneDriveSharedWithExternalUsersSummary',
          icon: '',
          role: 'tenantAdmin',
          cards: [
            {
              loading: false,
              title: 'SummaryTable',
              type: 'table',
              reportDefinition: {
                actionsTags: ['oneDriveSharedWithExternalUsersSummary', 'category_Users'],
                entity: 'oneDriveSharedWithExternalUsersSummary',
                title: 'licenses_OneDriveSharedWithExternalUsersSummary',
                urlParameters: {
                  isSummary: true,
                },
                fields: ['UserPrincipalName', 'Count'],
                responseItemProp: 'oneDriveExternalUsers',
                sortField: 'Count',
                sortOrder: 'desc',
                url: '/onedrive/externalusers',
                verb: 'get',
                filterActions: [],
                groupedActions: [
                  { 
                    ...this.reportsComponentHelper.scheduleAction, 
                    onClick: () => {
                      this.schedule(this.gridSummary)
                    },
                  },
                  { 
                    ...this.reportsComponentHelper.saveCustomReportAction, 
                    onClick: () => {
                      this.saveView(this.gridSummary)
                    },
                  },
                  { 
                    ...this.reportsComponentHelper.exportAction, 
                    isMenuButton: true,
                    options: Constants.exportOptions,
                    onClick: (data: any[], key: string | null) => {
                      this.exportReport(this.gridSummary, key ?? '')
                    },
                  }
                ],
                operationsOnCols: [
                  {
                    name: 'userPrincipalName',
                    field: 'userPrincipalName',
                    param: '/reports/oneDrive/oneDriveSharedWithExternalUsers',
                    props: {
                      UserPrincipalName: (data: any, queryParams: any) => {
                        queryParams.userPrincipalName = data.userPrincipalName
                        return queryParams
                      },
                    },
                    type: 'link',
                    external: false,
                  },
                ],
              },
            },
          ],
        },
        {
          title: 'licenses_OneDriveSharedWithExternalUsersDetails',
          icon: '',
          role: 'tenantAdmin',
          calendarType: 'ChooseLastDays',
          daysList: [30, 60, 90],
          defaultDaysFilter: 30,
          cards: [
            {
              loading: false,
              title: 'DetailsTable',
              type: 'table',
              reportDefinition: {
                actionsTags: ['oneDriveSharedWithExternalUsersDetail', 'category_Users'],
                entity: 'oneDriveSharedWithExternalUsersDetail',
                title: 'licenses_OneDriveSharedWithExternalUsersDetails',
                isOnlineUsersType: false,
                urlParameters: {
                  isSummary: false,
                },
                fields: ['UserPrincipalName', 'Email', 'AcceptedAs', 'DisplayName', 'WhenCreated', 'InvitedBy'],
                responseItemProp: 'oneDriveExternalUsers',
                sortField: 'UPN',
                sortOrder: 'asc',
                url: '/onedrive/externalusers',
                verb: 'get',
                filters: {},
                groupedActions: [
                  { 
                    ...this.reportsComponentHelper.exportAction, 
                    isMenuButton: true,
                    options: Constants.exportOptions,
                    onClick: (data: any[], key: string | null) => {
                      this.exportReport(this.gridDetail, key ?? '')
                    },
                  },
                  { 
                    ...this.reportsComponentHelper.scheduleAction, 
                    onClick: (data: any[]) => {
                      this.schedule(this.gridDetail)
                    },
                  },
                  { 
                    ...this.reportsComponentHelper.saveCustomReportAction, 
                    onClick: (data: any[]) => {
                      this.saveView(this.gridDetail)
                    },
                  }
                ],
                operationsOnCols: [],
                operationsColumns: [
                  {
                    originalName: 'common_OpenCardInNewTab',
                    predefinedOperation: 'card',
                    param: {
                      redirectUrl: 'usercard/{userPrincipalName}',
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
    }

    return licenseOptimizationDashboardDefinition
  }

  schedule(grid: DatagridComponent) {
    this.metadata.queryRequest.fields = this.reportsComponentHelper.getVisibleFields(grid.columnApi)
    const panelRef = this.rightPanelService.open({
      type: NewSchedulationComponent,
      data: {
        request: this.metadata?.queryRequest,
        route: this.route,
        initialName: this.savedReport ? this.reportTitle : this.reportDefinition?.title,
        savedReport: this.savedReport,
        fromReport: true,
      },
    })
    panelRef
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(() => {
        this.loadSavedReports()
        this.panels.first.open = true
      })
  }

  saveView(grid: DatagridComponent) {
    this.metadata.queryRequest.fields = this.reportsComponentHelper.getVisibleFields(grid.columnApi)
    const panelRef = this.rightPanelService.open({
      type: SaveViewComponent,
      data: {
        lastRequest: this.metadata?.queryRequest,
        reportDefinition: this.reportDefinition,
        route: this.route,
        savedReport: this.savedReport,
        pivotSettings: this.isPivotModeActive ? JSON.stringify(this.pivotSettings) : null,
        isPivot: this.isPivotModeActive,
      },
    })
    panelRef
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(() => {
        this.loadSavedReports()
        this.panels.first.open = true
      })
  }

  loadSavedReports() {
    const urlToFind = this.savedReportId ? this.route.replace(`${Constants.savedReportString}${this.savedReportId}`, '') : this.route

    const urlToFindResults = '/onedrive/externalusers'

    this.service.getViews({ pageSize: 50, pageNumber: 1, route: urlToFind, sortOrder: 'asc', sortField: 'reportName' }).subscribe((res) => {
      this.savedReports = res.filter((r: SavedReport) => r.apiUrl === urlToFindResults)
    })
  }

  exportReport(grid: DatagridComponent, key: string) {
    this.metadata.queryRequest.fields = this.reportsComponentHelper.getVisibleFields(grid.columnApi)

    this.reportExportService
      .exportGeneric(
        this.getExportedItems,
        this.metadata,
        this.reportDefinition!.verb,
        key,
        this.translateHelper.instant(this.reportDefinition?.title || ''),
        this.reportDefinition?.url!
      )
      .subscribe()
  }

  getExportedItems = (params: ApiDataParameters & { asyncExport: boolean; reportNameContainer: string }, format: string) => {
    const paramsToPass = { ...params, ...this.params }

    return (
      this.service.getExportedData(
        this.reportDefinition?.url || '',
        this.reportDefinition?.verb || 'post',
        paramsToPass,
        format,
        this.selectedDataCenter?.apiUrl
      ) || of({} as any)
    )
  }

  setSavedReportsParams() {
    if (this.savedReport) {
      let filterModel: any = {}
      const filterKeysToKeepCapitized: string[] = ['UPN']

      filterModel = this.savedReport.request?.filters

      this.savedReportFilterSortModel = {
        filterModel,
        sortModel: { sortField: this.savedReport.request?.sort, sortOrder: this.savedReport.request?.sortOrder },
        filterKeysToKeepCapitized,
      }
    } else {
      this.savedReportFilterSortModel = {
        filterModel: null,
        sortModel: {
          sortField: this.reportDefinition?.sortField || 'UPN',
          sortOrder: this.reportDefinition?.sortOrder === 'desc' ? 'desc' : 'asc',
        },
      }
    }
  }

  canSeeLeftPanel() {
    return this.canSeeCustomReportsBySku
  }
}

/* eslint-disable @typescript-eslint/member-delimiter-style */
import { EnhancedJsonFormData, EnhancedPropertyFormDescriptor } from '@shared/models/enhanced-json-form-data'

export type EnhancedPropertyNames = keyof EnhancedPropertyFormDescriptor

export abstract class JsonSchemaParser {
  jsonSchema!: EnhancedJsonFormData

  abstract getJsonSchema(): EnhancedJsonFormData
}

export class JsonSchemaV1Rosetta extends JsonSchemaParser {
  constructor(public jsonSchema: EnhancedJsonFormData) {
    super()
  }

  public getJsonSchema(): EnhancedJsonFormData {
    return this.jsonSchema
  }
}

export abstract class SchemaPropertyDecorator extends JsonSchemaParser {
  constructor(protected component: JsonSchemaParser) {
    super()
  }

  /**
   * The Decorator delegates all work to the wrapped component.
   */
  public getJsonSchema(): EnhancedJsonFormData {
    return this.component.getJsonSchema()
  }

  public match(schema: EnhancedJsonFormData): boolean {
    return true
  }
}

export type DecoratorList = Array<new (component: JsonSchemaParser) => SchemaPropertyDecorator>

export enum CvSchemaAttribute {
  xCvDataSource = 'x-cv-data-source',
  xCvDataSourceFilterable = 'x-cv-data-source-filterable',
  xCvCustomList = 'x-cv-custom-list',
  xCvUi = 'x-cv-ui',
  xCvPassword = 'x-cv-password',
  xCvProtected = 'x-cv-protected',
  xCvTooltip = 'x-cv-tooltip',
  xCvRichText = 'x-cv-richText'
}

<div class="panel-container">
  <div class="panel">
    <div class="title p-l-20" style="display: flex; line-height: 69px">
      <div style="flex: 1; font-size: 14px; font-weight: 600" class="uppercase" translate>licenses_AdvancedFilters</div>
    </div>
    <div class="p-20" style="height: 100%; overflow-y: scroll">
      <ng-container *ngIf="hasShowOnlyPoolsWithAllocation">
      <div class="subtitle">
        <div translate>
          <span style="line-height: 24px" translate>common_Filters</span>
        </div>
      </div>
      <div>
        <cv-switch (checkedChange)="changeSKUwithAllocation($event)" [value]="showOnlyPoolsWithAllocation">
          <div class="m-t-15" style="white-space: normal;">{{ 'licenses_ShowOnlyPoolsWithAllocationTitle' | translate }}</div>
          <div class="text-grey" style="font-size: 12px; white-space: normal;">{{ 'licenses_ShowOnlyPoolsWithAllocationSubtitle' | translate }}</div>
        </cv-switch>
      </div>
    </ng-container>
      <div class="subtitle" [ngClass]="{ 'm-t-30': hasShowOnlyPoolsWithAllocation === true }" >
        <div translate>
          <span style="line-height: 24px" translate>common_SKUS</span>
        </div>
      </div>
      <cv-checkbox-list orientation="vertical" class="col">
        <cv-checkbox (checkedChange)="toggleSelectAll()" [value]="selectAll">
          <div translate>common_SelectAll</div>
        </cv-checkbox>
        <cv-checkbox (checkedChange)="handleChange(license)" [value]="isCheckedLicense(license)" *ngFor="let license of licenses">
          <div>{{ license.sku }}</div>
          <div class="text-grey" style="font-size: 12px">{{ 'licenses_Available' | translate }}: {{ license.notAssigned }}</div>
        </cv-checkbox>
      </cv-checkbox-list>
    </div>

    <div class="footer-container">
      <cv-button (click)="close()" class="m-r-10" buttonType="secondary" [text]="'common_Cancel' | translate"> </cv-button>
      <cv-button (click)="submit()" primary="secondary" [text]="'common_Apply' | translate" [disabled]="!isValid"> </cv-button>
    </div>
  </div>
</div>

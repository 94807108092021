import { ToastService } from '@coreview/coreview-components'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import { ReportsService } from '@app/core/services/reports.service'
import { combineLatest, Subject, Observable, of, timer } from 'rxjs'
/* eslint-disable max-len */
import { Component, Input, OnInit, EventEmitter, Output, ViewChild, OnDestroy, SimpleChanges, OnChanges } from '@angular/core'
import {
  ColDef,
  ColumnApi,
  GridApi,
  IServerSideGetRowsParams,
  GridOptions,
  GetContextMenuItemsParams,
  MenuItemDef,
  ProcessCellForExportParams,
  ServerSideGroupLevelParams,
  RowModelType,
  IRowNode,
  SortDirection,
} from '@ag-grid-community/core'
import { Constants } from '@app/shared/utilities/constants'
import { BuildColAgGrid, BuildColParameter } from '@app/shared/utilities/build-col-ag-grid'
import { LibBuildColAgGrid, TranslateHelper } from '@coreview/coreview-library'
import {
  CellClassRules,
  comparableDateVerbs,
  comparableVerbs,
  comparableVerbsWithNoValues,
  ExternalFilter,
  FilterDefinition,
  FilterReportColumn,
  OperationColumn,
  OperationsOnCols,
  ReportDefinition,
} from '@app/core/models/ReportDefinition'

import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { ServerResponse } from '@app/core/models/ServerResponse'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import { PaginationComponent } from '../pagination/pagination.component'
import { Helpers } from '@app/shared/utilities/helpers'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { distinctUntilChanged, takeUntil, switchMap, delay, tap, debounceTime, filter } from 'rxjs/operators'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { selectShowSensitiveData, selectUserSettings, selectUserSettingsColumnsByEntity } from '@app/store/userSettings/userSettings.selectors'
import { flatten, get, groupBy, keys, map, sumBy, union, uniq, forEach, isEqual } from 'lodash-es'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { QueryFilter2 } from '@app/core/models/QueryFilter'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { ActivatedRoute } from '@angular/router'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { LoadingCellRendererComponent } from './loading-cell-renderer/loading-cell-renderer.component'
import { UserSettingColumn } from '@coreview/coreview-library/models/user-settings'
import { selectHasActionsOrCustomActions } from '@app/store/management-actions/management-actions.selectors'
import { convertTargetEntityForActions } from '@app/core/enums/group-type'
import { UserSettingsService } from '@app/core/services/user-settings.service'

@Component({
  selector: 'app-datagrid',
  templateUrl: './datagrid.component.html',
  styleUrls: ['./datagrid.component.sass'],
})
export class DatagridComponent implements OnInit, OnChanges, OnDestroy {
  @Input() hideColumnsSelector = false
  @Input() routeParamsFilters?: Record<string, string>
  @Input() public rowData!: ServerResponse<any> | null
  @Input() getItems!:
    | ((
        params: ApiDataParameters,
        dataCenterUrl?: string,
        serverSideParams?: IServerSideGetRowsParams
      ) => {
        items: Observable<ServerResponse<any>>
        cols?: Observable<CoreViewColumn[]> | null
      })
    | undefined
  @Input() allCols!: CoreViewColumn[] | undefined | null

  @Input() gridDefinition!: ReportDefinition | null | undefined
  @Input() fieldsForManagement: string[] = []

  @Input() autoGroupColumnDef?: ColDef
  @Input() treeData = false
  @Input() isServerSideGroup?: (dataItem: any) => boolean
  @Input() getServerSideGroupKey?: (dataItem: any) => string
  @Input() customParameters: any = {}
  @Input() rangeFilters?: { since?: string; to?: string; days?: number }
  @Input() customFilters?: FilterReportColumn
  @Input() savedReportFilterSortModel?: {
    filterModel: Record<string, string>
    treeFilters?: QueryFilter2
    sortModel: { sortField: string; sortOrder: SortDirection }
    filterKeysToKeepCapitized?: string[]
  }

  @Input() autoHeight = false
  @Input() keepSelectionBetweenPages = true

  @Input() cvDataTest = 'server-datagrid'
  @Input() enablePivotMode = false
  @Input() enableAnonymousData = false

  @Input() externalFunctionMapper: Record<string, (filter: ExternalFilter, key: string | null) => void> = {}
  @Input() defaultColumnsDefinition?: ColDef

  @Input() expandAllOnSelectAll = false
  @Input() skipResize = false

  @Output() public pageDataChanged = new EventEmitter<{ currentPage: number; currentPageSize: number; data: any[]; dataChart: any }>()
  @Output() metadataChanged = new EventEmitter<any>()
  @Output() rowSelected = new Subject<{ rowData: any; isSelected: boolean }>()
  @Output() columnDefsDefined = new EventEmitter<{ columnDefs: (ColDef & CoreViewColumn)[]; serverCols: CoreViewColumn[] }>()
  @Output() modelUpdated = new EventEmitter<any>()
  @Output() pivotModeChanged = new EventEmitter<any>()
  @Output() gridReady = new EventEmitter<any>()
  @Output() filterChanged$ = new EventEmitter<void>()

  @ViewChild(PaginationComponent)
  pagination!: PaginationComponent

  sortHasChanged = false

  public selectedColumnsChanged$: Subject<{ newColumns: (ColDef & CoreViewColumn)[]; visibilityUpdated: boolean }> = new Subject<{
    newColumns: (ColDef & CoreViewColumn)[]
    visibilityUpdated: boolean
  }>()

  public filter = ''
  public savedReportFilters?: FilterReportColumn

  public defaultCols!: (ColDef & CoreViewColumn)[]
  public columnDefs!: (ColDef & CoreViewColumn)[]
  public gridApi!: GridApi
  public columnApi!: ColumnApi
  defaultColDef = Constants.defaultColumnsDefinition

  public paginationPageSize = Constants.paginationPageSize
  public cacheBlockSize = Constants.cacheBlockSize
  public rowModelType: RowModelType = 'serverSide'
  public dataCenterUrl?: string
  userSettingsColumns?: UserSettingColumn[]
  previousRangeFilter?: number

  colsWithDetail!: OperationColumn[]
  hasMasterDetail = true
  defaultDetailCellRenderer = 'dictionaryCellRenderer'
  detailCellRenderer = 'dictionaryCellRenderer'
  detailCellRendererParam = {}
  detailRowHeight?: number = 0
  gridOptions: GridOptions = {}

  frameworkComponents: any
  showSensitiveData = false

  modules = [
    ServerSideRowModelModule,
    ClientSideRowModelModule,
    RowGroupingModule,
    SetFilterModule,
    MasterDetailModule,
    ColumnsToolPanelModule,
    RangeSelectionModule,
    ClipboardModule,
    MenuModule,
  ]
  rowClassRules: any = {
    'row-in-progress': (params: any) => params?.data?.inProgress,
  }

  rowSelection?: 'single' | 'multiple'
  firstLoad = true

  serverCols!: CoreViewColumn[]

  allColsLocal: any[] = []
  suppressMultiSort = true

  resize$ = new EventEmitter<any>()

  private isSavedReport = false
  private savedReportUpdatedColumns?: string[]
  private ignoreRequest = false
  private response: any = {}
  private loadedItemsForServerSideSelection: any[] = []

  private skipNextResize = false

  private cellClassRulesFunction: { [K: string]: () => any } = {
    subscriptionsHistoryCellClassRulesFunc: this.reportsService.subscriptionsHistoryCellClassRulesFunc,
  }
  private destroyed$: Subject<boolean> = new Subject()

  private currentCol: string | null = null
  private currentColUpdated = new Subject<any>();


  set loadedItemsForSelectAll(loadedItems: any[]) {
    this.loadedItemsForServerSideSelection = loadedItems
  }

  constructor(
    private translateHelper: TranslateHelper,
    private reportsService: ReportsService,
    private localstorageService: LocalstorageService,
    private toastService: ToastService,
    private buildColumnsHelper: BuildColAgGrid,
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private sharedHelperService: SharedHelperService,
    private userSettingsService: UserSettingsService
  ) {
    this.frameworkComponents = buildColumnsHelper.frameworkComponents

    this.store.select(selectShowSensitiveData).subscribe((sensitiveData) => (this.showSensitiveData = sensitiveData))
    this.isSavedReport = !!this.activatedRoute.snapshot.queryParams?.SavedReportId

    this.currentColUpdated.pipe(
      debounceTime(500), // Ensure the column is visible after the model is updated
      takeUntil(this.destroyed$)
    ).subscribe(_ => {
      if (this.currentCol) {
        this.gridApi?.ensureColumnVisible(this.currentCol, 'auto');
      }
    });
  }

  createServerSideDatasource() {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        const currentPageSize = (params.request.endRow ?? 0) - (params.request.startRow ?? 0)
        if (this.ignoreRequest || currentPageSize !== this.gridApi?.paginationGetPageSize()) {
          this.ignoreRequest = false
          params.success(this.response)
          return
        }

        this.getRows(
          currentPageSize,
          params.request.startRow ?? 0,
          params.request.groupKeys,
          this.getTreeFilters(),
          undefined,
          params
        ).subscribe(
          (response: any) => {
            this.checkNoRowsOverlay(response)
            if (response) {
              this.checkSelectedColumnsChanged()
              params.success(this.response)
            } else {
              this.firstLoad = false
              params.fail()
            }
          },
          (error) => {
            if(this.sortHasChanged)
              this.sortHasChanged = false

            this.toastService.open({
              id: 'error',
              title: this.translateHelper.instant('common_ErrorTitle'),
              message: this.translateHelper.instant('common_ErrorMessage'),
              variant: 'error',
            })
            this.firstLoad = false
            params.fail()
            this.gridApi?.showNoRowsOverlay()
          }
        )
      },
    }
  }

  defineCellClassRules = () => {
    if (!this.gridDefinition?.cellClassRules || this.gridDefinition.cellClassRules.length === 0) {
      return
    }

    const cellClassRules: any = {}

    this.gridDefinition.cellClassRules.forEach((c: CellClassRules) => {
      if (c.prop && !cellClassRules[c.prop]) {
        cellClassRules[c.prop] = {}
      }

      if (c.prop && c.class && c.value) {
        cellClassRules[c.prop][c.class] = c.value
      } else if (c.prop && c.func) {
        cellClassRules[c.prop] = this.getCellClassRulesFunction(c.func)
      }
    })

    return cellClassRules
  }

  getSortModelFromUserSettings(): { sortField: string; sortOrder: SortDirection } | undefined {
    const firstSortModel = this.userSettingsColumns?.find((col) => col.sortIndex === 0)
    if (firstSortModel) {
      return {
        sortField: this.getSortColumnId(firstSortModel.originalName),
        sortOrder: this.convertStringToSortOrder(firstSortModel.sort),
      }
    }
    return undefined
  }

  defineColumns(paramsForBuildColDef: BuildColParameter) {
    const cols = this.buildColumnsHelper.buildCols(
      paramsForBuildColDef,
      this.getHiddenFields(),
      this.localstorageService.selectedOrganization?.id,
      this.getUserSettingsColumnsForBuildCols(),
      this.gridDefinition?.lockedColumns,
      this.gridDefinition?.availableFilters,
      this.useLegacyLicenseFilter()
    )

    if (
      isEqual(
        cols?.map((c) => c.field),
        this.columnDefs?.map((c) => c.field)
      )
    ) {
      this.columnDefsDefined.emit({ columnDefs: cols, serverCols: this.serverCols })
      return
    }

    this.checkSortedColumns(cols)

    if (cols.length > 0) {
      this.columnDefs = cols
      this.columnDefsDefined.emit({ columnDefs: cols, serverCols: this.serverCols })
    }
    if (!this.defaultCols || this.defaultCols.length === 0) {
      this.defaultCols = cols.filter((c: any) => this.gridDefinition?.fields?.includes(c.originalName))
    }
  }

  getFields = (): (string | undefined)[] => {
    const cols = this.getSelectedColumns()

    return uniq([
      ...(this.gridDefinition?.lockedColumns || []),
      ...(this.gridDefinition?.defaultHiddenFields || []),
      ...this.fieldsForManagement,
      ...cols.map((c: any) => c.originalName).filter((c) => !!c),
    ]).filter((x) => !!x && !Constants.columnsToExcludeInExport.includes(x))
  }

  getStartColumns() {
    if (this.gridDefinition?.entity && this.userSettingsColumns) {
      return this.userSettingsColumns.filter((n) => !!n).map((c) => ({ originalName: c.originalName }))
    }
    return this.gridDefinition?.fields?.map((c) => ({ headerName: c, field: c, originalName: c })) || []
  }

  getSortModel = (): { sort: string; sortOrder: 'asc' | 'desc' | null } => {
    const sortModel = this.columnApi?.getColumnState().filter((x) => !!x.sort)

    if (this.firstLoad || sortModel.length === 0) {
      if (!!this.savedReportFilterSortModel?.sortModel) {
        return {
          sort: this.getSortColumnId(this.savedReportFilterSortModel?.sortModel.sortField),
          sortOrder: this.savedReportFilterSortModel?.sortModel.sortOrder,
        }
      }

      const userSettingSortModel = this.getSortModelFromUserSettings()
      if (userSettingSortModel) {
        return {
          sort: userSettingSortModel.sortField,
          sortOrder: userSettingSortModel.sortOrder,
        }
      }

      if (!!this.gridDefinition) {
        return {
          sort: this.getSortColumnId(this.gridDefinition?.sortField),
          sortOrder: this.gridDefinition.sortOrder,
        }
      }
    }
    return get(
      sortModel.map((sm) => ({
        sort: this.getSortColumnId(Helpers.capitalize(sm.colId || '')),
        sortOrder: sm.sort || null,
      })),
      0
    )
  }

  setFilterModel(cols: CoreViewColumn[]) {
    let filterModel: any = {}

    if (this.gridDefinition?.initialFilters) {
      filterModel = { ...filterModel, ...this.gridDefinition.initialFilters }
      this.gridDefinition.initialFilters = undefined
    }

    if (this.gridDefinition?.initialUrlFilters) {
      filterModel = {
        ...filterModel,
        ...this.buildColumnsHelper.dictionaryFilterToFilterDefinition(this.gridDefinition.initialUrlFilters, cols),
      }
      this.gridDefinition.initialUrlFilters = undefined
    }

    if (this.gridDefinition?.filters) {
      filterModel = { ...filterModel, ...this.gridApi?.getFilterModel(), ...this.gridDefinition.filters }
    }

    if (!!this.customFilters && Object.keys(this.customFilters).length > 0) {
      filterModel = { ...filterModel, ...this.gridApi?.getFilterModel(), ...this.customFilters }
      this.customFilters = {}
    }

    if (!!this.savedReportFilterSortModel && Object.keys(this.savedReportFilterSortModel).length > 0) {
      filterModel = { ...filterModel, ...this.gridApi?.getFilterModel(), ...this.savedReportFilters }

      if (this.savedReportFilterSortModel.treeFilters) {
        filterModel = {
          ...filterModel,
          ...this.buildColumnsHelper.mapSavedTreeFilters(this.savedReportFilterSortModel.treeFilters, this.gridDefinition?.treeFilters),
        }
      }
      this.savedReportFilterSortModel = undefined
    }

    if (!!this.routeParamsFilters && Object.keys(this.routeParamsFilters).length > 0) {
      const filters = this.buildColumnsHelper.dictionaryFilterToFilterDefinition(this.routeParamsFilters, cols)
      filterModel = { ...filterModel, ...this.gridApi?.getFilterModel(), ...filters }
    }

    if (!!this.gridDefinition?.treeFilters) {
      filterModel = { ...this.buildColumnsHelper.mapTreeFilters(this.gridDefinition?.treeFilters), ...filterModel }
    }

    if (Object.keys(filterModel).length > 0) {
      filterModel = this.rebuildFilterModel(filterModel)

      this.gridApi?.setFilterModel(filterModel)
      this.ignoreRequest = true
    }
  }

  rebuildFilterModel(filterModel: any) {
    Object.keys(filterModel).forEach((fm) => {
      this.allColsLocal
        .filter(
          (f) =>
            f.filterName &&
            Helpers.downcase(f.filterName) === Helpers.downcase(fm) &&
            Helpers.downcase(f.name) !== Helpers.downcase(f.filterName)
        )
        .forEach((s) => {
          filterModel = { ...filterModel, [s.name]: filterModel[fm] }
          delete filterModel[fm]
        })
    })
    return filterModel
  }

  ngOnInit(): void {
    this.store.select(selectUserSettings).pipe(takeUntil(this.destroyed$)).subscribe((userSettings) => {
      const rowsPerPagePreference = this.userSettingsService.getRowsPerPagePreference(userSettings)
      this.paginationPageSize = rowsPerPagePreference
      this.cacheBlockSize = rowsPerPagePreference
    })

    this.resize$
      .pipe(
        filter(() => !this.skipResize),
        debounceTime(10),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.resize())

    if (!!this.gridDefinition?.entity && !this.isSavedReport) {
      this.store
        .select(selectUserSettingsColumnsByEntity(this.gridDefinition.entity))
        .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
        .subscribe((columns: any) => {
          if (columns) {
            this.userSettingsColumns = columns
          }
        })
    }

    if (this.gridDefinition?.rowSelection !== 'none' && this.gridDefinition?.actionsTags?.length) {
      this.store
        .select(
          selectHasActionsOrCustomActions(this.gridDefinition.actionsTags, convertTargetEntityForActions(this.gridDefinition.targetEntity!))
        )
        .pipe(takeUntil(this.destroyed$))
        .subscribe((hasActionsOrCustomActions) => {
          this.rowSelection = !!hasActionsOrCustomActions
            ? (this.gridDefinition?.rowSelection as 'single' | 'multiple' | undefined) || 'multiple'
            : undefined
        })
    } else {
      this.rowSelection = this.gridDefinition?.rowSelection === 'none' ? undefined : this.gridDefinition?.rowSelection || 'multiple'
    }

    this.detailCellRenderer = this.gridDefinition?.detailCellRenderer || this.defaultDetailCellRenderer
    this.detailCellRendererParam = this.gridDefinition?.detailCellRenderParams
    this.detailRowHeight = this.gridDefinition?.detailRowHeight
    this.gridOptions = Object.assign(
      { loadingCellRenderer: 'loadingCellRenderer', ...Constants.defaultGridOptions },
      this.gridDefinition?.gridOptions || {}
    )
    if (this.defaultColumnsDefinition) {
      this.defaultColDef = { ...this.defaultColDef, ...this.defaultColumnsDefinition }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const { rangeFilters } = changes
    if (rangeFilters && !rangeFilters.firstChange) {
      this.firstLoad = true
      this.ignoreRequest = false
      if (this.rangeFilters?.days) {
        this.previousRangeFilter = rangeFilters.previousValue?.days || this.gridDefinition?.defaultDaysFilter
        if (this.userSettingsColumns?.length) {
          this.userSettingsColumns = this.getMappedRangeColumns(this.userSettingsColumns) as {
            originalName: string
            position: number
            pinned?: string
          }[]
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  onGridReady(params: any) {
    this.gridApi = params.api
    this.columnApi = params.columnApi
    this.getRows(this.gridApi.paginationGetPageSize(), 0, [], this.getInitialTreeFilters())
      .pipe(delay(1), takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.setFilterModel(this.allColsLocal)
        this.gridApi?.setServerSideDatasource(this.createServerSideDatasource())
        this.checkNoRowsOverlay(response)
      })
    this.columnApi?.autoSizeAllColumns()
    this.gridReady.emit(params)
  }

  clearAndRefresh = () => {
    this.columnDefs = []
    this.ignoreRequest = false
    this.firstLoad = true
    this.gridApi?.deselectAll()
    this.gridApi?.showLoadingOverlay()
    this.gridApi?.refreshServerSide({ purge: true })
  }

  refresh = (dataCenterUrl?: string) => {
    if (dataCenterUrl) {
      this.dataCenterUrl = dataCenterUrl
    }

    this.gridApi?.showLoadingOverlay()
    this.gridApi?.refreshServerSide({ purge: true })
  }

  getServerSideStoreParams = (params: any): ServerSideGroupLevelParams => ({
    storeType: 'partial',
    cacheBlockSize: undefined,
    maxBlocksInCache: -1,
  })

  getFiltersVerb = (filterModel: FilterDefinition): string => comparableVerbs[filterModel.type] ?? comparableDateVerbs[filterModel.type]

  getFiltersValue = (filterModel: any) =>
    comparableVerbsWithNoValues.hasOwnProperty(filterModel.type as string)
      ? comparableVerbsWithNoValues[filterModel.type as string]
      : filterModel.filter + (filterModel.filterTo || filterModel.filterTo === 0 ? ' & ' + filterModel.filterTo : '')

  getMultiSelectFilerValues = (filterModel: FilterDefinition) => filterModel.filter
  getDateFiltersVerb = (filterModel: FilterDefinition): string => comparableDateVerbs[filterModel.type]

  getFilterKey = (propertyKey: string): string => {
    const filterName = this.serverCols?.find((x) => x.originalName?.toLowerCase() === propertyKey.toLowerCase())?.filterName
    return filterName ? Helpers.capitalize(filterName) : Helpers.capitalize(propertyKey)
  }

  buildFilter = (filterModel: any): string => {
    if (filterModel.filterType === 'date') {
      return this.getDateFiltersVerb(filterModel) + Helpers.getDateFiltersValue(filterModel)
    } else {
      return this.getFiltersVerb(filterModel) + this.getFiltersValue(filterModel)
    }
  }

  getInitialTreeFilters = () => {
    if (!!this.gridDefinition?.treeFilters?.children && this.gridDefinition?.treeFilters?.children.length > 0) {
      return {
        operation: 'AND',
        children: this.gridDefinition?.treeFilters?.children,
      } as QueryFilter2
    } else {
      return undefined
    }
  }

  getChildOperation = (group: any) => {
    if (group.some((child: any) => child.queryFilter && Helpers.downcase(child.queryFilter.operation) === 'doesNotContain')) {
      return 'AND'
    }
    return 'OR'
  }

  getTreeFilters = () => {
    let filterModel = this.gridApi?.getFilterModel()

    if (this.gridDefinition?.treeFilters) {
      filterModel = { ...this.buildColumnsHelper.mapTreeFilters(this.gridDefinition?.treeFilters), ...filterModel }
    }

    const filters = flatten(
      keys(filterModel)
        .filter((x) => !!filterModel[x].type && filterModel[x].filterType === 'multiselect')
        .map((k) => filterModel[k].filter.children)
    )

    const fGroup = groupBy(filters, 'queryFilter.name')

    const children = [
      ...map(fGroup, (g) => ({ operation: this.getChildOperation(g), children: g })),
      ...(this.gridDefinition?.treeFilters?.children.filter(
        (ai) => !!ai.queryFilter && Helpers.downcase(ai.queryFilter.operation) === 'doesNotEqual'
      ) || []), //this case is only in multi-filter-selection
    ]

    if (children.length > 0) {
      return {
        operation: 'AND',
        children,
      } as QueryFilter2
    } else {
      return undefined
    }
  }

  getFilters = (): any => {
    const filterModel = {
      ...(this.gridDefinition?.initialFilters as { [key: string]: any }),
      ...this.gridApi?.getFilterModel(),
      ...(this.gridDefinition?.filters as { [key: string]: any }),
      ...this.customFilters,
    }

    let filters: any = {}
    if (filterModel) {
      keys(filterModel)
        .filter((x) => !!filterModel[x].type && filterModel[x].filterType !== 'multiselect')
        .forEach((k: string) => {
          filters[this.getFilterKey(k)] = this.buildFilter(filterModel[k])
        })
    }

    if (this.gridDefinition?.initialUrlFilters) {
      filters = { ...this.gridDefinition?.initialUrlFilters, ...filters }
    }

    if (this.routeParamsFilters && keys(this.routeParamsFilters).length > 0) {
      filters = { ...filters, ...this.routeParamsFilters }
    }

    if (!filters || keys(filters).length === 0) {
      return this.gridDefinition?.verb === 'get' ? '' : {}
    }

    if (this.gridDefinition?.verb === 'get') {
      return JSON.stringify(filters)
    }
    return filters
  }

  pageChanged() {
    this.pagination?.pageChanged()
    const data: any[] = []
    const currentPage = this.gridApi?.paginationGetCurrentPage()
    const currentPageSize = this.gridApi?.paginationGetPageSize()
    for (let index = currentPage * currentPageSize; index < (currentPage + 1) * currentPageSize; index++) {
      const row = this.gridApi?.getModel().getRow(index)
      if (row?.data) {
        data.push(row.data)
      }
    }
    if (data.length > 0) {
      this.pageDataChanged.emit({
        currentPage,
        currentPageSize,
        data,
        dataChart: this.response.rowDataChart,
      })
    }
    //To avoid double resize wait for LoadingCellRendererComponent that occur when the data from the server are ready,
    if (this.gridApi?.getCellRendererInstances()?.some((x) => x instanceof LoadingCellRendererComponent)) {
      return
    }
    this.resize$.next()
  }

  pageSizeChanged(pageSize: number) {
    this.userSettingsService.updateExtraUserSettings('rowsPerPagePreference', pageSize).subscribe()
    this.gridApi?.paginationSetPageSize(pageSize)
    timer(1).subscribe(() => this.gridApi?.setServerSideDatasource(this.createServerSideDatasource()))
  }

  setHasMasterDetail(allCols: OperationColumn[], paramsForBuildColDef: BuildColParameter) {
    this.colsWithDetail = allCols?.filter(
      (c) => paramsForBuildColDef.selectedCols?.includes(c.originalName) && (c.type === 'dictionary' || c.type === 'tableDetail')
    )
    if (this.gridDefinition?.detailCellRenderer) {
      const colDetail = allCols.find((c) => c.name === this.gridDefinition?.detailCellRenderParams.field)
      if (colDetail) {
        this.colsWithDetail.push({ ...colDetail, type: this.gridDefinition?.detailCellRenderer })
      }
    }
    this.hasMasterDetail = !!this.colsWithDetail && this.colsWithDetail.length > 0
  }

  isRowMaster = (dataItem: any) => {
    if (this.colsWithDetail) {
      return this.colsWithDetail
        .map((c) =>
          this.buildColumnsHelper.isMasterDetailColumn(
            !!c.agColDef && c.agColDef.cellRenderer ? c.agColDef.cellRenderer.toString() : c.type.toString(),
            dataItem[c.name]
          )
        )
        .reduce((p, c) => p || c, false)
    }
    return false
  }

  resize(event?: any) {
    if (this.skipNextResize) {
      this.skipNextResize = false
      return
    }

    if (event && !event.clientWidth && !event.clientHeight) {
      return
    }
    const nodes: IRowNode[] = []
    this.gridApi.forEachNode((x) => {
      if (x.expanded) {
        nodes.push({ ...(x as any).detailNode })
      }
    })
    this.columnApi?.autoSizeAllColumns()
    this.columnApi?.autoSizeAllColumns()

    if (nodes.length) {
      this.skipNextResize = true
      nodes.forEach((x) => this.gridApi.getRowNode(x.id || '')?.setRowHeight(x.rowHeight))
    }

    this.resizeColumnsToFit()
  }

  sortChanged(event: any) {
    if (['uiColumnSorted'].includes(event.source)) {
      this.sortHasChanged = true
    }
  }

  checkSelectedColumnsChanged() {
    if(this.sortHasChanged) {
      this.sortHasChanged = false

      if(this.gridDefinition?.entity) {
        const newColumns = LibBuildColAgGrid.getUpdatedVisibleColumnsBySorting(this.columnApi)
        const savedColumns = Helpers.getSavedColumns(newColumns, this.columnDefs, ['selection', 'common_Actions'])

        this.userSettingsService.updateUserSettings(savedColumns, this.gridDefinition?.entity).subscribe()
      }
    }
  }

  columnEverythingChanged(event: any) {
    if(event.source == 'uiColumnDragged'){
      this.columnDragged()
    }
  }

  columnDragged() {
     this.gridApi.forEachNode((node) => node.setExpanded(false))
  }

  columnMoved(event: any) {
    if (['uiColumnDragged', 'uiColumnMoved'].includes(event.source)) {
      this.selectedColumnsChanged$.next({ newColumns: LibBuildColAgGrid.getUpdatedVisibleColumns(this.columnApi), visibilityUpdated: false })
    }
  }

  columnVisible(event: any) {
    if (['uiColumnDragged', 'uiColumnMoved'].includes(event.source)) {
      this.selectedColumnsChanged$.next({ newColumns: LibBuildColAgGrid.getUpdatedVisibleColumns(this.columnApi), visibilityUpdated: true })
      this.resize$.next()
    }
  }

  onVisibleColumnChanged(columns: (ColDef<any, any> & CoreViewColumn)[]) {
    if (this.isSavedReport && columns) {
      this.savedReportUpdatedColumns = columns.map((c: any) => c.originalName)
    }
  }

  onRowSelected(event: any) {
    this.rowSelected.next({ rowData: event.data, isSelected: event.node.isSelected() })
  }

  onModelUpdated(event: any) {
    this.currentColUpdated.next(event)
    this.modelUpdated.emit(event)
  }

  onFilterChanged(params: GridOptions & Partial<{ colId: string, columns: any, source?: string }>): void {
    if (params.source === 'columnFilter') {
      this.currentCol = params.columns && params.columns.length > 0 ? params.columns[0].colId || null : null
    }
    const col: (ColDef & Partial<{ filterParams: any }>) | undefined = params?.api
      ?.getColumnDefs()
      ?.find((f: any) => !!f.filterParams && params.colId && f.filterParams.parentController === params.colId)
    if (!!col && !!this.gridApi) {
      const filterParams = this.gridApi.getFilterModel()[col.filterParams.parentController]
      if (filterParams) {
        this.gridApi.getFilterInstance(col?.colId || '')?.setModel({ groupFilter: filterParams.filter })
      } else {
        this.gridApi.getFilterInstance(col?.colId || '')?.setModel({ groupFilter: null })
      }
    }
    this.filterChanged$.emit()
  }

  getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] =>
    !params.node ? [] : this.buildColumnsHelper.getContextMenuItems(this.gridApi)

  processCellForClipboard = (params: ProcessCellForExportParams) => this.buildColumnsHelper.processCellForClipboard(params)

  //#region PivotMode
  onPivotModeChange = (val: boolean) => {
    this.pivotModeChanged.emit(val)
  }
  //#endregion

  onAnonymousDataModeChanged = (anonData: boolean) => {
    this.showSensitiveData = !anonData
    this.clearAndRefresh()
  }

  getRowId = (rowData: any) => {
    if (
      this.localstorageService.selectedOrganization?.hideSensitiveData === true &&
      this.gridDefinition?.entityIdField === 'UserPrincipalName'
    ) {
      return rowData.data.userHash || rowData.data.userPrincipalName
    }
    return rowData.data[Helpers.downcase(this.gridDefinition?.entityIdField || 'id')]
  }

  // This should always be used insted of the GridApi one that doesn't work with ServerSideSelection
  getSelectedRows = (): any[] =>
    this.gridApi?.getServerSideSelectionState()?.toggledNodes?.map((selectedId) => {
      const nodeOnSelectedRows = this.gridApi.getSelectedRows().find((x) => selectedId === this.getRowId({ data: x }))
      return nodeOnSelectedRows
        ? nodeOnSelectedRows
        : this.loadedItemsForServerSideSelection.find((x) => selectedId === this.getRowId({ data: x }))
    }) ?? []

  buildLinkButtonColumn(c: CoreViewColumn, l: OperationsOnCols): CoreViewColumn {
    return {
      ...c,
      cellOperation: l,
    }
  }

  buildChipColumn(c: CoreViewColumn, l: OperationsOnCols): CoreViewColumn {
    return {
      ...c,
      type: c.type !== 'string' ? c.type : '',
      cellOperation: l,
    }
  }

  buildCol(c: CoreViewColumn): CoreViewColumn {
    const l = this.gridDefinition?.operationsOnCols?.find((o) => o.field === c.name)

    switch (l?.type) {
      case 'link':
      case 'button':
      case 'persistableCellEditor':
        return this.buildLinkButtonColumn(c, l)
      case 'chip':
        return this.buildChipColumn(c, l)
      default:
        return c
    }
  }

  private getHiddenFields(): string[] {
    const visibleCols = this.getVisibleColumnsNames()
    const hiddenFieldsManagement = this.fieldsForManagement.filter((x) => !visibleCols?.includes(x))

    return uniq([...(this.gridDefinition?.defaultHiddenFields || []), ...hiddenFieldsManagement])
  }

  private getUserSettingsColumnsForBuildCols() {
    return this.userSettingsColumns ?? this.getSelectedColumns().map((c, i) => ({ originalName: c.originalName, position: i }))
  }

  private getSortDefinition(): { sortField: string; sortOrder: SortDirection } | null | undefined {
    return this.savedReportFilterSortModel?.sortModel || this.getSortModelFromUserSettings() || this.gridDefinition
  }

  private convertStringToSortOrder(input: string | undefined): 'asc' | 'desc' | null {
    if (input === 'asc' || input === 'desc') {
      return input
    } else {
      return null
    }
  }

  private resizeColumnsToFit() {
    if (!this.gridApi) {
      return
    }
    const panel = (this.gridApi as any).gridBodyCtrl
    const availableWidth = panel.eBodyViewport.clientWidth
    const displayedColumns = this.columnApi.getAllDisplayedColumns()
    const usedWidth = sumBy(displayedColumns, 'actualWidth')

    if (usedWidth < availableWidth) {
      this.gridApi.sizeColumnsToFit()
    }
  }

  private getCellClassRulesFunction = (key: string) => this.cellClassRulesFunction[key]()

  private getRows(
    currentPageSize: number,
    startRow: number,
    groupKeys: any,
    treeFilters?: QueryFilter2,
    selectAllPages?: boolean,
    serverSideParams?: IServerSideGetRowsParams
  ) {
    if (!selectAllPages && (this.ignoreRequest || currentPageSize !== this.gridApi?.paginationGetPageSize())) {
      this.ignoreRequest = false
      return of(this.response)
    }

    if (!this.getItems) {
      return of(null)
    }

    const customParams = { ...this.customParameters, showSensitiveData: this.showSensitiveData }
    const params = this.buildParams(currentPageSize, startRow, groupKeys, customParams, treeFilters)
    const res = this.getItems(params, this.dataCenterUrl, serverSideParams)

    return combineLatest([res.items, this.getCols(res)]).pipe(
      switchMap(([response, cols]: [ServerResponse<any>, CoreViewColumn[] | undefined]) => {
        this.gridApi?.hideOverlay()

        if (!(response?.metadata && this.gridDefinition)) {
          return of(null)
        }

        this.metadataChanged.emit(response.metadata)
        this.serverCols = this.getServerCols(cols, response)
        const allColsLocal: CoreViewColumn[] = this.generateAllColsLocal()

        const paramsForBuildColDef = {
          selectedCols: union(
            response.metadata.fields,
            this.gridDefinition.operationsColumns?.map((x) => x.originalName)
          ),
          //TODO: for onlineusers the colls should be in the state
          allcols: allColsLocal || (response.configs as CoreViewColumn[]),
          filtersToDisable: this.getFiltersToDisable(),
          sortedFields: this.gridDefinition?.sortedFields,
          cellClassRules: this.defineCellClassRules(),
        }

        if (!!this.rowSelection && paramsForBuildColDef.allcols.find((c) => c.name === 'selection') === undefined) {
          paramsForBuildColDef.allcols.push({
            ...this.buildColumnsHelper.selectionColumn,
            agColDef: {
              ...this.buildColumnsHelper.selectionColumn.agColDef,
              headerComponentParams: {
                keepSelectionBetweenPages: this.keepSelectionBetweenPages,
                expandAllOnSelectAll: this.expandAllOnSelectAll,
                getAllFilteredItems: this.getAllFilteredItems,
                rowIdField: this.getRowIdField(),
              },
            },
          })
        }

        this.setFiltersFromSavedReport(allColsLocal)
        this.executeFirstLoad(paramsForBuildColDef, allColsLocal)

        this.allColsLocal = allColsLocal

        const rowData = this.getRowData(response)
        this.response = {
          rowData,
          rowDataChart: this.getRowDataChart(response),
          rowCount: this.getRowCount(response),
        }

        if (!rowData.length) {
          this.pageDataChanged.emit({
            currentPage: this.gridApi?.paginationGetCurrentPage(),
            currentPageSize: this.gridApi?.paginationGetPageSize(),
            data: [],
            dataChart: this.response.rowDataChart,
          })
        }

        return of(this.response)
      })
    )
  }

  private getRowCount(response: ServerResponse<any>) {
    return this.gridDefinition?.gridOptions?.groupIncludeTotalFooter ? ++response.metadata.totalCount : response.metadata.totalCount
  }

  private getRowDataChart(response: ServerResponse<any>) {
    return this.gridDefinition?.chart &&
      this.gridDefinition.chart.length > 0 &&
      this.gridDefinition.chart[0].hasSameApi &&
      this.gridDefinition.chart[0].resultItem
      ? response[this.gridDefinition.chart[0].resultItem]
      : null
  }

  private executeFirstLoad(
    paramsForBuildColDef: {
      cellClassRules: any
      filtersToDisable: string[]
      selectedCols: (string | undefined)[]
      sortedFields: string[] | undefined
      allcols: CoreViewColumn[]
    },
    allColsLocal: CoreViewColumn[]
  ) {
    if (this.firstLoad) {
      this.setHasMasterDetail(allColsLocal, paramsForBuildColDef)
    }

    this.defineColumns(paramsForBuildColDef)

    this.firstLoad = false
  }

  private getRowData(response: ServerResponse<any>) {
    return this.gridDefinition?.gridOptions?.groupIncludeTotalFooter
      ? response[this.gridDefinition.responseItemProp].concat([response.totals])
      : response[this.gridDefinition!.responseItemProp]
  }

  private getRowIdField() {
    return this.gridDefinition?.entityIdField ? Helpers.downcase(this.gridDefinition.entityIdField) : 'id'
  }

  private getFiltersToDisable() {
    return [...Object.keys(this.gridDefinition?.filters || {}), ...Object.keys(this.routeParamsFilters || {})]
  }

  private getServerCols(cols: CoreViewColumn[] | undefined, response: ServerResponse<any>) {
    return cols || response.configs || []
  }

  private getCols(res: { items: Observable<ServerResponse<any>>; cols?: Observable<CoreViewColumn[]> | null }) {
    const cols$ = this.allCols ? of(this.allCols) : res.cols ?? of([])
    return this.gridDefinition?.isOnlineUsersType ? cols$ : of(undefined)
  }

  private buildParams(currentPageSize: number, startRow: number, groupKeys: any, customParams: any, treeFilters?: QueryFilter2) {
    const params = {
      fields: this.getFields(),
      filters: this.getFilters(),
      pageSize: currentPageSize || 0,
      pageNumber: startRow / (currentPageSize || 1) + 1,
      itemsPerPage: currentPageSize || 0,
      ...this.getSortModel(),
      groupParameters: groupKeys,
      ...this.rangeFilters,
      ...customParams,
    }

    if (treeFilters) {
      params.treeFilters = treeFilters
    }

    if (this.gridDefinition?.verb === 'post') {
      if (!!this.reportsService.reportFilter?.reportTreeFilters && this.reportsService.reportFilter.targetEntity !== 'Audit') {
        params.reportTreeFilters = this.reportsService.reportFilter?.reportTreeFilters
      }

      if (this.reportsService.reportFilter?.membershipReportFilters) {
        params.membershipReportFilters = this.reportsService.reportFilter?.membershipReportFilters
      }
    }

    return params
  }

  private generateAllColsLocal() {
    const allColsLocal: CoreViewColumn[] = this.serverCols.map((c: CoreViewColumn) => this.buildCol(c))

    if (allColsLocal) {
      const hideOpenCard = this.enableAnonymousData && !this.showSensitiveData
      forEach(this.gridDefinition?.operationsColumns, (op) => {
        this.buildColumnsHelper.buildOperationColumn(allColsLocal, op, hideOpenCard, this.gridDefinition?.targetEntity)
      })
    }

    return allColsLocal
  }

  private getAllFilteredItems = () => {
    if (!this.sharedHelperService.checkIfCanSelectAllGridRows(this.gridApi?.paginationGetRowCount())) {
      // returns empty observable to avoid it's subscription
      return of()
    }
    return this.getRows(Constants.maximumRowsForSelectAll, 0, [], this.getTreeFilters(), true)
      .pipe(delay(1), takeUntil(this.destroyed$))
      .pipe(
        tap((x) => {
          this.loadedItemsForServerSideSelection = x.rowData
        })
      )
  }

  private setFiltersFromSavedReport(allColsLocal: CoreViewColumn[]) {
    if (!!this.savedReportFilterSortModel?.filterModel && !!allColsLocal) {
      const filterKeysToKeepCapitized = Helpers.getStringsWithFirst2LettersCapitalized(
        Object.keys(this.savedReportFilterSortModel?.filterModel)
      )

      this.savedReportFilters = this.buildColumnsHelper.dictionaryFilterToFilterDefinition(
        this.savedReportFilterSortModel?.filterModel,
        allColsLocal,
        filterKeysToKeepCapitized.concat(this.savedReportFilterSortModel.filterKeysToKeepCapitized || [])
      )
    }
  }

  private getSortColumnId(sortField: string): string {
    if (!this.columnDefs) {
      return sortField
    }
    const column = this.columnDefs.find((col) => col.originalName === sortField)
    return column && column.sortName ? column.sortName : sortField
  }

  private getSelectedColumns() {
    let cols =
      this.columnDefs && this.columnDefs.length > 0 ? this.columnDefs.filter((c: ColDef) => c.hide === false) : this.getStartColumns()

    if (this.gridDefinition?.fieldsWithDays?.length && this.rangeFilters?.days) {
      cols = this.getMappedRangeColumns(cols)
    }
    return cols
  }

  private getMappedRangeColumns(cols: { originalName: string }[] | (ColDef & CoreViewColumn)[]) {
    const currentRangeFilter = this.rangeFilters!.days!.toString()
    return cols.map((x) => {
      const fieldWithDay = this.gridDefinition?.fieldsWithDays?.find(
        (field) => x.originalName.replace(this.previousRangeFilter?.toString() || currentRangeFilter, '') === field
      )
      if (fieldWithDay && !x.originalName.includes(currentRangeFilter)) {
        return { ...x, originalName: fieldWithDay + currentRangeFilter }
      }
      return x
    })
  }

  private getVisibleColumnsNames() {
    if (this.userSettingsColumns?.length) {
      return this.userSettingsColumns.map((c) => c.originalName)
    } else if (this.savedReportUpdatedColumns?.length) {
      return this.savedReportUpdatedColumns
    }
    return this.columnApi?.getAllDisplayedColumns()?.length
    ? this.columnApi.getAllDisplayedColumns().map((x: any) => x.colDef.originalName)
    : this.gridDefinition?.fields
  }


  private useLegacyLicenseFilter() {
    return !!this.gridDefinition?.actionsTags?.find((tag: string) => tag.toLowerCase() === 'legacylicensefilter')
  }

  private checkNoRowsOverlay(response?: any) {
    if (!response?.rowCount) {
      timer(1).subscribe(() => this.gridApi?.showNoRowsOverlay())
    }
  }

  private checkSortedColumns(cols: ColDef[]) {
    if (this.firstLoad) {
      const sortDefinition = this.getSortDefinition()
      const sortcol = union(cols, this.autoGroupColumnDef ? [this.autoGroupColumnDef] : []).find(
        (c) => c.field?.toLowerCase() === sortDefinition?.sortField.toLowerCase()
      )

      if (sortcol) {
        sortcol.sort = sortDefinition?.sortOrder || null
        this.ignoreRequest = true
      }
    } else {
      const sortedCols = this.columnApi?.getColumnState().filter((x) => !!x.sort)
      const colsToBeCleaned = cols.filter((x) => x.sort && !sortedCols.map((c) => c.colId).includes(x.field || ''))
      if (sortedCols.length && colsToBeCleaned.length) {
        colsToBeCleaned.forEach((x) => (x.sort = null))
        this.ignoreRequest = true
      }
      if (sortedCols.length) {
        sortedCols.forEach((x) => cols.filter((c) => c.field === x.colId).forEach((c) => (c.sort = x.sort)))
      }
    }
  }
}

import { createAction, props } from "@ngrx/store";

export const setLeftPanelIsOpen = createAction('[License Pool Center] setLeftPanelIsOpen', props<{ isOpen: boolean }>())
export const setLeftPanelReport = createAction('[License Pool Center] setLeftPanelSelectReport', props<{ report: string }>())
export const convertExtraToLicencePoolState =createAction('[License Pool Center] convertExtraToLicencePoolState', props<{ extra: any }>())

export const setGroupedBySkuAreAllRowsExpanded = createAction('[License Pool Center] setGroupedBySkuAreAllRowsExpanded', props<{ areExpanded: boolean }>())
export const setGroupedByPoolsAreAllRowsExpanded = createAction('[License Pool Center] setGroupedByPoolsAreAllRowsExpanded', props<{ areExpanded: boolean }>())
export const setOverAllocatedAreAllRowsExpanded = createAction('[License Pool Center] setOverAllocatedAreAllRowsExpanded', props<{ areExpanded: boolean }>())
export const setOutOfCapacityAreAllRowsExpanded = createAction('[License Pool Center] setOutOfCapacityAreAllRowsExpanded', props<{ areExpanded: boolean }>())
export const setShowOnlyPoolsWithAllocation = createAction('[License Pool Center] setShowOnlyPoolsWithAllocation', props<{ isShow: boolean }>())

export const setIsSavedReport = createAction('[License Pool Center] setIsSavedReport', props<{ isSavedReport: boolean }>())

export const setSelectedLicenses = createAction('[License Pool Center] setSelectedLicenses', props<{ licenses: string[] }>())
export const setIsOpenInFull = createAction('[License Pool Center] setIsOpenInFull', props<{ isOpenInFull: boolean }>())
export const toggleIsOpenInFull = createAction('[License Pool Center] toggleIsOpenInFull')

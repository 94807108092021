import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnInit } from '@angular/core';
import isObject from 'lodash-es/isObject';

@Component({
  selector: 'app-object-cell-render',
  templateUrl: './object-cell-render.component.html',
  styleUrls: ['./object-cell-render.component.sass']
})
export class ObjectCellRenderComponent implements AgRendererComponent {
  public value!: string[]
  public params!: any

  constructor() { }

  refresh(params: ICellRendererParams): boolean {
    return true
  }

  agInit(params: ICellRendererParams): void {
    this.params = params

    this.value = isObject(params.value) ? JSON.stringify(params.value) : params.value
  }

}

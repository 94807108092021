import { createReducer, on } from '@ngrx/store'
import { DemoModeState } from './demo-mode.types'
import { setDemoMode } from './demo-mode.actions'

export const initialState: DemoModeState = {
  demoModeActivated: false,
}

export const demoModeReducer = createReducer(
  initialState,
  on(setDemoMode, (state, action) => {
    const ent = { demoModeActivated: action.demoModeActivated }
    return { ...state, ...ent }
  })
)

export default demoModeReducer

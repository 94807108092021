<app-loading-report-skeleton *ngIf="reportDefinition && !loadedFirstTime" [hasCharts]="hasCharts"> </app-loading-report-skeleton>

<div class="report-container" [style.display]="reportDefinition && loadedFirstTime ? 'flex' : 'none'">
  <app-report-title
    *ngIf="reportDefinition"
    [reportDefinition]="reportDefinition"
    [menu]="!!policy ? undefined : menu"
    [savedReportId]="savedReportId"
    [reportTitle]="isOpenInFull ? reportTitleFullscreen : reportTitle "
    [reportDescription]="reportDescription"
    (dataCenterSelected)="selectDataCenter($event)"
    (rangeFilterChanged)="onRangeFilterChange($event)"
    [defaultRangeFilter]="defaultRangeFilter"
    [hasCharts]="hasCharts"
    [hasMaps]="!!mapData"
    [chartPreference]="chartPreference"
    [mapPreference]="mapPreference"
    [isNonVTenantSensitive]="isNonVTenantSensitive"
    [routeParamsFilters]="routeParamsFilters"
    [isOpenInFull]="isOpenInFull"
    [filterTitle]="filterTitleFullscreen"
    (chartVisibilityChanged)="updateChartVisibilityPreference($event)"
    (mapVisibilityChanged)="updateMapVisibilityPreference($event)"
  >
  </app-report-title>

  <section *ngIf="isNewCustomReport() && !savedReport" class="m-b-10">
    <cv-alert-bar variant="info" [hideCloseButton]="true">
      {{ 'reports_NewCustomReportDisclaimer' | translate }}
    </cv-alert-bar>
  </section>

  <section *ngIf="!!savedReport?.hasBrokenMembershipFilter" class="m-b-10">
    <cv-alert-bar [hideCloseButton]="true" variant="error">
      <span translate>reports_SavedReportHasBrokenMembershipFilter</span>
    </cv-alert-bar>
  </section>

  <div
    class="main-container"
    [ngClass]="!canSeeLeftPanel('CustomSmartPanel') && !canSeeLeftPanel('CustomReports') ? 'mainContainer-two' : 'mainContainer-three'"
  >
    <ng-content *ngIf="canSeeLeftPanel('CustomSmartPanel')" select="[customSmartPanel]"></ng-content>

    <cv-smart-panel
      *ngIf="canSeeLeftPanel('CustomReports')"
      [title]="'reports_CustomReports' | translate"
      style="padding-top: 6px"
      [open]="isOpenPanelCustomReport"
      (openChange)="openChangePanelCustomReport($event)"

    >
    <div style="cursor: default" content>
        <div class="title-container">
          <div class="title" translate>reports_CustomReports</div>
          <mat-icon [tooltip]="'reports_SavedViewsInfo' | translate" class="info material-icons-outlined">info </mat-icon>
        </div>
        <div class="m-l-15 m-b-20" translate>reports_SelectReport</div>
        <div class="saved-report-container">
          <app-custom-reports-list [savedReports]="savedReports"></app-custom-reports-list>
        </div>
      </div>
    </cv-smart-panel>
    <div class="grid-container">
      <div
        *ngIf="hasCharts && chartPreference"
        style="margin-inline: auto; width: 100%; margin-bottom: 30px"
        [style.minHeight]="
          hasCharts
            ? reportDefinition && reportDefinition.chart && reportDefinition.chart.length > 1 && reportDefinition.chart[0].areTabbable
              ? '245px'
              : '230px'
            : '0'
        "
      >
        <span
          *ngIf="reportDefinition && reportDefinition.chart && reportDefinition.chart.length > 1 && reportDefinition.chart[0].areTabbable"
        >
          <cv-horizontal-tab-group
            type="line"
            [activeTab]="getDefaultTabChartIndex() + 1"
            (tabChanged)="changeChart($event)"
            style="height: auto"
          >
            <cv-tab title="{{ c.name || 'tab' | translate }}" *ngFor="let c of reportDefinition?.chart" style="padding-top: 0px"> </cv-tab>
          </cv-horizontal-tab-group>
        </span>
        <div style="display: flex">
          <span
            [hidden]="!allChartsLoaded()"
            *ngFor="let chart of chartsContainer | keyvalue"
            [style.width]="calculateChartsContainerWidth()"
          >
            <lib-chart
              [chart]="getChart(chart.value)"
              (click)="onClickChartEvent($event)"
              [data]="getChartData(chart.value)"
              [dataChart]="getChartDataChart(chart.value)"
              [chartName]="getChart(chart.value).name"
            ></lib-chart>
          </span>
        </div>
      </div>
      <div *ngIf="mapPreference && !isPivotModeActive && mapData && mapLoaded" class="map-container">
        <div
          *ngIf="!mapData.hideMap"
          style="height: 300px; width: 90%"
          leaflet
          [leafletMarkerCluster]="mapData.markers"
          [leafletMarkerClusterOptions]="mapData.clusterOptions || {}"
          [leafletOptions]="mapData.options"
          (leafletMarkerClusterReady)="markerClusterReady($event, mapData)"
          (leafletMapReady)="onMapReady($event, mapData)"
        ></div>
      </div>
      <cv-alert-bar *ngIf="searchInProgress" variant="info" [hideCloseButton]="true"
        [title]="'reports_SearchInProgress' | translate">
        {{ (progressMessage || 'reports_SearchInProgressDescription') | translate }}
      </cv-alert-bar>
      <app-datagrid
        *ngIf="!!reportDefinition && !isPivotModeActive && !reportDefinition?.isClientDataGrid"
        (columnDefsDefined)="onAppDataGridColumnDefined($event)"
        (metadataChanged)="onMetadataChanged($event)"
        (pageDataChanged)="pageDataChanged($event)"
        (pivotModeChanged)="onPivotModeChanged($event)"
        (rowSelected)="rowSelected$.emit($event)"
        (filterChanged$)="onFilterChanged()"
        [allCols]="allCols"
        [autoHeight]="false"
        [customFilters]="customFilters"
        [customParameters]="customParameters"
        [enablePivotMode]="isPivotModeEnabled"
        [enableAnonymousData]="reportDefinition?.enableAnonymousData || false"
        [externalFunctionMapper]="externalFilterHandlers"
        [getItems]="refreshData"
        [gridDefinition]="reportDefinition"
        [fieldsForManagement]="fieldsForManagement"
        [hideColumnsSelector]="hideColumnsSelector"
        [rangeFilters]="rangeFilters"
        [routeParamsFilters]="routeParamsFilters"
        [savedReportFilterSortModel]="savedReportFilterSortModel"
      >
        <div *ngIf="showPolicyItemsSelector()" style="display: flex; margin-left: 10px" extraFilters>
          <div style="margin: auto; margin-top: 9px; margin-right: 5px" translate>common_ShowOnly</div>
          <cv-tertiary-dropdown
            class="policy-show"
            [(ngModel)]="policyItemsSelectorValue"
            [options]="policyItemsSelectorOptions"
            (selectionChanged)="policyItemsSelectorChanged($event)"
          ></cv-tertiary-dropdown>
        </div>
      </app-datagrid>

      <app-client-datagrid
        *ngIf="reportDefinition?.isClientDataGrid"
        [entityName]="reportDefinition?.entity"
        [getItems]="getClientGridData"
        (rowSelected)="rowSelectedClient()"
        [columnDefsBuiltExternally]="clientTableConfiguration.columnDefs"
        [autosizeAllColumns]="true"
        [gridOptions]="clientTableConfiguration.gridOptions"
        [detailCellRenderer]="clientTableConfiguration.gridOptions.detailCellRenderer || 'customDetail'"
        [detailCellRendererParams]="clientTableConfiguration.gridOptions.detailCellRendererParams"
        (metadataChanged)="metadata = $event"
        (pageDataChanged)="pageDataChanged($event)"
        [isRowMaster]="clientTableConfiguration.isRowMaster"
        [responseItemProp]="reportDefinition?.responseItemProp || ''"
        [selectionActions]="clientSelectionActions"
        [hideColumnsSelector]="true"
        [filters]="reportDefinition?.filters"
        [rowSelection]="reportDefinition?.rowSelection !== 'none' ? 'multiple' : undefined"
        #clientGrid
      ></app-client-datagrid>

      <div *ngIf="isPivotModeActive" style="display: flex; flex: 1">
        <div *ngIf="loadingPivotData" style="display: flex; flex: 1">
          <app-loading-pivot-skeleton></app-loading-pivot-skeleton>
        </div>

        <div [style.display]="loadingPivotData ? 'none' : 'flex'" style="flex: 1">
          <app-client-datagrid
            [getItems]="getPivotData"
            [columnsParameters]="reportsComponentHelper.pivotParamsForBuildColDef"
            [responseItemProp]="pivotResponseItemProp"
            [enablePivotMode]="isPivotModeEnabled"
            [pivotModeActive]="isPivotModeEnabled && isPivotModeActive"
            (pivotModeChanged)="onPivotModeChanged($event)"
            [groupedActions]="pivotSelectionActions"
            [autosizeAllColumns]="true"
            (gridReady)="onPivotGridReady()"
            [gridOptions]="{ suppressRowClickSelection: true }"
          >
          </app-client-datagrid>
        </div>
      </div>
    </div>

    <app-management-panel
      *ngIf="!isPivotModeActive && canSeeManagementPanel(reportDefinition?.actionsTags)"
      [targetEntity]="targetEntity"
      [collectionName]="reportDefinition?.dbCollectionName"
      [reportDefinition]="reportDefinition"
      [entityIdField]="reportDefinition?.entityIdField"
      [reportTitle]="reportDefinition?.title"
      [workflowItemsSelectionColumnDefinition]="managementColumnDefinition"
      [selectedRows]="selectedRows"
      [policy]="policy"
      [tagsByDefault]="reportDefinition?.actionsTags || []"
    ></app-management-panel>
  </div>
</div>

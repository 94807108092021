<div class="cv-dialog-div-container" *ngIf="isLoadedInDialog">
  <div class="title-div">
    <div class="title-container">
      <span class="title">{{ policy?.title }}</span>
    </div>
    <cv-close-button class="close-icon" [mat-dialog-close]="'cancel'"></cv-close-button>
  </div>
  <cv-alert-bar *ngIf="reportType === 'matchedExceptions' && reportDefinition?.isOnlineUsersType" [hideCloseButton]="true">
    <span translate>playbook_ExceptionsDisclaimer</span>
  </cv-alert-bar>
  <div class="dialog-container">
    <cv-horizontal-tab-group type="line" [activeTab]="1">
      <cv-tab [title]="(reportType === 'matchedItems' ? 'playbook_matchedItems' : 'playbook_exceptions') | translate">
        <ng-container [ngTemplateOutlet]="grid"></ng-container>
      </cv-tab>
    </cv-horizontal-tab-group>
  </div>
</div>
<ng-container [ngTemplateOutlet]="grid" *ngIf="!isLoadedInDialog"></ng-container>

<ng-template #grid>
  <app-policy-item-list
    *ngIf="!!policy && !!reportDefinition"
    (metadataChanged)="metadataChanged($event)"
    (gridReady)="applySelectionAction()"
    [reportDefinition]="reportDefinition"
    [policy]="policy"
    [reportType]="reportType"
  ></app-policy-item-list>
</ng-template>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LicensePoolCenterState } from './license-pool-center.reducer';

export const selectLicensePoolCenterState = createFeatureSelector<LicensePoolCenterState>('licensePoolCenter')

export const selectLeftPanelIsOpen = createSelector(selectLicensePoolCenterState, (state) => state.leftPanelIsOpen)
export const selectLeftPanelReport = createSelector(selectLicensePoolCenterState, (state) => state.leftPanelReport)

export const selectGroupedBySkuAreAllRowsExpanded = createSelector(selectLicensePoolCenterState, (state) => state.groupedBySkuAreAllRowsExpanded)
export const selectGroupedByPoolsAreAllRowsExpanded = createSelector(selectLicensePoolCenterState, (state) => state.groupedByPoolsAreAllRowsExpanded)
export const selectOverAllocatedAreAllRowsExpanded = createSelector(selectLicensePoolCenterState, (state) => state.overAllocatedAreAllRowsExpanded)
export const selectOutOfCapacityAreAllRowsExpanded = createSelector(selectLicensePoolCenterState, (state) => state.outOfCapacityAreAllRowsExpanded)
export const selectShowOnlyPoolsWithAllocation = createSelector(selectLicensePoolCenterState, (state) => state.showOnlyPoolsWithAllocation)
export const selectSelectedLicenses = createSelector(selectLicensePoolCenterState, (state) => state.selectedLicenses)
export const selectIsSavedReport = createSelector(selectLicensePoolCenterState, (state) => state.isSavedReport)

export const selectIsOpenInFull = createSelector(selectLicensePoolCenterState, (state) => state.isOpenInFull)

import { Injectable } from '@angular/core'
import { ApplicationInsights, ICustomProperties, SeverityLevel } from '@microsoft/applicationinsights-web'
import { LocalstorageService } from './localstorage.service'
import { environment } from '@environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightService {

  appInsights!: ApplicationInsights

  constructor(
    private storage: LocalstorageService) {
  }

  public load() {
    if (origin !== 'http://localhost:4200' && origin !== 'https://devapp.coreview.com') {
      this.appInsights = new ApplicationInsights({
        config: {
          //instrumentationKey: environment.instrumentationKey,
          accountId: this.storage.getLoggedUser().userName
          /* ...Other Configuration Options... */
        }
      })

      this.appInsights.loadAppInsights()
    }
  }

  public contextId() {
    return !!this.appInsights ? this.appInsights.context.user.id : 'local'
  }
  public trackException(error: any, message: string) {
    this.appInsights?.trackException({ exception: error }, { cvMessage: message })
  }

  public trackError(message: string) {
    this.appInsights?.trackTrace({ message, severityLevel: SeverityLevel.Critical })
  }

  public trackWarning(message: string, customProperties: ICustomProperties) {
    this.appInsights?.trackTrace({ message, severityLevel: SeverityLevel.Warning }, customProperties)
  }

  public trackEvent(message: string) {
    this.appInsights?.trackTrace({ message, severityLevel: SeverityLevel.Critical })
  }
  //appInsights.trackPageView() // Manually call trackPageView to establish the current user/session/pageview
}

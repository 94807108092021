/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { SavedReport } from '@app/core/models/saved-report'
import { TranslateHelper } from '@coreview/coreview-library'
import { RootState } from '@app/store/RootState.type'
import * as Actions from '@app/store/license-pool-center/license-pool-center.actions'
import * as Selectors from '@app/store/license-pool-center/license-pool-center.selectors'
import { Store } from '@ngrx/store'
import { Suggestion } from '@coreview/coreview-components'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { Constants } from '@app/shared/utilities/constants'
import { selectedOrganizationSkus } from '@app/store/organizations/organizations.selectors'

@Component({
  selector: 'app-license-left-panel',
  templateUrl: './license-left-panel.component.html',
  styleUrls: ['./license-left-panel.component.sass'],
})
export class LicenseLeftPanelComponent implements OnInit, OnDestroy {
  @Input() noLpConfigured = false
  @Input() savedReports: SavedReport[] = []
  @Input() reportOnly = false
  @Output() openAdvancedFilters = new EventEmitter()

  selectedPanelTab = 1

  isOpenPanel$ = this.store.select(Selectors.selectLeftPanelIsOpen)

  quickFilterTypes: Suggestion[] = [
    {
      value: LicensePoolFilterType.FullReport,
      displayValue: this.translateHelper.instant('licenses_FullReport'),
    },
    {
      value: LicensePoolFilterType.OverAllocatedOnPool,
      displayValue: this.translateHelper.instant('licenses_OverAlloratedOnPool'),
      otherDisplayValue: this.translateHelper.instant('licenses_OverAlloratedOnPoolDescription'),
    },
    {
      value: LicensePoolFilterType.OutOfCapacityOnInventory,
      displayValue: this.translateHelper.instant('licenses_OutOfCapacityOnInventory'),
      otherDisplayValue: this.translateHelper.instant('licenses_OutOfCapacityOnInventoryDescription'),
    },
  ]
  selectedQuickFilter = this.quickFilterTypes[0].value

  fullReportType: Suggestion[] = [
    {
      value: LicensePoolFilterFullReportType.FullReportMasterData,
      displayValue: this.translateHelper.instant('licenses_FullReportMasterData'),
    },
    {
      value: LicensePoolFilterFullReportType.FullReportGroupedBySKU,
      displayValue: this.translateHelper.instant('licenses_FullReportGroupedBySKU'),
    },
    {
      value: LicensePoolFilterFullReportType.FullReportGroupedByPools,
      displayValue: this.translateHelper.instant('licenses_FullReportGroupedByPools'),
    },
  ]
  selectedFullReport = this.fullReportType[0].value

  private destroyed$: Subject<boolean> = new Subject()
  canSeeCustomReportsBySku = true //#bug 40906

  constructor(private translateHelper: TranslateHelper, private store: Store<RootState>) {}

  ngOnInit() {

    this.store
      .select(Selectors.selectLeftPanelReport)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((report) => {
        if (report === LicensePoolFilterType.OverAllocatedOnPool || report === LicensePoolFilterType.OutOfCapacityOnInventory) {
          this.selectedQuickFilter = report
        } else {
          this.selectedFullReport = report
        }
        if (this.reportOnly) {
          this.selectedQuickFilter = LicensePoolFilterType.FullReport
        }
      })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  openChangePanelCustomReport(isOpen: boolean) {
    this.store.dispatch(Actions.setLeftPanelIsOpen({ isOpen }))
  }

  tabChanged({ index }: any) {
    this.selectedPanelTab = index + 1
  }

  onChangeQuickFilterType(
    event: LicensePoolFilterType.FullReport | LicensePoolFilterType.OverAllocatedOnPool | LicensePoolFilterType.OutOfCapacityOnInventory
  ) {
    this.selectedQuickFilter = event
    if (event === LicensePoolFilterSelectedType.FullReport) {
      this.store.dispatch(Actions.setLeftPanelReport({ report: this.selectedFullReport }))
    } else {
      this.store.dispatch(Actions.setLeftPanelReport({ report: event }))
    }
  }

  onChangeFullReportSelection(
    event:
      | LicensePoolFilterFullReportType.FullReportMasterData
      | LicensePoolFilterFullReportType.FullReportGroupedBySKU
      | LicensePoolFilterFullReportType.FullReportGroupedByPools
  ) {
    this.onChangeQuickFilterType(LicensePoolFilterType.FullReport)

    this.selectedFullReport = event
    this.store.dispatch(Actions.setLeftPanelReport({ report: event }))
  }

  handleOpenAdvancedFilters() {
    this.openAdvancedFilters.emit()
  }

  canSeeLeftPanel() {
    return this.canSeeCustomReportsBySku
  }
}

export enum LicensePoolFilterType {
  FullReport = 'FullReport',
  OverAllocatedOnPool = 'OverAllocatedOnPool',
  OutOfCapacityOnInventory = 'OutOfCapacityOnInventory',
}

export enum LicensePoolFilterFullReportType {
  FullReportMasterData = 'MasterReport',
  FullReportGroupedBySKU = 'GroupedBySKU',
  FullReportGroupedByPools = 'GroupedByName',
}

export const LicensePoolFilterSelectedType = {
  ...LicensePoolFilterType,
  ...LicensePoolFilterFullReportType,
}

export type LicensePoolFilterSelectedType = typeof LicensePoolFilterSelectedType

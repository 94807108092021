import { Organization } from '@core/models/Organization';
import { ColDef, ColumnState, GridOptions } from '@ag-grid-community/core'
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  Type,
  ViewChild,
  ViewChildren,
} from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { OperationsOnCols, ReportDefinition, SelectionAction, SelectionActionOptions } from '@app/core/models/ReportDefinition'
import { ServerResponse } from '@app/core/models/ServerResponse'
import { NavItem } from '@app/core/models/nav-item'
import { SavedReport } from '@app/core/models/saved-report'
import { AdministrationsService } from '@app/core/services/administrations.service'
import { ApiclientService } from '@app/core/services/apiclient.service'
import { BreadcrumbService, TranslateHelper } from '@coreview/coreview-library'
import { DashboardService } from '@app/core/services/dashboard.service'
import { LicenseService } from '@app/core/services/license.service'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { ReportExportService } from '@app/core/services/report-export.service'
import { ReportsService } from '@app/core/services/reports.service'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { CustomOption } from '@app/modules/cards/Card'
import { ServicesUsageFilterComponent } from '@app/modules/dashboard/components/services-usage-filter/services-usage-filter.component'
import { LicensePoolService } from '@app/modules/licenses/services/license-pool.service'
import { LicenseHelper } from '@app/modules/management/services/license.helper'
import { DuplicatedPlansAdvancedFiltersComponent } from '@app/modules/reports/components/optimization-center/duplicated-plans-advanced-filters/duplicated-plans-advanced-filters.component'
import { LicenseOptimizationAdvancedFiltersComponent } from '@app/modules/reports/components/optimization-center/license-optimization-advanced-filters/license-optimization-advanced-filters.component'
import { ServiceUsageAdvancedFiltersComponent } from '@app/modules/reports/components/optimization-center/service-usage-advanced-filters/service-usage-advanced-filters.component'
import { SaveViewComponent } from '@app/modules/reports/components/save-view/save-view.component'
import { NewSchedulationComponent } from '@app/modules/reports/pages/new-schedulation/new-schedulation.component'
import { AbstractCardComponent } from '@app/shared/components/cards/abstract-card.component'
import { ClientDatagridComponent } from '@app/shared/components/client-datagrid/client-datagrid.component'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'
import { WorkflowItemsSelectionColumnsDefinition } from '@app/shared/models/workflow-target-selection-definition'
import { BuildColAgGrid, BuildColParameter } from '@app/shared/utilities/build-col-ag-grid'
import { Constants } from '@app/shared/utilities/constants'
import { Helpers } from '@app/shared/utilities/helpers'
import { ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper'
import { RootState } from '@app/store/RootState.type'
import { selectFavorites, selectFlatMenusAll, selectMenuByRoute } from '@app/store/menu/menu.selectors'
import { selectOnlineuserColumns } from '@app/store/onlineuser-columns/onlineuser-columns.selectors'
import { ButtonComponent, HorizontalTabGroupComponent, SmartPanelComponent, Suggestion } from '@coreview/coreview-components'
import { Store } from '@ngrx/store'
import dayjs from 'dayjs'
import { cloneDeep, isEqual, remove, trimStart, uniqBy } from 'lodash-es'
import { Observable, Subject, combineLatest, of, timer } from 'rxjs'
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators'
import { CardDashboard, DashboardDefinition, DashboardTab } from '@app/core/models/DashboardDefinition'
import { LicenseOptimizationCenterService } from './license-optimization-center.service'
import { selectedOrganization } from '@app/store/organizations/organizations.selectors';

@Component({
  selector: 'app-license-optimization-center',
  templateUrl: './license-optimization-center.component.html',
  styleUrls: ['./license-optimization-center.component.sass'],
})
export class LicenseOptimizationCenterComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChildren(AbstractCardComponent)
  set setCards(cards: QueryList<AbstractCardComponent>) {
    this.cards = cards
  }

  @ViewChild(HorizontalTabGroupComponent)
  set setTabGroup(tabGroup: HorizontalTabGroupComponent) {
    this.tabGroup = tabGroup
  }

  @ViewChild(DatagridComponent)
  set setGrid(grid: DatagridComponent) {
    if (grid) {
      this.grid = grid
    }
  }

  @ViewChild(ClientDatagridComponent)
  set setClientGrid(grid: ClientDatagridComponent) {
    if (grid) {
      this.clientGrid = grid
    }
  }

  @ViewChildren(SmartPanelComponent) panels!: QueryList<SmartPanelComponent>

  reportTitle!: string
  reportDescription!: string
  tabSelectedIndex = 0
  daysBefore: 0 | 1 | 3 | 7 | 14 | 30 | 60 | 90 | 180 | undefined = 90
  calendarType!: string | undefined
  daysList?: any[]

  grid!: DatagridComponent
  clientGrid!: ClientDatagridComponent

  params: any
  tabGroup!: HorizontalTabGroupComponent

  dashboardDefinition!: DashboardDefinition
  reportDefinition: ReportDefinition | undefined

  data: any

  showDescription = false

  menu!: NavItem | undefined

  daysValue: { since?: string; to?: string; days?: number; isOnStart?: boolean } = { days: 30, isOnStart: true }
  componentFilter: any = {}

  organization!: Organization | undefined

  loading = false
  loadingTable = false
  loadingLicenses = false
  loadingLicenseByUser = false
  loadingServiceUsage = false
  loadingDuplicatedPlans = false
  loadedLicensesTabFirstTime = false
  showNoPrimaryLicensesLicensesDisclaimer = false
  loadedDuplicatedFirstTime = false
  showNoPrimaryLicensesDuplicatedDisclaimer = false
  pathParams!: any

  public columnsParameters!: BuildColParameter
  snapshotToCompare!: string
  snapshotToView!: string
  snapshotsDates: Suggestion[] = []
  gridOptionsSnapshots: GridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: false,
      filter: true,
      floatingFilter: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
    },
    columnDefs: [],
    groupIncludeTotalFooter: true,
    suppressRowClickSelection: true
  }

  savedReportIdFilter?: string
  savedReportId?: string
  savedReport!: SavedReport | null
  savedReports: SavedReport[] = []
  savedReportFilterSortModel?: {
    filterModel: any
    sortModel: { sortField: string; sortOrder: 'asc' | 'desc' }
    filterKeysToKeepCapitized?: string[]
  }
  isPivotModeActive = false
  pivotSettings: ColumnState[] = []

  noLpConfigured = false
  reportOnly = false

  metadata!: any

  managementColumnDefinition!: WorkflowItemsSelectionColumnsDefinition
  selectedRows: any[] = []
  selectedRowsLicenseByUser: any[] = []
  selectedRowsDuplicatedPlans: any[] = []

  columnDefsLicenseByUser!: CoreViewColumn[]

  serviceUsageGroupByItems = ['User', 'Department', 'Domain', 'Company', 'Country', 'Office', 'LicensePool']
  serviceUsageGroupBy = 'User'
  gridOptionsServiceUsage: GridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: false,
      filter: true,
      floatingFilter: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
    },
    suppressRowClickSelection: true
  }

  clientSelectionActions: SelectionAction[] = [
    {
      isMenuButton: true,
      text: 'common_Export',
      buttonType: 'tertiary',
      icon: 'download',
      visibility: 'noRow',
      options: Constants.exportOptionsNoPdf,
      onClick: (data: any[], key: string | null) => {
        this.clientGrid.exportClientReport(this.translateHelper.instant(this.reportDefinition?.title || ''), key!)
      },
    },
  ]

  public entityName = ''
  menuIds = Constants.menuIds
  route!: string

  servicePlans: { msServiceName: string; serviceName: string }[] = []

  licenseOptimizationAdvancedFiltersConfiguration: {
    daysBefore?: number
    filters?: any
    selectedSkus?: any[]
    selectedPlans?: any[]
    plansFilters: any
  } = {
      plansFilters: {},
    }
  defaultSkus!: string[]

  serviceUsageAdvancedFiltersConfiguration: { includeCosts: boolean; includeNewUsers: boolean } = {
    includeCosts: true,
    includeNewUsers: false,
  }

  duplicatedPlansAdvancedFiltersConfiguration: {
    filters?: any
    aggregations?: any[]
    skuAggregationFilter?: string
    licenseAggregationFilter?: string
  } = {}

  tabsDefinitions = [
    {
      id: Constants.menuIds.licenseSavingsOpportunities,
      refreshTable: () => {
        this.loading = true
        combineLatest([
          this.dashboardDefinition?.dataUrl
            ? this.dashboardService.getData(this.dashboardDefinition.dataUrl, this.dashboardDefinition.dataProperty || '', this.daysValue)
            : of({}),
          this.dashboardDefinition?.initDataUrl
            ? this.dashboardService.getData(this.dashboardDefinition.initDataUrl, this.dashboardDefinition.initDataProperty || '')
            : of({}),
        ])
          .pipe(take(1))
          .subscribe(([detailsData, initialsData]) => {
            if (this.dashboardDefinition?.dataMapFunction) {
              detailsData = new Function('data', 'pathParams', this.dashboardDefinition.dataMapFunction)(detailsData, this.pathParams)
            }

            this.data = { ...detailsData }
            this.buildOptionsInFilterActions(initialsData)

            timer(10).subscribe(() => {
              this.cards.forEach((x) => x.load())
              this.grid?.refresh()
              this.clientGrid?.refresh()
              this.loading = false
            })
          })
      },
      load: (_: void) => {
        this.reportDefinition = undefined

        combineLatest([
          this.dashboardDefinition?.dataUrl
            ? this.dashboardService.getData(this.dashboardDefinition.dataUrl, this.dashboardDefinition.dataProperty || '', this.daysValue)
            : of({}),
          this.dashboardDefinition?.initDataUrl
            ? this.dashboardService.getData(this.dashboardDefinition.initDataUrl, this.dashboardDefinition.initDataProperty || '')
            : of({}),
        ]).subscribe(([detailsData, initialsData]) => {
          if (this.dashboardDefinition?.dataMapFunction) {
            detailsData = new Function('data', 'pathParams', this.dashboardDefinition.dataMapFunction)(detailsData, this.pathParams)
          }

          this.data = { ...detailsData }

          this.buildOptionsInFilterActions(initialsData)

          this.loadMenuAndFavorite()

          timer(10).subscribe(() => {
            this.cards.forEach((x) => x.load())
            this.loading = false
          })
        })
      },
      setCalendarParams: () => { },
    },
    {
      id: Constants.menuIds.licenseOptimization,
      canSaveReport: true,
      refreshTable: () => {
        this.grid?.refresh()
      },
      load: (_: void) => {
        if (this.dashboardDefinition?.tabs && this.dashboardDefinition?.tabs?.length > 1 && this.dashboardDefinition?.tabs[1].cards) {
          this.reportDefinition = this.getReportDefinitionFilteringFields(this.dashboardDefinition?.tabs[1].cards[0].reportDefinition)
        }

        this.loadingLicenses = true

        this.administrationsService.getLicenseOptimizationAvailableSkus(this.storage.selectedOrganization?.id).subscribe((result: any) => {
          if (result.skus?.length) {
            const calls: Observable<void>[] = []

            // eslint-disable-next-line @typescript-eslint/naming-convention
            const params = { Days: this.daysBefore, plansVisibility: {}, filters: {}}
            result.skus.forEach((s: any) => {
              calls.push(
                this.administrationsService.getLicenseOptimizationActiveServices(this.storage.selectedOrganization?.id, s.sku, params)
              )
            })

            combineLatest(calls).subscribe((responses: any) => {
              this.defaultSkus = responses
                .filter((item: any) => item.aggregations[0].items.length > 0)
                .map((item: any) => item.sku.toLowerCase())
              this.loadingLicenses = false
            })
          } else {
            this.loadingLicenses = false
          }
        })
      },
      setCalendarParams: () => {
        this.calendarType = this.dashboardDefinition?.tabs![1]?.calendarType
        this.daysList = this.dashboardDefinition.tabs![1].daysList
        this.daysBefore = this.dashboardDefinition.tabs![1].defaultDaysFilter
      },
    },
    {
      id: Constants.menuIds.licenseByUser,
      canSaveReport: true,
      refreshTable: () => {
        this.grid?.refresh()
      },
      load: (_: void) => {
        if (this.dashboardDefinition?.tabs && this.dashboardDefinition?.tabs?.length > 1 && this.dashboardDefinition?.tabs[2].cards) {
          this.reportDefinition = this.getReportDefinitionFilteringFields(this.dashboardDefinition?.tabs[2].cards[0].reportDefinition)
        }
      },
      setCalendarParams: () => { },
    },
    {
      id: Constants.menuIds.licenseServiceUsage,
      canSaveReport: true,
      refreshTable: () => {
        if (this.serviceUsageGroupBy === 'User') {
          this.grid?.refresh()
        } else {
          this.clientGrid?.refresh()
        }
      },
      load: (full: boolean) => {
        if (this.dashboardDefinition?.tabs && this.dashboardDefinition?.tabs?.length > 2 && this.dashboardDefinition?.tabs[3].cards) {
          this.reportDefinition = this.getReportDefinitionFilteringFields(this.dashboardDefinition?.tabs[3].cards[0].reportDefinition)
          this.daysBefore = this.dashboardDefinition?.tabs[3].defaultDaysFilter
        }
      },
      setCalendarParams: () => {
        this.calendarType = this.dashboardDefinition?.tabs![3]?.calendarType
        this.daysList = this.dashboardDefinition.tabs![3].daysList
        this.daysBefore = this.dashboardDefinition.tabs![3].defaultDaysFilter
      },
    },
    {
      id: Constants.menuIds.licenseDuplicatedPlans,
      canSaveReport: true,
      refreshTable: () => {
        this.grid?.refresh()
      },
      load: (full: boolean) => {
        if (this.dashboardDefinition?.tabs && this.dashboardDefinition?.tabs?.length > 3 && this.dashboardDefinition?.tabs[4].cards) {
          this.reportDefinition = this.dashboardDefinition?.tabs[4].cards[0].reportDefinition
        }

        if (full) {
          this.loadingDuplicatedPlans = true
          this.loadingDuplicatedPlans = false
        }
      },
      setCalendarParams: () => { },
    },
  ]
  tabsHandlers: any[] = []

  canSeeCustomReports = true //#bug 40906

  private destroyed$: Subject<boolean> = new Subject()
  private cards!: QueryList<AbstractCardComponent>

  private filtersComponentsMap: Record<string, Type<unknown>> = {
    servicesUsageFilter: ServicesUsageFilterComponent,
  }

  constructor(
    private dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<RootState>,
    private breadcrumbService: BreadcrumbService,
    private translateHelper: TranslateHelper,
    public licenseHelper: LicenseHelper,
    private rightPanelService: RightPanelService,
    private administrationsService: AdministrationsService,
    public storage: LocalstorageService,
    private reportExportService: ReportExportService,
    private apiClient: ApiclientService,
    private cdref: ChangeDetectorRef,
    private service: ReportsService,
    public licensePoolService: LicensePoolService,
    public licenseService: LicenseService,
    private reportsComponentHelper: ReportsComponentHelper,
    public licenseOptimizationCenterService: LicenseOptimizationCenterService,
    private buildColumnsHelper: BuildColAgGrid
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.activatedRoute = activatedRoute
    this.savedReportIdFilter = this.activatedRoute.snapshot.queryParams.SavedReportId
    this.savedReportId = this.activatedRoute.snapshot.queryParams.SavedReportId
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges()
  }

  ngOnInit(): void {
    this.pathParams = this.activatedRoute.snapshot.params

    this.loading = true

    this.licensePoolService
      .arePermissionsLoaded()
      .pipe(
        filter((x) => !!x),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        combineLatest([
          this.router.events.pipe(
            filter((eventRes: any) => eventRes instanceof NavigationEnd),
            startWith(this.router)
          ),
          this.store.select(selectFlatMenusAll),
          this.store.select(selectFavorites),
          this.activatedRoute.data,
          this.store.select(selectedOrganization),
          this.administrationsService.getLicensePools(),
          this.licenseService.getServicePlans(),
          this.administrationsService.getLicensePoolConfiguration(),
        ])
          .pipe(takeUntil(this.destroyed$))
          .subscribe(([eventRes, { flatMenuAll }, favorites, data, organization, lp, sp, lpConfiguration]) => {
            this.tabsHandlers = this.tabsDefinitions.filter((k) => this.licensePoolService.canSeeTab(k.id))

            this.route = eventRes.url?.split('?')[0]
            this.servicePlans = sp.servicePlans
            this.savedReport = data.savedReport
            this.organization = organization

            this.dashboardDefinition = this.licenseOptimizationCenterService.getLicenseOptimizationCenterDefinition(this) as any

            this.noLpConfigured = lp.commandTask?.status === 'Progress' || lp.licensePoolGroups?.length === 0
            this.reportOnly = !!lpConfiguration?.licensePoolConfiguration?.reportOnly

            this.reportTitle = this.savedReport
              ? this.savedReport.reportName
              : this.translateHelper.instant(this.dashboardDefinition.title || '')
            this.reportDescription = this.savedReport
              ? this.savedReport.description
              : this.translateHelper.instant(this.dashboardDefinition.description || '')

            this.setTabSelectedBySavedReport()

            this.tabsHandlers[this.tabSelectedIndex].setCalendarParams()
            this.setMenu(flatMenuAll, favorites)
            this.setSavedReportsParams()

            if (this.savedReport) {
              if (this.tabSelectedIndex === this.licensePoolService.tabIndex('optimization', Constants.menuIds.licenseOptimization)) {
                this.licenseOptimizationAdvancedFiltersConfiguration.plansFilters = this.savedReport.request?.plansVisibility
                this.daysBefore = this.savedReport.request?.days
                this.licenseOptimizationAdvancedFiltersConfiguration.selectedSkus = this.savedReport.request?.skus
                this.licenseOptimizationAdvancedFiltersConfiguration.selectedPlans = this.savedReport.request?.selectedPlans
              } else if (
                this.tabSelectedIndex === this.licensePoolService.tabIndex('optimization', Constants.menuIds.licenseServiceUsage)
              ) {
                this.daysBefore = this.savedReport.request?.days
                this.serviceUsageAdvancedFiltersConfiguration.includeCosts = this.savedReport.request?.includeCosts
                this.serviceUsageAdvancedFiltersConfiguration.includeNewUsers = this.savedReport.request?.includeNewUsers
              } else if (
                this.tabSelectedIndex === this.licensePoolService.tabIndex('optimization', Constants.menuIds.licenseDuplicatedPlans)
              ) {
                this.duplicatedPlansAdvancedFiltersConfiguration.licenseAggregationFilter =
                  this.savedReport.request?.licenseAggregationFilter
                this.duplicatedPlansAdvancedFiltersConfiguration.skuAggregationFilter = this.savedReport.request?.skuAggregationFilter
              }
            }

            this.tabChanged({ index: this.tabSelectedIndex }, true)
          })
      })

    this.breadcrumbService.addComponent({
      type: ButtonComponent,
      data: {
        buttonType: 'tertiary',
        text: this.translateHelper.instant('common_Reload'),
        leftIcon: 'autorenew',
        size: 'tiny',
      },
      events: {
        clicked: (e: any) => this.refreshTable(),
      },
    })
  }

  ngOnDestroy(): void {
    this.breadcrumbService.clear()
  }

  buildOptionsInFilterActions(initialsData: any) {
    if (!!this.dashboardDefinition?.tabs && this.dashboardDefinition?.tabs.length > 0) {
      this.dashboardDefinition?.tabs.forEach((tab: DashboardTab) => {
        tab.cards?.forEach((card: any) => {
          if (card.type === 'table') {
            card.reportDefinition.filterActions?.forEach((action: SelectionAction) => {
              if (action.mapResultFunction) {
                action.options = new Function('data', 'dayjs', action.mapResultFunction)(initialsData, dayjs)
              }
            })
          } else if (card.type === 'chart') {
            card.charts?.forEach((chart: any) => {
              if (chart.options?.genericDropdownFilters) {
                chart.options.genericDropdownFilters.forEach((val: any) => {
                  if (val.mapResultFunction) {
                    val.options = new Function('data', 'dayjs', val.mapResultFunction)(initialsData, dayjs)
                  }

                  if (!!val.apiParamsToUpdate && !!val.apiParamsFunction) {
                    chart.apiParams[val.apiParamsToUpdate] = new Function('data', val.apiParamsFunction)(initialsData)
                  }
                })
              }
            })
          } else if (card.type === 'custom-chart') {
            card.customOptions?.forEach((option: CustomOption) => {
              if (option.getDataFromInit) {
                if (option.mapResultFunction) {
                  option.suggestions = option.mapResultFunction(initialsData, { dayjs })

                  if (option.suggestions) {
                    option.value = option.suggestions
                    option.displayValue = option.suggestions
                  }
                }
              }
            })
          }
        })
      })
    }
  }

  onDestroy() {
    this.breadcrumbService.clear()
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  canSeeTab = (id: number) => this.licensePoolService.canSeeTab(id)
  imSelected = (id: number) => this.tabsHandlers[this.tabSelectedIndex]?.id === id

  canSeeLeftPanel(): boolean {
    return this.canSeeCustomReports
  }

  rangeFilterChanged(daysFilter: { since?: string; to?: string; days?: any; isOnStart?: boolean }) {
    if (!isEqual(this.daysBefore, daysFilter.days)) {
      this.daysBefore = daysFilter.days || this.daysBefore
      this.refreshTable()
    }
  }

  openFilterPanel() {
    if (this.dashboardDefinition?.filterComponent) {
      const panelRef = this.rightPanelService.open({
        type: this.filtersComponentsMap[this.dashboardDefinition?.filterComponent],
        data: { organization: this.organization, filter: this.componentFilter, width: '50%' },
      })

      panelRef.afterClosed().subscribe((f: any) => {
        this.componentFilter = f || {}
        this.loading = true
        timer(10).subscribe(() => {
          this.cards.forEach((x) => x.load())
          this.loading = false
        })
      })
    }
  }

  tabChanged(params: any, firstTime?: boolean) {
    this.daysBefore = undefined

    this.selectedRows = []
    this.selectedRowsLicenseByUser = []

    if (!firstTime) {
      this.savedReport = null
      this.savedReportId = undefined
      this.reportTitle = this.translateHelper.instant(this.dashboardDefinition?.title || '')
      this.reportDescription = this.translateHelper.instant(this.dashboardDefinition?.description || '')
    }

    this.tabSelectedIndex = params.index

    this.tabsHandlers[this.tabSelectedIndex].setCalendarParams()
    this.setSavedReportsParams()
    this.loadSavedReports()

    this.tabsHandlers[this.tabSelectedIndex].load()
  }

  getPageData$(card: any): any {
    return of(card.reportDefinition)
  }

  getItems$ = (reportUrl: string, verb: Verb, params: any) => this.service.getData(reportUrl, verb, params) || of({} as ServerResponse<any>)
  onlineUsersColumns = () => this.store.select(selectOnlineuserColumns)

  getReportParams(card: any, params: ApiDataParameters): any {
    const reportParams = { ...params }

    if (this.savedReportIdFilter) {
      Object.assign(reportParams, { savedReportId: this.savedReportIdFilter })
      this.savedReportIdFilter = undefined
    }

    if (!!card && !!card.reportDefinition?.urlParameters) {
      Object.assign(reportParams, card.reportDefinition.urlParameters)
    }

    if (this.reportDefinition?.urlParameters) {
      Object.assign(reportParams, this.reportDefinition.urlParameters)
    }

    return reportParams
  }

  refreshTable() {
    this.tabsHandlers[this.tabSelectedIndex].refreshTable()
  }

  loadMenuAndFavorite() {
    combineLatest([this.store.select(selectMenuByRoute(trimStart(this.route, '/'))), this.store.select(selectFavorites)])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([menu, favorites]) => {
        this.menu = menu
        if (this.menu) {
          this.menu.isFavorite = !!favorites.favoriteMenus.find((x: any) => this.menu?.id === x)
        }
        this.breadcrumbService.updatePath(this.getPath())
      })
  }

  setIsFavorite(favorites: any) {
    if (this.menu) {
      if (this.savedReportId) {
        this.menu.isFavorite = !!favorites.favoriteSavedReports.find(
          (x: any) =>
            this.menu?.route === x.route.replace(`${Constants.savedReportString}${this.savedReportId}`, '') &&
            this.savedReportId === x.savedReportId
        )
      } else {
        this.menu.isFavorite = !!favorites.favoriteMenus.find((x: any) => this.menu?.id === x)
      }
    }
  }

  setMenu(flatMenuAll: NavItem[], favorites: any) {
    this.menu = flatMenuAll.find((x: NavItem) => {
      const urlToFind = this.savedReportId ? this.route.replace(`${Constants.savedReportString}${this.savedReportId}`, '') : this.route
      return '/' + x.route === urlToFind
    })
    if (this.menu) {
      this.setIsFavorite(favorites)
      this.loadSavedReports()
      this.breadcrumbService.updatePath([...this.getPath()])
    } else {
      this.breadcrumbService.updatePath([this.translateHelper.instant('common_Reports')])
    }
  }

  rowSelected() {
    this.selectedRows = this.grid?.getSelectedRows()
  }

  rowSelectedLicenseByUser() {
    this.selectedRowsLicenseByUser = this.grid?.getSelectedRows()
  }

  rowSelectedServiceUsage() {
    this.selectedRowsLicenseByUser = this.grid?.getSelectedRows()
  }

  rowSelectedDuplicatedPlans() {
    this.selectedRowsDuplicatedPlans = this.grid?.getSelectedRows()
  }

  getLicenseOptmizationItemsCall(params: ApiDataParameters): any {
    this.params = {
      ...this.getReportParams(null, params),
      days: this.daysBefore,
      getAllInactive:
        !this.licenseOptimizationAdvancedFiltersConfiguration.selectedSkus ||
        this.licenseOptimizationAdvancedFiltersConfiguration.selectedSkus?.length === 0,
      plansVisibility: this.licenseOptimizationAdvancedFiltersConfiguration.plansFilters,
      selectedPlans: this.licenseOptimizationAdvancedFiltersConfiguration.selectedPlans,
      skus: this.licenseOptimizationAdvancedFiltersConfiguration.selectedSkus || this.defaultSkus || [],
    }

    return this.administrationsService.getOnlineUsersByActivePlansGroup(this.params, this.storage.selectedOrganization?.id).pipe(
      tap((x) => {
        if (!this.loadedLicensesTabFirstTime) {
          this.showNoPrimaryLicensesLicensesDisclaimer = !x?.items?.length
        }
        this.loadedLicensesTabFirstTime = true
        this.managementColumnDefinition = {
          configs: x?.configs,
          fields: x?.metadata.fields,
          isOnlineUsersType: true,
        }
      })
    )
  }

  getLicenseOptmizationItems = (params: ApiDataParameters): any => ({
    items: this.getLicenseOptmizationItemsCall(params),
    cols: this.onlineUsersColumns(),
  })

  getLicenseByUserItemsCall = (params: ApiDataParameters): Observable<any> => {
    this.params = {
      ...this.getReportParams(null, params),
    }

    return this.administrationsService.getLicenseByUser(this.params).pipe(
      tap(
        (x) =>
        (this.managementColumnDefinition = {
          configs: x?.configs,
          fields: x?.metadata.fields,
          isOnlineUsersType: false,
        })
      )
    )
  }

  onAppDataGridColumnDefined = (colConfig: { columnDefs: (ColDef & CoreViewColumn)[]; serverCols: CoreViewColumn[] }) => {
    this.managementColumnDefinition.builtColumnDefs = cloneDeep(colConfig)
      .columnDefs.filter((x) => !x.hide)
      .map((x) => {
        if (this.buildColumnsHelper.isLinkRenderer(x.cellRenderer as string)) {
          x.cellRenderer = undefined
        }
        return x
      })
  }

  getLicenseByUserItems = (params: ApiDataParameters): any => ({
    items: this.getLicenseByUserItemsCall(params),
    cols: this.loadLicenseByUserColumns(),
  })

  getServiceUsageItemsCall(params: ApiDataParameters): any {
    this.params = {
      ...this.getReportParams(null, params),
      days: this.daysBefore,
      includeCosts: this.serviceUsageAdvancedFiltersConfiguration.includeCosts,
      includeNewUsers: this.serviceUsageAdvancedFiltersConfiguration.includeNewUsers,
    }

    return this.licensePoolService.getOnlineUsersGrouped(this.params).pipe(
      tap((x) => {
        if (this.reportDefinition) {
          this.reportDefinition.fields = x.configs.filter((c: any) => c.visible).map((c: any) => c.originalName)
          this.params.fields = this.reportDefinition.fields.map((c: any) => Helpers.capitalize(c))
          x.metadata.fields = this.reportDefinition.fields
        }
        this.managementColumnDefinition = {
          configs: x?.configs,
          fields: x?.metadata.fields,
          isOnlineUsersType: false,
        }
      })
    )
  }

  getServiceUsageItems = (params: ApiDataParameters): any => ({
    items: this.getServiceUsageItemsCall(params),
    cols: this.onlineUsersColumns(),
  })

  getServiceUsageItemsClient = (): any => {
    const params = { pageNumber: 1, pageSize: 2000000, sort: 'Total', sortOrder: 'desc' }
    this.params = {
      ...this.getReportParams(null, params),
      days: this.daysBefore,
      includeCosts: this.serviceUsageAdvancedFiltersConfiguration.includeCosts,
      includeNewUsers: this.serviceUsageAdvancedFiltersConfiguration.includeNewUsers,
    }

    return this.licensePoolService.getOnlineUsersGrouped(this.params).pipe(
      switchMap((x) => {
        x.metadata.fields = x.configs.map((x: any) => x.originalName)
        return of(x)
      })
    )
  }

  getDuplicatedPlansItemsCall(params: ApiDataParameters): any {
    this.params = {
      ...this.getReportParams(null, params),
      licenseAggregationFilter: this.duplicatedPlansAdvancedFiltersConfiguration.licenseAggregationFilter,
      skuAggregationFilter: this.duplicatedPlansAdvancedFiltersConfiguration.skuAggregationFilter,
    }

    return this.administrationsService.getOnlineUsersByDuplicatedPlans(this.params, this.storage.selectedOrganization?.id).pipe(
      tap((x) => {
        if (!this.loadedDuplicatedFirstTime) {
          this.showNoPrimaryLicensesDuplicatedDisclaimer = !x?.items?.length
        }
        this.loadedDuplicatedFirstTime = true
        this.duplicatedPlansAdvancedFiltersConfiguration.aggregations = x.aggregations
        this.managementColumnDefinition = {
          configs: x?.configs,
          fields: x?.metadata.fields,
          isOnlineUsersType: true,
        }
      })
    )
  }

  getDuplicatedPlansItems = (params: ApiDataParameters): any => ({
    items: this.getDuplicatedPlansItemsCall(params),
    cols: this.onlineUsersColumns(),
  })

  getRefreshData(card: any): any {
    return (params: ApiDataParameters) => ({
      // eslint-disable-next-line max-len
      items: this.getItems$(card.reportDefinition?.url || '', card.reportDefinition?.verb || 'post', this.getReportParams(card, params)),
      cols: card.reportDefinition?.isOnlineUsersType ? this.onlineUsersColumns() : null,
    })
  }

  getConfiguration() {
    return {
      ...this.licenseOptimizationAdvancedFiltersConfiguration,
      filters: this.grid.getFilters() || {},
      daysBefore: this.daysBefore,
    }
  }

  getServiceUsageAdvancedFiltersConfiguration() {
    return {
      ...this.serviceUsageAdvancedFiltersConfiguration,
    }
  }

  getDuplicatedPlansConfiguration() {
    return {
      ...this.duplicatedPlansAdvancedFiltersConfiguration,
      filters: this.grid.getFilters() || {},
    }
  }

  schedule() {
    this.metadata.queryRequest.fields = this.reportsComponentHelper.getVisibleFields(this.grid.columnApi)
    const panelRef = this.rightPanelService.open({
      type: NewSchedulationComponent,
      data: {
        request: this.metadata?.queryRequest,
        route: this.route,
        initialName: this.savedReport ? this.reportTitle : this.translateHelper.instant(this.reportDefinition!.title!),
        savedReport: this.savedReport,
        fromReport: true,
      },
    })
    panelRef
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(() => {
        this.loadSavedReports()
        this.panels.first.open = true
      })
  }

  saveView() {
    this.metadata.queryRequest.fields = this.reportsComponentHelper.getVisibleFields(this.grid.columnApi)
    const panelRef = this.rightPanelService.open({
      type: SaveViewComponent,
      data: {
        lastRequest: this.metadata?.queryRequest,
        reportDefinition: this.reportDefinition,
        route: this.route,
        savedReport: this.savedReport,
        pivotSettings: this.isPivotModeActive ? JSON.stringify(this.pivotSettings) : null,
        isPivot: this.isPivotModeActive,
      },
    })
    panelRef
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(() => {
        this.loadSavedReports()
        this.panels.first.open = true
      })
  }

  loadSavedReports() {
    if (this.tabsHandlers[this.tabSelectedIndex].canSaveReport) {
      const urlToFind = this.route.replace(`${Constants.savedReportString}${this.savedReportId}`, '')

      this.service
        .getViews({ pageSize: 500, pageNumber: 1, route: urlToFind, sortOrder: 'asc', sortField: 'reportName' })
        .subscribe((res) => {
          this.savedReports = res.filter((r: SavedReport) => r.apiUrl === this.reportDefinition?.url)
        })
    }
  }

  exportReportLicenseByUser(format: string) {
    this.metadata.queryRequest.fields = this.columnDefsLicenseByUser.map((f) => f.originalName)

    this.reportExportService
      .exportGeneric(
        (params: ApiDataParameters) => {
          this.params = {
            ...this.getReportParams(null, params),
          }
          return this.getExportedItems(params, format)
        },
        this.metadata,
        this.reportDefinition!.verb,
        format,
        this.translateHelper.instant(this.reportDefinition?.title || ''),
        `${this.apiClient.basePortalApiUrl + this.reportDefinition?.url}`
      )
      .subscribe()
  }

  exportReport(key: string) {
    this.metadata.queryRequest.fields = this.reportsComponentHelper
      .getVisibleFields(this.grid.columnApi)
      .map((field) => Helpers.capitalize(field))

    this.reportExportService
      .exportGeneric(
        this.getExportedItems,
        this.metadata,
        this.reportDefinition!.verb,
        key,
        this.translateHelper.instant(this.reportDefinition?.title || ''),
        this.reportDefinition?.url!
      )
      .subscribe()
  }

  getExportedItems = (params: ApiDataParameters, format: string) => {
    const paramsToPass = { ...params, ...this.params }

    return (
      this.service.getExportedData(this.reportDefinition?.url || '', this.reportDefinition?.verb || 'post', paramsToPass, format) ||
      of({} as any)
    )
  }

  setSavedReportsParams() {
    if (this.savedReport) {
      let filterModel: any = {}
      const filterKeysToKeepCapitized: string[] = []

      if (this.tabSelectedIndex === this.licensePoolService.tabIndex('optimization', Constants.menuIds.licenseByUser)) {
        Object.entries(this.savedReport.request?.filters).forEach((f: any) => {
          if (f[0].startsWith('MicrosoftServicePlan_')) {
            filterKeysToKeepCapitized.push(f[0].replace('MicrosoftServicePlan_', ''))
          }
          filterModel[f[0].replace('MicrosoftServicePlan_', '')] = f[1]
        })
      } else {
        filterModel = this.savedReport.request?.filters
      }

      this.savedReportFilterSortModel = {
        filterModel,
        sortModel: { sortField: this.savedReport.request?.sort, sortOrder: this.savedReport.request?.sortOrder },
        filterKeysToKeepCapitized,
      }
    } else {
      this.savedReportFilterSortModel = undefined
    }
  }

  cardSavingOppsTableDisplayByOnClick = (event: any, key: string) => {
    if (!!this.dashboardDefinition?.tabs && !!this.dashboardDefinition?.tabs[0] && !!this.dashboardDefinition.tabs[0].cards) {
      const card = this.dashboardDefinition.tabs[0].cards.find((c: CardDashboard) => c.title === 'savingOppsTable')

      card.reportDefinition.urlParameters.displayBy = key

      const options = card.reportDefinition.filterActions.find((f: SelectionAction) => f.text === 'licenses_DisplayBy').options

      options.forEach((o: SelectionActionOptions) => {
        o.isSelected = o.key === key
      })

      if (card.type === 'clientTable') {
        this.clientGrid.refresh()
        return
      }

      const fields = this.licenseHelper.getFields(card.reportDefinition.urlParameters.displayBy, false)

      card.reportDefinition.fields = fields?.map((f) => f.col.text)

      const coldef = this.grid?.gridApi.getColumnDefs() as (ColDef & CoreViewColumn)[]

      coldef.forEach((c: ColDef & CoreViewColumn) => {
        c.hide = !fields.find((f) => f.col.text === c.originalName)
      })

      this.grid.columnDefs = []
      this.grid.columnDefs = coldef
      this.grid.refresh()
    }
  }

  cardSavingOppsTableViewOnClick = (event: any, key: string) => {
    this.loadingTable = true

    setTimeout(() => {
      if (!!this.dashboardDefinition?.tabs && !!this.dashboardDefinition?.tabs[0] && !!this.dashboardDefinition.tabs[0].cards) {
        const card = this.dashboardDefinition.tabs[0].cards.find((c: CardDashboard) => c.title === 'savingOppsTable')

        const currentMonth = Helpers.toYYYYMM(new Date())

        card.reportDefinition.operationsOnCols?.map((o: OperationsOnCols) => {
          o.type = Helpers.toYYYYMM(new Date(key)) !== currentMonth ? '' : 'link'
        })

        this.loadingTable = false

        this.licenseHelper.configureOptions(card, key, 'licenses_View', 'licenses_CompareTo')

        if (card.type === 'clientTable') {
          card.reportDefinition.urlParameters.target = key
          this.clientGrid.refresh()
        } else {
          card.reportDefinition.urlParameters.date = key
          this.grid.refresh()
        }
      }
    }, 1)
  }

  cardSavingOppsTableCompareToOnClick = (event: any, key: string) => {
    if (!!this.dashboardDefinition?.tabs && !!this.dashboardDefinition?.tabs[0] && !!this.dashboardDefinition.tabs[0].cards) {
      const card = this.dashboardDefinition.tabs[0].cards.find((c: CardDashboard) => c.title === 'savingOppsTable')

      this.licenseHelper.configureOptions(card, key, 'licenses_CompareTo', 'licenses_View')

      if (key !== 'None') {
        card.reportDefinition.urlParameters.source = key
        card.reportDefinition.urlParameters.target =
          card.reportDefinition.urlParameters.date ||
          card.reportDefinition.urlParameters.target ||
          card.reportDefinition.filterActions[2].options[1].key
        delete card.reportDefinition.urlParameters.date

        card.type = 'clientTable'
        card.filterActions = card.reportDefinition.filterActions

        card.reportDefinition.url = '/licensepool/report/savingopportunities/comparable'
        card.getItems = (): any => this.licenseHelper.getItemsCompare(card, this.gridOptionsSnapshots)
        card.manageError = { propToCheck: 'errorCode', messageProp: 'message' }
        this.clientGrid?.refresh()
      } else {
        card.type = 'table'
        if (card.reportDefinition.urlParameters.date || card.reportDefinition.urlParameters.target) {
          card.reportDefinition.urlParameters.date = card.reportDefinition.urlParameters.date || card.reportDefinition.urlParameters.target
        }
        delete card.reportDefinition.urlParameters.target
        delete card.reportDefinition.urlParameters.source
        card.reportDefinition.fields = this.licenseHelper
          .getFields(card.reportDefinition.urlParameters.displayBy, false)
          ?.map((f) => f.col.text)
        card.reportDefinition.url = '/licensepool/report/savingopportunities'

        this.grid?.refresh()
      }
    }
  }

  cardDuplicatedPlansTableOnClick = () => {
    this.rightPanelService
      .open({
        type: DuplicatedPlansAdvancedFiltersComponent,
        data: {
          configuration: this.getDuplicatedPlansConfiguration(),
          width: '30%',
        },
      })
      .afterClosed()
      .subscribe((f: { filters?: any; aggregations?: any[]; licenseAggregationFilter?: string; skuAggregationFilter?: string }) => {
        if (!f) {
          return
        }

        this.duplicatedPlansAdvancedFiltersConfiguration = f
        this.refreshTable()
      })
  }

  cardLicensesServiceUsageOnClick = (event: any, key: string) => {
    if (
      !!this.dashboardDefinition?.tabs &&
      !!this.dashboardDefinition?.tabs[this.tabSelectedIndex] &&
      !!this.dashboardDefinition.tabs[3].cards
    ) {
      const card = this.dashboardDefinition.tabs[3].cards.find((c: CardDashboard) => c.title === 'serviceUsageTable')

      card.reportDefinition.urlParameters.groupBy = key
      this.serviceUsageGroupBy = key

      const options = card.reportDefinition.filterActions.find((f: SelectionAction) => f.text === 'licenses_GroupBy').options

      options.forEach((o: SelectionActionOptions) => {
        o.isSelected = o.key === key
      })

      if (key === 'User') {
        card.type = 'table'
        card.reportDefinition.fields = this.licenseHelper
          .getFields(card.reportDefinition.urlParameters.groupBy, false)
          ?.map((f) => f.col.text)
        this.refreshTable()
      } else {
        card.type = 'clientTable'
        card.filterActions = card.reportDefinition.filterActions
        card.getItems = (): any => this.getServiceUsageItemsClient()
        card.manageError = { propToCheck: 'errorCode', messageProp: 'message' }
        this.refreshTable()
      }
    }
  }

  cardLicensesAdvancedFiltersOnClick = () => {
    this.rightPanelService
      .open({
        type: ServiceUsageAdvancedFiltersComponent,
        data: {
          configuration: this.getServiceUsageAdvancedFiltersConfiguration(),
          width: '30%',
        },
      })
      .afterClosed()
      .subscribe((f: { includeCosts: boolean; includeNewUsers: boolean }) => {
        if (!f) {
          return
        }

        this.serviceUsageAdvancedFiltersConfiguration = f
        this.refreshTable()
      })
  }

  cardOptimizationLicensesOnClick = () => {
    this.rightPanelService
      .open({
        type: LicenseOptimizationAdvancedFiltersComponent,
        data: {
          configuration: this.getConfiguration(),
          width: '30%',
        },
      })
      .afterClosed()
      .subscribe((f: { daysBefore: number; filters?: any; selectedSkus?: any[]; selectedPlans?: any[]; plansFilters: any }) => {
        if (!f) {
          return
        }

        this.licenseOptimizationAdvancedFiltersConfiguration = f
        this.refreshTable()
      })
  }

  private loadLicenseByUserColumns() {
    this.loadingLicenseByUser = true

    const calls = [
      this.administrationsService.getLicensesSkuService(this.storage.selectedOrganization?.id),
      this.administrationsService.getLicensesStatuses(),
    ]

    return combineLatest(calls).pipe(
      map(([resSku, resStauts]) => {
        const spCols: CoreViewColumn[] = []
        resSku.aggregatedServicePlansBySku.forEach((sp: any) => {
          sp.aggregatedServicePlans.forEach((a: any) => {
            spCols.push({
              originalName: Helpers.capitalize(a.name),
              name: a.name,
              translate: this.licenseHelper.getServiceName(a.name, this.servicePlans),
              filter: { type: 'string', name: a.name },
              filterName: 'MicrosoftServicePlan_' + a.name,
              sortName: 'no_sort',
              type: 'string',
              visible: true,
              agColDef: {
                filterParams: {
                  options: of(resStauts.itemsList),
                  filterOptions: [],
                  defaultFilterType: 'contains',
                },
                filter: 'singleSelectFilter',
                valueFormatter: (params: any): string => params.data.microsoftServicePlans[params.colDef.field],
              },
            })
          })
        })

        this.columnDefsLicenseByUser = (
          [
            {
              originalName: 'DisplayName',
              name: 'displayName',
              translate: 'DisplayName',
              filter: { type: 'string', name: 'displayName' },
              type: 'string',
            },
            {
              originalName: 'UserPrincipalName',
              name: 'userPrincipalName',
              translate: 'UserPrincipalName',
              filter: { type: 'string', name: 'userPrincipalName' },
              type: 'string',
            },
            {
              originalName: 'LicensePoolGroupName',
              name: 'licensePoolGroupName',
              translate: 'LicensePoolGroupName',
              filter: { type: 'string', name: 'licensePoolGroupName' },
              type: 'string',
            },
            {
              originalName: 'AccountSku',
              name: 'accountSku',
              translate: 'AccountSku',
              filter: { type: 'string', name: 'accountSku' },
              type: 'string',
            },
            {
              originalName: 'CopilotInteractionDate',
              name: 'copilotInteractionDate',
              translate: 'CopilotInteractionDate',
              filter: { type: 'date', name: 'copilotInteractionDate' },
              type: 'date',
            },
          ] as CoreViewColumn[]
        ).concat(uniqBy(spCols, (c: any) => c.name))

        this.loadingLicenseByUser = false
        return this.columnDefsLicenseByUser
      })
    )
  }

  private getPath(): string[] {
    const path = []
    let menu = this.menu
    while (menu) {
      path.push(this.translateHelper.instant(menu.title))
      menu = menu.parent || undefined
    }
    return path.reverse()
  }

  private getReportDefinitionFilteringFields(reportDefinition: ReportDefinition): ReportDefinition {
    if (this.noLpConfigured) {
      remove(reportDefinition.fields, x => x === 'LicensePoolGroupName' || x === 'LicensePoolDiscriminator')
    } else if (this.reportOnly) {
      remove(reportDefinition.fields, x => x === 'LicensePoolGroupName')
    }
    return reportDefinition
  }

  private setTabSelectedBySavedReport() {
    if (this.savedReport) {
      if (
        this.savedReport.apiUrl?.indexOf('licenses/optmization') >= 0 &&
        this.savedReport.apiUrl?.indexOf('onlineusersbyactiveplansgroup') >= 0
      ) {
        this.tabSelectedIndex = this.licensePoolService.tabIndex('optimization', Constants.menuIds.licenseOptimization)
      } else if (this.savedReport.apiUrl?.indexOf('licences') >= 0) {
        this.tabSelectedIndex = this.licensePoolService.tabIndex('optimization', Constants.menuIds.licenseByUser)
      } else if (this.savedReport.apiUrl?.indexOf('onlineusers/grouped') >= 0) {
        this.tabSelectedIndex = this.licensePoolService.tabIndex('optimization', Constants.menuIds.licenseServiceUsage)
      } else if (
        this.savedReport.apiUrl?.indexOf('licenses/optmization') >= 0 &&
        this.savedReport.apiUrl?.indexOf('onlineusersduplicatedplans') >= 0
      ) {
        this.tabSelectedIndex = this.licensePoolService.tabIndex('optimization', Constants.menuIds.licenseDuplicatedPlans)
      }
    }
  }
}

import { Component, Input } from '@angular/core'
import { CommercialPortalSkusService } from '@app/core/services/commercial-portalskus.service'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { Suggestion } from '@coreview/coreview-components'

export interface CommercialPortalSku {
  id: string
  title: string
  order: number
}

@Component({
  selector: 'app-demo-experience-filter',
  templateUrl: './demo-experience-filter.component.html',
  styleUrls: ['./demo-experience-filter.component.sass'],
})
export class DemoExperienceFilterComponent {
  @Input() activatedDemoMode = false
  @Input() portalSkus: string[] = []

  originalMainSkus: CommercialPortalSku[] = []
  originalAddOnSkus: CommercialPortalSku[] = []

  mainSkusFilter: Suggestion[] = []
  selectedMainSkusFilter: Suggestion | null = null

  addOnSkusFilter: Suggestion[] = []
  selectedAddOnSkusFilter: Suggestion[] = []

  constructor(private commercialPortalSkusService: CommercialPortalSkusService, private location: Location,
    private storage: LocalstorageService) {
    this.initLicenses()
  }

  initLicenses() {
    this.commercialPortalSkusService.getCommercialPortalSkus().subscribe((res: any) => {
      const orgSkus = this.storage.selectedOrganization.portalSkus || []

      this.originalMainSkus = res?.main?.filter((m: any) => orgSkus.includes(m.id)).sort((a: CommercialPortalSku, b: CommercialPortalSku) => a.order - b.order);
      this.originalAddOnSkus = res?.addOns?.filter((m: any) => orgSkus.includes(m.id)).sort((a: CommercialPortalSku, b: CommercialPortalSku) => a.order - b.order);

      this.mainSkusFilter = res?.main?.filter((m: any) => orgSkus.includes(m.id)).map((x: CommercialPortalSku) => ({ value: x.id, displayValue: x.title }))
      this.addOnSkusFilter = res?.addOns?.filter((m: any) => orgSkus.includes(m.id)).map((x: CommercialPortalSku) => ({ value: x.id, displayValue: x.title }))

      if (this.activatedDemoMode) {
        this.setSelectedFilters()
      }
    })
  }

  handleMainSkusFilterChanged(mainSku: Suggestion | null) {
    this.selectedMainSkusFilter = mainSku
  }

  handleAddOnSkusFilterChanged(addOnSkus: Suggestion[]) {
    this.selectedAddOnSkusFilter = addOnSkus
  }

  handleActivateDemoMode() {
    const cskus: string[] = [this.selectedMainSkusFilter?.value, ...this.selectedAddOnSkusFilter.map((x) => x.value)]

    this.commercialPortalSkusService.activateDemoMode(cskus).subscribe(() => {
      this.location.reload()
    })
  }

  handleExitDemoMode() {
    this.commercialPortalSkusService.exitDemoMode().subscribe(() => {
      this.location.reload()
    })
  }

  handleMainSkusFilterInputChanged(input?: string) {
    this.mainSkusFilter = this.originalMainSkus
      .filter((x) => x.title.toLowerCase().includes(input?.toLowerCase() || ''))
      .map((x) => ({ value: x.id, displayValue: x.title }))
  }

  handleAddOnSkusFilterInputChanged(input?: string) {
    this.addOnSkusFilter = this.originalAddOnSkus
      .filter((x) => x.title.toLowerCase().includes(input?.toLocaleLowerCase() ?? ''))
      .map((x) => ({ value: x.id, displayValue: x.title }))
  }

  private setSelectedFilters() {
    this.selectedMainSkusFilter = this.originalMainSkus.find((x) => this.portalSkus.includes(x.id))
      ? this.originalMainSkus.filter((x) => this.portalSkus.includes(x.id)).map((x) => ({ value: x.id, displayValue: x.title }))[0]
      : null

    this.selectedAddOnSkusFilter = this.originalAddOnSkus
      .filter((x) => this.portalSkus.includes(x.id))
      .map((x) => ({ value: x.id, displayValue: x.title }))
  }
}

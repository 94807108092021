import { Component, AfterViewInit, ViewChild } from '@angular/core'
import { ReportsComponent } from '../../reports.component'

@Component({
  selector: 'app-signin-reports',
  templateUrl: './signin-reports.component.html',
  styleUrls: ['./signin-reports.component.sass'],
})
export class SigninReportsComponent implements AfterViewInit {
  @ViewChild(ReportsComponent) reportsComponent!: ReportsComponent

  ngAfterViewInit(): void {
    if (this.reportsComponent !== undefined) {
      this.reportsComponent.onPrepareApiParams(this.prepareParams)
    }
  }

  public prepareParams = (params: { fields: string[]; showSensitiveData?: boolean }) => {
    this.arrangeFields(params)
  }

  private arrangeFields(def: { fields: string[]; showSensitiveData?: boolean }) {
    def.fields = def.fields.filter((f) => !['UserPrincipalName', 'UserDisplayName', 'UserHash'].includes(f))
    if (!!def.showSensitiveData) {
      def.fields.unshift('UserPrincipalName', 'UserDisplayName')
    } else {
      def.fields.unshift('UserHash')
    }
  }
}

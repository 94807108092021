import { AgFloatingFilterComponent } from '@ag-grid-community/angular'
import { FilterChangedEvent, IFloatingFilterParams } from '@ag-grid-community/core'
import { Component, EventEmitter, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core'
import { comparableVerbs, comparableVerbsWithNoValues } from '@app/core/models/ReportDefinition'
import { Helpers } from '@app/shared/utilities/helpers'
import { fromEvent, merge, timer } from 'rxjs'
import { takeUntil, debounceTime, filter, map } from 'rxjs/operators'

@Component({
  selector: 'app-generic-floating-filter',
  templateUrl: './generic-floating-filter.component.html',
  styleUrls: ['./generic-floating-filter.component.sass'],
})
export class GenericFloatingFilterComponent implements AfterViewInit, OnDestroy, AgFloatingFilterComponent {
  @ViewChild('input') input!: ElementRef;

  params!: IFloatingFilterParams
  currentValue!: string
  disabled = false
  readonly = false
  inputChanged$ = new EventEmitter<string>()
  destroyed$ = new EventEmitter<void>()

  ngAfterViewInit(): void {
    const enterKey$ = fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup').pipe(
      filter((event: KeyboardEvent) => event.key === 'Enter'),
      map(event => (event.target as HTMLInputElement).value)
    )

    const input$ = this.inputChanged$.pipe(debounceTime(1500))

    merge(input$, enterKey$).pipe(
      takeUntil(this.destroyed$)
    ).subscribe((currentValue) => {
      let key: string
      let value: string | null

      this.params.parentFilterInstance((instance: any) => {
        const numberFilter = instance.getFilterType() === 'number'
        let found = this.findInComparableVerbsNoValue(currentValue)
        if (found) {
          key = found
        } else {
          found = this.findInComparableVerbs(currentValue, instance.optionsFactory.filterOptions)
          if (found) {
            key = found
            value = currentValue.substring(comparableVerbs[key].length)
          } else {
            key = numberFilter ? 'equals' : 'contains'
            value = !currentValue ? null : currentValue
          }
          if (this.isRangeFilter(key, value)) {
            instance.setModel({
              type: key,
              filter: value?.split('&')[0].trim(),
              filterTo: value?.split('&')[1].trim(),
            })
            return
          }
        }
        if (numberFilter && value && isNaN(+value)) {
          instance.onFloatingFilterChanged(null, null)
          return
        }
        instance.onFloatingFilterChanged(key, value)
      })
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.emit()
    this.destroyed$.complete()
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent | null): void {
    timer(50).subscribe(() => {
      if ((filterChangedEvent as any)?.source === 'filterDestroyed') {
        this.clear()
      } else {
        this.currentValue = Helpers.getStringFilterFromModel(parentModel)
      }
    })
  }

  onInputBoxChanged() {
    this.inputChanged$.emit(this.currentValue)
  }

  agInit(params: IFloatingFilterParams): void {
    this.params = params
    this.disabled = (params as any).disableParentFilter
    this.readonly = params.suppressFilterButton
  }

  clear() {
    this.currentValue = ''
    this.params.parentFilterInstance((instance: any) => {
      instance.onFloatingFilterChanged(instance?.optionsFactory?.defaultOption, null)
    })
  }

  private findInComparableVerbsNoValue(currentValue: string): string | undefined {
    return Object.keys(comparableVerbsWithNoValues).find((x) => comparableVerbsWithNoValues[x] === currentValue)
  }

  private findInComparableVerbs(currentValue: string, filterOptions?: any[]): string | undefined {
    return Object.keys(comparableVerbs)
      .filter((x) => filterOptions?.map((o: any) => (typeof o === 'string' ? o : o.displayKey))?.includes(x))
      .sort((a, b) => comparableVerbs[b].length - comparableVerbs[a].length)
      .find((x) => !!comparableVerbs[x] && currentValue.startsWith(comparableVerbs[x]))
  }

  private isRangeFilter(key: string, value: string | null): boolean {
    return key === 'inRange' && !!value?.includes('&')
  }
}

<div class="flex-full-height">
  <div style="display: flex; color: initial; line-height: 60px; border-bottom: 1px solid var(--opaque-light-contrast-color)">
    <div style="flex: 1; font-size: 14px; font-weight: 600; margin-left: 20px" translate>management_ErrorMessageDetail</div>
  </div>
  <div class="p-20" style="flex: 1; display: flex; flex-direction: column; overflow: auto">
    <div translate>management_CustomActionsErrorsDescription</div>
    <app-client-datagrid
      [getItems]="getErrorMessages"
      [columnsParameters]="errorMessagesColumnDefs"
      [hideColumnsSelector]="true"
      [autosizeAllColumns]="true"
      [gridOptions]="{ suppressRowClickSelection: true }"
      style="flex: 1"
      cvDataTest="custom-action-error-details"
    >
    </app-client-datagrid>
  </div>
</div>

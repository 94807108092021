<app-datagrid-actions
  [hideColumnsSelector]="hideColumnsSelector"
  [columnDefs]="columnDefs"
  [defaultCols]="defaultCols"
  [columnApi]="columnApi"
  [gridApi]="gridApi"
  [actions]="gridDefinition?.selectionActions || []"
  [filterActions]="gridDefinition?.filterActions || []"
  [refresh]="refresh"
  [entityName]="gridDefinition?.entity || ''"
  [selectedColumnsChanged$]="selectedColumnsChanged$"
  [lockedColumns]="gridDefinition?.lockedColumns || []"
  (pivotModeChanged)="onPivotModeChange($event)"
  [pivotModeEnabled]="enablePivotMode"
  [enableAnonymousData]="enableAnonymousData"
  (anonymousDataModeChanged)="onAnonymousDataModeChanged($event)"
  [externalFilters]="gridDefinition?.externalFilters || []"
  [externalFunctionMapper]="externalFunctionMapper"
  [groupedActions]="gridDefinition?.groupedActions || []"
  [filters]="gridDefinition?.filters"
  [getSelectedRows]="getSelectedRows"
  [rowSelected$]="rowSelected"
  (visibleColumnChanged)="onVisibleColumnChanged($event)"
>
  <div toolbar>
    <ng-content select="[toolbar]"></ng-content>
  </div>
  <div extraFilters><ng-content select="[extraFilters]"></ng-content></div>
  <div expandCollapse><ng-content select="[expandCollapse]"></ng-content></div>
</app-datagrid-actions>
<ag-grid-angular
  #agGrid
  style="width: 100%; flex: 1"
  class="ag-theme-alpine ag-theme-coreview"
  [ngClass]="{ 'single-selection': rowSelection === 'single' }"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [rowModelType]="rowModelType"
  [components]="frameworkComponents"
  [suppressServerSideInfiniteScroll]="false"
  [pagination]="true"
  [paginationPageSize]="paginationPageSize"
  [cacheBlockSize]="cacheBlockSize"
  [animateRows]="true"
  [rowSelection]="rowSelection"
  [rowMultiSelectWithClick]="true"
  (gridReady)="onGridReady($event)"
  [modules]="modules"
  [treeData]="treeData"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [isServerSideGroup]="isServerSideGroup"
  [getServerSideGroupKey]="getServerSideGroupKey"
  [suppressPaginationPanel]="true"
  (paginationChanged)="pageChanged()"
  (sortChanged)="sortChanged($event)"
  [rowClassRules]="rowClassRules"
  [getServerSideGroupLevelParams]="getServerSideStoreParams"
  [masterDetail]="hasMasterDetail"
  [isRowMaster]="isRowMaster"
  [detailCellRenderer]="detailCellRenderer"
  [detailCellRendererParams]="detailCellRendererParam"
  [detailRowAutoHeight]="true"
  [detailRowHeight]="detailRowHeight"
  [gridOptions]="gridOptions"
  [blockLoadDebounceMillis]="500"
  [rowHeight]="32"
  [suppressColumnVirtualisation]="true"
  [overlayLoadingTemplate]="'common_GridLoading' | translate"
  [overlayNoRowsTemplate]="'common_NoRowsToShow' | translate"
  [domLayout]="autoHeight ? 'autoHeight' : 'normal'"
  (gridSizeChanged)="resize$.next($event)"
  (columnMoved)="columnMoved($event)"
  (columnVisible)="columnVisible($event)"
  (columnPinned)="columnMoved($event)"
  (columnEverythingChanged)="columnEverythingChanged($event)"
  (rowSelected)="onRowSelected($event)"
  (modelUpdated)="onModelUpdated($event)"
  [attr.cv-data-test]="cvDataTest"
  [suppressDragLeaveHidesColumns]="true"
  [suppressPropertyNamesCheck]="true"
  [suppressCopyRowsToClipboard]="true"
  [enableRangeSelection]="true"
  (filterChanged)="onFilterChanged($event)"
  [keepDetailRows]="true"
  [getContextMenuItems]="getContextMenuItems"
  [processCellForClipboard]="processCellForClipboard"
  [getRowId]="getRowId"
  [suppressBrowserResizeObserver]="true"
  [suppressMultiSort]="suppressMultiSort"
>
</ag-grid-angular>
<app-pagination (pageSizeChanged)="pageSizeChanged($event)" [gridApi]="gridApi" [pageSize]="'' + paginationPageSize"></app-pagination>

/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core'
import { ICellRendererParams } from '@ag-grid-community/core'

interface RolesDescriptionMapping {
    [key: string]: string;
}

@Component({
   selector: 'app-roles-cell',
   template: `
    <mat-chip-listbox aria-label="roles" hideSingleSelectionIndicator [selectable]="false">
        <mat-chip-option *ngFor="let i of roles"
            [tooltip]="i.description | translate"
            selected="{{i.selected}}"
            color="{{i.name === 'TenantAdmin' ? 'accent' : 'primary'}}"
            >
                {{i.name}}
        </mat-chip-option>
    </mat-chip-listbox>`,
    styleUrls: ['./roles-cell-renderer.component.sass']
})

export class RolesCellRendererComponent implements AfterViewChecked {
    public rolesDescription: RolesDescriptionMapping = {
        TenantAdmin: 'administration_TenantAdminRoleDescription',
        GlobalStats: 'administration_GlobalStatsRoleDescription',
        GlobalLicenses: 'administration_GlobalLicensesRoleDescription',
        Management: 'administration_ManagementRoleDescription',
        WorkflowPublisher: 'administration_WorkflowPublisherRoleDescription',
        WorkflowEditor: 'administration_WorkflowEditorRoleDescription',
        Audit: 'administration_AuditRoleDescription',
        CoreLearningAdmin: 'administration_CoreLearningAdminRoleDescription',
        SupportChat: 'administration_SupportChatRoleDescription',
        Feedback: 'administration_FeedbackRoleDescription',
        PortalAdmin: 'administration_PortalAdminRoleDescription',
        Beta: 'administration_BetaRoleDescription',
    }
    public roles: any[] = [];
    public selectedRole = '';

    constructor(private cdRef: ChangeDetectorRef) {}

    ngAfterViewChecked() {
        //explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
        this.cdRef.detectChanges()
    }

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
       this.roles = this.formatRoles(params.value)
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams) {
        // set value into cell again
        this.roles = this.formatRoles(params.value)
    }

    formatRoles(roles: string[]): any[] {
        return roles.map((r: string) => ({
            name: r,
            description: this.rolesDescription[r] || '',
            selected: r === 'TenantAdmin'
        }))
    }

    setSelectedRole(role: any) {
        role.selected = true
    }
}

<div class="datagrid-actions-wrapper">
  <div *ngIf="pivotModeEnabled" style="margin-top: 6px; z-index: 1; margin-left: 8px;" [style.height]="actions.length ? '36px' : 'auto'">
    <cv-switch (checkedChange)="onPivotModeChange($event)" [value]="pivotMode" [attr.cv-data-test]="'pivot-switch'">{{
      'reports_PivotMode' | translate
    }}</cv-switch>
  </div>

  <ng-content select="[expandCollapse]"></ng-content>

  <!-- Columns -->
  <app-columns-selector
    [class.hidden]="!(!hideColumnsSelector && (!pivotMode || !pivotModeEnabled))"
    [columnDefs]="columnDefs"
    [defaultCols]="defaultCols"
    [columnApi]="columnApi"
    [refresh]="refresh"
    [maxSelectableColumns]="maxSelectableColumns"
    [entityName]="entityName"
    [selectedColumnsChanged$]="selectedColumnsChanged$"
    [lockedColumns]="lockedColumns"
    [filters]="filters"
    (visibleColumnChanged)="onVisibleColumnChanged($event)"
  ></app-columns-selector>

  <div class="d-fl" [style.height]="actions.length ? '36px' : 'auto'">
    <ng-container *ngFor="let action of filterActions">
      <cv-button
        *ngIf="!action.isMenuButton"
        [disabled]="action.disabled || false"
        [style]="action.style || 'margin: 0px 5px'"
        [text]="action.text | translate"
        [buttonType]="action.buttonType || 'primary'"
        [leftIcon]="action.icon || null"
        [rightIcon]="action.rightIcon || null"
        [leftClassIcon]="action.classIcon || null"
        [leftIconStyle]="action.leftClassStyle || 'filled'"
        [color]="action.color || 'info'"
        (click)="action.onClick(selectedRows, null)"
        [class.hidden]="
          (action.visibility === 'custom' && action.isVisible && !action.isVisible([])) ||
          (pivotMode && !action.isPivotAction && pivotModeEnabled)
        "
        [attr.cv-data-test]="!!action.cvDataTest ? action.cvDataTest : null"
      ></cv-button>
      <mat-icon
        class="info-icon material-icons-outlined"
        *ngIf="action.tooltip"
        tooltip="{{ action.tooltip | translate }}"
        placement="top"
        >info</mat-icon
      >
      <span style="line-height: 37px" *ngIf="action.showSelectionText">{{ action.text | translate }}</span>
      <cv-button-menu
        *ngIf="action.isMenuButton && (!action.showSelectionText || (action.options && action.options.length > 0))"
        [options]="action.options || []"
        [style]="action.style || 'margin: 0px 5px'"
        [text]="action.text | translate"
        [showSelectionText]="action.showSelectionText || false"
        [buttonType]="action.buttonType || 'primary'"
        [leftIcon]="action.icon || null"
        [leftClassIcon]="action.classIcon || null"
        [color]="action.color || 'info'"
        (buttonClick)="onFilterActionsClick(action, gridApi.getSelectedRows(), $event)"
        [class.hidden]="
          (action.visibility === 'custom' && action.isVisible && !action.isVisible([])) ||
          (pivotMode && !action.isPivotAction && pivotModeEnabled)
        "
        [attr.cv-data-test]="!!action.cvDataTest ? action.cvDataTest : null"
      >
      </cv-button-menu>
    </ng-container>

    <ng-container *ngFor="let filter of externalFilters">
      <cv-button-menu
        [options]="filter.enum"
        [text]="filter.title | translate"
        [buttonType]="'tertiary'"
        [leftIcon]="filter.icon || ''"
        style="margin: 0px 5px"
        [class.hidden]="pivotMode && pivotModeEnabled"
        (buttonClick)="onExternalFilterClick(filter, $event)"
      >
      </cv-button-menu>
    </ng-container>

    <ng-content select="[extraFilters]"></ng-content>
  </div>
  <div *ngIf="enableAnonymousData" style="margin-top: 6px">
    <cv-switch
      (checkedChange)="onAnonymousDataModeChange($event)"
      [value]="showAnonymousData"
      [attr.cv-data-test]="'anonymousData-switch'"
      >{{ 'reports_AnonymousData' | translate }}</cv-switch
    >
  </div>

  <div style="display: flex; flex: 1; justify-content: flex-end" [style.height]="actions.length || groupedActions.length ? '36px' : 'auto'">
    <ng-container *ngFor="let action of actions">
      <cv-button
        *ngIf="!action.isMenuButton"
        [disabled]="action.disabled || false"
        [style]="action.style || 'margin: 0px 5px'"
        [text]="action.text | translate"
        [buttonType]="action.buttonType || 'primary'"
        [leftIcon]="action.icon || null"
        [rightIcon]="action.rightIcon || null"
        [leftClassIcon]="action.classIcon || null"
        [leftIconStyle]="action.leftClassStyle || 'filled'"
        [color]="action.color || 'info'"
        (click)="action.onClick(selectedRows, null, gridApi)"
        [class.hidden]="isActionHidden(action)"
        [attr.cv-data-test]="!!action.cvDataTest ? action.cvDataTest : null"
      ></cv-button>
      <mat-icon
        class="info-icon material-icons-outlined"
        *ngIf="action.tooltip"
        tooltip="{{ action.tooltip | translate }}"
        placement="top"
        >info</mat-icon
      >
      <cv-button-menu
        *ngIf="action.isMenuButton"
        [options]="action.options || []"
        [style]="action.style || 'margin: 0px 5px'"
        [text]="action.text | translate"
        [buttonType]="action.buttonType || 'primary'"
        [leftIcon]="action.icon || null"
        [leftClassIcon]="action.classIcon || null"
        [color]="action.color || 'info'"
        (buttonClick)="action.onClick(selectedRows, $event)"
        [class.hidden]="isActionHidden(action)"
        [attr.cv-data-test]="!!action.cvDataTest ? action.cvDataTest : null"
      >
      </cv-button-menu>
    </ng-container>

    <cv-button
      *ngIf="someGroupedActionVisible()"
      [matMenuTriggerFor]="menu"
      (click)="$event.stopPropagation()"
      [buttonType]="'tertiary'"
      [attr.cv-data-test]="'icon-menu-trigger'"
      [leftIcon]="'more_horizontal'"
      [color]="'info'"
      [text]="'common_Actions' | translate"
    ></cv-button>

    <mat-menu #menu="matMenu" yPosition="below" class="icon-menu">
      <ng-container *ngFor="let action of groupedActions; let i = index">
        <div
          *ngIf="action.isMenuButton"
          mat-menu-item
          [matMenuTriggerFor]="action.disabled ? null : subMenu"
          class="menu-item datagrid-actions-menu-item"
          [disabled]="action.disabled || false"
          [class.hidden]="isActionHidden(action)"
          [attr.cv-data-test]="!!action.cvDataTest ? action.cvDataTest : null"
        >
          <mat-icon *ngIf="action.icon" color="primary">{{ action.icon }}</mat-icon>
          <span translate>{{ action.text }}
            <mat-icon
              class="info-icon material-icons-outlined"
              *ngIf="action.tooltip"
              tooltip="{{ action.tooltip | translate }}"
              placement="top"
              >info</mat-icon
            >
          </span>
        </div>
        <div
          *ngIf="!action.isMenuButton"
          mat-menu-item
          class="menu-item datagrid-actions-menu-item"
          [class.hidden]="isActionHidden(action)"
          [disabled]="action.disabled || false"
          (click)="action.disabled ? null : action.onClick(selectedRows, null, gridApi)"
          [attr.cv-data-test]="!!action.cvDataTest ? action.cvDataTest : null"
        >
          <mat-icon *ngIf="action.icon" color="primary">{{ action.icon }}</mat-icon>
          <span translate>{{ action.text }}
            <mat-icon
              class="info-icon material-icons-outlined"
              *ngIf="action.tooltip"
              tooltip="{{ action.tooltip | translate }}"
              placement="top"
              >info</mat-icon
            >
          </span>
        </div>
        <mat-menu #subMenu="matMenu" yPosition="below">
          <div mat-menu-item class="submenu datagrid-actions-menu-item" (click)="action.onClick(selectedRows, o.key)" *ngFor="let o of action.options">
            <mat-icon *ngIf="o.icon">{{ o.icon }}</mat-icon>
            <span>{{ o.text }}</span>
          </div>
        </mat-menu>
      </ng-container>
    </mat-menu>
  </div>
  <!-- Actions defined on some specific pages -->
  <ng-content select="[toolbar]"></ng-content>
</div>

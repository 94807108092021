import { createReducer, on } from "@ngrx/store"
import { addReportFilter } from "./report-filters.actions"
import { ReportsFiltersState } from "./report-filters.types"

export const initialState: ReportsFiltersState = { isGlobalNull: true }

export const reportFilterReduce = createReducer(
    initialState,
    on(addReportFilter, (state, reportFilter) => { 
        return {isGlobalNull: !reportFilter, filter: reportFilter.filter}
    } )
  )

export default reportFilterReduce
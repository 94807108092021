import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { TranslateHelper } from '@coreview/coreview-library'


@Component({
  selector: 'app-exception-dialog',
  templateUrl: './exception-dialog.component.html',
  styleUrls: ['./exception-dialog.component.sass'],
})
export class ExceptionDialogComponent implements OnInit {

  exception!: any
  form!: UntypedFormGroup;
  constructor( 
    private matDialogRef: MatDialogRef<ExceptionDialogComponent>,
    private translateHelper: TranslateHelper,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { exception: any}) {   
      this.exception = dialogData.exception   
    }


  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      isNeverExpire: new UntypedFormControl(this.exception.isNeverExpire),
      exceptionExpirationDate: new UntypedFormControl(this.exception.exceptionExpirationDate),
      exceptionNotes: new UntypedFormControl(this.exception.exceptionNotes)
    });

    this.form.disable()
  }

  close() {
    this.matDialogRef?.close()
  }

  manageException() {   
    this.matDialogRef?.close(this.exception)
  } 
}



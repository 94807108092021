import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { getPlaybookItems, getPlaybookItemsResponse, getPlaybookItemsInvalidate } from '@app/store/playbooks/playbooks.actions'
import { catchError, mergeMap, map } from 'rxjs/operators'
import { combineLatest, of } from 'rxjs'
import { PlaybookService } from '@app/modules/playbook/services/playbook.service'
import { Playbook, Policy } from '@app/core/models/playbook'
import { MenuService } from '@app/core/services/menu.service'

@Injectable()
export class PlaybooksEffects {
  getPlaybookItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPlaybookItems),
      mergeMap(() =>
        combineLatest([this.menuService.getPlaybookItems(), this.playbookService.getPolicies()]).pipe(
          map(([playbooks, policies]) => {
            const playbookActivePolicies = this.createPlaybookAssociatedPolicies(playbooks, policies)
            return getPlaybookItemsResponse({ playbooks, playbooksAssociatedPolicies: playbookActivePolicies })
          }),
          catchError((error) => of(getPlaybookItemsInvalidate({ error: { code: error.status, message: error.message }})))
        )
      )
    )
  )

  constructor(private actions$: Actions, private menuService: MenuService, private playbookService: PlaybookService) {}

  createPlaybookAssociatedPolicies = (playbooks: Playbook[], policies: Policy[]) => {
    const records: Record<string, Policy[]> = {}
    playbooks.forEach((playbook) => {
      records[playbook.id] = policies.filter((p) => p.playbookIds?.includes(playbook.id))
    })
    return records
  }
}

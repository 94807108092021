import { ICellEditorAngularComp } from '@ag-grid-community/angular'
import { ICellEditorParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

@Component({
  selector: 'app-editable-cell-renderer',
  templateUrl: './editable-cell-renderer.component.html',
  styleUrls: ['./editable-cell-renderer.component.sass'],
})
export class EditableCellRendererComponent implements ICellEditorAngularComp {
  value!: any
  private params: any

  agInit(params: ICellEditorParams): void {
    this.params = params
    this.value = params.value
  }

  onClick() {
    this.params.api.stopEditing(true)
    this.params.api.startEditingCell({
      rowIndex: this.params.rowIndex,
      colKey: this.params.column!.getId(),
    })
  }

  getValue(): any {
    return this.value
  }
}

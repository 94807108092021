import { Injectable } from '@angular/core'
import { PolicyScheduleReport, ScheduleDetails } from '@app/core/models/playbook'
import { Suggestion } from '@coreview/coreview-components'
import dayjs from 'dayjs'
import { TranslateHelper } from '@coreview/coreview-library'
/* ' ' + this.translate(this.convertDayOfMonth(cronData.selectedDayMonth)) */
@Injectable({ providedIn: 'root' })
export class CronExpressionHelper {
  recurrenceOptionsWeekly: Suggestion[] = [
    { value: 'MON', displayValue: this.translateHelper.instant('common_Monday') },
    { value: 'TUE', displayValue: this.translateHelper.instant('common_Tuesday') },
    { value: 'WED', displayValue: this.translateHelper.instant('common_Wednesday') },
    { value: 'THU', displayValue: this.translateHelper.instant('common_Thursday') },
    { value: 'FRI', displayValue: this.translateHelper.instant('common_Friday') },
    { value: 'SAT', displayValue: this.translateHelper.instant('common_Saturday') },
    { value: 'SUN', displayValue: this.translateHelper.instant('common_Sunday') },
  ]

  constructor(private translateHelper: TranslateHelper) {}

  getSchedulingString(schedule?: ScheduleDetails | null): string {
    if (!schedule || !schedule.cronExpression) {
      return ''
    }
    const cronData: CronData = this.extractDataFromCronExpression(schedule.cronExpression)
    return this.getScheduleRecurrenceString(cronData, schedule.startDatetime, schedule?.endDatetime)
  }

  getScheduleReportString(cronExpression: string, scheduleReport?: PolicyScheduleReport | null, ): string {
    if (!scheduleReport || !cronExpression) {
      return ''
    }
    const cronData: CronData = this.extractDataFromCronExpression(cronExpression)
    return this.getScheduleRecurrenceString(cronData, scheduleReport.startTime ?? new Date(), scheduleReport?.endTime)
  }

  getRecurringString(cronExpression: string): string {
    const cronData = this.extractDataFromCronExpression(cronExpression)
    return `${this.translate('common_Every')}${
      (cronData.selectedHour || cronData.selectedDay || cronData.selectedMonthYear || cronData.selectedMonth ? ' ' : '') +
      (cronData.selectedHour || cronData.selectedDay || cronData.selectedMonthYear || cronData.selectedMonth || '')
    } ${this.translate(
      this.convertInterval(
        cronData.selectedInterval,
        cronData.selectedHour || cronData.selectedDay || cronData.selectedWeek || cronData.selectedMonthYear
      )
    ).toLowerCase()},${
      ['monthly', 'weekly'].includes(cronData.selectedInterval)
        ? cronData.selectedInterval === 'weekly'
          ? ' ' + this.translate('common_On').toLowerCase() + ' ' + this.convertSelectedWeek(cronData.selectedDayWeek)
          : cronData.selectedInterval === 'monthly' && cronData.selectedRepetition === 'dayMonth'
          ? ' ' + this.translate(this.convertDayOfMonth(cronData.selectedDayMonth))
          : cronData.selectedInterval === 'monthly' && cronData.selectedRepetition === 'daysOfWeek'
          ? ' ' +
            this.translate('common_The') +
            ' ' +
            this.getOrdinalOutNumber(cronData.selectedOrdinal) +
            ' ' +
            this.getNameOfWeekOutNumber(cronData.selectedDayWeekMonth)
          : cronData.selectedInterval === 'monthly' && cronData.selectedRepetition === 'lastDay'
          ? this.translate('common_The') + ' ' + this.translate('common_LastDayOfMonth') + ''
          : ''
        : ''
    }`
  }

  private getScheduleRecurrenceString(cronData: CronData, startDate: Date, endDate?: Date | null) {
    return `${this.translate('common_Every')}${
      (cronData.selectedHour || cronData.selectedDay || cronData.selectedMonthYear || cronData.selectedMonth ? ' ' : '') +
      (cronData.selectedHour || cronData.selectedDay || cronData.selectedMonthYear || cronData.selectedMonth || '')
    } ${this.translate(
      this.convertInterval(
        cronData.selectedInterval,
        cronData.selectedHour || cronData.selectedDay || cronData.selectedWeek || cronData.selectedMonthYear
      )
    ).toLowerCase()},${
      ['monthly', 'weekly'].includes(cronData.selectedInterval)
        ? cronData.selectedInterval === 'weekly'
          ? ' ' + this.translate('common_On').toLowerCase() + ' ' + this.convertSelectedWeek(cronData.selectedDayWeek)
          : cronData.selectedInterval === 'monthly' && cronData.selectedRepetition === 'dayMonth'
          ? ' ' + this.translate(this.convertDayOfMonth(cronData.selectedDayMonth))
          : cronData.selectedInterval === 'monthly' && cronData.selectedRepetition === 'daysOfWeek'
          ? ' ' +
            this.translate('common_The') +
            ' ' +
            this.getOrdinalOutNumber(cronData.selectedOrdinal) +
            ' ' +
            this.getNameOfWeekOutNumber(cronData.selectedDayWeekMonth)
          : cronData.selectedInterval === 'monthly' && cronData.selectedRepetition === 'lastDay'
          ? this.translate('common_The') + ' ' + this.translate('common_LastDayOfMonth') + ''
          : ''
        : ''
    } ${this.translate('common_StartingOn').toLowerCase() + ' ' + dayjs(startDate)?.utc().format('L hh:mm A UTC Z')} ${
      endDate!== null && endDate !== undefined ? this.translate('common_And').toLowerCase() : ''
    } ${
      endDate !== null && endDate !== undefined
        ? this.translate('common_EndingOn').toLowerCase() + ' ' + dayjs(endDate)?.utc().format('L hh:mm A UTC Z')
        : ''
    }`
  }

  private convertSelectedWeek(weeks: string) {
    return this.recurrenceOptionsWeekly.find((x) => x.value === weeks)?.displayValue
  }

  private convertInterval(interval: string, value: string) {
    switch (interval) {
      case 'hourly':
        return value === '1' ? 'common_Hour' : 'common_Hours'
      case 'daily':
        return value === '1' ? 'common_Day' : 'common_Days'
      case 'weekly':
        return value === '1' ? 'common_Week' : 'common_Weeks'
      case 'monthly':
        return value === '1' ? 'common_Month' : 'common_Months'
    }
    return ''
  }

  private convertDayOfMonth(value: string): any {
    return value
      .split(',')
      .map((numVal) => {
        const numericVal = Number(numVal)
        if (isNaN(numericVal)) {
          return ''
        }
        if (numericVal >= 10 && numericVal <= 20) {
          return 'common_The' + numericVal + 'th'
        }
        return (
          'common_The' +
          numVal +
          (numVal.substring(numVal.length - 1) === '1'
            ? 'st'
            : numVal.substring(numVal.length - 1) === '2'
            ? 'nd'
            : numVal.substring(numVal.length - 1) === '3'
            ? 'rd'
            : 'th')
        )
      })
      .filter((item) => !!item)
      .join(', ')
  }

  /*
  0 0 0/5 ? * *
  */

  private extractDataFromCronExpression(cronExpression: string) {
    let selectedInterval = ''
    let selectedRepetition = ''
    let selectedOrdinal = ''
    let selectedHour = ''
    let selectedDay = ''
    let selectedWeek = ''
    let selectedDayWeek = ''
    let selectedDayWeekMonth = ''
    let selectedMonth = ''
    let selectedDayMonth = ''
    let selectedMonthYear = ''

    const segments = cronExpression?.split(' ') || []
    if (segments.length === 6 || segments.length === 7) {
      const [seconds, minutes, hours, dayOfMonth, month, dayOfWeek, year] = segments
      if (dayOfMonth === '?' && dayOfWeek !== '*') {
        selectedInterval = 'weekly'
        selectedDayWeek = dayOfWeek
        selectedWeek = '1'
      } else if (hours.includes('/')) {
        selectedInterval = 'hourly'
        selectedHour = hours.substring(hours.indexOf('/') + 1)
      } else if (dayOfMonth.includes('/')) {
        selectedInterval = 'daily'
        selectedDay = dayOfMonth.substring(dayOfMonth.indexOf('/') + 1)
      } else {
        selectedInterval = 'monthly'
        selectedRepetition = 'dayMonth'
        selectedDayMonth = dayOfMonth
        if (month.includes('/')) {
          selectedMonth = month.substring(month.indexOf('/') + 1)
        } else {
          selectedMonthYear = month
        }
      }

      if (dayOfWeek.includes('#')) {
        selectedInterval = 'monthly'
        selectedRepetition = 'daysOfWeek'
        selectedOrdinal = dayOfWeek.substring(dayOfWeek.indexOf('#') + 1)
        selectedDayWeekMonth = dayOfWeek.charAt(0)
        if (month.includes('/')) {
          selectedMonth = month.substring(month.indexOf('/') + 1)
        } else {
          selectedMonthYear = month
        }
      }
      if (dayOfMonth.includes('L')) {
        selectedInterval = 'monthly'
        selectedRepetition = 'lastDay'
        if (month.includes('/')) {
          selectedMonth = month.substring(month.indexOf('/') + 1)
        } else {
          selectedMonthYear = month
        }
      }
    }

    return {
      selectedInterval,
      selectedRepetition,
      selectedHour,
      selectedDay,
      selectedOrdinal,
      selectedDayMonth,
      selectedWeek,
      selectedDayWeek,
      selectedDayWeekMonth,
      selectedMonthYear,
      selectedMonth,
    }
  }

  private translate(text: string): string {
    return this.translateHelper.instant(text || '') || ''
  }

  private getOrdinalOutNumber = (val: string) => {
    switch (val) {
      case '1':
        return this.translateHelper.instant('common_First')
      case '2':
        return this.translateHelper.instant('common_Second')
      case '3':
        return this.translateHelper.instant('common_Third')
      case '4':
        return this.translateHelper.instant('common_Fourth')
      case 'L':
        return this.translateHelper.instant('common_Last')
    }
    return ''
  }

  private getNameOfWeekOutNumber = (val: string) => {
    switch (val) {
      case '0':
        return this.translateHelper.instant('common_Monday')
      case '1':
        return this.translateHelper.instant('common_Tuesday')
      case '2':
        return this.translateHelper.instant('common_Wednesday')
      case '3':
        return this.translateHelper.instant('common_Thursday')
      case '4':
        return this.translateHelper.instant('common_Friday')
      case '5':
        return this.translateHelper.instant('common_Saturday')
      case '6':
        return this.translateHelper.instant('common_Sunday')
    }
    return ''
  }
}
export type CronData = {
  selectedInterval: string;
  selectedRepetition: string;
  selectedHour: string;
  selectedDay: string;
  selectedOrdinal: string;
  selectedDayMonth: string;
  selectedWeek: string;
  selectedDayWeek: string;
  selectedDayWeekMonth: string;
  selectedMonthYear: string;
  selectedMonth: string;
}

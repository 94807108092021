/* eslint-disable @typescript-eslint/member-delimiter-style */
import { Component, OnInit, ViewChild, Input, EventEmitter, Output, OnChanges } from '@angular/core'

import { ReportDefinition, SelectionAction } from '@core/models/ReportDefinition'
import { WorkflowHttpService } from '../../../../http/workflow.http'
// eslint-disable-next-line max-len
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { of } from 'rxjs'
import { Constants, WorkflowExecutionStatus } from '@app/shared/utilities/constants'
import { WorkflowExecutionListService } from '../../services/workflow-executions-list.service'
import { SecurityService } from '@app/modules/workflow/services/security.service'
import { Workflow } from '@app/modules/workflow/models/workflow.model'
import { isValidRestartWorkflowExecution, isValidStopWorkflowExecution } from '@app/modules/workflow/models/workflow.definition'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'
import { CellClickedEvent } from '@ag-grid-community/core'
import { uniq } from 'lodash-es'

type ExecutionStateCustomFilter = { key: string; text: string; icon?: string }

@Component({
  selector: 'app-workflow-executions-v2',
  templateUrl: './workflow-executions-v2.component.html',
  styleUrls: ['./workflow-executions-v2.component.sass'],
})
export class WorkflowExecutionsComponentV2 implements OnInit, OnChanges {
  @ViewChild(DatagridComponent) grid!: DatagridComponent

  @Input() workflowId: any
  @Input() startingStatus!: string | null
  @Input() serviceTag!: string
  @Input() showFullScreenButton = false
  @Input() hideStatusFilter = false
  @Input() showStopAllAction = false
  @Output() openInFullChange = new EventEmitter<boolean>()

  private currentPage = 0
  private executionsPage: any[] = []

  reportDefinition: ReportDefinition = {
    title: 'notused',
    fields: ['Id', 'Status', 'Duration', 'ExecutedBy', 'StartAt', 'StopAt'],
    lockedColumns: ['Id', 'Status'],
    defaultHiddenFields: [],
    sortField: 'StartAt',
    sortOrder: 'desc',
    verb: 'post' as Verb,
    isOnlineUsersType: false,
    responseItemProp: 'executions',
    url: 'api/v2/executions/',
    sortedFields: ['StartAt'],
    gridOptions: {
      suppressRowClickSelection: true,
      onCellClicked: (e: CellClickedEvent) => {
        if (e.column.getColId() !== 'id') {
          e.node.setSelected(!e.node.isSelected())
        }
      },
    },
  }

  public currentStateFilter: ExecutionStateCustomFilter = this.workflowExecutionListService.stateOptions[0]
  public panelRef: any

  private isOpenInFull = false
  private hideActionColumn = false
  constructor(
    private workflowHttp: WorkflowHttpService,
    private workflowExecutionListService: WorkflowExecutionListService,
    private securityService: SecurityService
  ) {}

  ngOnInit(): void {
    this.hideActionColumn = !(
      this.securityService.canView() ||
      this.securityService.canExportWorkflow() ||
      this.securityService.canExecuteRetryStopWorkflow()
    )
    this.setCurrentStateFilter(this.startingStatus)
    if (this.hideStatusFilter) this.reportDefinition.fields = ['Id', 'WorkflowId', 'WorkflowName', 'ExecutedBy']

    this.reportDefinition.operationsColumns = this.getOperationsColumns()
    if (this.showFullScreenButton) this.reportDefinition.filterActions = this.generateFilterActions()
  }

  ngOnChanges(): void {
    if (this.grid) {
      this.grid.clearAndRefresh()
      this.generateSelectionActions()
    }
  }

  getItems = (params: ApiDataParameters) => {
    let url = this.reportDefinition?.url
    if (this.workflowId) {
      url += this.workflowId
    }
    let filter: any = {}
    if (params.filters) {
      filter = params.filters
    }

    if (this.startingStatus!!) {
      filter.Status = '=' + this.startingStatus
    } else {
      if ('Status' in filter) {
        delete filter.Status
      }
    }

    if (this.serviceTag!!) {
      filter.ServiceTags = '=' + this.serviceTag
    }

    params.filters = filter

    return {
      items: this.workflowHttp.getDataWtihMetadata(url!, this.reportDefinition?.verb, params),
      cols: of([]),
    }
  }

  onPageDataChanged(event: { currentPage: number; currentPageSize: number; data: any[] }): void {
    this.currentPage = event.currentPage
    this.executionsPage = event.data
  }

  public getOperationsColumns() {
    return [
      ...(this.reportDefinition.operationsColumns || []),
      {
        name: 'id',
        type: 'string',
        agColDef: {
          cellRenderer: 'customLinkRendererComponent',
          cellRendererParams: {
            onClick: (event: any) => {
              this.panelRef = this.workflowExecutionListService.handleExecutionDetails(event.rowData, this.currentPage, this.executionsPage)
            },
          },
          hide: false,
        },
      },
      {
        name: 'status',
        type: 'string',
        agColDef: {
          cellRenderer: 'badgeRenderer',
          cellRendererParams: {
            getVariant: (value: string) =>
              Constants.Workflows.statusVariantMap[value as WorkflowExecutionStatus] ?? Constants.Workflows.statusVariantMap.NotStarted,
            getIcon: (value: string) =>
              Constants.Workflows.statusIconMap[value as WorkflowExecutionStatus] ?? Constants.Workflows.statusIconMap.NotStarted,
          },
          hide: false,
        },
      },
      {
        name: 'duration',
        type: 'number',
        agColDef: {
          valueGetter: (params: any) => params.data.duration * 1000,
        },
      },
      {
        type: 'undefined',
        name: 'actions',
        position: 100,
        notSelectable: true,
        translate: 'common_Actions',
        originalName: 'common_Actions',
        agColDef: {
          ...Constants.defaultActionColumnsDefinition,
          hide: this.hideActionColumn,
          suppressToolPanel: this.hideActionColumn,
          cellRendererParams: {
            onClick: (event: any) => {
              switch (event.key) {
                case 'export':
                  this.workflowExecutionListService.handleExportJson(this.workflowId, event.rowData)
                  break
                case 'executionDetails':
                  this.panelRef = this.workflowExecutionListService.handleExecutionDetails(
                    event.rowData,
                    this.currentPage,
                    this.executionsPage
                  )
                  break
                case 'stop':
                  this.workflowExecutionListService.handleBulkStopWorkflowExecutionCommand([event.rowData], this.grid)
                  break
                case 'restart':
                  this.workflowExecutionListService.handleBulkRestartWorkflowExecutionCommand([event.rowData], this.grid)
                  break
              }
            },
            icon: 'more_horiz',
            optionsFunction: (data: any) => this.workflowExecutionListService.getActionMenu(data),
          },
        },
      },
    ]
  }

  public setStatusFilter(key: string | null): void {
    if (key && key != 'All') {
      this.startingStatus = key
    } else {
      this.startingStatus = null
    }

    this.setCurrentStateFilter(key)
  }

  public setCurrentStateFilter(key: string | null) {
    const item = this.workflowExecutionListService.stateOptions.find((option) => option.key === key)
    if (item) {
      this.currentStateFilter = item
    } else {
      this.currentStateFilter = this.workflowExecutionListService.stateOptions[0]
    }

    this.reportDefinition.selectionActions = this.generateSelectionActions()
  }

  public generateSelectionActions(): SelectionAction[] {
    let selectionActions: SelectionAction[] = [
      {
        text: 'common_Refresh',
        buttonType: 'tertiary',
        icon: 'autorenew',
        visibility: 'noRow',
        onClick: () => {
          this.grid.refresh()
        },
      },
    ]
    if (this.showStopAllAction) {
      selectionActions.push({
        text: 'workflow_StopAllInQueueByWorkflow',
        buttonType: 'tertiary',
        icon: 'close',
        visibility: 'custom',
        isVisible: (selectedRows) => uniq(selectedRows.map((x) => x.workflowId)).length === 1,
        onClick: (data: any[], key: string | null) => {
          this.workflowExecutionListService.handleAllStopWorkflowInQueueExecutionCommand(data[0].workflowId, this.grid)
        },
      })
    }

    if (!this.hideStatusFilter) {
      selectionActions.push({
        text: this.currentStateFilter.text,
        buttonType: 'tertiary',
        isMenuButton: true,
        options: this.workflowExecutionListService.stateOptions,
        icon: 'filter_alt',
        visibility: 'noRow',
        onClick: (data: any[], key: string | null) => {
          this.setStatusFilter(key)
          this.grid.refresh()
        },
      })
    }

    if (this.securityService.canExecuteRetryStopWorkflow()) {
      selectionActions = selectionActions.concat([
        {
          text: 'workflow_StopExecutions',
          buttonType: 'tertiary',
          icon: 'close',
          visibility: 'multiRow',
          filterRows: (data: any[]) => data.some((row) => isValidStopWorkflowExecution(row.status)),
          onClick: (items: Workflow.Dto.V1.WorkflowExecutions.Item[]) => {
            this.workflowExecutionListService.handleBulkStopWorkflowExecutionCommand(items, this.grid)
          },
        },
        {
          text: 'workflow_RestartExecutions',
          buttonType: 'tertiary',
          icon: 'restart_alt',
          visibility: 'multiRow',
          filterRows: (data: any[]) => data.some((row) => isValidRestartWorkflowExecution(row.status)),
          onClick: (items: Workflow.Dto.V1.WorkflowExecutions.Item[]) => {
            this.workflowExecutionListService.handleBulkRestartWorkflowExecutionCommand(items, this.grid)
          },
        },
      ])
    }

    return selectionActions
  }

  private generateFilterActions(): SelectionAction[] {
    return [
      {
        text: 'common_OpenInFull',
        buttonType: 'tertiary',
        icon: 'open_in_full',
        classIcon: 'mat-icon-no-color',
        leftClassStyle: 'outlined',
        isVisible: () => !this.isOpenInFull,
        onClick: () => {
          this.isOpenInFull = !this.isOpenInFull
          this.openInFullChange.emit(this.isOpenInFull)
        },
        visibility: 'custom',
        cvDataTest: 'open-full-mode',
      },
      {
        text: 'common_CloseFullscreen',
        buttonType: 'tertiary',
        icon: 'close_fullscreen',
        classIcon: 'mat-icon-no-color',
        leftClassStyle: 'outlined',
        isVisible: () => this.isOpenInFull,
        onClick: () => {
          this.isOpenInFull = !this.isOpenInFull
          this.openInFullChange.emit(this.isOpenInFull)
        },
        visibility: 'custom',
        cvDataTest: 'close-full-mode',
      },
    ]
  }
}

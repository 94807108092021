<app-loading-report-skeleton *ngIf="!loadedFirstTime"></app-loading-report-skeleton>

<div class="wf-panel-container" [style.display]="loadedFirstTime ? 'flex' : 'none'">
  <div class="right-panel">
    <div class="content-container">
      <div class="panel-title-container">
        <div class="wf-title-container">
          <div class="panel-title uppercase wf-title" translate>workflow_ExecutionDetail</div>
          <div class="wf-navigation" *ngIf="executionsPage.length > 0">
            <cv-icon-button
              [disabled]="executionIndex === 0"
              icon="arrow_upward"
              buttonType="tertiary"
              size="tiny"
              class="wf-navigation-button"
              (click)="goToPreviousExecution()"
            >
            </cv-icon-button>
            <cv-icon-button
              [disabled]="executionIndex >= executionsPage.length - 1"
              icon="arrow_downward"
              buttonType="tertiary"
              size="tiny"
              class="wf-navigation-button"
              (click)="goToNextExecution()"
            >
            </cv-icon-button>
            <span style="font-size: 16px; margin-left: 8px"
              >{{ executionIndex + 1 }} {{ 'of' | translate }} {{ executionsPage.length }}&nbsp;|&nbsp;{{ 'Page' | translate }}
              {{ executionsCurrentPage + 1 }}
            </span>

            <mat-icon class="info-icon material-icons-outlined" tooltip="{{ 'workflow_ExecutionDetailInfo' | translate }}">info </mat-icon>
          </div>
        </div>

        <div class="panel-subtitle" style="display: flex">
          <app-execution-status-badge
            style="margin: auto 0"
            [status]="executionDetails?.status || 'NotStarted'"
          ></app-execution-status-badge>

          <cv-button
            style="margin-left: 5px"
            (click)="refreshData()"
            leftIcon="refresh"
            buttonType="tertiary"
            text="{{ 'common_Refresh' | translate }}"
          ></cv-button>
        </div>
        <div style="display: flex">
          <cv-button
            *ngIf="executionDetails?.originalExecutionId"
            style="margin-top: 5px"
            (click)="loadParentExecutionData()"
            leftIcon="restart_alt"
            buttonType="secondary"
            text="{{ 'workflow_RestartedFrom' | translate }} {{ executionDetails?.originalExecutionId }}"
          ></cv-button>
        </div>
      </div>
      <div class="wf-content-container">
        <div class="wf-executionDetails-summary">
          <div class="label">{{ 'workflow_ExecutionId' | translate }}</div>
          <div class="text">{{ executionDetails?.workflowExecutionId }}</div>
          <div class="label">{{ 'workflow_ExecutedBy' | translate }}</div>
          <div class="text">{{ executionDetails?.executedBy }}</div>
          <div class="label">{{ 'workflow_Overview' | translate }}</div>
          <app-hover-card>
            <mat-icon slot="button" class="wf-executionDetails-icon">description</mat-icon>
            <div slot="card">
              <app-execution-detail [data]="executionDetails" [definitionActions]="getExecutionHistoryActions()"></app-execution-detail>
            </div>
          </app-hover-card>
        </div>

        <!-- executionHistoryEvents -->
        <ng-container *ngIf="executionDetails?.executionHistoryEvents?.length">
          <div
            class="wf-executionDetails-content"
            *ngFor="let event of getEvents(executionDetails?.executionHistoryEvents || []); let i = index"
          >
            <app-execution-event-list
              [showMergeLine]="i < getEvents(executionDetails?.executionHistoryEvents || [])!.length - 1"
              [event]="event"
              [events]="executionDetails?.executionHistoryEvents || []"
              [isCatch]="false"
            >
            </app-execution-event-list>
            <div class="arrow-container" *ngIf="i < getEvents(executionDetails?.executionHistoryEvents || [])!.length - 1">
              <mat-icon class="arrow-icon m-t-10">arrow_downward</mat-icon>
            </div>
          </div>
        </ng-container>

        <div *ngIf="executionDetails?.catchExecutionHistoryEvents?.length" class="wf-executionDetailsCatch-container">
          <span class="wf-executionDetails-catchTitle">{{ 'workflow_ExecutionCatchActions' | translate }}</span>
          <span class="wf-executionDetails-catchTitle-sub">{{ 'workflow_ExecutionCatchActionsMessage' | translate }}</span>
          <!-- catchExecutionHistoryEvents -->
          <div
            class="wf-executionDetails-content"
            *ngFor="let event of getEvents(executionDetails?.catchExecutionHistoryEvents || []); let i = index"
          >
            <app-execution-event-list
              [showMergeLine]="i < getEvents(executionDetails?.catchExecutionHistoryEvents || [])!.length - 1"
              [event]="event"
              [events]="executionDetails?.catchExecutionHistoryEvents || []"
              [isCatch]="true"
            >
            </app-execution-event-list>
            <div class="arrow-container" *ngIf="i < getEvents(executionDetails?.catchExecutionHistoryEvents || [])!.length - 1">
              <mat-icon class="arrow-icon m-t-10">arrow_downward</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

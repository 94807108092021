<div class="panel-title-container">
  <div cv-data-test="panel-title" class="panel-title uppercase">{{ actionTitle | translate }}</div>
  <div cv-data-test="panel-subtitle" class="panel-subtitle">{{ panelSubtitle }}</div>
</div>

<div style="overflow: hidden; display: flex; flex-direction: column; flex: 1; padding: 0px 30px; margin: 0 -20px">
  <div class="loading-summary p-10 p-b-25" *ngIf="!summaryCards.length" style="flex: 1">
    <ng-container *ngFor="let _ of [].constructor(3)">
      <div class="loading-card"></div>
    </ng-container>
  </div>

  <cv-form-builder
    form-builder
    *ngIf="!!jsonFormData && !!initialValuesLoaded"
    [jsonFormData]="jsonFormData"
    [form]="form"
    [singleColumnWidth]="700"
    (formReady)="onFormBuilderReady()"
    [extraData]="selectedTargets"
    [apiBaseUrl]="basePortalApiUrl"
    [class.hidden]="true"
    (eventFired)="onEventFired($event)"
    cv-data-test="view-mode-form"
  ></cv-form-builder>

  <div *ngIf="summaryCards.length" class="p-10 flex-full-height">
    <cv-carousel>
      <app-summary-card
        style="flex: 1"
        cvCarouselContent
        *ngFor="let card of summaryCards"
        [cardInfo]="card"
        attr.cv-data-test="{{ 'mgmt-' + card.title }}"
      ></app-summary-card>
    </cv-carousel>
  </div>
</div>
<div class="footer-container">
  <cv-button
    [text]="'common_Cancel' | translate"
    (click)="cancel()"
    buttonType="secondary"
    leftIcon="close"
    style="float: left"
    class="m-r-10"
    cv-data-test="panel-cancel"
  ></cv-button>
  <cv-button
    *ngIf="canManage()"
    [text]="'common_Edit' | translate"
    (click)="edit()"
    buttonType="primary"
    leftIcon="edit"
    style="float: right"
    cv-data-test="panel-edit"
  ></cv-button>
</div>

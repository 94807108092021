import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

@Component({
  selector: 'app-edit-cell-renderer',
  templateUrl: './edit-cell-renderer.component.html',
  styleUrls: ['./edit-cell-renderer.component.sass'],
})
export class EditCellRendererComponent implements AgRendererComponent {
  public params!: any
  public value!: string

  minValue?: number
  maxValue?: number

  constructor() {}

  refresh(params: ICellRendererParams): boolean {
    this.maxValue = this.params.getMaxValue instanceof Function ? this.params.getMaxValue(this.params.data) : undefined
    this.minValue = this.params.getMinValue instanceof Function ? this.params.getMinValue(this.params.data) : undefined
    return true
  }

  agInit(params: any): void {
    this.params = params
    this.value = params.value
    this.maxValue = params.getMaxValue instanceof Function ? params.getMaxValue(params.data) : undefined
    this.minValue = params.getMinValue instanceof Function ? params.getMinValue(params.data) : undefined
  }

  modelChanged(value: any) {
    this.params.setValue(value)

    this.maxValue = this.params.getMaxValue instanceof Function ? this.params.getMaxValue(this.params.data) : undefined
    this.minValue = this.params.getMinValue instanceof Function ? this.params.getMinValue(this.params.data) : undefined

    if (this.params.onModelChanged instanceof Function) {
      this.params.onModelChanged(this.params)
    }
  }

  isValid() {
    let minValid = true
    let maxValid = true
    if (this.minValue !== undefined) {
      minValid = Number(this.value) >= this.minValue
    }
    if (this.maxValue !== undefined) {
      maxValid = Number(this.value) <= this.maxValue
    }
    return minValid && maxValid
  }
}

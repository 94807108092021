import { Injectable } from '@angular/core'
import { UserSettingsService } from '@app/core/services/user-settings.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { ToastService } from '@coreview/coreview-components'
import { of } from 'rxjs'
import { catchError, map, mergeMap, switchMap, withLatestFrom, concatMap } from 'rxjs/operators'
import {
  getUserSettings,
  getUserSettingsInvalidate,
  getUserSettingsResponse,
  resetUserSettingsExtra,
  saveUserSettings,
  saveUserSettingsInvalidate,
  saveUserSettingsResponse,
  updateUserSettingsExtra,
} from './userSettings.actions'
import { selectUserSettings } from './userSettings.selectors'
import { Store } from '@ngrx/store'
import { UserSettings } from '@coreview/coreview-library/models/user-settings'
import { RootState } from '../RootState.type'
import { convertExtraToLicencePoolState } from '../license-pool-center/license-pool-center.actions'
import { TranslateHelper } from '@coreview/coreview-library'

@Injectable()
export class UserSettingsEffects {
  convertExtraExecuted = false

  getUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSettings),
      mergeMap(() =>
        this.userSettingsService.getUserSettings().pipe(
          concatMap((data) => {
            if (!this.convertExtraExecuted) {
              this.convertExtraExecuted = true
              return [getUserSettingsResponse({ data }), convertExtraToLicencePoolState({ extra: data?.extra })]
            } else {
              return [getUserSettingsResponse({ data })]
            }
          }),
          catchError(this.catchErrorAndNotify(getUserSettingsInvalidate, 'UserSettings'))
        )
      )
    )
  )

  resetUserSettingsExtra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetUserSettingsExtra),
      withLatestFrom(this.store.select(selectUserSettings)),
      switchMap(([action, usState]) => {
        const state = usState as UserSettings
        return this.userSettingsService.saveUserSettings(state).pipe(
          map(() => saveUserSettingsResponse({ data: state })),
          catchError(this.catchErrorAndNotify(saveUserSettingsInvalidate, 'UserSettings'))
        )
      })
    )
  )

  saveUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveUserSettings),
      mergeMap(({ data }) =>
        this.userSettingsService.saveUserSettings(data).pipe(
          map(() => saveUserSettingsResponse({ data })),
          catchError(this.catchErrorAndNotify(saveUserSettingsInvalidate, 'UserSettings'))
        )
      )
    )
  )

  updateUserSettingsExtra$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserSettingsExtra),
      withLatestFrom(this.store.select(selectUserSettings)),
      switchMap(([action, usState]) => {
        const state = usState as UserSettings
        return this.userSettingsService.saveUserSettings(state).pipe(
          map(() => saveUserSettingsResponse({ data: state })),
          catchError(this.catchErrorAndNotify(saveUserSettingsInvalidate, 'UserSettings'))
        )
      })
    )
  )

  constructor(
    private actions$: Actions,
    private userSettingsService: UserSettingsService,
    private toastService: ToastService,
    private translateHelper: TranslateHelper,
    private store: Store<RootState>
  ) {}

  private catchErrorAndNotify(action: any, objectType: string) {
    return (error: { message: string }) => {
      this.toastService.open({
        id: 'err',
        variant: 'error',
        title: this.translateHelper.instant('Error'),
        message: this.translateHelper.combineTranslations('generic_ObjectCouldNotBeRetrieved', objectType),
      })
      return of(action({ error: error.message }))
    }
  }
}

import { of } from 'rxjs'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  getManagementStatus,
  getManagementStatusInvalidate,
  getManagementStatusResponse,
} from '@app/store/management-status/management-status.actions'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { OrganizationService } from '@app/core/services/organization.service'

@Injectable()
export class ManagementStatusEffects {
  getManagementStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getManagementStatus),
      mergeMap(() =>
        this.organizationService.getManagementSession().pipe(
          map((actions) => getManagementStatusResponse({ actions })),
          catchError((error) => of(getManagementStatusInvalidate({ error: { code: error.status, message: error.message }})))
        )
      )
    )
  )

  constructor(private actions$: Actions, private organizationService: OrganizationService) {}
}

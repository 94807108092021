<app-loading-login-skeleton style="width: 100%;" *ngIf="!startUpCompleted && errorStatus === 0"></app-loading-login-skeleton>
<app-consent-apps style="width: 100%;" *ngIf="!!showConsent"></app-consent-apps>
<div class="page-container" *ngIf="!outlet.isActivated && !!startUpCompleted && !showConsent">
  <app-toolbar *ngIf="errorStatus === 0" [isMenuOpen]="isMenuOpen" [darkMode]="darkMode" (themeChanged)="preferredThemeChanged($event)" #appToolbar></app-toolbar>

  <app-navigation *ngIf="errorStatus === 0" [isMenuOpen]="isMenuOpen" [menus]="menus" class="flex-full-height"></app-navigation>

  <div class="error-container" *ngIf="errorStatus > 0">
    <app-unauthorized-access [errorCode]="errorStatus"></app-unauthorized-access>
  </div>
  <cv-alert-toast-controller style="z-index: 1000000"></cv-alert-toast-controller>
</div>
<div class="forbidden" [style.display]="outlet.isActivated ? 'flex' : 'none'">
  <router-outlet #outlet="outlet" name="forbidden"></router-outlet>
</div>

import { Component, Input } from '@angular/core';
import { DropdownSelectorComponent } from '../../../../shared/components/dropdown-selector/dropdown-selector.component'
import { ToSuggestion } from '@app/core/models/PolicyOwnerCandidate'
import { Policy } from '@app/core/models/playbook';
import { TranslateHelper } from '@coreview/coreview-library';
import { MatDialog } from '@angular/material/dialog';
import { PlaybookService } from '../../services/playbook.service';
import { ButtonType, ToastService } from '@coreview/coreview-components';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'app-edit-policy-owner-button',
  templateUrl: './edit-policy-owner-button.component.html',
  styleUrls: ['./edit-policy-owner-button.component.sass']
})

export class EditPolicyOwnerButtonComponent {
  @Input() policy: Policy | undefined
  @Input() buttonType: ButtonType = 'tertiary'

  private destroyed$: Subject<boolean> = new Subject()

  constructor(private translateHelper: TranslateHelper,
              private dialog: MatDialog,
              private playbookService: PlaybookService,
              private toastService: ToastService) {}

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  handleEditOwnerButtonClick(policy: Policy) {
    this.openDialog(policy)
        .pipe(
          filter(dialogOutput => dialogOutput && policy.createdBy !== dialogOutput),
          switchMap(newPolicyOwner => this.updatePolicyOwner(policy, newPolicyOwner)),
          catchError(_ => this.openErrorToast()),
          takeUntil(this.destroyed$))
        .subscribe()
  }

  private openDialog(policy: Policy) : Observable<any> {
    return this.dialog.open(DropdownSelectorComponent, {
      minWidth: "35%",
      data: {
        mainTitle: this.translateHelper.instant("playbook_editOwner"),
        subTitle: policy.title,
        comboBoxTitle: this.translateHelper.instant("playbook_Owner"),
        searchValuesFunc: (username: string) => {
          return this.playbookService.getPolicyOwnerCandidates(10, username)
            .pipe(map(candidates => candidates.map(candidate => ToSuggestion(candidate))))
        }
      },
      autoFocus: false
    })
    .afterClosed()
  }

  private openErrorToast() : Observable<null> {
    this.toastService.open({
      id: 'warning',
      variant: 'warning',
      title: this.translateHelper.instant('common_Error'),
      message: this.translateHelper.instant('playbook_ErrorOwnerNotUpdated'),
    })

    return of(null)
  }

  private openSuccessToast() : Observable<null> {
    this.toastService.open({
      id: 'success',
      variant: 'success',
      title: this.translateHelper.instant('common_SaveChange'),
      message: this.translateHelper.instant('playbook_OwnerUpdated'),
    })

    return of(null)
  }

  private updatePolicyOwner(policy: Policy, newPolicyOwner: string): Observable<null> {
    return this.playbookService
      .updatePolicyOwner(policy.id!, newPolicyOwner)
      .pipe(
        switchMap(_ => {
          policy.createdBy = newPolicyOwner
          return this.openSuccessToast()
        })
      )
  }
}

import { Component, Input, OnInit } from '@angular/core'
import { isActionSequence } from '@app/modules/workflow/models/workflow.definition'
import { Workflow } from '@app/modules/workflow/models/workflow.model'

@Component({
  selector: 'app-execution-event-list',
  templateUrl: './execution-event-list.component.html',
  styleUrls: ['./execution-event-list.component.sass'],
})
export class ExecutionEventListComponent implements OnInit {
  @Input() event!: Workflow.Dto.V1.WorkflowExecutionDetail.ExecutionHistoryEvent
  @Input() events!: Workflow.Dto.V1.WorkflowExecutionDetail.ExecutionHistoryEvent[]
  @Input() isCatch = false
  @Input() showMergeLine = false

  public isSequence = false
  public sequenceEvents:  Workflow.Dto.V1.WorkflowExecutionDetail.ExecutionHistoryEvent[] = []

  ngOnInit(): void {
    this.isSequence = isActionSequence(this.event.action)
    this.setSequenceEvents()
  }

  setSequenceEvents() {
    this.sequenceEvents = this.isSequence
      ? this.events.filter((item) => item.action.parentUid === this.event.action.uid) : this.events
  }
}

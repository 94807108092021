<div class="flex-full-height">
  <div style="display: flex; color: initial; line-height: 60px; border-bottom: 1px solid var(--opaque-light-contrast-color)">
    <div style="flex: 1; font-size: 14px; font-weight: 600; margin-left: 20px" translate>management_ErrorMessageDetail</div>
  </div>
  <div class="p-20" style="flex: 1; display: flex; flex-direction: column; overflow: auto">
    <div style="margin-top: 20px">
      <div style="display: grid; grid-template-columns: max-content 1fr; grid-gap: 24px; overflow: auto">
        <div style="font-weight: 600" translate>management_ActionName</div>
        <div>{{ action.translate }}</div>

        <div style="font-weight: 600" translate>common_Description</div>
        <div>{{errorDescription}}</div>

        <div style="font-weight: 600" translate>common_Details</div>
        <div translate>common_SeeTableBelow</div>
      </div>
    </div>
    <app-client-datagrid
      #selectedTargetsGrid
      *ngIf="processedErrorMessages && processedErrorMessages.length > 0"
      [getItems]="getErrorMessages"
      [columnsParameters]="errorMessagesColumnDefs"
      [hideColumnsSelector]="true"
      [autosizeAllColumns]="true"
      [gridOptions]="{ suppressRowClickSelection: true }"
      style="flex: 1"
      cvDataTest="mgmt-error-details"
    >
    </app-client-datagrid>
  </div>
</div>

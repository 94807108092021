import { omit } from 'lodash-es'

import { EnhancedJsonFormData, EnhancedPropertyFormDescriptor } from '@shared/models/enhanced-json-form-data'

import { CvSchemaAttribute, SchemaPropertyDecorator } from './json-schema-rosetta-v1.class'

export class DateTimePickerDecorator extends SchemaPropertyDecorator {
  match(schema: EnhancedJsonFormData): boolean {
    const properties = schema?.properties ?? {}

    return Object.entries(properties).some((value) => this.isDataSourceProp(value))
  }

  getJsonSchema(): EnhancedJsonFormData {
    const schema = super.getJsonSchema()

    if (this.match(schema)) {
      const properties = Object.entries(schema.properties)
        .map((value) => {
          if (!this.isDataSourceProp(value)) {
            return value
          }

          const [key, prop] = value
          const dateTimeProp = {
            ...omit(prop, CvSchemaAttribute.xCvUi),
            format: 'date',
          }

          return [key, dateTimeProp]
        })
        .reduce((acc, [key, prop]) => ({ ...acc, [`${key}`]: prop }), {})

      schema.properties = properties
    }

    return schema
  }

  private isDataSourceProp([, prop]: [string, EnhancedPropertyFormDescriptor]): boolean {
    const key = prop[CvSchemaAttribute.xCvUi]?.toLowerCase()
    const expected = 'DateTimePicker'

    return key === expected.toLowerCase()
  }
}

import { ILoadingCellRendererAngularComp } from '@ag-grid-community/angular'
import { ILoadingCellRendererParams } from '@ag-grid-community/core'
import { Component } from '@angular/core'

@Component({
  selector: 'app-loading-cell-renderer',
  templateUrl: './loading-cell-renderer.component.html',
  styleUrls: ['./loading-cell-renderer.component.sass'],
})
export class LoadingCellRendererComponent implements ILoadingCellRendererAngularComp {
  public params!: ILoadingCellRendererParams

  agInit(params: ILoadingCellRendererParams): void {
    this.params = params
  }
}

import { Component, Input } from '@angular/core'
import { SavedReport } from '@core/models/saved-report'
import { Constants } from '@shared/utilities/constants'

@Component({
  selector: 'app-custom-reports-list',
  templateUrl: './custom-reports-list.component.html',
  styleUrls: ['./custom-reports-list.component.sass'],
})
export class CustomReportsListComponent {
  @Input() savedReports: SavedReport[] = []

  constructor(private location: Location) {}

  goToSavedReport(savedReport: SavedReport) {
    this.setLocationHref(savedReport.reportUrl + Constants.savedReportString + savedReport.guid)
  }

  setLocationHref(url: string) {
    this.location.href = url
  }
}

import { createAction, props } from '@ngrx/store'
import { WorkflowActionCategoryExtended } from '@app/core/models/WorkflowActionCategory'

export const getWorkflowActionCategoryLookups = createAction('[WorkflowActionCategoryLookups] GET request')
export const getWorkflowActionCategoryLookupsResponse = createAction(
  '[WorkflowActionCategoryLookups] GET resp',
  props<{ lookups: WorkflowActionCategoryExtended[] }>()
)
export const getWorkflowActionCategoryLookupsInvalidate = createAction(
  '[WorkflowActionCategoryLookups] GET invalidates',
  props<{ error: { code: number; message: string } }>()
)
export const getWorkflowActionCategoryLookupById = createAction(
  '[WorkflowActionCategoryLookupById] GET by ID request',
  props<{ id: string }>()
)

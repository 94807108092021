import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core'
import { RightPanelRef } from '@app/core/services/right-panel.service'
import { PanelStep } from '@app/shared/models/panel-step'
import { TranslateHelper } from '@coreview/coreview-library'
import { flattenDeep, minBy, maxBy } from 'lodash-es'
import { timer } from 'rxjs'

@Component({
  selector: 'app-panel-steps',
  templateUrl: './panel-steps.component.html',
  styleUrls: ['./panel-steps.component.sass'],
})
export class PanelStepsComponent implements OnInit, OnChanges {
  @Input() steps!: PanelStep[]
  @Input() panelTitle = ''
  @Input() panelSubtitle?: string
  @Input() isSaving = false
  @Input() viewMode!: boolean
  @Input() collapseSteps = false
  @Input() submitLabel?: string
  @Input() allowQuickSubmit = false
  @Input() showPreviewBadge = false
  @Input() previewBadgeIdentifier = ''
  @Output() save = new EventEmitter()
  @Output() changeViewMode = new EventEmitter<boolean>()

  activeStep?: PanelStep & { parentSort: number; parentStepKeys: string[]; isFirst: boolean }
  flatSteps: PanelStep[] = []

  constructor(private rightPanelRef: RightPanelRef, private translateHelper: TranslateHelper) {}

  ngOnInit(): void {
    this.flattenSteps()
    timer(1).subscribe(() => {
      const defaultStepIndex = this.flatSteps.findIndex((s: PanelStep) => s.isDefaultStep)
      this.clickStep(this.flatSteps[defaultStepIndex > -1 ? defaultStepIndex : 0], defaultStepIndex === this.flatSteps.length - 1)
    })
  }

  ngOnChanges(): void {
    this.flattenSteps()
  }

  clearSubSteps(parentStep: PanelStep): void {
    parentStep.subSteps = []
    this.flattenSteps()
  }

  addSubSteps(parentStep: PanelStep, subSteps: PanelStep[]) {
    parentStep.subSteps = subSteps
    this.flattenSteps()
  }

  clickStep(step: PanelStep, selectLastSubstep = false, resetViewMode = false, isBack = false) {
    if (resetViewMode) {
      this.viewMode = false
      this.changeViewMode.emit(this.viewMode)
    }

    if (step.subSteps?.length) {
      step = selectLastSubstep ? step.subSteps[step.subSteps.length - 1] : step.subSteps[0]
    }

    if (step.status === 'NotAvailable' || (typeof step.canEnterStep === 'function' && !step.canEnterStep())) {
      return
    }

    this.onExitStep(isBack)
    this.onEnterStep(step)

    // collapse parent if necessary
    this.toggleParentStep(this.activeStep?.parentStepKey)

    this.activeStep = {
      ...step,
      parentSort: this.getParentSort(step),
      parentStepKeys: this.getParentKeys(step),
      isFirst: this.firstStepIsActive(step),
    }

    // open new parent if necessary
    this.toggleParentStep(step?.parentStepKey, true)

    if (step.subSteps?.length) {
      this.clickStep(selectLastSubstep ? step.subSteps[step.subSteps.length - 1] : step.subSteps[0])
    }
  }

  canGoDirectlyToStep(step: PanelStep): boolean {
    if (step.sort < (this.activeStep?.sort || 0)) {
      // if it's a step previous to currently active step, it can move without validation
      return true
    } else if (step.sort > (this.activeStep?.sort || 0)) {
      // it it's a future step, need to validate if can proceed
      return this.canProceed(this.activeStep)
    }
    // same step, do nothing
    return false
  }

  next() {
    this.clickStep(this.getNextStep())
  }

  back() {
    this.clickStep(this.getPreviousStep(this.activeStep || this.steps[0]), false, false, true)
  }

  lastStepIsActive(): boolean {
    return this.activeStep?.stepKey === this.flatSteps[this.flatSteps.length - 1].stepKey
  }

  isInvalidStep(step: PanelStep): boolean {
    return typeof step.isValid === 'function' && !step.isValid()
  }

  isPreviousStep(step: PanelStep): boolean {
    return (this.activeStep?.parentSort || 0) > step.sort
  }

  isActiveStep(step: PanelStep): boolean {
    if (!this.activeStep) {
      return minBy(this.flatSteps, 'sort')?.sort === step.sort
    }
    return step.stepKey === this.activeStep.stepKey
  }

  isActiveChildStep(step: PanelStep): boolean {
    if (!this.activeStep) {
      return false
    }
    return this.activeStep.parentStepKeys.includes(step.stepKey)
  }

  canProceed(step?: PanelStep) {
    if (typeof step?.canProceed === 'function') {
      return step.canProceed()
    }
    return true
  }

  getSubtitle(step: PanelStep) {
    if (typeof step?.getSubtitle === 'function') {
      return step.getSubtitle()
    }
    return step.isRequired && (step.subSteps || []).length <= 0 ? 'common_Required' : ''
  }

  cancel() {
    this.rightPanelRef.close()
  }

  getSubmitLabel() {
    if (this.isSaving) {
      return this.translateHelper.instant('common_Processing')
    }
    return this.submitLabel ? this.translateHelper.instant(this.submitLabel) : this.translateHelper.instant('common_Submit')
  }

  quickComplete() {
    const lastStep = this.flatSteps[this.flatSteps.length - 1]
    if (this.canProceed(lastStep)) {
      this.complete()
    } else {
      this.clickStep(lastStep)
    }
  }

  complete() {
    this.save.emit()
  }

  private flattenSteps() {
    this.flatSteps = flattenDeep(this.steps.map((x) => [x, x.subSteps?.map((y) => [y, y.subSteps || []]) || []])).sort(
      (a, b) => a.sort - b.sort
    )
  }

  private onExitStep(isBack = false) {
    if (typeof this.activeStep?.onExitStep === 'function') {
      const func: (isBack: boolean) => void = this.activeStep?.onExitStep
      setTimeout(() => {
        func(isBack)
      }, 10)
    }
  }

  private onEnterStep(step: PanelStep) {
    if (typeof step.onEnterStep === 'function') {
      const func = step.onEnterStep
      setTimeout(() => {
        func()
      }, 10)
    } else {
      const parentStep = step.parentStepKey ? this.flatSteps.find((x) => x.stepKey === step.parentStepKey) : undefined
      if (typeof parentStep?.onEnterStep === 'function') {
        const funcParent = parentStep.onEnterStep
        setTimeout(() => {
          funcParent()
        }, 10)
      }
    }
  }

  private getNextStep(): PanelStep {
    if (this.activeStep?.nextStep) {
      const definedNextStep = this.flatSteps.find((x) => x.stepKey === this.activeStep!.nextStep)
      if (definedNextStep) {
        return definedNextStep
      }
    }
    const nextStep = minBy(
      this.flatSteps.filter((x) => x.sort > (this.activeStep?.sort || 0)),
      'sort'
    )
    return nextStep as PanelStep
  }

  private getPreviousStep(currentStep: PanelStep): PanelStep {
    const prevStep = maxBy(
      this.flatSteps.filter((x) => x.sort < (currentStep.sort || 0)),
      'sort'
    )
    if (prevStep?.subSteps?.length) {
      return this.getPreviousStep(prevStep)
    }
    return prevStep as PanelStep
  }

  private toggleParentStep(stepKey?: string, expand = false) {
    const previousStepParent = stepKey ? this.flatSteps.find((x) => x.stepKey === stepKey) : undefined
    if (previousStepParent?.subSteps?.length) {
      previousStepParent.expanded = expand
    }
  }

  private getParentSort(step: PanelStep): number {
    if (step.parentStepKey) {
      const parent = this.flatSteps.find((x) => x.stepKey === step.parentStepKey) as PanelStep
      if (parent?.parentStepKey) {
        return this.getParentSort(this.flatSteps.find((x) => x.stepKey === parent.parentStepKey) as PanelStep)
      }
      return parent.sort
    }
    return step.sort
  }

  private getParentKeys(step: PanelStep): string[] {
    if (step.parentStepKey) {
      const parent = this.flatSteps.find((x) => x.stepKey === step.parentStepKey) as PanelStep
      if (parent?.parentStepKey) {
        return [step.parentStepKey, ...this.getParentKeys(parent)]
      }
      return [step.parentStepKey]
    }
    return []
  }

  private firstStepIsActive(step: PanelStep): boolean {
    if (step.stepKey === this.flatSteps[0].stepKey) {
      return true
    }
    return step.stepKey === this.getFirstChildKey(this.flatSteps[0])
  }

  private getFirstChildKey(step: PanelStep): string {
    if (step.subSteps?.length) {
      return this.getFirstChildKey(step.subSteps[0])
    }
    return step.stepKey
  }
}

<mat-icon
  [class.disabled]="disabled"
  class="button_row"
  [matMenuTriggerFor]="menu"
  (click)="$event.stopPropagation()"
  [attr.cv-data-test]="'icon-menu-trigger'"
  #trigger="matMenuTrigger"
  >{{ icon }}</mat-icon
>

<mat-menu #menu="matMenu" yPosition="below" class="icon-menu">
  <ng-container *ngFor="let opt of options">
    <div mat-menu-item *ngIf="!opt.isSeparator" 
    [disabled]="opt.isDisabled"
    (click)="click(opt.key, $event, opt.isDisabled)" 
    attr.cv-data-test="{{ 'menu_' + opt.key }}">
      <mat-icon [class.alert]="opt.color === 'alert'" style="width: 18px; font-size: 18px; height: 18px; margin: 0 16px 0 0" *ngIf="opt.icon">{{
        opt.icon
      }}</mat-icon>
      <span [class.alert]="opt.color === 'alert'">{{ opt.text }}</span>
    </div>
    <div class="separator" *ngIf="opt.isSeparator"></div>
  </ng-container>
</mat-menu>

import { Injectable } from '@angular/core'
import { v4 } from 'uuid'

@Injectable({
  providedIn: 'root',
})
// This wrapper is needed to able to mock uuid in unit tests
export class UuidService {
  v4(): string {
    return v4()
  }
}

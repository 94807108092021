import { ManagementTypeResponse, ManagementTypeValues } from '@app/core/models/ManagementTypeResponse'
import { createAction, props } from '@ngrx/store'

export const getManagementType = createAction('[ManagementType] GET by organization id request', props<{ orgId: string }>())
export const getManagementTypeResponse = createAction('[ManagementType] GET resp', props<{ data: ManagementTypeResponse }>())
export const getManagementTypeInvalidate = createAction(
  '[ManagementType] GET invalidates',
  props<{ error: { code: number; message: string } }>()
)

export const setManagementType = createAction('[ManagementType] SET ManagementType', props<{ value: ManagementTypeValues }>())


import { Component, Input, OnInit } from '@angular/core';
import { RightPanelRef } from '@app/core/services/right-panel.service';

@Component({
  selector: 'app-service-usage-advanced-filters',
  templateUrl: './service-usage-advanced-filters.component.html',
  styleUrls: ['./service-usage-advanced-filters.component.sass']
})
export class ServiceUsageAdvancedFiltersComponent implements OnInit {
  @Input() configuration!: { includeCosts: boolean; includeNewUsers: boolean }

  constructor(
    private rightPanelRef: RightPanelRef,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.rightPanelRef.close()
  }

  submit() {
    this.rightPanelRef.close(this.configuration)
  }
}

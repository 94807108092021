<div class="cv-dialog-div-container">
  <div class="title-div">
    <div class="title-container">
      <span class="title">{{ title | translate }}</span>
      <span *ngIf="!!subTitle" class="subtitle">{{ subTitle | translate }}</span>
    </div>
    <cv-close-button class="close-icon" [mat-dialog-close]></cv-close-button>
  </div>
  <div class="dialog-container">
    <app-client-datagrid [columnsParameters]="columnParams" [hideColumnsSelector]="hideColumnSelector"
      [detailCellRenderer]="detailCellRenderer" [detailCellRendererParams]="detailCellRenderParams"
      [isRowMaster]="isRowMaster" [selectionActions]="selectionActions" [filterActions]="filterActions"
      [getItems]="getItems" style="flex: 1" [autosizeAllColumns]="true" (gridReady)="applyFilters()">
    </app-client-datagrid>
  </div>
</div>
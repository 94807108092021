<form [formGroup]="form" style="display: grid; grid-template-columns: 1fr auto; column-gap: 10px">
  <cv-select
    formControlName="holiday"
    [label]="'management_Holiday' | translate"
    [suggestions]="holidaySuggestions"
    [errorMessage]="'common_Required' | translate"
    [isError]="!isValid()"
    [required]="true"
    style="width: 100%"
  ></cv-select>
  <ng-container *ngIf="!!form.get('holiday')?.value">
    <cv-icon-button
      (click)="togglePreview()"
      #trigger="cdkOverlayOrigin"
      buttonType="tertiary"
      cdkOverlayOrigin
      color="info"
      icon="date_range"
    ></cv-icon-button>

    <ng-template
      (backdropClick)="isPreviewOpen = false"
      cdkConnectedOverlay
      cdkConnectedOverlayHasBackdrop
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      [cdkConnectedOverlayPositions]="positions"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isPreviewOpen"
    >
      <div class="container">
        <h4 style="font-weight: 600" translate class="m-b-10">management_HolidayRanges</h4>

        <ul class="list">
          <li class="item" *ngFor="let range of getHolidayRanges(form.get('holiday')?.value)">{{ range }}</li>
        </ul>
      </div>
    </ng-template>
  </ng-container>
  <div *ngIf="!!errorMessage" class="error-wrapper">
    <mat-icon class="material-icons-outlined icon"> error </mat-icon>
    <span class="message">{{ errorMessage }}</span>
  </div>
</form>

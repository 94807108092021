import { Injectable } from '@angular/core'
import { ManagementAction } from '@app/core/models/management-action'
import { ManagementHelperService } from '@app/shared/utilities/management.helper.service'
import { TranslateHelper } from '@coreview/coreview-library'
import {
  getManagementActions,
  getManagementActionsInvalidate,
  getManagementActionsResponse,
} from '@app/store/management-actions/management-actions.actions'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { ManagementActionsService } from '../../core/services/management-actions.service'

@Injectable()
export class ManagementActionsEffects {
  getManagementActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getManagementActions),
      mergeMap(() =>
        this.managementActionsService.getActionsV2().pipe(
          map((actions) => actions.map((a) => this.mapActions(a))),
          map((actions) => getManagementActionsResponse({ actions })),
          catchError((error) => of(getManagementActionsInvalidate({ error: { code: error.status, message: error.message } })))
        )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private managementActionsService: ManagementActionsService,
    private translateHelper: TranslateHelper
  ) {}

  private mapActions(a: ManagementAction) {
    if (ManagementHelperService.isCustomAction(a)) {
      a.translate = a.title
    } else {
      a.translate = this.translateHelper.instant(`management_${a.title}`)
      a.description = this.translateHelper.instant(`descriptions_${a.title}`)
    }
    a.actionItems?.forEach((ai) => {
      if (ManagementHelperService.isCustomAction(ai)) {
        ai.translate = ai.title
      } else {
        ai.translate = this.translateHelper.instant(`management_${ai.title}`)
        ai.description = this.translateHelper.instant(`descriptions_${ai.title}`)
      }
      ai.category = a.title
      ai.translatedCategory = a.translate
      ai.iconCategory = a.icon || 'ms-Icon ms-Icon--Settings'
    })
    return a
  }
}

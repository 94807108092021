import { EnhancedPropertyFormDescriptor } from '@app/shared/models/enhanced-json-form-data'

export const isFilterable = (value: Partial<EnhancedPropertyFormDescriptor>): boolean => {
  const filterableDataSources = [
    'office365groups',
    'securitygroups',
    'distributiongroups',
    'teamgroupsall',
    'userprincipalnames',
    'organizationalunitsdropdown',
    'deletedusers'
  ]
  const previousMarkedAsFilterable = !!value.options?.filterableOptions
  const hasFilterableAttribute = Object.keys(value).includes('x-cv-data-source-filterable')
  const datasoureceIsFilterable = filterableDataSources.includes(value['x-cv-data-source'] || '')
  return previousMarkedAsFilterable || hasFilterableAttribute || datasoureceIsFilterable
}

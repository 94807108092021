<div class="wrapper" *ngIf="!loading">
  <div *ngFor="let card of homecards" style="height: 100%">
    <cv-surface class="cardSurface" *ngIf="card.visible">
      <div>
        <mat-icon [style.color]="card.color" class="analytics m-r-5">{{card.icon}}</mat-icon> <span class="title"
          translate>{{card.title}}</span>
      </div>
      <div style="display: flex; flex-direction: column; height: calc(100% - 41px); overflow: auto;">
        <div *ngFor="let group of card.groups">
          <div class="reports-text" translate>{{group.title}}</div>
          <div class="p-5">
            <ng-container *ngFor="let child of group.children">
              <cv-button (click)="manageItem(child)" [color]="card.color" style="display: block; margin-top: 5px"
                buttonType="tertiary" [text]="child.name" [fontWeight]="600"></cv-button>
            </ng-container>
          </div>
        </div>
        <cv-button *ngIf="card.hasButtonAction" [color]="card.color" [text]="card.actionButtonLabel || '' | translate"
          [leftIcon]="card.leftIcon || null" style="float: right; margin-top: auto; margin-left: auto; margin-bottom: 10px;"
          (click)="onCardClick(card)"></cv-button>
      </div>
    </cv-surface>
  </div>
</div>


import { Configuration } from '@app/core/services/environment-configuration.service'
export const environment: Configuration = {
  name:'DEV',
  production: true,
  baseAuthUrl: 'https://devauth.coreview.com/',
  baseCommunicationUrl: {
    LOCAL: "wss://devnotification.coreview.com",
    DEV: "wss://devnotification.coreview.com",
    STAGING: 'https://stgnotification.coreview.com',
  },
  workflowUrl: {
    LOCAL: "",
  },
  workflowUrlCatalog: {
    LOCAL: ""
  },
  workflowUrlV2: {
    LOCAL: "",
  },
  baseCentralUrl: 'https://preprodapi.coreview.com/',
  portalV1Url: 'https://devportal.coreview.com/',
  serviceNowUrl: 'serviceNow',
  appVersion: '14.0.001'
}

import { Injectable } from '@angular/core'
import { convertFromNumberToStatus } from '@app/core/models/management-response'
import { ManagementTypeResponse } from '@app/core/models/ManagementTypeResponse'
import { SelectedOrganization } from '@coreview/coreview-library/models/selected-organization'
import { CustomManagementAction, ManagementAction, UploadCsvButtonDef } from '@app/core/models/management-action'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { TargetSelectionComponent } from '@app/shared/components/target-selection/target-selection.component'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { Helpers } from '@app/shared/utilities/helpers'
import { RootState } from '@app/store/RootState.type'
import { getManagementStatusResponse } from '@app/store/management-status/management-status.actions'
import { selectManagementStatus } from '@app/store/management-status/management-status.selectors'
import { selectManagementType } from '@app/store/management-type/management-type.selectors'
import { selectLastMessageOfType } from '@app/store/messages/messages.selectors'
import { selectOrganizationById } from '@app/store/organizations/organizations.selectors'
import { selectSelectedTenant } from '@app/store/tenants/tenants.selectors'
import { Store } from '@ngrx/store'
import { every, forEach, isArray, startsWith } from 'lodash-es'
import { Observable, forkJoin, of } from 'rxjs'
import { filter, pluck, switchMap } from 'rxjs/operators'
import { ActionPanelComponent } from '../components/action-panel/action-panel.component'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { ToolbarService } from '@app/core/services/toolbar.service'
import { ApiclientService } from '@app/core/services/apiclient.service'

@Injectable({ providedIn: 'root' })
export class ManagementHelperService {
  public static readonly uploadUsersFromCsvActionBar: UploadCsvButtonDef[] = [
    {
      actionType: 'upload',
      title: 'common_UploadCSV',
      buttonType: 'tertiary',
      icon: 'upload',
      url: '/v2/onlineusers/checkupns',
      formSchema: {
        $schema: '',
        title: 'UploadCSVFrom',
        type: 'object',
        options: {
          gridRowHeight: '100%',
        },
        properties: {
          fileUpload: {
            type: 'object',
            format: 'step',
            options: {
              gridColumnsNumber: 3,
            },
            properties: {
              fileDescription: {
                type: 'object',
                format: 'file',
                options: { fileMimeType: ['text/csv'], sizeLimitMB: 5 },
              },
            },
          },
          summary: {
            options: {
              gridRowHeight: '100%',
              conditions: [
                {
                  if: { properties: { isValid: { const: true } } },
                  then: {
                    properties: { selectedTargets: { options: { hidden: false } }, uploadErrors: { options: { hidden: true } } },
                  },
                  else: {
                    properties: { selectedTargets: { options: { hidden: true } }, uploadErrors: { options: { hidden: false } } },
                  },
                },
                {
                  if: { form: { evaluateFunction: 'return !!formData.importedItems?.find(x => !x.verified)' } },
                  then: {
                    properties: {
                      summary: { options: { disclaimer: { variant: 'warning', message: 'management_UnverifiedUploadedItemsWarning' } } },
                    },
                  },
                  else: {
                    properties: { summary: { options: { disclaimer: undefined } } },
                  },
                },
              ],
            },
            type: 'object',
            format: 'step',
            properties: {
              isValid: { type: 'boolean', options: { hidden: true } },
              importedItems: {
                type: 'array',
                format: 'table',
                options: {
                  hidden: true,
                  mapEvent: {
                    sourceEvent: 'dataChanged',
                    targetEvent: 'replaceSelectedTargets',
                    targetProperty: 'countChanged',
                    filterEventFunction: 'return !formData?.selectedTargets?.length',
                    mapFunction: 'return { event: { data: formData.importedItems?.filter(x => !!x.verified) } }',
                  },
                },
              },
              selectedTargets: {
                type: 'object',
                format: 'custom',
                options: {
                  customType: TargetSelectionComponent,
                  customDataFunction:
                    // eslint-disable-next-line max-len
                    `const targetSelectionProperties = {
                      detectSelectedTargetsChanges: true,
                      targetsToSelectDefinition: { isClientDatagrid: true, getTargetsToSelectNativeFunction: () => { return formData?.importedItems || [] }, hideColumnsSelector: true, targetPropertyId: 'userPrincipalName', rowSelection: 'multiple' },
                      options: { isPanelExpandedByDefault: true, fields: [{ key: 'userPrincipalName', value: 'UserPrincipalName', type: 'string' }, { key: 'verified', value: 'management_TargetFound', type: 'boolean' }], selectedItemsFields: [{ key: 'userPrincipalName', value: 'UserPrincipalName', type: 'string' }, { key: 'verified', value: 'management_TargetFound', type: 'boolean' }] },
                      selectedTargets: !formData?.selectedTargets?.length ? formData?.importedItems?.filter((x) => !!x.verified) || [] : formData?.selectedTargets || [],
                    }
                    return targetSelectionProperties`,
                  mapEvent: {
                    sourceEvent: 'dataChanged',
                    targetEvent: 'replaceSelectedTargets',
                  },
                },
              },
              uploadErrors: {
                type: 'array',
                format: 'table',
                options: { disableDelete: true },
                items: {
                  properties: {
                    errorMessage: {
                      type: 'string',
                      format: 'label-translated',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  ]

  selectedOrganization!: SelectedOrganization
  managementStatus!: string
  managementType!: ManagementTypeResponse | null
  autoManagementEnabled = false

  constructor(
    private storage: LocalstorageService,
    private sharedHelperService: SharedHelperService,
    private store: Store<RootState>,
    private apiClientService: ApiclientService,
    private rightPanelService: RightPanelService,
    private toolbarService: ToolbarService
  ) {
    this.selectedOrganization = this.storage.selectedOrganization

    this.store
      .select(selectLastMessageOfType('SetManagementSession'))
      .pipe(filter((x) => !!x))
      .subscribe((x) =>
        this.store.dispatch(
          getManagementStatusResponse({
            actions: {
              status: typeof x?.body.status === 'number' ? convertFromNumberToStatus(x?.body.status) : x?.body.status,
              sessionId: x?.body.sessionId,
            },
          })
        )
      )

    this.store
      .select(selectManagementStatus)
      .pipe(filter((x) => !!x))
      .subscribe((x) => {
        this.managementStatus = x?.status || 'Disabled'
      })

    this.store
      .select(selectSelectedTenant)
      .pipe(
        filter((x) => !!x),
        switchMap((x) => this.store.select(selectOrganizationById(x?.managedTenantId || '')).pipe(filter((o) => !!o)))
      )
      .subscribe((org) => {
        this.autoManagementEnabled = !!org?.autoEnableManagementSession
      })

    this.store.select(selectManagementType).subscribe((managementType) => {
      this.managementType = managementType
    })
  }

  static isCustomAction(action: ManagementAction | CustomManagementAction): boolean {
    //TODO Change check tags
    const customAction = action as CustomManagementAction
    return !!customAction.customActionId && (isNaN(Number(customAction.customActionId)) || !!Number(customAction.customActionId))
  }

  canRunAction(action: ManagementAction): boolean {
    return !!this.isManagementEnabled() || action.category === 'EndpointManager' || action.title === 'ManageTenders'
  }

  isManagementEnabled(): boolean {
    return (
      this.managementStatus === 'Enabled' ||
      this.sharedHelperService.isDemoUser() ||
      this.sharedHelperService.isDemoCompany() ||
      this.isAdvancedManagement() ||
      window.location.host.startsWith('localhost:4200')
    )
  }

  isManagementEnabledWithoutAdvancedManagement(): boolean {
    return this.managementStatus === 'Enabled' || this.sharedHelperService.isDemoUser() || this.sharedHelperService.isDemoCompany()
  }

  isManagementBusy(): boolean {
    return (
      this.managementStatus === 'Enabled' ||
      this.managementStatus === 'Enabling' ||
      this.managementStatus === 'Disabling' ||
      this.managementStatus === 'Loading' ||
      this.sharedHelperService.isDemoUser() ||
      this.sharedHelperService.isDemoCompany()
    )
  }

  isAdvancedManagement(): boolean {
    return this.managementType?.managementType === 'Advanced' && this.autoManagementEnabled
  }

  checkConsentNeeded(actionCategory: string) {
    if (actionCategory === 'OneDrive' || actionCategory === 'SharePoint') {
      // if (!this.selectedOrganization.isSharePointManagementEnabled) {
      //     this.sharepointConsent()
      //     return true
      // }
    } else if (actionCategory === 'EndpointManager') {
      // if (!this.selectedOrganization.isGraphManagementConsentGiven) {
      //     this.graphManagementConsent('Intune')
      //     return true
      // }
    }
    return false
  }

  getInitialDataForAction(action: ManagementAction, selectedTargets: any[]): Observable<any | null> {
    if (!!action.initDataCalls && action.initDataCalls.length > 0) {
      const resultsObservables: Observable<any | null>[] = []

      action.initDataCalls.forEach((apiDefinition) => {
        if (
          apiDefinition.initCallType === 'Always' ||
          (apiDefinition.initCallType === 'Single' && selectedTargets.length === 1) ||
          (apiDefinition.initCallType === 'Multiple' && selectedTargets.length > 1)
        ) {
          const func = Function('targets', apiDefinition.mapApiParams)
          const params = func(selectedTargets)
          let url = apiDefinition.apiUrl
          ;(url.match(/\{([A-z]+)\}/g) || []).forEach((x) => {
            url = url.replace(x, params[x.substring(1, x.length - 1)])
            delete params[x.substring(1, x.length - 1)]
          })
          resultsObservables.push(
            apiDefinition.responseObjectName
              ? this.apiClientService.getData(url, apiDefinition.verb, params).pipe(pluck(...apiDefinition.responseObjectName.split('.')))
              : this.apiClientService.getData(url, apiDefinition.verb, params)
          )
        } else {
          resultsObservables.push(of({}))
        }
      })
      return forkJoin(resultsObservables).pipe(
        switchMap((results) => {
          const finalObj: any[] = []
          results.forEach((res) => {
            finalObj.push(res)
          })
          return of(finalObj)
        })
      )
    } else if (!!action.initDataApiUrl && !!action.initDataGetApiParams && selectedTargets.length === 1) {
      // Legacy init data calls are meant to be done for single target only
      const func = Function('data', action.initDataGetApiParams)
      const params = func(selectedTargets[0])
      let url = action.initDataApiUrl
      ;(url.match(/\{([A-z]+)\}/g) || []).forEach((x) => {
        url = url.replace(x, params[x.substring(1, x.length - 1)])
        delete params[x.substring(1, x.length - 1)]
      })
      return this.apiClientService.getData(url, 'get', params)?.pipe(pluck(...(action.initDataResponseObjectName?.split('.') || '')))
    }
    return of([])
  }

  getMappedDataForAction(mapFunction: string, initialData: any, selectedTargets: any[]): any {
    // TODO keeping 'target' for retrocompatibility, someday we'll use only 'targets'
    const func = Function('data', 'target', 'targets', mapFunction)
    return func(initialData, selectedTargets[0], selectedTargets)
  }

  getSubmitData(mapFunction: string | undefined, formData: any, initialData: any, selectedTargets: any): any {
    if (mapFunction) {
      try {
        const func = Function('data', 'initialData', 'targets', mapFunction)
        return func(formData, initialData, selectedTargets)
      } catch {}
    }
    return formData
  }

  isCompliantWithActionRules(action: ManagementAction, itemsChecked: any[]): boolean {
    let isCompliant = true
    // do not replace this foreach with native one!
    // we need the return false to work as iteration stopper
    forEach(action.rules, (rule: any) => {
      rule = rule.split(':')
      // @ts-ignore
      const property = Helpers.downcase(rule[0].trim())
      const value = rule[1].trim() === 'false' ? false : rule[1].trim() === 'true' ? true : rule[1].trim()

      if (startsWith(value, '!=')) {
        isCompliant = every(itemsChecked, (i) => !!i[property] && i[property] !== value.replace('!=', ''))
      } else if (startsWith(value, 'reg=>')) {
        const regexContent = value.replace('reg=>', '')
        const regex = new RegExp('(' + regexContent + ')')

        isCompliant = every(itemsChecked, (i) => i[property] && regex.test(i[property]) !== false)
      } else if (value === 'IsNotEmpty') {
        isCompliant = every(itemsChecked, (i) => {
          if (i[property] && isArray(i[property])) {
            return i[property].length > 0
          } else if (i[property] && !isArray(i[property])) {
            return i[property].trim() !== ''
          } else {
            return false
          }
        })
      } else if (value === 'IsEmpty') {
        isCompliant = every(itemsChecked, (i) => {
          if (i[property] && isArray(i[property])) {
            return i[property].length === 0
          } else if (i[property] && !isArray(i[property])) {
            return i[property].trim() === ''
          } else {
            return true
          }
        })
      } else {
        // equals
        isCompliant = every(itemsChecked, (i) => i[property] === value)
      }

      // this is to avoid continue the iteration checking other rules
      // if one of them is already not compliant
      return isCompliant
    })
    return isCompliant
  }

  openManagementPanel(targets: any[], action?: ManagementAction) {
    if (action) {
      if (this.canRunAction(action)) {
        this.rightPanelService.open({
          type: ActionPanelComponent,
          data: {
            action,
            width: '100%',
            selectedTargets: targets,
          },
        })
      } else {
        this.toolbarService.open('management-session')
      }
    }
  }
}

<form [formGroup]="form" style="display: grid; grid-template-columns: 180px 1fr; column-gap: 10px;">
  <cv-select
    formControlName="type"
    [label]="'RedirectTo' | translate"
    [suggestions]="typesSuggestions"
    (selectionChanged)="typeChanged()"
    [errorMessage]="'common_Required' | translate"
    style="width: 100%;"
    [style.gridColumn]="!form.get('type')?.value || form.get('type')?.value === 'Disconnect' ? 'span 2' : '1'"
  ></cv-select>
  <ng-container *ngIf="!!form.get('type')?.value && form.get('type')?.value !== 'Disconnect'">
    <cv-input
      style="width: 100%"
      *ngIf="
        form.get('type')?.value === 'ExternalPstn' ||
        form.get('type')?.value === 'AnnouncementText'
      "
      formControlName="destination"
      [label]="(form.get('type')?.value === 'ExternalPstn' ? 'management_Destination' : 'management_TypeGreetingMessage'  ) | translate"
      [errorMessage]="'common_Required' | translate"
      [disabled]="false"
    >
    </cv-input>
    <cv-autocomplete-input
      style="width: 100%"
      *ngIf="
        form.get('type')?.value === 'UserOperator' ||
        form.get('type')?.value === 'User' ||
        form.get('type')?.value === 'ApplicationEndpoint' ||
        form.get('type')?.value === 'SharedVoicemail'
      "
      formControlName="destination"
      [leftIcon]="form.get('type')?.value !== 'UserOperator' ? 'search' : ''"
      (inputChange)="form.get('type')?.value !== 'UserOperator' && destinationUpdate.next($event)"
      [label]="'management_Destination' | translate"
      [placeholder]="'management_SearchDestination' | translate"
      [suggestions]="destinationSuggestions"
      [forceSelection]="form.get('type')?.value !== 'UserOperator'"
      [errorMessage]="'common_Required' | translate"
      [disabled]="form.get('type')?.value === 'UserOperator'"
      [description]="form.get('type')?.value === 'UserOperator' ? ('management_SetUpOperatorFirstStep' | translate) : ''"
    ></cv-autocomplete-input>
    <cv-file-upload
      formControlName="destination"
      *ngIf="form.get('type')?.value === 'AnnouncementAudio'"
      [acceptedTypes]="['audio/mp3', 'audio/mpeg', 'audio/wav', 'audio/x-ms-wma']"
    ></cv-file-upload>
    <ng-container *ngIf="form.get('type')?.value === 'SharedVoicemail'">
      <cv-switch formControlName="enableTranscription" class="m-t-5">
        {{ 'management_Transcription' | translate }}
      </cv-switch>
      <cv-switch formControlName="enableSharedVoicemailSystemPromptSuppression" [info]="'management_SuppressVoicemailSystemMessageInfo' | translate" class="m-t-5">
        {{ 'management_SuppressVoicemailSystemMessage' | translate }}
      </cv-switch>
    </ng-container>
  </ng-container>
</form>
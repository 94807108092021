import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'
import { TenantsState } from '@app/store/tenants/tenants.types'
import { ManagedTenant } from '@app/core/models/ManagedTenant'
import {
  changeManagedTenantInvalidate,
  changeManagedTenantSuccess,
  getManagedTenants,
  getManagedTenantsInvalidate,
  getManagedTenantsResponse,
  requestManagedTenantChange,
} from './tenants.actions'

/**
 * Creates a handy adapter for the the "tenants" state.
 */
export const tenantsAdapter: EntityAdapter<ManagedTenant> = createEntityAdapter<ManagedTenant>({
  selectId: ({ managedTenantId }) => managedTenantId,
})

export const initialState: TenantsState = tenantsAdapter.getInitialState({
  didInvalidate: false,
  isFetching: false,
  error: null,
  default: null,
  managed: [],
  selected: null,
})

/**
 * Derives the next "tenants" state from the previous one and the current action.
 * Uses the adapter to make sure the next state is a valid entity state.
 * The returned state is a brand a new object.
 */
export const tenantsReducer = createReducer(
  initialState,
  on(getManagedTenants, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(getManagedTenantsResponse, (state, { managedTenants, defaultTenant, selected }) => {
    const managed = managedTenants.map(({ managedTenantId }) => managedTenantId)
    const nextState = { ...state, isFetching: false, managed, default: defaultTenant.managedTenantId, selected }

    return tenantsAdapter.setAll([...managedTenants, defaultTenant], nextState)
  }),
  on(getManagedTenantsInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true, isFetching: false })),
  on(requestManagedTenantChange, (state) => ({ ...state, isFetching: true })),
  on(changeManagedTenantSuccess, (state, action) => ({ ...state, selected: action.tenant.managedTenantId, isFetching: false })),
  on(changeManagedTenantInvalidate, (state, action) => ({ ...state, error: action.error, isFetching: false, didInvalidate: true }))
)

export default tenantsReducer

<cv-alert-bar
  *ngIf="singleLevel && !controller?.controls?.length && !hideAlertBar"
  variant="info"
  [title]="'administration_AddFilterDisclaimerTitle' | translate"
  [hideCloseButton]="true"
>
  <span translate>administration_AddFilterDisclaimerMessage</span>
</cv-alert-bar>
<div [formGroup]="$any(d)" *ngFor="let d of controller?.controls; let i = index">
  <div *ngIf="i !== 0" class="p-t-15 p-b-15 p-l-5 text-grey">
    <label>{{ d.get('condition')?.value }}</label>
  </div>
  <div style="display: flex">
    <cv-autocomplete-input
      #inp
      style="flex: 1; padding: 5px"
      [suggestions]="filtersFields('')"
      (inputChange)="inp.suggestions = filtersFields($event)"
      [forceSelection]="true"
      [showToggle]="true"
      formControlName="nameObj"
      (ngModelChange)="handleNameModelChange(d, $event)"
      [label]="'Name' | translate"
      [errorMessage]="'common_Required' | translate"
      [disabled]="readonly || this.columns.length === 1"
    ></cv-autocomplete-input>
    <cv-select
      #sel
      style="padding: 5px; width: 150px"
      [suggestions]="operations[inp.value?.data?.type !== 'date' && inp.value?.data?.type !== 'bool' ? 'rest' : inp.value?.data?.type]"
      formControlName="operation"
      [label]="'common_Operation' | translate"
      [errorMessage]="'common_Required' | translate"
      (selectionChanged)="setValueRequired(inp.value, sel.value, d)"
      [disabled]="readonly"
    >
    </cv-select>
    <span style="flex: 1; display: flex" [style.visibility]="isValueHidden(inp.value, sel.value) ? 'hidden' : 'visible'">
      <cv-autocomplete-input
        #inpvalue
        *ngIf="inp.value?.data?.type !== 'date' && inp.value?.data?.type !== 'bool'"
        style="flex: 1; padding: 5px"
        (inputChange)="filterValueField($event, d, inpvalue)"
        (selectionChanged)="filterValueSelected($event, d)"
        formControlName="valueObj"
        [label]="'common_Value' | translate"
        [errorMessage]="'common_Required' | translate"
        [debounceMilliseconds]="500"
        [disabled]="readonly"
      ></cv-autocomplete-input>
      <cv-datepicker
        *ngIf="inp.value?.data?.type === 'date'"
        style="flex: 1; padding: 5px"
        formControlName="value"
        (ngModelChange)="setValueDate(d, $event)"
        [label]="'common_Value' | translate"
        [errorMessage]="'common_Required' | translate"
        [disabled]="readonly"
      ></cv-datepicker>
      <cv-select
        *ngIf="inp.value?.data?.type === 'bool'"
        [suggestions]="booleanValues"
        style="flex: 1; padding: 5px"
        formControlName="value"
        [label]="'common_Value' | translate"
        [errorMessage]="'common_Required' | translate"
        [disabled]="readonly"
      >
      </cv-select>
    </span>
    <cv-icon-button
      *ngIf="!readonly"
      [disabled]="!!this.showAtLeastOneLine && i === 0"
      (click)="deleteFilter(i)"
      icon="close"
      style="margin: auto"
      buttonType="tertiary"
      attr.cv-data-test="{{ 'close-button_' + i }}"
    ></cv-icon-button>
  </div>
</div>
<div *ngIf="!!columns && !!controller?.controls?.length && !readonly" class="p-t-10 p-l-5">
  <cv-button
    *ngIf="showAndButton || singleLevel"
    [text]="'common_And' | translate"
    buttonType="secondary"
    leftIcon="add"
    (click)="andClicked()"
    [disabled]="controller.invalid"
    class="p-r-10"
    cv-data-test="and-button"
  ></cv-button>
  <cv-button
    *ngIf="showOrButton || singleLevel"
    [text]="'common_Or' | translate"
    buttonType="secondary"
    leftIcon="add"
    (click)="orClicked()"
    [disabled]="controller.invalid"
    cv-data-test="or-button"
  ></cv-button>
</div>
<div>
  <cv-button
    *ngIf="singleLevel && !controller?.controls?.length && !readonly"
    class="p-t-10"
    [text]="'administration_AddFilter' | translate"
    buttonType="secondary"
    leftIcon="add"
    size="tiny"
    (click)="andClicked()"
  ></cv-button>
</div>

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, iif } from 'rxjs'

@Injectable()
export class EnableYarpInterceptor implements HttpInterceptor {
  urls = ['/api/playbook', '/api/configurationmanager']

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return iif(
      () => this.urls.some((x) => req.url.includes(x)),
      next.handle(req.clone({ url: req.url.replace('api', 'apiproxy') })),
      next.handle(req)
    )
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseItem } from '@app/core/models/universal-search';

@Component({
  selector: 'app-recent-item',
  templateUrl: './recent-item.component.html',
  styleUrls: ['./recent-item.component.sass']
})
export class RecentItemComponent {
  @Input() search!: BaseItem
  @Output() manageItem: EventEmitter<BaseItem> = new EventEmitter<BaseItem>()

  handleManageItem(item: BaseItem): void {
    this.manageItem.emit(item)
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store'
import { values } from 'lodash-es'

import { userGroupsAdapter } from './userGroups.reducer'
import { UserGroupsState } from './userGroups.types'

const defaultSelectors = userGroupsAdapter.getSelectors()

export const selectUserGroupsState = createFeatureSelector<UserGroupsState>('userGroups')

export const selectUserGroups = createSelector(selectUserGroupsState, (state: UserGroupsState) => values(state.entities))

export const selectUserGroupsLoading = createSelector(selectUserGroupsState, (state) => state.isFetching)

export const selectUserGroupsError = createSelector(selectUserGroupsState, (state) => state.error)

export const selectUserGroupsLoaded = createSelector(selectUserGroupsState, (state) => state.loaded)

<div class="cv-unauthorized">
  <header class="cv-unauthorized-header">
    <img
        src="../../../../assets/img/logos/CoreView logo.svg"
        class="cv-unauthorized-logo"
        alt="company logo"
      />
  </header>
  <div class="cv-unauthorized-container">
    <div class="cv-unauthorized-content">
    <div class="cv-unauthorized-image">
      <img style="margin: auto" src="../assets/img/{{imageName}}" alt="unauthorized access" />
    </div>
    <div class="cv-unauthorized-text">
      <h2>{{ errorTitleKey | translate}}</h2>
      <div [innerHTML]="errorMessageKey | translate"></div>
      <cv-button leftIcon="logout" [text]="buttonText | translate" (click)="handleLogout()" *ngIf="showLogoutButton"></cv-button>
    </div>
  </div>
</div>

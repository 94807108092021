<div [tooltip]="tooltip" placement="top" [autoPlacement]="false" tooltipClass="cv-tooltip cv-header-tooltip" (click)="sort()" class="ag-header-cell-label">
  <span class="ag-header-cell-text">{{ params.displayName }}</span>
  <span *ngIf="params.enableSorting" class="ag-header-icon ag-header-label-icon">
    <span
      [class.ag-icon-asc]="this.params.column.isSortAscending()"
      [class.ag-icon-none]="this.params.column.isSortNone()"
      [class.ag-icon-desc]="this.params.column.isSortDescending()"
      class="ag-icon"
    ></span>
  </span>
</div>

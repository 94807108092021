/* eslint-disable @typescript-eslint/member-ordering */
import { ColDef, GridOptions, IRowNode } from '@ag-grid-community/core'
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
  QueryList,
  Optional,
  Self,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import { DownloadFileButtonDef, UploadCsvButtonDef } from '@app/core/models/management-action'
import { ReportDefinition, SelectionAction } from '@app/core/models/ReportDefinition'
import { ServerResponse } from '@app/core/models/ServerResponse'
import { ReportsService } from '@app/core/services/reports.service'
import { ClientDatagridComponent } from '@app/shared/components/client-datagrid/client-datagrid.component'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'
import { TranslateHelper } from '@coreview/coreview-library'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { Observable, of, Subject } from 'rxjs'
import { selectOnlineuserColumns } from '@app/store/onlineuser-columns/onlineuser-columns.selectors'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { cloneDeep, isEqual } from 'lodash-es'
import { Constants } from '@app/shared/utilities/constants'
import { buffer, debounceTime } from 'rxjs/operators'
import { BaseControlComponent } from '@coreview/coreview-components'
import { NgControl } from '@angular/forms'
import { BuildColAgGrid } from '@app/shared/utilities/build-col-ag-grid'

@Component({
  selector: 'app-target-selection',
  templateUrl: './target-selection.component.html',
  styleUrls: ['./target-selection.component.sass'],
})
export class TargetSelectionComponent extends BaseControlComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedTargets: any[] = []
  @Input() targetsToSelectDefinition!: TargetSelectionDefinition
  @Input() options?: TargetSelectionOptions
  @Input() detectSelectedTargetsChanges = false
  @Input() deselectAllWarning = false

  @Input()
  get value(): any[] {
    return this.selectedTargets
  }

  set value(model: any[]) {}

  @Output() selectedTargetsChanged = new EventEmitter<any[]>()
  @Output() actionTabButtonsClicked = new EventEmitter<DownloadFileButtonDef | UploadCsvButtonDef>()

  @ViewChild(DatagridComponent)
  set setTargetsToSelectGrid(targetsToSelectGrid: DatagridComponent) {
    if (targetsToSelectGrid) {
      this.targetsToSelectGrid = targetsToSelectGrid
      if (this.selectedTargets?.length) {
        this.targetsToSelectGrid.loadedItemsForSelectAll = this.selectedTargets
      }
    }
  }

  @ViewChildren(ClientDatagridComponent)
  set setTargetsGrid(targetsGrid: QueryList<ClientDatagridComponent>) {
    if (targetsGrid?.length > 0) {
      if (this.targetsToSelectDefinition.rowSelection === 'multiple') {
        this.selectedTargetsGrid = targetsGrid.get(0) || ({} as any)

        if (this.targetsToSelectDefinition.isClientDatagrid) {
          this.targetsToSelectGridClient = targetsGrid.get(1) || ({} as any)
        }
      } else if (this.targetsToSelectDefinition.isClientDatagrid) {
        this.targetsToSelectGridClient = targetsGrid.get(0) || ({} as any)
      }
    }
  }

  targetsToSelectGrid!: DatagridComponent
  targetsToSelectGridClient!: ClientDatagridComponent
  selectedTargetsGrid!: ClientDatagridComponent
  targetsToSelectGridClientActions: SelectionAction[] = []

  isMultiTarget = false
  params!: ApiDataParameters
  isSelectedTargetsViewOpen = false
  isPanelExpandedByDefault = false
  rowSelection?: 'single' | 'multiple'

  public refreshTargetsToSelectDataGrid = () => {
    if (this.targetsToSelectDefinition.isClientDatagrid) {
      this.targetsToSelectGridClient?.refresh()
    } else {
      this.targetsToSelectGrid?.refresh()
    }
  }

  public refreshDataGrid!:
    | ((params: ApiDataParameters) => {
        items: Observable<ServerResponse<any>>;
        cols: Observable<CoreViewColumn[]> | null;
      })
    | undefined

  gridOptions: GridOptions = {}

  targetsGridColumnsDefs: ColDef[] = []
  selectedTargetsGridColumnsDefs: ColDef[] = []
  selectedTargetsGridSelectionActions: SelectionAction[] = []

  rowSelected$ = new EventEmitter<{ rowData: any; isSelected: boolean }>()

  private destroyed$: Subject<boolean> = new Subject()

  constructor(
    private translateHelper: TranslateHelper,
    private service: ReportsService,
    private store: Store<RootState>,
    private cdRef: ChangeDetectorRef,
    private buildColAgGrid: BuildColAgGrid,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(ngControl)
  }

  ngOnInit(): void {
    this.rowSelection = this.targetsToSelectDefinition.rowSelection === 'none' ? undefined : this.targetsToSelectDefinition.rowSelection
    this.setTargetsToSelectGridDefinition()
    this.rowSelected$.pipe(buffer(this.rowSelected$.pipe(debounceTime(10)))).subscribe((rows) => {
      if (!this.isSelectedTargetsViewOpen) {
        this.onTargetsGridRowSelected(rows)
      }
    })
    this.isPanelExpandedByDefault = this.options
      ? this.options.isPanelExpandedByDefault || false
      : !!this.selectedTargets && this.selectedTargets.length > 0
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !!this.detectSelectedTargetsChanges &&
      !!changes.selectedTargets &&
      !isEqual(changes.selectedTargets.previousValue, changes.selectedTargets.currentValue)
    ) {
      this.updateSelectedTargetsGridData()
      this.refreshTargetsToSelectDataGrid()
      if (!this.isSelectedTargetsViewOpen) {
        this.checkSelectionDataGrid()
      }
    } else if (this.targetsToSelectChanged(changes)) {
      this.refreshTargetsToSelectDataGrid()
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  getSelectedTargets = (): any => of(this.selectedTargets)

  getTargetsToSelectClient = (): any =>
    this.targetsToSelectDefinition.getTargetsToSelectNativeFunction
      ? of(this.targetsToSelectDefinition.getTargetsToSelectNativeFunction())
      : this.targetsToSelectDefinition?.getTargetsToSelect

  onRowSelected = (event: any) => this.rowSelected$.emit({ rowData: event.data, isSelected: event.node.isSelected() })

  onTargetsGridRowSelected = (events: { rowData: any; isSelected: boolean }[]) => {
    this.selectedTargets = !this.targetsToSelectDefinition.isClientDatagrid
      ? this.targetsToSelectGrid.getSelectedRows()
      : this.targetsToSelectGridClient.getSelectedRow()
    this.updateSelectedTargetsGridData()
  }

  onSelectedTargetsChanged = () => {
    this.selectedTargets =
      this.targetsToSelectDefinition?.rowSelection !== 'single'
        ? this.selectedTargetsGrid.getSelectedRow() || []
        : this.selectedTargets
    this.selectedTargetsChanged.emit(this.selectedTargets)
  }

  onTargetsToSelectGridClientReady() {
    this.checkSelectionDataGrid()
    this.targetsToSelectGridClient.gridApi?.addEventListener('rowSelected', this.onRowSelected)
  }

  onTargetsToSelectGridServerReady() {
    this.targetsToSelectGrid.gridApi?.addEventListener('rowSelected', this.onRowSelected)
  }

  onSelectedTargetsGridReady() {
    if (!!this.selectedTargets && this.selectedTargets.length > 0) {
      this.selectedTargetsGrid?.gridApi.selectAll()
    }
    if (this.isSelectedTargetsViewOpen) {
      this.selectedTargetsGrid?.gridApi?.addEventListener('rowSelected', this.onSelectedTargetsChanged)
    }
  }

  onTargetColumnDefsDefined(event: any) {
    if (this.isMultiTarget) {
      this.setSelectedTargetsGridClientColumns()
    }
  }

  onModelUpdated(event: any) {
    this.checkSelectionDataGrid()
  }

  onAfterCollapseSelectedTargetsView() {
    this.selectedTargetsGrid.gridApi?.removeEventListener('rowSelected', this.onSelectedTargetsChanged)
    this.isSelectedTargetsViewOpen = false
    this.checkSelectionDataGrid()
    this.updateSelectedTargetsGridData()
    this.cdRef.detectChanges()
  }

  updateSelectedTargetView() {
    this.checkSelectionDataGrid()
    //force visual re-rendering of the selectedTargetView for edge case
    this.selectedTargetsGrid?.gridApi?.forEachNode((node) => {
      node.setSelected(true)
    })
  }

  onAfterExpandSelectedTargetsView() {
    this.selectedTargetsGrid?.gridApi.selectAll()
    this.updateSelectedTargetsGridData()
    this.isSelectedTargetsViewOpen = true
    this.selectedTargetsGrid?.gridApi?.addEventListener('rowSelected', this.onSelectedTargetsChanged)
    this.cdRef.detectChanges()
  }

  getSelectedTargetsCount() {
    return this.selectedTargets.length
  }

  selectedTargetsIsRowMaster = (data: any) =>
    this.options?.selectedTargetsIsRowMaster ? this.options.selectedTargetsIsRowMaster(data) : false

  private setTargetsToSelectGridDefinition() {
    this.isMultiTarget = this.targetsToSelectDefinition.rowSelection === 'multiple'
    this.targetsToSelectDefinition.entityIdField = this.targetsToSelectDefinition.targetPropertyId
    if (this.targetsToSelectDefinition.isClientDatagrid) {
      if (this.isMultiTarget) {
        this.setSelectedTargetsGridClientColumns()
      }
      this.setTargetsToSelectGridClientColumns()
    } else {
      if (this.isMultiTarget) {
        this.setTargetsToSelectGridServerActions()
      }

      this.refreshDataGrid = (params: ApiDataParameters) => ({
        items: this.getItems$(this.targetsToSelectDefinition?.url || '', this.targetsToSelectDefinition?.verb || 'get', params),
        cols: this.options?.columnDefs?.length
          ? of(this.options.columnDefs)
          : this.targetsToSelectDefinition?.isOnlineUsersType
          ? this.onlineUsersColumns()
          : null,
      })
    }
  }

  private setSelectedTargetsGridClientColumns() {
    this.selectedTargetsGridColumnsDefs = []

    if (this.options?.selectedItemsFields?.length) {
      this.options?.selectedItemsFields?.map((f) => {
        const filters =
          !!f.type && !!this.buildColAgGrid.generalFilters.get(f.type)
            ? this.buildColAgGrid.generalFilters.get(f.type)
            : {
                filter: 'agTextColumnFilter',
                filterParams: { suppressAndOrCondition: true },
              }
        const coldef: ColDef & { originalName: string } = {
          field: f.key,
          ...filters,
          type: f.type,
          floatingFilterComponent: 'genericFloatingFilter',
          floatingFilterComponentParams: { suppressFilterButton: false },
          headerName: this.translateHelper.instant(f.value),
          lockVisible: true,
          menuTabs: [],
          originalName: f.value,
          sortable: true,
          sortingOrder: ['asc', 'desc'],
          suppressMenu: true,
          valueFormatter: f.type ? this.buildColAgGrid.valueFormatterMap[f.type] : undefined,
          valueGetter: f.type ? this.buildColAgGrid.valueGetterMap[f.type] : null
        }

        this.selectedTargetsGridColumnsDefs.push(coldef)
      })
    } else if (this.options?.builtColumnDefs?.length) {
      this.selectedTargetsGridColumnsDefs = cloneDeep(this.options.builtColumnDefs)
    } else if (this.targetsToSelectGrid?.columnDefs?.length) {
      this.targetsToSelectGrid.columnDefs
        .filter((x) => !!x.originalName && !x.checkboxSelection)
        .forEach((x) => {
          const coldef: ColDef = {
            ...x,
            floatingFilterComponentParams: { ...(x.floatingFilterComponentParams || {}), suppressFilterButton: false },
          }
          this.selectedTargetsGridColumnsDefs.push(coldef)
        })
    }

    this.selectedTargetsGridColumnsDefs.unshift({
      headerComponentParams: {
        keepSelectionBetweenPages: true,
      },
      ...Constants.defaultSelectColumnDefinition,
    })
  }

  private setTargetsToSelectGridClientColumns() {
    if (this.options?.fields?.length) {
      this.options.fields.forEach((f) => {
        const filters =
          !!f.type && !!this.buildColAgGrid.generalFilters.get(f.type)
            ? this.buildColAgGrid.generalFilters.get(f.type)
            : {
                filter: 'agTextColumnFilter',
                filterParams: { suppressAndOrCondition: true },
              }
        const coldef: ColDef & { originalName: string } = {
          field: f.key,
          ...filters,
          type: f.type,
          floatingFilterComponent: 'genericFloatingFilter',
          floatingFilterComponentParams: { suppressFilterButton: false },
          headerName: this.translateHelper.instant(f.value),
          lockVisible: true,
          menuTabs: [],
          originalName: f.value,
          sortable: true,
          sortingOrder: ['asc', 'desc'],
          sort: f.sort,
          suppressMenu: true,
          valueFormatter: f.type ? this.buildColAgGrid.valueFormatterMap[f.type] : undefined,
          valueGetter: f.type ? this.buildColAgGrid.valueGetterMap[f.type] : null,
        }
        this.targetsGridColumnsDefs.push(coldef)
      })
    } else if (this.options?.builtColumnDefs?.length) {
      this.targetsGridColumnsDefs = cloneDeep(this.options.builtColumnDefs)
    }

    this.targetsGridColumnsDefs.unshift({
      headerComponentParams: {
        keepSelectionBetweenPages: true,
      },
      ...Constants.defaultSelectColumnDefinition,
    })
  }

  private setTargetsToSelectGridServerActions() {
    if (!this.targetsToSelectDefinition.selectionActions) {
      this.targetsToSelectDefinition.selectionActions = []
    }
  }

  private updateSelectedTargetsGridData() {
    const model = this.selectedTargetsGrid?.gridApi?.getFilterModel()
    this.selectedTargetsGrid?.gridApi?.setRowData(this.selectedTargets)
    this.selectedTargetsGrid?.gridApi?.setFilterModel(model)
    this.selectedTargetsGrid?.gridApi?.selectAll()
    this.onChange(this.selectedTargets)
    this.selectedTargetsChanged.emit(this.selectedTargets)
  }

  private checkSelection(node: IRowNode<any>) {
    if (node.data) {
      if (
        this.selectedTargets.find(
          (x) =>
            x[this.targetsToSelectDefinition.targetPropertyId ?? ''] === node.data[this.targetsToSelectDefinition.targetPropertyId ?? '']
        )
      ) {
        node.setSelected(true)
      } else {
        node.setSelected(false)
      }
    }
  }

  checkSelectionDataGrid() {
    if (!this.targetsToSelectDefinition.isClientDatagrid) {
      this.targetsToSelectGrid?.gridApi?.setServerSideSelectionState({
        selectAll: false,
        toggledNodes: this.selectedTargets.map((rowData: any) => rowData[this.targetsToSelectDefinition.targetPropertyId ?? '']),
      })
    } else {
      this.targetsToSelectGridClient?.gridApi?.forEachNode((node) => {
        this.checkSelection(node)
      })
    }
  }

  private getItems$ = (reportUrl: string, verb: Verb, params: any) => {
    this.params = { ...params, fields: params.fields }
    return this.service.getData(reportUrl, verb, Object.assign({}, this.params)) || of({} as ServerResponse<any>)
  }

  private targetsToSelectChanged(changes: SimpleChanges) {
    return !!changes.targetsToSelectDefinition?.currentValue?.getTargetsToSelectNativeFunction &&
      !!changes.targetsToSelectDefinition?.previousValue?.getTargetsToSelectNativeFunction &&
      !isEqual(changes.targetsToSelectDefinition.currentValue.getTargetsToSelectNativeFunction(), changes.targetsToSelectDefinition.previousValue.getTargetsToSelectNativeFunction())
  }

  deselectAllClient() {
    this.selectedTargetsGrid.gridApi?.removeEventListener('rowSelected', this.onSelectedTargetsChanged)
    this.selectedTargetsGrid.gridApi.deselectAll()
    this.onSelectedTargetsChanged()
    this.selectedTargetsGrid.gridApi?.addEventListener('rowSelected', this.onSelectedTargetsChanged)
  }

  deselectAllTargetsToSelect() {
    this.selectedTargets = []
    this.targetsToSelectGridClient.gridApi?.removeEventListener('rowSelected', this.onRowSelected)
    this.targetsToSelectGridClient.gridApi.deselectAll()
    this.updateSelectedTargetsGridData()
    this.targetsToSelectGridClient.gridApi?.addEventListener('rowSelected', this.onRowSelected)
  }

  deselectAllServer() {
    this.targetsToSelectGrid.gridApi?.removeEventListener('rowSelected', this.onRowSelected)
    this.selectedTargets = []
    this.targetsToSelectGrid.gridApi.setServerSideSelectionState({
      selectAll: false,
      toggledNodes: [],
    })
    this.updateSelectedTargetsGridData()
    this.targetsToSelectGrid.gridApi?.addEventListener('rowSelected', this.onRowSelected)
  }

  private onlineUsersColumns = () => this.store.select(selectOnlineuserColumns)
}

export interface TargetSelectionDefinition extends ReportDefinition {
  isClientDatagrid?: boolean;
  getTargetsToSelect?: Observable<any[]>;
  getTargetsToSelectNativeFunction?: () => any[];
  hideColumnsSelector?: boolean;
  targetPropertyId?: string;
  defaultFilters?: Record<string, string>;
  selectedItems?: any[];
}

export interface TargetSelectionOptions {
  isPanelExpandedByDefault?: boolean;
  selectedItemsFields?: { key: string; value: string; type?: string }[];
  fields?: { key: string; value: string; type?: string; sort?: 'asc' | 'desc' | null }[];
  columnDefs?: CoreViewColumn[];
  builtColumnDefs?: (ColDef & CoreViewColumn)[];
  selectedTargetsDetailCellRenderer?: any;
  selectedTargetsDetailCellRenderParams?: any;
  selectedTargetsIsRowMaster?: (data: any) => boolean;
}

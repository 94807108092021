<app-panel-steps
  *ngIf="steps.length > 0"
  [steps]="steps"
  [panelTitle]="'playbook_manageExceptions' | translate"
  [panelSubtitle]="getStepSubtitle()"
  [isSaving]="isSaving"
  (save)="submit()"
>
  <div step [class.hidden]="panelSteps?.activeStep?.stepKey !== 'selectTargets'" class="flex-full-height">
    <mat-expansion-panel
      [expanded]="isPanelExpandedByDefault"
      class="info-panel m-t-25"
      [ngClass]="{ 'expanded-flex': !!isSelectedTargetsViewOpen }"
      (opened)="onAfterExpandSelectedTargetsView()"
      (afterCollapse)="onAfterCollapseSelectedTargetsView()"
      displayMode="flat"
      cv-data-test="selected-targets-panel"
    >
      <mat-expansion-panel-header collapsedHeight="41px" expandedHeight="41px">
        <span translate [translateParams]="{ count: selectedTargets.length }">management_ItemsSelectedCount</span>
        <span class="m-r-10 hidden-shown-text">
          <span *ngIf="!isSelectedTargetsViewOpen" translate>common_Show</span>
          <span *ngIf="!!isSelectedTargetsViewOpen" translate>common_Hide</span>
        </span>
      </mat-expansion-panel-header>
      <div class="flex-full-height" style="height: 54vh">
        <app-client-datagrid
          #selectedTargetsGrid
          *ngIf="selectedTargetsGridColumnsDefs && selectedTargetsGridColumnsDefs.length > 0"
          [getItems]="getSelectedTargets"
          [selectionActions]="selectedTargetsGridSelectionActions"
          [columnDefsBuiltExternally]="selectedTargetsGridColumnsDefs"
          [hideColumnsSelector]="true"
          [autosizeAllColumns]="true"
          (gridReady)="onGridReady()"
          (rowSelected)="addOrRemoveRowFromSelectedTargets($event)"
          style="flex: 1"
        >
        </app-client-datagrid>
      </div>
    </mat-expansion-panel>
    <div [ngClass]="{ hidden: !!isSelectedTargetsViewOpen }" class="flex-full-height">
      <app-policy-item-list
        [policy]="policy"
        [reportDefinition]="reportDefinition"
        [reportType]="reportType"
        (rowSelected)="onTargetsGridRowSelected($event)"
        (columnDefsDefined)="onTargetColumnDefsDefined()"
        (modelUpdated)="onModelUpdated()"
        (metadataChanged)="metadataChanged($event)"
      ></app-policy-item-list>
    </div>
  </div>

  <div step *ngIf="panelSteps?.activeStep?.stepKey === 'manageException'" class="manage-exception-container">
    <div class="expiration-date-container">
      <span translate>playbook_expirationDate</span>
      <ng-container [formGroup]="form">
        <cv-switch formControlName="isNeverExpire" (checkedChange)="changeFormDefinition()">{{
          'playbook_exceptionNeverExpire' | translate
        }}</cv-switch>
        <ng-container *ngIf="!form.get('isNeverExpire')?.value">
          <cv-datepicker
            [label]="'playbook_exceptionExpiredOn' | translate"
            [errorMessage]="(form.get('expirationDate')?.errors?.past ? 'playbook_ExpirationNotInPast' : 'common_Required') | translate"
            formControlName="expirationDate"
            style="width: 50%"
          >
          </cv-datepicker>
        </ng-container>
        <cv-textarea
          [required]="true"
          [label]="'management_AddNotes' | translate"
          formControlName="notes"
          style="width: 50%"
      >
      </cv-textarea>
      </ng-container>
    </div>
  </div>

  <div step *ngIf="panelSteps?.activeStep?.stepKey === 'complete'" style="overflow: hidden; display: flex; flex-direction: column; flex: 1">
    <div class="summary-wrapper">
      <app-summary-card [cardInfo]="populateSummaryCardException()"> </app-summary-card>
      <app-summary-card [cardInfo]="populateSummaryCardConfiguration()"> </app-summary-card>
    </div>
  </div>
</app-panel-steps>

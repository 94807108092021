<lib-navigation
  *ngIf="!!activeRoute"
  [loading]="loading"
  [activeRoute]="activeRoute"
  [isMenuOpen]="isMenuOpen"
  [menus]="menus"
  [bottomMenu]="bottomMenu"
  [showBreadcrumb]="true"
  [favoritesMenuId]="favoritesMenuId"
  (onMenuOpenToggled)="saveMenuOpenPreference($event)"
>
  <div banners>
    <lib-banner *ngIf="showExpirationBanner" (closeClicked)="closeExpirationBanner()" [message]="getExpirationMessage()">
    </lib-banner>
    <lib-banner *ngIf="showImportErrorBanner" (closeClicked)="closeImportErrorBanner()">
      <div [innerHTML]="'common_ImportErrorBannerMessage' | translate"></div>
    </lib-banner>  
  </div>
  <router-outlet routerContent></router-outlet>
</lib-navigation>

import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { ManagementAction } from '@app/core/models/management-action'
import { EnhancedJsonFormData } from '@app/shared/models/enhanced-json-form-data'
import { PanelStep } from '@app/shared/models/panel-step'
import { TranslateHelper } from '@coreview/coreview-library'
import { FormBuilderComponent } from '@coreview/coreview-dynamoforms'
import { ManagementHelperService } from '@app/shared/utilities/management.helper.service'
import { DynamoFormHelperService } from '@app/shared/utilities/dynamo-form.helper.service'
import { JsonFormData } from '@coreview/coreview-dynamoforms/interfaces/json-form-data.interface'
import { ApiclientService } from '@app/core/services/apiclient.service'
import { ManagementActionsService } from '@app/core/services/management-actions.service'
import { SummaryCard } from '@app/shared/models/summary-card'
import { Subject, forkJoin } from 'rxjs'
import { RightPanelRef, RightPanelService } from '@app/core/services/right-panel.service'
import { ActionPanelComponent } from '@app/shared/components/action-panel/action-panel.component'
import { ApplicationInsightService } from '@app/core/services/application-insight.service'
import { ToastService } from '@coreview/coreview-components'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { DynamoFormSummaryHelperService } from '@app/shared/utilities/dynamo-form-summary.helper.service'
import { debounceTime, filter } from 'rxjs/operators'

@Component({
  selector: 'app-view-mode-action-panel',
  templateUrl: './view-mode-action-panel.component.html',
  styleUrls: ['./view-mode-action-panel.component.sass'],
})
export class ViewModeActionPanelComponent implements OnInit {
  @ViewChildren(FormBuilderComponent)
  set setFormBuilders(forms: QueryList<FormBuilderComponent>) {
    if (forms?.length > 0) {
      this.formBuilder = forms.last
    }
  }

  @Input() actionId!: number
  @Input() actionTitle!: string
  @Input() selectedTargets: any[] = []
  // use this when the form builder itself populates some value and you need to wait until
  // it finishes firing all initial events before calculating the summary EG: BUG 26807
  @Input() waitFormBuilderEventFired = false

  action!: ManagementAction
  steps: PanelStep[] = []
  jsonFormData!: EnhancedJsonFormData | undefined
  form: UntypedFormGroup = this.fb.group({})
  formBuilder?: FormBuilderComponent
  initialValues = {}
  panelSubtitle = ''
  summaryCards: SummaryCard[] = []
  hasPermissionToAction = false

  initialValuesLoaded = false

  basePortalApiUrl: string

  formBuildEventFired: Subject<{ key: string; event: { type: string; data: any } }> = new Subject<{
    key: string
    event: { type: string; data: any }
  }>()

  constructor(
    private translateHelper: TranslateHelper,
    private dynamoFormsHelper: DynamoFormHelperService,
    private dynamoFormSummaryHelperService: DynamoFormSummaryHelperService,
    private fb: UntypedFormBuilder,
    private managementHelperService: ManagementHelperService,
    private managementAcionsService: ManagementActionsService,
    private sharedHelperService: SharedHelperService,
    private rightPanelService: RightPanelService,
    private rightPanelRef: RightPanelRef,
    private toastService: ToastService,
    private appInsights: ApplicationInsightService,
    apiClient: ApiclientService
  ) {
    this.basePortalApiUrl = apiClient.basePortalApiUrl

    this.formBuildEventFired
      .pipe(
        debounceTime(300),
        filter(() => this.waitFormBuilderEventFired)
      )
      .subscribe((event: { key: string; event: { type: string; data: any } }) => {
        this.prepareSummary()
      })
  }

  ngOnInit(): void {
    forkJoin({
      action: this.managementAcionsService.getActionById(this.actionId),
      hasPermission: this.managementAcionsService.canViewAction(this.actionId),
    }).subscribe(
      ({ action, hasPermission }) => {
        if (action) {
          this.action = action
          if (!!this.action.formSchema && this.action.formSchema !== 'null') {
            this.dynamoFormsHelper.getFormattedJsonFormData(this.action.formSchema).subscribe((formData) => {
              if (formData) {
                this.jsonFormData = formData
                this.steps = this.dynamoFormsHelper.getSubSteps(this.jsonFormData, 'setProps', 0)
                this.initData()
              }
            })
          }
        }
        this.hasPermissionToAction = hasPermission
      },
      (err) => {
        this.toastService.open({
          id: 'err',
          variant: 'error',
          title: this.translateHelper.instant('common_Error'),
          message: this.translateHelper.instant('error_ManagementActionCouldNotBeRetrieved'),
        })
        this.appInsights.trackError(err)
      }
    )
  }

  initData = () => {
    this.managementHelperService.getInitialDataForAction(this.action, this.selectedTargets)?.subscribe((initialData: any) => {
      this.initialValues = this.action.initDataMap
        ? this.managementHelperService.getMappedDataForAction(this.action.initDataMap, initialData, this.selectedTargets)
        : initialData

      if (this.initialValues) {
        if (Object.keys(this.jsonFormData?.options?.templates || {}).length) {
          FormBuilderComponent.replaceTemplates(this.jsonFormData as JsonFormData, this.jsonFormData?.options?.templates as any)
        }
        this.dynamoFormsHelper.bindInitialData(this.jsonFormData as EnhancedJsonFormData, this.initialValues)
      }
      if (this.selectedTargets.length === 1) {
        this.panelSubtitle = this.getDisplayValue()
      }
      this.initialValuesLoaded = true
    })
  }

  getDisplayValue() {
    let displayValue = ''
    if (!!this.action.targetPropertyDisplayValue && this.action.targetPropertyDisplayValue.length > 0) {
      this.action.targetPropertyDisplayValue.forEach((key) => {
        if (displayValue) {
          return
        }
        displayValue = (this.initialValues || ({} as any))[key] || this.selectedTargets[0][key]
      })
    }

    if (displayValue) {
      return displayValue
    } else if (this.action.targetPropertyId) {
      return (this.initialValues || ({} as any))[this.action.targetPropertyId] || this.selectedTargets[0][this.action.targetPropertyId]
    }
    return ''
  }

  onFormBuilderReady() {
    if (!this.waitFormBuilderEventFired) {
      this.prepareSummary()
    }
  }

  onEventFired(event: { key: string; event: { type: string; data: any } }) {
    this.formBuildEventFired.next(event)
  }

  canManage() {
    return this.hasPermissionToAction && this.sharedHelperService.isManagement() && this.managementHelperService.isManagementEnabled()
  }

  edit() {
    this.rightPanelRef.close()
    this.rightPanelService.open({
      type: ActionPanelComponent,
      data: {
        action: this.action,
        selectedTargets: this.selectedTargets,
        width: '100%',
      },
    })
  }

  cancel() {
    this.rightPanelRef.close()
  }

  private prepareSummary = () => {
    this.summaryCards = []

    if (this.jsonFormData) {
      if ((this.steps?.length || 0) > 0) {
        this.steps?.forEach((subStep) => {
          if (!subStep.subSteps?.length) {
            this.summaryCards = this.summaryCards.concat(
              this.hasCustomSummary(subStep.stepKey) && this.action.summaryStepMap
                ? this.dynamoFormSummaryHelperService.customSummaryMapping[this.action.summaryStepMap[subStep.stepKey]](
                    this.formBuilder?.getValue(),
                    this.jsonFormData as EnhancedJsonFormData,
                    this.selectedTargets
                  )
                : [
                    {
                      title: subStep.title,
                      properties: this.dynamoFormSummaryHelperService.getSummaryProperties(
                        this.jsonFormData?.properties[subStep.stepKey] as EnhancedJsonFormData,
                        this.formBuilder?.getValue()
                      ),
                    },
                  ]
            )
          } else {
            this.summaryCards = this.summaryCards.concat(
              ...subStep.subSteps.map((x) =>
                this.hasCustomSummary(x.stepKey) && this.action.summaryStepMap
                  ? this.dynamoFormSummaryHelperService.customSummaryMapping[this.action.summaryStepMap[x.stepKey]](
                      this.formBuilder?.getValue(),
                      this.jsonFormData as EnhancedJsonFormData,
                      this.selectedTargets
                    )
                  : [
                      {
                        title: x.summaryCardTitlePreffix
                          ? `${this.translateHelper.instant(x.summaryCardTitlePreffix)} - ${this.translateHelper.instant(x.title)}`
                          : x.title,
                        properties: this.dynamoFormSummaryHelperService.getSummaryProperties(
                          ((this.jsonFormData?.properties || {})[subStep.stepKey]?.properties || {})[x.stepKey] as EnhancedJsonFormData,
                          this.formBuilder?.getValue()
                        ),
                      },
                    ]
              )
            )
          }
        })
      } else {
        if (!!this.action.summaryStepMap && !!Object.keys(this.action.summaryStepMap).length) {
          Object.keys(this.action.summaryStepMap).forEach((summKey) => {
            if (this.dynamoFormSummaryHelperService.customSummaryMapping[(this.action.summaryStepMap || {})[summKey]] instanceof Function) {
              this.summaryCards = this.summaryCards.concat(
                this.dynamoFormSummaryHelperService.customSummaryMapping[(this.action.summaryStepMap || {})[summKey]](
                  this.formBuilder?.getValue(),
                  this.jsonFormData as EnhancedJsonFormData,
                  this.selectedTargets
                )
              )
            }
          })
        } else {
          this.summaryCards.push({
            title: this.jsonFormData?.title?.startsWith('management_') ? this.jsonFormData.title : 'management_Properties',
            properties: this.dynamoFormSummaryHelperService.getSummaryProperties(this.jsonFormData, this.formBuilder?.getValue()),
          })
        }
      }
    }
  }

  private hasCustomSummary(summaryKey: string): boolean {
    return (
      !!this.action.summaryStepMap &&
      !!this.action.summaryStepMap[summaryKey] &&
      this.dynamoFormSummaryHelperService.customSummaryMapping[this.action.summaryStepMap[summaryKey]] instanceof Function
    )
  }
}

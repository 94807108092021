<div *ngIf="!centered" class="title-div">
  <span style="flex: 1">
    <span [class]="type" class="icon-div">
      <mat-icon class="icon">{{ iconByStatus[type] }}</mat-icon>
    </span>
    <span class="title">{{ title | translate }}</span>
  </span>
  <cv-close-button class="close-icon" (click)="click($event, 'close')"></cv-close-button>
</div>
<div *ngIf="centered" class="title-div title-div-centered">
  <div class="icon-centered-container">
    <div [class]="type" class="icon-div icon-centered">
      <mat-icon class="icon">{{ iconByStatus[type] }}</mat-icon>
    </div>
    <cv-close-button class="close-icon close-icon-centered" (click)="click($event, 'close')"></cv-close-button>
  </div>
  <div class="title">{{ title | translate }}</div>
</div>
<div style="text-align: center" [class.text-centered]="centered" [innerHTML]="text | translate"></div>
<div mat-dialog-actions class="actions" [class.actions-right]="!centered">
  <div [class.actions-centered]="centered">
    <cv-button
      class="button"
      *ngIf="secondaryButton"
      buttonType="secondary"
      (click)="click($event, secondaryButton.key)"
      [text]="secondaryButton.text | translate"
      [color]="type"
      size="tiny"
    ></cv-button>
    <cv-button
      class="button"
      *ngIf="primaryButton"
      buttonType="primary"
      (click)="click($event, primaryButton.key)"
      [text]="primaryButton.text | translate"
      [color]="type"
      size="tiny"
      cdkFocusInitial
    ></cv-button>
  </div>
</div>

<div class="container">
    <div class="p-l-20 panel-title">
        <div style="flex: 1; font-size: 14px; font-weight: 600;" class="uppercase">
            {{item?.eventId || '&nbsp;'}}
        </div>
    </div>

    <div class="p-20" style="padding-left: 15px; overflow: auto; padding-right: 5px; flex: 1">
        <ng-container *ngIf="!item">
            <div class="title-skeleton">
                &nbsp;
            </div>
            <div class="content-skeleton">
                &nbsp;
            </div>
        </ng-container>

        <ng-container *ngIf="item">
            <div class="title">
                <span style="flex: 1">{{item.title}}</span>
            </div>
            <div class="content">
                <ng-container *ngIf="type == 'serviceHealthIssues' || type == 'microsoft365ServiceHealth'">
                    <div class="section-title" translate>
                        common_Details
                    </div>

                    <div class="section-content">
                        <span class="item-title" translate>common_Classification</span><span class="item-value">{{item.classification}}</span>
                        <span class="item-title" translate>common_Status</span>
                        <span class="item-value">
                            <mat-chip-listbox aria-label="Status" [selectable]="false" hideSingleSelectionIndicator>
                                <mat-chip-option [color]="getChipColorByStatus(item.status)" [selected]="true" [disableRipple]="true">
                                        {{item.status}}
                                </mat-chip-option>
                            </mat-chip-listbox>
                        </span>
                        <span class="item-title" translate>common_StartDate</span><span class="item-value">{{formatDate(item.startDateTime)}}</span>
                        <span class="item-title" *ngIf="type == 'serviceHealthIssues'" translate>common_EndDate</span><span class="item-value" *ngIf="type == 'serviceHealthIssues'">{{formatDate(item.endDateTime)}}</span>
                        <span class="item-title" translate>common_UpdatedDate</span><span class="item-value">{{formatDate(item.lastModifiedDateTime)}}</span>
                        <span class="item-title" translate>common_Service</span><span class="item-value">{{item.service}}</span>
                        <span class="item-title" translate>common_Feature</span><span class="item-value">{{item.featureGroup}} | {{item.feature}}</span>
                    </div>

                    <div class="section-title" style="margin-top: 16px" translate>
                        common_Updates
                    </div>

                    <div *ngFor="let i of item.posts" class="section-content">
                        <div class="item-date">{{formatDate(i.publishedTime)}}</div>

                        <div class="item-inner-html" [innerHTML]="i.messageText"></div>
                    </div>
                   
                    <cv-button style="margin-top: 16px" *ngIf="item.externalLink" (click)="goToLink(item.externalLink)"
                        [text]="'common_GoToSite' | translate" buttonType="secondary" ></cv-button>
                </ng-container>

                <ng-container *ngIf="type == 'microsoft365Messages'">
                    <div class="section-title" translate>
                        common_Details
                    </div>

                    <div class="section-content">
                        <span class="item-title" translate>common_Level</span><span class="item-value">{{item.severity}}</span>
                        <span class="item-title" *ngIf="item.isMajorChange" translate>common_MajorUpdate</span><span *ngIf="item.isMajorChange" class="item-value" translate>common_Yes</span>
                        <span class="item-title" translate>common_StartDate</span><span class="item-value">{{formatDate(item.startDateTime)}}</span>
                        <span class="item-title" translate>common_EndDate</span><span class="item-value">{{formatDate(item.endDateTime)}}</span>
                        <span class="item-title" translate>common_UpdatedDate</span><span class="item-value">{{formatDate(item.lastModifiedDateTime)}}</span>
                        <span class="item-title" *ngIf="item.actionRequiredByDateTime">common_ActionRequiredBy</span><span class="item-value" *ngIf="item.actionRequiredByDateTime">{{formatDate(item.actionRequiredByDateTime)}}</span>                        
                        <span class="item-title" translate>common_Services</span><span class="item-value">{{item.services}}</span>
                        <span class="item-title" translate>common_Tags</span><span class="item-value">{{item.messageTags}}</span>
                    </div>

                    <div class="section-title" style="margin-top: 16px" translate>
                        common_MessageSummary
                    </div>

                    <div class="section-content">
                        <div class="item-inner-html" [innerHTML]="item.content"></div>
                    </div>

                    <cv-button style="margin-top: 16px" *ngIf="item.externalLinks && item.externalLinks[0]" (click)="goToLink(item.externalLinks[0])"
                        [text]="'common_GoToSite' | translate" buttonType="secondary" ></cv-button>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
<div class="card-header">
  <mat-icon *ngIf="!!cardInfo.icon" class="summary-icon">{{ cardInfo.icon }}</mat-icon
  ><span style="flex: 1" class="uppercase" translate>{{ cardInfo.title }}</span>
  <cv-button
    *ngIf="!!cardInfo.editFunction"
    (click)="cardInfo.editFunction()"
    buttonType="tertiary"
    leftIcon="edit"
    [text]="'common_Edit' | translate"
    attr.cv-data-test="{{ cardInfo.title }}_summary-edit"
  ></cv-button>
</div>
<div *ngIf="!!cardInfo.subtitle" style="margin: 10px 0; font-weight: 600" translate>{{ cardInfo.subtitle }}</div>
<cv-alert-bar *ngIf="!!cardInfo.disclaimer?.message" [variant]="cardInfo.disclaimer?.variant || 'info'" [hideCloseButton]="true">
  <span>{{ cardInfo.disclaimer?.message || '' | translate }}</span>
</cv-alert-bar>
<div class="summary-container">
  <div class="m-b-10" *ngFor="let property of cardInfo.properties">
    <div class="m-b-10" *ngFor="let prop of property | keyvalue" attr.cv-data-test="{{ prop.key }}_summary-prop">
      <div>
        <div class="summary-value" *ngIf="isTable(prop.value); else not_table">
          <div
            *ngIf="!!$any(prop.value).values && $any(prop.value).values.length > 0"
            [style.gridTemplateColumns]="'repeat(' + $any(prop.value).headers.length + ', 1fr)'"
            [style.rowGap]="'5px'"
            [style.display]="'grid'"
          >
            <span *ngFor="let header of $any(prop.value).headers" class="summary-label">
              {{ getKey(header.displayValue) }}
            </span>
            <ng-container *ngFor="let value of $any(prop.value).values; let i = index">
              <div
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
                *ngFor="let col of $any(prop.value).headers"
                [tooltip]="value[col.name] || ''"
                tooltipClass="long-tooltip"
                [innerHtml]="value[col.name]"
              >
              </div>
            </ng-container>
          </div>
        </div>
        <ng-template #not_table>
          <div *ngIf="isTree(prop.value); else not_tree">
            <app-light-readonly-treeview
              [data]="prop.value.treeData"
              [preventTranslation]="prop.value.preventTranslation"
            ></app-light-readonly-treeview>
          </div>
          <ng-template #not_tree>
            <div *ngIf="isArray(prop.value); else not_array">
              <div class="summary-label">{{ getKey(prop.key) }}</div>
              <div class="summary-value">
                <ng-container *ngFor="let val of $any(prop.value)">
                  <div *ngIf="!truncateValues">
                    {{ val }}
                  </div>
                  <div *ngIf="truncateValues" [tooltip]="val" tooltipClass="long-tooltip" class="fit-content">
                    {{ val | truncate : truncateValues }}
                  </div>
                </ng-container>
              </div>
            </div>
            <ng-template #not_array>
              <div *ngIf="isObject(prop.value); else not_object">
                <div
                  class="m-b-10"
                  *ngFor="let innerProp of prop.value | keyvalue : order"
                  attr.cv-data-test="{{ innerProp.key }}_summary-prop"
                >
                  <div class="summary-label">{{ innerProp.key }}</div>
                  <span class="summary-value" *ngIf="!isHtml">{{ innerProp.value }}</span>
                  <span class="summary-value" *ngIf="isHtml" [innerHtml]="innerProp.value"></span>
                </div>
              </div>
              <ng-template #not_object>
                <div class="summary-label">{{ getKey(prop.key) }}</div>
                <span class="summary-value" *ngIf="!isHtml">{{ prop.value }}</span>
                <span class="summary-value" *ngIf="isHtml" [innerHtml]="prop.value"></span>
              </ng-template>
            </ng-template>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</div>

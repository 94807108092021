<div class="container">
    <div class="p-r-20" style="display: flex;">
        <span style="padding-left: 15px; font-weight: 600; margin-bottom: 10px; flex: 1; line-height: 40px;" class="uppercase" translate>
            reports_DeviceHistory
        </span>

        <cv-button (click)="reload()" *ngIf="dataResult" buttonType="tertiary"
            leftIcon="refresh" [text]="'common_Reload' | translate"></cv-button>
    </div>

    <div style="padding-left: 15px; overflow: auto; padding-right: 5px; flex: 1">
        <div class="section">
            <div class="title-skeleton" *ngIf="!dataResult">
                &nbsp;
            </div>
            <div class="content-skeleton" *ngIf="!dataResult">
                &nbsp;
            </div>

            <div class="title" *ngIf="dataResult">
                <mat-icon class="summary-icon">info</mat-icon>
                <span style="flex: 1" class="uppercase" translate>reports_DeviceName</span>
            </div>
            <div class="content" *ngIf="dataResult">
                {{dataResult?.deviceName}}
            </div>
        </div>

        <div class="section" *ngIf="canViewLocateDevice && (!dataResult || (dataResult?.isLocateDeviceActionSupported || !!locateDeviceErrorMessage))">
            <div class="title-skeleton" *ngIf="!dataResult">
                &nbsp;
            </div>

            <ng-container *ngIf="dataResult && (dataResult?.isLocateDeviceActionSupported || !!locateDeviceErrorMessage)">
                <div class="title" *ngIf="dataResult">
                    <mat-icon class="summary-icon" style="line-height: 25px;">place</mat-icon>
                    <span style="flex: 1; line-height: 30px;" class="uppercase" translate>reports_DeviceLocation</span>

                    <cv-button (click)="locateDevice()"
                        *ngIf="!!dataResult?.isLocateDeviceActionSupported && !retrievingLocation && !lastLocateDeviceAction"
                        style="margin-top: -5px; margin-right: -5px" buttonType="tertiary" leftIcon="place"
                        [text]="'common_LocateDevice' | translate"></cv-button>
                </div>
                <div class="content" *ngIf="dataResult">
                    <div *ngIf="!retrievingLocation && locateDeviceErrorMessage">
                        <span translate>{{locateDeviceErrorMessage}}</span>
                    </div>

                    <div *ngIf="!!retrievingLocation">
                        <span translate>common_RetrievingDeviceLocation</span>
                    </div>

                    <div *ngIf="!retrievingLocation && !!lastLocateDeviceAction && !!lastLocateDeviceAction.deviceLocation">
                        <div style="margin-top: 10px; margin-bottom: 10px; font-weight: 300; font-size: 12px; line-height: 16px;">
                            <span translate>common_LastUpdatedOn</span>
                            {{formatDateAndHours(lastLocateDeviceAction.deviceLocation.lastCollectedDateTime)}}
                        </div>

                        <ng-container *ngFor="let mapData of mapsData">
                            <div style="height: 300px" leaflet
                                [leafletMarkerCluster]="mapData.markers"
                                [leafletMarkerClusterOptions]="mapData.clusterOptions || {}"
                                [leafletOptions]="mapData.options"></div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="section" *ngIf="!dataResult || (dataResult.deviceActionResults && dataResult.deviceActionResults.length > 0)">
            <div class="title-skeleton" *ngIf="!dataResult">
                &nbsp;
            </div>

            <div class="title" *ngIf="dataResult">
                <mat-icon class="summary-icon">history</mat-icon>
                <span style="flex: 1" class="uppercase" translate>reports_ActionsHistory</span>
            </div>

            <div class="content" *ngIf="dataResult?.deviceActionResults && dataResult?.deviceActionResults.length > 0">
                <div *ngFor="let result of dataResult?.deviceActionResults; let i = index">
                    <div class="device-actions-result">
                        <div>
                            <mat-icon class="content-icon">history</mat-icon>
                        </div>
                        <div class="device-actions-result-info">
                            <div class="uppercase" style="font-size: 10px; letter-spacing: 1.5px; margin-bottom: 5px;">
                                {{formatDateAndHours(result.startDateTime)}}
                            </div>
                            <div style="font-size: 14px; margin-bottom: 10px;" translate>
                                {{result.actionName}}
                            </div>
                            <div style="margin-bottom: 10px;">
                                <cv-badge [variant]="getBadgeVariant(result.actionState)"
                                    [text]="result.actionState | translate"
                                    [leftIcon]="null"></cv-badge>
                            </div>
                            <div style="font-size: 12px;">
                                <span translate>common_LastUpdatedOn</span>
                                {{formatDateAndHours(result.lastUpdatedDateTime)}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

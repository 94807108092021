<div>
  <div class="actionFilterDetails-titleContainer">
    <span class="actionFilterDetails-icon" [ngClass]="iconName | fabricIconClass"></span>
    <span class="title" translate>workflow_Filters</span>
  </div>

  <ng-container *ngFor="let group of action?.filter?.conditionGroups; let i = index">
    <div class="actionFilterDetails-filterGroup">
      <p translate>{{ getGroupTitle(i) }}</p>
      <ul *ngFor="let condition of group?.conditions">
        <app-action-filter-condition *ngIf="condition" [condition]="condition"></app-action-filter-condition>
      </ul>
    </div>
  </ng-container>
</div>

import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'
import { AuthenticatedUser } from '@app/core/models/authenticated-user'
import { OperatorState } from './operators.types'
import { getOperatorById, getOperatorByIdInvalidate, getOperatorByIdResponse } from './operators.actions'


export const operatorsAdapter: EntityAdapter<AuthenticatedUser> = createEntityAdapter<AuthenticatedUser>({
  selectId: ({ userName }) => userName,
})

export const initialState: OperatorState = operatorsAdapter.getInitialState({
  didInvalidate: false,
  isFetching: false,
  error: null,
})

/**
 * Derives the next "operators" state from the previous one and the current action.
 * Uses the adapter to make sure the next state is a valid entity state.
 * The returned state is a brand a new object.
 */
export const operatorsReducer = createReducer(
  initialState,
  on(getOperatorById, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(getOperatorByIdResponse, (state, operator) => operatorsAdapter.setOne(operator, state)),
  on(getOperatorByIdInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true })),
)

export default operatorsReducer

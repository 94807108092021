import { RowNode } from '@ag-grid-community/core'
import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core'
import { BuildColParameter } from '@app/shared/utilities/build-col-ag-grid'
import { of } from 'rxjs'
import { Constants } from '@app/shared/utilities/constants'
import { BaseControlComponent } from '@coreview/coreview-components'
import { NgControl } from '@angular/forms'
import { CustomComponentReady } from '@coreview/coreview-dynamoforms'

@Component({
  selector: 'app-license-allocator',
  templateUrl: './license-allocator.component.html',
  styleUrls: ['./license-allocator.component.sass'],
})
export class LicenseAllocatorComponent extends BaseControlComponent implements CustomComponentReady {
  data!: LicenseAllocatorData[]
  
  gridOptions = {
    suppressRowClickSelection: true,
    defaultColDef: {
      ...Constants.defaultColumnsDefinition,
      wrapHeaderText: true,
      autoHeaderHeight: true
    }
  }

  columnDefs: BuildColParameter = {
    selectedCols: ['sku', 'total', 'consumed', 'notAssigned', 'newAssigned'],
    allcols: [
      {
        originalName: 'sku',
        name: 'sku',
        translate: 'licenses_SearchSku',
        type: 'string',
        filter: { type: 'string', name: 'sku' },
        agColDef: {
          wrapText: true,
          autoHeight: true,
        },
      },
      {
        originalName: 'total',
        name: 'total',
        translate: 'licenses_Purchased',
        type: 'number',
        filter: { type: 'number', name: 'total' },
        agColDef: {
          wrapText: true,
          autoHeight: true,
        },
      },
      {
        originalName: 'consumed',
        name: 'consumed',
        translate: 'licenses_Consumed',
        type: 'number',
        filter: { type: 'number', name: 'consumed' },
        agColDef: {
          wrapText: true,
          autoHeight: true,
        },
      },
      {
        originalName: 'notAssigned',
        name: 'notAssigned',
        translate: 'licenses_NotAllocatedToPools',
        type: 'number',
        filter: { type: 'number', name: 'notAssigned' },
        agColDef: {
          wrapText: true,
          autoHeight: true,
        },
      },
      {
        originalName: 'newAssigned',
        name: 'newAssigned',
        translate: 'licenses_AllocatedToPool',
        type: 'number',
        filter: { type: 'number', name: 'newAssigned' },
        agColDef: {
          wrapText: true,
          autoHeight: true,
          cellRenderer: 'editCellRendererer',
          cellRendererParams: {
            getMaxValue: (rowData: any) => rowData.notAssignedOriginal + rowData.assigned,
            getMinValue: () => 0,
            onModelChanged: (params: {
              node: RowNode;
              data: {
                assigned: number;
                available: number;
                consumed: number;
                isActive: boolean;
                newAssigned: number;
                notAssigned: number;
                notAssignedOriginal: number;
                sku: string;
                skuId: string;
                skuPartNumber: string;
                total: number;
              };
            }) => {
              const license = params.data
              if (license.newAssigned >= 0 && license.newAssigned <= license.notAssignedOriginal + license.assigned) {
                params.node.setDataValue('notAssigned', license.notAssignedOriginal - license.newAssigned + license.assigned)
              } else {
                params.node.setDataValue('notAssigned', license.notAssignedOriginal)
              }
            },
          },
        },
      },
    ],
  }

  @Input()
  get value(): LicenseAllocatorData[] {
    return this.data
  }

  set value(value: LicenseAllocatorData[]) {
    this.setInitialData(value)
  }

  @Output() customComponentReady = new EventEmitter()

  constructor(@Optional() @Self() public ngControl: NgControl) {
    super(ngControl)
  }

  setInitialData(value: LicenseAllocatorData[]) {
    this.data = value
    if (!!this.data && this.data[0].notAssignedOriginal === undefined) {
      this.data.forEach((d: any) => {
        d.newAssigned = d.assigned
        d.notAssignedOriginal = d.notAssigned
      })
    }
    this.customComponentReady.emit()
  }

  getLicenses = () => of(this.data)

}

type LicenseAllocatorData = {
  assigned: number;
  available: number;
  consumed: number;
  isActive: boolean;
  newAssigned: number;
  notAssigned: number;
  notAssignedOriginal: number;
  sku: string;
  skuId: string;
  skuPartNumber: string;
  total: number;
}

/* eslint-disable max-len */
import { ExportingDialogComponent } from './../components/exporting-dialog/exporting-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { TranslateHelper } from '@coreview/coreview-library'
import { ToastService } from '@coreview/coreview-components'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { ApiclientService } from './apiclient.service'
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http'
import { tap } from 'rxjs/operators'
import { FileSaverService } from 'ngx-filesaver'
import { ApiDataParameters } from '../models/ApiDataParameters'
import { Metadata } from '../models/ServerResponse'

@Injectable({
  providedIn: 'root',
})
export class ReportExportService {
  constructor(
    private apiClient: ApiclientService,
    private httpClient: HttpClient,
    private fileSaverService: FileSaverService,
    private toastService: ToastService,
    private translateHelper: TranslateHelper,
    private matDialog: MatDialog
  ) {}

  public exportGeneric(
    getItems: (printModel: ApiDataParameters & { asyncExport: boolean; reportNameContainer: string }, format: string) => Observable<any>,
    metadata: Metadata,
    verb: string,
    format: string,
    fileName: string,
    apiUrl: string,
    sizeLimitAsync = 1000
  ): Observable<any> {
    delete metadata.queryRequest.savedReportParams

    const printModel: ApiDataParameters & {
      asyncExport: boolean;
      reportNameContainer: string;
      format: string;
      pageSizeExport: number;
      bsonFilters?: any;
    } = {
      ...metadata.queryRequest,
      asyncExport: false,
      reportNameContainer: fileName,
      format,
      pageSize: 2000000, // 2 Million
      pageSizeExport: 2000000 // 2 Million
    }

    if (metadata.totalCount > sizeLimitAsync) {
      printModel.asyncExport = true

      return this.httpClient
        .post(
          this.apiClient.basePortalApiUrl + '/asyncexceloutput?metadata=true&reportNameContainer=' + printModel.reportNameContainer,
          {
            requestObject: printModel,
            apiUrl: apiUrl.split('?')[0] + '?reportNameContainer=' + printModel.reportNameContainer,
          },
          { withCredentials: true }
        )
        .pipe(
          tap(() =>
            this.toastService.open({
              id: 'success',
              variant: 'success',
              matIcon: 'timelapse',
              title: this.translateHelper.instant('common_ExportInProgressTitle'),
              message: this.translateHelper.instant('common_ExportInProgressDescription'),
            })
          )
        )
    } else {
      if (verb === 'get' && !!printModel.filters) {
        printModel.filters = JSON.stringify(printModel.filters)
      }

      const dialogRef = this.matDialog.open(ExportingDialogComponent, { disableClose: true })
      delete printModel.bsonFilters
      delete printModel.pagingParameter
      return getItems(printModel, format).pipe(
        tap(
          (result) => {
            dialogRef.close()
            this.download(result.body, result.headers, printModel.reportNameContainer, format)
          },
          () => dialogRef.close()
        )
      )
    }
  }

  public downloadFile(reportUrl: string): void {
    this.getData(this.apiClient.basePortalApiUrl + '/resourcedownload/exportreports', {
      fileName: reportUrl,
      type: 'blob',
    }).subscribe(
      (result: HttpResponse<any>) => {
        const blob = new Blob([result.body], {
          type: 'application/octet-stream',
        })
        this.fileSaverService.save(blob, this.getFileName(result.headers))
      },
      (err) => {
        ;(window as any).appInsights.trackTrace('DownloadAsyncExportFailed', { fileName: reportUrl }, 3) // error: 3
      }
    )
  }

  private download(data: any, headers: HttpHeaders, fileName: string, format: string) {
    const filename = headers.get('Filename') || fileName.replace('&', 'and') + '.' + format || 'DownloadedFile.' + format
    const contentType = headers.get('content-type') || ''

    try {
      const blob = new Blob([data], { type: contentType })
      const name = this.sanitizeName(filename)
      this.fileSaverService.save(blob, name)
    } catch (ex) {}
  }

  private sanitizeName(name: string) {
    const rg1 = /^[^\/:*?"<>|]+$/ // forbidden characters  / : * ? " < > |
    const rg2 = /^\./ // cannot start with dot (.)
    const rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(.|$)/i // forbidden file names

    if (!rg1.test(name) || rg2.test(name) || rg3.test(name)) {
      return 'report'
    } else {
      return name
    }
  }

  private getFileName(headers: HttpHeaders) {
    const content = headers.get('content-disposition')
    const reg = RegExp(/filename="(.*?)\"/gm)
    return reg.exec(content || '')?.pop()
  }

  private getData(url: string, model: any, svgKey?: string | null): Observable<HttpResponse<any>> {
    let reportNameContainer = document.querySelector('span.reportNameContainerSaved')?.innerHTML
    if (reportNameContainer === '') {
      reportNameContainer = document.querySelector('span.reportNameContainer')?.innerHTML
    }

    if (model === undefined) {
      model = {}
    }
    model.metadata = true
    model.reportNameContainer = reportNameContainer
    if (!!model.type) {
      return this.httpClient.get(url, { params: model, responseType: model.type, withCredentials: true, observe: 'response' })
    }
    if (model.format === 'pdf' || model.format === 'xlsx') {
      if (svgKey !== null && svgKey !== undefined && svgKey !== '') {
        model.hasChart = true
        model.svgKey = svgKey
      }

      return this.httpClient.get(url, { params: model, responseType: 'arraybuffer', withCredentials: true, observe: 'response' })
    } else {
      return this.httpClient.get(url, { params: model, withCredentials: true, observe: 'response' })
    }
  }
}

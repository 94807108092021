import { ColDef, GridOptions } from '@ag-grid-community/core'
import { LocaleInfo } from '@app/core/models/LocaleInfo'
import { BadgeVariant } from '@coreview/coreview-components'

export type WorkflowExecutionStatus =
  | 'InQueue'
  | 'Succeeded'
  | 'Success'
  | 'Failed'
  | 'Aborted'
  | 'TimedOut'
  | 'Running'
  | 'NotStarted'
  | 'Skipped'
  | 'ApprovalRejected'
  | 'PendingForApproval'
export type WorkflowActionExecutionStatus =
  | 'Running'
  | 'Success'
  | 'Failed'
  | 'Skipped'
  | 'Aborted'
  | 'TimedOut'
  | 'NotStarted'
  | 'ApprovalRejected'
  | 'PendingForApproval'
  | 'Scheduled'

/* eslint-disable @typescript-eslint/naming-convention */
export class Constants {
  public static readonly paginationPageSize: number = 25

  public static readonly cacheBlockSize: number = 25

  public static readonly maximumRowsForSelectAll: number = 1000

  public static readonly defaultColumnsDefinition: ColDef = {
    flex: 1,
    minWidth: 10,
    filter: true,
    floatingFilter: true,
    filterParams: {
      suppressAndOrCondition: true,
    },
    resizable: true,
    unSortIcon: true,
  }

  public static readonly defaultOperationColumnsDefinition: ColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMovable: true,
    suppressMenu: true,
    suppressColumnsToolPanel: true,
    width: 36,
    minWidth: 36,
    maxWidth: 36,
    cellStyle: () => ({ paddingLeft: '6px', paddingRight: '0px' }),
  }

  public static readonly defaultSelectColumnDefinition: ColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMovable: true,
    suppressMenu: true,
    suppressColumnsToolPanel: true,
    width: 47,
    minWidth: 47,
    maxWidth: 47,
    checkboxSelection: true,
    colId: 'selection',
    field: 'selection',
    headerComponent: 'selectAllHeader',
    pinned: 'left',
    lockPinned: true,
    lockPosition: true,
    hide: false,
    cellStyle: () => ({ top: '0px', paddingLeft: '8px', paddingRight: '0px' }),
    headerClass: 'ag-selection-header p-r-0',
  }

  public static readonly defaultActionColumnsDefinition: ColDef = {
    headerClass: 'cv-grid-action-header',
    colId: 'actions',
    field: 'actions',
    hide: false,
    sortable: false,
    filter: false,
    resizable: false,
    suppressMovable: true,
    suppressMenu: true,
    suppressColumnsToolPanel: true,
    width: 120,
    pinned: 'right',
    lockPinned: true,
    minWidth: 120,
    maxWidth: 120,
    cellRenderer: 'iconMenuButtonRenderer',
    cellStyle: () => ({ padding: '0px', 'text-align': 'center' }),
  }

  public static readonly defaultGridOptions: GridOptions = {
    headerHeight: 36,
    floatingFiltersHeight: 40,
  }

  public static readonly licenseStatus: any = {
    active: 'active',
    inactive: 'inactive',
    unknown: 'unknown',
  }

  public static readonly languages: LocaleInfo[] = [
    {
      src: 'assets/img/flags/us.png',
      fullLabel: 'English',
      label: 'Eng',
      locale: 'en-US',
      iso: 'en',
    },
    {
      src: 'assets/img/flags/de.png',
      fullLabel: 'German',
      label: 'Deu',
      locale: 'de-de',
      iso: 'de',
    },
    {
      src: 'assets/img/flags/es.png',
      fullLabel: 'Spanish',
      label: 'Esp',
      locale: 'es-es',
      iso: 'es',
    },
    {
      src: 'assets/img/flags/it.png',
      fullLabel: 'Italian',
      label: 'Ita',
      locale: 'it-IT',
      iso: 'it',
    },
    {
      src: 'assets/img/flags/fr.png',
      fullLabel: 'French',
      label: 'Fra',
      locale: 'fr-FR',
      iso: 'fr',
    },
    {
      src: 'assets/img/flags/ja.png',
      fullLabel: 'Japanese',
      label: 'Ja',
      locale: 'ja-ja',
      iso: 'ja',
    },
  ]

  public static readonly regionalSettings: LocaleInfo[] = [
    {
      fullLabel: 'English',
      label: 'Eng',
      locale: 'en-US',
      iso: 'en',
    },
    {
      fullLabel: 'German',
      label: 'Deu',
      locale: 'de-de',
      iso: 'de',
    },
    {
      fullLabel: 'Spanish',
      label: 'Esp',
      locale: 'es-es',
      iso: 'es',
    },
    {
      fullLabel: 'Italian',
      label: 'Ita',
      locale: 'it-IT',
      iso: 'it',
    },
    {
      fullLabel: 'French',
      label: 'Fra',
      locale: 'fr-FR',
      iso: 'fr',
    },
  ]

  public static readonly groupsClassIcon: any = {
    distributionGroup: 'ms-Icon ms-Icon--Group',
    securityGroup: 'ms-Icon ms-Icon--SecurityGroup',
    microsoft365: 'ms-Icon ms-Icon--Teamwork',
    teams: 'ms-Icon ms-Icon--TeamsLogo',
  }
  
  public static readonly msProductsLogoClassIcon: any = {
    Azure: 'ms-Icon ms-Icon--AADLogo',
    Exchange: 'ms-Icon ms-Icon--ExchangeLogo',
    OneDrive: 'ms-Icon ms-Icon--OneDriveLogo',
    Office: 'ms-Icon ms-Icon--OfficeLogo',
    SharePoint: 'ms-Icon ms-Icon--SharepointLogo',
    Yammer: 'ms-Icon ms-Icon--YammerLogo',
    Skype: 'ms-Icon ms-Icon--SkypeForBusinessLogo',
    Teams: 'ms-Icon ms-Icon--TeamsLogo',
    PowerBI: 'ms-Icon ms-Icon--PowerBILogo',
    Dynamics: 'ms-Icon ms-Icon--Dynamics365Logo',
  }

  public static readonly msProductsLogoColor: any = {
    Azure: '#000000',
    Exchange: '#006ec0',
    OneDrive: '#094ab2',
    Office: '#eb3d01',
    OfficeProPlus: '#eb3d01',
    SharePoint: '#0072c6',
    Yammer: '#0093be',
    Skype: '#00b0f0',
    Teams: '#6264a7',
    PowerBI: '#edbd11',
    PowerBIPro: '#edbd11',
    Dynamics: '#13214d',
    Crm: '#13214d',
  }

  public static readonly msProductsLicenseOptimizationPage: any = {
    Exchange: 'ms-Icon ms-Icon--ExchangeLogo',
    OneDrive: 'ms-Icon ms-Icon--OneDriveLogo',
    OfficeProPlus: 'ms-Icon ms-Icon--OfficeLogo',
    SharePoint: 'ms-Icon ms-Icon--SharepointLogo',
    Yammer: 'ms-Icon ms-Icon--YammerLogo',
    Skype: 'ms-Icon ms-Icon--SkypeForBusinessLogo',
    Teams: 'ms-Icon ms-Icon--TeamsLogo',
    PowerBIPro: 'ms-Icon ms-Icon--PowerBILogo',
    Crm: 'ms-Icon ms-Icon--Dynamics365Logo',
    Copilot: 'copilot-icon',
  }

  public static readonly activeInactiveColors: any = {
    active: 'var(--success-color)',
    inactive: 'var(--alert-color)',
    unknown: 'var(--grey-color)',
  }

  public static readonly brandColors: any = {
    blue: 'var(--primary-color)',
    orange: 'var(--warning-color)',
    red: 'var(--alert-color)',
    success: 'var(--success-color)',
  }

  public static readonly commonClassIcons: any = {
    active: 'ms-Icon ms-Icon--SkypeCircleCheck',
    inactive: 'ms-Icon ms-Icon--AlertSolid',
    unknown: 'ms-Icon ms-Icon--Info',
    remove: 'ms-Icon ms-Icon--Delete ',
    checked: 'ms-Icon ms-Icon--CheckboxComposite',
    unchecked: 'ms-Icon ms-Icon--Checkbox',
    settings: 'ms-Icon ms-Icon--Settings',
    incident: 'ms-Icon ms-Icon--IncidentTriangle',
  }

  public static readonly modalDialogSizes: Record<string, string> = {
    xl: '90%',
    l: '75%',
    m: '50%',
    sm: '25%',
  }

  public static readonly multiForestServerType: any = {
    exchange: 'Exchange',
    activeDirectoryResources: 'ActiveDirectoryResources',
    activeDirectoryLogon: 'ActiveDirectoryLogon',
  }

  public static readonly defaultValueManagementActionNote = 'management_DefaultValueManagementActionNote'

  public static readonly roles = {
    TenantAdmin: 'tenantAdmin',
    Management: 'management',
    Guest: 'guest',
    Public: 'public',
    User: 'user',
    GlobalStats: 'globalStats',
    Audit: 'audit',
    Admin: 'admin',
    Beta: 'beta',
    PortalAdmin: 'portalAdmin',
    Sales: 'sales',
    GlobalLicenses: 'globalLicenses',
    CoreLearningAdmin: 'coreLearningAdmin',
    SupportChat: 'supportChat',
    Feedback: 'feedback',
    Invoice: 'invoice',
    WorkflowEditor: 'workflowEditor',
    WorkflowPublisher: 'workflowPublisher',
    PlaybookAdmin: 'playbookAdmin',
    PlaybookManager: 'playbookManager',
    PlaybookGlobalViewOnly: 'playbookGlobalViewOnly',
  }

  public static readonly noServiceCheck = 'noServiceCheck'

  public static readonly skus = {
    GroupOperatorsBulk: 'FT:GROUPSOPERATORSBULK',
    MasterTenant: 'SKU:MASTERTENANT',
  }

  public static readonly providersIntegrations = {
    ServiceNow: 'serviceNow',
  }

  //#region Reports range filters
  public static readonly rangesTranslationKeyMap: Record<number | string, string> = {
    1: 'reports_LastDay',
    3: 'reports_Last3Days',
    7: 'reports_Last7Days',
    14: 'reports_Last14Days',
    30: 'reports_Last30Days',
    60: 'reports_Last60Days',
    90: 'reports_Last90Days',
    180: 'reports_Last180Days',
    0: 'common_All',
    Custom: 'reports_CustomRange',
  }
  public static readonly defaultDaysOptions: (1 | 3 | 7 | 14 | 30 | 60 | 90 | 180 | 0)[] = [1, 7, 30, 60, 90]
  public static readonly defaultRangeOptions: (1 | 3 | 7 | 14 | 30 | 60 | 90 | 180 | 0)[] = [7, 14, 30, 60, 90, 180]
  //#endregion

  public static readonly savedReportsCategories: Record<string, string> = {
    PortalV2: 'PortalV2',
    Audit: 'Audit',
  }

  public static readonly menuIds = {
    favorites: 120,
    playbooks: 50000,
    healthcheck: 80000,
    settings: 40000,
    // saved: 16,
    // scheduled: 17,
    // exported: 19,
    // manageOperators: 4030,
    // manageVTenants: 4040,
    // managePermissions: 4050,
    // operatorsActivities: 5000,
    // auditActivities: 5001,
    reports: 2,
    audit: 70000,
    //TODO CHANGE
    // tasksProgress: -1,
    // manageCustomActions: 300030,
    // manageActions: 300020,

    licensePoolReport: 205,
    licensePoolCostReport: 206,
    licensePoolReportSnapshots: 210,

    licenseByUser: 203,
    licenseOptimization: 208,
    licenseServiceUsage: 221,
    licenseSavingsOpportunities: 107,
    licenseDuplicatedPlans: 209,

    licenseSubscriptionOverview: 201,
    licenseSubscriptionsHistory: 202,

    licenseOfficeInstallations: 240,
    lLicenseSoftwareLicenses: 230,
    licenseM365AppsUsage: 257,
    licenseOffice365ActiveUsers: 245,
  }

  public static readonly savedReportString = '?SavedReportId='

  public static readonly trackActons: any = {
    navigate: 'Navigate',
    actionSelected: 'ManagementActionSelected',
    actionExecuted: 'ManagementActionExecuted',
  }

  public static readonly taskStatusDetailsMap: Record<string, { icon: string; text: string; variant: BadgeVariant }> = {
    Failed: {
      icon: 'error',
      variant: 'red',
      text: 'common_Failed',
    },
    Finished: {
      icon: 'check_circle',
      variant: 'green',
      text: 'common_Finished',
    },
    Progress: {
      icon: 'timelapse',
      variant: 'yellow',
      text: 'common_OnProgress',
    },
    ApprovalRejected: {
      icon: 'pan_tool',
      variant: 'grey',
      text: 'workflow_ApprovalRejected',
    },
    PendingForApproval: {
      icon: 'pending',
      variant: 'yellow',
      text: 'workflow_PendingForApproval',
    },
  }

  public static readonly emailRegex =
    // eslint-disable-next-line max-len
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+\/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+\/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/

  public static readonly managementActionsIds = {
    forwardingTo: 101,
    forwardingToInternal: 102,
    passwordNeverExpires: 205,
    manageLicenses: 210,
    setPortalAttributes: 300,
    managePassword: 201,
    manageMFA: 285,
    editUserProperties: 230,
    manageOnpremisesPassword: 1550,
    editSynchronizedUser: 1555,
    editOnPremisesUser: 1521,
    editOnPremisesDistributionGroup: 1533,
    editOnPremisesSecurityGroup: 1539,
    locateDevice: 1816,
    addDelegatesToMailboxes: 106,
    addUsersAsDelegates: 105,
    removeDelegatesFromMailbox: 121,
    removeUserAccessToMailboxes: 118,
    editMailbox: 131,
    setMailboxAutoReplyConfiguration: 180,
    editSecurityGroup: 915,
    addMembersToSecurityGroup: 935,
    removeSecurityGroupMember: 930,
    editDistributionGroup: 602,
    addMembersToDistributionGroup: 606,
    addMembersToDistributionGroupUserCard: 607,
    removeDistributionGroupMember: 609,
    editO365Group: 715,
    addMembersToO365Group: 735,
    removeO365GroupMember: 740,
    manageMembersOfO365Group: 730,
    addMembersToTeamsGroup: 1609,
    editTeamsAutoAttendant: 1632,
    editTeamsCallQueue: 1624,
    manageTeamGroupMembers: 1607,
    manageTeamUserPolicy: 1611,
    manageTeamUserPolicySingle: 1613,
    removeTeamsGroupMember: 1640,
    editTeamsGroup: 1603,
    removeMobileDevice: 401,
    manageVoicemailSettings: 1645
  }

  public static readonly managementCategoriesIds = {
    mobile: 4,
    adminReadOnly: 5,
    endpointManager: 18,
  }

  public static readonly chipColorsMap: Record<any, any> = {
    workloadStatus: {
      ServiceOperational: 'accent',
      ServiceRestored: 'accent',
      FalsePositive: 'accent',
      PostIncidentReviewPublished: 'accent',
      RestoringDevice: 'primary',
      ExtendedRecovery: 'primary',
      ServiceDegradation: 'warn',
    },
  }

  public static readonly exportOptionsNoPdf: { key: string; text: string; icon?: string }[] = [
    {
      key: 'xlsx',
      text: 'Excel',
    },
    {
      key: 'csv',
      text: 'CSV',
    },
  ]

  public static readonly exportOptions: { key: string; text: string; icon?: string }[] = [
    ...this.exportOptionsNoPdf,
    {
      key: 'pdf',
      text: 'PDF',
    },
  ]

  // Note: Skype for Business, Audit, Application Usage and Signins are not present in New UX,
  // therefore these tabs are not delegable from New UX
  public static readonly userCardNewUxToLegacyPermissionMapping: Record<number, number[]> = {
    10002: [1101, 1102, 1103], // Info > Groups : Groups > M365 / SG / DG
    10401: [200], // Exchange > MailboxDetails : Exchange > Details
    10402: [200], // Exchange > ConnectionTypes : Exchange > Details
    10406: [200], // Exchange > CustomAtributes : Exchange > Details
    10403: [201], // Exchange > MailboxAutoReplyConfiguration : Exchange > MailboxAutoReplyConfiguration
    10404: [203], // Exchange > MailSentAndReceived : Exchange > TotalMailSentAndReceivedOverTime
    10405: [], // Exchange > MailboxQuotaStatus : Not present in legacy
    10006: [205, 206], // Details > Mailbox : Exchange > UsersWithAccess / MailboxRights
    10201: [300], // OneDriveSharePointYammer > OneDriveDetails : OneDrive > Details
    10202: [301], // OneDriveSharePointYammer > OneDriveUsage : OneDrive > Chart
    10203: [302], // OneDriveSharePointYammer > OneDriveExternalUsers : OneDrive > ExternalUsers
    10204: [303, 304], // OneDriveSharePointYammer > OneDrivePermissions : OneDrive > Accessible / Delegates
    10205: [700], // OneDriveSharePointYammer > SharePointDetails : SharePoint > Statistics
    10206: [], // OneDriveSharePointYammer > SharePointSiteOwners : Not present in legacy
    10207: [800], // OneDriveSharePointYammer > YammerDetails : Yammer > Statistics
    10301: [1201], // Teams > Meetings > Teams > General
    10302: [1201], // Teams > Calls > Teams > General
    10303: [1201], // Teams > ChannelMessages > Teams > General
    10304: [1201], // Teams > Quality > Teams > General
    10305: [1203], // Teams > Installation > Teams > Statistics
    10306: [1203], // Teams > ChatMessages > Teams > General
    10308: [1202], // Teams > Membership > Teams > Groups
    10501: [900, 901], // Notes > Notes : Notes > UserTable / TenantTable
  }

  public static readonly Workflows = {
    uidString: 'input-stepUid',

    statusTextMap: {
      InQueue: 'workflow_InQueue',
      Succeeded: 'workflow_StatusSucceeded',
      Success: 'workflow_StatusSuccess',
      Failed: 'workflow_StatusFailed',
      Aborted: 'workflow_StatusAborted',
      TimedOut: 'workflow_StatusTimedOut',
      Running: 'workflow_StatusRunning',
      NotStarted: 'workflow_StatusNotStarted',
      Skipped: 'workflow_StatusSkipped',
      ApprovalRejected: 'workflow_ApprovalRejected',
      PendingForApproval: 'workflow_PendingForApproval',
    } as Record<WorkflowActionExecutionStatus | WorkflowExecutionStatus, string>,

    statusVariantMap: {
      InQueue: 'yellow',
      Succeeded: 'green',
      Success: 'green',
      Failed: 'red',
      Running: 'blue',
      Skipped: 'yellow',
      Aborted: 'yellow',
      TimedOut: 'grey',
      NotStarted: 'grey',
      ApprovalRejected: 'grey',
      PendingForApproval: 'yellow',
    } as Record<WorkflowActionExecutionStatus | WorkflowExecutionStatus, BadgeVariant>,

    statusIconMap: {
      InQueue: 'pending_actions',
      Succeeded: 'check_circle',
      Success: 'check_circle',
      Failed: 'error',
      Running: 'timelapse',
      Skipped: 'double_arrow',
      Aborted: 'block',
      TimedOut: 'highlight_off',
      NotStarted: 'timelapse',
      ApprovalRejected: 'pan_tool',
      PendingForApproval: 'pending',
      Scheduled: 'schedule',
    } as Record<WorkflowActionExecutionStatus | WorkflowExecutionStatus, string | null>,
  }

  public static readonly RunCustomActionActivityTag = 'activity_RunCustomAction'

  //public static readonly showCustomReportsSku: string = 'FT:CUSTOMREPORTS'

  public static readonly columnsToExcludeInExport = ['selection', 'common_OpenCardInNewTab', 'common_Edit'];

  public static readonly MissingRemediationPermission = 'no-available-workflow-remediation-sku';
}

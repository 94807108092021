import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { pluck } from 'rxjs/operators'
import { OrganizationalUnit } from '../models/organizational-unit'
import { ResponseStatus } from '../models/ServerResponse'
import { ApiclientService } from './apiclient.service'

export interface OrganizationalUnitsResponse {
  readonly importing: boolean;
  readonly organizationalUnits?: OrganizationalUnit[];
  readonly domainController?: string;
  readonly responseStatus?: ResponseStatus;
  readonly isExpanded?: boolean;
  readonly organizationalUnitsCount?: number;
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationalUnitService {
  constructor(private apiClient: ApiclientService, private httpClient: HttpClient) {}

  getOrganizationalUnitsImportStatus(companyId: string): Observable<{ importing: boolean; countOrganizationalUnits: number }> {
    const url = `${this.apiClient.basePortalApiUrl}/organizationalUnits/infoimport/${companyId}`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  importOrganizationalUnits(companyId: string): Observable<ResponseStatus> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/organizationalunits/importnow/`
    return this.httpClient.post<ResponseStatus>(url, { companyId }, { withCredentials: true })
  }

  getDomainControllers(companyId: string) {
    const url = `${this.apiClient.basePortalApiUrl}/organizationalUnits/domaincontrollers/${companyId}`
    return this.httpClient.get<any>(url, { withCredentials: true }).pipe(pluck('domainControllers'))
  }

  getDomainControllersNewDG() {
    const url = `${this.apiClient.basePortalApiUrl}/domaincontrollers/dg`
    return this.httpClient.get<any>(url, { withCredentials: true }).pipe(pluck('domainControllers'))
  }

  getOrganizationalUnits(
    companyId: string,
    domainController?: string,
    selectedOrganizationalUnit?: string,
    organizationalUnitParentId?: string
  ): Observable<OrganizationalUnitsResponse> {
    const url = `${this.apiClient.basePortalApiUrl}/organizationalUnits/${companyId}`
    const params: any = { companyId }
    if (domainController) {
      params.domainController = domainController
    }
    if (selectedOrganizationalUnit) {
      params.organizationalUnit = selectedOrganizationalUnit
    }
    if (organizationalUnitParentId) {
      params.id = organizationalUnitParentId
    }
    return this.httpClient.get<OrganizationalUnitsResponse>(url, { withCredentials: true, params })
  }

  getOrganizationalUnitsFilter(companyId: string, domainController?: string): Observable<OrganizationalUnitsResponse> {
    const url = `${this.apiClient.basePortalApiUrl}/expandedorganizationalunits/${companyId}`
    const params: any = { companyId }
    if (domainController) {
      params.domainController = domainController
    }
    return this.httpClient.get<OrganizationalUnitsResponse>(url, { withCredentials: true, params })
  }

  checkAllOrganizationalUnits(companyId: string, domainController: string | null): Observable<OrganizationalUnitsResponse> {
    const url = `${this.apiClient.basePortalApiUrl}/checkallorganizationalunits/${companyId}`
    const params: any = { companyId }
    if (domainController) {
      params.domainController = domainController
    }
    return this.httpClient.get<OrganizationalUnitsResponse>(url, { withCredentials: true, params })
  }

  getAllOrganizationalUnitsChildren(companyId: string, nodeId: string): Observable<OrganizationalUnitsResponse> {
    const url = `${this.apiClient.basePortalApiUrl}/expandedorganizationalunitsbyid/${companyId}`
    const params: any = { companyId, id: nodeId }
    return this.httpClient.get<OrganizationalUnitsResponse>(url, { withCredentials: true, params })
  }

  saveOrganizationalUnitsSelection(
    companyId: string,
    organizationalUnitIds: string[],
    domainController: string | null,
    fqdn?: string
  ): Observable<ResponseStatus> {
    const url = `${this.apiClient.basePortalApiUrl}/organization/${companyId}/onpremises/ous`
    const params: any = { companyId, organizationalUnitIds }
    if (domainController) {
      params.domainController = domainController
    }
    if (fqdn) {
      params.fqdn = fqdn
    }
    return this.httpClient.put<ResponseStatus>(url, params, { withCredentials: true })
  }
}

<span (mouseover)="openCard()" (mouseleave)="closeCard()"  cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <ng-content select="[slot=button]"></ng-content>

  <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="showCard">
    <div class="hover-container"  (mouseover)="openCard()" (mouseleave)="closeCard()" >
      <ng-content select="[slot=card]"></ng-content>
    </div>
  </ng-template>
</span>


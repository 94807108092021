<form [formGroup]="form">
  <div class="form-row">
    <cv-checkbox formControlName="open" style="margin-top: 4px"></cv-checkbox>
    <label class="text-centered">{{ dayOfWeek | translate }}</label>
    <ng-container *ngIf="open">
      <div formArrayName="ranges">
        <div class="form-range" *ngFor="let range of ranges.controls; let i = index; let first = first; let last = last">
          <app-auto-attendant-business-hours-row [formControlName]="i" [fieldsDisabled]="fieldsDisabled"></app-auto-attendant-business-hours-row>
          <mat-icon *ngIf="ranges.controls.length > 1" (click)="handleDeleteTime(i)" class="delete-icon">close</mat-icon>
          <cv-button
            *ngIf="last && !fieldsDisabled"
            text="{{ 'management_AddNewTime' | translate }}"
            (click)="handleAddTime()"
            leftIcon="add"
            buttonType="tertiary"
            [disabled]="disabled"
          ></cv-button>
        </div>
      </div>
      <ng-container *ngIf="form.valid">
        <p class="text-centered"><span translate>common_Open</span> {{getOpenHours()}}</p>
        <div class="closed-hours" [innerHTML]="getClosingHours()"></div>
      </ng-container>
    </ng-container>
    <p *ngIf="!open" class="text-centered" style="margin-bottom: 32px">{{closedLabel}}</p>
  </div>
</form>
<div class="error-wrapper" *ngIf="form.errors?.hasOverlap">
  <mat-icon class="material-icons-outlined icon"> error </mat-icon>
  <span translate>management_TimeRangesSameDayCantOverlap</span>
</div>

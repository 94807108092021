export const maskPasswords = (obj: Record<string, any>): Record<string, any> => {
    const maskedObj = { ...obj };
  
    for (const key in maskedObj) {
      if (typeof maskedObj[key] === 'object' && maskedObj[key] !== null) {
        maskedObj[key] = maskPasswords(maskedObj[key]);
      } else if (key.toLowerCase().includes('password')) {
        maskedObj[key] = '******';
      }
    }
  
    return maskedObj;
  }
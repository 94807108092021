import { convertCronExpressionArrayToString, convertCronExpressionToArray } from './cron-helper'

export default class CronExpressions {
  private cronExpressionParts: string[]

  constructor(private cronExpression: string) {
    this.cronExpressionParts = convertCronExpressionToArray(this.cronExpression)
  }

  onDayOfWeekChange(val: any): CronExpressions {
    this.updatePart(val, 5)

    return this
  }

  getDayOfWeek(defaultValue: string | undefined): CronExpressions {
    this.getPartValue(5, defaultValue)

    return this
  }

  onMonthChange(val: any): CronExpressions {
    this.updatePart(val, 4)

    return this
  }

  getMonth(defaultValue: string | undefined): CronExpressions {
    this.getPartValue(4, defaultValue)

    return this
  }

  onDayOfMonthChange(val: any): CronExpressions {
    this.updatePart(val, 3)

    return this
  }

  getDayOfMonth(defaultValue: string | undefined): CronExpressions {
    this.getPartValue(3, defaultValue)

    return this
  }

  onHourChange(val: any): CronExpressions {
    this.updatePart(val, 2)

    return this
  }

  getHour(defaultValue = '0'): CronExpressions {
    this.getPartValue(2, defaultValue)

    return this
  }

  onMinuteChange(val: any): CronExpressions {
    this.updatePart(val, 1)

    return this
  }

  getMinute(defaultValue = '0'): CronExpressions {
    this.getPartValue(1, defaultValue)

    return this
  }

  getIncrementStart(val: string, defaultValue: any): string[] {
    return val && val.indexOf('/') >= 0 ? val.split('/')[0] : defaultValue
  }

  getIncrement(val: string, defaultValue: any): string[] {
    return val && val.indexOf('/') >= 0 ? val.split('/')[1] : defaultValue
  }

  generateExpression(): string {
    return convertCronExpressionArrayToString(this.cronExpressionParts)
  }

  private updatePart(val: string, index: number, defaultValue = '0') {
    const newCronExpression = [...this.cronExpressionParts]
    newCronExpression[index] = val === '' ? defaultValue : val

    this.cronExpressionParts = newCronExpression
  }

  private getPartValue(index: number, defaultValue = '*') {
    return this.cronExpressionParts && this.cronExpressionParts[index] && this.cronExpressionParts[index]
      ? this.cronExpressionParts[index]
      : defaultValue
  }
}

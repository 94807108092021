import { selectDefaultTenant } from './../tenants/tenants.selectors'
import { RootState } from './../RootState.type'
import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { OrganizationService } from '@app/core/services/organization.service'
import { getOrganizationSettings, getOrganizationSettingsResponse, getOrganizationSettingsInvalidate } from './organizationSettings.actions'
import { Store } from '@ngrx/store'

@Injectable()
export class OrganizationSettingsEffects {
  getOrganizationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrganizationSettings),
      mergeMap(() =>
        this.store
          .select(selectDefaultTenant)
          .pipe(
            filter((x) => !!x),
            take(1),
            mergeMap((x) => this.organizationService.getOrganizationSettings(x?.managedTenantId || ''))
          )
          .pipe(
            map((data) => getOrganizationSettingsResponse({ data })),
            catchError((error) => of(getOrganizationSettingsInvalidate({ error: { code: error.status, message: error.message }})))
          )
      )
    )
  )

  constructor(private actions$: Actions, private store: Store<RootState>, private organizationService: OrganizationService) {}
}

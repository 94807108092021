import { Component, Input } from '@angular/core'

import { WorkflowFlowDefinitionTypes } from '@app/modules/workflow/shared/constants/workflow'

@Component({
  selector: 'app-workflow-preview',
  template: `
    <div class="workflowPreview-container">
      <div class="panel-title-container">
        <h2 *ngIf="title" class="panel-title uppercase" style="font-weight: bold">
        <ng-container>{{"common_Preview" | translate}}</ng-container>
        <ng-container> | </ng-container>
        <ng-container>{{title | translate}}</ng-container>
        </h2>
        <h3 *ngIf="description" class="panel-title" translate>{{ description }}</h3>
      </div>
      <div class="panel-content">
        <app-action-list [actions]="actions" [previewType]="previewType" [showDetails]="showDetails" [isCatch]="isCatch"></app-action-list>
      </div>
    </div>
  `,
  styleUrls: ['./workflow-preview.component.sass'],
})
export class WorkflowPreviewComponent {
  @Input() actions: any[] = []
  @Input() title: string | undefined
  @Input() previewType: WorkflowFlowDefinitionTypes = 'workflows'
  @Input() showDetails = true
  @Input() description: string | undefined
  @Input() isCatch = false
}

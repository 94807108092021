/* eslint-disable @typescript-eslint/naming-convention */
import { ManagementAction } from '@app/core/models/management-action'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { ManagementCustomAction, ManagementCustomActionVars } from '@app/core/models/ManagementCustomAction'
import { Helpers } from '@app/shared/utilities/helpers'
import { EnhancedJsonFormData, EnhancedPropertyFormDescriptor } from '@app/shared/models/enhanced-json-form-data'
import { TargetEntityCustomActionType, TargetEntityNoneType, TargetEntityBaseType } from '@app/core/enums/group-type'
import { TargetHelper } from './target-helper'
import { CustomActionSubmitErrorDetailComponent } from '@app/modules/management/components/custom-actions/custom-action-submit-error-detail/custom-action-submit-error-detail.component'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { Injectable } from '@angular/core'
import { ToastService } from '@coreview/coreview-components'
import { TranslateHelper } from '@coreview/coreview-library'
@Injectable({ providedIn: 'root' })
export class CustomActionsHelper {
  static defaultGridDefinition: ReportDefinition = {
    title: 'notused',
    fields: [],
    defaultHiddenFields: [],
    sortField: '',
    sortOrder: 'asc',
    verb: 'post' as Verb,
    isOnlineUsersType: false,
    responseItemProp: '',
    rowSelection: 'none',
  }

  static targetMap: Record<
    TargetEntityCustomActionType | TargetEntityBaseType | TargetEntityNoneType,
    ReportDefinition & { apiUrl: string; propertyId: string }
  > = {
    ...TargetHelper.targetMap,
    O365Group: TargetHelper.Office365GroupDefinition,
    SharePoint: TargetHelper.SharePointDefinition,
    SkypeForBusiness: {
      ...CustomActionsHelper.defaultGridDefinition,
      isOnlineUsersType: true,
      fields: ['UserPrincipalName'],
      sortField: 'UserPrincipalName',
      responseItemProp: 'onlineUsers',
      apiUrl: '/onlineusers/skype/',
      operationsColumns: [
        {
          type: 'string',
          name: 'userPrincipalName',
          notSelectable: true,
        },
      ],
      verb: 'get',
      propertyId: 'userPrincipalName',
    },
    None: {
      ...CustomActionsHelper.defaultGridDefinition,
      fields: [],
      sortField: '',
      sortOrder: null,
      responseItemProp: '',
      apiUrl: '',
      propertyId: '',
    },
  }

  constructor(private rightPanelService: RightPanelService, private toastService: ToastService, private translateHelper: TranslateHelper) {}

  static getManagementAction(data: ManagementCustomAction) {
    const propertyId = data.target !== 'None' ? Helpers.downcase(this.targetMap[data.target]?.propertyId) : ''
    const filters: Record<string, string> = {}
    Object.keys(this.targetMap[data.target]?.filters || {}).forEach(
      (x) => (filters[Helpers.capitalize(x)] = Helpers.getStringFilterFromModel((this.targetMap[data.target]?.filters as any)[x]))
    )
    return {
      title: data.title,
      targetSelectionType: data.target !== 'None' ? 'Multiple' : 'None',
      onlineUserTarget: this.targetMap[data.target]?.isOnlineUsersType,
      fields: Object.keys(data.columns || {}),
      apiUrl: this.targetMap[data.target]?.apiUrl,
      filters,
      responseObjectName: this.targetMap[data.target]?.responseItemProp,
      targetPropertyId: propertyId,
      targetPropertyDisplayValue: data.params?.map((x) => Helpers.downcase(x.name)),
      sortField: this.targetMap[data.target]?.sortField,
      sortOrder: this.targetMap[data.target]?.sortOrder,
      formSchema: this.getFormSchema(data.vars, data.description),
      submitVerb: 'post',
      submitApiUrl: 'customactions/' + data.id + '/execute',
      submitDataMap: `const variables = {}; Object.keys(data || {}).forEach(x => variables[x] = data[x]); return {items: ${
        propertyId ? `targets?.map(x => x.${propertyId})` : '[]'
      }, variables}`,
    } as ManagementAction
  }

  private static getFormSchema(vars: ManagementCustomActionVars[] | undefined, description?: string): string {
    if (!vars?.length) {
      return ''
    }
    const schema: EnhancedJsonFormData = {
      $schema: '',
      type: 'object',
      properties: {},
      required: [],
      options: {},
    }
    if (description) {
      schema.options!.disclaimer = {
        message: description,
        variant: 'info',
      }
    }
    vars.forEach((x) => {
      schema.properties[x.name] = this.getMappedProperty(x)
      if (x.isRequired) {
        schema.required?.push(x.name)
      }
    })

    return JSON.stringify(schema)
  }

  private static getMappedProperty(variable: ManagementCustomActionVars): EnhancedPropertyFormDescriptor {
    return {
      type: this.getPropertyType(variable.type),
      format: variable.type === 'dateTime' ? 'date' : '',
      enum: variable.type === 'list' ? variable.values?.split(',') : undefined,
      title: variable.name,
      default: variable.type === 'bool' ? false : undefined,
      options:
        variable.type === 'bool'
          ? {
              style: {
                padding: '5px 0',
              },
            }
          : {},
    }
  }

  private static getPropertyType(variableType: string): 'number' | 'boolean' | 'string' {
    if (['int', 'decimal'].includes(variableType)) {
      return 'number'
    } else if (variableType === 'bool') {
      return 'boolean'
    }
    return 'string'
  }

  showSubmitErrorMessage(submitApiResponse: { isValid: boolean; messages?: { severity: string; message: string }[] }) {
    if (submitApiResponse.messages?.length) {
      this.toastService.open(
        {
          id: 'error',
          variant: 'error',
          matIcon: 'error',
          title: this.translateHelper.instant('management_CustomActionsErrorsCommands'),
          message: this.translateHelper.instant('management_CustomActionsErrorsCommandsMessage'),
          displayCTA: true,
          actionLabel: this.translateHelper.instant('management_SeeDetails'),
          action: () => {
            this.toastService.close('error')
            this.openErrorMessageDetail(submitApiResponse.messages!)
          },
          dismiss: (toast: { id: any }) => this.toastService.close(toast.id),
        },
        {
          autoclose: false,
          timeout: 50000,
        }
      )
    } else {
      this.toastService.open({
        id: 'error',
        variant: 'error',
        matIcon: 'error',
        title: this.translateHelper.instant('common_Error'),
        message: this.translateHelper.instant('management_CustomActionsErrorsCommands'),
      })
    }
  }

  private openErrorMessageDetail(errors: { severity: string; message: string }[]) {
    this.rightPanelService.open({
      type: CustomActionSubmitErrorDetailComponent,
      data: {
        errors,
        width: '750px',
      },
    })
  }
}

<div class="service-plans-picker">
  <div>
    <div class="m-b-15">
      <cv-radio-group orientation="vertical" [(ngModel)]="viewType" (ngModelChange)="onChangeViewType()" [suggestions]="viewTypes" cv-data-test="view-type"> </cv-radio-group>
    </div>

    <div>
      <label class="f-w-600" translate>common_Description</label>
      <div class="m-t-10">
        <cv-checkbox [value]="true" [disabled]="true" class="m-b-5" style="display: block">
          {{ 'management_ServicePlanEnabled' | translate }}
        </cv-checkbox>
        <cv-checkbox [value]="false" [disabled]="true" class="m-b-5" style="display: block">
          {{ 'management_ServicePlanDisabled' | translate }}
        </cv-checkbox>
        <cv-checkbox-tristate [value]="null" [disabled]="true" style="display: block">
          {{ 'management_ServicePlanUnchanged' | translate }}
        </cv-checkbox-tristate>
      </div>
    </div>
  </div>

  <div style="grid-column: span 2">
    <label class="f-w-600" translate>management_ManageServicePlans</label>
    <cv-input
      [label]="'common_Search' | translate"
      (inputChange)="filterChanged($event)"
      rightIcon="search"
      [clearButton]="true"
      style="width: 100%"
      class="p-b-10 m-t-10"
    ></cv-input>

    <ng-container *ngIf="loading">
      <div style="display: flex; flex-direction: column; gap: 10px">
        <div
          class="loading-skeleton"
          style="height: 35px; width: 100%; border-radius: 4px; background-color: var(--opaque-light-contrast-color)"
          *ngFor="let _ of [].constructor(6)"
        ></div>
      </div>
    </ng-container>

    <div *ngIf="!loading">
      <div *ngIf="viewType === 'ServicePlan'" class="m-t-10">
        <div *ngFor="let sp of servicePlans" [style.display]="sp.hidden ? 'none' : 'flex'">
          <mat-checkbox
            [checked]="sp.status === 'selected'"
            [indeterminate]="sp.status === 'indeterminate'"
            (change)="togglePlanSelection(sp)"
            class="m-b-5"
          >
            <span>{{ sp.serviceName }}</span
            >&nbsp;
            <span *ngIf="sp.msServiceName">({{ sp.msServiceName }})</span>
          </mat-checkbox>
        </div>
      </div>

      <div *ngIf="viewType === 'SKU'" class="m-t-10">
        <div *ngFor="let l of licenses" [style.display]="l.hidden ? 'none' : 'block'">
          <div class="node-container">
            <mat-icon (click)="l.expanded = !l.expanded" class="node-toggle m-r-5">{{
              !l.expanded ? 'chevron_right' : 'expand_more'
            }}</mat-icon>
            <mat-checkbox
              [checked]="l.status === 'selected'"
              [indeterminate]="l.status === 'indeterminate'"
              (change)="toggleLicenseSelection(l)"
            >
              <span>{{ l.label }}</span>
            </mat-checkbox>
          </div>
          <div *ngIf="l.expanded" class="p-l-50 m-l-5">
            <div *ngFor="let sp of l.servicePlans" [style.display]="sp.hidden ? 'none' : 'block'">
              <div class="node-container">
                <mat-checkbox
                  [checked]="sp.status === 'selected'"
                  [indeterminate]="sp.status === 'indeterminate'"
                  (change)="togglePlanSelection(sp, l)"
                >
                  <span>{{ sp.serviceName }}</span
                  >&nbsp;
                  <span *ngIf="sp.msServiceName">({{ sp.msServiceName }})</span>
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showNoResultsMessage()">
        <span translate>generic_NoResults</span>
      </div>
    </div>
  </div>
</div>

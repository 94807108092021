import { createFeatureSelector, createSelector } from '@ngrx/store'
import { reportsDefinitionAdapter } from './reports-definition.reducer'
import { ReportsDefinitionState } from './reports-definition.types'


const defaultSelectors = reportsDefinitionAdapter.getSelectors()

/**
 * Selects the whole reportsDefinitions state
 */
export const selectReportsDefinitionState = createFeatureSelector<ReportsDefinitionState>('reportsDefinitions')

/**
 * Select all the available entities
 */
export const selectReportsDefinition = createSelector(
    selectReportsDefinitionState,
    defaultSelectors.selectEntities,
)

export const selectReportDefinitionById = (id: string) => createSelector(
    selectReportsDefinitionState,
    (state: ReportsDefinitionState) => state && state.entities && state.entities[id]
)

import { FlatTreeControl } from '@angular/cdk/tree'
import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core'
import { NgControl } from '@angular/forms'
import { OrganizationalUnit } from '@app/core/models/organizational-unit'
import { OrganizationService } from '@app/core/services/organization.service'
import {
  OrganizationalUnitTreeDataSource,
  OrganizationalUnitTreeService,
  OUTreeNode,
} from '@app/core/services/organizational-unit-tree.service'
import { OrganizationalUnitService } from '@app/core/services/organizational-unit.service'
import { selectedOrganization } from '@app/store/organizations/organizations.selectors'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { BaseControlComponent, Suggestion } from '@coreview/coreview-components'
import { filter, first } from 'rxjs/operators'
import { CustomComponentReady } from '@coreview/coreview-dynamoforms'

@Component({
  selector: 'app-organizational-unit-picker',
  templateUrl: './organizational-unit-picker.component.html',
  styleUrls: ['./organizational-unit-picker.component.sass'],
})

export class OrganizationalUnitPickerComponent extends BaseControlComponent implements OnInit, CustomComponentReady {
  // in case of edit we don't show domain controller dropdown as we can't move from one dc to another
  @Input() isEdit = false
  // in case of new dg we call a different api for domain controller as we need to check if there's a resource forest
  // and return only that in case
  @Input() isNewDistributionGroup = false

  @Input() hideReset = false

  @Input()
  get value(): string | undefined {
    return this.selectedOrganizationalUnit
  }

  @Output() customComponentReady = new EventEmitter<void>()

  set value(value: string | undefined) {
    this.selectedOrganizationalUnit = value
    this.onChange(value)
  }

  selectedOrganizationalUnit?: string
  companyId!: string
  showNoOUDisclaimer = false

  isMultiForest = false
  domainControllers: Suggestion[] = []
  selectedDomainController: string | null = null
  previousValues: { domainController?: string; organizationalUnit?: string } = {}

  firstLvItems = new Map<OUTreeNode, OrganizationalUnit>()
  nestedItems = new Map<OrganizationalUnit, OUTreeNode>()
  treeControl: FlatTreeControl<OUTreeNode>
  dataSource!: OrganizationalUnitTreeDataSource

  loading = true

  constructor(
    private store: Store<RootState>,
    private organizationService: OrganizationService,
    private organizationalUnitService: OrganizationalUnitService,
    private organizationalUnitTreeService: OrganizationalUnitTreeService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(ngControl)
    this.treeControl = new FlatTreeControl<OUTreeNode>(this.getLevel, this.isExpandable)
  }

  ngOnInit(): void {
    this.store
      .select(selectedOrganization)
      .pipe(
        filter((x) => !!x),
        first()
      )
      .subscribe((company) => {
        this.companyId = company?.guid as string
        this.previousValues.organizationalUnit = this.selectedOrganizationalUnit

        this.dataSource = new OrganizationalUnitTreeDataSource(
          this.treeControl,
          this.organizationalUnitTreeService,
          this.companyId,
          this.selectedOrganizationalUnit
        )

        this.organizationService.getOnPremisesConfigurationType(this.companyId).subscribe((response) => {
          this.isMultiForest = response?.isMultiforest || false
          if (this.isMultiForest && !this.isEdit) {
            const dcsObs = this.isNewDistributionGroup
              ? this.organizationalUnitService.getDomainControllersNewDG()
              : this.organizationalUnitService.getDomainControllers(this.companyId)
            dcsObs.subscribe((dcs: string[]) => {
              if (dcs.length) {
                this.domainControllers = dcs.map((x) => ({
                  value: x,
                  displayValue: x,
                }))
                if (!this.selectedOrganizationalUnit) {
                  this.selectedDomainController = dcs[0]
                }
                this.getOrganizationalUnitsDatasource(this.selectedDomainController || undefined)
              } else {
                this.showNoOUDisclaimer = true
                this.loading = false
                this.customComponentReady.emit()
              }
            })
          } else {
            this.getOrganizationalUnitsDatasource()
          }
        })
      })
  }

  getOrganizationalUnitsDatasource = (domainController?: string) => {
    this.organizationalUnitTreeService
      .initialData(this.companyId, domainController, this.selectedOrganizationalUnit)
      .subscribe((response) => {
        if (this.isMultiForest) {
          if (!response?.domainController) {
            this.showNoOUDisclaimer = true
          } else {
            this.selectedDomainController = response.domainController
          }

          if (this.selectedOrganizationalUnit) {
            this.previousValues.domainController = response.domainController
          }
        } else if (!response.organizationalUnits?.length) {
          this.showNoOUDisclaimer = true
        }

        this.dataSource.data = response.organizationalUnits?.length ? this.organizationalUnitTreeService.ouMapper(response.organizationalUnits, 0, this.selectedOrganizationalUnit) : []

        if (
          !!this.selectedOrganizationalUnit &&
          !this.dataSource.data.find((x) => x.distinguishedName === this.selectedOrganizationalUnit)
        ) {
          this.dataSource.data
            .filter((n) => (this.selectedOrganizationalUnit || '').includes(n.distinguishedName))
            .forEach((node) => {
              this.treeControl.expand(node)
            })
        }
        this.loading = false
        this.customComponentReady.emit()
      }, () => {
        this.loading = false
        this.customComponentReady.emit()
      })
  }

  onDomainControllerChange = (nextValue: string, expandSelectedNode?: boolean) => {
    this.loading = true
    this.selectedDomainController = nextValue
    this.organizationalUnitTreeService.initialData(this.companyId, this.selectedDomainController).subscribe((response) => {
      this.dataSource.data = response.organizationalUnits?.length ? this.organizationalUnitTreeService.ouMapper(response.organizationalUnits, 0, this.selectedOrganizationalUnit) : []
      if (expandSelectedNode) {
        if (!this.dataSource.data.find((x) => x.distinguishedName === this.selectedOrganizationalUnit)) {
          this.dataSource.data
            .filter((n) => (this.selectedOrganizationalUnit || '').includes(n.distinguishedName))
            .forEach((node) => {
              this.treeControl.expand(node)
            })
        }
      } else {
        this.selectedOrganizationalUnit = undefined
      }
      this.loading = false
    }, () => {
      this.loading = false
    })
  }

  resetSelection = () => {
    this.selectedOrganizationalUnit = this.previousValues.organizationalUnit
    if (this.isMultiForest && !!this.previousValues.domainController) {
      if (this.selectedDomainController !== this.previousValues.domainController) {
        this.onDomainControllerChange(this.previousValues.domainController, true)
      }
    }
  }

  getLevel = (node: OUTreeNode) => node.level

  isExpandable = (node: OUTreeNode) => node.expandable

  hasChild = (level: number, node: OUTreeNode) => node.expandable
}

<ng-container *ngFor='let item of items'>
  <ng-container [ngSwitch]='item.type'>
    <app-binding-tag-action *ngSwitchCase="'action'" [propertyValue]='item.value'></app-binding-tag-action>
    <app-binding-tag-input *ngSwitchCase="'input'" [propertyValue]='item.value'></app-binding-tag-input>
    <span *ngSwitchDefault>{{ item.value }} </span>
  </ng-container>
</ng-container>





import { AdministrationsService } from './../../core/services/administrations.service'
import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { getUserGroups, getUserGroupsResponse, getUserGroupsInvalidate } from './userGroups.actions'
import { ToastService } from '@coreview/coreview-components'
import { TranslateHelper } from '@coreview/coreview-library'

@Injectable()
export class UserGroupsEffects {
  getUserGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserGroups),
      mergeMap(() =>
        this.administrationsService.getUserGroupsSelectedCompany().pipe(
          map((data) => getUserGroupsResponse({ data })),
          catchError(this.catchErrorAndNotify(getUserGroupsInvalidate, 'UserGroups'))
        )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private administrationsService: AdministrationsService,
    private toastService: ToastService,
    private translateHelper: TranslateHelper
  ) {}

  private catchErrorAndNotify(action: any, objectType: string) {
    return (error: { message: string }) => {
      this.toastService.open({
        id: 'err',
        variant: 'error',
        title: this.translateHelper.instant('Error'),
        message: this.translateHelper.combineTranslations('generic_ObjectCouldNotBeRetrieved', objectType),
      })
      return of(action({ error: error.message }))
    }
  }
}

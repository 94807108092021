<div class="container">
    <div class="header">
        <div class="round rect elms"></div>
        <div class="small-circle elms"></div>
        <div class="long rect elms"></div>
        <div class="rect-full elms"></div>
        <div class="rect-slim elms"></div>
        <div class="small-circle elms"></div>
        <div class="small-circle elms"></div>
        <div class="rect-slim elms"></div>
        <div class="circle elms"></div>
    </div>
    <div class="content">
        <div class="nav">
            <div class="round rect col"></div>
            <div class="n-row">
                <div class="s-circle col"></div>
                <div class="s-rect col"></div>
            </div>
            <div class="n-row">
                <div class="s-circle col"></div>
                <div class="s-rect col"></div>
            </div>
            <div class="n-row">
                <div class="s-circle col"></div>
                <div class="s-rect col"></div>
            </div>
            <div class="n-row">
                <div class="s-circle col"></div>
                <div class="s-rect col"></div>
            </div>
            <div class="n-row">
                <div class="s-circle col"></div>
                <div class="s-rect col"></div>
            </div>
            <div class="n-row">
                <div class="s-circle col"></div>
                <div class="s-rect col"></div>
            </div>
            <div class="n-row">
                <div class="s-circle col"></div>
                <div class="s-rect col"></div>
            </div>
        </div>
        <div style="display: flex; flex-direction: column; width: 95%;">
            <div class="rect-top"></div>
            <div style="display: flex; margin-top: 10px; height: 85%;">
                <div class="rect-left"></div>
                <div class="rect-left"></div>
            </div>
        </div>
    </div>
</div>
<div style="display: flex; height: 100%; box-sizing: border-box">
  <div style="display: flex; padding: 20px; flex: 0.8">
    <img style="margin: auto" src="../assets/img/forbidden.svg" alt="access is forbidden" />
  </div>
  <div style="flex: 1; display: flex">
    <div style="margin: auto;">
      <div style="font-size: 24px; font-weight: 600" translate>generic_StayTunedTitle</div>
      <div style="font-size: 16px; margin: 50px 0" [innerHTML]="'generic_StayTunedDescription' | translate"></div>
      <cv-button leftIcon="arrow_back" (click)="goToPortalV1()" [text]="'generic_GoBackToPortal' | translate"></cv-button>
    </div>
  </div>
</div>

import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Policy } from '@app/core/models/playbook'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { EditPolicyComponent } from '@app/modules/playbook/components/edit-policy/edit-policy.component'
import { NewCustomPolicyComponent } from '@app/modules/playbook/components/new-custom-policy/new-custom-policy.component'
import { ExtendedPolicy } from '@app/modules/playbook/components/policy/policy.component'
import { PlaybookService } from '@app/modules/playbook/services/playbook.service'
import { DataListInfo } from '@app/shared/components/cards/data-list/data-list.component'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { TranslateHelper } from '@coreview/coreview-library'
import { Subject, combineLatest, iif, of, timer } from 'rxjs'
import { RestService } from '@app/core/services/rest.service copy'
import { catchError, takeUntil, filter } from 'rxjs/operators'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { SwitchComponent } from '@coreview/coreview-components'
import { Router, ActivatedRoute } from '@angular/router'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { selectedOrganizationSkus } from '@app/store/organizations/organizations.selectors'
import { FeatureFlags } from '@app/modules/playbook/playbook-constants'
import { WorkflowPreviewDialogComponent } from '@app/modules/playbook/components/workflow-preview-dialog/workflow-preview-dialog.component'
import { PolicyService } from '@app/modules/playbook/services/policy.service'
import { PolicyDialogComponent } from '@app/modules/playbook/components/policy-dialog/policy-dialog.component'
import { WorkflowExecutionsComponentV2 } from '@app/modules/workflow/features/workflow-execution-list/components/workflow-executions-v2/workflow-executions-v2.component'
import { Constants } from '@app/shared/utilities/constants'

@Component({
  selector: 'app-policy-list-details-dialog',
  templateUrl: './policy-list-details-dialog.component.html',
  styleUrls: ['./policy-list-details-dialog.component.sass'],
})
export class PolicyListDetailsDialogComponent implements OnInit, OnDestroy {
  selectedPolicy!: ExtendedPolicy
  playbookTitle!: string
  categoryTitle!: string
  pageText!: string
  policies!: DataListInfo[]
  isAdminProductionMode = false
  isAdmin = false
  timeRangeType = 'Monthly'
  options = [
    {
      key: 'Monthly',
      text: this.translateHelper.instant('common_Monthly'),
    },
  ]
  missingRemediationPermission = Constants.MissingRemediationPermission
  chart!: any
  chartData!: any
  activeTab = 1
  activatedRoute!: ActivatedRoute
  skus: readonly string[] | undefined = []
  canSeeEditButton = false
  totalWfExecutionCount = 0

  policyDialog?: PolicyDialogComponent
  wfExecutionComp?: WorkflowExecutionsComponentV2

  @ViewChild(PolicyDialogComponent)
  set setPolicyDialog(policyDialog: PolicyDialogComponent) {
    this.policyDialog = policyDialog
  }

  @ViewChild(WorkflowExecutionsComponentV2)
  set setWfExecutionComp(wfExecutionComp: WorkflowExecutionsComponentV2) {
    this.wfExecutionComp = wfExecutionComp
  }

  private destroyed$: Subject<boolean> = new Subject()
  constructor(
    private translateHelper: TranslateHelper,
    private playbookService: PlaybookService,
    private policyService: PolicyService,
    private sharedHelperService: SharedHelperService,
    private rightPanelService: RightPanelService,
    private storage: LocalstorageService,
    private dialog: MatDialog,
    private restService: RestService,
    public dialogRef: MatDialogRef<PolicyListDetailsDialogComponent>,
    private router: Router,
    private store: Store<RootState>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      policies: DataListInfo[]
      selectedPolicy: DataListInfo
      playbookTitle: string
      categoryTitle: string
      activatedRoute: ActivatedRoute
    }
  ) {
    this.initComponent(dialogData)
  }

  initComponent = (dialogData: any) => {
    this.isAdmin = this.sharedHelperService.checkRoles(['tenantAdmin', 'playbookAdmin'])
    this.isAdminProductionMode = this.storage.selectedOrganization?.subscriptionLevel === 'PRODUCTION' && this.isAdmin
    this.playbookTitle = dialogData.playbookTitle ? this.translateHelper.instant(dialogData.playbookTitle) : null
    this.categoryTitle = dialogData.categoryTitle ? this.translateHelper.instant(dialogData.categoryTitle) : null
    this.policies = dialogData.policies
    if (dialogData.selectedPolicy?.id) {
      this.updateData(dialogData.selectedPolicy.id)
    }
    this.activatedRoute = dialogData.activatedRoute
    this.store.select(selectedOrganizationSkus).subscribe((skus) => {
      this.skus = skus
      this.updateOptions()
      this.canSeeEditButton = this.skus?.includes(FeatureFlags.editPolicy) ?? false
    })
  }

  updateOptions = () => {
    this.options.push(
      {
        key: 'Weekly',
        text: this.translateHelper.instant('common_Weekly'),
      },
      {
        key: 'Daily',
        text: this.translateHelper.instant('common_Daily'),
      }
    )
  }

  updateData = (selectedPolicyId: string) => {
    const selectedIndex = this.policies?.findIndex((p) => p.id === selectedPolicyId)
    this.pageText = `${selectedIndex + 1} of ${this.policies.length}`
    this.loadData(selectedPolicyId, this.timeRangeType)
  }

  ngOnInit(): void {
    dayjs.extend(utc)
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  tabChanged = ({ index }: any) => (this.activeTab = (index ?? 0) + 1)

  loadData = (policyId: string, timeRangeType = 'Monthly') => {
    combineLatest([
      this.playbookService.getPolicy(policyId, false),
      iif(
        () => this.isAdminProductionMode,
        this.restService
          .getData(`/playbook/healthchecks/policy/${policyId}/snapshots?timeRangeType=${timeRangeType}`)
          .pipe(catchError((error) => of(null))),
        of(null)
      ),
    ]).subscribe(([policy, snapshots]) => {
      this.playbookService
        .getPolicyWorkflowExecutionsCount(policy?.workflowId ?? '', this.getServiceTag(policy))
        .subscribe((count) => (this.totalWfExecutionCount = count))
      const p = policy as ExtendedPolicy
      if (p.policyDescription) {
        p.policyDescription.policy = new Function('data', 'translateHelper', p.policyDescription.policyJavascriptFunction ?? '')(
          p,
          this.translateHelper
        )
      } else {
        p.policyDescription = {
          policy: p.description,
          workflow: p.isWorkflowEnabled ? p.howThisAffect : '',
        }
      }

      if (!this.selectedPolicy) {
        this.selectedPolicy = p
      } else {
        Object.assign(this.selectedPolicy, p)
      }

      if (this.policyDialog) {
        this.policyDialog.policyItemList?.grid?.refresh()
      } else if (this.wfExecutionComp) {
        this.wfExecutionComp.grid.refresh()
      }

      if (this.isAdminProductionMode && !!snapshots) {
        this.createChart(snapshots)
      }
    })
  }

  createChart = (responseData: any) => {
    this.chart = this.policyService.createChart()
    this.chartData = this.policyService.createChartData(responseData, this.timeRangeType)
  } 

  goToFullReport(policy: Policy, showExceptions = false) {
    this.router
      .navigate([policy.policyGroupType === 'LegacyPolicy' ? `policy/legacy/${policy.id}` : `policy/${policy.id}`], {
        relativeTo: this.activatedRoute,
        queryParams: showExceptions ? { showExceptions: 'true' } : {},
      })
      .catch((_: any) => _)
  }

  upClick = (event: any) => {
    const selectedIndex = this.policies?.findIndex((p) => p.id === this.selectedPolicy.id)
    if (selectedIndex > 0) {
      const newPolicyId = this.policies[selectedIndex - 1].id
      if (newPolicyId) {
        this.updateData(newPolicyId)
      }
    }
  }

  downClick = (event: any) => {
    const selectedIndex = this.policies?.findIndex((p) => p.id === this.selectedPolicy.id)
    if (selectedIndex < this.policies.length - 1) {
      const newPolicyId = this.policies[selectedIndex + 1].id
      if (newPolicyId) {
        this.updateData(newPolicyId)
      }
    }
  }

  isPolicyScheduled() {
    return this.policyService.isPolicyScheduled(this.selectedPolicy)
  }

  close = () => {
    this.dialogRef.close()
  }

  disableWorkflow(policy: Policy) {
    this.patchPolicy({ ...policy, isWorkflowEnabled: false })
  }

  openEditPolicyPanel(policy: Policy, patch: any = {}, checker: SwitchComponent | null = null) {
    if (this.sharedHelperService.checkRoles(['tenantAdmin', 'playbookAdmin'])) {
      timer(1)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          if (checker) {
            checker.checked = policy.isWorkflowEnabled
          }
        })
      this.rightPanelService
        .open({
          type: policy.policyGroupType === 'OutOfTheBoxPolicy' ? EditPolicyComponent : NewCustomPolicyComponent,
          data: {
            width: '100%',
            policyId: policy.id,
            changedRemediation: !!checker,
            isPolicyProvided: false,
            patchPolicy: patch,
          },
        })
        .afterClosed()
        .pipe(
          filter((x) => !!x),
          takeUntil(this.destroyed$)
        )
        .subscribe((id) => {
          this.updateData(id)
        })
    }
  }

  patchPolicy(policy: Policy) {
    const { isPolicyEnabled, isWorkflowEnabled } = policy
    this.playbookService
      .patchPolicy(policy.id ?? '', { isPolicyEnabled, isWorkflowEnabled })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.updateData(policy.id ?? ''))
  }

  openWorkflow(policy: ExtendedPolicy): void {
    this.dialog.open(WorkflowPreviewDialogComponent, {
      width: '70%',
      height: '70%',
      data: { workflowId: policy.workflowId, isForPlaybook: true },
    })
  }

  getServiceTag(policy: Policy): string {
    return 'policyid:' + policy.id
  }
}

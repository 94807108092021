import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'fabricIconClass',
})
export class FabricIconClassPipe implements PipeTransform {
  transform(value: string): string {
    let classes = ['ms-Icon']

    if (value) {
      classes = [...classes, `ms-Icon--${value}`]
    }

    return classes.join(' ')
  }
}

import { Playbook } from '@app/core/models/playbook'
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import {
  getPlaybookItems,
  getPlaybookItemsResponse,
  getPlaybookItemsInvalidate,
} from '@app/store/playbooks/playbooks.actions'
import { PlaybookState } from './playbooks.types'

export const playbookAdapter: EntityAdapter<Playbook> = createEntityAdapter<Playbook>({
  selectId: ({ id }) => id,
})

export const initialState: PlaybookState = playbookAdapter.getInitialState({
  playbooks: [],
  playbooksAssociatedPolicies: {},
  didInvalidate: false,
  isFetching: false,
  error: null,
  loaded: false,
})

export const playbookReducer = createReducer(
  initialState,
  on(getPlaybookItems, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null, loaded: false })),
  on(getPlaybookItemsResponse, (state, { playbooks, playbooksAssociatedPolicies }) => ({
    ...state,
    playbooks,
    playbooksAssociatedPolicies,
    loaded: true,
    isFetching: false,
  })),
  on(getPlaybookItemsInvalidate, (state, playbook) => ({
    ...state,
    error: playbook.error,
    didInvalidate: true,
    loaded: true,
    isFetching: false,
  }))
)

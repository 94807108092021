import { Workflow } from './workflow.model'

export const isLocked = (stepDefinition: any) => stepDefinition.isLocked === true

export const getSequenceActions = (actions: Workflow.Dto.V1.Common.Action[], action: Workflow.Dto.V1.Common.Action) => {
  const sequenceActions = actions.filter((actionItem) => actionItem.parentUid && actionItem.parentUid === action.uid)
  return sequenceActions
}

export const isActionSequence = (stepDefinition: any) => stepDefinition?.id === 'item::sequence'

const enum WorkflowExecutionStatus {
  aborted = 'aborted',
  timedOut = 'timedout',
  failed = 'failed',
  running = 'running',
  pendingForApproval = 'pendingforapproval',
  approvalRejected = 'approvalrejected',
  inQueue = 'inqueue'
}

export const isValidRestartWorkflowExecution = (status: string) =>
  status.toLowerCase() == WorkflowExecutionStatus.aborted ||
  status.toLowerCase() == WorkflowExecutionStatus.timedOut ||
  status.toLowerCase() == WorkflowExecutionStatus.failed ||
  status.toLowerCase() == WorkflowExecutionStatus.approvalRejected

export const isValidStopWorkflowExecution = (status: string) =>
  status.toLowerCase() === WorkflowExecutionStatus.running ||
  status.toLowerCase() === WorkflowExecutionStatus.pendingForApproval ||
  status.toLowerCase() == WorkflowExecutionStatus.inQueue

<div class="title p-l-20" style="display: flex; line-height: 69px">
  <div style="flex: 1; font-size: 14px; font-weight: 600" class="uppercase" translate>reports_SaveCustomReport</div>
</div>
<div class="p-20">
  <form [formGroup]="form">
    <cv-input
      [errorMessage]="'common_Required' | translate"
      style="display: block; width: 100%; margin: 10px 0px"
      label="{{'reports_ReportName' | translate}}*"
      formControlName="name"
    ></cv-input>
    <cv-textarea
      style="display: block; width: 100%; margin: 10px 0px 20px 0px"
      [label]="'Description' | translate"
      formControlName="description"
    ></cv-textarea>
    <mat-checkbox *ngIf="!!savedReport && !!savedReport.guid" style="display: block; margin: 10px 0px" formControlName="overwriteExistingReport"><span translate>reports_OverwriteExistingSavedReport</span></mat-checkbox>
    <mat-checkbox style="display: block; margin: 10px 0px" formControlName="isFavourite"><span translate>reports_AddToMyFavorites</span></mat-checkbox>
    <div style="display: flex; margin: 10px 0px">
      <mat-checkbox style="display: block;" formControlName="isShared"><span translate>reports_SetAsPublic</span></mat-checkbox>
      <mat-icon
              [tooltip]="'common_SavedReportPublic' | translate"
              style="width: 20px; height: 20px; font-size: 20px; margin-left: 5px; margin-top: 2px; vertical-align: middle"
              class="material-icons-outlined info"
              >info</mat-icon
            >
      </div>
    <mat-checkbox *ngIf="isAdmin()" style="display: block; margin: 10px 0px" formControlName="columnsLocked"><span translate>reports_LockedColumns</span></mat-checkbox>
    <mat-checkbox *ngIf="isPortalAdmin()" style="display: block; margin: 10px 0px" formControlName="predefinedReport"><span translate>reports_PredefinedReport</span></mat-checkbox>
  </form>
  <cv-alert-bar *ngIf="isAdmin()" [hideCloseButton]="true">
    <span translate>reports_AssignSavedReportsPermissionsDisclaimer</span>
  </cv-alert-bar
  >
  <div style="display: flex; justify-content: flex-end; margin: 15px 0px">
    <cv-button (click)="close()" class="m-r-10" buttonType="secondary" [text]="'common_Cancel' | translate"></cv-button>
    <cv-button (click)="save()" primary="secondary" [text]="'common_Proceed' | translate" [disabled]="!!isSaving"></cv-button>
  </div>
</div>

<ng-container>
  <app-reports [clientTableConfiguration]="configuration" *ngIf="configurationLoaded" (getItemsResChange)="handleResChanged($event)">
    <cv-smart-panel [title]="'reports_FilterAssistant' | translate" [open]="true" customSmartPanel>
      <div style="cursor: default" content class="content">
        <div class="title-container">
          <div class="title" translate>reports_FilterAssistant</div>
        </div>
        <div class="search-filters">
          <cv-input [(ngModel)]="fulltext" [label]="'common_Search' | translate" [clearButton]="true"></cv-input>
          <cv-autocomplete-string-input
            [(ngModel)]="searchAggregation"
            [suggestions]="suggestions"
            (selectionChanged)="handleAddAggregation($event)"
            [label]="'reports_AddAggregation' | translate"
            rightIcon="search"
          ></cv-autocomplete-string-input>
          <form class="aggregations">
            <div *ngFor="let aggregation of filterAggregations" class="options m-b-10">
              <span class="subtitle" translate>{{ aggregation.key }}</span>
              <div>
                <ng-container *ngFor="let item of aggregation.items">
                  <div *ngIf="!item.hidden" class="option">
                    <cv-checkbox
                      [value]="selectedFilters.has(item.parentkey + '-' + item.key)"
                      (checkedChange)="handleCheck(item)"
                      *ngIf="aggregation.componentType === 'Checkbox'"
                      >{{ item.label }} <span>{{ item.count }}</span></cv-checkbox
                    >

                    <cv-radio-button
                      [checked]="selectedFilters.has(item.parentkey + '-' + item.key)"
                      (change)="handleRadioCheck(item)"
                      *ngIf="aggregation.componentType === 'Radio'"
                      >{{ item.label }} <span>{{ item.count }}</span></cv-radio-button
                    >
                  </div>
                </ng-container>
              </div>
              <cv-button
                *ngIf="aggregation.otherDocuments"
                buttonType="tertiary"
                [text]="'common_ShowAll' | translate"
                (click)="handleLoadItems(aggregation)"
              ></cv-button>
            </div>
          </form>
        </div>
      </div>
      <div footer>
        <cv-button
          (click)="reset()"
          class="m-r-10"
          buttonType="secondary"
          [text]="'common_Reset' | translate"
          [disabled]="isSearchDisabled"
        >
        </cv-button>
        <cv-button (click)="search()" primary="secondary" [text]="'common_Search' | translate" [disabled]="isSearchDisabled"> </cv-button>
      </div>
    </cv-smart-panel>
  </app-reports>
</ng-container>

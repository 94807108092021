/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core'
import { Suggestion } from '@coreview/coreview-components'
import { TranslateHelper } from '@coreview/coreview-library'

@Injectable({
  providedIn: 'root',
})
export class RuleConverterService {
  private specialTokens: string[] = ['null', 'system.now']

  private basePattern = 'user\\.[a-zA-Z0-9]+\\s+-[a-zA-Z]+\\s+[^)]+'
  private operators = '\\s*(?:-and|and|-or|or)\\s*'
  private fullPattern = `^\\(${this.basePattern}\\)(?:${this.operators}\\(${this.basePattern}\\))*$`
  private scriptFormatValidator = new RegExp(this.fullPattern)
  private ruleSeparatorRegex = new RegExp('\\s*(?:-and|\\)(?:\\s*)and(?:\\s*)\\(|-or|\\)(?:\\s*)or(?:\\s*)\\()\\s*', 'g')

  private operationMap: { [key: string]: { code: string; translationKey: string } } = {
    '-ne': { code: 'DoesNotEqual', translationKey: 'common_DoesNotEqual' },
    '-eq': { code: 'Equals', translationKey: 'common_Equals' },
    '-notStartsWith': { code: 'DoesNotStartsWith', translationKey: 'common_DoesNotStartsWith' },
    '-startsWith': { code: 'StartsWith', translationKey: 'common_StartsWith' },
    '-notContains': { code: 'DoesNotContain', translationKey: 'common_DoesNotContain' },
    '-contains': { code: 'Contains', translationKey: 'common_Contains' },
    '-notMatch': { code: 'DoesNotMatch', translationKey: 'common_DoesNotMatch' },
    '-match': { code: 'Match', translationKey: 'common_Match' },
    '-notIn': { code: 'NotIn', translationKey: 'common_NotIn' },
    '-in': { code: 'In', translationKey: 'common_In' },
  }

  private columnDefinitions: Suggestion[] = [
    { value: 'accountEnabled', displayValue: 'accountEnabled', data: { type: 'bool' } },
    { value: 'dirSyncEnabled', displayValue: 'dirSyncEnabled', data: { type: 'bool' } },
    { value: 'employeeHireDate', displayValue: 'employeeHireDate', data: { type: 'string' } },
    { value: 'city', displayValue: 'city', data: { type: 'string' } },
    { value: 'country', displayValue: 'country', data: { type: 'string' } },
    { value: 'companyName', displayValue: 'companyName', data: { type: 'string' } },
    { value: 'department', displayValue: 'department', data: { type: 'string' } },
    { value: 'displayName', displayValue: 'displayName', data: { type: 'string' } },
    { value: 'employeeId', displayValue: 'employeeId', data: { type: 'string' } },
    { value: 'facsimileTelephoneNumber', displayValue: 'facsimileTelephoneNumber', data: { type: 'string' } },
    { value: 'givenName', displayValue: 'givenName', data: { type: 'string' } },
    { value: 'jobTitle', displayValue: 'jobTitle', data: { type: 'string' } },
    { value: 'mail', displayValue: 'mail', data: { type: 'string' } },
    { value: 'mailNickName', displayValue: 'mailNickName', data: { type: 'string' } },
    { value: 'mobile', displayValue: 'mobile', data: { type: 'string' } },
    { value: 'objectId', displayValue: 'objectId', data: { type: 'string' } },
    { value: 'onPremisesDistinguishedName', displayValue: 'onPremisesDistinguishedName', data: { type: 'string' } },
    { value: 'passwordPolicies', displayValue: 'passwordPolicies', data: { type: 'string' } },
    { value: 'physicalDeliveryOfficeName', displayValue: 'physicalDeliveryOfficeName', data: { type: 'string' } },
    { value: 'postalCode', displayValue: 'postalCode', data: { type: 'string' } },
    { value: 'preferredLanguage', displayValue: 'preferredLanguage', data: { type: 'string' } },
    { value: 'sipProxyAddress', displayValue: 'sipProxyAddress', data: { type: 'string' } },
    { value: 'state', displayValue: 'state', data: { type: 'string' } },
    { value: 'streetAddress', displayValue: 'streetAddress', data: { type: 'string' } },
    { value: 'surname', displayValue: 'surname', data: { type: 'string' } },
    { value: 'telephoneNumber', displayValue: 'telephoneNumber', data: { type: 'string' } },
    { value: 'usageLocation', displayValue: 'usageLocation', data: { type: 'string' } },
    { value: 'userPrincipalName', displayValue: 'userPrincipalName', data: { type: 'string' } },
    { value: 'userType', displayValue: 'userType', data: { type: 'string' } },
    { value: 'otherMails', displayValue: 'otherMails', data: { type: 'string' } },
    { value: 'proxyAddresses', displayValue: 'proxyAddresses', data: { type: 'string' } },
    { value: 'assignedPlans', displayValue: 'assignedPlans', data: { type: 'string' } },
    { value: 'onPremisesSecurityIdentifier', displayValue: 'onPremisesSecurityIdentifier', data: { type: 'string' } },
    { value: 'extensionAttribute1', displayValue: 'extensionAttribute1', data: { type: 'string' } },
    { value: 'extensionAttribute2', displayValue: 'extensionAttribute2', data: { type: 'string' } },
    { value: 'extensionAttribute3', displayValue: 'extensionAttribute3', data: { type: 'string' } },
    { value: 'extensionAttribute4', displayValue: 'extensionAttribute4', data: { type: 'string' } },
    { value: 'extensionAttribute5', displayValue: 'extensionAttribute5', data: { type: 'string' } },
    { value: 'extensionAttribute6', displayValue: 'extensionAttribute6', data: { type: 'string' } },
    { value: 'extensionAttribute7', displayValue: 'extensionAttribute7', data: { type: 'string' } },
    { value: 'extensionAttribute8', displayValue: 'extensionAttribute8', data: { type: 'string' } },
    { value: 'extensionAttribute9', displayValue: 'extensionAttribute9', data: { type: 'string' } },
    { value: 'extensionAttribute10', displayValue: 'extensionAttribute10', data: { type: 'string' } },
    { value: 'extensionAttribute11', displayValue: 'extensionAttribute11', data: { type: 'string' } },
    { value: 'extensionAttribute12', displayValue: 'extensionAttribute12', data: { type: 'string' } },
    { value: 'extensionAttribute13', displayValue: 'extensionAttribute13', data: { type: 'string' } },
    { value: 'extensionAttribute14', displayValue: 'extensionAttribute14', data: { type: 'string' } },
    { value: 'extensionAttribute15', displayValue: 'extensionAttribute15', data: { type: 'string' } },
  ]

  constructor(private translateHelper: TranslateHelper) {}

  getAvailableOperations() {
    return Object.entries(this.operationMap)
      .map(([key, { code, translationKey }]) => {
        return {
          value: code,
          displayValue: this.translateHelper.instant(translationKey),
        }
      })
      .sort((a, b) => a.displayValue.localeCompare(b.displayValue))
  }

  getColumnDefinitions(): Suggestion[] {
    return [...this.columnDefinitions].sort((a, b) => a.displayValue.localeCompare(b.displayValue))
  }

  isRuleStringValid(ruleString: string): { isValid: boolean; errorMessageKey?: string } {
    if (!this.scriptFormatValidator.test(ruleString)) {
      return {
        isValid: false,
        errorMessageKey: 'management_RuleSyntaxNotSupported',
      }
    }

    const { isValid, errorMessageKey } = this.parseRuleStringToObj(ruleString)

    return {
      isValid,
      errorMessageKey,
    }
  }

  parseRuleStringToObj(ruleString: string): { rules: any[]; isValid: boolean; errorMessageKey?: string } {
    let conditions: string[] = ['and']
    let isValid = true
    let errorMessageKey: string | undefined

    let match: RegExpExecArray | null
    while ((match = this.ruleSeparatorRegex.exec(ruleString)) !== null) {
      conditions.push(match[0])
    }
    const parsedRules = ruleString.split(this.ruleSeparatorRegex).map((condition, i) => {
      condition = condition.replace(/^\(/, '').replace(/\)$/, '')
      const match = new RegExp(/user\.(\w+)\s(-\w+)\s(.+)/).exec(condition)
      if (!match) {
        isValid = false
        errorMessageKey = 'management_RuleSyntaxNotSupported'
        return null
      }

      const [, name, operation, value] = match

      const propertyIsValid = this.columnDefinitions.some((def) => def.value === name)
      if (!propertyIsValid) {
        isValid = false
        errorMessageKey = 'management_RulePropertyNotSupported'
        return null
      }

      const operationIsValid = Object.keys(this.operationMap).includes(operation)
      if (!operationIsValid) {
        isValid = false
        errorMessageKey = 'management_RuleOperationNotSupported'
        return null
      }

      return {
        condition: conditions[i].replace(/^\)/, '').replace(/\($/, '').trim().toUpperCase(),
        name,
        operation: this.mapStringToOperation(operation.trim()),
        value: this.parseValue(value.trim()),
      }
    })

    return {
      rules: parsedRules,
      isValid,
      errorMessageKey,
    }
  }

  convertObjToRuleString(conditions: any[]): string {
    return conditions
      .map((condition, index) => {
        const { name, operation, value, condition: cond } = condition
        const operationString = this.mapOperationToString(operation)
        const valueString = this.formatValue(value, operation)
        if (!name || value == null) {
          return ''
        }
        let conditionString = `(user.${name} ${operationString} ${valueString})`
        if (index > 0) {
          const prefix = cond?.toUpperCase() === 'OR' ? ' or ' : ' and '
          conditionString = prefix + conditionString
        }
        return conditionString
      })
      .join('')
  }

  private mapStringToOperation(operationString: string): string {
    return this.operationMap[operationString] ? this.operationMap[operationString].code : operationString
  }

  private mapOperationToString(operationCode: string): string {
    const operationEntry = Object.entries(this.operationMap).find(([, value]) => value.code === operationCode)
    return operationEntry ? operationEntry[0] : operationCode
  }

  private parseValue(value: string): any {
    if (value.startsWith('["') && value.endsWith('"]')) {
      return value.slice(2, -2).split('", "')
    } else if (value.toLowerCase() === 'true') {
      return true
    } else if (value.toLowerCase() === 'false') {
      return false
    } else if (!isNaN(Number(value))) {
      return Number(value)
    } else if (value.startsWith('"') && value.endsWith('"')) {
      return value.slice(1, -1)
    } else if (this.specialTokens.includes(value?.toLowerCase())) {
      return value
    }

    return value
  }

  private formatValue(value: any, operation?: string): string | null {
    if (operation === 'In' || operation === 'NotIn') {
      if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
        return value
      }
      const arrayValue = Array.isArray(value) ? value : [value]
      const formattedValues = arrayValue.map((v) => this.formatValue(v))
      return `[${formattedValues.join(', ')}]`
    }

    if (Array.isArray(value)) {
      return `[${value.map((v) => this.formatValue(v, operation)).join(', ')}]`
    } else if (typeof value === 'boolean') {
      return value.toString()
    } else if (value === 'true' || value === 'false') {
      return value
    } else if (typeof value === 'string' && this.specialTokens.includes(value?.toLowerCase())) {
      return value
    } else if (typeof value === 'string') {
      return `"${value}"`
    }

    return value?.toString()
  }
}

import { createReducer, on } from '@ngrx/store'
import { UserCardState } from './user-card.types'
import { setUserCard } from './user-card.actions'

export const initialState: UserCardState = {
  userCard: {userPrincipalName: '', displayName: ''},
}

export const userCardReducer = createReducer(
  initialState,
  on(setUserCard, (state, action) => {
    const ent = { userCard: action }
    return { ...state, ...ent }
  })
)

export default userCardReducer

import { Component, Input } from '@angular/core'
import { Workflow } from '@app/modules/workflow/models/workflow.model'

@Component({
  selector: 'app-action-filter-details',
  templateUrl: './action-filter-details.component.html',
  styleUrls: ['./action-filter-details.component.sass'],
})
export class ActionFilterDetailsComponent {
  @Input() action!: Workflow.Dto.V1.Common.Action

  public iconName = 'Filter'

  getGroupTitle = (index: number) => (index < 1 ? 'workflow_OnlyExecuteIf' : 'workflow_OrIf')
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Verb } from '../models/PageDataCommonClasses'
import { ApiclientService } from './apiclient.service'

@Injectable({
  providedIn: 'root',
})
export class RestService {
  constructor(private apiClient: ApiclientService, private httpClient: HttpClient) {}

  getData(url: string, verb: Verb = 'get', params?: any): Observable<any> {
    url = `${this.apiClient.basePortalApiUrl + url}`

    return this.httpClient.request(verb, url, { body: params, withCredentials: true })
  }
}

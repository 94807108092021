import {
  EnhancedJsonFormData,
  EnhancedPropertyFormDescriptor,
  EnhancedPropertyFormDescriptorOptions,
} from '@app/shared/models/enhanced-json-form-data'
import { SchemaPropertyDecorator } from './json-schema-rosetta-v1.class'

/**
 * Add an order property if it's not exist.
 *
 * This property is use by method `calculateSortedProperties` in FormBuilderComponent.
 */
export class LanguageDecorator extends SchemaPropertyDecorator {
  isDataSourceProp([key]: [string, EnhancedPropertyFormDescriptor]): boolean {
    return key === 'language'
  }

  match(schema: EnhancedJsonFormData): boolean {
    const properties = schema?.properties ?? {}

    return Object.entries(properties).some((value) => this.isDataSourceProp(value))
  }

  getJsonSchema(): EnhancedJsonFormData {
    const schema = super.getJsonSchema()

    if (this.match(schema)) {
      const properties = Object.entries(schema.properties)
        .map((value) => {
          if (!this.isDataSourceProp(value)) {
            return value
          }

          const [key, prop] = value
          const options: EnhancedPropertyFormDescriptorOptions = {
            enumApi: '/api/language/all',
            enumApiVerb: 'get',
            enumResponseProp: 'languages',
            enumGetApiParams: 'return { pageNumber: 1, pageSize: 1000 }',
            enumResponseKeyProp: 'key',
            enumResponseDisplayProp: 'value',
          }
          const newProp = {
            ...prop,
            options,
          }

          return [key, newProp]
        })
        .reduce((acc, [key, prop]) => ({ ...acc, [`${key}`]: prop }), {})

      schema.properties = properties
    }

    return schema
  }
}

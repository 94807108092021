import { Routes } from '@angular/router'
import { ReportsComponent } from './reports.component'
import { LicensePoolCenterComponent } from '../licenses/pages/license-pool-center/license-pool-center.component'
import { GenericCenterComponent } from './pages/generic-center/generic-center.component'
import { MessageTraceComponent } from './pages/message-trace/message-trace.component'
import { LicenseOptimizationCenterComponent } from '../dashboard/pages/license-optimization-center/license-optimization-center.component'
import { Microsoft365ServiceHealthComponent } from './pages/microsoft365-service-health/microsoft365-service-health.component'
import { SigninReportsComponent } from './pages/signin-reports/signin-reports.component'
import { CalendarPermissionComponent } from './pages/calendar-permission/calendar-permission.component'
import { OneDriveSharedWithExternalUsersComponent } from './pages/one-drive-shared-with-external-users/one-drive-shared-with-external-users.component'
import { QuarantinedMessagesComponent } from './pages/quarantined-messages/quarantined-messages.component'
import { UsersAnalyzerComponent } from './pages/users-analyzer/users-analyzer.component'

export const reportRoutes: Routes = [
  { path: '', component: ReportsComponent },
  {
    path: 'license/poolCenter',
    data: {
      entity: 'poolCenter',
    },
    component: LicensePoolCenterComponent,
    pathMatch: 'full'
  },
  {
    path: 'license/m365ProPlusCenter',
    data: {
      entity: 'M365ProPlusCenter',
    },
    component: GenericCenterComponent,
    pathMatch: 'full'
  },
  {
    path: 'license/subscriptionCenter',
    data: {
      entity: 'SubscriptionCenter',
    },
    component: GenericCenterComponent,
    pathMatch: 'full'
  },
  {
    path: 'license/optimizationCenter',
    data: {
      entity: 'OptimizationCenter',
    },
    component: LicenseOptimizationCenterComponent,
    pathMatch: 'full'
  },
  {
    path: 'exchange/messageTrace',
    data: {
      entity: 'messageTrace',
    },
    component: MessageTraceComponent,
    pathMatch: 'full'
  },
  {
    path: 'oneDrive/oneDriveSharedWithExternalUsers',
    data: {
      entity: 'oneDriveSharedWithExternalUsers',
    },
    component: OneDriveSharedWithExternalUsersComponent,
    pathMatch: 'full'
  },
  {
    path: 'exchange/calendarPermissions',
    data: {
      entity: 'calendarPermission',
    },
    component: CalendarPermissionComponent,
  },
  {
    path: 'exchange/quarantinedMessages',
    data: {
      entity: 'quarantinedMessages',
    },
    component: QuarantinedMessagesComponent,
    pathMatch: 'full'
  },
  ...[
    //users reports
    {
      path: 'users/users',
      data: {
        entity: 'users',
      },
    },
    {
      path: 'users/customReport',
      data: {
        entity: 'customReport',
      },
    },
    {
      path: 'users/customPivot',
      data: {
        entity: 'customPivot',
      },
    },
    {
      path: 'users/deletedUsers',
      data: {
        entity: 'deletedUsers',
      },
    },
    {
      path: 'users/usersAnalyzer',
      data: {
        entity: 'usersAnalyzer',
      },
      component: UsersAnalyzerComponent,
    },
    {
      path: 'users/multiGeo',
      data: {
        entity: 'multiGeo',
      },
    },
    {
      path: 'users/guestUsers',
      data: {
        entity: 'guestUsers',
      },
    },
    {
      path: 'users/partialImportUsers',
      data: {
        entity: 'partialImportUsers',
      },
    },
    {
      path: 'users/inactiveGuestUsers',
      data: {
        entity: 'inactiveGuestUsers',
      },
    },
    {
      path: 'users/disabledUsers',
      data: {
        entity: 'disabledUsers',
      },
    },
    {
      path: 'users/createdBy',
      data: {
        entity: 'createdBy',
      },
    },

    //license reports
    {
      path: 'license/subscriptionOverview',
      data: {
        entity: 'subscriptionOverview',
      },
    },
    {
      path: 'license/subscriptionsList',
      data: {
        entity: 'subscriptionsList',
      },
    },
    {
      path: 'license/subscriptionsHistory',
      data: {
        entity: 'subscriptionsHistory',
      },
    },
    {
      path: 'license/licenseByUser',
      data: {
        entity: 'licenseByUser',
      },
    },
    {
      path: 'license/usersWithoutLicense',
      data: {
        entity: 'usersWithoutLicense',
      },
    },
    {
      path: 'license/licensePoolReport',
      data: {
        entity: 'licensePoolReport',
      },
    },
    {
      path: 'license/licensePoolCostReport',
      data: {
        entity: 'licensePoolCostReport',
      },
    },
    {
      path: 'license/licenseOptimization',
      data: {
        entity: 'licenseOptimization',
      },
    },
    {
      path: 'license/duplicatedPlans',
      data: {
        entity: 'duplicatedPlans',
      },
    },
    {
      path: 'license/licensePoolReportSnapshots',
      data: {
        entity: 'licensePoolReportSnapshots',
      },
    },
    {
      path: 'license/serviceUsageByLicensePool',
      data: {
        entity: 'serviceUsageByLicensePool',
      },
    },
    {
      path: 'license/serviceUsage',
      data: {
        entity: 'serviceUsage',
      },
    },
    {
      path: 'license/softwareLicenses',
      data: {
        entity: 'softwareLicenses',
      },
    },
    {
      path: 'license/officeInstallations',
      data: {
        entity: 'officeInstallations',
      },
    },
    {
      path: 'license/office365ActiveUsers',
      data: {
        entity: 'office365ActiveUsers',
      },
    },
    {
      path: 'license/accountSkuHistory',
      data: {
        entity: 'accountSkuHistory',
      },
    },
    {
      path: 'license/disabledLicensedUsers',
      data: {
        entity: 'disabledLicensedUsers',
      },
    },
    {
      path: 'license/microsoft365AppsUsage',
      data: {
        entity: 'microsoft365AppsUsage',
      },
    },
    {
      path: 'license/licensesByTenant',
      data: {
        entity: 'licensesByTenant',
      },
    },
    //exchange reports
    {
      path: 'exchange/exchangeInactiveMailboxes',
      data: {
        entity: 'exchangeInactiveMailboxes',
      },
    },
    {
      path: 'exchange/emailAddresses',
      data: {
        entity: 'emailAddresses',
      },
    },
    {
      path: 'exchange/mailboxSizes',
      data: {
        entity: 'mailboxSizes',
      },
    },
    {
      path: 'exchange/archiveSizes',
      data: {
        entity: 'archiveSizes',
      },
    },
    {
      path: 'exchange/recipientsByType',
      data: {
        entity: 'recipientsByType',
      },
    },
    {
      path: 'exchange/recipientCountOverTime',
      data: {
        entity: 'recipientCountOverTime',
      },
    },
    {
      path: 'exchange/usersAuditStatus',
      data: {
        entity: 'usersAuditStatus',
      },
    },
    {
      path: 'exchange/usersWithArchiveMailbox',
      data: {
        entity: 'usersWithArchiveMailbox',
      },
    },
    {
      path: 'exchange/usersWithForwardSet',
      data: {
        entity: 'usersWithForwardSet',
      },
    },
    {
      path: 'exchange/usersWithForwardSet/:hasExternalForwarding',
      data: {
        entity: 'usersWithForwardSet',
      },
    },
    {
      path: 'exchange/usersWithAutoReply',
      data: {
        entity: 'usersWithAutoReply',
      },
    },
    {
      path: 'exchange/mailboxesWithoutLicense',
      data: {
        entity: 'mailboxesWithoutLicense',
      },
    },
    {
      path: 'exchange/usersByConnectionType',
      data: {
        entity: 'usersByConnectionType',
      },
    },
    {
      path: 'exchange/sharedMailboxesWithoutDelegates',
      data: {
        entity: 'sharedMailboxesWithoutDelegates',
      },
    },
    {
      path: 'exchange/exchangeEmailActivity',
      data: {
        entity: 'exchangeEmailActivity',
      },
    },
    {
      path: 'exchange/exchangeEmailAppUsage',
      data: {
        entity: 'exchangeEmailAppUsage',
      },
    },
    {
      path: 'exchange/exchangeMailboxUsage',
      data: {
        entity: 'exchangeMailboxUsage',
      },
    },
    //under exchange report but with security in route, why???
    {
      path: 'security/usersByRetentionPolicy',
      data: {
        entity: 'usersByRetentionPolicy',
      },
    },

    //teams reports
    {
      path: 'teams/teamsUserActivity',
      data: {
        entity: 'teamsUserActivity',
      },
    },
    {
      path: 'teams/teamsUsage',
      data: {
        entity: 'teamsUsage',
      },
    },
    {
      path: 'teams/teamsUsers',
      data: {
        entity: 'teamsUsers',
      },
    },
    {
      path: 'teams/teamsDeviceUsage',
      data: {
        entity: 'teamsDeviceUsage',
      },
    },
    {
      path: 'teams/teamsGroups',
      data: {
        entity: 'teamsGroups',
      },
    },
    {
      path: 'teams/teamsChannels',
      data: {
        entity: 'teamsChannels',
      },
    },
    {
      path: 'teams/teamsMembers',
      data: {
        entity: 'teamsMembers',
      },
    },
    {
      path: 'teams/emptyTeamsGroups',
      data: {
        entity: 'emptyTeamsGroups',
      },
    },
    {
      path: 'teams/teamsGroupsWithoutOwners',
      data: {
        entity: 'teamsGroupsWithoutOwners',
      },
    },
    {
      path: 'teams/teamsExternalMembers',
      data: {
        entity: 'teamsExternalMembers',
      },
    },
    {
      path: 'teams/teamsInactiveUsers',
      data: {
        entity: 'teamsInactiveUsers',
      },
    },
    {
      path: 'teams/userCallQuality',
      data: {
        entity: 'userCallQuality',
      },
    },
    {
      path: 'teams/callQuality',
      data: {
        entity: 'callQuality',
      },
    },
    {
      path: 'teams/qualityCallDashboard',
      data: {
        entity: 'qualityCallDashboard',
      },
    },
    {
      path: 'teams/teamsGroupsActivity',
      data: {
        entity: 'teamsGroupsActivity',
      },
    },
    {
      path: 'teams/teamsAdoptionGrowth',
      data: {
        entity: 'teamsAdoptionGrowth',
      },
    },
    {
      path: 'teams/teamsVoiceUsers',
      data: {
        entity: 'teamsVoiceUsers',
      },
    },
    {
      path: 'teams/teamsNumbers',
      data: {
        entity: 'teamsNumbers',
      },
    },
    {
      path: 'teams/teamsLocations',
      data: {
        entity: 'teamsLocations',
      },
    },
    {
      path: 'teams/pstnUsage',
      data: {
        entity: 'teamsPstnUsage',
      },
    },
    {
      path: 'teams/teamsCallQueues',
      data: {
        entity: 'teamsCallQueues',
      },
    },
    {
      path: 'teams/teamsAutoAttendantHolidays',
      data: {
        entity: 'teamsAutoAttendantHolidays',
      },
    },
    {
      path: 'teams/teamsAutoAttendants',
      data: {
        entity: 'teamsAutoAttendants',
      },
    },
    {
      path: 'teams/teamsPhoneNumbers',
      data: {
        entity: 'teamsPhoneNumbers',
      },
    },
    {
      path: 'teams/teamsPrivateSharedChannelMembers',
      data: {
        entity: 'teamsPrivateSharedChannelMembers',
      },
    },
    {
      path: 'teams/users',
      data: {
        entity: 'teamsOnlineUsers',
      },
    },
    //group reports
    {
      path: 'groups/distributionGroups',
      data: {
        entity: 'distributionGroups',
      },
    },
    {
      path: 'groups/distributionGroupSizes',
      data: {
        entity: 'distributionGroupSizes',
      },
    },
    {
      path: 'groups/acceptingExternalMail',
      data: {
        entity: 'acceptingExternalMail',
      },
    },
    {
      path: 'groups/distributionGroupMembers',
      data: {
        entity: 'distributionGroupMembers',
      },
    },
    {
      path: 'groups/distributionGroupsInactive',
      data: {
        entity: 'distributionGroupsInactive',
      },
    },
    {
      path: 'groups/office365Groups',
      data: {
        entity: 'office365Groups',
      },
    },
    {
      path: 'groups/office365GroupMembers',
      data: {
        entity: 'office365GroupMembers',
      },
    },
    {
      path: 'groups/office365GroupsActivity',
      data: {
        entity: 'office365GroupsActivity',
      },
    },
    {
      path: 'groups/office365DeletedGroups',
      data: {
        entity: 'office365DeletedGroups',
      },
    },
    {
      path: 'groups/securityGroups',
      data: {
        entity: 'securityGroups',
      },
    },
    {
      path: 'groups/securityGroupMembers',
      data: {
        entity: 'securityGroupMembers',
      },
    },
    {
      path: 'groups/emptyDistributionGroups',
      data: {
        entity: 'emptyDistributionGroups',
      },
    },
    {
      path: 'groups/emptyOffice365Groups',
      data: {
        entity: 'emptyOffice365Groups',
      },
    },
    {
      path: 'groups/emptySecurityGroups',
      data: {
        entity: 'emptySecurityGroups',
      },
    },
    //no more available?
    // {
    //     path: 'groups/office365GroupsInactive',
    //     data: {
    //         accessLevel: accessLevels.user,
    //         entity: 'office365GroupsInactive'
    //     }
    // },
    //device reports
    {
      path: 'device/usersWithMobileDevices',
      data: {
        entity: 'usersWithMobileDevices',
      },
    },
    {
      path: 'device/mobileDevices',
      data: {
        entity: 'mobileDevices',
      },
    },
    {
      path: 'device/quarantined',
      data: {
        entity: 'quarantinedMobileDevices',
      },
    },
    {
      path: 'device/mobileDevicesByOS',
      data: {
        entity: 'mobileDevicesByOS',
      },
    },
    {
      path: 'device/mobileDevicesByManufacturer',
      data: {
        entity: 'mobileDevicesByManufacturer',
      },
    },
    {
      path: 'device/mobileDevicesByPolicy',
      data: {
        entity: 'mobileDevicesByPolicy',
      },
    },
    {
      path: 'device/policyApplicationStatus',
      data: {
        entity: 'policyApplicationStatus',
      },
    },
    {
      path: 'device/bitLockerKeys',
      data: {
        entity: 'bitLockerKeys',
      },
    },
    {
      path: 'device/devices',
      data: {
        entity: 'devices',
      },
    },
    {
      path: 'device/devices/notcompliant',
      data: {
        entity: 'devicesNotCompliant',
      },
    },
    //endpoint reports
    {
      path: 'endpointManager/intuneDevices',
      data: {
        entity: 'intuneDevices',
      },
    },
    {
      path: 'endpointManager/intuneDevices/notcompliant',
      data: {
        entity: 'intuneDevicesNotCompliant',
      },
    },
    {
      path: 'endpointManager/intuneDevices/notCompliantComplianceDevices',
      data: {
        entity: 'intuneNotCompliantComplianceDevices',
      },
    },
    {
      path: 'endpointManager/intuneBitLockerKeys',
      data: {
        entity: 'intuneBitLockerKeys',
      },
    },
    {
      path: 'endpointManager/importedWindowsAutoPilotDeviceIdentities',
      data: {
        entity: 'autoPilotDevicesImportStatus',
      },
    },
    {
      path: 'endpointManager/autoPilotDevices',
      data: {
        entity: 'autoPilotDevices',
      },
    },

    //security reports
    {
      path: 'security/userMailboxSecurity',
      data: {
        entity: 'userMailboxSecurity',
      },
    },
    {
      path: 'security/usersOnLitigationHold',
      data: {
        entity: 'usersOnLitigationHold',
      },
    },
    {
      path: 'security/mailboxesOnLitigationHold',
      data: {
        entity: 'mailboxesOnLitigationHold',
      },
    },
    {
      path: 'security/userPasswordSettings',
      data: {
        entity: 'userPasswordSettings',
      },
    },
    {
      path: 'security/allUsersPasswordSettings',
      data: {
        entity: 'allUsersPasswordSettings',
      },
    },
    {
      path: 'security/administrativeRoles',
      data: {
        entity: 'administrativeRoles',
      },
    },
    {
      path: 'security/licensedUsersWithAdminRoles',
      data: {
        entity: 'licensedUsersWithAdminRoles',
      },
    },
    {
      path: 'security/usersWithAdminRoles',
      data: {
        entity: 'usersWithAdminRoles',
      },
    },
    {
      path: 'security/userAccessingMultipleMailboxes',
      data: {
        entity: 'userAccessingMultipleMailboxes',
      },
    },
    {
      path: 'security/mailboxesWithDelegates',
      data: {
        entity: 'mailboxesWithDelegates',
      },
    },
    // no more available??
    // {
    //     path: 'security/sharedMailboxSecurity',
    //     data: {
    //         accessLevel: accessLevels.user,
    //         entity: 'sharedMailboxSecurity'
    //     }
    // },

    //operational reports
    {
      path: 'operational/microsoft365ServiceHealth',
      data: {
        entity: 'microsoft365ServiceHealth',
      },
      component: Microsoft365ServiceHealthComponent,
    },
    {
      path: 'operational/serviceHealthIssues',
      data: {
        entity: 'serviceHealthIssues',
      },
    },
    {
      path: 'operational/microsoft365Messages',
      data: {
        entity: 'microsoft365Messages',
      },
    },
    {
      path: 'operational/dirSyncErrors',
      data: {
        entity: 'dirSyncErrors',
      },
    },
    // one drive
    {
      path: 'oneDrive/oneDriveMultipleOwners',
      data: {
        entity: 'oneDriveMultipleOwners',
      },
    },
    {
      path: 'oneDrive/oneDriveInactiveUsers',
      data: {
        entity: 'oneDriveInactiveUsers',
      },
    },
    {
      path: 'oneDrive/oneDriveActivityOverTime',
      data: {
        entity: 'oneDriveActivityOverTime',
      },
    },
    {
      path: 'oneDrive/oneDriveOwners',
      data: {
        entity: 'oneDriveOwners',
      },
    },
    {
      path: 'oneDrive/oneDriveSizes',
      data: {
        entity: 'oneDriveSizes',
      },
    },
    {
      path: 'oneDrive/oneDriveUsage',
      data: {
        entity: 'oneDriveUsage',
      },
    },
    {
      path: 'oneDrive/oneDriveOverTime',
      data: {
        entity: 'oneDriveOverTime',
      },
    },
    {
      path: 'oneDrive/oneDriveActivity',
      data: {
        entity: 'oneDriveActivity',
      },
    },
    {
      path: 'oneDrive/oneDriveStatistics',
      data: {
        entity: 'oneDriveStatistics',
      },
    },
    // yammer
    {
      path: 'yammer/yammerActivities',
      data: {
        entity: 'yammerActivities',
      },
    },
    {
      path: 'yammer/yammerDeviceUsage',
      data: {
        entity: 'yammerDeviceUsage',
      },
    },
    {
      path: 'yammer/yammerGroupsActivity',

      data: {
        entity: 'yammerGroupsActivity',
      },
    },
    // copilot
    {
      path: 'copilot/copilotEligibleUsers',
      data: {
        entity: 'copilotEligibleUsers',
      },
    },
    {
      path: 'copilot/copilotUsage',
      data: {
        entity: 'copilotUsage',
      },
    },
    //mail traffic
    {
      path: 'mailTraffic/trafficByUsers',
      data: {
        entity: 'trafficByUsers',
      },
    },
    {
      path: 'mailTraffic/trafficByUsersDetails',
      data: {
        entity: 'trafficByUsersDetails',
      },
    },
    {
      path: 'mailTraffic/topSendersAndReceivers',
      data: {
        entity: 'topSendersAndReceivers',
      },
    },
    {
      path: 'mailTraffic/mailTraffic',
      data: {
        entity: 'mailTraffic',
      },
    },
    {
      path: 'mailTraffic/spamAndMalware',
      data: {
        entity: 'spamAndMalware',
      },
    },
    {
      path: 'mailTraffic/mailWithLowTraffic',
      data: {
        entity: 'mailWithLowTraffic',
      },
    },
    {
      path: 'mailTraffic/externalVsInternal',
      data: {
        entity: 'externalVsInternal',
      },
    },
    {
      path: 'mailTraffic/malwareDetail',
      data: {
        entity: 'malwareDetail',
      },
    },
    //audits
    {
      path: 'audits/CopilotActivities',
      data: {
        entity: 'auditCopilotActivities',
      },
    },
    {
      path: 'audits/auditActivities',
      data: {
        entity: 'auditActivities',
      },
    },
    {
      path: 'audits/activitiesExchange',
      data: {
        entity: 'auditActivitiesExchange',
      },
    },
    {
      path: 'audits/activitiesSharePoint',
      data: {
        entity: 'auditActivitiesSharePoint',
      },
    },
    {
      path: 'audits/adminMailboxActivities',
      data: {
        entity: 'auditAdminMailboxActivities',
      },
    },
    {
      path: 'audits/dlpActivitiesExchange',
      data: {
        entity: 'auditDlpActivitiesExchange',
      },
    },
    {
      path: 'audits/externalInvitationsSharePoint',
      data: {
        entity: 'auditExternalInvitationsSharePoint',
      },
    },
    {
      path: 'audits/externalAccessSharePoint',
      data: {
        entity: 'auditExternalAccessSharePoint',
      },
    },
    {
      path: 'audits/nonOwnerMailboxActivities',
      data: {
        entity: 'auditNonOwnerMailboxActivities',
      },
    },
    {
      path: 'audits/sendAsAndOnBehalfExchange',
      data: {
        entity: 'auditSendAsAndOnBehalfExchange',
      },
    },
    {
      path: 'audits/oneDriveActivities',
      data: {
        entity: 'auditOneDriveActivities',
      },
    },
    {
      path: 'audits/threatIntelligenceActivities',
      data: {
        entity: 'auditThreatIntelligenceActivities',
      },
    },
    {
      path: 'audits/teamsActivities',
      data: {
        entity: 'auditTeamsActivities',
      },
    },
    {
      path: 'audits/powerBIActivities',
      data: {
        entity: 'auditPowerBIActivities',
      },
    },
    {
      path: 'audits/securityAndComplianceActivities',
      data: {
        entity: 'auditSecurityAndComplianceActivities',
      },
    },
    {
      path: 'audits/crmActivities',
      data: {
        entity: 'auditCrmActivities',
      },
    },
    {
      path: 'audits/activeDirectoryEvents',
      data: {
        entity: 'auditActiveDirectoryEvents',
      },
    },
    {
      path: 'audits/powerAppsActivities',
      data: {
        entity: 'auditPowerAppsActivities',
      },
    },
    {
      path: 'audits/mailboxRightsChangesExchange',
      data: {
        entity: 'auditMailboxRightsChangesExchange',
      },
    },
    {
      path: 'audits/anonymousInvitationsSharePoint',
      data: {
        entity: 'auditAnonymousInvitationsSharePoint',
      },
    },
    {
      path: 'audits/siteCollectionAdminChangesSharePoint',
      data: {
        entity: 'auditSiteCollectionAdminChangesSharePoint',
      },
    },
    {
      path: 'audits/dlpActivitiesSharePoint',
      data: {
        entity: 'auditDlpActivitiesSharePoint',
      },
    },
    {
      path: 'audits/sharingOperationsSharePoint',
      data: {
        entity: 'auditSharingOperationsSharePoint',
      },
    },
    {
      path: 'audits/permissionChangesSharePoint',
      data: {
        entity: 'auditPermissionChangesSharePoint',
      },
    },
    {
      path: 'audits/externalInvitationsOneDrive',
      data: {
        entity: 'auditExternalInvitationsOneDrive',
      },
    },
    {
      path: 'audits/externalAccessOneDrive',
      data: {
        entity: 'auditExternalAccessOneDrive',
      },
    },
    {
      path: 'audits/anonymousInvitationsOneDrive',
      data: {
        entity: 'auditAnonymousInvitationsOneDrive',
      },
    },
    {
      path: 'audits/siteProvisioningOneDrive',
      data: {
        entity: 'auditSiteProvisioningOneDrive',
      },
    },
    {
      path: 'audits/dlpActivitiesOneDrive',
      data: {
        entity: 'auditDlpActivitiesOneDrive',
      },
    },
    {
      path: 'audits/sharingOperationsOneDrive',
      data: {
        entity: 'auditSharingOperationsOneDrive',
      },
    },
    {
      path: 'audits/permissionChangesOneDrive',
      data: {
        entity: 'auditPermissionChangesOneDrive',
      },
    },
    {
      path: 'audits/channelOperationsTeams',
      data: {
        entity: 'auditChannelOperationsTeams',
      },
    },
    {
      path: 'audits/membershipOperationsTeams',
      data: {
        entity: 'auditMembershipOperationsTeams',
      },
    },
    {
      path: 'audits/teamOperationsTeams',
      data: {
        entity: 'auditTeamOperationsTeams',
      },
    },
    {
      path: 'audits/externalInvitationsTeams',
      data: {
        entity: 'auditExternalInvitationsTeams',
      },
    },
    {
      path: 'audits/anonymousInvitationsTeams',
      data: {
        entity: 'auditAnonymousInvitationsTeams',
      },
    },
    {
      path: 'audits/externalAccessTeams',
      data: {
        entity: 'auditExternalAccessTeams',
      },
    },
    {
      path: 'audits/externalAccessTeams',
      data: {
        entity: 'auditExternalAccessTeams',
      },
    },
    {
      path: 'audits/siteCollectionAdminChangesTeams',
      data: {
        entity: 'auditSiteCollectionAdminChangesTeams',
      },
    },
    {
      path: 'audits/dlpActivitiesTeams',
      data: {
        entity: 'auditDlpActivitiesTeams',
      },
    },
    {
      path: 'audits/sharingOperationsTeams',
      data: {
        entity: 'auditSharingOperationsTeams',
      },
    },
    {
      path: 'audits/permissionChangesTeams',
      data: {
        entity: 'auditPermissionChangesTeams',
      },
    },
    {
      path: 'audits/createdPowerApps',
      data: {
        entity: 'auditCreatedPowerApps',
      },
    },
    {
      path: 'audits/launchedPowerApps',
      data: {
        entity: 'auditLaunchedPowerApps',
      },
    },
    {
      path: 'audits/publishedPowerApps',
      data: {
        entity: 'auditPublishedPowerApps',
      },
    },
    {
      path: 'audits/permissionsPowerApps',
      data: {
        entity: 'auditPermissionsPowerApps',
      },
    },
    {
      path: 'audits/signinEvents',
      data: {
        entity: 'auditSigninEvents',
      },
      component: SigninReportsComponent,
    },
    {
      path: 'audits/signinEventsAdminRoles',
      data: {
        entity: 'auditSigninEventsAdminRoles',
      },
      component: SigninReportsComponent,
    },
    {
      path: 'audits/signinEventsExternals',
      data: {
        entity: 'auditSigninEventsExternals',
      },
      component: SigninReportsComponent,
    },
    {
      path: 'audits/signinEventsFailed',
      data: {
        entity: 'auditSigninEventsFailed',
      },
      component: SigninReportsComponent,
    },
    {
      path: 'audits/riskyUsers',
      data: {
        entity: 'auditRiskyUsers',
      },
    },
    {
      path: 'audits/riskDetections',
      data: {
        entity: 'auditRiskDetections',
      },
    },
    {
      path: 'audits/riskDetectionsAnonymousIP',
      data: {
        entity: 'auditRiskDetectionsAnonymousIP',
      },
    },
    {
      path: 'audits/riskMalware',
      data: {
        entity: 'auditRiskMalware',
      },
    },
    {
      path: 'audits/riskDetectionsUnfamiliarLocation',
      data: {
        entity: 'auditRiskDetectionsUnfamiliarLocation',
      },
    },
    {
      path: 'audits/riskDetectionsImpossibleTravels',
      data: {
        entity: 'auditRiskDetectionsImpossibleTravels',
      },
    },
    {
      path: 'audits/monthlySignInStatsByUser',
      data: {
        entity: 'auditMonthlySignInStatsByUser',
      },
    },
    {
      path: 'audits/monthlySignInStatsByApp',
      data: {
        entity: 'auditMonthlySignInStatsByApp',
      },
    },
    {
      path: 'audits/signinLegacyProtocols',
      data: {
        entity: 'auditSigninLegacyProtocols',
      },
      component: SigninReportsComponent,
    },
    // activities
    {
      path: 'activities/operators-activities',
      data: {
        entity: 'operatorsActivities',
      },
    },
    {
      path: 'activities/audit',
      data: {
        entity: 'auditLogs',
      },
    },
    {
      path: 'exchange/messageTrace',
      data: {
        entity: 'messageTrace',
      },
    },
    {
      path: 'intune/configurationPolicies',
      data: {
        entity: 'intuneConfigurationPolicies',
      },
    },
    {
      path: 'intune/notCompliantConfigurationDevices',
      data: {
        entity: 'intuneNotCompliantConfigurationDevices',
      },
    },
    {
      path: 'intune/notCompliantConfigurationEncryptionDevices',
      data: {
        entity: 'intuneNotCompliantConfigurationEncryptionDevices',
      },
    },
    {
      path: 'intune/compliancePolicies',
      data: {
        entity: 'intuneCompliancePolicies',
      },
    },
    {
      path: 'intune/RemoteActionAudit',
      data: {
        entity: 'intuneRemoteActionAudits',
      },
    },
    ...[//sharepoint reports
    ['sharePoint/sites', 'sharepointSites'],
    ['sharePoint/siteUsage', 'sharepointSiteUsage'],
    ['sharePoint/mostActiveSites', 'sharePointMostActiveSites'],
    ['sharePoint/inactiveUsers', 'sharePointInactiveUsers'],
    ['sharePoint/navigator', 'sharePointNavigator'],
    ['sharePoint/librarySizes', 'sharePointLibrarySizes'],
    ['sharePoint/activity', 'sharePointActivity'],
    ['sharePoint/deletedSites', 'sharePointDeletedSites'],
    ['sharePoint/owners', 'sharePointOwners' ],
    ['sharePoint/sitesMembers', 'sharePointSitesMembers'],
    ['entraApps/enterpriseApplications', 'enterpriseApplications'],
    ['entraApps/delegatedPermissions', 'enterpriseApplicationsDelegatedPermissions'],
    ['entraApps/applicationsPermissions', 'enterpriseApplicationsPermissions'],
    ['entraApps/appsRegistrations', 'enterpriseApplicationsRegistrations'],
    ['entraApps/appsRegistrationsSecrets', 'enterpriseApplicationsRegistrationsSecrets'],
    ['entraApps/appsRegistrationsPermissions', 'enterpriseApplicationsRegistrationsPermissions'],
    ['entraApps/appsCertificates', 'enterpriseApplicationsCertificates'],
    ['entraApps/enterpriseApplicationsOwners', 'enterpriseApplicationsOwners'],
    ['entraApps/applicationsByUser', 'enterpriseApplicationsByUser'],
    ['entraApps/appsRegistrationsOwners', 'enterpriseApplicationsRegistrationsOwners'],
    ].map((p: any) => ({
      path: p[0],
      data: {
        entity: p[1],
      },
    }))
  ],
]

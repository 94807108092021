import { DashboardDefinition } from './../../core/models/DashboardDefinition'
import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity'
import { DashboardState } from './dashboards.types'
import { getDashboards, getDashboardsInvalidate, getDashboardsResponse } from './dashboards.actions'

export const dashboardsAdapter: EntityAdapter<DashboardDefinition> = createEntityAdapter<DashboardDefinition>({
  selectId: ({ route }) => route,
})

export const initialState: DashboardState = dashboardsAdapter.getInitialState({
  didInvalidate: false,
  isFetching: false,
  error: null,
  loaded: false,
})

export const dashboardsReducer = createReducer(
  initialState,
  on(getDashboards, (state) => ({ ...state, didInvalidate: false, isFetching: true, error: null })),
  on(getDashboardsResponse, (state, dashboards) => ({ ...dashboardsAdapter.setAll(dashboards.actions, state), loaded: true })),
  on(getDashboardsInvalidate, (state, action) => ({ ...state, error: action.error, didInvalidate: true, loaded: true }))
)

export default dashboardsReducer

<div
  class="reports-item spacer"
  title="{{ item.name }}"
  (click)="handleManageItem(item)"
  (mouseenter)="showOnHover[index] = true"
  (mouseleave)="showOnHover[index] = false"
>
  <div class="reports-item-child" [ngClass]="showOnHover[index] && isElmentManageable(item) ? 'manageHover' : ''">
    <span class="circle-icon-container"><em class="circle-icon circle-icon-small" [class]="item.classIcon"></em></span>
    <div class="result-name-container">
      <div class="result-name" ng-bind-html="item.name">{{ item.name }}</div>
      <div class="result-name-sub" *ngIf="item.email">{{ item.email }}</div>
      <div class="result-name-sub" *ngIf="item.category">{{ item.category }}</div>
    </div>
  </div>
  <cv-button
    *ngIf="isElmentManageable(item) && showOnHover[index]"
    class="manage-btn"
    buttonType="tertiary"
    [leftIcon]="'settings'"
    [text]="'Manage'"
    (click)="handleOnManageableElementClick(item)"
  ></cv-button>
</div>

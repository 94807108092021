import isArray from 'lodash-es/isArray'
import isString from 'lodash-es/isString'

export const defaultHourlyCronExpression = '0 0 */1 1/1 * ? *'
export const defaultDailyCronExpression = '0 0 0 */1 * ? *'
export const defaultWeekDaysCronExpression = '0 0 0 ? * MON-FRI *'
export const defaultWeeklyCronExpression = '0 0 0 ? * MON *'
export const defaultMonthlyCronExpression = '0 0 0 1 */1 ? *'
export const defaultDaysOfWeekMonthCronExpression = '0 0 0 ? * 1#1 *'
export const defaultLastDayOfMonthCronExpression = '0 0 0 L * ? *'
export const defaultLastDaysBeforeEndOfMonthCronExpression = '0 0 0 L-1 * ? *'

export const convertCronExpressionArrayToString = (cronExpressionArray: any[]) =>
  cronExpressionArray && isArray(cronExpressionArray) && cronExpressionArray.length > 0 ? cronExpressionArray.join(' ') : ''

export const convertCronExpressionToArray = (cronExpression: string) =>
  cronExpression && isString(cronExpression) ? cronExpression.split(' ') : []

import { DashboardDefinition } from './../models/DashboardDefinition'
import { pluck } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiclientService } from './apiclient.service'

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private apiClient: ApiclientService, private httpClient: HttpClient) {}

  getDashboard(route: string): Observable<DashboardDefinition> {
    const url = `${this.apiClient.baseCentralUrl}api/v2/dashboarddefinitions/${encodeURIComponent(route)}`
    return this.httpClient.get<any>(url, { withCredentials: true }).pipe(pluck('dashboard'))
  }

  getDashboards(): Observable<DashboardDefinition[]> {
    const url = `${this.apiClient.baseCentralUrl}api/v2/dashboarddefinitions`
    return this.httpClient.get<any>(url, { withCredentials: true }).pipe(pluck('dashboards'))
  }

  getDashboardDetails(): Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}api/dashboards`
    return this.httpClient.get<any>(url, { withCredentials: true }).pipe(pluck('dashboardDetail'))
  }
  
  getData(dataUrl: string, dataProperty: string, params: any = {}): Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/${dataUrl}`
    const call = this.httpClient.get<any>(url, { params, withCredentials: true })
    return dataProperty ? call.pipe(pluck(dataProperty)) : call
  }

  getPropertyData(urlProperty: string): Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/${urlProperty}`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  postPropertyData(urlProperty: string, params: any, dataProperty: string): Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/${urlProperty}`
    return this.httpClient.post<any>(url, params, { withCredentials: true }).pipe(pluck(dataProperty))
  }

  getServicesUsageProductFilters(companyId: string): Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/Office365UsageAnalytics/productfiltersTree/${companyId}`
    return this.httpClient.get<any>(url, { withCredentials: true }).pipe(pluck('items', 'filters'))
  }

  getServicesUsageDates(companyId: string): Observable<string[]> {
    const url = `${this.apiClient.basePortalApiUrl}/Office365UsageAnalytics/dates/${companyId}`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }
}

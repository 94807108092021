import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'

@Component({
  selector: 'app-not-allowed-dialog',
  templateUrl: './not-allowed-dialog.component.html',
  styleUrls: ['./not-allowed-dialog.component.sass'],
})
export class NotAllowedDialogComponent {
  constructor(public dialogRef: MatDialogRef<NotAllowedDialogComponent>, private router: Router) {}

  gotoHome(): void {
    this.router.navigate(['']).catch((_: any) => _)
    this.dialogRef.close()
  }

  closeDialog(): void {
    this.dialogRef.close()
  }
}

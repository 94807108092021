<div class="persistable-cell-editor-container">
  <span *ngIf="!submitting" (click)="cancel()" class="icon-button-container cancel m-r-5">
    <mat-icon class="input-icon"> close </mat-icon>
  </span>

  <input
    #input
    [type]="valueType"
    [(ngModel)]="value"
    [attr.max]="maxValue !== undefined ? maxValue : null"
    [attr.min]="minValue !== undefined ? minValue : null"
    [ngClass]="{ 'has-error': !isValid() }"
    [disabled]="submitting"
  />

  <span *ngIf="!submitting" (click)="submit()" class="icon-button-container submit m-l-5" [class]="{ disabled: !isValid() }">
    <mat-icon class="input-icon"> check </mat-icon>
  </span>

  <mat-icon *ngIf="submitting" class="submitting-icon m-l-5"> timelapse </mat-icon>
</div>

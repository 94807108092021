<input
  [disabled]="disabled || readonly"
  [class.disabled]="disabled || readonly"
  [placeholder]="(disabled ? 'common_ClickOnFilter': '') | translate"
  #input
  class="generic-floating-filter-input"
  [(ngModel)]="currentValue"
  (input)="onInputBoxChanged()"
  attr.cv-data-test="{{ 'grid-filter_' + params.column.getColId() }}"
/>
<mat-icon
  (click)="clear(); $event.stopPropagation()"
  *ngIf="input.value && !readonly"
  class="icon"
  style="position: absolute; right: 2px; top: calc(50% - 10px)"
  >close</mat-icon
>

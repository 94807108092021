import { Injectable } from '@angular/core'

import { flatMap, isNil, map } from 'lodash-es'
import { Observable } from 'rxjs'

import { Workflow } from '@app/modules/workflow/models/workflow.model'
import { EnhancedJsonFormData, EnhancedProperties, EnhancedPropertyFormDescriptor } from '@shared/models/enhanced-json-form-data'
import { DynamoFormHelperService } from '@shared/utilities/dynamo-form.helper.service'
import { ExtendedBindValue } from '@coreview/coreview-components'
import { PropertyFormDescriptor } from '@coreview/coreview-dynamoforms/interfaces/json-form-data.interface'
import {
  DateTimePickerDecorator,
  FilterableSourceDecorator,
  LanguageDecorator,
  PasswordDecorator,
  RichTextDecorator,
  TitleDecorator,
  WorkflowErrorDecorator,
} from '../json-schema-rosetta-v1/decorators'
import { DecoratorList, JsonSchemaV1Rosetta } from '../json-schema-rosetta-v1/json-schema-rosetta-v1.class'
import { AddActionEvent } from '../../workflow-builder/models/add-action-event.model'

type ActionHierarchy = {
  level: number
  index: number
  branch?: string
}

@Injectable({
  providedIn: 'root',
})
export class DynamoFormV1HelperService {
  decorators: DecoratorList = [
    WorkflowErrorDecorator,
    // OrderDecorator,
    TitleDecorator,
    FilterableSourceDecorator,
    LanguageDecorator,
    DateTimePickerDecorator,
    PasswordDecorator,
    RichTextDecorator,
  ]

  constructor(private dynamoFormsHelper: DynamoFormHelperService) {}

  getFormattedJsonFormData(jsonSchema: string, options: { debug?: boolean } = {}): Observable<EnhancedJsonFormData | null> {
    const jsonFormData = JSON.parse(jsonSchema) as EnhancedJsonFormData

    if (options?.debug === true) {
      console.log(`original json schema`, jsonFormData)
    }

    if (isNil(jsonFormData?.properties)) {
      jsonFormData.properties = {}
    }

    let rosetta = new JsonSchemaV1Rosetta(jsonFormData)

    for (const decorator of this.decorators) {
      rosetta = new decorator(rosetta)
    }

    const value = rosetta.getJsonSchema()
    // console.log(`after rosetta`, value)

    const formattedJsonFormData = this.dynamoFormsHelper.getFormattedJsonFormData(JSON.stringify(value))

    return formattedJsonFormData
  }

  isSuggester(format?: string): boolean {
    return this.dynamoFormsHelper.isSuggester(format)
  }

  getPropertiesToWatch(properties: EnhancedProperties): { [key: string]: EnhancedPropertyFormDescriptor } {
    return this.dynamoFormsHelper.getPropertiesToWatch(properties)
  }

  getUpdatedSuggesterProp(
    ...args: [EnhancedPropertyFormDescriptor, any, string, any[]]
  ): Observable<Partial<EnhancedPropertyFormDescriptor>> {
    return this.dynamoFormsHelper.getUpdatedSuggesterPropWorkFlow(...args)
  }

  getDropDownOptionsPickerItems(schemaProperty: PropertyFormDescriptor): ExtendedBindValue[] {
    let result: ExtendedBindValue[] = []

    if (!schemaProperty) {
      return result
    }

    if (schemaProperty.hasOwnProperty('x-cv-data-source')) {
      const enumProp = schemaProperty.enum || []

      result = enumProp.map(
        (item, index): ExtendedBindValue => ({
          title: schemaProperty.options?.enumDisplayValues ? schemaProperty.options.enumDisplayValues[index] : '',
          property: item,
        })
      )
    } else if (schemaProperty.hasOwnProperty('enum')) {
      const enumProp = schemaProperty.enum || []

      result = enumProp.map((value: any, index: number) => {
        const title = schemaProperty.enumNames ? schemaProperty.enumNames[index] : value

        return { property: value, title }
      })
    } else if (schemaProperty.type === 'boolean' || schemaProperty.type?.includes('boolean')) {
      result = [
        { property: 'true', title: 'Yes' },
        { property: 'false', title: 'No' },
      ]
    }

    return result
  }

  getDropDownActionItems(actions: Workflow.Dto.V1.Common.Action[] | undefined, event?: AddActionEvent): ExtendedBindValue[] {
    let options: ExtendedBindValue[] = []
    const IfElseActions = (actions as Workflow.Dto.V1.Common.IfElse[]) ?? []
    const flatActions = flatMap(IfElseActions, (action) => {
      const trueBranchFlattened = map(action.trueBranch, (branch) => ({
        ...branch,
      }))
      const falseBranchFlattened = map(action.falseBranch, (branch) => ({
        ...branch,
      }))
      return [action, ...trueBranchFlattened, ...falseBranchFlattened]
    })

    flatActions.forEach((action) => {
      if (
        action?.successOutputSchema &&
        this.isOutputOptionAfterAddedAction(actions as Workflow.Dto.V1.Common.IfElse[], action.uid, event)
      ) {
        const description = this.findActionInsideWorkflowAndBuildDescription(IfElseActions, action.uid, '')
        options = this.addOutputs(action, options, description)
      }
    })

    return options
  }

  private addOutputs(action: Workflow.Dto.V1.Common.Action, options: ExtendedBindValue[], description: string) {
    const schema = JSON.parse(action.successOutputSchema)
    const properties: Record<string, Record<string, any>> = schema?.properties || {}
    const newOptions: ExtendedBindValue[] = Object.entries(properties)
      .filter((prop) => {
        const [key, value] = prop
        return key !== 'isSuccessEvent' && !Object.keys(value).includes('x-cv-dynamic-output')
      })
      .map((prop) => {
        const [key, value] = prop
        const title = key || 'title'
        const property = value?.hasOwnProperty('x-cv-propertyPath') ? value['x-cv-propertyPath'] : title
        const type = !!value.type && typeof value.type === 'string' ? value.type : value.type[0]
        return {
          category: 'inputs',
          step: 'action',
          type,
          uid: action.uid,
          property,
          title,
          description,
        }
      })
    options = [...options, ...newOptions]
    return options
  }

  private findActionInsideWorkflowAndBuildDescription(actions: Workflow.Dto.V1.Common.IfElse[], uid: string, basePath: string): string {
    for (let i = 0; i < actions.length; i++) {
      const path = this.findActionPath(actions[i], uid, basePath, i)
      if (path) return path
    }
    return ''
  }

  private findActionPath(action: Workflow.Dto.V1.Common.IfElse, uid: string, basePath: string = '', index: number = 0): string {
    if (action.uid === uid) {
      return `${basePath}${index + 1}.${action.name}`
    }

    if (action.trueBranch && action.trueBranch.length > 0) {
      const truePath = this.findActionInsideWorkflowAndBuildDescription(
        action.trueBranch as Workflow.Dto.V1.Common.IfElse[],
        uid,
        `${basePath}${index + 1}.${action.categoryItemName}>Yes>`
      )
      if (truePath) return truePath
    }

    if (action.falseBranch && action.falseBranch.length > 0) {
      const falsePath = this.findActionInsideWorkflowAndBuildDescription(
        action.falseBranch as Workflow.Dto.V1.Common.IfElse[],
        uid,
        `${basePath}${index + 1}.${action.categoryItemName}>No>`
      )
      if (falsePath) return falsePath
    }

    return ''
  }

  private isOutputOptionAfterAddedAction(
    actions: Workflow.Dto.V1.Common.IfElse[],
    targetActionUid: string,
    event?: AddActionEvent
  ): boolean {
    if (!event) {
      return false
    }
    const targetHierarchy = this.findIndexHierarchyById(actions, targetActionUid)
    const insertedActionUid = this.getInsertedActionUid(event)
    if (!insertedActionUid) {
      return false
    }
    const insertedActionHierarchy = this.findIndexHierarchyById(actions, insertedActionUid)
    return this.compareTargetsHierarchy(targetHierarchy, insertedActionHierarchy, event?.isEdit)
  }

  private getInsertedActionUid(event: AddActionEvent): string | undefined {
    if (event.isEdit) {
      return event.key
    }
    if (event.afterActionUid === undefined || event.afterActionUid === '') {
      return event?.parentUid
    }
    return event?.afterActionUid
  }

  private findIndexHierarchyById(
    actions: Workflow.Dto.V1.Common.IfElse[],
    uid: string,
    path: ActionHierarchy[] = [],
    branchName?: string
  ): ActionHierarchy[] {
    for (let i = 0; i < actions.length; i++) {
      const currentItem = actions[i]
      const currentPath = [...path, { level: path.length, index: i, branch: branchName }]

      if (currentItem.uid === uid) {
        return currentPath
      }
      if (currentItem.trueBranch) {
        const resultInTrueBranch = this.findIndexHierarchyById(
          currentItem.trueBranch as Workflow.Dto.V1.Common.IfElse[],
          uid,
          currentPath,
          'trueBranch'
        )
        if (resultInTrueBranch.length > 0) {
          return resultInTrueBranch
        }
      }
      if (currentItem.falseBranch) {
        const resultInFalseBranch = this.findIndexHierarchyById(
          currentItem.falseBranch as Workflow.Dto.V1.Common.IfElse[],
          uid,
          currentPath,
          'falseBranch'
        )
        if (resultInFalseBranch.length > 0) {
          return resultInFalseBranch
        }
      }
    }
    return []
  }

  private compareTargetsHierarchy(outputAction: ActionHierarchy[], newAction: ActionHierarchy[], isEdit?: boolean): boolean {
    if (outputAction.length === 0 || newAction.length === 0) {
      return false
    }
    const maxLength = Math.min(outputAction.length, newAction.length)
    for (let i = 0; i < maxLength; i++) {
      const comparisonResult = this.compareSingleLevel(outputAction[i], newAction[i], isEdit)
      if (comparisonResult !== null) {
        return comparisonResult
      }
    }
    return this.compareIndexAtLastLevel(outputAction, newAction, maxLength, isEdit)
  }

  private compareSingleLevel(outputLevel: ActionHierarchy, newLevel: ActionHierarchy, isEdit?: boolean): boolean | null {
    if (outputLevel.level !== newLevel.level) {
      return outputLevel.level < newLevel.level
    }
    if (outputLevel.branch && newLevel.branch) {
      if (outputLevel.branch === newLevel.branch) {
        return isEdit ? outputLevel.index < newLevel.index : outputLevel.index <= newLevel.index
      }
      return false
    }
    return null
  }

  private compareIndexAtLastLevel(
    outputAction: ActionHierarchy[],
    newAction: ActionHierarchy[],
    maxLength: number,
    isEdit?: boolean
  ): boolean {
    const lastOutputIndex = outputAction[maxLength - 1].index
    const lastNewIndex = newAction[maxLength - 1].index
    return isEdit ? lastOutputIndex < lastNewIndex : lastOutputIndex <= lastNewIndex
  }
}

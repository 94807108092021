<div class="panel-container">
    <div class="panel">
        <div class="title p-l-20" style="display: flex; line-height: 69px">
            <div style="flex: 1; font-size: 14px; font-weight: 600" class="uppercase">
                <span translate>licenses_Filters</span>
            </div>
        </div>
        <div class="p-20" style="height: 100%; overflow-y: scroll;">
            <div class="section">
                <div class="subtitle">
                    <div translate>
                        <span style="line-height: 24px" translate>common_SKUS</span>
                        <mat-icon class="history">history</mat-icon>
                    </div>
                </div>
                <cv-checkbox-list orientation="vertical" class="col">
                    <cv-checkbox (checkedChange)="clickSku(sku)" [value]="sku.checked"
                        *ngFor="let sku of skuList">

                        <div class="plan-container">
                            <div style="text-transform: uppercase">{{ sku.key }}</div>

                            <span class="text-grey" style="font-size: 12px; margin-left: auto;">{{'licenses_Consumed' |
                                translate}}: {{
                                sku.count }}
                            </span>
                        </div>
                    </cv-checkbox>
                </cv-checkbox-list>
            </div>

            <div class="section">
                <div class="subtitle">
                    <div translate>
                        <span style="line-height: 24px" translate>licenses_ServiceUsage</span>
                        <mat-icon class="history">history</mat-icon>
                    </div>
                </div>

                <div *ngFor="let service of serviceUsageItems" style="line-height: 36px">
                    <div class="plan-container" *ngIf="isServiceActive(service.key)">
                        <em class="plans-logo" [ngClass]="getIconTitleByService(service.key)"
                            [ngStyle]="{ '-webkit-text-fill-color': getIconColorByService(service.key) }"
                            title="{{ service.key }}"></em>
                        <span>{{service.key}}</span>

                        <span  class="text-grey" style="font-size: 12px; margin-left: auto;">
                            <cv-tertiary-dropdown [options]="getOptions()" [value]="service.status"
                                (selectionChanged)="serviceUsageStatusChanged(service, $any($event), comp)" #comp>
                            </cv-tertiary-dropdown>
                        </span>
                    </div>
                </div>
            </div>

            <div class="section">
                <div class="subtitle">
                    <div translate>
                        <span style="line-height: 24px" translate>licenses_UsageMap</span>
                        <mat-icon class="history">history</mat-icon>
                    </div>
                </div>
                <cv-checkbox-list orientation="vertical" class="col">
                    <cv-checkbox (checkedChange)="clickPlan(plan)" [value]="plan.checked"
                        *ngFor="let plan of getVisiblePlansList()">

                        <div class="plan-container">
                            <span *ngFor="let key of getKeysByPlanKey(plan.key)">
                                <em class="plans-logo" [ngClass]="getIconTitle(key)"
                                    [ngStyle]="{ '-webkit-text-fill-color': getIconColor(key) }" title="{{ key }}"></em>
                            </span>

                            <span  class="text-grey" style="font-size: 12px; margin-left: auto;">{{'licenses_Users' |
                                translate}}: {{ plan.count }}
                            </span>
                        </div>
                    </cv-checkbox>
                </cv-checkbox-list>
            </div>
        </div>


        <div class="footer-container">
            <cv-button (click)="close()" class="m-r-10" buttonType="secondary" [text]="'common_Cancel' | translate">
            </cv-button>
            <cv-button (click)="submit()" primary="secondary" [text]="'common_Apply' | translate">
            </cv-button>
        </div>
    </div>
</div>

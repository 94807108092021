import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-workflow-execution-details-dialog',
  templateUrl: './workflow-execution-details-dialog.component.html',
  styleUrls: ['./workflow-execution-details-dialog.component.sass'],
})
export class WorkflowExecutionDetailsDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<WorkflowExecutionDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { workflowId: string; executionId: string; title: string; isForPlaybook: boolean }
  ) {}

  ngOnInit(): void {}
}
